import api from "../../axios/axios";
import { authHeader, getDeviceAndStore } from "../../axios/authHeader";

// create login service for login Action and return axios response based on api..
export function login(username, password, store_id, master_store_id) {
  // console.log(CryptoJS)
  // const bytes  = CryptoJS.decrypt(pass, '$2a$10$CwTybottlecUXWuepos5&12MGk');
  // console.log(bytes.toString())
  // const password = bytes.toString(CryptoJS.enc.Utf8);
  const data = {
    username,
    password,
    store_id: parseInt(store_id),
    master_store_id,
  };
  return api.post("/api/staff/api/login/", data);
}

// Create logout service for logout Action and return axios response based on api..
// Pass Authentication Header with Token..
export function logout() {
  const body = {};
  const config = { headers: authHeader() };
  return api.post("/api/staff/api/logout/", body, config);
}

export function setUpRegisterDevice(data) {
  return api.post("/api/setting/api/localconfig/", data);
}

export function AuthUser(username, password, master_store_id) {
  let storeId = getDeviceAndStore()[1];
  const data = { username, password, master_store_id, store_id: storeId };
  return api.post("/api/staff/api/authuserapi/", data);
}

export function setUpOnlineDevices(formData) {
  return api.post("/api/inventory/api/onlinedevice/", formData);
}

export function RemoveOnlineDevices(id) {
  return api.delete(`/api/inventory/api/onlinedevicedetail/${id}`);
}

export default {
  login,
  logout,
  AuthUser,
  setUpRegisterDevice,
  setUpOnlineDevices,
  RemoveOnlineDevices,
};
