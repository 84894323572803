import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function LoaderSpinner({ additionalClass }) {
  return (
    <div>
      <Loader className={`custom-loader ${additionalClass}`} type="TailSpin" color="#000" height={100} width={100} />
    </div>
  );
}
