import React, { useEffect, useState, useRef } from "react";
import {
  Nav,
  Image,
  Navbar,
  Container,
  Button,
  Dropdown,
  Form,
} from "@themesberg/react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";

// Routes
import { Routes } from "../routes";

// Images
import boopSfx from "../assets/sounds/item_not_found.mp3";

// Logout Action
import {
  logoutAction,
  loadingToggleAction,
  clockInOutLoginAction,
  closeCustomerScreen,
  INACTIVITY_LOGOUT,
} from "../app/actions/authAction";
//  React Redux
import { connect, useDispatch } from "react-redux";
import Clock from "../pages/register/clock";
import { NavLink } from "react-router-dom";
import {
  authUserId,
  checkUserPermission,
  getSettingData,
  isMobileDevice,
  getDeviceAndStore
} from "../axios/authHeader";
import { addActionFunction } from "../app/actions/getListAction";
import {
  OfflineData,
  currencyFormat,
  formatCode,
  isWeb,
} from "../app/helpers/custom";
import {
  ADDITEMONSALE,
  AGEPOPUPSHOW,
  CLOCKIN,
  CLOCKOUT,
} from "../app/helpers/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import FormField from "../pages/components/formField";
import ReactAutocomplete from "react-autocomplete";
// import dymo from 'dymojs';
import { xml2json, json2xml } from "xml-js";
import BarcodeReader from "react-barcode-reader";
import { Howl, Howler } from "howler";
import { AddSaleItemList, AgePopUpShow } from "../app/actions/saleItemAction";
import Localstorage from "app/storage/Localstorage";
import CustomModal from "../pages/components/Modals";
import TaskListDetails from "../pages/task-list/task-list-details";
import PropTypes from 'prop-types';

const listenUserActivity = (dispatch) => {
  let inactivityTimer;
  const inactivityThreshold = 1800000;

  function startInactivityTimer() {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(
      logoutUserDueToInactivity,
      inactivityThreshold
    );
  }

  function resetInactivityTimer() {
    clearTimeout(inactivityTimer);
    startInactivityTimer();
  }

  function logoutUserDueToInactivity() {
    dispatch(logoutAction());
    dispatch({
      type: INACTIVITY_LOGOUT,
      data: true,
    });
  }

  // Listen for user activity events
  document.addEventListener("mousemove", resetInactivityTimer);
  document.addEventListener("keydown", resetInactivityTimer);

  // Start the inactivity timer when the page loads
  startInactivityTimer();
};

const HeaderNavbar = (props) => {
  const [statusCheck, setStatusCheck] = useState(1);
  const [offlineRecords, setOfflineRecords] = useState(0);
  const dispatch = useDispatch();
  listenUserActivity(dispatch);
  const history = useHistory();
  const [showClose, setShowClose] = useState(false);
  const onCollapse = () => {
    setShowClose(!showClose);
  };
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [error, setError] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const handleDialogChange1 = () => setShow1(!show);
  const [logout, setLogout] = useState(false);
  const [fullscreen, setFullScreen] = useState(false);
  const handleDialogChange = () => setShow(!show);
  const [posSideSetting, setPosSideSetting] = useState(
    getSettingData("wpos_config")
  );
  const [printIcon, setPrintIcon] = useState(0);
  const [labelModal, setLabelModal] = useState(false);
  const [open, setopen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [stockSearch, setstockSearch] = useState("");
  const [noOfLabels, setNoOfLabels] = useState(1);
  const [barcode, setBarcode] = useState(null);
  const [itemList, setItemList] = useState([]);
  const [UpcTenCode, setUpcTenCode] = useState(
    props?.genSetting?.OTHER?.use_upc_ten
  );
  const [clockinoutFormData, setClockinoutFormData] = useState({
    time: new Date(),
    user: "",
    password: "",
  });

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    function updateOnlineStatus() {
      var condition = navigator.onLine ? 1 : 2;
      localStorage.setItem("SystemStatus", condition === 1 ? true : false);
      setStatusCheck(condition);
    }
  }, []);

  useEffect(() => {
    let offline_sales = JSON.parse(localStorage.getItem("off_sale"));
    console.log("offline_sales", offline_sales)
    if (!isWeb()) {
      setOfflineRecords(offline_sales?.length)
    }
  }, [localStorage.getItem("off_sale")])


  useEffect(() => {
    setUpcTenCode(props?.genSetting?.OTHER?.use_upc_ten);
  }, [props?.genSetting]);

  const handleClockInOutValidation = (type) => {
    console.log("confirm data", clockinoutFormData);
    if (!clockinoutFormData?.user || clockinoutFormData?.user === "") {
      setError({
        title: "Error!",
        msg: `Please select user.`,
      });
    } else if (
      !clockinoutFormData?.password ||
      clockinoutFormData?.password === ""
    ) {
      setError({
        title: "Error!",
        msg: `Password is required.`,
      });
    } else {
      setConfirmation({
        title: "Confirmation",
        msg: " Are you sure you want to check in?",
        type,
      });
    }
  };

  const onSelectItem = (item) => {
    const wpos_config = Localstorage.getItem("wpos_config");
    console.log(`label_printer`, wpos_config);
    console.log(`item:`, item);
    const id = parseInt(item.split(",")[1]);
    const data = props?.itemlist.find((itm) => itm.id === id);
    console.log(`label print data`, data);
    if (
      wpos_config &&
      wpos_config?.hasOwnProperty("label_printer") &&
      wpos_config.label_printer != undefined &&
      wpos_config.label_printer != ""
    ) {
      printLabel(wpos_config.label_printer, data);
      // setError({
      //   title: "Error",
      //   msg: `PrintLabel(): unknown printer ${wpos_config.label_printer}`
      // })
    } else {
      let LabelMsg;
      switch (wpos_config.label_print_setting) {
        case "bp":
          LabelMsg = "Browser";
          break;
        case "wp":
          LabelMsg = "Web";
          break;
        case "dymo":
          LabelMsg = "Dymo";
          break;
        case "brother":
          LabelMsg = "Brother";
          break;
        case "remote":
          LabelMsg = "Remote";
          break;
        default:
          LabelMsg = "";
      }
      setError({
        title: "Error",
        msg: `${LabelMsg} Label printer is not found. Please install the printer.`,
      });
    }
    setstockSearch(item);
    setopen(false);
    setSearchValue("");
  };

  useEffect(() => {
    window.addEventListener("storage", () => {
      setPosSideSetting(getSettingData("wpos_config"));
    });
  }, []);

  useEffect(() => {
    if (posSideSetting.autolanuchCustomeScreen) OpenCustomerWindow();
  }, [posSideSetting.autolanuchCustomeScreen]);

  const handleOfflineLogout = () => {
    if (isWeb()) {
      const date = new Date();
      const day = date.getDate();
      const filename =
        "wpos_offline_sales_" +
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        day +
        "_" +
        date.getHours() +
        "-" +
        date.getMinutes() +
        ".json";
      const offline_sales = localStorage.getItem("wpos_osales");
      if (offline_sales != undefined) {
        localStorage.removeItem("wpos_osales");

        const a = document.createElement("a");
        const blob = new Blob([offline_sales], {
          type: "application/octet-stream",
        });
        window.URL = window.URL || window.webkitURL;
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    }
    localStorage.removeItem("pos_user");
    const cookies = new Cookies();
    cookies.remove("bottleposLoginCheck");
    history.push({
      pathname: "/",
    });
  };

  const logoutHandle = (e) => {
    e.preventDefault(e);
    if (window.navigator.onLine) {
      if (checkUserPermission("ask_close_register_on_logout")) {
        setConfirmation({
          title: "Confirmation",
          msg: "Do you want to close the register.",
          type: "registerClosing",
        });
      } else {
        dispatch(loadingToggleAction(true));
        dispatch(logoutAction());
      }
    } else {
      if (isWeb()) {
        const offline_sales = localStorage.getItem("wpos_osales");
        if (offline_sales != undefined && offline_sales != null) {
          setError({
            title: "Offline Backup",
            msg: "You have offline sales that have not been uploaded to the server. Would you like to back them up?",
            status: 1,
          });
        } else {
          handleOfflineLogout();
        }
      } else {
        // OfflineData(uploadOfflineSales);
        function uploadOfflineSales(ress) {
          if (
            ress != undefined &&
            ress != null &&
            Object.keys(ress).length > 0
          ) {
            // offline sales backup alert
            setError({
              title: "Offline Backup",
              msg: "You have offline sales that have not been uploaded to the server. Would you like to back them up?",
              status: 1,
            });
          } else {
            localStorage.removeItem("pos_user");
            const cookies = new Cookies();
            cookies.remove("bottleposLoginCheck");
            history.push({
              pathname: "/",
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (
      props?.posSetData != undefined &&
      props?.posSetData.label_printing_enabled === true
    ) {
      setPrintIcon(1);
    } else {
      setPrintIcon(0);
    }
  }, [props?.posSetData?.label_printing_enabled]);

  useEffect(() => {
    console.log(`props.itemList`, props);
    let wpos_config = getSettingData("wpos_config");
    if (getSettingData("wpos_config") !== false) {
      if (wpos_config.label_printing_enabled === true) {
        setPrintIcon(1);
      } else {
        setPrintIcon(0);
      }
    }
  }, []);

  useEffect(() => {
    console.log(`props.itemlist`, props);
  }, [props?.itemlist]);

  useEffect(() => {
    expendUi(props.expendCounter);
  }, [props.expendCounter]);

  const expendUi = () => {
    return props.setWidth(props.expendCounter);
  };

  const newWindow = useRef(null);
  const OpenAdminDashboard = (e) => {
    e.preventDefault();
    if (newWindow.current && !newWindow.current.closed) {
      newWindow.current.focus();
    } else {
      if (!isWeb() && window.electron) {
        // Use an absolute URL or a full path to the admin dashboard.
        var adminDashboardURL =
          window.location.protocol + "//" + window.location.host + "/admin";
        window.electron.shell.openExternal(adminDashboardURL);
      } else {
        // Use an absolute URL or a full path to the admin dashboard.
        let adminDashboardURL =
          window.location.protocol + "//" + window.location.host + "/admin";
        let user = Localstorage.getItem("pos_user");
        Localstorage.setItem("user", user);
        newWindow.current = window.open(adminDashboardURL);
      }
    }
  };

  // const custWindow = useRef(null);
  // let electronwin = undefined;
  // let win = useRef(null);
  // const OpenCustomerWindow = () => {
  //   if (custWindow.current && !custWindow.current.closed) {
  //     custWindow.current.blur();
  //     custWindow.current.focus();
  //     custWindow.current.stop();
  //   } else {
  //     var customer_screen_url =
  //       window.location.protocol +
  //       "//" +
  //       window.location.host +
  //       "/customer-screen";
  //     if (!isWeb() && window.electron) {
  //       const BrowserWindow = window.electron.remote.BrowserWindow;
  //       const path = window.path;
  //       var electronrequire = window.electronrequire;
  //       let displays = window.electron.remote.screen.getAllDisplays();
  //       let externalDisplay = displays.find((display) => {
  //         return display.bounds.x !== 0 || display.bounds.y !== 0;
  //       });

  //       if (externalDisplay) {
  //         if (electronwin) {
  //           // electronwin.focus();
  //           electronwin.blur();
  //           window.electron.remote.getCurrentWindow().focus();
  //         } else {
  //           win = new BrowserWindow({
  //             x: externalDisplay.bounds.x + 50,
  //             y: externalDisplay.bounds.y + 50,
  //             webPreferences: {
  //               nodeIntegration: false,
  //               preload: electronrequire,
  //             },
  //           });

  //           win.setFullScreen(true);
  //           win.setMenuBarVisibility(false);
  //           win.loadURL(customer_screen_url);
  //           electronwin = win;
  //           electronwin.once("closed", function (e) {
  //             console.log(e);
  //           });
  //           win.blur();
  //           window.electron.remote.getCurrentWindow().focus();
  //         }
  //       }
  //       window.electron.remote.getCurrentWindow().on("close", () => {
  //         // on main window closed
  //         win.close();
  //       });
  //     } else {
  //       custWindow.current = window.open(
  //         customer_screen_url,
  //         "popupWindow",
  //         "width=" +
  //           window.innerWidth +
  //           ", height=" +
  //           window.innerHeight +
  //           ", left=0, top=0, scrollbars=no"
  //       );
  //     }
  //   }
  // };
  let custWindow = null; // Maintain a reference to the customer screen window
  const isCustomerWindowOpen = () => {
    if (window.electron) {
      const allWindow = window.electron.remote.BrowserWindow.getAllWindows();
      return allWindow.find((element) => element.customerScreen);
    }
  };

  const OpenCustomerWindow = () => {
    const customerWindow = isCustomerWindowOpen();
    if (customerWindow && !customerWindow.closed) {
      customerWindow.focus();
    } else {
      var customer_screen_url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/customer-screen";
      if (!isWeb() && window.electron) {
        const BrowserWindow = window.electron.remote.BrowserWindow;
        const path = window.path;
        var electronrequire = window.electronrequire;
        let displays = window.electron.remote.screen.getAllDisplays();
        let externalDisplay = displays.find((display) => {
          return display.bounds.x !== 0 || display.bounds.y !== 0;
        });

        if (externalDisplay) {
          if (customerWindow) {
            customerWindow.focus();
            window.electron.remote.getCurrentWindow().focus();
          } else {
            closeCustomerScreen(); //in case customer screen is open first we have to close it then only new customer screen will open
            custWindow = new BrowserWindow({
              x: externalDisplay.bounds.x + 50,
              y: externalDisplay.bounds.y + 50,
              webPreferences: {
                nodeIntegration: false,
                preload: electronrequire,
              },
            });
            custWindow.setFullScreen(true);
            custWindow.customerScreen = true;
            custWindow.setMenuBarVisibility(false);
            custWindow.loadURL(customer_screen_url);

            custWindow.once("closed", function (e) {
              console.log(e);
              custWindow = null; // Reset the reference when the window is closed
            });

            custWindow.blur();
            window.electron.remote.getCurrentWindow().focus();
          }
        }
        window.electron.remote.getCurrentWindow().on("close", () => {
          // on main window closed
          if (custWindow && !custWindow.closed) {
            custWindow.close();
          }
        });
      } else {
        custWindow = window.open(
          customer_screen_url,
          "popupWindow",
          "width=" +
          window.innerWidth +
          ", height=" +
          window.innerHeight +
          ", left=0, top=0, scrollbars=no"
        );
      }
    }
  };

  const handlePrintLabel = () => {
    console.log("alert print label");
    // props.setLabelModal(true);
    setLabelModal(true);
  };

  const handleClockInOut = async (type) => {
    if (type === "registerClosing") {
      setConfirmation(false);
      setLogout(false);
      history.push({
        pathname: "/reports",
        state: {
          logout: true,
        },
      });
      return;
    }

    if (window.navigator.onLine == false) {
      setConfirmation(false);
      setError({
        title: "Error",
        msg: "Clock In/Out will not work in Offline mode.",
      });
    } else {
      setConfirmation(false);
      let clockInOutDetails = JSON.parse(
        localStorage.getItem("clockInOutDetails")
      );
      if (type === CLOCKIN) {
        if (clockInOutDetails && clockInOutDetails.clockInDetails) {
          setError({
            title: "Error",
            msg:
              "Could Not Clock In. Please Clock Out First . Last Clock In time was: " +
              clockInOutDetails?.clockInDetails?.starttime,
          });
          return false;
        }
      } else {
        if (clockInOutDetails && clockInOutDetails.clockOutDetails) {
          setError({
            title: "Error",
            msg: "Could Not Clock Out. Please Clock In First",
          });
          return false;
        }
      }
      setShow1(false);
      let loginSuccess = await dispatch(
        clockInOutLoginAction(
          clockinoutFormData?.user?.username,
          clockinoutFormData?.password
        )
      );
      if (loginSuccess) {
        if (type === CLOCKIN) {
          dispatch(
            addActionFunction(CLOCKIN, {
              clock_intime: new Date(clockinoutFormData.time).getTime(),
              staff_id: clockinoutFormData.user?.id,
              store: getDeviceAndStore()[1],
            })
          );
        } else {
          dispatch(
            addActionFunction(CLOCKOUT, {
              clock_outtime: new Date(clockinoutFormData.time).getTime(),
              staff_id: clockinoutFormData.user?.id,
              id: clockInOutDetails?.clockInDetails.id,
              store: getDeviceAndStore()[1],
            })
          );
        }
      }
    }
  };
  // function printLabel() {
  //   try {
  //     const labelXml = '<Label>test</Label>';
  //     printJS({ printable: labelXml, type: 'raw-html', targetStyles: ['*'] });
  //   } catch (error) {
  //     console.error('Error printing label:', error);
  //   }
  // }

  const printLabel = async (label_printer, item) => {
    const Dymo = require("dymojs"),
      dymo = new Dymo({ printerName: label_printer });

    const template = props?.labeltemplates.filter(
      (item) => item.id === posSideSetting.template
    );
    console.log("Dymo", Dymo);
    console.log("dymo", dymo);
    console.log(`item1...`, item);

    const replacements = [
      { placeholder: "Name1", value: item.name },
      { placeholder: "Price", value: `$${item?.modifier[0]?.price}` },
      { placeholder: "1030.99", value: `$${item?.modifier[0]?.price}` },
      { placeholder: "SupplierName", value: item.supplier_name ?? "" },
      { placeholder: "Name2", value: item.name2 ?? "" },
      // { placeholder: 'Code', value: item.code[0].code ?? "" },
    ];

    // let updatedXmlContent = template[0]?.content;
    // replacements.forEach((replacement) => {
    //   updatedXmlContent = updatedXmlContent.replace(
    //     new RegExp(replacement.placeholder, 'g'),
    //     replacement.value
    //   );
    // });

    // Parse XML to JSON
    const jsonData = xml2json(template[0]?.content, {
      compact: true,
      spaces: 4,
    });
    const parsedData = JSON.parse(jsonData);

    // Find the <Name>Price</Name> element and replace the value
    const objectInfo = parsedData.DieCutLabel.ObjectInfo;
    const priceObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "Price"
    );
    if (priceObject) {
      priceObject.TextObject.StyledText.Element.String._text =
        "$" + item?.modifier[0]?.price;
    }

    const nameObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "Name1"
    );
    if (nameObject) {
      nameObject.TextObject.StyledText.Element.String._text = item.name ?? "";
    }

    const name2Object = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "Name2"
    );
    if (name2Object) {
      name2Object.TextObject.StyledText.Element.String._text = item.name2 ?? "";
    }

    const supplierObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "SupplierName"
    );
    if (supplierObject) {
      supplierObject.TextObject.StyledText.Element.String._text =
        item.supplier_name ?? "";
    }

    const promoObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "PromotionName"
    );
    if (promoObject) {
      promoObject.TextObject.StyledText.Element.String._text =
        item.PromotionName ?? "";
    }

    const promoPriceObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "PromotionPrice"
    );
    if (promoPriceObject) {
      promoPriceObject.TextObject.StyledText.Element.String._text =
        item.PromotionPrice ?? "";
    }

    const promoQtyObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "PromotionQty"
    );
    if (promoQtyObject) {
      promoQtyObject.TextObject.StyledText.Element.String._text =
        item.PromotionQty ?? "";
    }

    const codeObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "code"
    );
    if (codeObject) {
      codeObject.TextObject.StyledText.Element.String._text = item.code ?? "";
    }

    // Convert JSON back to XML
    const modifiedXmlData = json2xml(parsedData, {
      compact: true,
      ignoreComment: true,
      spaces: 4,
    });

    for (let i = 0; i < noOfLabels; i++) {
      console.log("label_printer", label_printer);
      console.log("modifiedXmlData", modifiedXmlData);
      await dymo.print(label_printer, modifiedXmlData);
    }
  };

  useEffect(() => {
    if (barcode !== null) onBarCodeScan(barcode, itemList);
  }, [barcode, itemList]);

  useEffect(() => {
    if (props.itemlist !== undefined) setItemList(props.itemlist);
  }, [props.itemlist]);

  const handleScanBarcode = (barcode) => {
    if (labelModal === true) {
      console.log("Label Scan Barcode");
      console.log(`barcode`, barcode);
      if (barcode.length > 0 && barcode.match(/^[0-9]+$/) !== null) {
        setBarcode(barcode);
      }
    }
  };

  function onBarCodeScan(barcode, itemList) {
    console.log(`on scan`, itemList);
    console.log(`barcode`, barcode);
    const itemDetails = itemList.filter((item) =>
      item.code.find((code) => code.code === barcode)
    );
    if (itemDetails !== undefined && itemDetails.length > 0) {
      if (props.itemlist.length) {
        const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
        if (
          wpos_config &&
          wpos_config?.hasOwnProperty("label_printer") &&
          wpos_config.label_printer != undefined &&
          wpos_config.label_printer != ""
        ) {
          printLabel(wpos_config.label_printer, itemDetails?.[0]);
        } else {
          let LabelMsg;
          switch (wpos_config.label_print_setting) {
            case "bp":
              LabelMsg = "Browser";
              break;
            case "wp":
              LabelMsg = "Web";
              break;
            case "dymo":
              LabelMsg = "Dymo";
              break;
            case "brother":
              LabelMsg = "Brother";
              break;
            case "remote":
              LabelMsg = "Remote";
              break;
            default:
              LabelMsg = "";
          }
          setError({
            title: "Error",
            msg: `${LabelMsg} Label printer is not found. Please install the printer.`,
          });
        }
        setTimeout(() => {
          setBarcode(null);
        }, 300);
      } else {
        dispatch(AgePopUpShow(true, AGEPOPUPSHOW));
      }
    } else if (barcode !== "") {
      console.log(`item not found`);
      // Setup the new Howl.
      const sound = new Howl({ src: [boopSfx] });
      // play the sound
      sound.play();
      // Change global volume.
      Howler.volume(1);
      // setShow(STOCKCODECHECK);
      // setSearchFocus(true);
      setError({
        title: "Error",
        msg: `Item not Found`,
      });
    }
  }

  const resetData = () => {
    console.log("Reset Barcode");
  };

  const location = useLocation();
  useEffect(() => {
    dispatch(AddSaleItemList([], ADDITEMONSALE));
  }, [location.pathname]);

  const closeLabelModal = () => {
    setSearchValue("");
    setLabelModal(false);
    setopen(false);
    // props.setLabelModal(false);
  };


  return (
    <>
      <BarcodeReader onError={resetData} onScan={handleScanBarcode} />
      <Navbar expand="lg" variant="light" className="ps-0  pb-0 pt-0">
        <Container fluid className="px-0">
          <div className="d-flex  w-100 has-box-shadow">

            {!isMobileDevice() && (
              <Nav className="align-items-center secondary-navbar">
                <NavLink
                  exact
                  activeClassName="active"
                  title="Register"
                  className="nav-link "
                  to={Routes.Register.path}
                >
                  Register
                </NavLink>
                <NavLink
                  title="Sales"
                  className="nav-link"
                  activeClassName="active"
                  to={Routes.Sales.path}
                >
                  Sales
                </NavLink>
                <NavLink
                  title="WebOrders"
                  activeClassName="active"
                  className="nav-link"
                  to={Routes.WebOrders.path}
                >
                  WebOrders
                </NavLink>
                <NavLink
                  title="Reports"
                  activeClassName="active"
                  className="nav-link"
                  to={Routes.Reports.path}
                >
                  Reports
                </NavLink>
                {checkUserPermission("setting") && (
                  <NavLink
                    title="Settings"
                    activeClassName="active"
                    className="nav-link"
                    to={Routes.Settings.path}
                  >
                    Settings
                  </NavLink>
                )}
              </Nav>

            )}
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            <Navbar.Toggle
              as={Button}
              // aria-controls="basic-navbar-nav"
              onClick={onCollapse}
            >
              {showClose ? (
                <FontAwesomeIcon
                  width="24px"
                  height="24px"
                  color="#fff"
                  icon={faTimes}
                />
              ) : (
                <span className="navbar-toggler-icon" />
              )}
            </Navbar.Toggle>
            {/* <Button
        type="button"
        disabled={!notes}
        variant="primary px-4 position-absolute form-cta-btn"
        onClick={saveNote}
      >
        Save
      </Button> */}

            <div className="online-status d-flex d-md-none">
              <div>
                {offlineRecords > 0 && !statusCheck && `${offlineRecords} Offline Records`}
              </div>
              {statusCheck === 1 ? (
                <>
                  <span className="mx-1 text-success">Online</span>
                  <Image
                    className="me-2"
                    src={props?.dynamicImages?.LogoutIcon}
                    alt="list Icon"
                  />
                </>
              ) : (
                <>
                  <span className="mx-1 text-danger">Offline</span>
                  <Image
                    className="me-2"
                    src={props?.dynamicImages?.cross}
                    alt="list Icon"
                  />
                </>
              )}
            </div>

            <div className="toggle-btn d-block d-md-none">
              <Button
                title="Logout"
                type="button"
                onClick={() => setLogout(true)}
                variant="primary"
              >
                <Image src={props?.dynamicImages?.logout} alt="list Icon" />
              </Button>
            </div>

            {showClose && <Navbar.Toggle as={Button}
              className="shortcut-overlay" onClick={onCollapse}></Navbar.Toggle>}
            <div className={`navbar-collapse ${showClose ? 'show' : ''}`} >
              <Nav className="align-items-center  primary-navbar w-100">
                <div className="d-flex flex-column d-md-none w-100">
                  <NavLink
                    exact
                    activeClassName="active"
                    title="Register"
                    className="nav-link "
                    to={Routes.Register.path}
                  >
                    <Image src={props?.dynamicImages?.register} alt="Register Icon" />
                    Register
                  </NavLink>
                  <NavLink
                    title="Sales"
                    className="nav-link"
                    activeClassName="active"
                    to={Routes.Sales.path}
                  >
                    <Image src={props?.dynamicImages?.pos} alt="sales Icon" />
                    Sales
                  </NavLink>
                  <NavLink
                    title="WebOrders"
                    activeClassName="active"
                    className="nav-link"
                    to={Routes.WebOrders.path}
                  >
                    <Image src={props?.dynamicImages?.weborders} alt="weborders Icon" />
                    WebOrders
                  </NavLink>
                  <NavLink
                    title="Reports"
                    activeClassName="active"
                    className="nav-link"
                    to={Routes.Reports.path}
                  >
                    <Image src={props?.dynamicImages?.reports} alt="reports Icon" />
                    Reports
                  </NavLink>
                  {checkUserPermission("setting") && (
                    <NavLink
                      title="Settings"
                      activeClassName="active"
                      className="nav-link"
                      to={Routes.Settings.path}
                    >
                      <Image src={props?.dynamicImages?.settings} alt="settings Icon" />
                      Settings
                    </NavLink>
                  )}
                </div>
                {!isMobileDevice() && (
                  <>
                    <Link
                      title="Task List"
                      to="#"
                      className="nav-link hide-for-mobile-cash-register"
                      onClick={() => handleDialogChange()}
                    >
                      <Image src={props?.dynamicImages?.listIcon} alt="list Icon" />
                      Task List
                    </Link>
                    {printIcon === 1 && (
                      <Link
                        title="Print Label"
                        onClick={() => handlePrintLabel()}
                        className="nav-link hide-for-mobile-cash-register"
                        to="#"
                      >
                        <Image
                          src={props?.dynamicImages?.PrintLabelIcon}
                          alt="list Icon"
                        />
                        Print Label
                      </Link>
                    )}
                    <Link
                      onClick={() => handleDialogChange1()}
                      title="Clock In/Out"
                      className="nav-link hide-for-mobile-cash-register"
                      to="#"
                    >
                      <Image
                        src={props?.dynamicImages?.ClockIcon}
                        alt="list Icon"
                      />
                      Clock In/Out
                    </Link>
                    {!fullscreen ? (
                      <Link
                        onClick={() => {
                          // props.handle.enter();
                          setFullScreen(true);
                          document
                            .getElementsByClassName("fullscreen-working")[0]
                            .requestFullscreen();
                        }}
                        title="Full Screen"
                        className="nav-link hide-for-mobile-cash-register"
                        to="#"
                      >
                        <Image
                          src={props?.dynamicImages?.FullscreenIcon}
                          alt="list Icon"
                        />
                        Full Screen
                      </Link>
                    ) : (
                      <Link
                        onClick={() => {
                          // props.handle.exit();
                          setFullScreen(false);
                          document.exitFullscreen();
                        }}
                        title="Exit Full Screen"
                        className="nav-link"
                        to="#"
                      >
                        <Image
                          src={props?.dynamicImages?.FullscreenIcon}
                          alt="list Icon"
                        />
                        Exit Full Screen
                      </Link>
                    )}
                    <Link
                      title="Open Administration Dashboard"
                      className="nav-link d-lg-none d-xl-flex hide-for-mobile-cash-register"
                      // to={Routes.Admin.path}
                      onClick={OpenAdminDashboard}
                      to="#"
                    >
                      <Image
                        src={props?.dynamicImages?.AdminIcon}
                        alt="Admin Icon"
                      />
                      Admin
                    </Link>

                    <Link
                      title="Customer Screen"
                      to="#"
                      className="nav-link d-lg-none d-xl-flex hide-for-mobile-cash-register"
                      // style={{ pointerEvents: props.loading ? "none" : "" }}
                      onClick={OpenCustomerWindow}
                    >
                      <Image
                        src={props?.dynamicImages?.EyeIcon}
                        alt="customer Icon"
                      />
                      Customer Screen
                    </Link>
                    <div className="custom-dropdown d-none d-lg-flex hide-for-mobile-cash-register">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <Image
                            src={props?.dynamicImages?.hamburger}
                            alt="hamburger Icon"
                          />
                          <span>Menu</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {authUserId(true) && (
                            <Dropdown.Item
                              title="Open Administration Dashboard"
                              to="#"
                              className="d-lg-flex d-xl-none"
                              onClick={OpenAdminDashboard}
                            >
                              Admin
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            title="Customer Screen"
                            to="#"
                            className="d-lg-flex d-xl-none"
                            style={{ pointerEvents: props.loading ? "none" : "" }}
                            onClick={OpenCustomerWindow}
                          >
                            Customer Screen
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            title="Logout"
                            to="#!logout"
                            onClick={() => setLogout(true)}
                          >
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </>
                )}
              </Nav>
            </div>
          </div>
        </Container>
      </Navbar>

      <CustomModal
        open={show}
        handleClose={handleDialogChange}
        dialogClassName="custom-modal "
        title="Task List"
        size="lg"
        content={<TaskListDetails />}
      />

      <CustomModal
        open={logout}
        handleClose={() => setLogout(false)}
        dialogClassName="custom-modal confirmation success-popup with-border-bottom"
        title="Logout Confirmation"
        content={
          <>
            <h1>
              <img
                className="me-3"
                width="25px"
                height="25px"
                src={props?.dynamicImages?.check}
                alt="check icon"
              />
              Are you sure?
            </h1>
          </>
        }
        action={
          <>
            {props.showloading ? (
              ""
            ) : (
              <Button
                variant="outline-blackcustom px-5"
                onClick={() => setLogout(false)}
                title="No"
              >
                No
              </Button>
            )}
            <Button
              type="submit"
              variant="primary px-5"
              disabled={props.showloading ? true : false}
              onClick={(e) => logoutHandle(e)}
              title={props.showloading ? "Please wait..." : "Yes"}
            >
              {props.showloading ? `Please wait..` : `Yes`}
            </Button>
          </>
        }
      />

      {/* Clock in and clock out popup */}

      <CustomModal
        open={show1}
        handleClose={() => setShow1(false)}
        dialogClassName="custom-modal without-save-btn clock-in with-border-bottom"
        title="Clock In/Out"
        content={
          <>
            <Clock
              clockinoutFormData={clockinoutFormData}
              setClockinoutFormData={setClockinoutFormData}
            />
          </>
        }
        action={
          <>
            <Button
              variant="outline-seagreen px-5 me-2"
              onClick={() => handleClockInOutValidation(CLOCKIN)}
              title="Clock In"
            >
              Clock In
            </Button>
            <Button
              variant="outline-danger px-5"
              onClick={() => handleClockInOutValidation(CLOCKOUT)}
              title="Clock Out"
            >
              Clock Out
            </Button>
          </>
        }
      />

      {/* confirmation */}

      <CustomModal
        open={confirmation}
        handleClose={() => setConfirmation(false)}
        dialogClassName="custom-modal without-save-btn clock-in with-border-bottom modal-clock"
        title={confirmation.title}
        content={confirmation.msg}
        action={
          <>
            <Button
              variant="outline-danger px-5 me-2"
              onClick={(e) => {
                setConfirmation(false);
                if (confirmation.type === "registerClosing") {
                  dispatch(loadingToggleAction(true));
                  dispatch(logoutAction());
                }
              }}
              title="No"
            >
              No
            </Button>
            <Button
              variant="outline-seagreen px-5 me-2"
              onClick={() => handleClockInOut(confirmation.type)}
              title="Yes"
            >
              Yes
            </Button>
          </>
        }
      />

      {/* print label modal */}

      <CustomModal
        open={labelModal}
        handleClose={closeLabelModal}
        dialogClassName="custom-modal without-save-btn clock-in with-border-bottom"
        title="Print Label"
        backdrop={"static"}
        content={
          <div>
            {props?.itemlist && props.itemlist.length > 0 ? (
              <div className="d-flex align-items-center top-search">
                <div className="react-auto-complete navbar-search stock-search ">
                  <ReactAutocomplete
                    id="stockSearch"
                    open={open}
                    items={props?.itemlist?.map((e) => {
                      return {
                        ...e,
                        s_code: e?.code?.map((y) => y.code).join(",") + e.name,
                      };
                    })}
                    shouldItemRender={(item, value) =>
                      item.s_code?.toLowerCase().indexOf(value?.toLowerCase()) >
                      -1
                    }
                    getItemValue={(item) => item.name + "," + item.id + ","}
                    renderItem={(item, highlighted) => (
                      <div
                        className={
                          highlighted
                            ? "list-group-item focused"
                            : "list-group-item"
                        }
                        key={item.id}
                      >
                        <span>{item?.name}</span>
                        <span>{item?.item_size_name || "-"}</span>
                        <span>
                          {item?.modifier !== undefined
                            ? currencyFormat(item?.modifier[0]?.price)
                            : currencyFormat(0)}
                        </span>
                        <span>{item?.totalitem}</span>
                      </div>
                    )}
                    value={searchValue}
                    inputProps={{
                      placeholder: "Scan or Search Item",
                      onKeyDown: (e) => {
                        e.preventDefault();
                        if (e.key === "Backspace") {
                          setSearchValue(searchValue.slice(0, -1));
                          setopen(true);
                        } else {
                          if (e.key.length == 1) {
                            setSearchValue(searchValue + e.key);
                            setopen(true);
                          }
                          if (e.key === "Enter" && Number(searchValue)) {
                            const finalItem = props?.itemlist?.filter(
                              (item) =>
                                item?.code[0]?.code == searchValue ||
                                item?.code[0]?.code ==
                                formatCode(searchValue, UpcTenCode)
                            );
                            if (finalItem.length == 1) {
                              onSelectItem(
                                `${finalItem[0]?.name},${finalItem[0]?.id},`
                              );
                              setopen(false);
                              return;
                            } else {
                              setSearchValue("");
                              setopen(false);
                              return;
                            }
                          }
                        }
                      },
                      onBlur: (e) => {
                        setopen(false);
                        if (searchValue.length == 0) {
                          setopen(false);
                        } else {
                          setopen(true);
                        }
                      },
                    }}
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        setopen(false);
                      } else {
                        setopen(true);
                      }
                    }}
                    onSelect={(item) => onSelectItem(item)}
                  />
                </div>
              </div>
            ) : (
              <span>Loading Items..</span>
            )}
            <Form.Group className="form-floating mt-4">
              <FormField
                label="No of Labels"
                type="number"
                name="noOfLabels"
                value={noOfLabels}
                placeholder="No of Labels"
                onChange={(e) => setNoOfLabels(e.target.value)}
              />
            </Form.Group>
          </div>
        }
        action={
          <>
            <Button
              variant="outline-seagreen px-5 me-2"
              onClick={() => closeLabelModal()}
              title="Cancel"
            >
              Cancel
            </Button>
          </>
        }
      />

      {/* error */}

      <CustomModal
        open={error}
        handleClose={() => (setError(false), setBarcode(""))}
        dialogClassName="custom-modal without-save-btn confirmation with-border-bottom modal-clock-pass"
        title={error.title}
        content={error.msg}
        action={
          <>
            <Button
              variant="outline-seagreen px-5 me-2"
              onClick={() =>
                error.status == 1
                  ? handleOfflineLogout()
                  : (setError(false), setBarcode(""))
              }
              title="Ok"
            >
              Ok
            </Button>
          </>
        }
      />
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    showloading: state.auth.showloading,
    loading: state.inventory.loading,
    expendCounter: state.saleItem.expendCounter,
    storeId: state.auth.storeId,
    posSetData: state.saleItem.posSetData,
    itemlist: state.inventory.itemlist,
    labeltemplates: state.saleItem.labeltemplates,
    genSetting: state.inventory.generalSetting.data,
  };
};
HeaderNavbar.propTypes = {
  dynamicImages: PropTypes.shape({
    logo1: PropTypes.string,
    listIcon: PropTypes.string,
    PrintLabelIcon: PropTypes.string,
    ClockIcon: PropTypes.string,
    FullscreenIcon: PropTypes.string,
    AdminIcon: PropTypes.string,
    EyeIcon: PropTypes.string,
    hamburger: PropTypes.string,
    check: PropTypes.string,
    loading: PropTypes.bool,
    showloading: PropTypes.bool,
  }),
  itemlist: PropTypes.array.isRequired,
};
export default connect(mapStateToprops)(HeaderNavbar);
