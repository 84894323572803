export const NEXTMODULE = "NEXTMODULE";
export const ITEM = "item";
export const ADDITEM = "ADDITEM";
export const ITEMIMAGE = "ITEMIMAGE";
export const CATEGORY = "Category";
export const REMOVEITEMSALE = "REMOVEITEMSALE";
export const REMOVESALELIST = "REMOVESALELIST";
export const TAX = "tax";
export const TAXRULE = "tax Rule";
export const LOADING = "LOADING";
export const ITEMLIST_GET_LIST = "ITEMLIST_GET_LIST";
export const STOCKCODE_GET_LIST = "STOCKCODE_GET_LIST";
export const ADDITIONALFEE_GET_LIST = "ADDITIONALFEE_GET_LIST";
export const ITEMTAG_GET_LIST = "ITEMTAG_GET_LIST";
export const MERGEITEMSPAYLOAD = "MERGEITEMSPAYLOAD";
export const ITEMMOSIFIER_GET_LIST = "ITEMMOSIFIER_GET_LIST";
export const UPDATE_ITEM_QTY_IN_HAND = "UPDATE_ITEM_QTY_IN_HAND";
export const CATEGORY_GET_LIST = "CATEGORY_GET_LIST";
export const TAX_GET_LIST = "TAX_GET_LIST";
export const TAXRULE_GET_LIST = "TAXRULE_GET_LIST";
export const ITEMSALELIST = "ITEMSALELIST";
export const COMPLETEITEMSALELIST = "COMPLETEITEMSALELIST";
export const ITEMSALENOTE = "ITEMSALENOTE";
export const SALESSATUSFILTER = "SALESSATUSFILTER";
export const ADDITEMONSALE = "ADDITEMONSALE";
export const NUMPADSTATUS = "NUMPADSTATUS";
export const ACCOUNTSETTING = "account";
export const GENERALSETTING = "general";
export const UPDATEGENERALSETTINGS = "UPDATEGENERALSETTINGS";
export const ADDITIONALFEE = "ADDITIONALFEE";
export const BOTTLEDEPOSIT = "BOTTLEDEPOSIT";
export const AGEPOPUPSHOW = "AGEPOPUPSHOW";
export const TOTALAMOUNT = "AddTotalAmount";
export const CASH = "Cash";
export const CARD = "Card";
export const ETF = "Etf";
export const DEBITCARD = "Debit Card";
export const DEBIT = "debit";
export const SIDECARD = "Side Card";
export const GIFTCARD = "Gift Card";
export const EBTSALE = "EBT Sale";
export const EBTCASH = "EBT Cash";
export const CHECK = "Check";
export const HOUSEPAY = "House Sale";
export const ADDCONFIRMSALEITEM = "ADDCONFIRMSALEITEM";
export const ADDCANCLESALEITEM = "ADDCANCLESALEITEM";
export const ITEMSALE = "ITEMSALE";
export const ITEMUPDATESALE = "ITEMUPDATESALE";
export const PAYMENT = "PAYMENT";
export const REVERTPAYMENT = "REVERTPAYMENT";
export const REMOVEALLITEMSALE = "REMOVEALLITEMSALE";
export const ITEMSALELIST_GET_LIST = "ITEMSALELIST_GET_LIST";
export const UPDATESALESTATUS = "UPDATESALESTATUS";
export const ITEMSALELISTUPDATED = "ITEMSALELISTUPDATED";
export const GETITEMLIST = "GETITEMLIST";
export const ITEMSALEREFUND = "ITEMSALEREFUND";
export const ITEMSALECOMPLETE = "ITEMSALECOMPLETE";
export const ALL = "All";
export const ORDER = "Order";
export const COMPLETE = "Complete";
export const VOID = "Void";
export const REFUNDED = "Refunded";
export const CANCELED = "Canceled";
export const DEPRETURN = "DEPRETURN";
export const DECELINED = "Declined";
export const NOSALE = "No Sale";
export const SALECHANNEl = "sales_channel";
export const SALE_EVENT = "sales_event";
export const ACCEPTED = "Accepted";
export const ALLVALUE = "";
export const ORDERVALUE = 0;
export const COMPLETEVALUE = 1;
export const VOIDVALUE = 3;
export const REFUNDEDVALUE = 2;
export const CANCELEDVALUE = 6;
export const DECELINEDVALUE = 5;
export const NOSALEVALUE = 8;
export const ACCEPTEDVALUE = 7;
export const DEPOSITRETURNTYPE = 6;
export const LOTTOPAYOUTTYPE = 5;
export const ONLINEPAYOUTTYPE = 9;
export const ONLINELOTTERYTYPE = 8;
export const LOTTOSALETYPE = 4;
export const NEGATIVETYPE = 3;
export const GIFTITEMTYPE = 7;
export const DEVICE = "DEVICE";
export const STORE = "STORE";
export const DEVICE_GET_LIST = "DEVICE_GET_LIST";
export const STORE_GET_LIST = "STORE_GET_LIST";
export const POSSETTING = "pos";
export const UPDATEPOSSETTING = "UPDATEPOSSETTING";
export const DEVICESETUP = "DEVICESETUP";
export const POSSIDEETTINGS = "POSSIDEETTINGS";
export const RESETLOCALCONFIGDATA =
  "Are you sure you want to restore local settings to their defaults?";
export const CLEARLOCALCONFIGDATA =
  "Are you sure you want to clear all local data? This removes all locally stored data except device registration key. Offline Sales will be deleted.";
export const REFRESHREMOTEDATA =
  "Are you sure you want to reload data from the server?";
export const REMOVEDEVICEREGISTRATION =
  "Are you sure you want to delete this devices registration? You will be logged out and this device will need to be re registered.";
export const RETRYFAILDTRANSTION = "RETRYFAILDTRANSTION";
export const CLOSEBATCH = "Error: NOT FOUND. Retry  Closing Batch?";
export const EXPENDUI = "EXPENDUI";
export const RESETPAXMACHINE = "RESETPAXMACHINE";
export const CLOSEPAXMACHINE = "CLOSEPAXMACHINE";
export const CHECKFORUPDATES = "CHECKFORUPDATES";
export const REPORT = "REPORT";
export const GRAPHREPORT = "GRAPHREPORT";
export const REPORT_GET_LIST = "REPORT_GET_LIST";
export const LASTCLOSINGTIME = "LASTCLOSINGTIME";
export const CASHRECONCILIATION = "CASHRECONCILIATION";
export const IMAGEUPLOAD = "IMAGEUPLOAD";
export const SLIDESHOWFORPOSSCREEN = "SliderForPosScreen";
export const SLIDRESHOWIMAGES = "SliderShowImages";
export const UPLOADSTATICIMAGEFORNUMBER = "UploadStaticImageForNumber";
export const UPLOADFIXEDIMAGE = "uploadFixedImage";
export const CUSTOMERSCREENCHECK = "CUSTOMERSCREENCHECK";
export const REGISTERREPORT = "REGISTERREPORT";
export const DAYREPORT = "DAYREPORT";
export const CUSTOMERDATA = "CUSTOMERDATA";
export const CUSTOMER_GET_LIST = "CUSTOMER_GET_LIST";
export const CUSTOMERREWARDS = "CUSTOMERREWARDS";
export const CUSTOMER_REWARDS_GET_LIST = "CUSTOMER_REWARDS_GET_LIST";
export const FORMSUBMITLOAD = "FORMSUBMITLOAD";
export const ITEMTYPE = "ITEMTYPE";
export const ITEMTYPE_GET_LIST = "ITEMTYPE_GET_LIST";
export const TAG = "TAG";
export const TAG_GET_LIST = "TAG_GET_LIST";
export const SUPPLIER = "SUPPLIER";
export const SUPPLIER_GET_LIST = "SUPPLIER_GET_LIST";
export const REMOVEMODIFIER = "Modifier";
export const REMOVESTOCKCODE = "stockcode";
export const STOCKCODECHECK = "Stock code";
export const ITEMSIZE = "ITEMSIZE";
export const ITEMSIZE_GET_LIST = "ITEMSIZE_GET_LIST";
export const ITEMUPDATE = "ITEMUPDATE";
export const ITEMUPDATEPUSHER = "ITEMUPDATEPUSHER";
export const ITEMUPDATELABELPRINT = "ITEMUPDATELABELPRINT";
export const POSSETTINGDATA = "posSetting";
export const GENERALSETIINGDATA = "generalSetting";
export const ACCOUNTSETTINGDATA = "accountSetting";
export const STAFFANDADMIN = "STAFFANDADMIN";
export const CUSTOMERUPDATE = "CUSTOMERUPDATE";
export const POSSIDEETTINGS_GET = "POSSIDEETTINGS_GET";
export const SHORTCUT = "SHORTCUT";
export const RECALL = "RECALL";
export const OTHER = "OTHER";
export const PAYOUT = "PAYOUT";
export const REPRINT = "REPRINT";
export const DUPLICATE = "DUPLICATE";
export const DELETE = "DELETE";
export const ITEMADDED = "ITEMADDED";
export const VALIDATION = "VALIDATION";
export const DATEOFBIRTH = "DATEOFBIRTH";
// IMAGE COMPRESS MAXIMUM UPLOAD SIZE AND MAX WIDTH HEIGHT
export const IMAGE_COMPRESS_UPLOAD_SIZE = 2;
export const IMAGE_COMPRESS_MAX_WIDTH_HEIGHT = 1920;

//expenses
export const EXPENSE = "expense";
export const EXPENSE_ADDED = "expense_added";
export const EXPENSE_VENDOR = "expense_vendor";
export const EXPENSE_VENDOR_GET_LIST = "expense_vendor_get_list";
export const EXPENSE_CATEGORY = "expense_category";
export const EXPENSE_CATEGORY_GET_LIST = "expense_category_get_list";
export const EXPENSETYPE = "expense_type";
export const EXPENSETYPE_GET_LIST = "expense_get_list";

//logo
export const BUSINESSLOGO = "businessLogo";

export const SUMMARY_GET_LIST = "SUMMARY_GET_LIST";

//clock
export const CLOCKIN = "Clock in";
export const CLOCKOUT = "Clock out";

//dashboard
export const DASHBOARD = "DASHBOARD";

export const PAXPOPUP = "PAXPOPUP";

export const BATCHPOPUP = "BATCHPOPUP";

export const HELLO = "HELLO";

export const OFFLINESALE = "OFFLINESALE";
export const REGISTERCLOSINGTIME = "REGISTERCLOSINGTIME";

//register report
export const SATFF_ADMIN_GET_LIST = "SATFF_ADMIN_GET_LIST";
export const REPORTTRNSACTION = "REPORTTRNSACTION";

//permissions
export const USER_EVENT = "user_event";
export const USERCHANNEL = "user_channel";

//user details
export const USERDETAILS = "USERDETAILS";

//realtime
export const DEVICE_CHANNEL = "onlinedevice_channel";
export const TERMINAL_DEVICE_EVENT = "terminal_device_event";

export const PRINTLABEL_CHANNEL = "printlabel_channel";
export const PRINTLABEL_EVENT = "print_event";

// label template
export const LABELTEMPLATE_GET_LIST = "LABELTEMPLATE_GET_LIST";

//darkmode
export const CHANGE_MODE = "CHANGE_MODE";

//fontsize
export const CHANGE_FONTSIZE = "CHANGE_FONTSIZE";
export const CHANGE_SEARCH_FONTSIZE = "CHANGE_SERACH_FONTSIZE";
export const CHANGE_CUSTOMER_SCREEN_FONTSIZE =
  "CHANGE_CUSTOMER_SCREEN_FONTSIZE";

//customer
export const CUSTOMERSCREENCHANNEL = "CUSTOMERSCREENCHANNEL";
export const CUSTOMER_CHANNEL = "customer_channel";
export const CUSTOMER_EVENT = "customer_event";
export const SALE_CUSTOMER = "SALE_CUSTOMER";

//puhser setting
export const SETTING_CHANNEL = "setting_channel";
export const SETTING_EVENT = "setting_event";

//validate bussiness
export const BUSINESS_VALIDATE = "BUSINESS_VALIDATE";

//customer points
export const REEDEM_REWARDS = "REEDEM_REWARDS";
export const LOYALITY_POINTS_ENABLE = "1";
export const DOLLER_REWARD_TYPE = "2";

//promotions
export const PROMOTIONS = "PROMOTIONS";
export const PROMOTIONS_GET_LIST = "PROMOTIONS_GET_LIST";
export const PROMOTION_DATA = "PROMOTION_DATA";

//auth
export const INVALID_CREDENTISLD = "Invalid username or password";

//item not found
export const ITEMNOTFOUND = "ITEMNOTFOUND";

//discount
export const DISCOUNT = "DISCOUNT";
export const ITEMDISCOUNTS = "ITEMDISCOUNTS"
export const PAXERROR = "PAXERROR";
export const ITEMNOSALE = "ITEMNOSALE";

//image data
export const GETIMAGEDATA = "GETIMAGEDATA";

export const UPDATELOTTODATA = "UPDATELOTTODATA";
export const UPDATELOTTOIDS = "UPDATELOTTOIDS";

//category
export const CATEGORYCHANNEL = "cat_channel";
export const CATEGORYEVENT = "cat_event";

//sale other items
export const SALEITEMCUSTOMER = "SALEITEMCUSTOMER";
export const SALEEXTRACOST = "SALEEXTRACOST";
export const SALEITEMS = "SALEITEMS";
export const SALECODES = "SALECODES";
export const SALEPAYMENTS = "SALEPAYMENTS";
export const SALEVOID = "SALEVOID";

//email invoice
export const EMAILINVOICE = "EMAILINVOICE";
export const SHORTCUTVIEW = "SHORTCUTVIEW";

//popups
export const INITIALIZED_POPUP = "INITIALIZED_POPUP"

//payfac
export const PAYFACAUTHTOKEN = "PAYFACAUTHTOKEN"
export const CREATEORDER = "CREATEORDER"
export const AUTHORDER = "AUTHORDER"
export const VERIFYSTATUS = "VERIFYSTATUS"
export const CANCEL = "CANCEL"
export const HARDWARECANCEL = "HARDWARECANCEL"
export const PAYFACREFUND = "PAYFACREFUND"
export const DECLINECARDADD = "DECLINECARDADD"


