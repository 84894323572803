import actions from "../../assets/images/cigarsPOS/actions.svg";
import ActivateCard from "../../assets/images/cigarsPOS/activate-card.svg";
import DeactivateCard from "../../assets/images/cigarsPOS/deactivate-card.svg";
import add from "../../assets/images/cigarsPOS/add.svg";
import AddBalance from "../../assets/images/cigarsPOS/add-balance.svg";
import allCategories from "../../assets/images/cigarsPOS/all-categories.svg";
import backBlue from "../../assets/images/cigarsPOS/back-blue.svg";
import bckBtn from "../../assets/images/cigarsPOS/back-btn-green.svg";
import banIcon from "../../assets/images/cigarsPOS/ban.svg";
import barcodeIcon from "../../assets/images/cigarsPOS/barcode.png";
import cancel from "../../assets/images/cigarsPOS/cancel.png";
import cart from "../../assets/images/cigarsPOS/cart-arrow-down.svg";
import sideCard from "../../assets/images/cigarsPOS/cash-icon.svg";
import check from "../../assets/images/cigarsPOS/check.png";
import check1 from "../../assets/images/cigarsPOS/check1.svg";
import CheckBalance from "../../assets/images/cigarsPOS/check-balance.svg";
import LogoutIcon from "../../assets/images/cigarsPOS/check-circle.svg";
import close from "../../assets/images/cigarsPOS/close.png";
import cost from "../../assets/images/cigarsPOS/cost.svg";
import cross from "../../assets/images/cigarsPOS/cross.svg";
import crossIcon from "../../assets/images/cigarsPOS/cross-screen.svg";
import customer from "../../assets/images/cigarsPOS/customer1.svg";
import dayReport from "../../assets/images/cigarsPOS/day-report.svg";
import debit from "../../assets/images/cigarsPOS/debit.svg";
import deleteIcon from "../../assets/images/cigarsPOS/delete.svg";
import delivery from "../../assets/images/cigarsPOS/delivery.png";
import details from "../../assets/images/cigarsPOS/details.svg";
import ebt from "../../assets/images/cigarsPOS/ebt.svg";
import edit from "../../assets/images/cigarsPOS/edit.svg";
import ExpanIcon from "../../assets/images/cigarsPOS/expand.svg";
import EyeIcon from "../../assets/images/cigarsPOS/eye-btn.svg";
import EyeIconCLosed from "../../assets/images/cigarsPOS/eye-btn-closed.svg";
import faCross from "../../assets/images/cigarsPOS/faCross.svg";
import faPLus from "../../assets/images/cigarsPOS/faPlus.svg";
import general from "../../assets/images/cigarsPOS/general.svg";
import logo from "../../assets/images/cigarsPOS/get.png";
import giftCard from "../../assets/images/cigarsPOS/gist-card.svg";
import hamburger from "../../assets/images/cigarsPOS/hamburger.svg";
import hamburger2 from "../../assets/images/cigarsPOS/hamburger-2.svg";
import ClockIcon from "../../assets/images/cigarsPOS/Icon-awesome-clock.svg";
import AdminIcon from "../../assets/images/cigarsPOS/Icon-awesome-user-cog.svg";
import FullscreenIcon from "../../assets/images/cigarsPOS/Icon-map-fullscreen.svg";
import PrintLabelIcon from "../../assets/images/cigarsPOS/Icon-material-label.svg";
import listIcon from "../../assets/images/cigarsPOS/Icon-metro-list.svg";
import LogoutIcon1 from "../../assets/images/cigarsPOS/Icon-open-account-logout.svg";
import items from "../../assets/images/cigarsPOS/items1.svg";
import logo1 from "../../assets/images/cigarsPOS/logo-new.svg";
import minus from "../../assets/images/cigarsPOS/minus.svg";
import miscallenous from "../../assets/images/cigarsPOS/miscallenous.svg";
import moneyOff from "../../assets/images/cigarsPOS/money-off.svg";
import more from "../../assets/images/cigarsPOS/more.svg";
import noData from "../../assets/images/cigarsPOS/no-data-found.svg";
import offers from "../../assets/images/cigarsPOS/offers.svg";
import options from "../../assets/images/cigarsPOS/options.svg";
import payments from "../../assets/images/cigarsPOS/payments.svg";
import PlusAdd from "../../assets/images/cigarsPOS/plus-add.svg";
import plus from "../../assets/images/cigarsPOS/plus-green.svg";
import print from "../../assets/images/cigarsPOS/print.svg";
import printBg from "../../assets/images/cigarsPOS/print-big.svg";
import printIcon from "../../assets/images/cigarsPOS/print-icon.svg";
import printing from "../../assets/images/cigarsPOS/printing.svg";
import promotions from "../../assets/images/cigarsPOS/promotions.svg";
import redeem from "../../assets/images/cigarsPOS/reddem.svg";
import refundCash from "../../assets/images/cigarsPOS/refund-cash.svg";
import refund from "../../assets/images/cigarsPOS/refund-icon.svg";
import refundCard from "../../assets/images/cigarsPOS/refund-on-card.svg";
import registerReports from "../../assets/images/cigarsPOS/register-reports.svg";
import remove from "../../assets/images/cigarsPOS/remove.svg";
import sellerReports from "../../assets/images/cigarsPOS/seller.svg";
import defaultImage from "../../assets/images/cigarsPOS/shop-with-us.svg";
import shortcutIcon from "../../assets/images/cigarsPOS/shortcut-icon.svg";
import BgImage from "../../assets/images/cigarsPOS/signin-bg.png";
import whatsSelling from "../../assets/images/cigarsPOS/star.svg";
import takingsCOunt from "../../assets/images/cigarsPOS/takings-count.svg";
import adminIcon from "../../assets/images/cigarsPOS/user.png";
import warning from "../../assets/images/cigarsPOS/warning.svg";
import warningRed from "../../assets/images/cigarsPOS/warning-red.svg";
import logoWhite from "../../assets/images/cigarsPOS/logo-new-white.svg";
import minusIcon from "../../assets/images/cigarsPOS/circle-minus.svg";
import plusIcon from "../../assets/images/cigarsPOS/circle-plus.svg";
import ratings from "../../assets/images/cigarsPOS/ratings.svg";
import giftIcon from "../../assets/images/cigarsPOS/gift-icon.svg";
import discount from "../../assets/images/cigarsPOS/discount.svg";
import logout from "../../assets/images/logout.svg";
import settings from "../../assets/images/cigarsPOS/settings.svg";
import register from "../../assets/images/cigarsPOS/register.png";
import weborders from "../../assets/images/cigarsPOS/weborders.png";
import reports from "../../assets/images/cigarsPOS/reports.svg";
import pos from "../../assets/images/cigarsPOS/pos_sales.svg";
import tag from "../../assets/images/tag.svg";
import advancedUpdate from "../../assets/images/cigarsPOS/advanced-update.svg";




export const CigarsPOSImg = () => {
  const Img = {
    advancedUpdate: advancedUpdate,
    tag:tag,
    pos:pos,
    reports:reports,
    weborders:weborders,
    register:register,
    settings:settings,
    logout:logout,
    discount:discount,
    ratings: ratings,
    plusIcon:plusIcon,
    minusIcon:minusIcon,
    logoWhite:logoWhite,
    hamburger2:hamburger2,
    warningRed:warningRed,
    warning:warning,
    adminIcon:adminIcon,
    takingsCOunt:takingsCOunt,
    whatsSelling:whatsSelling,
    BgImage:BgImage,
    shortcutIcon:shortcutIcon,
    defaultImage:defaultImage,
    sellerReports:sellerReports,
    remove:remove,
    registerReports:registerReports,
    refundCash:refundCash,
    redeem:redeem,
    promotions:promotions,
    printing:printing,
    printIcon:printIcon,
    printBg:printBg,
    print:print,
    plus:plus,
    PlusAdd:PlusAdd,
    payments:payments,
    options:options,
    offers:offers,
    noData:noData,
    more:more,
    moneyOff:moneyOff,
    miscallenous:miscallenous,
    minus:minus,
     actions:actions,
     ActivateCard:ActivateCard,
     DeactivateCard:DeactivateCard,
     add:add,
     AddBalance:AddBalance,
     allCategories:allCategories,
     backBlue:backBlue,
     bckBtn:bckBtn,
     banIcon:banIcon,
     barcodeIcon:barcodeIcon,
     cancel:cancel,
     cart:cart,
     sideCard:sideCard,
     check:check,
     check1:check1,
     CheckBalance:CheckBalance,
     LogoutIcon:LogoutIcon,
     close:close,
     cost:cost,
     cross:cross,
     crossIcon:crossIcon,
     customer:customer,
     dayReport:dayReport,
     debit:debit,
     deleteIcon:deleteIcon,
     delivery:delivery,
     details:details,
     ebt:ebt,
     edit:edit,
     ExpanIcon:ExpanIcon,
     EyeIcon:EyeIcon,
     EyeIconCLosed:EyeIconCLosed,
     faCross:faCross,
     faPLus:faPLus,
     general:general,
     logo:logo,
     giftCard:giftCard,
     hamburger:hamburger,
     ClockIcon:ClockIcon,
     AdminIcon:AdminIcon,
     FullscreenIcon:FullscreenIcon,
     PrintLabelIcon:PrintLabelIcon,
     listIcon:listIcon,
     LogoutIcon1:LogoutIcon1,
     items:items,
     logo1:logo1,
     refund:refund,
     refundCard:refundCard,
     giftIcon:giftIcon,
  };
  return Img;
};

export default CigarsPOSImg;
