import React, { useState, useEffect, useRef } from "react";
import { ListGroup } from "@themesberg/react-bootstrap";
import { Button } from "@themesberg/react-bootstrap";
import FormField from "../../../pages/components/formField";
import Switch from "../../components/switch/switch";
import CustomNumpad from "../../components/customNumpad";
import {
  CUSTOMERSCREENCHECK,
  DISCOUNT,
  POSSETTINGDATA,
  TOTALAMOUNT,
} from "../../../app/helpers/constant";
import {
  AddTotalpayAmount,
  AddDataToReducer,
} from "../../../app/actions/saleItemAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { currencyFormat, qtyFormat } from "../../../app/helpers/custom";
import {
  checkUserPermission,
  getSettingData,
  isUserAdminstrator,
} from "../../../axios/authHeader";
import CustomModal from "../../components/Modals";
import { Auth } from "../../auth/auth";
import Localstorage from "app/storage/Localstorage";
import PropTypes from 'prop-types';

const TotalInfo = (props) => {
  const dispatch = useDispatch();
  const [showDiscountNumpad, setShowDiscountNumpad] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);
  const [sumQtySaleitem, setSumQtySaleitem] = useState([]);
  const [sumTotalSaleitem, setSumTotalSaleitem] = useState([]);
  const [sumSubtotal, setSumSubtotal] = useState([]);
  const [sumTaxTotal, setSumtaxTotal] = useState([]);
  const [sumBottleDeposit, setSumBottleDeposit] = useState([]);
  const [sumAdditionalFee, setSumAdditionalFee] = useState([]);
  const [sumNoncashAdjust, setSumNoncashAdjust] = useState({
    type: "",
    value: Number(0).toFixed(2),
  });
  const [checkedEbtAny, setEbtAnyChecked] = useState(false);
  const [sumEbtTotal, setSumEbtTotal] = useState([]);
  const [additionalFeeCheck, setAdditionalFeeCheck] = useState(0);
  const [discountSymbol, setDiscountSymbol] = useState(false);
  const [auth, setAuth] = useState(false);
  const [error, setError] = useState(false);
  const [authType, setAuthType] = useState("");
  const [authSuccess, setAuthSuccess] = useState(false);

  const decimalQty =
    (props?.PosSetting?.SALE?.allow_decimal_qty_sale ??
      props?.PosSetting?.SALE?.allow_decimal_qty_sale) ||
    props?.posSetting?.SALE?.allow_decimal_qty_sale;
  const [botteDeposit, setBottleDeposit] = useState({
    bottleDepositReturnAmount: "",
    enable: "",
    lable: "",
  });
  const [nonCashAdjustMent, setNonCashAdjustMent] = useState({
    customerScreenTaxt: "",
    ebt: "",
    enable: "",
    label: "",
    minimun: "",
    percentage: "",
  });

  const [taxRuleData, setTaxRuleData] = useState({
    deafultEbtTax: "",
    deafultEbtTaxCheck: "",
    defaultTax: "",
    taxButton: "",
    taxButtonCheck: "",
    taxLable: "",
  });
  const discountKeyRef = useRef([]);


  useEffect(() => {
    if (authSuccess) {
      dispatch({
        type: DISCOUNT,
        payload: { symbol: discountSymbol, value: discountValue, ignorePopup: false, newlyAdded: true },
      });
      setDiscountValue(0);
      setAuth(false);
      return;
    }
  }, [authSuccess]);


  const maximumDiscountLimitPerSaleType = useSelector(
    () => props?.PosSetting?.SALE?.maximum_discount_limit_per_sale_type
  );
  useEffect(() => {
    setDiscountSymbol(maximumDiscountLimitPerSaleType === "$");
  }, [maximumDiscountLimitPerSaleType]);

  const [typingDer, setTypingDer] = useState(0)
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(true);

  useEffect(() => {
    let elem = document.getElementById("discVal")
    if (first) {
      elem.selectionEnd = count + 1;
      elem.selectionStart = count + 1;
      elem.focus()
    } else {
      elem.selectionEnd = count;
      elem.selectionStart = count;
      elem.focus()
    }
  }, [discountValue])

  useEffect(() => {
    setTypingDer(0)
  }, [showDiscountNumpad])

  const getDiscountValue = (data) => {
    const posSetting = getSettingData(POSSETTINGDATA);
    let elem = document.getElementById("discVal")
    setFirst(false);
    if (data.value === "Enter") {
      // setDiscount();
      if (props?.salesitemList[props?.salesitemList?.length - 1]?.totalDiscount > 0) {
        setError(true);
      } else {
        if (
          !checkUserPermission("apply_discount_hide") ||
          (!isUserAdminstrator() &&
            posSetting?.SALE?.allow_cashier_to_apply_discount !== "1")
        ) {
          setAuth(true);
          setAuthType("apply_discount_hide");
        } else {
          setDiscountValue(0);
          dispatch({
            type: DISCOUNT,
            payload: { symbol: discountSymbol, value: discountValue, newlyAdded: true },
          });
          return;
        }
      }
    }
    if (data.value === "Clear") {
      setDiscountValue(0);
    } else if (data.value === "ArrowLeft") {
      elem.selectionEnd = 0;
      elem.focus()
      setTypingDer(1)
      setCount(0)
      return;
    } else if (data.value === "ArrowRight") {
      elem.selectionStart = discountValue.length;
      elem.focus()
      setTypingDer(0)
      setCount(discountValue.length)
      return;
    } else if (data.type === "DiscountKeypad" && data.value !== "Enter") {
      discountKeyRef.current.focus();
      setCount((prev) => prev + 1);
      setDiscountValue(
        data.value
          ? discountValue > 0
            ? count < discountValue?.length
              ? count == 0
                ? data.value + discountValue
                : discountValue.slice(0, count) + data.value + discountValue.slice(count)
              : discountValue + data.value
            : data.value
          : ""
      );
    }
  };

  useEffect(() => {
    if (sumQtySaleitem.length > 0 && nonCashAdjustMent.enable > 0) {
      var percentageCal = parseFloat(0);
      var minum = parseFloat(0);
      if (nonCashAdjustMent.percentage !== "") {
        percentageCal =
          (parseFloat(sumTotalSaleitem.reduce((a, b) => a + b, 0)) *
            parseFloat(nonCashAdjustMent.percentage)) /
          100;
      }
      if (nonCashAdjustMent.minimun !== "") {
        minum = parseFloat(nonCashAdjustMent.minimun);
      }
      if (percentageCal > minum) {
        setSumNoncashAdjust({
          type: `Per,${nonCashAdjustMent.label},${nonCashAdjustMent.percentage}`,
          value: percentageCal,
        });
      } else {
        setSumNoncashAdjust({
          type: `Val,${nonCashAdjustMent.label},${minum}`,
          value: minum,
        });
      }
    } else {
      setSumNoncashAdjust({ type: "", value: parseFloat(0) });
    }
  }, [sumQtySaleitem, nonCashAdjustMent]);

  useEffect(() => {
    if (props?.salesitemList !== undefined) {
      setSumQtySaleitem(
        props?.salesitemList.map((data) => {
          if (data.qty === "") {
            return qtyFormat(decimalQty, 0);
          } else {
            return qtyFormat(decimalQty, data.qty);
          }
        })
      );
      setSumSubtotal(
        props?.salesitemList.map((data) => {
          if (data.subtotal === "NaN") {
            return parseFloat(0);
          } else {
            return parseFloat(data.subtotal);
          }
        })
      );
      setSumTotalSaleitem(
        props?.salesitemList.map((data) => {
          if (data.total === "NaN") {
            return parseFloat(0);
          } else {
            return parseFloat(data.total);
          }
        })
      );
      setSumtaxTotal(
        props?.salesitemList.map((data) => {
          if (data.taxTotal === "NaN") {
            return parseFloat(0);
          } else {
            return parseFloat(data.taxTotal);
          }
        })
      );
      setSumBottleDeposit(
        props?.salesitemList.map((data) => {
          if (data.bottleDeposit === "NaN") {
            return parseFloat(0);
          } else {
            return parseFloat(data.bottleDeposit);
          }
        })
      );
      setSumAdditionalFee(
        props?.salesitemList.map((data) => {
          if (data.additionalFee === "NaN") {
            return parseFloat(0);
          } else {
            return parseFloat(data.additionalFee);
          }
        })
      );
      setSumEbtTotal(
        props?.salesitemList.map((data) => {
          if (data.ebttotal === "NaN") {
            return parseFloat(0);
          } else {
            return parseFloat(data.ebttotal);
          }
        })
      );
      setEbtAnyChecked(
        props?.salesitemList.filter((data) => data.ebt).length > 0
          ? true
          : false
      );
    }
  }, [props.salesitemList]);

  useEffect(() => {
    let totalAmt = Localstorage.getItem("totalAmount");
    totalAmt = totalAmt || {}
    const data = {
      ...totalAmt,
      Total: sumTotalSaleitem.reduce((a, b) => {
        if (b){
          return a + b
        }
        return a
      }, 0),
      nonCashAdj: parseFloat(sumNoncashAdjust.value),
      nonCashAdjType: sumNoncashAdjust.type,
      EBTTotal:
        nonCashAdjustMent.ebt > 0
          ? `${Number(
            sumEbtTotal.reduce((a, b) => a + b, 0) +
            parseFloat(sumNoncashAdjust.value)
          ).toFixed(2)}`
          : `${Number(sumEbtTotal.reduce((a, b) => a + b, 0)).toFixed(2)}`,
    };
    dispatch(AddTotalpayAmount(data, TOTALAMOUNT));
  }, [nonCashAdjustMent.ebt, sumEbtTotal, sumNoncashAdjust, sumTotalSaleitem]);

  useEffect(() => {
    const renderData = [];
    if (
      props?.AccountSetting?.ADDITIONALFEE ||
      props?.accountSetting?.ADDITIONALFEE
    ) {
      const feeCheck =
        props?.AccountSetting?.ADDITIONALFEE?.feeSetting ||
        props?.accountSetting?.ADDITIONALFEE?.feeSetting;
      setAdditionalFeeCheck(feeCheck);
      renderData.push({ additionalFeeEnable: feeCheck });
    }
    if (
      props?.AccountSetting?.BOTTLEDEPOSIT ||
      props?.accountSetting?.BOTTLEDEPOSIT
    ) {
      const botdt =
        props?.AccountSetting?.BOTTLEDEPOSIT ||
        props?.accountSetting?.BOTTLEDEPOSIT;
      setBottleDeposit(botdt);
      renderData.push({ bottleEnable: botdt });
    }
    if (
      props?.AccountSetting?.NONCASEADJUSTMENT ||
      props?.accountSetting?.NONCASEADJUSTMENT
    ) {
      const nonCash =
        props?.AccountSetting?.NONCASEADJUSTMENT ||
        props?.accountSetting?.NONCASEADJUSTMENT;
      setNonCashAdjustMent(nonCash);
      renderData.push({ nonCashEnable: nonCash });
    }
    if (
      props?.AccountSetting?.TAXRULEDATA ||
      props?.accountSetting?.TAXRULEDATA
    ) {
      setTaxRuleData(
        props?.AccountSetting?.TAXRULEDATA || props?.accountSetting?.TAXRULEDATA
      );
    }
    dispatch(AddDataToReducer(renderData, CUSTOMERSCREENCHECK));
  }, [props.AccountSetting, props.accountSetting]);

  useEffect(() => {
    const symbol =
      props?.PosSetting?.OPTION?.default_discount_type ||
      props?.posSetting?.OPTION?.default_discount_type;
    setDiscountSymbol(symbol === "%" ? false : true);
  }, [props.PosSetting, props.posSetting]);
  return (
    <>
      <ListGroup horizontal className="total-info">
        <ListGroup.Item>
          <h2>Total Items</h2>
          <p>{sumQtySaleitem.reduce((a, b) => a + +b, 0)}</p>
        </ListGroup.Item>
        <ListGroup.Item className=" switch-outer">
          <h2>Enter Discount</h2>
          <div className="d-flex justify-content-between align-items-center enter-discount">
            <div className="custom-numpad">
              <FormField
                id="discVal"
                type="text"
                value={discountValue}
                onChange={(e) => {
                  setDiscountValue(e.target.value);
                }}
                ref={discountKeyRef}
                onClick={(e) => {
                  e.target.select();
                  if (
                    JSON.parse(localStorage.getItem("wpos_config") || "{}")
                      ?.screenKeypad
                  ) {
                    setShowDiscountNumpad(true);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getDiscountValue({ value: "Enter" });
                    setShowDiscountNumpad(false)
                  }
                }}
                placeholder="Enter Discount"
              />
              {showDiscountNumpad && (
                <CustomNumpad
                  type="DiscountKeypad"
                  setValue={getDiscountValue}
                  hideNumpad={setShowDiscountNumpad}
                />
              )}
            </div>
            <div className="custom-switch-box">
              <Switch
                onColor="#fff"
                isOn={discountSymbol}
                handleToggle={() => setDiscountSymbol(!discountSymbol)}
              />
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item>
          <h2>Subtotal</h2>
          <p>{currencyFormat(sumSubtotal.reduce((a, b) => a + b, 0))}</p>
        </ListGroup.Item>
        <ListGroup.Item>
          <h2>Tax</h2>
          <p>{currencyFormat(sumTaxTotal.reduce((a, b) => a + b, 0))}</p>
        </ListGroup.Item>
        {botteDeposit?.enable > 0 && (
          <ListGroup.Item>
            <h2>
              {botteDeposit?.lable && botteDeposit?.lable !== ""
                ? botteDeposit.lable
                : "Deposit"}
            </h2>
            <p>{currencyFormat(sumBottleDeposit.reduce((a, b) => a + b, 0))}</p>
          </ListGroup.Item>
        )}
        {additionalFeeCheck > 0 && (
          <ListGroup.Item>
            <h2>Additional Fees:</h2>
            <p>{currencyFormat(sumAdditionalFee.reduce((a, b) => a + b, 0))}</p>
          </ListGroup.Item>
        )}
        {nonCashAdjustMent?.enable > 0 && (
          <ListGroup.Item>
            <h2>
              {nonCashAdjustMent?.label && nonCashAdjustMent?.label !== ""
                ? nonCashAdjustMent.label
                : "Non-cash Adj"}
            </h2>
            <p>{currencyFormat(sumNoncashAdjust.value)}</p>
          </ListGroup.Item>
        )}
        <ListGroup.Item>
          <h2>
            {nonCashAdjustMent?.enable > 0 && nonCashAdjustMent?.label !== ""
              ? nonCashAdjustMent.label + " Total"
              : "Total"}
          </h2>
          <p>
            {currencyFormat(
              sumTotalSaleitem.reduce((a, b) => a + b, 0) +
              parseFloat(sumNoncashAdjust.value)
            )}
          </p>
        </ListGroup.Item>
        {taxRuleData.deafultEbtTaxCheck > 0 && checkedEbtAny && (
          <ListGroup.Item>
            <h2>EBT Total</h2>
            <p>
              {nonCashAdjustMent.ebt > 0
                ? `${currencyFormat(
                  sumEbtTotal.reduce((a, b) => a + b, 0) +
                  parseFloat(sumNoncashAdjust.value)
                )}`
                : `${currencyFormat(sumEbtTotal.reduce((a, b) => a + b, 0))}`}
            </p>
          </ListGroup.Item>
        )}
      </ListGroup>

      {/* ask auth */}

      <CustomModal
        open={auth}
        handleClose={() => setAuth(false)}
        dialogClassName="custom-modal  with-border-bottom authorization"
        title="Authorization"
        content={
          <Auth
            setAuthSuccess={setAuthSuccess}
            userlist={props.userlist}
            type={authType}
          />
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5"
              onClick={() => setAuth(false)}
            >
              Cancel
            </Button>
          </>
        }
      />

      <CustomModal
        open={error}
        handleClose={() => setError(false)}
        dialogClassName="custom-modal confirmation error-popup"
        title="Error"
        content={<h1 className="mb-3">{"Discount already applied, Please remove previous discount first to apply new discount."}</h1>}
        action={
          <Button
            type="button"
            onClick={() => setError(false)}
            variant="danger px-5 m-auto"
          >
            OK
          </Button>
        }
      />
    </>
  );
};
TotalInfo.propTypes = {
  PosSetting: PropTypes.shape({
    SALE: PropTypes.shape({
      allow_decimal_qty_sale: PropTypes.bool,
      maximum_discount_limit_per_sale_type: PropTypes.number,
    }),
  }),
  PosSetting: PropTypes.shape({
    OPTION: PropTypes.shape({
      default_discount_type: PropTypes.string,
    }),
  }),

};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    salesitemList: state.saleItem.saleItemList,
    totalAmount: state.saleItem.totalAmount,
    accountSetting: state.inventory.accountSetting.data,
    posSetting: state.inventory.posSetting.data,
    userlist: state.inventory.userList,
    customerScreen: state.saleItem.customerScreen,
    taxlist: state.inventory.taxRule,
    taxCount: state.inventory.tax,
  };
};

export default connect(mapStateToprops)(TotalInfo);
