import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import {
  IMAGEUPLOAD,
  SLIDESHOWFORPOSSCREEN,
  SLIDRESHOWIMAGES,
  UPLOADFIXEDIMAGE,
  UPLOADSTATICIMAGEFORNUMBER,
} from "../../../app/helpers/constant";
import AskForNumber from "./AskForNumber";
import CustomerDetail from "./CustomerDetail";
import SliderMain from "./slider";
import { getListActionFunction } from "../../../app/actions/getListAction";
import { getDeviceAndStore } from "../../../axios/authHeader";
import Localstorage from "app/storage/Localstorage";
// import "./scss/volt.scss";

const CustomerScreenComponents = () => {
  const [isComponentVisible, setComponentVisibility] = useState(false);
  const storeId = getDeviceAndStore()[1];

  useEffect(() => {
    const general_setting = bussinesName;
    // set app type in local storage
    localStorage.setItem("appType", general_setting?.BUSINESS?.logo_name);
    const body = document.body;
    body.classList.remove("dark-mode");
    // Default css loading for selected app type
    const importScss = async () => {
      if (general_setting?.BUSINESS?.logo_name === "CigarPOS") {
        await import("../../../scss/volt-cigar.scss");
      } else if (general_setting?.BUSINESS?.logo_name === "CStore") {
        await import("../../../scss/volt-cstore.scss");
      } else if (
        general_setting?.BUSINESS?.logo_name === "BottlePOS" ||
        general_setting?.BUSINESS?.logo_name === "PNCstore" ||
        general_setting?.BUSINESS?.logo_name === "PNLiquor" ||
        general_setting?.BUSINESS?.logo_name === "PNTobacco" ||
        general_setting?.BUSINESS?.logo_name === "PNRetail"
      ) {
        await import("../../../scss/volt.scss");
        if (
          general_setting?.BUSINESS?.logo_name === "PNCstore" ||
          general_setting?.BUSINESS?.logo_name === "PNLiquor" ||
          general_setting?.BUSINESS?.logo_name === "PNTobacco" ||
          general_setting?.BUSINESS?.logo_name === "PNRetail"
        ) {
        } else {
        }
      } else {
        await import("../../../scss/volt.scss");
      }
      // Do something after the SCSS file is imported
      document.body.classList.remove("dark-mode");
      setComponentVisibility(true);
    };
    importScss();
  }, [bussinesName]);

  const dispatch = useDispatch();
  const [posSetting, setPosSetting] = useState(
    JSON.parse(localStorage.getItem("posSetting"))
  );
  const [displayType, setDisplayType] = useState(1);
  const [listData, setListData] = useState(
    JSON.parse(localStorage.getItem("saleitems"))
  );

  const [posSideSetting, setPosSideSetting] = useState(
    JSON.parse(localStorage.getItem("wpos_config") || "{}")
  );
  const [customerDetails, setCustomerDetails] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [payments, setPayment] = useState([]);
  const [calculation, setCalculation] = useState(
    JSON.parse(localStorage.getItem("customerScreen"))
  );
  const [nonCashAdj, setNonCashAdj] = useState(
    JSON.parse(localStorage.getItem("totalAmount"))
  );
  const [bussinesName, setBussinessName] = useState(
    JSON.parse(localStorage.getItem("generalSetting"))
  );
  const [displayData, setDisplayData] = useState([]);
  const [hideCustomerNumPad, setCustomerNumPadHide] = useState(false);
  const [noThanksButton, setNoThanksButton] = useState(false);
  const [Images, setImages] = useState(localStorage.getItem("images"));
  const [ , setPromoData] = useState({});

  useEffect(() => {
    if (Object.entries(posSetting)?.length > 0) {
      setDisplayType(posSetting?.CUSTOMER?.customer_display_type || 1);
      setDisplayData(posSetting?.CUSTOMER);
    }
  }, [posSetting]);

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      setImages(localStorage.getItem("images"));

      setPayment(JSON.parse(localStorage.getItem("payment")) || []);
      let checkout = Localstorage.getItem("checkOut");
      if (!checkout) {
        setNonCashAdj(JSON.parse(localStorage.getItem("totalAmount")) || []);
        setListData(JSON.parse(localStorage.getItem("saleitems")) || []);
        setCalculation(
          JSON.parse(localStorage.getItem("customerScreen")) || []
        );
        setCustomerDetails(
          JSON.parse(localStorage.getItem("sale_customer")) || {}
        );
        setPromoData(JSON.parse(localStorage.getItem("promotion_data")) || {});
      }

      setPosSetting(JSON.parse(localStorage.getItem("posSetting")) || []);
      setBussinessName(
        JSON.parse(localStorage.getItem("generalSetting")) || []
      );
      setPosSideSetting(JSON.parse(localStorage.getItem("wpos_config")) || {});
      if (
        event.key === "sale_customer" &&
        (event.newValue == null || event.newValue === "null")
      ) {
        setCustomerNumPadHide(false);
        localStorage.setItem("hideNumPad", false);
      }
    });
  }, []);

  useEffect(() => {
    if (!listData.length) {
      setCustomerNumPadHide(false);
    }
  }, [listData]);

  useEffect(() => {
    setNoThanksButton(parseFloat(nonCashAdj?.Total || 0) > 0);
  }, [nonCashAdj]);

  useEffect(() => {
    if (Object.entries(customerDetails).length) {
      setCustomerNumPadHide(true);
    } else {
      if (!hideCustomerNumPad) {
        setCustomerNumPadHide(false);
      }
    }
  }, [customerDetails]);

  useEffect(() => {
    setTimeout(() => {
      handleFontSizeChange(posSideSetting?.customerFont);
      handleCustomerScreenZoom(posSideSetting?.customerScreenZoom);
    }, [1]);
  }, [posSideSetting]);

  // Customer screen font size change
  const handleFontSizeChange = (selectedOption) => {
    const fontSize = selectedOption ? selectedOption + "px" : null;
    // Get all elements in the DOM
    let allElements = document
      .getElementById("item-table")
      ?.querySelectorAll("*");
    allElements = allElements || [];
    const body = document.getElementsByTagName("body")[0];
    // Remove the existing font-size class
    const fontClasses = body.classList;
    for (let i = fontClasses.length - 1; i >= 0; i--) {
      if (fontClasses[i].startsWith("font-size-")) {
        body.classList.remove(fontClasses[i]);
      }
    }
    // Add the new font-size class if a valid font size is selected
    if (fontSize) {
      body.classList.add("font-size-" + fontSize);
    }
    // Loop through each element and update the font size accordingly
    allElements.forEach((element) => {
      element.style.fontSize = fontSize;
    });
  };

  const handleCustomerScreenZoom = (selectedOption) => {
    const zoomSize = selectedOption ? selectedOption + "%" : null;

    try {
      const targetDiv = document.querySelector("#item-table");
      const body = document.getElementsByTagName("body")[0];

      // If targetDiv or body is not found, throw an error
      if (!targetDiv || !body) {
        throw new Error("Required elements not found in the DOM.");
      }

      // Reset the zoom size
      targetDiv.style.zoom = "";

      // Remove the existing zoomSize class
      const zoomClasses = body.classList;
      for (let i = zoomClasses.length - 1; i >= 0; i--) {
        if (zoomClasses[i].startsWith("zoomSize-")) {
          body.classList.remove(zoomClasses[i]);
        }
      }

      // Add the new zoomSize class if a valid zoom size is selected
      if (zoomSize) {
        body.classList.add("zoomSize-" + zoomSize);
        targetDiv.style.zoom = zoomSize;
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
    }
  };

  useEffect(() => {
    let imgs = [];
    try {
      imgs = JSON.parse(Images);
    } catch (e) {
      imgs = [];
    }
    let tempVar = {};
    if (imgs && Array.isArray(imgs)) {
      imgs.map((elem) => {
        if (
          elem.name === UPLOADFIXEDIMAGE ||
          elem.name === SLIDRESHOWIMAGES ||
          elem.name === UPLOADSTATICIMAGEFORNUMBER ||
          elem.name === SLIDESHOWFORPOSSCREEN
        ) {
          if (Object.keys(tempVar).includes(elem.name)) {
            tempVar[elem.name].push(elem.file);
          } else {
            tempVar[elem.name] = [elem.file];
          }
        }
      });
    }
    setImageList(tempVar);
  }, [Images]);

  useEffect(() => {
    dispatch(getListActionFunction(IMAGEUPLOAD, storeId, IMAGEUPLOAD));
  }, [storeId]);

  useEffect(() => {
    if (listData.length <= 0 && displayType === 2) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [listData, displayType]);

  return (
    <>
      {isComponentVisible && (
        <>
          {listData.length <= 0 &&
            (displayType === 3 ||
              displayType === 1 ||
              displayType === 4 ||
              displayType === 5) && (
              <AskForNumber
                noThanksButton={noThanksButton}
                setCustomerNumPadHide={setCustomerNumPadHide}
                hideCustomerNumPad={hideCustomerNumPad}
                posSettingData={displayData}
                bussines={bussinesName?.BUSINESS}
                cond={displayType}
                image={imageList}
              />
            )}
          {listData.length > 0 && (
            <CustomerDetail
              noThanksButton={noThanksButton}
              setCustomerNumPadHide={setCustomerNumPadHide}
              hideCustomerNumPad={hideCustomerNumPad}
              bussines={bussinesName?.BUSINESS}
              posSideSetting={posSideSetting || {}}
              posSetting={posSetting}
              OrderData={listData}
              customerScreen={calculation}
              nonCashAdjust={nonCashAdj}
              customer={customerDetails}
              image={imageList}
              posSettingData={displayData}
              withoutitemdisplayType={displayType}
              payments={payments}
            />
          )}
          {listData.length <= 0 && displayType === 2 && (
            <SliderMain
              image={
                imageList && imageList[SLIDRESHOWIMAGES]
                  ? imageList[SLIDRESHOWIMAGES]
                  : []
              }
              posSettingData={displayData}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    storeId: state.auth.storeId,
  };
};

export default connect(mapStateToProps)(CustomerScreenComponents);
