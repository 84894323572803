import React, { useEffect, useState } from "react";
import ReactSelect, { components } from "react-select";
import { CHANGE_FONTSIZE } from "../../app/helpers/constant";
import { useDispatch } from "react-redux";

const FontSizesPos = () => {
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const dispatch = useDispatch();

  const FontSize = [12, 13, 14, 15, 16, 17, 18]; // Example font size options

  const handleFontSizeChange = (selectedOption) => {
    setSelectedFontSize(selectedOption);

    // Remove previous font size class from body
    document.body.classList.remove(localStorage.getItem("fontSizeClass"));

    // Add new font size class to body
    const fontSizeClass = `font-size-${selectedOption.value}`;
    if (selectedOption.value !== "default") {
      document.body.classList.add(fontSizeClass);
    }
    localStorage.setItem("fontSizeClass", fontSizeClass);

    // Store the font size class in local storage

    // Dispatch action to update font size in Redux store
    dispatch({
      type: CHANGE_FONTSIZE,
      payload: selectedOption.value,
    });
  };

  const fontSizeOptions = [
    { value: "default", label: "Default" },
    ...FontSize.map((item) => ({ value: item, label: item + "px" })),
  ];
  const [selectedFontSize, setSelectedFontSize] = useState(fontSizeOptions[0]);
  // console.log("Selected Font Size:", selectedFontSize);
  // console.log("Font Size Options:", fontSizeOptions);

  useEffect(() => {
    const cachedFontSizeClass = localStorage.getItem("fontSizeClass");
    if (cachedFontSizeClass) {
      // Apply the cached font size class to the body element
      document.body.classList.add(cachedFontSizeClass);

      // Retrieve the font size value from the cached class name
      const fontSizeValue = cachedFontSizeClass.replace("font-size-", "");

      // Set the selected font size
      setSelectedFontSize({
        value: fontSizeValue,
        label: fontSizeValue === "default" ? "Default" : fontSizeValue + "px",
      });

      // Dispatch action to update font size in Redux store
      dispatch({
        type: CHANGE_FONTSIZE,
        payload: fontSizeValue,
      });
    }
  }, []);

  return (
    <ReactSelect
      components={{
        ValueContainer: CustomValueContainer,
      }}
      styles={{
        valueContainer: (provided, state) => ({
          ...provided,
          overflow: "visible",
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? -15 : "7px",
          transition: "top 0.1s, font-size 0.1s",

          transform:
            (state.hasValue || state.selectProps.inputValue) && "scale(0.85)",
          opacity: (state.hasValue || state.selectProps.inputValue) && 1,
        }),
      }}
      value={selectedFontSize}
      classNamePrefix="react-select"
      onChange={handleFontSizeChange}
      options={fontSizeOptions}
      // defaultValue={fontSizeOptions[0]}
      placeholder="POS Screen Font Size"
    />
  );
};

export default FontSizesPos;
