import React, { useEffect, useState } from "react";
import { Card, Fade, Image } from "@themesberg/react-bootstrap";

// Other imports
import BootstrapTable2 from "../components/BootsrapTable2";
import { connect } from "react-redux";
import {
  currencyFormat,
  FirtsLetterUpperCase,
  getTodaysRecords,
  getTransactionStatus,
} from "../../app/helpers/custom";
import { getSettingData } from "../../axios/authHeader";
import CustomScroll from "react-custom-scroll";

const SellerReports = (props) => {
  const [sellerReportData, setSellerReportData] = useState({});
  const [sellerReport, setSellerReport] = useState([]);
  useEffect(() => {
    if (props.reportlist.length > 0) {
      setSellerReportData(getSellerStats(props.reportlist));
    }
  }, [props.reportlist]);

  useEffect(() => {
    if (Object.entries(sellerReportData).length > 0) {
      let userId = JSON.parse(localStorage.getItem("user"))
      let sellerObj = { ...sellerReportData }
      if (localStorage.getItem("off_sale") != null) {
        let sales = JSON.parse(localStorage.getItem("off_sale"));
        for (let sale of sales) {
          if (sellerObj[userId?.data?.id]) {
            sellerObj[userId?.data?.id].salerefs.push(sale?.ref)
            sellerObj[userId?.data?.id].saletotal = +sellerObj[userId?.data?.id]?.saletotal + +sale?.total
            sellerObj[userId?.data?.id].salenum += 1
            sellerObj[userId?.data?.id].balance = +sellerObj[userId?.data?.id]?.balance + +sale?.total
          }
        }
      }
      if (localStorage.getItem("off_refund_sales") != null) {
        let off_refund_sales = JSON.parse(localStorage.getItem("off_refund_sales"))
        for (let sale of off_refund_sales) {
          if (sellerObj[userId?.data?.id]) {
            sellerObj[userId?.data?.id].refrefs.push(sale?.formData?.ref)
            sellerObj[userId?.data?.id].reftotal = +sellerObj[userId?.data?.id]?.reftotal + +sale?.formData?.amount
            sellerObj[userId?.data?.id].refnum += 1
            sellerObj[userId?.data?.id].balance = +sellerObj[userId?.data?.id]?.balance - +sale?.formData?.amount
          }
        }
      }

      if (localStorage.getItem("off_void_sales") != null) {
        let off_void_sales = JSON.parse(localStorage.getItem("off_void_sales"))
        for (let sale of off_void_sales) {
          if (sellerObj[userId?.data?.id]) {
            sellerObj[userId?.data?.id].voidrefs.push(sale?.formData?.ref)
            sellerObj[userId?.data?.id].voidtotal = +sellerObj[userId?.data?.id]?.voidtotal + +sale?.formData?.amount
            sellerObj[userId?.data?.id].voidnum += 1
            sellerObj[userId?.data?.id].balance = +sellerObj[userId?.data?.id]?.balance - +sale?.formData?.amount
          }
        }
      }
      setSellerReport(renderOnTableData(sellerObj));
    }
  }, [sellerReportData]);

  function renderOnTableData(stats) {
    const returnArray = [];
    let item;
    const users = getSettingData("usersList");
    var i = 1;
    for (const id in stats) {
      item = stats[id];
      const user =
        users.length > 0
          ? users.filter((user) => user.id === parseInt(id))[0].username
          : "Unknown";
      returnArray.push({
        id: i,
        User: FirtsLetterUpperCase(user),
        Sales: currencyFormat(item.saletotal) + " (" + item.salenum + ")",
        Voids: currencyFormat(item.voidtotal) + " (" + item.voidnum + ")",
        Refunds: currencyFormat(item.reftotal) + " (" + item.refnum + ")",
        Balance: currencyFormat(item.balance),
      });
      i++;
    }
    return returnArray;
  }

  function getSellerStats(saleList) {
    var sales = getTodaysRecords(true, saleList);
    var sale;
    var stime = new Date();
    var etime = new Date();
    stime.setHours(0);
    stime.setMinutes(0);
    stime.setSeconds(0);
    stime = stime.getTime();
    etime.setHours(23);
    etime.setMinutes(59);
    etime.setSeconds(59);
    etime = etime.getTime();
    var data = {};
    var salestat;
    for (var key in sales) {
      sale = sales[key];
      salestat = sale.status;
      var userid;
      switch (salestat) {
        case 3:
          userid = sale?.salevoid[0]?.user_id;
          if (data.hasOwnProperty(userid)) {
            data[userid].voidrefs.push(sale.ref);
            data[userid].voidnum++;
            data[userid].voidtotal += parseFloat(sale.total);
          } else {
            data[userid] = {};
            data[userid].salerefs = [];
            data[userid].salenum = 0;
            data[userid].saletotal = 0;
            data[userid].refrefs = [];
            data[userid].refnum = 0;
            data[userid].reftotal = 0;
            data[userid].voidrefs = [sale.ref];
            data[userid].voidnum = 1;
            data[userid].voidtotal = parseFloat(sale.total);
          }
          break;
        case 2:
          // cycle though all refunds and add to total
          for (var i in sale.salevoid) {
            var amount = parseFloat(sale.salevoid[i].amount);
            userid = sale.salevoid[i].user_id;
            if (data.hasOwnProperty(userid)) {
              data[userid].refrefs.push(sale.ref);
              data[userid].refnum++;
              data[userid].reftotal += parseFloat(amount);
            } else {
              data[userid] = {};
              data[userid].salerefs = [];
              data[userid].salenum = 0;
              data[userid].saletotal = 0;
              data[userid].refrefs = [sale.ref];
              data[userid].refnum = 1;
              data[userid].reftotal = parseFloat(amount);
              data[userid].voidrefs = [];
              data[userid].voidnum = 0;
              data[userid].voidtotal = 0;
            }
          }
          // count refund as a sale, but only if it was sold today
          if (sale.processdt < stime || sale.processdt > etime) {
            break; // the sale was not made today
          }
        case 1:
          var extracosttotal = 0;
          if (sale.hasOwnProperty("extracosts") && sale.extracosts.length > 0) {
            for (var i = 0; i < sale.extracosts.length; i++) {
              var extracost = sale.extracosts[i];
              extracosttotal += extracost.charge;
            }
          }
          if (data.hasOwnProperty(sale.userid)) {
            data[sale.userid].salerefs.push(sale.ref);
            data[sale.userid].salenum++;
            data[sale.userid].saletotal +=
              parseFloat(sale.total) - parseFloat(extracosttotal);
          } else {
            data[sale.userid] = {};
            data[sale.userid].salerefs = [sale.ref];
            data[sale.userid].salenum = 1;
            data[sale.userid].saletotal =
              parseFloat(sale.total) - parseFloat(extracosttotal);
            data[sale.userid].refrefs = [];
            data[sale.userid].refnum = 0;
            data[sale.userid].reftotal = 0;
            data[sale.userid].voidrefs = [];
            data[sale.userid].voidnum = 0;
            data[sale.userid].voidtotal = 0;
          }
      }
    }
    for (var x in data) {
      data[x].balance = (data[x].saletotal - data[x].reftotal)?.toFixed(2);
      data[x].saletotal = data[x].saletotal?.toFixed(2);
      data[x].reftotal = data[x].reftotal?.toFixed(2);
      data[x].voidtotal = data[x].voidtotal?.toFixed(2);
    }

    return data;
  }

  const columns = [
    {
      dataField: "User",
      text: "User",
      sort: true,
      attrs: {
        "data-title": "User",
      },
    },
    {
      dataField: "Sales",
      text: "Sales",
      sort: true,
      attrs: {
        "data-title": "Sales",
      },
    },
    {
      dataField: "Voids",
      text: "Voids",
      sort: true,
      attrs: {
        "data-title": "Voids",
      },
    },
    {
      dataField: "Refunds",
      text: "Refunds",
      sort: true,
      attrs: {
        "data-title": "Refunds",
      },
    },
    {
      dataField: "Balance",
      text: "Balance",
      sort: true,
      attrs: {
        "data-title": "Balance",
      },
    },
  ];

  // Expand row
  const size = useWindowSize();
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: "0 px",
      height: "0 px",
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const expandRow = {
    renderer: (row) => (
      <Fade appear={true} in={true}>
        <ul>
          <li>
            <span className="dtr-title">Refunds</span>
            <span className="dtr-data">{row.Refunds || "-"}</span>
          </li>
          <li>
            <span className="dtr-title">Balance</span>
            <span className="dtr-data">{row.Balance || "-"}</span>
          </li>
        </ul>
      </Fade>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return expendColorShow(isAnyExpands);
    },
    expandColumnRenderer: ({ expanded }) => {
      return expendColorShow(expanded);
    },
  };
  const expendColorShow = (value) => {
    if (value) {
      return (
        <Image
          className="expand-row-icon"
          src={props?.dynamicImages?.minusIcon}
          alt="minus icon"
        />
      );
    }
    return (
      <Image
        className="expand-row-icon"
        src={props?.dynamicImages?.plusIcon}
        alt="plus icon"
      />
    );
  };

  const expandRow2 = {
    renderer: () => { },
  };

  return (
    <>
      <Card className="custom-card no-checkbox no-box-shadow border-0 report-with-scroll  column-visibility-table auto-width seller-table">
        <div className=" with-scroll-on-table">
          <CustomScroll
            addScrolledClass
            heightRelativeToParent="calc(100% - 0px)"
            className={
              size.width !== undefined && size.width >= 1 && size.width <= 576
                ? "expand-table-row "
                : ""
            }
          >
            <BootstrapTable2
              columns={columns}
              data={sellerReport}
              keyField="id"
              noDataIndication={sellerReport.length === 0 ? true : false}
              expandRow={
                size.width !== undefined && size.width >= 1 && size.width <= 576
                  ? expandRow
                  : expandRow2
              }
            />
          </CustomScroll>
        </div>
      </Card>
    </>
  );
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    reportlist: state.inventory.reportList,
  };
};
export default connect(mapStateToprops)(SellerReports);
