import Dexie from "dexie";

export const db = new Dexie("WPOS");
  db.version(1).stores({
    wpos_items:
      "++id, name, item_size, default_qty, category, supplier, tax, promptforqty, donotdiscount,donottrackinventory,ebteligible,hideinventory,autoupdate,closeoutitem,printlabel,shortcutkeys,showtoweb,totalitem,itemdata,cases,vendoritemno,sku,unit_per_case,reorder_point,reorder_value,item_rank,item_name,price,data,case_cash_total,item_shortcut_no,item_shortcut_name,bottle_deposit,color_code,item_type,remind_date,code,tag,fee,store,user_id",
    wpos_customer:
      "++id,mobile,dob,email,address,zipcode,points,sms_email,house_acc_eligible,maximum_amount",
    wpos_csales:
      "++id, total, rounding, promocouponcount, promocoupondisc, isroundingenable, extracosts, ref, userid,device_id,custid,custemail,custname,custphone,cost,subtotal,numitems,processdt,payments,codes,payout,discountamt,multipleDiscounts,discountvalue,discountindex,customerage,customerdob,discount,discount_amountvalue,totaltendered,promotions,additionalcharges,manualpriceedit,totalLoyaltyPoints,rating,scanpromocouponcode,tax,taxdata,items,store_id,status,register_id",
  });