import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Form,
  Image,
  InputGroup,
  Fade,
} from "@themesberg/react-bootstrap";
import { Type } from "react-bootstrap-table2-editor";
import { salestable } from "../../data/tables";
import BootstrapTable2 from "../components/BootsrapTable2";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import add from "../../assets/images/add.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "../components/Modals";
import TaskDetails from "./task-details";
import EyeIconCLosed from "../../assets/images/eye-btn-closed.svg";
import { connect } from "react-redux";

const TaskListDetails = (props) => {
  const [show, setShow] = useState(false);
  const [dynamicImages, setDynamicImages] = useState(null);
  useEffect(() => {
    setDynamicImages(props.dynamicImages);
  }, [props.dynamicImages]);
  const handleDialogChange = () => setShow(!show);
  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          onClick={handleDialogChange}
          className="p-0"
          variant="link"
          title="Add total"
        >
          <Image src={EyeIconCLosed} alt="Eye Icon" />
        </Button>
      </>
    );
  };
  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {row.status === "true" ? (
          <Badge bg="seagreen">Enabled</Badge>
        ) : (
          <Badge bg="blackcustom">Disabled</Badge>
        )}
      </>
    );
  };

  const columns = [
    {
      dataField: "TaskList",
      text: "Task List",
      sort: true,
      attrs: {
        "data-title": "Task List",
      },
    },
    {
      dataField: "DateTime",
      text: "Date & Time",
      sort: true,
      attrs: {
        "data-title": "Date & Time",
      },
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      attrs: {
        "data-title": "Status",
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: actionsFormatter,
      attrs: {
        "data-title": "Actions",
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size}
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: false, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: salestable.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  // Expand row
  const size = useWindowSize();
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: "0 px",
      height: "0 px",
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const expandRow = {
    renderer: (row) => (
      <Fade appear={true} in={true}>
        <ul>
          <li>
            <span className="dtr-title">Date & Time</span>
            <span className="dtr-data">{row.DateTime || "-"}</span>
          </li>
          <li>
            <span className="dtr-title">Status</span>
            <span className="dtr-data">{statusFormatter(null, row)}</span>
          </li>
        </ul>
      </Fade>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return expendColorShow(isAnyExpands);
    },
    expandColumnRenderer: ({ expanded }) => {
      return expendColorShow(expanded);
    },
  };
  const expendColorShow = (value) => {
    if (value) {
      return (
        <Image
          className="expand-row-icon"
          src={props?.dynamicImages?.minusIcon}
          alt="minus icon"
        />
      );
    }
    return (
      <Image
        className="expand-row-icon"
        src={props?.dynamicImages?.plusIcon}
        alt="plus icon"
      />
    );
  };

  const expandRow2 = {
    renderer: () => {},
  };
  return (
    <>
      <div className="custom-data-table">
        <div className="custom-card card no-box-shadow no-checkbox task-list-details border-0 with-scroll-on-table task-detail-table">
          <div
            className={
              size.width !== undefined && size.width >= 1 && size.width <= 576
                ? "expand-table-row "
                : ""
            }
          >
            <BootstrapTable2
              columns={columns}
              data={salestable}
              keyField="id"
              pagination={true}
              exportCSVToggle={false}
              SearchBarToggle={true}
              // selectRowToggle={false}
              cellEditToggle={false}
              PaginationOptions={options}
              expandRow={
                size.width !== undefined && size.width >= 1 && size.width <= 576
                  ? expandRow
                  : expandRow2
              }
            />
          </div>
        </div>
      </div>
      <CustomModal
        backdrop="static"
        keyboard={false}
        open={show}
        handleClose={handleDialogChange}
        dialogClassName="custom-modal with-border-bottom"
        title="Task  Details"
        size="lg"
        content={<TaskDetails />}
        action={
          <>
            <Button
              variant="outline-blackcustom px-5"
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
  };
};
export default connect(mapStateToprops)(TaskListDetails);
