/*
  File name : auth.js
  This file is responsible for check permission and authencate
*/

//imports

// import all required modile/hooks of react js
import React, { useEffect, useState } from "react";
import { Button, Form } from "@themesberg/react-bootstrap";

// import all the component/module related to the theme
import ReactSelect, { components } from "react-select";
import FormField from "pages/components/formField";
import { toast } from "react-toastify";

// import all third party module/helpers/services
import { authUserId, getUser, checkUserPermission } from "axios/authHeader";
import authServices from "app/services/authServices";

// import constants
import { INVALID_CREDENTISLD } from "app/helpers/constant";
import PropTypes from 'prop-types';

export const Auth = ({ setAuthSuccess, userlist, type }) => {
  const { ValueContainer, Placeholder } = components;

  //Defining the state for user info
  const [userId, setUserId] = useState(authUserId() || null);
  const [password, setPassword] = useState(null);
  const [allUserList, setAllUserList] = useState([]);

  useEffect(() => {
    return () => {
      setAuthSuccess(false);
    };
  }, []);

  // Modifing and filtering the user list for the react select accordingly
  useEffect(() => {
    let ulist = userlist?.filter((e) => e.master_store_id == getUser().master_store_id)
      .filter((e) => {
        if (type) {
          if (checkUserPermission(type, e)) {
            return e;
          }
        } else {
          return e;
        }
      })
      .map((list) => {
        return {
          ...list,
          value: list.id,
          label: list.username,
        };
      })
      .filter((e) => {
        if (e.permission) {
          return !e.permission["6"]?.allDisablePermission;
        } else {
          return e.store_owner === 1;
        }
      });
    setAllUserList(ulist);

    return () => {
      setAllUserList([]);
    };
  }, [userlist]);

  // Handle the auth and set the authorization to true/false for selected user
  const handleAuth = async (e) => {
    e.preventDefault();
    let username = userId?.username;
    const masterId = allUserList?.filter((e) => e.username === username)[0]
      .master_store_id;
    if (password && username) {
      try {
        let response = await authServices.AuthUser(
          username,
          password,
          masterId
        );
        if (response.status === 200) {
          setAuthSuccess(true);
        } else {
          toast.error(INVALID_CREDENTISLD);
          return false;
        }
      } catch (e) {
        toast.error(INVALID_CREDENTISLD);
        return false;
      }
    } else {
      toast.error(INVALID_CREDENTISLD);
    }
  };

  // Defining the custom value container for react select
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  return (
    <>
      <Form.Group className="custom-react-select">
        <ReactSelect
          components={{
            ValueContainer: CustomValueContainer,
          }}
          styles={{
            valueContainer: (provided) => ({
              ...provided,
              overflow: "visible",
            }),
            placeholder: (provided, state) => ({
              ...provided,
              position: "absolute",
              top: state.hasValue || state.selectProps.inputValue ? -15 : "7px",
              transition: "top 0.1s, font-size 0.1s",

              transform:
                (state.hasValue || state.selectProps.inputValue) &&
                "scale(0.85)",
              opacity: (state.hasValue || state.selectProps.inputValue) && 1,
            }),
          }}
          classNamePrefix="react-select"
          options={allUserList}
          value={userId}
          onChange={(e) => {
            setUserId(e);
          }}
          placeholder={"Staff Name"}
          placement={"top"}
          isSearchable={false}
        />
      </Form.Group>

      <Form.Group className="form-floating mt-4">
        <FormField
          label="Password"
          type="password"
          name="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
      </Form.Group>
      <Button
        variant="primary px-4  position-absolute auth-btn form-cta-btn"
        onClick={(e) => handleAuth(e)}
      >
        Authorize
      </Button>
    </>
  );
};
Auth.propTypes = {
  setAuthSuccess: PropTypes.func,
  userlist: PropTypes.array,
  type: PropTypes.string, 
  children: PropTypes.node, 
  isFocused: PropTypes.bool,
  selectProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
};