import React from "react";
import "./switch.scss";
import PropTypes from 'prop-types';

const Switch = ({ isOn, handleToggle }) => {
  return (
    <label  className="react-switch">
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        type="checkbox"
      />
      <div className="react-switch-button" />
      <div className="react-switch-labels">
        <span>%</span>
        <span>$</span>
      </div>
    </label>
  );
};
Switch.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  onColor: PropTypes.string,
};
export default Switch;
