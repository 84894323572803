import React, { useState, useRef, useEffect } from "react";
import { Button, Fade, Image } from "@themesberg/react-bootstrap";

// Data tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import CustomScroll from "react-custom-scroll";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  DEVICE,
  REPORTTRNSACTION,
  STAFFANDADMIN,
  STORE,
} from "../../app/helpers/constant";
import CustomModal from "../components/Modals";
import TransactionDetails from "../TransactionDetails/TransactionDetails";
import {
  currencyFormat,
  dateFormate,
  FirtsLetterUpperCase,
} from "../../app/helpers/custom";
import { getListActionFunction } from "../../app/actions/getListAction";
import { confirmedResponseAction } from "../../app/actions/saleItemAction";
import LoaderSpinner from "../../components/Loader";
import { authUserName, getDeviceAndStoreName } from "../../axios/authHeader";

const ReportTransactionTable = (props) => {
  const dispatch = useDispatch();
  const [SelectedRow, setSelectedRow] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [transectionDetails, setTransectionDetails] = useState({});
  const handleDialogChange = () => setShow(!show);
  const handleDialogChange1 = () => setShow1(!show);
  const handleDialogClose = () => {
    setShow(false);
  };
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  let ref = useRef(null);

  useEffect(() => {
    dispatch(confirmedResponseAction({ data: { sale: [] } }, REPORTTRNSACTION));
  }, []);

  useEffect(() => {
    getTransaction(props.data);
  }, [props?.storeId, props?.refs]);

  useEffect(() => {
    setListData(
      props?.reportTransaction?.length
        ? UpdateArray(props?.reportTransaction)
        : []
    );
  }, [
    props?.reportTransaction,
    props?.staff_admin,
    props?.devices,
    props?.stores,
  ]);

  const UpdateArray = (array) => {
    return array.map((data) => {
      let customer = undefined;
      if (data.custid > 0 && data.customer) {
        try {
          customer = JSON.parse(data?.customer);
        } catch (e) {
          customer = undefined;
        }
      }
      return {
        ...data,
        GID: data?.id,
        Ref: data?.ref?.split("-")[2],
        DeviceStore: FirtsLetterUpperCase(getDeviceAndStoreName(true)[0]),
        CustomerName: customer ? customer?.name : "N/A",
        Mobile: customer ? customer?.mobile : "N/A",
        Items: data?.numitems,
        Total: currencyFormat(data?.total),
        SaleTime: data?.dt,
        Status: data?.status,
        Payments: data?.payments,
        Item: data?.items,
        Extracosts: data?.extracosts,
        Processdt: dateFormate(parseInt(data?.processdt)),
        Store: FirtsLetterUpperCase(getDeviceAndStoreName(true)[1]),
        UserID: FirtsLetterUpperCase(authUserName()),
        Notes: data?.notes,
        actions: "Actions",
      };
    });
  };
  const getNameById = (type, id) => {
    switch (type) {
      case DEVICE:
        if (props.devices?.length > 0) {
          const devName = props.devices.filter((item) => item.id === id);
          return devName[0] !== undefined
            ? FirtsLetterUpperCase(devName[0].name)
            : id;
        }
      case STORE:
        if (props.stores?.length > 0) {
          const StrName = props.stores.filter((item) => item.id === id);
          return StrName[0] !== undefined
            ? FirtsLetterUpperCase(StrName[0].name)
            : id;
        }
      case STAFFANDADMIN:
        if (props.staff_admin?.length > 0) {
          const StaffName = props.staff_admin.filter((item) => item.id === id);
          return StaffName[0] !== undefined
            ? FirtsLetterUpperCase(StaffName[0].username)
            : id;
        }
      default:
        return id;
    }
  };

  const getTransaction = () => {
    setLoading(true);
    setListData([]);
    dispatch(
      getListActionFunction(
        REPORTTRNSACTION,
        { ref: props?.refs?.split(",") },
        REPORTTRNSACTION
      )
    );
  };

  const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          onClick={() => {
            setShow(true);
            setTransectionDetails({ Data: row });
          }}
          variant="primary"
          title="edit Invoice"
          className="btn-details"
        >
          Details
        </Button>
      </>
    );
  };

  const columns = [
    {
      dataField: "Ref",
      text: "Ref",
      sort: true,
      attrs: {
        "data-title": "Ref",
      },
    },
    {
      dataField: "Processdt",
      text: "Time",
      sort: true,
      attrs: {
        "data-title": "Time",
      },
    },
    {
      dataField: "UserID",
      text: "User",
      sort: true,
      attrs: {
        "data-title": "User",
      },
    },
    {
      dataField: "Total",
      text: "Total",
      sort: true,
      attrs: {
        "data-title": "Total",
      },
    },
    {
      dataField: "Details",
      text: "Details",
      sort: true,
      formatter: actionsFormatter,
      attrs: {
        "data-title": "Details",
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  // const handleGetCurrentData = () => {
  //   console.log(ref?.table?.props?.data?.length);
  //   debugger;
  //   // console.log(setSelectedRow);
  //   setSelectedRow(ref?.table?.props?.data?.length);
  // };

  const cellEdit = {
    mode: "click",
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size}
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: false, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: listData?.length,
      },
    ],
  };

  const NoDataIndication = () => (
    <LoaderSpinner />
  );

  // Expand row
  const size = useWindowSize();
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: "0 px",
      height: "0 px",
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const expandRow = {
    renderer: (row) => (
      <Fade appear={true} in={true}>
        <ul>
          <li>
            <span className="dtr-title">User</span>
            <span className="dtr-data">{row.UserID || "-"}</span>
          </li>
          <li>
            <span className="dtr-title">Total</span>
            <span className="dtr-data">{row.Total || "-"}</span>
          </li>
          <li className="status-orders">
            <span className="dtr-title">Details</span>
            <span className="dtr-data">{actionsFormatter(null, row)}</span>
          </li>
        </ul>
      </Fade>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return expendColorShow(isAnyExpands);
    },
    expandColumnRenderer: ({ expanded }) => {
      return expendColorShow(expanded);
    },
  };
  const expendColorShow = (value) => {
    if (value) {
      return (
        <Image
          className="expand-row-icon"
          src={props?.dynamicImages?.minusIcon}
          alt="minus icon"
        />
      );
    }
    return (
      <Image
        className="expand-row-icon"
        src={props?.dynamicImages?.plusIcon}
        alt="plus icon"
      />
    );
  };

  const expandRow2 = {
    renderer: () => {},
  };
  return (
    <>
      <div className="custom-card card border-0  no-box-shadow column-visibility-table auto-width no-checkbox transaction-table">
        <div className="with-scroll-on-table">
          <ToolkitProvider
            keyField="id"
            data={listData}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="mb-3">
                  <div className="search-bar  with-custom-width">
                    <SearchBar {...props.searchProps} />
                    {props.searchProps?.searchText?.length > 0 && (
                      <ClearSearchButton
                        className="clear"
                        {...props.searchProps}
                      />
                    )}
                  </div>
                  {/* <button
                  className="btn btn-primary"
                  onClick={handleGetCurrentData}
                >
                  Get Current Display Rows
                </button> */}
                </div>
                <div className="pagination-wrapper">
                  <CustomScroll
                    addScrolledClass
                    heightRelativeToParent="calc(100% - 0px)"
                    className={
                      size.width !== undefined &&
                      size.width >= 1 &&
                      size.width <= 576
                        ? "expand-table-row "
                        : ""
                    }
                  >
                    <BootstrapTable
                      // onChange={handleGetCurrentData}
                      ref={(n) => (ref = n)}
                      bootstrap4
                      headerClasses="table-header"
                      pagination={paginationFactory(options)}
                      keyField="Ref"
                      // data={listData}
                      dataColumn={listData?.title}
                      // columns={columns}
                      {...props.baseProps}
                      noDataIndication={() => (
                        <div className="empty-data">
                          <NoDataIndication />
                        </div>
                      )}
                      deleteRow={true}
                      defaultSorted={defaultSorted}
                      expandRow={
                        size.width !== undefined &&
                        size.width >= 1 &&
                        size.width <= 576
                          ? expandRow
                          : expandRow2
                      }
                    />
                  </CustomScroll>
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      </div>
      <CustomModal
        open={show}
        handleClose={handleDialogChange}
        dialogClassName="custom-modal modal-50w transaction-details with-border-bottom"
        title="Transaction Details"
        backdrop="static"
        // keyboard={false}
        content={
          <TransactionDetails
            handleClose={handleDialogChange}
            setposSaleList={setListData}
            posSaleList={listData}
            setTransectionDetails={setTransectionDetails}
            Details={transectionDetails}
            summaryReportTransaction={true}
          />
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-4"
              onClick={handleDialogChange}
            >
              Cancel
            </Button>
          </>
        }
      />
      {/* Confirmation dailog for closing the popup */}
      {/* <CustomModal
        open={show1}
        handleClose={() => {
          setShow1(false);
        }}
        dialogClassName="custom-modal confirmation error-popup"
        title="Confirmation"
        content={
          <>
            <img
              className="my-3"
              width="80px"
              height="80px"
              src={props?.dynamicImages?.cancel}
              alt="cancel icon"
            />
            <h1 className="mb-3">Are you sure you want to delete this Item?</h1>
          </>
        }
        action={
          <>
            <Button type="submit" variant="danger px-5">
              Yes
            </Button>
           <Button
            variant="outline-blackcustom px-5"
              onClick={() => {
                setShow1(false);
              }}
            >
              No
            </Button>
          </>
        }
      /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    reportTransaction: state.saleItem.reportTransaction,
    storeId: state.auth.storeId,
    devices: state.inventory.devices,
    stores: state.inventory.stores,
    staff_admin: state.inventory.userList,
  };
};

export default connect(mapStateToProps)(ReportTransactionTable);
