import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form } from "@themesberg/react-bootstrap";
import ReactSelect, { components } from "react-select";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import { Base64 as Base } from "js-base64";
import { DEVICESETUP, DEVICE_GET_LIST, DEVICE } from "../../app/helpers/constant";
import LoaderSpinner from "../../components/Loader";
import {
  setUpRegister,
  loadingToggleAction,
  intialPaxSetError,
  INITIALPAXSETPOPUP,
} from "../../app/actions/authAction";
import {
  getLRC,
  base64ToHex,
  hexToBase64,
  StringToHex,
  HexToString,
} from "../../app/helpers/custom";

import Localstorage from "../../app/storage/Localstorage";
import { getMasterStoreId } from "axios/authHeader";
import $ from "jquery";
import CustomModal from "../components/Modals";
import { getListActionFunction } from "app/actions/getListAction";
import getListService from "app/services/getListService";
import PropTypes from 'prop-types';

const InitialSetup = (props) => {
  const { ValueContainer, Placeholder } = components;
  const [deviceList, setDeviceList] = useState([]);
  const [show, setShow] = useState(false);
  const [setUpdata, setSetupData] = useState({
    store: Localstorage.getItem("store_id"),
    device: "",
  });
  const [user, ] = useState(Localstorage.getItem("pos_user"));
  const [loading, setLoading] = useState(false);
  const { store, device } = setUpdata;
  const dispatch = useDispatch();
  let count = 0;
  useEffect(() => {
    setDeviceList(props?.device);
  }, [props?.device]);

  const getIpAddress = (devices) => {
    const setupData = JSON.parse(localStorage.getItem("setupData"));
    let paxData = {
      pax_ip: "",
      pax_port: "",
      pax_protocol: "",
    };
    console.log("props?.device", devices)
    if (devices.length > 0 && setupData != undefined) {
      const paxDetails = devices.filter(
        (item) => item.id === setupData.device
      );
      if (
        paxDetails != undefined &&
        (paxDetails != "") & (paxDetails != null)
      ) {
        paxData = {
          pax_ip: paxDetails[0].pax_ip,
          pax_port: paxDetails[0].pax_port,
          pax_protocol: paxDetails[0].pax_protocol,
        };
      }
    }
    InitializePax(paxData);
  };

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const handleSelectChange = (e, formfield) => {
    let device = "";
    let storeData = "";
    if (formfield === "device") {
      device = e.value;
      storeData = store;
      localStorage.setItem("device_type", deviceList?.find(i => +i.id == +e.value ) ?.device_type );
    }
    setSetupData({ ["store"]: storeData, ["device"]: device});
    Localstorage.setItem("p5_terminal_id" , props?.device?.find(it => +it.id == +e.value)?.payfcterminal)
  };

  const HttpCommunication = (commandType, url, callback, timeout) => {
    $.ajax({
      url: url,
      timeout: timeout,
      error: function (xmlHttpRequest, error) {
        // setTitle("Error");
        // setErrorMsg("Please Manually Power Cycle the Card machine and Relogin");
        // setPaxMsg(true);
        console.log("Ajax error info: " + error);
        callback(error);
        // setShow(PAXPOPUP);
        // setPaxError("Error");
      },
      success: function (response) {
        console.log("success");
        console.log("Raw response: " + response);

        var checkParams = StringToHex(response).split(" ").pop();
        var RedundancyCheck = StringToHex(response)
          .split(" ")
          .pop()
          .substring(1);

        var check = getLRC(checkParams);

        if (check == RedundancyCheck) {
          //get package detail info
          var packetInfo = [];
          var len = StringToHex(response).indexOf("03");
          var hex = StringToHex(response).slice(0, len).split(/02|1c/);

          console.log(hex);
          if (commandType == "DoCredit") {
            var subHex = [],
              subPacketInfo = [];
            for (var i = 0; i < hex.length; i++) {
              if (hex[i] != "") {
                if (hex[i].indexOf("1f") > 0) {
                  subHex = hex[i].split("1f");
                  console.log(subHex);
                  subPacketInfo = [];
                  for (var j = 0; j < subHex.length; j++) {
                    if (subHex[j] != "") {
                      subPacketInfo.push(HexToString(subHex[j]));
                    }
                  }
                  console.log(subPacketInfo);
                  packetInfo.push(subPacketInfo);
                } else {
                  packetInfo[i] = HexToString(hex[i]);
                }
              }
            }
          } else {
            for (var i = 0; i < hex.length; i++) {
              if (hex[i] != "") {
                packetInfo[i] = HexToString(hex[i]);
              }
            }
          }

          console.log("Separate package info: ");
          console.log(packetInfo);
          callback(packetInfo);
        }
      },
    });
  };

  const updateDevices = async ()=>{
    
  }

  const InitializePax = (paxConfig) => {
    var initialInfo = {
      command: "A00",
      version: "1.28",
    };
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      // mDestinationIP: "http://127.0.0.1:10009",
      mDestinationIP: `${paxConfig.pax_protocol == ""
          ? "http"
          : paxConfig.pax_protocol == "1"
            ? "http"
            : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };
    var params = [
      PAX.mStx.hex,
      initialInfo.command,
      PAX.mFS.hex,
      initialInfo.version,
      PAX.mEtx.hex,
    ];
    //[02]A08[1c]1.28[1c]0[1c]90000[03]
    //var params = [0x02,"A08",0x1c,"1.28",0x1c, "0", 0x1c,"90000",0x03];
    var lrc = getLRC(params);

    var command_hex = base64ToHex(Base.btoa(initialInfo.command));
    var version_hex = base64ToHex(Base.btoa(initialInfo.version));
    //var elements = [this.mStx, command_hex, this.mFS, version_hex, this.mEtx, base64ToHex(Base64.btoa(lrc))];
    var elements = [
      PAX.mStx.code,
      command_hex,
      PAX.mFS.code,
      version_hex,
      PAX.mEtx.code,
      base64ToHex(Base.btoa(lrc)),
    ];

    var final_string = elements.join(" ");
    //console.log("final_string: " + final_string);

    var final_b64 = hexToBase64(final_string);
    console.log("LRC: " + lrc);
    console.log("Base64: " + final_b64);
    var url = PAX.mDestinationIP + "?" + final_b64;
    console.log("URL: " + url);

    HttpCommunication(
      "Initialize",
      url,
      async function (response) {
        console.log(`pax response`, response);
        if (response == "error") {
          if (count === 1){
            count++;
            dispatch(getListActionFunction(DEVICE, store, DEVICE_GET_LIST));
            const devices = await getListService.ListData(DEVICE, store)
            console.log("devices", devices)
            getIpAddress(devices.data);
          } else{
            console.log("pax not connected");
            dispatch(intialPaxSetError(true, INITIALPAXSETPOPUP));
          }
        } else {
          dispatch(intialPaxSetError(false, INITIALPAXSETPOPUP));
          console.log("pax connected");
        }
        // callback(response);
      },
      PAX.timeout.Initialize
    );
  };

  const handleRegister = (e) => {
    e.preventDefault();
    if (
      user?.data?.permission &&
      user?.data?.permission[store]?.access_permission != 2
    ) {
      setShow(true);
    } else if (!user?.data?.permission && !user.data.store_owner) {
      setShow(true);
    } else {
      if (device !== "") {
        setLoading(true);
        const data = {
          store_id: JSON.stringify(store),
          master_store: getMasterStoreId(),
          device_id: JSON.stringify(device),
          uuid: setDeviceUUID(device),
        };
        if (data) {
          dispatch(loadingToggleAction(true));
          dispatch(setUpRegister(data, DEVICESETUP));
          // setIsCheckedUrl(isCheckedUrl + 1)
          count++
          setTimeout(() => {
            getIpAddress(props?.device);
          }, [1000]);
        }
      } else {
        toast.error("Please select the device");
      }
    }
  };

  const setDeviceUUID = (device) => {
    // generate a SHA UUID using datestamp and rand for entropy and return the result
    var date = new Date().getTime();
    const message = (date * Math.random()).toString();
    const nonce = "";
    const path = "";
    const privateKey = device.toString(); // ...
    const hashDigest = sha256(nonce + message);
    const hmacDigest = Base64.stringify(
      hmacSHA512(path + hashDigest, privateKey)
    );
    return hmacDigest.replace(/[^\w\s]/gi, "");
  };

  return (
    <>
      {(props?.loading || loading) && (
        <LoaderSpinner />
      )}
      <Form autoComplete="off">
        <Row>
          <p>Select a device to merge with or enter a new device name</p>
          <Form.Group className="custom-react-select">
            <ReactSelect
              components={{
                ValueContainer: CustomValueContainer,
              }}
              styles={{
                valueContainer: (provided, state) => ({
                  ...provided,
                  overflow: "visible",
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  position: "absolute",
                  top:
                    state.hasValue || state.selectProps.inputValue
                      ? -15
                      : "7px",
                  transition: "top 0.1s, font-size 0.1s",

                  transform:
                    (state.hasValue || state.selectProps.inputValue) &&
                    "scale(0.85)",
                  opacity:
                    (state.hasValue || state.selectProps.inputValue) && 1,
                }),
              }}
              classNamePrefix="react-select"
              options={deviceList.map((list) => {
                return {
                  ...list,
                  value: list.id,
                  label: list.name.toUpperCase(),
                };
              })}
              value={deviceList
                .map((item) => {
                  return {
                    ...item,
                    value: item.id,
                    label: item.name.toUpperCase(),
                  };
                })
                .filter((item) => {
                  return item.value === device;
                })}
              onChange={(e) => handleSelectChange(e, "device")}
              placeholder={"Please select device"}
            />
          </Form.Group>
          <Col xs={12} className="d-flex justify-content-end mt-5">
            <Button
              variant="primary px-5 form-cta-btn"
              type="button"
              onClick={(e) => handleRegister(e)}
              disabled={props.disableButton ? true : false}
              title="Register"
            >
              Register
            </Button>
          </Col>
        </Row>
      </Form>
    
        <CustomModal
          open={show}
          handleClose={() => setShow(false)}
          dialogClassName="custom-modal confirmation"
          title="Error"
          content="You must login as an administrator for first time setup"
          action={
            <>
              <Button
                variant="primary px-5 me-2"
                onClick={() => props.handleAdminstratorLogin()}
                title="Ok"
              >
                Ok
              </Button>
            </>
          }
        />
     
    </>
  );
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    device: state.inventory.devices,
  };
};
InitialSetup.propTypes = {
  device: PropTypes.object,
};
export default connect(mapStateToprops)(InitialSetup);
