
import { MobileView } from 'react-device-detect';
export const Routes = {
    Signin: { path: "/" },
    // pages
    Register: { path: "/pos" },
    Sales: { path: "/sales" },
    Reports: { path: "/reports" },
    WebOrders: { path: "/web-orders" },
    Settings: { path: "/settings" },
    CustomerScreen: { path: "/customer-screen" },
    Admin: { path: "/admin" },
    // AdminDashboard: { path: "/admin/dashboard" },
    // NotFound: { path: "/examples/404" },
    // ServerError: { path: "/examples/500" },
};