import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
    apiKey: '386eff6fb180f3cdc8e5961037363df1',
    plugins: [new BugsnagPluginReact()],
    autoDetectErrors: false,
    collectUserIp: false,
    appVersion: "0.1"
  })

  //Use the provided error boundary to wrap your application:
const ErrorBoundary = Bugsnag.getPlugin('react')
.createErrorBoundary(React)

export {ErrorBoundary}
