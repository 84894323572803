import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Form,
  Col,
  Row,
  Accordion,
  useAccordionButton,
  Image,
} from "@themesberg/react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import FormField from "../components/formField";
import CustomNumpad from "../components/customNumpad";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  authUserId,
  checkOnlineStatus,
  getDeviceAndStore,
  getDeviceAndStoreName,
} from "../../axios/authHeader";
import CustomModal from "../components/Modals";
import {
  addActionData,
  confirmedResponseAction,
} from "../../app/actions/saleItemAction";
import {
  getStartTimeInReport,
  currencyFormat,
  checkEmptyandZeroValue,
  getLRC,
  HexToString,
  StringToHex,
  base64ToHex,
  hexToBase64,
} from "../../app/helpers/custom";

import {
  CASHRECONCILIATION,
  CLOSEBATCH,
  GRAPHREPORT,
  REGISTERCLOSINGTIME,
  REGISTERREPORT,
  DAYREPORT
} from "../../app/helpers/constant";
import { useReactToPrint } from "react-to-print";
import {
  loadingToggleAction,
  logoutAction,
} from "../../app/actions/authAction";
import { Base64 } from "js-base64";
import $ from "jquery";
import LoaderSpinner from "../../components/Loader";
import {
  getListActionFunction,
  getResponseAction,
} from "../../app/actions/getListAction";
import getListService from "app/services/getListService";
import { UpdateRegisterReport } from "./dayReports";
import moment from "moment";
import Cancel from '../../assets/images/cancel.png'
import PropTypes from 'prop-types';

const CashReconciliation = (props) => {
  const location = useLocation();
  const [cashReconciliation, setCashReconciliation] = useState(false);
  const [graphData, setGraphData] = useState({
    saleTotal: 0,
    refundTotal: 0,
    voidTotal: 0,
    totalTaking: 0,
  });
  const [showIcon, setShowIcon] = useState(false);
  function initialDataFn() {
    return {
      startingCash: "",
      fiftyCash: "",
      hundredCash: "",
      twentyCash: "",
      tenCash: "",
      fiveCash: "",
      twoCash: "",
      oneCash: "",
      fiftyCentCash: "",
      twentyFiveCentCash: "",
      tenCentCash: "",
      fiveCentCash: "",
      OneCentCash: "",
    };
  }

  const [Formdata, setFormData] = useState(initialDataFn());
  const {
    startingCash,
    fiftyCash,
    hundredCash,
    twentyCash,
    tenCash,
    fiveCash,
    twoCash,
    oneCash,
    fiftyCentCash,
    twentyFiveCentCash,
    tenCentCash,
    fiveCentCash,
    OneCentCash,
  } = Formdata;
  const [select, setSelect] = useState(false);
  const [showcashBalance, setShowCashBalance] = useState(false);
  const [curcashtakings, setCurcashTakings] = useState(parseFloat(0));
  const [cashBalance, setCashBalance] = useState(parseFloat(0));
  const [totalCash, setTotalCash] = useState(0);
  const [openErrorMsg, setOpenErrorMsg] = useState(false);
  const [openCloseMsg, setOpenCloseMsg] = useState(false);
  const [verification, setVerification] = useState("no");
  const [errorMsg, setErrorMsg] = useState(null);
  const [title, setTitle] = useState(null);
  const [close, setClose] = useState(false);
  const [logout, setLogout] = useState(false);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(1);
  const [paxMsg, setPaxMsg] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (location?.state?.logout) {
      setLogout(location?.state.logout);
    }
  }, [location]);

  useEffect(() => {
    if (props?.accountSetting?.DEFAULTSETTING !== undefined) {
      let stcash = props?.accountSetting?.DEFAULTSETTING?.defaultvalue || 0;
      const locallyStartingCash = localStorage.getItem("wpos_startingcash");
      if (locallyStartingCash) stcash = locallyStartingCash;
      updateFormData("startingCash", stcash);
    }
  }, [props.accountSetting]);

  useEffect(() => {
    if (props?.PosSetting?.RECEIPT !== undefined) {
      const balance =
        props?.PosSetting.RECEIPT.show_balance_cash_reconciliation || 0;
      setShowCashBalance(parseInt(balance) === 1 ? true : false);
    }
  }, [props.PosSetting]);

  useEffect(() => {
    updateTotalCash(totalCash);
  }, [totalCash]);

  useEffect(() => {
    if (props?.graphReport?.curCashTakings !== undefined) {
      setCurcashTakings(parseFloat(props?.graphReport?.curCashTakings));
      updateCashBalance(props?.graphReport?.curCashTakings);
    }
    if (props?.graphReport?.graphData !== undefined)
      updateUpValue(props?.graphReport?.graphData);
  }, [props.graphReport]);

  useEffect(() => {
    getRegister();
    setLoader(false);
  }, [props?.closeRegisterSuccess]);

  const handleChange = (e) => {
    setCashReconciliation(false);
    const value = e.target.value.replace(/[^0-9.]/g, "");
    if (e.target.name === "startingCash") {
      localStorage.setItem("wpos_startingcash", value);
    }

    if (e.target.name === "totalCash") setTotalCash(value);
    else updateFormData(e.target.name, value);
    updateCashBalance(curcashtakings, e.target.name, value);
  };

  function updateTotalCash(totalCash) {
    props.totalCash(parseFloat(totalCash));
  }

  function updateUpValue(data) {
    setGraphData({
      saleTotal: checkEmptyandZeroValue(data?.salestotal),
      refundTotal: checkEmptyandZeroValue(data?.refundtotal),
      voidTotal: checkEmptyandZeroValue(data?.voidtotal),
      totalTaking: checkEmptyandZeroValue(data?.totaltakings),
    });
  }

  function updateFormData(field, value) {
    setFormData({ ...Formdata, [field]: value });
  }

  const updateCashValue = (data) => {
    if (cashReconciliation !== false) {
      // let elem = document.getElementsByClassName("amountInput");
      if (data.value === "Clear") {
        UpdateValue(true, cashReconciliation, data.value);
      } else if (data.value === "ArrowRight") {
        // elem.setSelectionRange(elem.value.length, elem.value.length);
        // elem.focus();
      } else if (data.value === "ArrowLeft") {
        // elem.setSelectionRange(0, 0);
        // elem.focus();
      } else {
        UpdateValue(false, cashReconciliation, data.value);
      }
    }
  };

  function UpdateValue(clear, field, value) {
    if (clear && value === "Clear") {
      if (field === "startingCash") startCashValue("");
      updateFormData(field, "");
      updateCashBalance(curcashtakings, field, "");
    } else {
      const UpValue = select ? value : Formdata[field] + value;
      if (field === "startingCash") {
        startCashValue(UpValue);
      }
      updateFormData(field, UpValue);
      updateCashBalance(curcashtakings, field, UpValue);
      setSelect(false);
    }
  }

  function startCashValue(value) {
    localStorage.setItem("wpos_startingcash", value);
  }

  function updateCashBalance(curCash, field, value) {
    let calcedtakings = parseFloat(0);
    let balance = parseFloat(0);
    let startCash = parseFloat(startingCash);
    let recdom100 = parseFloat(0);
    if (field === "hundredCash") {
      recdom100 = parseFloat(value !== "" ? value : 0) * 100;
    } else {
      recdom100 = parseFloat(hundredCash) * 100;
    }
    if (isNaN(recdom100)) {
      recdom100 = 0;
    }
    let recdom50 = parseFloat(0);
    if (field === "fiftyCash") {
      recdom50 = parseFloat(value !== "" ? value : 0) * 50;
    } else {
      recdom50 = parseFloat(fiftyCash) * 50;
    }
    if (isNaN(recdom50)) {
      recdom50 = 0;
    }
    let recdom20 = parseFloat(0);
    if (field === "twentyCash") {
      recdom20 = parseFloat(value !== "" ? value : 0) * 20;
    } else {
      recdom20 = parseFloat(twentyCash) * 20;
    }
    if (isNaN(recdom20)) {
      recdom20 = 0;
    }
    let recdom10 = parseFloat(0);
    if (field === "tenCash") {
      recdom10 = parseFloat(value !== "" ? value : 0) * 10;
    } else {
      recdom10 = parseFloat(tenCash) * 10;
    }
    if (isNaN(recdom10)) {
      recdom10 = 0;
    }
    let recdom5 = parseFloat(0);
    if (field === "fiveCash") {
      recdom5 = parseFloat(value !== "" ? value : 0) * 5;
    } else {
      recdom5 = parseFloat(fiveCash) * 5;
    }
    if (isNaN(recdom5)) {
      recdom5 = 0;
    }
    let recdom2 = parseFloat(0);
    if (field === "twoCash") {
      recdom2 = parseFloat(value !== "" ? value : 0) * 2;
    } else {
      recdom2 = parseFloat(twoCash) * 2;
    }
    if (isNaN(recdom2)) {
      recdom2 = 0;
    }
    let recdom1 = parseFloat(0);
    if (field === "oneCash") {
      recdom1 = parseFloat(value !== "" ? value : 0) * 1;
    } else {
      recdom1 = parseFloat(oneCash) * 1;
    }
    if (isNaN(recdom1)) {
      recdom1 = 0;
    }
    let recdom50c = parseFloat(0);
    if (field === "fiftyCentCash") {
      recdom50c = parseFloat(value !== "" ? value : 0) * 0.5;
    } else {
      recdom50c = parseFloat(fiftyCentCash) * 0.5;
    }
    if (isNaN(recdom50c)) {
      recdom50c = 0;
    }
    let recdom25c = parseFloat(0);
    if (field === "twentyFiveCentCash") {
      recdom25c = parseFloat(value !== "" ? value : 0) * 0.25;
    } else {
      recdom25c = parseFloat(twentyFiveCentCash) * 0.25;
    }
    if (isNaN(recdom25c)) {
      recdom25c = 0;
    }
    let recdom10c = parseFloat(0);
    if (field === "tenCentCash") {
      recdom10c = parseFloat(value !== "" ? value : 0) * 0.1;
    } else {
      recdom10c = parseFloat(tenCentCash) * 0.1;
    }
    if (isNaN(recdom10c)) {
      recdom10c = 0;
    }
    let recdom5c = parseFloat(0);
    if (field === "fiveCentCash") {
      recdom5c = parseFloat(value !== "" ? value : 0) * 0.05;
    } else {
      recdom5c = parseFloat(fiveCentCash) * 0.05;
    }
    if (isNaN(recdom5c)) {
      recdom5c = 0;
    }
    let recdom1c = parseFloat(0);
    if (field === "OneCentCash") {
      recdom1c = parseFloat(value !== "" ? value : 0) * 0.01;
    } else {
      recdom1c = parseFloat(OneCentCash) * 0.01;
    }
    if (isNaN(recdom1c)) {
      recdom1c = 0;
    }
    let rectakingsval = parseFloat(totalCash);
    if (field === "totalCash") {
      rectakingsval = parseFloat(value !== "" ? value : 0);
    } else {
      rectakingsval = parseFloat(totalCash);
    }
    if (isNaN(rectakingsval)) {
      rectakingsval = 0;
    }
    if (field === "startingCash") {
      startCash = parseFloat(value !== "" ? value : 0);
    } else {
      startCash = parseFloat(startingCash);
    }
    if (isNaN(startCash)) {
      startCash = 0;
    }
    calcedtakings =
      recdom100 +
      recdom50 +
      recdom20 +
      recdom10 +
      recdom5 +
      recdom2 +
      recdom1 +
      recdom50c +
      recdom25c +
      recdom10c +
      recdom5c +
      recdom1c;
    calcedtakings = calcedtakings.toFixed(2);
    if (parseFloat(calcedtakings) > parseFloat(0)) {
      setTotalCash(parseFloat(calcedtakings).toFixed(2));
      balance = (calcedtakings - (parseFloat(curCash) + startCash)).toFixed(2);
    } else if (parseFloat(calcedtakings) > parseFloat(0)) {
      setTotalCash(parseFloat(rectakingsval).toFixed(2));
      balance = (rectakingsval - (parseFloat(curCash) + startCash)).toFixed(2);
    } else {
      balance = parseFloat(curCash).toFixed(2);
    }
    setCashBalance(balance);
  }

  const handleCloseRegister = () => {
    if (checkOnlineStatus()) {
      setOpenCloseMsg(true);
    } else {
      setTitle("Cash Reconciliation");
      setErrorMsg("Cannot close register while system is offline!");
      setOpenErrorMsg(true);
    }
  };

  const getData = async (start, end)=>{
    return await getListService.ListData(DAYREPORT, { start: new Date(start).getTime(), end: new Date(end).getTime() })
  }

  const confirmCloseRegister = async () => {
    const checkEmptyValue = "";
    // if (
    //   hundredCash === checkEmptyValue &&
    //   fiftyCash === checkEmptyValue &&
    //   twentyCash === checkEmptyValue &&
    //   tenCash === checkEmptyValue &&
    //   fiveCash === checkEmptyValue &&
    //   twoCash === checkEmptyValue &&
    //   oneCash === checkEmptyValue &&
    //   fiftyCentCash === checkEmptyValue &&
    //   twentyFiveCentCash === checkEmptyValue &&
    //   tenCentCash === checkEmptyValue &&
    //   fiveCentCash === checkEmptyValue &&
    //   OneCentCash === checkEmptyValue
    // ) {
    //   setTitle("Alert");
    //   setErrorMsg("There is no values in the Cash Reconciliation section");
    //   setOpenErrorMsg(true);
    //   setOpenCloseMsg(false);
    // }
    let stime = new Date();
    let etime = Date.now();
    if (getStartTimeInReport()) {
      stime = new Date(getStartTimeInReport());
      stime = stime.getTime();
    } else {
      stime.setHours(0);
      stime.setMinutes(0);
      stime.setSeconds(0);
      stime = stime.getTime();
    }

    let start = JSON.parse(localStorage.getItem("CloseRegisterTime") != null ? localStorage.getItem("CloseRegisterTime") : localStorage.getItem("accountClosingTime"));
    localStorage.setItem("startTime", JSON.stringify(start))
    let end = new Date().getTime();
    let ReportData = [await getData(start, end)]
    ReportData = [ReportData[0]?.data?.data]
    localStorage.setItem("ReportData", JSON.stringify(ReportData))

    if (
      props.generalSetting?.CREDIT?.show_batch_close_toggle === "1" &&
      verification == "yes" &&
      props.generalSetting?.CREDIT?.card_type != 2
    ) {
      setLoader(true);
      setTimeout(
        function () {
          closeBatch();
        },
        [4]
      );
    }
    const dt = new Date();
    const utcDate = dt.toUTCString();
    const myDate = new Date(utcDate);
    const timeStamp = myDate.toLocaleString();
    const process_dt = myDate.getTime();
    const devStore = getDeviceAndStore();
    const storeName = getDeviceAndStoreName(true);
    const CashreportData = {
      userinput: JSON.stringify({
        recdom100: hundredCash !== checkEmptyValue ? hundredCash : 0,
        recdom50: fiftyCash !== checkEmptyValue ? fiftyCash : 0,
        recdom20: twentyCash !== checkEmptyValue ? twentyCash : 0,
        recdom10: tenCash !== checkEmptyValue ? tenCash : 0,
        recdom5: fiveCash !== checkEmptyValue ? fiveCash : 0,
        recdom2: twoCash !== checkEmptyValue ? twoCash : 0,
        recdom1: oneCash !== checkEmptyValue ? oneCash : 0,
        recdom50c: fiftyCentCash !== checkEmptyValue ? fiftyCentCash : 0,
        recdom25c:
          twentyFiveCentCash !== checkEmptyValue ? twentyFiveCentCash : 0,
        recdom10c: tenCentCash !== checkEmptyValue ? tenCentCash : 0,
        recdom5c: fiveCentCash !== checkEmptyValue ? fiveCentCash : 0,
        recdom1c: OneCentCash !== checkEmptyValue ? OneCentCash : 0,
        startingcash: startingCash !== checkEmptyValue ? startingCash : 0,
        takingcash: null,
        repstime: stime,
        repetime: etime,
        curcashtakings: curcashtakings,
        accountClosingTime: new Date().getTime(),
      }),
      timeStamp: timeStamp,
      processdt: process_dt,
      sales: parseFloat(graphData.saleTotal).toFixed(2),
      refund: parseFloat(graphData.refundTotal).toFixed(2),
      voids: parseFloat(graphData.voidTotal).toFixed(2),
      takings: parseFloat(graphData.totalTaking).toFixed(2),
      total: totalCash,
      balance: cashBalance,
      device_id: devStore[0],
      register_id: getDeviceAndStore(true)[1],
      store: devStore[1],
      storeName: storeName[1],
      user_id: authUserId(),
    };
    if (CashreportData) {
      if (localStorage.getItem("wpos_startingcash") !== null) {
        localStorage.removeItem("wpos_startingcash");
      }
      localStorage.setItem("CloseRegisterTime", `${Date.now()}`);
      dispatch(
        addActionData(CASHRECONCILIATION, CashreportData, CASHRECONCILIATION)
      );
      setFormData(initialDataFn());
      setTotalCash(0);
      // handlePrint();
      handleBrowserPrint()
    }
    dispatch(
      confirmedResponseAction(
        {
          graphData: {},
          curCashTakings: 0,
        },
        GRAPHREPORT
      )
    );
    handleClose(false);
    if (logout) {
      dispatch(loadingToggleAction(true));
      dispatch(logoutAction());
    }
  };

  const getRegister = async () => {
    let stime = await dispatch(
      getResponseAction(REGISTERCLOSINGTIME, props?.storeId)
    );
    stime = stime?.data?.processdt;
    stime = stime ? stime : Date.now();
    dispatch(
      getListActionFunction(
        REGISTERREPORT,
        { storeId: props?.storeId, stime },
        REGISTERREPORT
      )
    );
  };

  const ShowMsgInfo = (modal, type, msg, status) => {
    setOpenErrorMsg(modal);
    setErrorMsg(type);
    setTitle(msg);
    setStatus(status);
  };

  const closeBatch = () => {
    ShowMsgInfo(false, "", "", "");
    let parms = {};
    var settings = getPaymentObject("Pax", parms, "sale", null, "batchclose");
    console.log(`settings`, settings);
    $.ajax(settings)
      .done(function (res) {
        console.log(`res`, res);
        setLoader(false);
        let response = handlePaymentResponse("Pax", res);
        console.log("response->", response);
        if (response.isApproved == true && response.approvedAmount > 0) {
          console.log("approved");
          ShowMsgInfo(true, "Batch", "Batch Closed", 2);
        } else if (response.isApproved == false) {
          ShowMsgInfo(true, CLOSEBATCH, "Error Closing Batch!", 0);
        }
      })
      .fail(function (jqXHR) {
        console.log(`jqXHR`, jqXHR);
        var errormessage;
        if (jqXHR.status === 0) {
          setShowIcon(true)
          errormessage = "Could not connect.\n Verify your network.";
        } else if (jqXHR.status == 404) {
          setShowIcon(false)
          errormessage = "Requested page not found. [404]";
        } else if (jqXHR.status == 500) {
          setShowIcon(false)
          errormessage = "Internal Server Error [500].";
        } else {
          setShowIcon(false)
          errormessage = "Uncaught Error.\n" + jqXHR.responseText;
        }
        setTitle("Error");
        setErrorMsg(
          <p>
              {errormessage} <br />
              <p className="text-center">
                {"Retry Closing Batch?"}
              </p>
            </p>
        );
        setLoader(false);
        setPaxMsg(true);
        console.log(`errormessage`, errormessage);
      });
  };

  const getPaymentObject = (
    type,
    parms,
    processtype = "sale",
    cardsaletype
  ) => {
    if (type == "Pax") {
      var settings = getPaymentSettings(parms, processtype, cardsaletype);
    }
    return settings;
  };

  const getPaymentSettings = (params, processtype = "sale", cardsaletype) => {
    var version, transactionType; //,accountInformation={},traceInformation,avsInformation,cashierInformation,commercialInformation,motoEcommerce,additionalInformation;

    var configuration = {
      version: "1.28",
      protocol: "https",
      ip: "127.0.0.1",
      port: "10009",
      promptForSignature: 0,
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
    };

    version = configuration.version;
    transactionType = processTypes[processtype];
    //set transaction Data

    let amountInformation = {};
    amountInformation.TransactionAmount =
      processtype == "void"
        ? ""
        : Math.round(parseFloat(params.transactionAmount) * 100);
    amountInformation.TipAmount = "";
    amountInformation.CashBackAmount = "";
    amountInformation.MerchantFee = "";
    amountInformation.TaxAmount = "";
    amountInformation.FuelAmount = "";
    console.log(amountInformation);

    let accountInformation = {};
    accountInformation.Account = "";
    accountInformation.EXPD = "";
    accountInformation.CVVCode = "";
    accountInformation.EBTtype = cardsaletype == "ebt cash" ? "C" : "";
    accountInformation.VoucherNumber = "";
    accountInformation.Force = "";
    accountInformation.FirstName = "";
    accountInformation.LastName = "";
    accountInformation.CountryCode = "";
    accountInformation.State_ProvinceCode = "";
    accountInformation.CityName = "";
    accountInformation.EmailAddress = "";

    let traceInformation = {};
    traceInformation.ReferenceNumber =
      params.hasOwnProperty("ticketNumber") && params.ticketNumber
        ? params.ticketNumber.substring(0, 15)
        : "";
    traceInformation.InvoiceNumber = "";
    traceInformation.AuthCode = "";
    traceInformation.TransactionNumber = params.hasOwnProperty("transactionId")
      ? params.transactionId
      : "";

    traceInformation.TimeStamp = "";
    traceInformation.ECRTransID = "";

    let avsInformation = {};
    avsInformation.ZipCode = "";
    avsInformation.Address = "";
    avsInformation.Address2 = "";

    let cashierInformation = {};
    cashierInformation.ClerkID = "";
    cashierInformation.ShiftID = "";

    let commercialInformation = {};
    commercialInformation.PONumber = "";
    commercialInformation.CustomerCode = "";
    commercialInformation.TaxExempt = "";
    commercialInformation.TaxExemptID = "";
    commercialInformation.MerchantTaxID = "";
    commercialInformation.DestinationZipCode = "";
    commercialInformation.ProductDescription = "";

    let motoEcommerce = {};
    motoEcommerce.MOTO_E_CommerceMode = "";
    motoEcommerce.TransactionType = "";
    motoEcommerce.SecureType = "";
    motoEcommerce.OrderNumber = "";
    motoEcommerce.Installments = "";
    motoEcommerce.CurrentInstallment = "";

    let additionalInformation = {};
    additionalInformation.TABLE = "";
    additionalInformation.GUEST = "";
    additionalInformation.SIGN =
      processtype == "sale" ? configuration.promptForSignature : "";
    additionalInformation.TICKET = "";
    additionalInformation.HREF = "";
    additionalInformation.TIPREQ = "";
    additionalInformation.SIGNUPLOAD = "";
    additionalInformation.REPORTSTATUS = "";
    additionalInformation.TOKENREQUEST = "";
    additionalInformation.TOKEN = "";
    additionalInformation.CARDTYPE = "";
    additionalInformation.CARDTYPEBITMAP = "";

    additionalInformation.PASSTHRUDATA = "";
    additionalInformation.RETURNREASON = "";
    additionalInformation.ORIGTRANSDATE = "";
    additionalInformation.ORIGPAN = "";
    additionalInformation.ORIGEXPIRYDATE = "";
    additionalInformation.ORIGTRANSTIME = "";
    additionalInformation.DISPROGPROMPTS = "";
    additionalInformation.GATEWAYID = "";
    additionalInformation.GETSIGN =
      processtype == "sale" ? configuration.promptForSignature : "";

    additionalInformation.ENTRYMODEBITMAP = "";
    additionalInformation.RECEIPTPRINT = "1";
    additionalInformation.CPMODE = "";
    additionalInformation.ODOMETER = "";
    additionalInformation.VEHICLENO = "";
    additionalInformation.JOBNO = "";
    additionalInformation.DRIVERID = "";
    additionalInformation.EMPLOYEENO = "";
    additionalInformation.LICENSENO = "";
    additionalInformation.JOBID = "";
    additionalInformation.DEPARTMENTNO = "";
    additionalInformation.CUSTOMERDATA = "";
    additionalInformation.USERID = "";
    additionalInformation.VEHICLEID = "";
    console.log(amountInformation);

    var command = "T00";
    if (cardsaletype == "gift") {
      command = "T06";
    } else if (cardsaletype == "debit") {
      command = "T02";
    } else if (cardsaletype == "ebt" || cardsaletype == "ebt cash") {
      command = "T04";
    } else if (cardsaletype == "batchclose") {
      command = "B00";
    }

    console.log(`command`, command);

    return DoCredit({
      command: command,
      version: version,
      transactionType: transactionType,
      amountInformation: amountInformation,
      accountInformation: accountInformation,
      traceInformation: traceInformation,
      avsInformation: avsInformation,
      cashierInformation: cashierInformation,
      commercialInformation: commercialInformation,
      motoEcommerce: motoEcommerce,
      additionalInformation: additionalInformation,
    });
  };

  //DO Credit
  const DoCredit = (doCreditInfo) => {
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      mDestinationIP: "http://127.0.0.1:10009", // - OLD "http://192.167.2.100:10009";       //http://112.199.49.146:8181
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };

    var params = [
      PAX.mStx.hex,
      doCreditInfo.command,
      PAX.mFS.hex,
      doCreditInfo.version,
    ];
    params.push(PAX.mFS.hex);
    if (doCreditInfo.transactionType != "") {
      params.push(doCreditInfo.transactionType);
    }
    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "amountInformation",
      doCreditInfo.amountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "accountInformation",
      doCreditInfo.accountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "traceInformation",
      doCreditInfo.traceInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "avsInformation", doCreditInfo.avsInformation);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "motoEcommerce", doCreditInfo.motoEcommerce);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    params.push(PAX.mEtx.hex);

    var lrc = getLRC(params);

    console.log(params);

    //prepare for base64 encoding.
    var command_hex = base64ToHex(Base64.btoa(doCreditInfo.command));
    var version_hex = base64ToHex(Base64.btoa(doCreditInfo.version));
    var transactionType_hex = base64ToHex(
      Base64.btoa(doCreditInfo.transactionType)
    );

    //var elements = [this.mStx.code, command_hex, PAX.mFS.code, version_hex, PAX.mFS.code, uploadFlag_hex, PAX.mFS.code, timeout, this.mEtx.code, base64ToHex(Base64.btoa(lrc))];
    var elements = [PAX.mStx.code];
    elements.push(command_hex);
    elements.push(PAX.mFS.code);
    elements.push(version_hex);
    elements.push(PAX.mFS.code);

    if (transactionType_hex != "") {
      elements.push(transactionType_hex);
    }
    elements.push(PAX.mFS.code);

    elements = AddBase64(
      elements,
      "amountInformation",
      doCreditInfo.amountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "accountInformation",
      doCreditInfo.accountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "traceInformation",
      doCreditInfo.traceInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "avsInformation",
      doCreditInfo.avsInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(elements, "motoEcommerce", doCreditInfo.motoEcommerce);
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    elements.push(PAX.mEtx.code);
    elements.push(base64ToHex(Base64.btoa(lrc)));
    console.log("elements");
    console.log(elements);

    var final_string = elements.join(" ");
    var final_b64 = hexToBase64(final_string);
    console.log("LRC: " + lrc);
    console.log("Base64: " + final_b64);

    var url = PAX.mDestinationIP + "?" + final_b64;
    console.log("URL: " + url);
    return { url: url, timeout: PAX.timeout.DoCredit };
  };

  const AddBase64 = (elements, type, objectInfo) => {
    //console.log(objectInfo);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    var empty = 0;
    var arr = [];
    arr = arr.concat(elements);
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.code);
        continue;
      }
      if (type == "additionalInformation") {
        if (objectInfo[name] == "") continue;
        empty++;
        arr.push(
          base64ToHex(Base64.btoa(name + "=" + objectInfo[name].toString()))
        );
      } else {
        empty++;
        arr.push(base64ToHex(Base64.btoa(objectInfo[name].toString())));
      }
      arr.push(PAX.mUS.code);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = elements;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.code);
    }
    //console.log(arr);
    return arr;
  };

  const PushParams = (params, type, objectInfo) => {
    var empty = 0;
    var arr = [];
    arr = arr.concat(params);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.hex);
        continue;
      }

      if (type == "additionalInformation") {
        if (objectInfo[name] == "") {
          continue;
        }
        empty++;
        arr.push(name + "=" + objectInfo[name].toString());
      } else {
        empty++;
        arr.push(objectInfo[name].toString());
      }
      arr.push(PAX.mUS.hex);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = params;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.hex);
    }
    //console.log(params);
    return arr;
  };

  const handlePaymentResponse = (type, response) => {
    if (type == "Tripos" || type == "TriposDirect") {
      return response;
    } else if (type == "Pax") {
      return parseResponse(response);
    }
  };

  function parseResponse(response) {
    var commandType = "DoCredit";
    var checkParams = StringToHex(response).split(" ").pop();
    var RedundancyCheck = StringToHex(response).split(" ").pop().substring(1);

    var check = getLRC(checkParams);

    if (check == RedundancyCheck) {
      //get package detail info
      var packetInfo = [];
      var len = StringToHex(response).indexOf("03");
      var hex = StringToHex(response).slice(0, len).split(/02|1c/);

      console.log(hex);
      if (commandType == "DoCredit") {
        var subHex = [],
          subPacketInfo = [];
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            if (hex[i].indexOf("1f") > 0) {
              subHex = hex[i].split("1f");
              console.log(subHex);
              subPacketInfo = [];
              for (var j = 0; j < subHex.length; j++) {
                if (subHex[j] != "") {
                  subPacketInfo.push(HexToString(subHex[j]));
                }
              }
              console.log(subPacketInfo);
              packetInfo.push(subPacketInfo);
            } else {
              packetInfo[i] = HexToString(hex[i]);
            }
          }
        }
      } else {
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            packetInfo[i] = HexToString(hex[i]);
          }
        }
      }
      response = packetInfo;
    }
    if (typeof response == "string") {
      // WPOS.util.okDialog("Error", response, function (dat) { });
      console.log(`responsee`, response);
      return true;
    }

    console.log(`responsee`, response);

    var i = 0,
      j = -1;
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
      LocalTotalReport: {},
      BatchHistory: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
      add: "10",
      activate: "08",
      deactivate: "12",
      balance: "23",
    };
    PacketageInfo.DoCredit.Status = response[++i];
    PacketageInfo.DoCredit.Command = response[++i];
    PacketageInfo.DoCredit.Version = response[++i];
    PacketageInfo.DoCredit.ResponseCode = response[++i];
    PacketageInfo.DoCredit.ResponseMessage = response[++i];
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.Command) {
          commandName = key;
        }
      }
    }
    if (PacketageInfo.DoCredit.ResponseCode == "000000") {
      PacketageInfo.DoCredit.isApproved = true;
    } else {
      PacketageInfo.DoCredit.isApproved = false;
    }

    console.log(`packetInfo->`, PacketageInfo);

    PacketageInfo.DoCredit.HostInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.HostInformation == "") {
      PacketageInfo.DoCredit.HostInformation = {};
      PacketageInfo.DoCredit.HostInformation.HostResponseCode = "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage = "";
      PacketageInfo.DoCredit.HostInformation.AuthCode = "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber = "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber = "";
    } else {
      PacketageInfo.DoCredit.HostInformation.HostResponseCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.AuthCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit._processor = {
      processorLogs: [
        "ExpressResponseCode: [0]\r\nExpressResponseMessage: [" +
        PacketageInfo.DoCredit.isApproved
          ? commandName + "Approved"
          : "" +
            "]\r\nHostResponseCode: [" +
            PacketageInfo.DoCredit.HostInformation.HostResponseCode +
            "]\r\nHostResponseMessage: [ " +
            PacketageInfo.DoCredit.HostInformation.HostResponseMessage +
            "]",
      ],
      processorRawResponse: "",
      processorReferenceNumber:
        PacketageInfo.DoCredit.HostInformation.HostReferenceNumber,
      processorRequestFailed: PacketageInfo.DoCredit.isApproved,
      processorRequestWasApproved: PacketageInfo.DoCredit.isApproved,
      processorResponseCode: PacketageInfo.DoCredit.isApproved
        ? commandName + "Approved"
        : "",
      processorResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      expressResponseCode:
        PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      expressResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      hostResponseCode: PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      hostResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      logs: ["ExpressResponseCode: ''"],
      rawResponse: "",
    };

    PacketageInfo.DoCredit.TransactionType =
      response[++i] != undefined ? response[i] : "";

    PacketageInfo.DoCredit.commandName = "";
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.TransactionType) {
          commandName = key.charAt(0).toUpperCase() + key.slice(1) + " ";
          PacketageInfo.DoCredit.commandName = commandName;
        }
      }
    }

    PacketageInfo.DoCredit.AmountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AmountInformation == "") {
      PacketageInfo.DoCredit.AmountInformation = {};
      PacketageInfo.DoCredit.approvedAmount = "";
      PacketageInfo.DoCredit.subTotalAmount = "0";
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount = "";
      PacketageInfo.DoCredit.AmountInformation.AmountDue = "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount = "";
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount = "";
      PacketageInfo.DoCredit.cashbackAmount =
        PacketageInfo.DoCredit.AmountInformation.CashBackAmount;
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = "";
      PacketageInfo.DoCredit.debitSurchargeAmount = "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount = "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 = "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.approvedAmount = parseFloat(
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
      );
      PacketageInfo.DoCredit.subTotalAmount = parseFloat(
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
      );

      PacketageInfo.DoCredit.AmountInformation.AmountDue =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.tipAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    // add params balance 1 and balance 2 for ebt
    if (["T05", "T07", "T09"].includes(PacketageInfo.DoCredit.Command)) {
      PacketageInfo.DoCredit.Balance1 =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance1) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance1 / 100)
          : PacketageInfo.DoCredit.AmountInformation.Balance1;
      PacketageInfo.DoCredit.Balance2 =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance2) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance2 / 100)
          : PacketageInfo.DoCredit.AmountInformation.Balance2;
    } else {
      PacketageInfo.DoCredit.Balance1 = 0;
      PacketageInfo.DoCredit.Balance2 = 0;
    }

    PacketageInfo.DoCredit.fsaCard = "NotApplicable";
    PacketageInfo.DoCredit.convenienceFeeAmount = "";
    PacketageInfo.DoCredit.AccountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AccountInformation == "") {
      PacketageInfo.DoCredit.AccountInformation = {};
      PacketageInfo.DoCredit.AccountInformation.Account = "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode = "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate = "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype = "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber = "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo = "";
      PacketageInfo.DoCredit.AccountInformation.CardType = "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder = "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage = "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AccountInformation.Account =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.accountNumber =
      PacketageInfo.DoCredit.AccountInformation.Account;
    PacketageInfo.DoCredit.isCardInserted =
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator;
    PacketageInfo.DoCredit.cardHolderName =
      PacketageInfo.DoCredit.AccountInformation.CardHolder;

    PacketageInfo.DoCredit.TraceInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.TraceInformation == "") {
      PacketageInfo.DoCredit.TraceInformation = {};
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber = "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AVSinformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AVSinformation == "") {
      PacketageInfo.DoCredit.AVSinformation = {};
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.CommercialInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.CommercialInformation == "") {
      PacketageInfo.DoCredit.CommercialInformation = {};
      PacketageInfo.DoCredit.CommercialInformation.PONumber = "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.CommercialInformation.PONumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription =
        response[i][++j] != undefined ? response[i][j] : "";
    }
    console.log(PacketageInfo.DoCredit.CommercialInformation);

    PacketageInfo.DoCredit.motoEcommerce =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.motoEcommerce == "") {
      PacketageInfo.DoCredit.motoEcommerce = {};
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType = "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType = "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber = "";
      PacketageInfo.DoCredit.motoEcommerce.Installments = "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.Installments =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AdditionalInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AdditionalInformation == "")
      PacketageInfo.DoCredit.AdditionalInformation = {};
    var additionalInfoArr = PacketageInfo.DoCredit.AdditionalInformation,
      keyValue = [];
    for (i = 0; i < additionalInfoArr.length; i++) {
      keyValue = additionalInfoArr[i].split("=");
      PacketageInfo.DoCredit.AdditionalInformation[keyValue[0]] = keyValue[1];
      keyValue = [];
    }
    if (
      PacketageInfo.DoCredit.AdditionalInformation.hasOwnProperty("CARDBIN")
    ) {
      PacketageInfo.DoCredit.binValue =
        PacketageInfo.DoCredit.AdditionalInformation.CARDBIN;
    }

    PacketageInfo.DoCredit.cardLogo = "";
    PacketageInfo.DoCredit.currencyCode = "";
    PacketageInfo.DoCredit.entryMode = "";
    PacketageInfo.DoCredit.expirationYear = "";
    PacketageInfo.DoCredit.expirationMonth = "";
    PacketageInfo.DoCredit.paymentType = "";
    PacketageInfo.DoCredit.pinVerified = "";
    PacketageInfo.DoCredit.signature = {
      statusCode: "SignatureNotRequiredByThresholdAmount",
    };
    PacketageInfo.DoCredit.statusCode = PacketageInfo.DoCredit.isApproved
      ? commandName + "Approved"
      : "";
    PacketageInfo.DoCredit.transactionDateTime =
      PacketageInfo.DoCredit.TraceInformation.TimeStamp;
    PacketageInfo.DoCredit.transactionId =
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber;
    PacketageInfo.DoCredit.merchantId = "";

    console.log(`PacketageInfo`, PacketageInfo.DoCredit);
    return PacketageInfo.DoCredit;
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleBrowserPrint = ()=>{
    let ReportData = localStorage.getItem("ReportData");
    let ModifiedReportData = [];
    if (ReportData != null){
      ModifiedReportData = UpdateRegisterReport(JSON.parse(ReportData)[0].data, JSON.parse(ReportData)[0])?.filter((e) => {
        return e?.Balance != 0 || (e?.Balance === "" && e?.Total === "");
      })
    }

    let printw = window.open("", "Dead Stock", "height=800,width=650");
    let store_name = getDeviceAndStoreName(true)[1] || ""
    printw.document.write(
      '<html><head><style>.itmvendorno {width: 100%!important;} .hidecol {display: none;}.table-print {margin-bottom: 20px;}.mytable, .table-print { color:#000; width:100%;border-collapse: collapse;border:1px solid #000;font-family:"Source Sans Pro", sans-serif;} .mytable thead:first-child td{font-weight:bold;color:black;} .mytable th, .mytable td, .table-print td, .table-print th {border:1px solid #ccc;padding:5px 10px; font-size: 14px;} .mytable th small {font-size: 14px;} .mytable thead th:last-child, .mytable tr td:last-child {display: none;} @media print {#scrollableDiv{width: 100%;height: 100%;}  .visible-print{display: block; width: auto;height: auto;overflow:visible;}}.dataTables_sizing {height: 40px !important;overflow: auto;} table.mytable td input {border: none;text-align: left;font-family:"Source Sans Pro", sans-serif;font-size:14px; text-transform: uppercase;} .itemname{text-transform: uppercase;} table.mytable th, table.mytable td {text-align: center; white-space: nowrap;} .table-print th, .table-print td {text-align:center; white-space: normal;}table.mytable th:nth-child(2), table.mytable td:nth-child(2) {text-align: left;} @page {size: landscape} </style>'
    );
    printw.document.write(
      '</head><body style="background-color: #FFFFFF;font-family:"Source Sans Pro", sans-serif;"><h2 style="text-align: center; ">' +
      store_name +
      " Day Report</h2>"
    );
    printw.document.write(
      '<h4 style="text-align: center; ">Range: ' +
      moment(JSON.parse(localStorage.getItem("startTime"))).format("MM/DD/YY HH:mm:ss")+ " To " + moment().format("MM/DD/YY HH:mm:ss") +
      "</h4>"
    );
    printw.document.write('<table class="mytable">');
    printw.document.write("<thead>");
    printw.document.write("</thead> <tbody>");
    printw.document.write("</tbody></table>");
    printw.document.write(`<div class="print-container" id="printthiswin">
    <table class="table table-bordered table-print">
        <thead>
            <tr>
                <th></th>
                <th>#Sales</th>
                <th>Total</th>
            </tr>
        </thead>
        <tbody>
            ${ModifiedReportData
          .map((itm) => `
                    <tr>
                        <td>${itm.Method ?? ""}</td>
                        <td>${itm.Balance ? itm.Balance : "-"}</td>
                        <td>${itm.Total ? itm.Total == 0 ? currencyFormat(itm.Total) : itm.Total : "-"}</td>
                    </tr>
                `)
        }
        </tbody>
    </table>
</div>`.replace(/,/g, ''));
    printw.document.write("</body></html>");
    printw.document.close();
    let printed = false;
    if (!printed) {
      printed = true;
      printw.focus();
      printw.print();
    }
  }

  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <>
        <div className="print-container" ref={ref}>
          <div>
            <table>
              <tr>
                <td colSpan={2}></td>
                <td>#Sale</td>
                <td>Total</td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <td>#Sale</td>
                <td>Total</td>
              </tr>
              <tr>
                <td colSpan={2}></td>
                <td>#Sale</td>
                <td>Total</td>
              </tr>
            </table>
          </div>
        </div>
      </>
    );
  });

  const handleClose = () => {
    setErrorMsg(null);
    setOpenCloseMsg(false);
    setOpenErrorMsg(false);
    setTitle(null);
    setPaxMsg(false);
  };

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setClose(!close);
      props.accordion(!close);
    });

    return (
      <Button
        type="button"
        onClick={decoratedOnClick}
        className={`${close ? `closed` : `open`} main-cta-accordion`}
      >
        {children}
      </Button>
    );
  }

  return (
    <>
      {loader && (
        <CustomModal
          open={loader}
          dialogClassName={`custom-modal confirmation error-popup`}
          title="Follow Prompts on Terminal"
          content={<h1 className="mb-3"></h1>}
          action={
            <LoaderSpinner />
          }
        />
      )}
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} />
      </div>
      <Accordion className="custom-accordion" defaultActiveKey="0">
        <Card className="custom-card cash-reconciliation">
          <Card.Header>
            <CustomToggle eventKey="0">Cash Reconciliation</CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="p-3">
              <div className="custom-numpad">
                <FormField
                  className="amountInput"
                  type="text"
                  name="startingCash"
                  value={startingCash}
                  onChange={(e) => handleChange(e)}
                  onClick={(e) => {
                    setCashReconciliation("startingCash");
                    e.target.select();
                    setSelect(true);
                  }}
                  placeholder="Starting Cash"
                  style={{ width: "100%" }}
                />
                {cashReconciliation === "startingCash" && (
                  <CustomNumpad
                    type="CustomerKeypad"
                    setValue={updateCashValue}
                    hideNumpad={setCashReconciliation}
                  />
                )}
              </div>
              <Row className="mt-3">
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="hundredCash"
                        value={hundredCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("hundredCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="$100"
                      />
                    </Form.Group>
                    {cashReconciliation === "hundredCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="fiftyCash"
                        value={fiftyCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("fiftyCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="$50"
                      />
                    </Form.Group>
                    {cashReconciliation === "fiftyCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="twentyCash"
                        value={twentyCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("twentyCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="$20"
                      />
                    </Form.Group>
                    {cashReconciliation === "twentyCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="tenCash"
                        value={tenCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("tenCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="$10"
                      />
                    </Form.Group>
                    {cashReconciliation === "tenCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="fiveCash"
                        value={fiveCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("fiveCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="$5"
                      />
                    </Form.Group>
                    {cashReconciliation === "fiveCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="twoCash"
                        value={twoCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("twoCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="$2"
                      />
                    </Form.Group>
                    {cashReconciliation === "twoCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="oneCash"
                        value={oneCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("oneCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="$1"
                      />
                    </Form.Group>
                    {cashReconciliation === "oneCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="fiftyCentCash"
                        value={fiftyCentCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("fiftyCentCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="50c"
                      />
                    </Form.Group>
                    {cashReconciliation === "fiftyCentCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="twentyFiveCentCash"
                        value={twentyFiveCentCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("twentyFiveCentCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="25c"
                      />
                    </Form.Group>
                    {cashReconciliation === "twentyFiveCentCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="tenCentCash"
                        value={tenCentCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("tenCentCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="10c"
                      />
                    </Form.Group>
                    {cashReconciliation === "tenCentCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="fiveCentCash"
                        value={fiveCentCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("fiveCentCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="5c"
                      />
                    </Form.Group>
                    {cashReconciliation === "fiveCentCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="custom-numpad">
                    <Form.Group className="form-floating with-label-up">
                      <FormField
                        type="text"
                        name="OneCentCash"
                        value={OneCentCash}
                        onChange={(e) => handleChange(e)}
                        onClick={(e) => {
                          setCashReconciliation("OneCentCash");
                          e.target.select();
                          setSelect(true);
                        }}
                        placeholder="0"
                        style={{ width: "100%" }}
                        label="1c"
                      />
                    </Form.Group>
                    {cashReconciliation === "OneCentCash" && (
                      <CustomNumpad
                        type="CustomerKeypad"
                        setValue={updateCashValue}
                        hideNumpad={setCashReconciliation}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
          <Row className="m-0 balance-wrapper">
            <Col className="mb-3 d-flex flex-column flex-sm-row total">
              <div className="custom-numpad">
                <Form.Group className="form-floating with-label-up">
                  <FormField
                    className="amountInput"
                    type="text"
                    name="totalCash"
                    value={totalCash}
                    onChange={(e) => handleChange(e)}
                    onClick={(e) => {
                      setCashReconciliation("totalCash");
                      e.target.select();
                      setSelect(true);
                    }}
                    placeholder="0"
                    style={{ width: "100%" }}
                    label="Total "
                  />
                </Form.Group>
                {cashReconciliation === "totalCash" && (
                  <CustomNumpad
                    type="CustomerKeypad"
                    setValue={updateCashValue}
                    hideNumpad={setCashReconciliation}
                  />
                )}
              </div>
              {showcashBalance && (
                <div className="balance">
                  <label>Balance</label>
                  <span
                    style={{
                      color:
                        parseFloat(cashBalance) < parseFloat(0)
                          ? "red"
                          : "#049e64",
                    }}
                    className={
                      parseFloat(cashBalance) < parseFloat(0) ? `down` : `up`
                    }
                  >
                    {currencyFormat(cashBalance)}
                  </span>
                </div>
              )}
            </Col>
            <Col xs="12">
              <Button
                className="close-register"
                variant="seagreen"
                type="button"
                onClick={handleCloseRegister}
                title="Close Register"
              >
                Close Register
              </Button>
            </Col>
          </Row>
        </Card>
      </Accordion>

      <CustomModal
        open={openErrorMsg}
        handleClose={() => handleClose(false)}
        dialogClassName="custom-modal confirmation error-popup"
        title={title !== null ? title : "-"}
        content={
          <h1>
            {" "}
            <Image
              className="me-3"
              src={props?.dynamicImages?.warning}
              alt="warning Icon"
            />{" "}
            {errorMsg !== null ? errorMsg : ""}
          </h1>
        }
        action={
          status == 0 ? (
            <>
              <Button
                type="button"
                onClick={() =>
                  status == 0 ? closeBatch() : handleClose(false)
                }
                variant="primary px-5 mt-0"
                title="Yes"
              >
                Yes
              </Button>
              <Button
                type="button"
                onClick={() => {
                  handleClose(false);
                  setLoader(false);
                }}
                variant="primary px-5 mt-0"
                title="No"
              >
                No
              </Button>
            </>
          ) : (
            <Button
              type="button"
              onClick={() => handleClose(false)}
                variant="primary px-5 mt-0"
                title="Ok"
            >
              OK
            </Button>
          )
        }
      />
      <CustomModal
        open={openCloseMsg}
        handleClose={() => handleClose(false)}
        dialogClassName="custom-modal confirmation success-popup"
        title="Report Dialog"
        content={
          <>
            <h1 className="mb-3">
              <Image
                className="me-3"
                src={props?.dynamicImages?.warning}
                alt="warning Icon"
              />
              Are you sure you want to close this register?
            </h1>

            {props.generalSetting?.CREDIT?.show_batch_close_toggle === "1" &&
              props.generalSetting?.CREDIT?.card_type != 2 && (
                <div className="batch-close">
                  <span>Batch Close:</span>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="batch"
                    id="yes"
                    htmlFor="yes"
                    value="yes"
                    checked={verification === "yes"}
                    onChange={(e) => setVerification("yes")}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    bala
                    name="batch"
                    id="no"
                    htmlFor="no"
                    value="no"
                    checked={verification === "no"}
                    onChange={(e) => setVerification("no")}
                  />
                </div>
              )}
          </>
        }
        action={
          <>
            <Button
              type="button"
              onClick={() => handleClose(false)}
              variant="outline-blackcustom  px-5"
              title="No"
            >
              No
            </Button>
            <Button
              type="button"
              onClick={() => confirmCloseRegister()}
              variant="primary px-5 ms-2"
              title="Yes"
            >
              Yes
            </Button>
          </>
        }
      />

      <CustomModal
        open={paxMsg}
        handleClose={handleClose}
        dialogClassName={`custom-modal confirmation align-btn ${status === 1 ? `success-popup` : `error-popup`
          }`}
        title={title}
        content={
          <>
            <div className="text-center">
              {showIcon && (
                <Image src={Cancel} alt="error icon" className="mb-3" />
              )}
            </div>
            <h1
              className={`mb-3 ${showIcon ? "justify-content-center" : ""}`}
            >
              {errorMsg}
            </h1>
          </>
        }
        action={
          <>
            {showIcon ? (
              <>
                <Button
                  type="button"
                  onClick={(e) => {
                    setLoader(true);
                    setPaxMsg(false);
                    closeBatch();
                  }}
                  variant="primary"
                  title="Yes"
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  onClick={(e) => handleClose()}
                  variant="outline-blackcustom px-5"
                  title="No"
                >
                  No
                </Button>
              </>
            ) : (
              <Button
                type="button"
                onClick={(e) => handleClose()}
                variant="light px-5 m-auto"
                title="Ok"
              >
                Ok
              </Button>
            )}
          </>
        }
      />

    </>
  );
};
CashReconciliation.propTypes = {
  accountSetting: PropTypes.shape({
    DEFAULTSETTING: PropTypes.shape({
      defaultvalue: PropTypes.number
    })
  }),
  PosSetting: PropTypes.shape({
    RECEIPT: PropTypes.shape({
      show_balance_cash_reconciliation: PropTypes.number
    })
  }),
  graphReport: PropTypes.shape({
    curCashTakings: PropTypes.number,
  }),
  closeRegisterSuccess: PropTypes.bool,
  totalCash: PropTypes.func,
  generalSetting: PropTypes.shape({
    CREDIT: PropTypes.shape({
      show_batch_close_toggle: PropTypes.string,
      card_type: PropTypes.number,
    })
  }),
  storeId: PropTypes.string,
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    dayReport: state.inventory.dayReport,
    accountSetting: state.inventory.accountSetting.data,
    posSetting: state.inventory.posSetting.data,
    generalSetting: state.inventory.generalSetting.data,
    graphReport: state.saleItem.graphData,
    closeRegisterSuccess: state.inventory.closeRegisterSuccess,
    storeId: state.auth.storeId,
  };
};
export default connect(mapStateToprops)(CashReconciliation);
