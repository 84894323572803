import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
} from "@themesberg/react-bootstrap";
import CustomNumpad from "../customNumpad";
import FormField from "../formField";
import { CustomerBusinessName } from "../../../app/helpers/custom";
import crossIcon from "../../../assets/images/cross-screen.svg";
import defaultImage from "../../../assets/images/shop-with-us.svg";
import {
  UPLOADSTATICIMAGEFORNUMBER,
} from "../../../app/helpers/constant";
import PropTypes from 'prop-types';

const AskForNumber = (props) => {
  const [custNumber, setCustNumber] = useState("");
  const [welcomeData, setWelcomeData] = useState(props.posSettingData);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    if (props.posSettingData !== undefined) {
      setWelcomeData(props.posSettingData);
    }
  }, [props.posSettingData]);

  useEffect(() => {
    if (msg !== "") {
      setTimeout(() => {
        setMsg("");
      }, 4000);
    }
  }, [msg]);

  const clearValue = () => {
    setCustNumber("");
  };

  // const redirectToThanks = () => {
  //   if (mobileNumber.length === 10) {

  //   }
  // }

  const getValue = (data) => {
    if (msg === "Enter Valid Number!") {
      setMsg("");
    }
    let mobileNumber = "";
    if (data.value === "") {
      mobileNumber = custNumber.slice(0, -1);
      setCustNumber(mobileNumber);
      return;
    } else if (data.value === "<-") {
      if (custNumber !== "") {
        mobileNumber = custNumber.slice(0, -1);
      }
    } else if (data.value === "Enter") {
      if (custNumber.length) {
        setMsg("Enter Valid Number!");
      }
    } else {
      mobileNumber = data.value
        ? custNumber !== ""
          ? custNumber + data.value
          : data.value
        : "";
    }
    if (mobileNumber.length <= 10) {
      if (mobileNumber.length === 10) {
        setMsg("Thank You!");
        localStorage.setItem("customerScreenMobileNumber", mobileNumber);
        setCustNumber("");
      } else {
        setCustNumber(mobileNumber);
      }
    }
  };

  let image_url;
  if (
    props.cond == 5 &&
    props?.image.hasOwnProperty(UPLOADSTATICIMAGEFORNUMBER)
  ) {
    image_url = props?.image[UPLOADSTATICIMAGEFORNUMBER][0];
  }
  if (
    props.cond == 4 &&
    props?.image.hasOwnProperty(UPLOADSTATICIMAGEFORNUMBER)
  ) {
    image_url = props?.image[UPLOADSTATICIMAGEFORNUMBER][0];
  }

  const redirectToThanks = () => {
    props.setCustomerNumPadHide(true);
  };

  return (
    <>
      {/* Welcome screen */}
      {props.cond === 1 && (
        <Container fluid className="welcome-screen">
          <Row>
            <Col xs="12">
              <h1>
                Welcome&nbsp;
                <span>
                  {CustomerBusinessName(props?.bussines?.business_name)}
                </span>
              </h1>
            </Col>
          </Row>
        </Container>
      )}
      {/* number  entry */}
      {props.cond === 3 && (
        <Container
          fluid
          className={`number-entry welcome-screen${
            props?.noThanksButton ? " no-Thanks" : ""
          }`}
        >
          <Row className="h-100 m-0">
            <Col className="left-side p-0 right-side">
              <div className="inner-wrapper">
                <h1>
                  Welcome&nbsp;
                  <span>
                    {CustomerBusinessName(props?.bussines?.business_name)}
                  </span>
                </h1>
                {props.cond !== 1 &&
                  welcomeData.heading_1 !== "" &&
                  welcomeData.heading_1 !== undefined && (
                    <h2>{welcomeData.heading_1}</h2>
                  )}
                {props.cond !== 1 &&
                  welcomeData.heading_2 !== "" &&
                  welcomeData.heading_2 !== undefined && (
                    <p>{welcomeData.heading_2}</p>
                  )}
                <div className="number-wrapper">
                  <div className="field-wrapper">
                    <FormField
                      type="text"
                      readOnly
                      value={msg !== "" ? msg : custNumber}
                      onChange={(e) => e}
                      placeholder="Enter Phone Number"
                      className="enter-number"
                    />
                    {custNumber.length > 0 && (
                      <Button variant="link" onClick={clearValue}>
                        <Image src={crossIcon} alt="cross icon" />
                      </Button>
                    )}
                  </div>
                  <CustomNumpad
                    type="customerScreenKeypad"
                    setValue={getValue}
                    hideNumpad={false}
                  />
                  {props?.noThanksButton && (
                    <Button
                      variant="link no-thanks"
                      onClick={() => redirectToThanks()}
                    >
                      No Thanks
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {/* ask for number no entry */}
      {props.cond === 4 && (
        <Container fluid className="number-entry no-entry">
          <Row className="h-100 m-0">
            <Col className="left-side p-0 right-side">
              <div className="inner-wrapper">
                <h1>
                  Welcome&nbsp;
                  <span>
                    {CustomerBusinessName(props?.bussines?.business_name)}
                  </span>
                </h1>
                {props.cond !== 1 &&
                  welcomeData.heading_1 !== "" &&
                  welcomeData.heading_1 !== undefined && (
                    <h2>{welcomeData.heading_1}</h2>
                  )}
                {props.cond !== 1 &&
                  welcomeData.heading_2 !== "" &&
                  welcomeData.heading_2 !== undefined && (
                    <p>{welcomeData.heading_2}</p>
                  )}
                <div className="mt-4">
                  {!image_url ? (
                    <Image
                      className="cacl-height"
                      src={defaultImage}
                      alt="shop"
                    />
                  ) : (
                    <Image className="cacl-height" src={image_url} />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      {/* ask for number with entry */}
      {props.cond === 5 && (
        <Container fluid className="number-entry with-entry">
          <Row className="h-100 m-0">
            <Col lg="6" xl="7" className="left-side p-0 pt-5">
              <div className="inner-wrapper mt-5">
                <h1>
                  Welcome&nbsp;
                  <span>
                    {CustomerBusinessName(props?.bussines?.business_name)}
                  </span>
                </h1>
                {props.cond !== 1 &&
                  welcomeData.heading_1 !== "" &&
                  welcomeData.heading_1 !== undefined && (
                    <h2>{welcomeData.heading_1}</h2>
                  )}
                {props.cond !== 1 &&
                  welcomeData.heading_2 !== "" &&
                  welcomeData.heading_2 !== undefined && (
                    <p>{welcomeData.heading_2}</p>
                  )}

                <div className="mt-5 pt-4">
                  {!image_url ? (
                    <Image
                      className="cacl-height"
                      src={defaultImage}
                      alt="shop"
                    />
                  ) : (
                    <Image className="cacl-height" src={image_url} />
                  )}
                </div>
              </div>
            </Col>
            <Col lg="6" xl="5" className="right-side py-4 ">
              <div className="inner-wrapper">
                <FormField
                  type="text"
                  readOnly
                  value={msg !== "" ? msg : custNumber}
                  onChange={(e) => e}
                  placeholder="Enter Phone Number"
                  className="enter-number"
                />
                <CustomNumpad
                  type="customerScreenKeypad"
                  setValue={getValue}
                  hideNumpad={false}
                />
                {props?.noThanksButton && (
                  <Button
                    variant="link no-thanks"
                    onClick={() => redirectToThanks()}
                  >
                    No Thanks
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

AskForNumber.propTypes = {
  posSettingData: PropTypes.object,
  cond: PropTypes.number,
  image: PropTypes.object,
  setCustomerNumPadHide: PropTypes.func,
  bussines: PropTypes.shape({
    business_name: PropTypes.string,
  }),
  noThanksButton: PropTypes.bool,
};

export default AskForNumber;
