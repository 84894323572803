import {
  CHANGE_FONTSIZE,
  CHANGE_CUSTOMER_SCREEN_FONTSIZE,
  CHANGE_SEARCH_FONTSIZE,
  CHANGE_MODE,
  GENERALSETTING,
  IMAGEUPLOAD,
  SALESSATUSFILTER,
  SHORTCUTVIEW
} from "../helpers/constant";
import { SETTING_DYNAMIC_IMAGES } from "../actions/getListAction";
import Localstorage from "../storage/Localstorage";
import { getFormateImages } from "app/helpers/custom";

const initialState = {
  generalSetting: {
    data: [],
    id: "",
  },
  images: [],
  salesStatusFilter: "",
  dynamicImages: null,
  darkmode: Localstorage.getItem("dark_mode") || false,
  fontSize: localStorage.getItem("fontSize"),
  fontSizeSearch: localStorage.getItem("fontSizeSearch"),
  shortCut: false,
};

// Reducer based on type of action and return state and payloads
export default function SettingReducer(state = initialState, actions) {
  switch (actions.type) {
    case IMAGEUPLOAD:
      {
        let formateImages = getFormateImages(actions.payload.data);
        Localstorage.setItem("images", formateImages);
        return {
          ...state,
          images: formateImages,
          disabled: false,
        };
      }
    case SETTING_DYNAMIC_IMAGES: {
      return {
        ...state,
        dynamicImages: actions.payload,
      };
    }
    case GENERALSETTING: {
      return {
        ...state,
        generalSetting: {
          data: JSON.parse(actions.payload.data[0].data),
          id: actions.payload.data[0].id,
        },
        disabled: false,
      };
    }
    case SALESSATUSFILTER:
      return {
        ...state,
        salesStatusFilter: actions.actions,
      };
    case CHANGE_MODE: {
      return {
        ...state,
        darkmode: actions.payload,
      };
    }
    case CHANGE_FONTSIZE: {
      return {
        ...state,
        fontSize: actions.payload,
      };
    }
    case CHANGE_SEARCH_FONTSIZE: {
      return {
        ...state,
        fontSizeSearch: actions.payload,
      };
    }
    case CHANGE_CUSTOMER_SCREEN_FONTSIZE: {
      return {
        ...state,
        fontSize: actions.payload,
      };
    }
    case SHORTCUTVIEW:
      return {
        ...state,
        shortCut: actions.payload,
      }
    default: {
      return state;
    }
  }
}
