import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Col,
  Row,
  Button,
  Tab,
  Nav,
  Form,
  FormCheck,
  InputGroup,
  Table,
  Image,
} from "@themesberg/react-bootstrap";
import $ from "jquery";
import LoaderSpinner from "../../../components/Loader";
import {
  formatCode,
  validateInputField,
  VailidateImageExtention,
  containsDuplicates,
  getTextAccToAppType,
} from "../../../app/helpers/custom";
//Custom Helper and Services
import FormField from "../../../pages/components/formField";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import ReactSelect, { components } from "react-select";
import CustomModal from "../../../pages/components/Modals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ColorPicker from "../../../pages/components/ColorPicker";
import Promotions from "./promotions";
import useKeypress from "react-use-keypress";
import { connect, useDispatch } from "react-redux";
import {
  authUserId,
  checkUserPermission,
  getDeviceAndStore,
  getSettingData,
} from "../../../axios/authHeader";
import {
  ITEMIMAGE,
  REMOVESTOCKCODE,
  REMOVEMODIFIER,
  STOCKCODECHECK,
  LOADING,
  ITEMUPDATE,
  ITEM,
  DUPLICATE,
  DELETE,
  IMAGE_COMPRESS_MAX_WIDTH_HEIGHT,
  IMAGE_COMPRESS_UPLOAD_SIZE,
  ITEMADDED,
} from "../../../app/helpers/constant";
import getListService from "../../../app/services/getListService";
import {
  loadingAction,
  editActionFunction,
  addActionFunction,
} from "../../../app/actions/getListAction";
import { currencyFormat } from "../../../app/helpers/custom";
import BarcodeReader from "react-barcode-reader";
import imageCompression from "browser-image-compression";
import { Auth } from "../../auth/auth";
import { debounce } from "lodash";
import { xml2json, json2xml } from "xml-js";
import ReactCustom from "../../../components/ReactSelect";
import PropTypes from 'prop-types';
// Images and icons
const none_option = [{ id: "", name: "None" }];

const rank_option = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
];

const multiplierList = [
  { id: 1, name: "1X" },
  { id: 2, name: "2X" },
  { id: 3, name: "3X" },
  { id: 4, name: "4X" },
  { id: 5, name: "5X" },
];

const AddItems = (props) => {
  const [show1, setShow1] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleDialogChange = () => {
    setShow1(!show1);
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handlePopupClose = () => {
    setShow1(false); // Set show1 to false when closing the popup
    setIsCheckboxChecked(false); 
  };
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const [ItemSizeList, setItemSizeList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [SupplierList, setSupplierList] = useState([]);
  const [stockcodeList, setStockcodeList] = useState([
    { code: "", upccode: "" },
  ]);
  const [show, setShow] = useState(false);
  const [deletetype, setDeletetype] = useState(null);
  const [deleteid, setDeleteid] = useState(null);
  const [idtype, setidtype] = useState(null);
  const [closeModal, setClosemodal] = useState(false);
  const [tag, setTag] = useState([]);
  const [oldtag, setOldTag] = useState([]);
  const [, setOldAdditionalfee] = useState([]);
  const [additionalfee, setAdditionalfee] = useState([]);
  const [promptShow, setPromptShow] = useState(false);
  const [bottledepositList, setBottledepositList] = useState([]);
  const [focus, setFocus] = useState(false);
  const [collectFee, setCollectFee] = useState([]);
  const [collectTag, setCollectTag] = useState([]);
  const [duplicateBarCode, setDuplicateBarcode] = useState(0);
  const [UpcTenCode, setUpcTenCode] = useState(
    props?.generalSetting?.OTHER?.use_upc_ten
  );
  const [duplicateItem, setDuplicateItem] = useState([]);
  const [itemImgData, setItemImgData] = useState("");
  const [auth, setAuth] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const [posSideSetting, setPosSideSetting] = useState(
    getSettingData("wpos_config")
  );

  useKeypress(["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"], (event) => {
    focus && keyMovementCheck(event.keyCode, event);
  });

  useEffect(() => {
    if (authSuccess == true && update) {
      onSubmitHandle();
      setAuthSuccess(false);
      setAuth(false);
      onSubmitHandle();
      setShow(false);
      setUpdate(false);
      props.closeEditModel(false);
    }
  }, [authSuccess]);

  useEffect(() => {
    window.addEventListener("storage", () => {
      setPosSideSetting(getSettingData("wpos_config"));
    });
  }, []);

  const keyMovementCheck = (keyCode, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (keyCode !== 38 && keyCode !== 40 && keyCode !== 37 && keyCode !== 39)
      return;
    const current = document.activeElement;
    const items = [...document.getElementsByClassName("nav-control")];
    const currentIndex = items.indexOf(current);
    let newIndex;
    if (currentIndex === -1) newIndex = 0;
    else {
      if (keyCode === 37)
        newIndex = (currentIndex + items.length - 1) % items.length;
      else if (keyCode === 39) newIndex = (currentIndex + 1) % items.length;
      else if (keyCode === 38) {
        // upkey
        if (items.length > 7)
          newIndex = ((currentIndex + items.length - 1) % items.length) - 6;
        else newIndex = (currentIndex + items.length - 1) % items.length;
      } else if (keyCode === 40) {
        //down key
        if (items.length > 7)
          newIndex = ((currentIndex + 1) % items.length) + 6;
        else newIndex = (currentIndex + 1) % items.length;
      }
    }
    if (
      !(
        newIndex === -6 ||
        newIndex === -5 ||
        newIndex === -4 ||
        newIndex === -3 ||
        newIndex === -2
      )
    ) {
      if (!(newIndex >= items.length)) {
        current.blur();
        items[newIndex].focus();
      }
    }
  };

  function convert(str) {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleScan = (data) => {
    if (data.length > 0) {
      updateStockCode(data);
    }
  };

  const handleError = (error) => {
    toast.error(error);
  };

  const [formData, setformData] = useState({
    id: "",
    item_size: "",
    name: "",
    category: "",
    supplier: "",
    tax: 0,
    totalitem: Number(0).toFixed(2),
    cases: Number(0).toFixed(2),
    vendoritemno: "",
    sku: "",
    unit_per_case: Number(1).toFixed(2),
    reorder_point: 1,
    reorder_value: 1,
    item_rank: "",
    vendoritemname: "",
    price: "",
    note: "",
    case_cash_total: Number(0).toFixed(2),
    promptforqty: true,
    printlabel: false,
    autoupdate: false,
    donottrackinventory: false,
    shortcutkeys: false,
    closeoutitem: false,
    donotdiscount: false,
    showtoweb: false,
    hideinventory: false,
    ebteligible: false,
    default_qty: 1,
    item_type: 1,
    remind_date: "",
    bottle_deposit: "",
    item_shortcut_no: "",
    item_shortcut_name: "",
    color_code: "#00ff00",
    pointsmultiplier: null,
    pointsrequired: null,
  });
  const {
    id,
    item_size,
    default_qty,
    category,
    supplier,
    tax,
    totalitem,
    cases,
    name,
    vendoritemno,
    sku,
    unit_per_case,
    reorder_point,
    reorder_value,
    item_rank,
    vendoritemname,
    price,
    note,
    case_cash_total,
    item_type,
    remind_date,
    bottle_deposit,
    item_shortcut_no,
    item_shortcut_name,
    color_code,
    pointsmultiplier,
    pointsrequired,
  } = formData;

  const [stockList, setStockList] = useState([initialModifers(1)]);

  const [couponItemType, setCouponItemType] = useState(false);
  const [couponTypeData, setCouponTypeData] = useState({
    qty: 1,
    price: 0,
    type: "",
  });
  const couponTypeOptions = [
    {
      id: 0,
      name: "Negative",
    },
    {
      id: 1,
      name: "Positive",
    },
  ];

  useEffect(() => {
    if (formData.item_type == 12 || formData.item_type == 13) {
      setCouponItemType(true);
    } else {
      setCouponItemType(false);
    }
  }, [formData.item_type]);

  useEffect(() => {
    setItemSizeList(
      props?.itemsize?.length > 0 ? props?.itemsize : none_option
    );
  }, [props.itemsize]);

  useEffect(() => {
    setCategoryList(
      props?.categories?.length > 0
        ? [...props?.categories].sort((a, b) => a.name.localeCompare(b.name))
        : none_option
    );
  }, [props.categories]);

  useEffect(() => {
    setSupplierList(
      props?.suppliers?.length > 0
        ? [...props?.suppliers].sort((a, b) => a.name.localeCompare(b.name))
        : none_option
    );
  }, [props.suppliers]);

  useEffect(() => {
    setBottledepositList([...none_option, ...props?.bottleDeposit]);
  }, [props.bottleDeposit]);

  useEffect(() => {
    if (
      props?.AccountSetting?.TAXRULEDATA ||
      props?.accountSetting?.TAXRULEDATA
    ) {
      setformData({
        ...formData,
        tax: parseInt(
          props?.AccountSetting?.TAXRULEDATA?.defaultTax ||
          props?.accountSetting?.TAXRULEDATA?.defaultTax ||
          0
        ),
      });
    }
  }, [props.AccountSetting, props.accountSetting]);

  useEffect(() => {
    if (props?.GeneralSetting?.OTHER || props?.generalSetting?.OTHER) {
      setDuplicateBarcode(
        parseInt(
          props?.GeneralSetting?.OTHER?.allow_duplicate_barcode ||
          props?.generalSetting?.OTHER?.allow_duplicate_barcode ||
          0
        )
      );
      setUpcTenCode(
        parseInt(
          props?.GeneralSetting?.OTHER?.use_upc_ten ||
          props?.generalSetting?.OTHER?.use_upc_ten
        )
      );
    }
  }, [props.GeneralSetting, props.generalSetting]);

  useEffect(() => {
    if (
      props?.msg !== "" &&
      (props?.msg === "itemUpdated" || props?.msg === "itemAdded")
    ) {
      if (props?.msg === "itemAdded" && closeModal) props.closeEditModel(true);
      else props.closeEditModel(false);
      dispatch(loadingAction(false, LOADING));
    }
  }, [props.msg]);

  useEffect(() => {
    if (!checkAnotherItem()) {
      let itemId = props?.updateData;
      if (itemId) {
        setShow(LOADING);
        getListService.checkSingleRow(ITEM, { id: itemId }).then((response) => {
          if (response.status == 200) {
            const details = response?.data?.item;
            getItemDetail(
              details,
              details?.modifier,
              details?.fee,
              details?.tag,
              details?.code
            );
            setShow(false);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (props?.stockCode !== undefined && props?.stockCode?.length > 0) {
      updateStockCode(props?.stockCode);
    }
  }, [props.stockCode]);

  useEffect(() => {
    if (stockList.length > 1) {
      if (stockList.filter((item) => item.prompt === true).length === 0) {
        setformData({ ...formData, promptforqty: false });
        if (promptShow !== "ShowValue") {
          setPromptShow(false);
        }
      } else {
        setformData({ ...formData, promptforqty: true });
        setPromptShow(true);
      }
    }
  }, [stockList]);

  let calCases = (totalitem, totalModifierQty) => {
    return parseFloat(totalitem / totalModifierQty).toFixed(2);
  };

  const getItemDetail = (detail, modifier, fee, tag, code) => {
    try {
      if (detail) {
        let data = {
          ...formData,
          id: detail?.id,
          item_size: detail?.item_size !== null ? detail?.item_size : "",
          default_qty: detail?.default_qty !== null ? detail?.default_qty : "",
          category: detail?.category !== null ? detail?.category : "",
          supplier: detail?.supplier !== null ? detail?.supplier : "",
          tax: detail?.tax !== null ? +detail?.tax : 0,
          totalitem: Number(detail?.totalitem).toFixed(2),
          cases: Number(
            calCases(parseFloat(detail?.totalitem), Number(detail?.unit_per_case))
          ).toFixed(2),
          name: detail?.name.toUpperCase(),
          vendoritemno:
            detail?.vendoritemno !== null ? detail?.vendoritemno : "",
          sku: detail?.sku !== null ? detail?.sku : "",
          unit_per_case: Number(detail?.unit_per_case).toFixed(2),
          case_cash_total: Number(detail?.case_cash_total).toFixed(2),
          reorder_point: detail?.reorder_point,
          reorder_value: detail?.reorder_value,
          item_rank: detail?.item_rank !== null ? detail?.item_rank : "",
          vendoritemname: detail?.item_name !== null ? detail?.item_name : "",
          price: detail?.price,
          item_type: detail?.item_type !== null ? detail?.item_type : "",
          remind_date: detail?.remind_date !== null ? detail?.remind_date : "",
          note: detail?.data !== null ? detail?.data : "",
          promptforqty: detail?.promptforqty,
          printlabel: detail?.printlabel,
          autoupdate: detail?.autoupdate,
          donottrackinventory: detail?.donottrackinventory,
          shortcutkeys: detail?.shortcutkeys,
          closeoutitem: detail?.closeoutitem,
          donotdiscount: detail?.donotdiscount,
          showtoweb: detail?.showtoweb,
          pointsrequired: detail?.pointsrequired,
          pointsmultiplier: detail?.pointsmultiplier,
          color_code:
            detail?.color_code !== null ? detail?.color_code : "#00ff00",
          hideinventory: detail?.hideinventory,
          ebteligible: detail?.ebteligible,
          bottle_deposit:
            detail?.bottle_deposit !== null ? detail?.bottle_deposit : "",
          item_shortcut_no:
            detail?.item_shortcut_no !== null ? detail?.item_shortcut_no : "",
          item_shortcut_name:
            detail?.item_shortcut_name !== null
              ? detail?.item_shortcut_name
              : "",
        }
        if ([12, 13].includes(data?.item_type)) {
          setCouponItemType(true);
          setCouponTypeData(detail?.modifier[0]);
        }
        setformData(data);

        if (modifier !== undefined && modifier.length > 0) {
          modifier = modifier.map((e) => {
            return {
              ...e,
              qty_on_hand: calCases(parseInt(detail?.totalitem), e.qty)
            };
          });
          setStockList(modifier);
          setPromptShow(true);
        }
        if (code !== undefined && code.length > 0) {
          setStockcodeList(
            code.map((data) => {
              return {
                ...data,
                code: data.code,
                upccode:
                  data.upc !== undefined && data.upc !== null ? data.upc : "",
              };
            })
          );
        }
        if (fee !== undefined && fee.length > 0) {
          const feeMap = findUniqueData(fee, "fee");
          let addfee = feeMap.map((data) => {
            return {
              ...data,
              value: data.fee,
              label: data.fee_name,
            };
          });

          setOldAdditionalfee(addfee);
          setAdditionalfee(addfee);
          // setCollectFee(fee);
        }
        if (tag !== null && tag !== undefined) {
          const tagMap = findUniqueData(tag, "tag");
          let taxx = tagMap.map((data) => {
            return {
              ...data,
              value: data.tag,
              label: data.tag_name,
            };
          });
          setOldTag(taxx);
          setTag(taxx);
          // setCollectTag(tag);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };


  const prevstate = () => {
    if (stockcodeList.length === 0) {
      return true;
    }
    const someEmpty = stockcodeList.some((item) => item.code === "");
    if (someEmpty) {
      toast.error("Add stockcode first");
    }
    return !someEmpty;
  };

  const findUniqueData = (arr, key) => {
    if (arr.length > 0 && key !== "") {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    } else {
      return arr;
    }
  };

  const AddStockcode = () => {
    setFocus(false);
    if (stockcodeList.length < 40) {
      const inputstate = {
        code: "",
        upccode: "",
      };
      if (prevstate()) {
        setStockcodeList((prev) => [...prev, inputstate]);
      }
    } else {
      toast.error("You can add only 40 stockcodes!");
    }
  };

  const prevstateModifer = () => {
    if (stockList.length === 0) return true;
    const someEmpty = stockList.some(
      (item) => item.qty === 0 || item.qty === ""
    );
    if (someEmpty) toast.error("Add quantity first");
    return !someEmpty;
  };

  const AddStock = () => {
    if (stockList.length < 12) {
      const inputstate = initialModifers(0);
      if (prevstateModifer()) setStockList((prev) => [...prev, inputstate]);
      setPromptShow("ShowValue");
    } else toast.error("You can add only 12 modifiers!");
  };

  const RemoveStock = (index, id) => {
    setFocus(false);
    if (id !== undefined && id !== "") {
      setDeleteid(id);
      setidtype(index);
    } else {
      setDeleteid(index);
      setidtype("index");
    }
    setDeletetype(REMOVEMODIFIER);
    setShow(DELETE);
  };

  const RemoveStockcode = (index, id) => {
    if (id !== undefined && id !== "") {
      setDeleteid(id);
      setidtype(index);
    } else {
      setDeleteid(index);
      setidtype("index");
    }
    setDeletetype(REMOVESTOCKCODE);
    setShow(DELETE);
  };

  const colorCode = (data) => {
    function ColorToHex(color) {
      const hexadecimal = color.toString(16);
      return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
    }
    function ConvertRGBtoHex(red, green, blue) {
      return "#" + ColorToHex(red) + ColorToHex(green) + ColorToHex(blue);
    }
    setformData({
      ...formData,
      color_code: ConvertRGBtoHex(data.r, data.g, data.b),
    });
  };

  const handleConfirmDelete = (type, deleteId, idtype) => {
    setFocus(false);
    switch (type) {
      case REMOVEMODIFIER:
        if (idtype === "index") {
          setStockList((prev) =>
            prev.filter((item) => item !== prev[deleteId])
          );
        } else {
          setStockList((prev) => prev.filter((item) => item !== prev[idtype]));
          if (props?.mergedata && props.itemid) {
            getListService.DeleteRow(type, deleteId);
          }
        }
        setShow(false);
        break;
      case REMOVESTOCKCODE:
        if (idtype === "index") {
          setStockcodeList((prev) =>
            prev.filter((item) => item !== prev[deleteId])
          );
        } else {
          setStockcodeList((prev) =>
            prev.filter((item) => item !== prev[idtype])
          );
          if (props?.mergedata && props.itemid) {
            getListService.DeleteRow(type, deleteId);
          }
        }
        setShow(false);
        break;
      default:
        return false;
    }
  };

  const SetlatestCostData = (index, event, fieldname) => {
    const values = [...stockList];
    if (fieldname === "avg_cost") {
      let avg_cost = event.target.value;
      if (avg_cost !== "") {
        if (
          stockList[index].latest_cost === 0 ||
          stockList[index].latest_cost <= 0
        ) {
          values[index]["latest_cost"] = avg_cost;
        }
      } else {
        values[index]["latest_cost"] = 0;
      }
    }
    setStockList(values);
  };

  const StockData = (index, event, fieldname) => {
    setFocus(true);
    const values = [...stockList];
    if (fieldname === "price") {
      let price = event.target.value;
      values[index][event.target.name] = price;
      if (price !== "") {
        let avg_cost = stockList[index].avg_cost;
        if (avg_cost > 0) {
          values[index]["margin"] = Number(
            ((price - avg_cost) / price) * 100
          ).toFixed(2);
          values[index]["markup"] = Number(
            ((price - avg_cost) / avg_cost) * 100
          ).toFixed(2);
        } else {
          values[index]["margin"] = Number(100).toFixed(2);
          values[index]["markup"] = Number(0).toFixed(2);
        }
      } else {
        values[index]["margin"] = Number(0).toFixed(2);
      }
    }
    if (fieldname === "avg_cost") {
      let avg_cost = event.target.value;
      values[index][event.target.name] = avg_cost;
      let price = stockList[index].price;
      if (avg_cost > 0 && avg_cost !== "") {
        if (price) {
          for (let i = 0; i < stockList.length; i++) {
            let margin = ((price - avg_cost) / price) * 100;
            values[i]["margin"] = Number(margin).toFixed(2);
            values[i]["markup"] = Number(
              ((price - avg_cost) / avg_cost) * 100
            ).toFixed(2);
            if (i !== index) {
              values[i]["avg_cost"] = Number(
                stockList[i].price * (1 - margin / 100)
              ).toFixed(2);
            }
            if (i === 0) {
              let unitcase = "0";
              if (formData.unit_per_case) {
                unitcase = formData.unit_per_case;
              }
              setformData({
                ...formData,
                case_cash_total: Number(
                  unitcase * stockList[0].avg_cost
                ).toFixed(2),
              });
            }
          }
        }
      } else {
        values[index]["markup"] = Number(0).toFixed(2);
        values[index]["margin"] = Number(100).toFixed(2);
      }
    }
    if (fieldname === "margin") {
      let margin = event.target.value;
      values[index][event.target.name] = margin;
      if (margin !== "") {
        if (margin <= 100) {
          let avg_cost = stockList[index].avg_cost;
          if (avg_cost > 0) {
            let price = (avg_cost * 100) / (100 - margin);
            values[index]["price"] = Number(price).toFixed(2);
            values[index]["markup"] = Number(
              ((price - avg_cost) / avg_cost) * 100
            ).toFixed(2);
          } else {
            values[index]["price"] = Number(0).toFixed(2);
            values[index]["markup"] = Number(0).toFixed(2);
          }
        } else {
          toast.error("Margin value can't be more than 100!");
        }
      }
    }
    if (fieldname === "markup") {
      let markup = event.target.value;
      values[index][event.target.name] = markup;
      if (markup !== "") {
        let avg_cost = stockList[index].avg_cost;
        if (avg_cost > 0) {
          let price = (markup / 100 + 1) * avg_cost;
          values[index]["price"] = Number(price).toFixed(2);
          values[index]["margin"] = Number(
            ((price - avg_cost) / price) * 100
          ).toFixed(2);
        } else {
          values[index]["price"] = Number(0).toFixed(2);
          values[index]["margin"] = Number(0).toFixed(2);
        }
      }
    }
    if (fieldname === "latest_cost") {
      values[index][event.target.name] = event.target.value;
    }
    if (fieldname === "qty") {
      let entqty = event.target.value;
      values[index][event.target.name] = entqty;
      if (entqty !== "") {
        values[index]["price"] = Number(stockList[0].price * entqty).toFixed(2);
        values[index]["avg_cost"] = Number(
          stockList[0].avg_cost * entqty
        ).toFixed(2);
        values[index]["margin"] = Number(
          ((values[index]["price"] - values[index]["avg_cost"]) /
            values[index]["price"]) *
          100
        ).toFixed(2);
        if (values[index]["avg_cost"] > 0 && entqty > 0) {
          values[index]["markup"] = Number(
            ((values[index]["price"] - values[index]["avg_cost"]) /
              values[index]["avg_cost"]) *
            100
          ).toFixed(2);
        } else {
          values[index]["markup"] = Number(0).toFixed(2);
          values[index]["margin"] = Number(0).toFixed(2);
        }
        values[index]["latest_cost"] = Number(
          stockList[0].latest_cost * entqty
        ).toFixed(2);
      } else {
        values[index]["price"] = Number(0).toFixed(2);
        values[index]["avg_cost"] = Number(0).toFixed(2);
        values[index]["margin"] = Number(0).toFixed(2);
        values[index]["markup"] = Number(0).toFixed(2);
        values[index]["latest_cost"] = Number(0).toFixed(2);
      }
    }
    if (totalitem !== "" && totalitem > 0) {
      let modifierqty = stockList[index].qty;
      if (modifierqty !== "") {
        values[index]["qty_on_hand"] = Number(totalitem / modifierqty).toFixed(
          2
        );
      }
    } else {
      values[index]["qty_on_hand"] = Number(0).toFixed(2);
    }
    setStockList(values);
  };

  const handleChange = (e, formfield) => {
    e.stopPropagation();
    if (formfield === "enterqty" && formfield !== "") {
      const values = [...stockList];
      let totalitem = e.target.value;
      if (stockList.length > 0) {
        for (let i = 0; i < stockList.length; i++) {
          let modifierqty = stockList[i].qty;
          if (totalitem !== "") {
            values[i]["qty_on_hand"] = Number(totalitem / modifierqty).toFixed(
              2
            );
          }
        }
        setStockList(values);
      }
      let totalcase = Number(totalitem / unit_per_case).toFixed(2);
      setformData({ ...formData, totalitem: totalitem, cases: totalcase });
    }
    if (formfield === "entercases" && formfield !== "") {
      const values = [...stockList];
      let cases = e.target.value;
      let item = "0";
      if (unit_per_case !== "" && cases !== "") {
        item = Number(unit_per_case * cases);
        if (stockList.length > 0) {
          for (let i = 0; i < stockList.length; i++) {
            let modifierqty = stockList[i].qty;
            if (item !== "") {
              values[i]["qty_on_hand"] = Number(item / modifierqty).toFixed(2);
            }
          }
          setStockList(values);
        }
      }
      setformData({
        ...formData,
        cases: cases,
        totalitem: Number(item).toFixed(2),
      });
    }
    if (formfield === "unit_per_case" && formfield !== "") {
      let unit_case = e.target.value;
      let cases = "0";
      let case_total = "0";
      if (unit_case !== "") {
        cases = formData.totalitem / unit_case;
        case_total = unit_case * stockList[0].avg_cost;
      }
      setformData({
        ...formData,
        unit_per_case: unit_case,
        cases: Number(cases).toFixed(2),
        case_cash_total: Number(case_total).toFixed(2),
      });
    }
    if (formfield === "all") {
      if (validateInputField(e.target.value)) {
        if (e.target.name === "pointsrequired" && e.target.value === "") {
          setformData({ ...formData, [e.target.name]: null });
        } else {
          setformData({ ...formData, [e.target.name]: e.target.value });
        }
      }
    }
  };

  const handleSelectChange = (e, fieldname) => {
    setFocus(false);
    setformData({ ...formData, [fieldname]: e.value });
  };

  const setCollectTags = (tags) => {
    let tagIds = tags.map((e) => e.id);
    setCollectTag(collectTag.concat(tag.filter((e) => !tagIds.includes(e.id))));
  };

  const setCollectFees = (fee) => {
    let feeIds = fee.map((e) => e.id);
    setCollectFee(
      collectFee.concat(additionalfee.filter((e) => !feeIds.includes(e.id)))
    );
  };

  const handleSelectChangeMulti = (e, fromfield) => {
    setFocus(false);
    if (fromfield === "tag") {
      tag.length - e.length == 1 && setCollectTags(e);
      setTag(e);
    } else if (fromfield === "additional_fee") {
      additionalfee.length - e.length == 1 && setCollectFees(e);
      setAdditionalfee(e);
    }
  };

  const Stockchecked = (index, event) => {
    setFocus(false);
    event.persist();
    let sList = stockList?.map((item, i) => {
      if (i !== index) {
        return item;
      }
      return {
        ...item,
        [event.target.name]: event.target.checked,
      };
    });
    let promp_for_qty_formData = sList?.filter((e) => e.prompt).length > 0;
    setStockList(sList);
    setformData({ ...formData, promptforqty: promp_for_qty_formData });
  };

  const debounceFetchUsers = useCallback(
    debounce((code, UpcTenCode, index, values) => {
      // const values = [...stockcodeList];
      values[index]["code"] = formatCode(code, UpcTenCode);
      values[index]["upccode"] = formatCode(code, UpcTenCode);
      setStockcodeList(values);
      // formatCode(code, UpcTenCode);
    }, 5),
    []
  );

  const StockcodeData = (index, event) => {
    setFocus(false);
    const values = [...stockcodeList];
    values[index]["code"] = event.target.value;
    values[index]["upccode"] = event.target.value;
    setStockcodeList(values);
  };

  const UpdatedStockcodeData = (index, event) => {
    setFocus(false);
    const values = [...stockcodeList];
    const updatedcode =
      UpcTenCode > 0 && checkAnotherItem()
        ? debounceFetchUsers(event.target.value, UpcTenCode, index, values)
        : event.target.value;
    if (!(UpcTenCode > 0 && checkAnotherItem())) {
      values[index]["code"] = updatedcode;
      values[index]["upccode"] = updatedcode;
      setStockcodeList(values);
    }
  };
  const generateNumber = useRef([]);

  const updateStockCode = (stockcode) => {
    setFocus(false);
    const values = [...stockcodeList];
    if (values.filter((item) => item.code === "").length > 0) {
      values[values.length - 1]["code"] =
        UpcTenCode > 0 ? formatCode(stockcode, UpcTenCode) : stockcode;
      values[values.length - 1]["upccode"] =
        UpcTenCode > 0 ? formatCode(stockcode, UpcTenCode) : "";
      setStockcodeList(values);
    } else {
      AddStockMorecode(stockcode);
    }
  };

  const AddStockMorecode = (stockcode) => {
    const inputstate = {
      code: UpcTenCode > 0 ? formatCode(stockcode, UpcTenCode) : stockcode,
      upccode: UpcTenCode > 0 ? formatCode(stockcode, UpcTenCode) : "",
    };
    setStockcodeList((prev) => [...prev, inputstate]);
  };

  const checkPriceValidation = (list) => {
    if (list.length > 0) {
      const check = list.some(
        (item) => item.price === "" || item.price === parseFloat(0).toFixed(2)
      );
      if (check) {
        toast.error("please add price for all modifiers");
        return false;
      } else {
        return true;
      }
    }
  };

  function checkAnotherItem() {
    if (props?.updateData !== undefined) {
      return false;
    } else {
      return true;
    }
  }

  function CheckStockCodeFirst(duplicateBarCode, genratedCode, values) {
    const store = getDeviceAndStore();
    if (CheckAddedData(values, genratedCode)) {
      if (containsDuplicates(stockcodeList, "code")) {
        setShow(LOADING);
        getListService
          .checkSingleRow(
            STOCKCODECHECK,
            genratedCode !== "" ? genratedCode : stockcodeList,
            genratedCode,
            duplicateBarCode,
            store[1]
          )
          .then((response) => {
            if (!response.data.status) {
              if (genratedCode !== "") {
                setStockcodeList(values);
                setShow(false);
              } else {
                if (!checkUserPermission("allow_edit_item_edit")) {
                  setAuth(true);
                  setUpdate(true);
                } else {
                  onSubmitHandle();
                  setShow(false);
                  props.closeEditModel(false);
                }
              }
            } else {
              setShow(false);
              setShow(STOCKCODECHECK);
              setDuplicateItem(response.data.data);
            }
          });
      } else {
        validateUniqueCode("code");
        setShow(DUPLICATE);
        setDeletetype("There are duplicate stock code please fix that!");
      }
    }
  }

  function CheckAddedData(codeLength, genratedCode) {
    if (codeLength[0].code === "") {
      toast.error("Stockcode is required");
      return false;
    } else if (genratedCode === "" && name === "") {
      toast.error("Name is required");
      return false;
    } else if (formData.shortcutkeys && item_shortcut_name == "") {
      toast.error("Item Shortcut Name must not be blank");
      return false;
    } else {
      return true;
    }
  }

  const GenerateBarCode = (index) => {
    setFocus(false);
    const values = [...stockcodeList];
    generateNumber.current[index].value =
      9999900000 + Math.floor(Math.random() * 90000) + 10000;
    const genratedCode = generateNumber.current[index].value;
    values[index][generateNumber.current[index].name] = genratedCode;
    values[index]["upccode"] = genratedCode;
    if (duplicateBarCode === 0) {
      CheckStockCodeFirst(duplicateBarCode, genratedCode, values);
    } else {
      setStockcodeList(values);
    }
  };

  function validateUniqueCode(codename) {
    const codes = $("input[name='" + codename)
      .map(function () {
        return $.trim($(this).val());
      })
      .get();
    let invalid = false;
    $("input[name='" + codename).each(function () {
      let count = 0;
      let value = $.trim($(this).val());
      $(this).attr("style", "");
      for (let i = 0; i < codes.length; ++i) {
        if (codes[i] === value && value !== "") count++;
      }
      if (count > 1) {
        invalid = true;
        $(this).attr("style", "color:red;");
      }
    });
    return invalid;
  }

  const handleChecked = (e) => {
    setFocus(false);
    if (e.target.name === "promptforqty") {
      setPromptShow(e.target.checked);
      const values = [...stockList];
      if (stockList.length > 1) {
        for (var i = 0; i < stockList.length; i++) {
          values[i]["prompt"] = e.target.checked;
        }
        setStockList(values);
      }
    }
    setformData({
      ...formData,
      [e.target.name]: e.target.checked,
      ...(e.target.name === "shortcutkeys" &&
        e.target.checked && {
        item_shortcut_name: formData?.name?.slice(0, 15) || "",
      }),
    });
  };

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    if (VailidateImageExtention(event.target.files[0])) {
      const options = {
        maxSizeMB: IMAGE_COMPRESS_UPLOAD_SIZE,
        maxWidthOrHeight: IMAGE_COMPRESS_MAX_WIDTH_HEIGHT,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        fileUploadFunction(compressedFile);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("Please select valid image.");
    }
  }

  const fileUploadFunction = async (enrImge) => {
    try {
      if (enrImge) {
        const uploadData = new FormData();
        uploadData.append("file", enrImge, enrImge.name);
        if (uploadData) {
          if (id !== undefined) {
            uploadData.append("id", props?.itemid);
          }
          await getListService
            .ImageUpload(ITEMIMAGE, uploadData)
            .then((response) => {
              if (response.statusCode === 200 && response.statusText === "OK") {
                setItemImgData(response.data.url);
              }
            })
            .catch((error) => {
              toast.error(error.message);
            });
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleClose = (type) => {
    setShow(false);
    setDuplicateItem([]);
    if (type === STOCKCODECHECK) {
      props.addAnotherItem(false);
    }
    setDeletetype(null);
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    if (duplicateBarCode) {
      onSubmitHandle();
    }
    setShow(false);
  };

  const onSubmitHandle = () => {
    const FormData = {
      DATA: { ...formData },
      COLLECTEDITEM: { fee: [...collectFee], tag: [...collectTag] },
      ADDITIONALFEE: { fee: [...additionalfee] },
      TAG: { tags: [...tag] },
      MODIFIER: {
        list: !couponItemType ? [...stockList] : [couponTypeData],
      },
      STOCKCODE: { code: [...stockcodeList.filter((e) => e.code !== "")] },
      MERGE: 0,
      StoreId: getDeviceAndStore()[1],
      user_id: authUserId(),
      user_name: "Admin",
      uuid: uuidv4(),
    };
    if (checkPriceValidation(stockList)) {
      const data = { loading: true, msg: "" };
      dispatch(loadingAction(data, LOADING));
      if (id !== undefined && !checkAnotherItem()) {
        let oldTagIds = oldtag.map((e) => e.id);
        let addedTags = tag.filter((e) => !oldTagIds.includes(e.id));
        let addedFees = additionalfee.filter((e) => !e.fee);
        FormData.TAG = { tags: [...addedTags] };
        FormData.ADDITIONALFEE = { fee: [...addedFees] };
        dispatch(editActionFunction(ITEM, FormData, id, ITEMUPDATE));
      } else {
        dispatch(addActionFunction(ITEM, FormData, ITEMADDED));
        if (closeModal) {
          setformData({
            ...formData,
            id: "",
            supplier: "",
            totalitem: Number(0).toFixed(2),
            cases: Number(0).toFixed(2),
            vendoritemno: "",
            sku: "",
            unit_per_case: Number(1).toFixed(2),
            reorder_point: 1,
            reorder_value: 1,
            item_rank: "",
            vendoritemname: "",
            price: Number(0).toFixed(2),
            note: "",
            case_cash_total: Number(0).toFixed(2),
            promptforqty: true,
            printlabel: false,
            autoupdate: false,
            donottrackinventory: false,
            shortcutkeys: false,
            closeoutitem: false,
            donotdiscount: false,
            showtoweb: false,
            hideinventory: false,
            ebteligible: false,
            default_qty: 1,
            item_type: 1,
            remind_date: "",
            bottle_deposit: "",
            item_shortcut_no: "",
            item_shortcut_name: "",
            color_code: "#00ff00",
            pointsmultiplier: null,
            pointsrequired: null,
          });
          setStockcodeList([{ code: "", upccode: "" }]);
          setTag([]);
          setAdditionalfee([]);
          setStockList([initialModifers(1)]);
        }
      }
      props.closeEditModel(true);
    }
  };

  function initialModifers(qty) {
    return {
      qty: qty,
      qty_on_hand: Number(0).toFixed(2),
      latest_cost: Number(0).toFixed(2),
      prompt: formData.promptforqty,
      markup: Number(0).toFixed(2),
      margin: Number(0).toFixed(2),
      avg_cost: Number(0).toFixed(2),
      price: Number(0).toFixed(2),
    };
  }
  const handlePrintLabel = (inputfield) => {
    const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
    const data = props?.itemlist.find((itm) => itm.id === formData?.id);
    const modifiedData = { ...data, modifier: [{ ...inputfield, store: props.storeId }] }
    if (
      wpos_config &&
      wpos_config?.hasOwnProperty("label_printer") &&
      wpos_config.label_printer != undefined &&
      wpos_config.label_printer != ""
    ) {
      printLabel(wpos_config.label_printer, modifiedData);
    }
  }

  const printLabel = async (label_printer, item) => {
    const Dymo = require("dymojs"),
      dymo = new Dymo({ printerName: label_printer });

    const template = props?.labeltemplates.filter(
      (item) => item.id === posSideSetting.template
    );
    // Parse XML to JSON
    const jsonData = xml2json(template[0]?.content, {
      compact: true,
      spaces: 4,
    });
    const parsedData = JSON.parse(jsonData);

    // Find the <Name>Price</Name> element and replace the value
    const objectInfo = parsedData.DieCutLabel.ObjectInfo;
    const priceObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "Price"
    );
    if (priceObject) {
      priceObject.TextObject.StyledText.Element.String._text =
        "$" + item?.modifier[0]?.price;
    }

    const nameObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "Name1"
    );
    if (nameObject) {
      nameObject.TextObject.StyledText.Element.String._text = item.name ?? "";
    }

    const name2Object = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "Name2"
    );
    if (name2Object) {
      name2Object.TextObject.StyledText.Element.String._text = item.name2 ?? "";
    }

    const supplierObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "SupplierName"
    );
    if (supplierObject) {
      supplierObject.TextObject.StyledText.Element.String._text =
        item.supplier_name ?? "";
    }

    const promoObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "PromotionName"
    );
    if (promoObject) {
      promoObject.TextObject.StyledText.Element.String._text =
        item.PromotionName ?? "";
    }

    const promoPriceObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "PromotionPrice"
    );
    if (promoPriceObject) {
      promoPriceObject.TextObject.StyledText.Element.String._text =
        item.PromotionPrice ?? "";
    }

    const promoQtyObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "PromotionQty"
    );
    if (promoQtyObject) {
      promoQtyObject.TextObject.StyledText.Element.String._text =
        item.PromotionQty ?? "";
    }

    const codeObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "code"
    );
    if (codeObject) {
      codeObject.TextObject.StyledText.Element.String._text = item.code ?? "";
    }

    // Convert JSON back to XML
    const modifiedXmlData = json2xml(parsedData, {
      compact: true,
      ignoreComment: true,
      spaces: 4,
    });
    await dymo.print(label_printer, modifiedXmlData);
  };

  const AdvanceModifier = () => {
    const [clones, setClones] = useState([]);
    const [maxClones, setMaxClones] = useState(getMaxClones());
    useEffect(() => {
      // Update maxClones when the window is resized
      const handleResize = () => {
        setMaxClones(getMaxClones());
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const handleCloneClick = () => {
      setClones([...clones, { id: clones.length }]);
    };

    const handleRemoveClick = (id) => {
      setClones(clones.filter((clone) => clone.id !== id));
    };

    const renderRow = (rowKey) => (
      <div className="navigating" key={rowKey}>
        <Form.Group className="form-floating nav-control-outer first-qty">
          <FormField
            label="Qty"
            name="qty"
            type="number"
            className="nav-control"
            placeholder="Qty"
          />
        </Form.Group>
        <div className="custom-react-select">
          <Form.Group>
            <ReactCustom
              onSelectChange={handleSelectChange}
              placeholder="Stockcode"
              options={rank_option}
              value={item_rank}
              formField="item_rank"
              readOnly={false}
              stringData={true}
            />
          </Form.Group>
        </div>

        <Form.Group className="form-floating nav-control-outer">
          <FormField
            label="Price"
            className="nav-control"
            type="number"
            name="price"
            placeholder="Price"
          />
        </Form.Group>
        <Form.Group className="form-floating nav-control-outer avg-cost">
          <FormField
            label="Avg Cost"
            className="nav-control"
            type="number"
            name="avg_cost"
            placeholder="Avg Cost"
          />
        </Form.Group>
        <Form.Group className="form-floating nav-control-outer">
          <FormField
            label="Margin"
            className="nav-control"
            type="number"
            name="margin"
            placeholder="Margin"
          />
        </Form.Group>
        <Form.Group className="form-floating nav-control-outer">
          <FormField
            label="Markup"
            type="number"
            className="nav-control"
            name="markup"
            placeholder="Markup"
          />
        </Form.Group>


        <Form.Group className="form-floating nav-control-outer notes">
          <FormField
            label="Note"
            className="nav-control "
            type="text"
            name="Note"
            placeholder="Note"
          />
        </Form.Group>
        <Form.Check
          className="custom-checkbox"
        >
          <FormCheck.Input
            className="me-2 custom-checkbox-bg"
            name="prompt"
          />
        </Form.Check>
        {/* Conditional rendering of buttons */}
        {rowKey === 0 ? (  // Display "Add Modifier" button on the first row
          <div className="link">
            <Button
              className="p-0"
              variant="link"
              title="Add Advance Modifier"
              onClick={handleCloneClick}
            >
              <Image src={props?.dynamicImages?.faPLus} alt="add Icon" />
            </Button>
          </div>
        ) : (  // Display "Remove Modifier" button on other rows
          <div className="link">
            <Button
              className="p-0"
              variant="link"
              title="Remove Advance Modifier"
              onClick={() => handleRemoveClick(rowKey)}
            >
              <Image src={props?.dynamicImages?.faCross} alt="remove Icon" />
            </Button>
          </div>
        )}
      </div>
    );
    function getMaxClones() {
      // Set maxClones based on screen width (e.g., 6 for desktop, 2 for mobile)
      return window.innerWidth >= 768 ? 6 : 3;
    }
    const containerClass = clones.length >= maxClones ? 'scroll-auto' : '';

    return (

      <div className={`advance-modifier ${containerClass}`}>
        {/* Render the first row only if there are no clones */}
        {clones.length === 0 && renderRow(0)}

        {/* Render the cloned rows */}
        {clones.map((clone) => (
          renderRow(clone.id)
        ))}

        {/* Save/Update Button */}
        <Button
          variant="primary px-5 position-absolute form-cta-btn"
          type="button"
          onClick={() => CheckStockCodeFirst("", stockcodeList)}
          disabled={props.disableButton ? true : false}
          title={!checkAnotherItem() ? "Update" : "Save"}
        >
          {!checkAnotherItem() ? "Update" : "Save"}
        </Button>
      </div>

    );
  };

  return (
    <>
      <BarcodeReader onError={handleError} onScan={handleScan} />
      <Form autoComplete="off">
        <Tab.Container defaultActiveKey="Details">
          <Nav
            fill
            variant="pills"
            style={{ border: "none" }}
            className="custom-tab-nav flex-sm-row"
          >
            <Nav.Item>
              <Nav.Link eventKey="Details" className="mb-sm-3 mb-md-0">
                <Image
                  width="17px"
                  height="16px"
                  className="me-2"
                  src={props?.dynamicImages?.details}
                  alt="Details Icon"
                />
                Details
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Options" className="mb-sm-3 mb-md-0">
                <Image
                  width="17px"
                  height="16px"
                  className="me-2"
                  src={props?.dynamicImages?.options}
                  alt="Options Icon"
                />
                Options
              </Nav.Link>
            </Nav.Item>
            {id !== undefined && !checkAnotherItem() && (
              <Nav.Item>
                <Nav.Link eventKey="Promotions" className="mb-sm-3 mb-md-0">
                  <Image
                    width="17px"
                    height="18px"
                    className="me-2"
                    src={props?.dynamicImages?.promotions}
                    alt="Promotions Icon"
                  />
                  Promotions
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <Tab.Content className="mt-2">
            {/* {(props?.loading || show === LOADING) && ( */}
            {show === LOADING && <LoaderSpinner />}
            <Tab.Pane eventKey="Details">
              <Row>
                <Col md={12} className="mb-3 px-0">
                <div
                    className={`box automation-settings three-column ${stockcodeList?.length >= 6 ? `scroll-auto` : ""
                      }`}
                  >
                   
                    {stockcodeList.length > 0 &&
                      stockcodeList.map((inputfield, index) => {
                        inputfield = { ...inputfield, index };
                        return (
                          <div className="automation-input" key={index}>
                            <div className="form-floating">
                              <input
                                type="text"
                                name="code"
                                placeholder="Stockcode"
                                value={
                                  UpcTenCode > 0 && !checkAnotherItem()
                                    ? formatCode(inputfield.code, UpcTenCode)
                                    : inputfield.code
                                }
                                style={{ color: inputfield.same ? "red" : "" }}
                                ref={(el) =>
                                  (generateNumber.current[index] = el)
                                }
                                onFocus={(e) => {
                                  e.target.select();
                                  setFocus(false);
                                }}
                                onChange={(event) =>
                                  StockcodeData(index, event)
                                }
                                onBlur={(event) => {
                                  UpdatedStockcodeData(index, event);
                                }}
                                onKeyUp={() => validateUniqueCode("code")}
                                className="form-control"
                              />
                              <input
                                type="text"
                                name="upccode"
                                value={inputfield.upccode}
                                onChange={(e) => e}
                                className="d-none"
                              />
                              <label className="form-label">Stockcode</label>
                            </div>
                            {inputfield.code?.length < 1 && (
                              <Button
                                className="p-0 mx-2"
                                variant="link"
                                onClick={(event) =>
                                  GenerateBarCode(index, event)
                                }
                                title="Generate Random Barcode"
                              >
                                <img
                                  src={props?.dynamicImages?.barcodeIcon}
                                  alt="Generate Random Barcode"
                                />
                              </Button>
                            )}
                            {index === 0 && (
                              <div className="link add-icon">
                                <Button
                                  className="p-0"
                                  variant="link"
                                  title="Add Stock Code"
                                  onClick={(e) => AddStockcode(e)}
                                >
                                  <Image
                                    src={props?.dynamicImages?.faPLus}
                                    alt="add Icon"
                                  />
                                </Button>
                              </div>
                            )}
                            {index > 0 && (
                              <div className="link">
                                <Button
                                  className="p-0"
                                  variant="link"
                                  title="Remove Stock Code"
                                  onClick={() =>
                                    RemoveStockcode(index, inputfield.id)
                                  }
                                >
                                  <Image
                                    src={props?.dynamicImages?.faCross}
                                    alt="remove Icon"
                                  />
                                </Button>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </Col>
                <Col md={6} className="mb-4">
                  <Form.Group className="form-floating label-text">
                    <FormField
                      label="Name"
                      type="text"
                      name="name"
                      value={name}
                      className="capitalCase"
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "all")}
                      placeholder="Name"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-4">
                  <Form.Group className="form-floating label-text mb-md-0">
                    <FormField
                      label="Qty On Hand (Items)"
                      type="number"
                      name="totalitem"
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      value={totalitem}
                      onChange={(e) => handleChange(e, "enterqty")}
                      placeholder="Qty On Hand (Items)" state
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-4">
                  <Form.Group className="form-floating label-text">
                    <FormField
                      label="Qty On Hand (Cases)"
                      type="number"
                      name="cases"
                      value={cases}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "entercases")}
                      placeholder="Qty On Hand (Cases)"
                    />
                  </Form.Group>
                </Col>
                
                {couponItemType ? (
                  <>
                    <Col className="mb-3 col-12 col-md-4">
                      <Form.Group className="form-floating nav-control-outer first-qty">
                        <FormField
                          label="Qty"
                          name="qty"
                          type="number"
                          tabIndex="0"
                          className="nav-control"
                          value={couponTypeData.qty}
                          onChange={(event) =>
                            setCouponTypeData({
                              ...couponTypeData,
                              qty: event.target.value,
                            })
                          }
                          placeholder="Qty"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3 col-12 col-md-4">
                      <Form.Group className="form-floating nav-control-outer">
                        <FormField
                          label={
                            formData.item_type == 12 ? "Price" : "Percentage"
                          }
                          className="nav-control"
                          type="number"
                          name="price"
                          tabIndex="0"
                          value={couponTypeData.price}
                          onChange={(event) =>
                            setCouponTypeData({
                              ...couponTypeData,
                              price: event.target.value,
                            })
                          }
                          placeholder={
                            formData.item_type == 12 ? "Price" : "Percentage"
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3 col-12 col-md-4">
                      <Form.Group>
                        <ReactCustom
                          onSelectChange={(fieldname, e) =>
                            setCouponTypeData({
                              ...couponTypeData,
                              type: fieldname,
                            })
                          }
                          placeholder="Type"
                          options={couponTypeOptions}
                          value={couponTypeData.type}
                          formField="type"
                          readOnly={false}
                          upperCase={true}
                          multi={false}
                        />
                      </Form.Group>
                    </Col>
                  </>
                )
                : (
                  <Col className="mb-3 col-12">
                <div
                    className={`box automation-settings seven-column  ${stockList.length >= 5 ? "scroll-auto" : ""
                      } ${stockList.length > 1 
                        ? "padding-res"
                        : ""
                      }`}
                  >
                      <div className="top-label">
                      <label className="form-check-label">Qty</label>
                      <label className="form-check-label">Price</label>
                      <label className="form-check-label">Avg Cost</label>
                      <label className="form-check-label">	Margin</label>
                      <label className="form-check-label">Markup</label>
                      <label className="form-check-label">Latest Cost</label>
                      <label className="form-check-label qty-on-hand">Qty On Hand</label>
                      <label className="form-check-label prompt">Prompt</label>
                      <label className="form-check-label advaced-img"></label>
                      <label className="form-check-label link"></label>
                    </div>
                    {stockList &&
                      stockList.map((inputfield, index) => (
                        <div
                          className="automation-input navigating"
                          key={index}
                        >
                          {index > 0 && parseInt(printLabel) !== 0 && (
                            <div className="tag-img">
                              <img
                                src={props?.dynamicImages?.tag}
                                alt="Print Label"
                                onClick={() => handlePrintLabel(inputfield)}
                              />
                            </div>
                          )}

                          <Form.Group className="form-floating nav-control-outer first-qty">
                            <FormField
                              label="Qty"
                              name="qty"
                              type="number"
                              tabIndex="0"
                              className="nav-control"
                              value={inputfield.qty}
                              onFocus={(e) => {
                                e.target.select();
                                setFocus(true);
                              }}
                              onChange={(event) =>
                                StockData(index, event, "qty")
                              }
                              readOnly={index === 0 ? true : false}
                              placeholder="Qty"
                            />
                          </Form.Group>
                          <Form.Group className="form-floating nav-control-outer">
                            <FormField
                              label="Price"
                              className="nav-control"
                              type="number"
                              name="price"
                              tabIndex="0"
                              value={inputfield.price}
                              onFocus={(e) => {
                                e.target.select();
                                setFocus(true);
                              }}
                              onChange={(event) =>
                                StockData(index, event, "price")
                              }
                              placeholder="Price"
                            />
                          </Form.Group>
                          <Form.Group className="form-floating nav-control-outer">
                            <FormField
                              label="Avg Cost"
                              className="nav-control"
                              type="number"
                              name="avg_cost"
                              tabIndex="0"
                              value={inputfield.avg_cost}
                              onFocus={(e) => {
                                e.target.select();
                                setFocus(true);
                              }}
                              onChange={(event) =>
                                StockData(index, event, "avg_cost")
                              }
                              onBlur={(event) =>
                                SetlatestCostData(index, event, "avg_cost")
                              }
                              placeholder="Avg Cost"
                            />
                          </Form.Group>
                          <Form.Group className="form-floating nav-control-outer">
                            <FormField
                              label="Margin"
                              className="nav-control"
                              type="number"
                              name="margin"
                              tabIndex="0"
                              value={inputfield.margin}
                              onFocus={(e) => {
                                e.target.select();
                                setFocus(true);
                              }}
                              onChange={(event) =>
                                StockData(index, event, "margin")
                              }
                              placeholder="Margin"
                            />
                          </Form.Group>
                          <Form.Group className="form-floating nav-control-outer">
                            <FormField
                              label="Markup"
                              type="number"
                              className="nav-control"
                              name="markup"
                              value={inputfield.markup}
                              tabIndex="0"
                              onFocus={(e) => {
                                e.target.select();
                                setFocus(true);
                              }}
                              onChange={(event) =>
                                StockData(index, event, "markup")
                              }
                              placeholder="Markup"
                            />
                          </Form.Group>
                          <Form.Group className="form-floating nav-control-outer">
                            <FormField
                              label="Latest Cost"
                              type="number"
                              className="nav-control"
                              name="latest_cost"
                              tabIndex="0"
                              onFocus={(e) => {
                                e.target.select();
                                setFocus(true);
                              }}
                              value={inputfield.latest_cost}
                              onChange={(event) =>
                                StockData(index, event, "latest_cost")
                              }
                              placeholder="Latest Cost"
                            />
                          </Form.Group>
                          <Form.Group className="form-floating nav-control-outer qty-on-hand">
                            <FormField
                              label="Qty On Hand"
                              className="nav-control"
                              type="text"
                              readOnly
                              name="qty_on_hand"
                              value={
                                formData.totalitem > Number(0).toFixed(2)
                                  ? inputfield.qty_on_hand
                                  : Number(0).toFixed(2)
                              }
                              onChange={() => { }}
                              placeholder="Qty On Hand"
                            />
                          </Form.Group>
                          <Form.Check
                            className={`custom-checkbox d-flex flex-column ${promptShow && stockList.length > 1 ? `` : `d-none`
                              }`}
                          >
                            <FormCheck.Label htmlFor={`prompt${index}`}>
                              Prompt
                            </FormCheck.Label>
                            <FormCheck.Input
                              className="custom-checkbox-bg"
                              name="prompt"
                              id={`prompt${index}`}
                              checked={inputfield.prompt}
                              onChange={(event) => Stockchecked(index, event)}
                            />
                          </Form.Check>
                          <div className="advaced-img">
                            <img
                              src={props?.dynamicImages?.advancedUpdate}
                              alt="Print Label"
                              // className="tag-img"
                              onClick={handleDialogChange}
                            />
                          </div>
                          {index === 0 && (
                            <div className="link add-icon">
                              <Button
                                className="p-0"
                                variant="link"
                                title="Add Modifier"
                                onClick={(e) => AddStock(e)}
                              >
                                <Image
                                  src={props?.dynamicImages?.faPLus}
                                  alt="add Icon"
                                />
                              </Button>
                            </div>
                          )}
                          {index > 0 && (
                            <div className="link">
                              <Button
                                className="p-0"
                                variant="link"
                                title="Remove Modifier"
                                onClick={() =>
                                  RemoveStock(index, inputfield.id)
                                }
                              >
                                <Image
                                  src={props?.dynamicImages?.faCross}
                                  alt="Cross Icon"
                                />
                              </Button>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </Col>
                )}
                
                <Col md={3} className="mb-4 custom-react-select">
                  <Form.Group>
                    <ReactSelect
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      styles={{
                        valueContainer: (provided) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue || state.selectProps.inputValue
                              ? -15
                              : "7px",
                          transition: "top 0.1s, font-size 0.1s",

                          transform:
                            (state.hasValue || state.selectProps.inputValue) &&
                            "scale(0.85)",
                          opacity:
                            (state.hasValue || state.selectProps.inputValue) &&
                            1,
                        }),
                      }}
                      classNamePrefix="react-select"
                      options={ItemSizeList?.map((list) => {
                        return {
                          ...list,
                          value: list.id,
                          label: list.name.toUpperCase(),
                        };
                      })}
                      value={ItemSizeList?.map((item) => {
                        return {
                          ...item,
                          value: item.id,
                          label: item.name.toUpperCase(),
                        };
                      }).filter((item) => {
                        return item.value === item_size;
                      })}
                      onChange={(e) => handleSelectChange(e, "item_size")}
                      placeholder={"Item Size"}
                    />
                  </Form.Group>
                </Col>

                <Col md={3} className="mb-4 custom-react-select">
                  <Form.Group>
                    <ReactSelect
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      styles={{
                        valueContainer: (provided) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue || state.selectProps.inputValue
                              ? -15
                              : "7px",
                          transition: "top 0.1s, font-size 0.1s",

                          transform:
                            (state.hasValue || state.selectProps.inputValue) &&
                            "scale(0.85)",
                          opacity:
                            (state.hasValue || state.selectProps.inputValue) &&
                            1,
                        }),
                      }}
                      classNamePrefix="react-select"
                      options={CategoryList?.map((list) => {
                        return {
                          ...list,
                          value: list.id,
                          label: list.name.toUpperCase(),
                        };
                      })}
                      value={CategoryList?.map((item) => {
                        return {
                          ...item,
                          value: item.id,
                          label: item.name.toUpperCase(),
                        };
                      }).filter((item) => {
                        return item.value === category;
                      })}
                      onChange={(e) => handleSelectChange(e, "category")}
                      placeholder={"Category"}
                    />
                  </Form.Group>
                </Col>

                <Col md={3} className="mb-4 custom-react-select">
                  <Form.Group>
                    <ReactSelect
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      styles={{
                        valueContainer: (provided) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue || state.selectProps.inputValue
                              ? -15
                              : "7px",
                          transition: "top 0.1s, font-size 0.1s",

                          transform:
                            (state.hasValue || state.selectProps.inputValue) &&
                            "scale(0.85)",
                          opacity:
                            (state.hasValue || state.selectProps.inputValue) &&
                            1,
                        }),
                      }}
                      classNamePrefix="react-select"
                      options={SupplierList?.map((list) => {
                        return {
                          ...list,
                          value: list.id,
                          label: list.name.toUpperCase(),
                        };
                      })}
                      value={SupplierList?.map((item) => {
                        return {
                          ...item,
                          value: item.id,
                          label: item.name.toUpperCase(),
                        };
                      }).filter((item) => {
                        return item.value === supplier;
                      })}
                      onChange={(e) => handleSelectChange(e, "supplier")}
                      placeholder={"Supplier"}
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-4 custom-react-select">
                  <Form.Group>
                    <ReactSelect
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      styles={{
                        valueContainer: (provided) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue || state.selectProps.inputValue
                              ? -15
                              : "7px",
                          transition: "top 0.1s, font-size 0.1s",

                          transform:
                            (state.hasValue || state.selectProps.inputValue) &&
                            "scale(0.85)",
                          opacity:
                            (state.hasValue || state.selectProps.inputValue) &&
                            1,
                        }),
                      }}
                      classNamePrefix="react-select"
                      options={props?.taxList?.map((item) => {
                        return {
                          ...item,
                          value: item.id,
                          label: item.name,
                        };
                      })}
                      value={props?.taxList
                        ?.map((item) => {
                          return {
                            ...item,
                            value: item.id,
                            label: item.name,
                          };
                        })
                        .filter((item) => {
                          return item.value === tax;
                        })}
                      onChange={(e) => handleSelectChange(e, "tax")}
                      placeholder={"Tax"}
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-4 d-flex align-items-end">
                  <Form.Group className="form-floating label-text">
                    <FormField
                      label="Vendor Item No"
                      type="number"
                      name="vendoritemno"
                      value={vendoritemno}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "all")}
                      placeholder="Vendor Item No"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-4 d-flex align-items-end">
                  <Form.Group className="form-floating label-text">
                    <FormField
                      label="SKU"
                      type="number"
                      name="sku"
                      value={sku}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "all")}
                      placeholder="SKU"
                    />
                  </Form.Group>
                </Col>

                <Col md={3} className="mb-4 d-flex align-items-end">
                  <Form.Group className="form-floating label-text">
                    <FormField
                      label="Units Per Case"
                      type="number"
                      name="unit_per_case"
                      value={unit_per_case}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "unit_per_case")}
                      placeholder="Units Per Case"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-4 d-flex align-items-end">
                  <Form.Group className="form-floating label-text">
                    <FormField
                      label="Case Cost Total"
                      type="number"
                      name="case_cash_total"
                      value={case_cash_total}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "all")}
                      placeholder="Case Cost Total"
                    />
                  </Form.Group>
                </Col>

                <Col md={2} className="mb-4 mb-md-0">
                  <Form.Group className="form-floating">
                    <FormField
                      label="Reorder Point"
                      type="number"
                      name="reorder_point"
                      value={reorder_point}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "all")}
                      placeholder="Reorder Point"
                    />
                  </Form.Group>
                </Col>

                <Col md={2} className="mb-4 mb-md-0">
                  <Form.Group className="form-floating">
                    <FormField
                      label="Reorder Value"
                      type="number"
                      name="reorder_value"
                      value={reorder_value}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "all")}
                      placeholder="Reorder Value"
                    />
                  </Form.Group>
                </Col>

                <Col md={2} className="mb-4 custom-react-select">
                  <Form.Group>
                    <ReactSelect
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      styles={{
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue || state.selectProps.inputValue
                              ? -15
                              : "7px",
                          transition: "top 0.1s, font-size 0.1s",

                          transform:
                            (state.hasValue || state.selectProps.inputValue) &&
                            "scale(0.85)",
                          opacity:
                            (state.hasValue || state.selectProps.inputValue) &&
                            1,
                        }),
                      }}
                      classNamePrefix="react-select"
                      options={rank_option}
                      value={rank_option.filter((item) => {
                        return item.value === item_rank;
                      })}
                      onChange={(e) => handleSelectChange(e, "item_rank")}
                      placeholder={"Rank"}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs="12"
                  className="d-flex flex-column flex-sm-row align-items-sm-center "
                >
                  <Form.Check
                    label="Prompt For Qty"
                    name="promptforqty"
                    id="promptforqty"
                    htmlFor="promptforqty"
                    checked={formData.promptforqty}
                    onChange={(e) => handleChecked(e)}
                    className={` me-3 ${stockList.length < 2 ? `d-none` : ""
                  }`}
                  />
                  {checkAnotherItem() && (
                    <Form.Check
                      label="Add Another Item"
                      name="addanotheritem"
                      id="addanotheritem"
                      htmlFor="addanotheritem"
                      checked={closeModal}
                      onChange={(e) => setClosemodal(e.target.checked)}
                      className=" me-3"
                    />
                  )}
                  {(props?.PosSetting?.OPTION?.enable_remote_label_print || 0) >
                    0 && (
                      <Form.Check
                        label="Print Label"
                        name="printlabel"
                        id="printlabel"
                        htmlFor="printlabel"
                        checked={formData.printlabel}
                        onChange={(e) => handleChecked(e)}
                        className="me-md-3 "
                      />
                    )}
                    
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="Options">
              <Row className="mb-3">
              <Col sm={6} md={4}>
                  <Form.Check
                    label="Do Not Auto Update"
                    name="autoupdate"
                    id="autoupdate"
                    htmlFor="autoupdate"
                    checked={formData.autoupdate}
                    onChange={(e) => handleChecked(e)}
                    className="me-4 mb-2"
                  />
                  <Form.Check
                    label="Do Not Track Inventory"
                    name="donottrackinventory"
                    id="donottrackinventory"
                    htmlFor="donottrackinventory"
                    checked={formData.donottrackinventory}
                    onChange={(e) => handleChecked(e)}
                    className="me-4 mb-2"
                  />
                  <div className="d-flex align-items-center">
                    <Form.Check
                      label="Add To Shortcut Keys"
                      name="shortcutkeys"
                      id="shortcutkeys"
                      htmlFor="shortcutkeys"
                      checked={formData.shortcutkeys}
                      onChange={(e) => handleChecked(e)}
                      className="me-4 mb-2"
                    />
                    <ColorPicker
                      colorCode={colorCode}
                      updateColorPicker={color_code}
                    />
                  </div>
                  <Form.Check
                    label="Close Out Item"
                    name="closeoutitem"
                    id="closeoutitem"
                    htmlFor="closeoutitem"
                    checked={formData.closeoutitem}
                    onChange={(e) => handleChecked(e)}
                    className="me-4 mb-2 mb-sm-0"
                  />
                </Col>
                <Col sm={6} md={4}>
                  <Form.Check
                    label="Do Not Discount"
                    name="donotdiscount"
                    id="donotdiscount"
                    htmlFor="donotdiscount"
                    checked={formData.donotdiscount}
                    onChange={(e) => handleChecked(e)}
                    className="me-4 mb-2"
                  />
                  <Form.Check
                    label="Do Not Show To Webstore"
                    name="showtoweb"
                    id="showtoweb"
                    htmlFor="showtoweb"
                    checked={formData.showtoweb}
                    onChange={(e) => handleChecked(e)}
                    className="me-4 mb-2"
                  />
                  <Form.Check
                    label="Hide Inventory"
                    name="hideinventory"
                    id="hideinventory"
                    htmlFor="hideinventory"
                    checked={formData.hideinventory}
                    onChange={(e) => handleChecked(e)}
                    className="me-4 mb-2"
                  />
                  <Form.Check
                    label="EBT Eligible"
                    name="ebteligible"
                    id="ebteligible"
                    htmlFor="ebteligible"
                    checked={formData.ebteligible}
                    onChange={(e) => handleChecked(e)}
                    className="me-4"
                  />
                </Col>
                <Col sm={12} md={4 }>

                <div className="uploader">
                  <Button variant="outline-seagreen px-3" title="Add Custom Image">
                    <Image
                      className="me-2"
                      src={props?.dynamicImages?.plus}
                      alt="plus Icon"
                    />
                    Add Custom Image
                  </Button>
                  <FormField
                    type="file"
                    name="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e)}
                    placeholder="Image"
                  />
                  {itemImgData && (
                    <div className="file-list">
                      <img
                        width={128}
                        height={64}
                        src={props?.dynamicImages?.itemImgData}
                      />
                    </div>
                  )}
                  <p className="mt-2">
                    * Minimum support file size is 1200*1200 <br />
                    * image format must be in jpeg | jpg | png <br /> * image
                    size must be less then 2 MB
                  </p>
                </div>
                </Col>
              </Row>
              <div className="divider"></div>
              <Row>
              <Col md={6} lg={2} className="mb-3">
                  <Form.Group className="form-floating">
                    <FormField
                      label="Default Qty"
                      type="number"
                      name="default_qty"
                      value={default_qty}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "all")}
                      placeholder="Default Qty"
                    />
                  </Form.Group>
                </Col>

                <Col md={6} lg={2} className="mb-3">
                  <Form.Group className="form-floating">
                    <FormField
                      label="Min Price"
                      type="number"
                      name="price"
                      value={price}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "all")}
                      placeholder="Min Price"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
              <Col md={6} className="mb-3 d-flex align-items-end">
                  <Form.Group className="form-floating label-text">
                    <FormField
                      label="Vendor Item Name"
                      type="text"
                      name="vendoritemname"
                      value={vendoritemname}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "all")}
                      placeholder="Vendor Item Name"
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className=" custom-time-picker">
                    <Form.Label style={{ zIndex: "11" }}>
                      Remind Date
                    </Form.Label>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={(e) =>
                        setformData({ ...formData, remind_date: convert(e._d) })
                      }
                      name="remind_date"
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            value={
                              remind_date !== ""
                                ? moment(remind_date).format("MM/DD/YYYY")
                                : ""
                            }
                            placeholder="mm/dd/yyyy"
                            onFocus={openCalendar}
                            onChange={() => { }}
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col md={12} className="mb-3">
                  <Form.Group className="form-floating label-text">
                    <FormField
                      as="textarea"
                      rows="4"
                      label="Notes"
                      name="note"
                      className="capitalCase"
                      value={note}
                      onFocus={(e) => {
                        e.target.select();
                        setFocus(false);
                      }}
                      onChange={(e) => handleChange(e, "all")}
                      placeholder="Notes"
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={6}
                  className="mb-3 custom-react-select multi-select  for-images select-color"
                >
                  <Form.Group>
                    <ReactSelect
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      styles={{
                        valueContainer: (provided) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue || state.selectProps.inputValue
                              ? -15
                              : "7px",
                          transition: "top 0.1s, font-size 0.1s",

                          transform:
                            (state.hasValue || state.selectProps.inputValue) &&
                            "scale(0.85)",
                          opacity:
                            (state.hasValue || state.selectProps.inputValue) &&
                            1,
                        }),
                      }}
                      classNamePrefix="react-select"
                      isMulti
                      options={props?.itemTag?.map((list) => {
                        return {
                          ...list,
                          value: list.id,
                          label: list.name,
                        };
                      })}
                      value={Object.entries(tag).length !== 0 ? tag : ""}
                      onChange={(e) => handleSelectChangeMulti(e, "tag")}
                      placeholder={"Tag"}
                    />
                  </Form.Group>
                </Col>
                {(props?.AccountSetting?.ADDITIONALFEE?.feeSetting || 0) >
                  0 && (
                    <Col
                    md={6}
                    className="mb-3 custom-react-select multi-select select-color  for-images"
                  >
                      <Form.Group>
                        <ReactSelect
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={{
                            valueContainer: (provided) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "absolute",
                              top:
                                state.hasValue || state.selectProps.inputValue
                                  ? -15
                                  : "7px",
                              transition: "top 0.1s, font-size 0.1s",
                              transform:
                                (state.hasValue ||
                                  state.selectProps.inputValue) &&
                                "scale(0.85)",
                              opacity:
                                (state.hasValue ||
                                  state.selectProps.inputValue) &&
                                1,
                            }),
                          }}
                          classNamePrefix="react-select"
                          isMulti
                          options={props?.additionalFee?.map((list) => {
                            return {
                              ...list,
                              value: list.id,
                              label: list.name,
                            };
                          })}
                          value={
                            Object.entries(additionalfee).length !== 0
                              ? additionalfee
                              : ""
                          }
                          onChange={(e) =>
                            handleSelectChangeMulti(e, "additional_fee")
                          }
                          placeholder={"Additional Fee"}
                        />
                      </Form.Group>
                    </Col>
                  )}

<Col md={6} className="custom-react-select mb-3 select-color">
                  <Form.Group>
                    <ReactSelect
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      styles={{
                        valueContainer: (provided,) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue || state.selectProps.inputValue
                              ? -15
                              : "7px",
                          transition: "top 0.1s, font-size 0.1s",

                          transform:
                            (state.hasValue || state.selectProps.inputValue) &&
                            "scale(0.85)",
                          opacity:
                            (state.hasValue || state.selectProps.inputValue) &&
                            1,
                        }),
                      }}
                      classNamePrefix="react-select"
                      options={props?.itemType?.map((list) => {
                        return {
                          ...list,
                          value: list.id,
                          label: list.name,
                        };
                      })}
                      value={props?.itemType
                        ?.map((item) => {
                          return {
                            ...item,
                            value: item.id,
                            label: item.name,
                          };
                        })
                        .filter((item) => {
                          return item.value === item_type;
                        })}
                      onChange={(e) => handleSelectChange(e, "item_type")}
                      placeholder={"Item Type"}
                      placement={"top"}
                    />
                  </Form.Group>
                </Col>
                {(props?.AccountSetting?.BOTTLEDEPOSIT?.enable || 0) > 0 && (
                 <Col md={6} className="custom-react-select select-color mb-3">
                    <Form.Group>
                      <ReactSelect
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={{
                          valueContainer: (provided) => ({
                            ...provided,
                            overflow: "visible",
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            position: "absolute",
                            top:
                              state.hasValue || state.selectProps.inputValue
                                ? -15
                                : "7px",
                            transition: "top 0.1s, font-size 0.1s",

                            transform:
                              (state.hasValue ||
                                state.selectProps.inputValue) &&
                              "scale(0.85)",
                            opacity:
                              (state.hasValue ||
                                state.selectProps.inputValue) &&
                              1,
                          }),
                        }}
                        classNamePrefix="react-select"
                        options={bottledepositList?.map((list) => {
                          return {
                            ...list,
                            value: list.id,
                            label: list.name,
                          };
                        })}
                        value={bottledepositList
                          ?.map((item) => {
                            return {
                              ...item,
                              value: item.id,
                              label: item.name,
                            };
                          })
                          .filter((item) => {
                            return item.value === bottle_deposit;
                          })}
                        onChange={(e) =>
                          handleSelectChange(e, "bottle_deposit")
                        }
                        placeholder={`${getTextAccToAppType("Bottle")} Deposit`}
                      />
                    </Form.Group>
                  </Col>
                )}
                {formData.shortcutkeys && (
                  <Col md={6}>
                   <Form.Group className="form-floating mb-3">
                      <FormField
                        label="Item Shortcut Name"
                        type="text"
                        maxLength="15"
                        name="item_shortcut_name"
                        value={item_shortcut_name}
                        onFocus={(e) => {
                          e.target.select();
                          setFocus(false);
                        }}
                        onChange={(e) => handleChange(e, "all")}
                        placeholder="Item Shortcut Name"
                      />
                    </Form.Group>
                  </Col>
                )}
                {formData.shortcutkeys && (
                  <Col md={6}>
                  <Form.Group className="form-floating mb-3">
                      <FormField
                        label="Item Sort No"
                        type="number"
                        name="item_shortcut_no"
                        value={item_shortcut_no}
                        onFocus={(e) => {
                          e.target.select();
                          setFocus(false);
                        }}
                        onChange={(e) => handleChange(e, "all")}
                        placeholder="Item Sort No"
                      />
                    </Form.Group>
                  </Col>
                )}

                {props?.generalSetting?.LOYALTY?.enable_loyalty_points ===
                  "1" && (
                    <>
                     <Col
                        md={6}
                        className="custom-react-select mb-3 mb-md-0 select-color"
                      >
                        <Form.Group>
                          <ReactSelect
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                            styles={{
                              valueContainer: (provided) => ({
                                ...provided,
                                overflow: "visible",
                              }),
                              placeholder: (provided, state) => ({
                                ...provided,
                                position: "absolute",
                                top:
                                  state.hasValue || state.selectProps.inputValue
                                    ? -15
                                    : "7px",
                                transition: "top 0.1s, font-size 0.1s",

                                transform:
                                  (state.hasValue ||
                                    state.selectProps.inputValue) &&
                                  "scale(0.85)",
                                opacity:
                                  (state.hasValue ||
                                    state.selectProps.inputValue) &&
                                  1,
                              }),
                            }}
                            classNamePrefix="react-select"
                            options={multiplierList?.map((item) => {
                              return {
                                ...item,
                                value: item.id,
                                label: item.name,
                              };
                            })}
                            value={multiplierList
                              ?.map((item) => {
                                return {
                                  ...item,
                                  value: item.id,
                                  label: item.name,
                                };
                              })
                              .filter((item) => {
                                return item.value === pointsmultiplier;
                              })}
                            onChange={(e) =>
                              handleSelectChange(e, "pointsmultiplier")
                            }
                            placeholder={"Points Multiplier"}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="form-floating label-text">
                          <FormField
                            label="Points Required"
                            type="text"
                            name="pointsrequired"
                            value={pointsrequired}
                            onFocus={(e) => {
                              e.target.select();
                              setFocus(false);
                            }}
                            onChange={(e) => handleChange(e, "all")}
                            placeholder="Points Required"
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}

               
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="Promotions">
              <Promotions
                promotionsList={props?.promotions}
                itemId={props?.updateData}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <Button
          variant="primary px-5 position-absolute form-cta-btn"
          type="button"
          onClick={() =>
            CheckStockCodeFirst(duplicateBarCode, "", stockcodeList)
          }
          disabled={props?.loading}
          title={id !== undefined && !checkAnotherItem() ? "Update" : "Save"}
        >
          {id !== undefined && !checkAnotherItem() ? "Update" : "Save"}
        </Button>
      </Form>
      <CustomModal
        open={show === DELETE}
        handleClose={() => handleClose(DELETE)}
        dialogClassName="custom-modal confirmation error-popup"
        title="Confirmation"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.warningRed}
                alt="warning Icon"
              />
              Are you sure you want to delete &nbsp; <b>{`${deletetype}`}</b>?
            </h1>
          </>
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5"
              onClick={() => handleClose(DELETE)}
              title="No"
            >
              No
            </Button>
            <Button
              type="button"
              variant="primary px-4 ms-2"
              onClick={() => handleConfirmDelete(deletetype, deleteid, idtype)}
              title="Yes Delete"
            >
              Yes Delete
            </Button>
          </>
        }
      />
      {/* for duplicate Items */}
      <CustomModal
        open={show === STOCKCODECHECK}
        handleClose={() => handleClose(STOCKCODECHECK)}
        size="lg"
        dialogClassName="custom-modal confirmation"
        title="Item List"
        content={
          <>
            <h1>
              This item barcode already assigned to below item list. Do you want
              to add duplicate barcode ?
            </h1>
            <Table responsive borderless>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody className="scroll-auto">
                {duplicateItem.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td>{data.id}</td>
                      <td>{data.name}</td>
                      <td>
                        {data?.modifier[0]?.price
                          ? currencyFormat(data?.modifier[0]?.price)
                          : currencyFormat(0.0)}
                      </td>
                      <td>{data?.totalitem ? data?.totalitem : 0}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        }
        action={
          <>
            {duplicateBarCode === 1 && (
              <Button
                type="button"
                variant="outline-blackcustom px-5"
                title="No"
                onClick={() => handleClose(STOCKCODECHECK)}
              >
                No
              </Button>
            )}
            <Button
              type="button"
              variant="primary px-5 ms-2"
              onClick={(e) => handleConfirm(e)}
              title="Yes"
            >
              Yes
            </Button>
          </>
        }
      />
      {/* for duplicate Items */}
      <CustomModal
        open={show === DUPLICATE}
        handleClose={() => handleClose(DUPLICATE)}
        dialogClassName="custom-modal confirmation error-popup"
        title="Confirmation"
        content={deletetype}
        action={
          <>
            <Button
              type="button"
              variant="primary px-5"
              onClick={() => handleClose(DUPLICATE)}
              title="Yes"
            >
              Yes
            </Button>
          </>
        }
      />

      {/* ask auth */}

      <CustomModal
        open={auth}
        handleClose={() => {
          setAuth(false);
          setShow(false);
        }}
        dialogClassName="custom-modal  with-border-bottom authorization"
        title="Authorization"
        content={
          <Auth setAuthSuccess={setAuthSuccess} userlist={props.userlist} />
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5"
              onClick={() => {
                setAuth(false);
                setShow(false);
              }}
              title="Cancel"
            >
              Cancel
            </Button>
          </>
        }
      />
           {/* Advance modifier */}
           <CustomModal
        open={show1}
        handleClose={handlePopupClose}
        dialogClassName="custom-modal modal-60w item-popup with-border-bottom advance-popup"
        title="Advance Modifier"
        content={<AdvanceModifier />}
        action={
          <>
            <Button
              type="button"
              variant="outline-blackcustom px-5"
              onClick={handlePopupClose}
              title="Yes"
            >
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};

AddItems.propTypes = {
  dynamicImages: PropTypes.shape({
    allCategories: PropTypes.string,
    miscallenous: PropTypes.string,
    details: PropTypes.string,
    options: PropTypes.string,
    promotions: PropTypes.string,
    barcodeIcon: PropTypes.string,
    plus: PropTypes.string,
    itemImgData: PropTypes.string,
    warningRed: PropTypes.string,
  }),
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  selectProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  generalSetting: PropTypes.shape({
    LOYALTY: PropTypes.shape({
      enable_loyalty_points: PropTypes.string,
    }),
  }),
  closeEditModel: PropTypes.func,
  itemsize: PropTypes.array,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  bottleDeposit: PropTypes.array,
  AccountSetting: PropTypes.shape({
    TAXRULEDATA: PropTypes.shape({
      defaultTax: PropTypes.number,
    }),
  }),
  accountSetting: PropTypes.shape({
    TAXRULEDATA: PropTypes.shape({
      defaultTax: PropTypes.number,
    }),
  }),
  msg: PropTypes.string,
  closeEditModel: PropTypes.func,
  stockCode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  mergedata: PropTypes.bool,
  itemid: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  addAnotherItem: PropTypes.func,
  taxList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  itemTag: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  additionalFee: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  itemType: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  userlist: PropTypes.array,
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    categories: state.inventory.category,
    itemsize: state.inventory.size,
    promotions: state.inventory.promotions,
    suppliers: state.inventory.supplier,
    itemlist: state.inventory.itemlist,
    userlist: state.inventory.userList,
    itemTag: state.inventory.tags,
    itemType: state.inventory.itemType,
    additionalFee: state.inventory.additionalFee,
    bottleDeposit: state.inventory.bottledeposit,
    taxList: state.inventory.taxRule,
    accountSetting: state.inventory.accountSetting.data,
    generalSetting: state.inventory.generalSetting.data,
    loading: state.inventory.loading,
    msg: state.inventory.msg,
    labeltemplates: state.saleItem.labeltemplates,
    storeId: state.auth.storeId,
  };
};
export default connect(mapStateToprops)(AddItems);
