import { getDeviceAndStore } from "axios/authHeader";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_FAILED,
  LOGOUT_SUCCESS,
  LOAD_ACTION,
  STORE_CHANGED,
  INITIALSETERROR,
  INITIALSETPOPUP,
  INITIALPAXSETPOPUP,
  INITIALBATCHPOPUP,
  INACTIVITY_LOGOUT,
} from "../actions/authAction";
import { DEVICESETUP } from "../helpers/constant";

const initialState = {
  auth: [],
  storeId: getDeviceAndStore()[1],
  success: false,
  error: false,
  LoginMessage: "",
  LogoutMessage: "",
  errorMessage: "",
  showloading: "",
  initialError: false,
  initialpop: false,
  setUpData: [],
  inactivity_logout: false,
};
// Reducer based on type of action and return state and payloads
export default function AuthReducer(state = initialState, actions) {
  switch (actions.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        auth: actions.payload.data,
        success: true,
        error: false,
        LoginMessage: "Login Successfully",
        errorMessage: "",
        LogoutMessage: "",
        showloading: false,
        initialError: false,
        initialpop: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        error: true,
        errorMessage: "Invaild Username or Password",
        LoginMessage: "",
        success: false,
        LogoutMessage: "",
        showloading: "",
        initialError: false,
        initialpop: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        auth: actions.payload,
        success: true,
        error: false,
        LogoutMessage: "Logout Successfully",
        errorMessage: "",
        LoginMessage: "",
        showloading: false,
        initialError: false,
        initialpop: false,
      };
    case LOGOUT_FAILED:
      return {
        ...state,
        error: true,
        errorMessage: "Logout Failed",
        success: false,
        LogoutMessage: "",
        LoginMessage: "",
        showloading: false,
        initialError: false,
        initialpop: false,
      };
    case STORE_CHANGED: {
      return {
        ...state,
        storeId: actions.payload,
      };
    }
    case DEVICESETUP: {
      return {
        ...state,
        setUpData: actions.payload,
        showloading: false,
      };
    }
    case INITIALSETERROR: {
      return {
        ...state,
        initialError: actions.payload,
        auth: [],
        initialpop: false,
        success: false,
      };
    }
    case INITIALSETPOPUP: {
      return {
        ...state,
        initialpop: actions.payload,
        initialError: false,
      };
    }
    case INITIALPAXSETPOPUP: {
      return {
        ...state,
        initialPaxpop: actions.payload,
        // initialPaxError:false
      };
    }
    case INITIALBATCHPOPUP: {
      return {
        ...state,
        initialBatchpop: actions.payload,
      };
    }
    case LOAD_ACTION:
      return {
        ...state,
        showloading: actions.payload,
        LogoutMessage: "",
        errorMessage: "",
      };
    case INACTIVITY_LOGOUT:
      return {
        ...state,
        inactivity_logout: actions.data,
      };
    default: {
      return state;
    }
  }
}
