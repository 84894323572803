import React, { useEffect, useState } from "react";
import { Button, Col, Row, Image } from "@themesberg/react-bootstrap";
import StockInformation from "./stock-information/stock_information";
import { usePusher } from "../../context/PusherContext";
import Shortcut from "./shortcut/shortcut";
import Checkout from "./checkout/checkout";
import CustomModal from "../components/Modals";
import { useDispatch, connect } from "react-redux";
import Cancel from "../../assets/images/cancel.png";
import boopSfx from "../../assets/sounds/item_not_found.mp3";
import BarcodeReader from "react-barcode-reader";
import {
  AddBase64,
  base64ToHex,
  CalculateAdditionalCardPrice,
  checkValue,
  currencyFormat,
  FormatImageBase64Code,
  getLRC,
  hexToBase64,
  HexToString,
  PusherUpdateFunction,
  PushParams,
  StringToHex,
} from "../../app/helpers/custom";
import {
  AddSaleItemList,
  AgePopUpShow,
  ShowNumpadStatus,
} from "../../app/actions/saleItemAction";
import {
  checkUserPermission,
  getDeviceAndStore,
  getSettingData,
} from "../../axios/authHeader";
import {
  ADDITEMONSALE,
  AGEPOPUPSHOW,
  ACCOUNTSETTINGDATA,
  POSSETTINGDATA,
  GENERALSETIINGDATA,
  STOCKCODECHECK,
  ITEM,
  PAXPOPUP,
  BATCHPOPUP,
  CLOSEBATCH,
  CLOSEPAXMACHINE,
  NUMPADSTATUS,
  ITEMUPDATEPUSHER,
  LOYALITY_POINTS_ENABLE,
  NEGATIVETYPE
} from "../../app/helpers/constant";
import { ITEMCHANNEl, ITEM_EVENT } from "../../app/helpers/pusherConstant";
import { Howl, Howler } from "howler";
import AddItems from "./stock-information/AddItem";
import { Base64 } from "js-base64";
import $ from "jquery";
import {
  INITIALBATCHPOPUP,
  INITIALPAXSETPOPUP,
  intialPaxSetError,
} from "../../app/actions/authAction";
import { Auth } from "../auth/auth";
import { CommonPusherComponent } from "../components/commonPusherConponent";
import PrintLabel from "../printLabel";
import Localstorage from "../../app/storage/Localstorage";
import { formatCode } from "../../app/helpers/custom";
import BootsrapTable2 from "pages/components/BootsrapTable2";
import LoaderSpinner from "components/Loader";

// import SlideInWindow from "./SlideInWindow";

export const roundOffToNext = (decimalNumber, rounding) => {
  let roundedNumber =
    Math.ceil(decimalNumber / (rounding / 100)) * (rounding / 100);
  return roundedNumber.toFixed(2);
};

const Register = (props) => {


  const [possetting, setPosSetting] = useState(
    getSettingData(POSSETTINGDATA) || props?.posSetting
  );
  const [generalsetting, setGeneralSetting] = useState(
    getSettingData(GENERALSETIINGDATA) || props?.generalSetting
  );
  const [accountsetting, setAccountSetting] = useState(
    getSettingData(ACCOUNTSETTINGDATA) || props?.accountSetting
  );
  const [posSideSetting, setPosSideSetting] = useState(
    getSettingData("wpos_config")
  );
  const [itemList, setItemList] = useState([]);
  const [sumCash, setSumCash] = useState(parseFloat(0));
  const [sumNonCash, setSumNonCash] = useState(parseFloat(0));
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [searchFocus, setSearchFocus] = useState(false);
  const [paxError, setPaxError] = useState(null);
  const [batchError, setBatchError] = useState(null);
  const [showCashNonCash, setShowCashNoncash] = useState(0);
  const [showRegularPriceBtn, setRegularPriceBtn] = useState(0);
  const [regularEnable, setRegularEnable] = useState(0);
  const [nonCashAdjEnable, setNonCashAdjEnable] = useState(0);
  const [custMobile, setCustMobile] = useState(null);
  const [auth, setAuth] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const totalAmount = Number(sumNonCash + sumCash).toFixed(2);
  const pusher = usePusher();
  const dispatch = useDispatch();
  const [businessLogo, setBusinessLogo] = useState(null);
  const [, setPaxMsg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [openErrorMsg, setOpenErrorMsg] = useState(false);
  const [status, setStatus] = useState(1);
  const [isSaleHavePayOut, setIsSaleHavePayOut] = useState(false);
  const [isSaleHaveNegativeType, setIsSaleHaveNegativeType] = useState(false);
  const [pointsRequiredError, setPointsRequiredError] = useState(false);
  const [pointsRequiredList, setPointsRequiredList] = useState([]);
  const payOutIds = [
    parseInt(props?.posSetting?.OPTION?.lotto_payout_item),
    parseInt(props?.posSetting?.OPTION?.online_payout_item),
  ];
  const negtiveIds = [NEGATIVETYPE, 588]
  const [UpcTenCode, setUpcTenCode] = useState(
    props?.generalSetting?.OTHER?.use_upc_ten
  );
  const [continueBtn, setContinueBtn] = useState(false);
  const [focusNo, setFocusNo] = useState('');

  useEffect(() => {
    window.addEventListener("storage", () => {
      setGeneralSetting(getSettingData(GENERALSETIINGDATA));
      setPosSetting(getSettingData(POSSETTINGDATA));
      setAccountSetting(getSettingData(ACCOUNTSETTINGDATA));
      setPosSideSetting(getSettingData("wpos_config"));
    });
  }, []);

  useEffect(() => {
    setUpcTenCode(props?.generalSetting?.OTHER?.use_upc_ten);
  }, [props?.generalSetting]);

  useEffect(() => {
    if (authSuccess == true) {
      setAuth(false);
      setShow(ITEM);
      setAuthSuccess(false);
    }
  }, [authSuccess]);

  const roundOffTotalPay = (amount) => {
    const cashRounding = getSettingData(POSSETTINGDATA)?.SALE?.cash_rounding;
    if (typeof amount !== "number" || isNaN(amount)) {
      return amount; // Return the original amount if it is not a valid number
    }

    if (cashRounding == 0) {
      return amount.toFixed(2);
    } else if (cashRounding == 5 || cashRounding == 10) {
      return roundOffToNext(amount, cashRounding);
    } else {
      return amount.toFixed(2);
    }
  };

  useEffect(() => {
    if (props?.totalAmounts !== undefined)
      UpDateCalculation(props?.totalAmounts);
  }, [props.totalAmounts]);

  useEffect(() => {
    if (props.itemlist !== undefined) setItemList(props?.itemlist);
  }, [props.itemlist]);

  useEffect(() => {
    if (barcode !== null) onBarCodeScan(barcode, itemList);
  }, [barcode, itemList]);

  useEffect(() => {
    function childEventCallback(data) {
      if (+data?.data?.store === +getDeviceAndStore()[1]) {
        const items = [...itemList];
        let listUpdate;
        if (data.method === "put" && Object.entries(data.data).length > 0) {
          listUpdate = PusherUpdateFunction("put", items, data.data);
        } else if (
          data.method === "add" &&
          Object.entries(data.data).length > 0
        ) {
          listUpdate = PusherUpdateFunction("add", items, data.data);
        } else if (data.method === "delete" && data.id > 0) {
          listUpdate = PusherUpdateFunction("delete", items, data);
        }
        dispatch({
          type: ITEMUPDATEPUSHER,
          payload: { data: listUpdate },
        });
      }
    }
    const channel = pusher.subscribe(ITEMCHANNEl);
    channel.bind(ITEM_EVENT, childEventCallback);
    return () => {
      channel.unbind(ITEM_EVENT, childEventCallback);
    };
  }, [itemList, pusher]);

  useEffect(() => {
    if (
      generalsetting?.hasOwnProperty("CREDIT") &&
      generalsetting?.CREDIT.hasOwnProperty("card_type") &&
      generalsetting?.CREDIT.card_type === 1 &&
      props.initialPaxpop == true
    ) {
      setTimeout(function () {
        setPaxError("Error");
        setShow(PAXPOPUP);
      }, 4 * 1000);
    }
  }, [props.initialPaxpop, generalsetting]);

  useEffect(() => {
    setTimeout(function () {
      if (props.initialBatchpop != "" && props.initialBatchpop != null) {
        setBatchError(props.initialBatchpop);
        setShow(BATCHPOPUP);
      }
    }, 500);
  }, [props.initialBatchpop]);

  useEffect(() => {
    setIsSaleHavePayOut(
      props?.salesitemList.filter((item) => {
        return payOutIds.includes(item
          ?.itemData?.id);
      }).length > 0
    );
    setIsSaleHaveNegativeType(
      props?.salesitemList.filter((item) => {
        return negtiveIds.includes(item?.itemData?.item_type);
      }).length > 0
    );
  }, [props?.salesitemList]);

  const checkRequiredPoints = (cust, saleList) => {
    cust = cust || {};
    let requiredItem = [];
    saleList.forEach((element) => {
      let custPoint = cust.points || 0;
      if (element?.itemData?.pointsrequired > custPoint) {
        requiredItem.push(element?.itemData);
      }
    });

    return requiredItem;
  };

  // customer rewards points calculation
  const calculateCustomerRewards = () => {
    const generalSetting = Localstorage.getItem("generalSetting");
    let customer = Localstorage.getItem("sale_customer");
    let customer_obj = { ...customer };
    let sale_point = 0;
    let excludeloyaltytotal = 0;

    if (generalSetting?.LOYALTY?.enable_loyalty_points === "1") {
      for (let x of props?.salesitemList) {
        const category = props?.category?.find((e) => +e.id === +x.category);
        //checking for wheather the added item is eligible for customer points earning
        //and permoforming the customer rewards points earning calculation
        // x.type == 1 is a Inventory Item type
        let itemType = props?.itemType?.find((it) => it.id == +x.type);
        if (
          (itemType && itemType.name === "Inventory Item") ||
          +x.type == 1 ||
          x.manual_item ||
          +x.type == 3
        ) {
          x.pointsmultiplier = x.pointsmultiplier || 1;
          if (category) {
            if (!category.loyaltyrewards) {
              let point = parseInt(
                x.price *
                x.qty *
                +generalSetting?.LOYALTY?.loyalty_points *
                +x.pointsmultiplier
              );
              sale_point = sale_point + point;
            }
          } else {
            let point = parseInt(
              x.price *
              x.qty *
              +generalSetting?.LOYALTY?.loyalty_points *
              +x.pointsmultiplier
            );

            sale_point = sale_point + point;
          }
        }

        if (category?.loyaltyrewards) {
          excludeloyaltytotal =
            excludeloyaltytotal +
            parseInt(
              x.price *
              x.qty *
              +generalSetting?.LOYALTY?.loyalty_points *
              +x.pointsmultiplier
            );
        }
      }
      customer_obj = {
        ...customer_obj,
        sale_point,
        totalpoints: customer_obj.points + sale_point,
        excludeloyaltytotal,
      };

      if (props?.pointsReedem?.points) {
        customer_obj = {
          ...customer_obj,
          redeemTotalPoints: props?.pointsReedem?.points,
        };
      }
      if (customer) {
        Localstorage.setItem("sale_customer", customer_obj);
      }
    }
  };

  const findMinPricesItems = (list) => {
    let result = [];
    for (let it of list) {
      if (!isNaN(+it.min_price))
        if (parseFloat(it.min_price) > parseFloat(it.showPrice)) {
          result.push({
            name: it.manualname,
            min_price: it.min_price
          })
        }
    }
    return result;
  }

  const checkIsPriceEntered = (saleItems) => {
    let zeroPriceItem = saleItems.findIndex((item) => item.price == 0)
    if (zeroPriceItem != -1) {
      setFocusNo(zeroPriceItem)
      return true;
    }
    setFocusNo('')
    return false;
  }

  const checkHousePayItem = (customer) => {
    for (const itm of props?.salesitemList) {
      if (+itm.id === +props?.posSetting?.OPTION?.house_pay_item && !customer?.ishousepay) {
        return true;
      }
    }
    return false;
  }

  const handleDialogPayAmount = (igore) => {
    setFocusNo('');
    let posSettings = getSettingData(POSSETTINGDATA);
    let generalSetting = getSettingData(GENERALSETIINGDATA);
    let cust = Localstorage.getItem("sale_customer") || {};
    let pointsRequiredLists = checkRequiredPoints(cust, props?.salesitemList);
    let priceCheck = checkIsPriceEntered(props?.salesitemList)
    if (priceCheck) {
      setTitle("Empty Item Price");
      setErrorMsg("Please enter the item price")
      setShow("ERROR");
      return;
    } else if (pointsRequiredLists.length && generalSetting?.LOYALTY?.enable_loyalty_points === LOYALITY_POINTS_ENABLE) {
      setTitle("Points Required");
      setErrorMsg("You need below points to purchase the item(s)");
      setPointsRequiredError(true);
      setPointsRequiredList(pointsRequiredLists);
      return;
    } else if (checkHousePayItem(cust)) {
      setTitle("Add Eligible Customer");
      setErrorMsg("Please select a customer.");
      setShow("ERROR");
      return;
    } else {
      let minPriceList = findMinPricesItems(props?.salesitemList);
      if (!isNaN(+posSettings?.SALE?.do_not_sale_below_cost) && posSettings?.SALE?.do_not_sale_below_cost !== 1 && minPriceList.length && !igore) {
        if (posSettings?.SALE?.do_not_sale_below_cost == 2) {
          setTitle("Below Cost Price");
          setContinueBtn(true);
          setErrorMsg(
            <>
              <span className="text-center">
                The price of following items is less than its cost.<br />
                {minPriceList.map((itm, idx) => {
                  let name = minPriceList[idx + 1] ? itm.name + ", " : itm.name
                  return name
                })}<br />
                Are you sure you want to continue ?
              </span>
            </>
          );
          setShow("ERROR");
          return;
        } else {
          setTitle("Alert");
          setContinueBtn(false);
          setErrorMsg(
            <>
              <span className="text-center">
                The price of following items is less than its cost.<br />
                {minPriceList.map((itm, idx) => {
                  let name = minPriceList[idx + 1] ? itm.name + ", " : itm.name
                  return name
                })}<br />
                This is not allowed.
              </span>
            </>
          );
          setShow("ERROR");
          return;
        }
      }
      calculateCustomerRewards();
      if (possetting?.SALE?.allow_negative_item_prices) {
        if (possetting?.SALE?.allow_negative_item_prices === "1" || isSaleHavePayOut || isSaleHaveNegativeType) {
          if (checkSaleList(props?.salesitemList)) {
            localStorage.setItem("totalAmountObject", JSON.stringify(props?.totalAmounts))
            setShow("AMOUNT");
          }
          return;
        } else {
          if (totalAmount > Number(0).toFixed(2)) {
            if (checkSaleList(props?.salesitemList)) {
              localStorage.setItem("totalAmountObject", JSON.stringify(props?.totalAmounts))
              setShow("AMOUNT");
              setContinueBtn(false);
            }
            return;
          } else {
            setTitle("Add Valid Items");
            setErrorMsg(
              "Please add some valid items to the sale before proceeding!"
            );
            setShow("ERROR");
            return;
          }
        }
      } else {
        if (totalAmount > Number(0).toFixed(2)) {
          if (checkSaleList(props?.salesitemList)) {
            localStorage.setItem("totalAmountObject", JSON.stringify(props?.totalAmounts))
            setShow("AMOUNT");
          }
          return;
        } else {
          if (isSaleHavePayOut === true) {
            setShow("AMOUNT");
          } else {
            setTitle("Add Valid Items");
            setErrorMsg(
              "Please add some valid items to the sale before proceeding!"
            );
            setShow("ERROR");
            return;
          }
        }
      }
    }
  };

  function onBarCodeScan(barcode, itemList) {
    console.log(`on scan`, itemList);
    const itemDetails = itemList.filter((item) =>
      item.code.find(
        (code) =>
          code.code === barcode || code.code === formatCode(barcode, UpcTenCode)
      )
    );
    if (itemDetails !== undefined && itemDetails.length > 0) {
      console.log(`length`, props.itemlist.length);
      console.log(`itemDetails`, itemDetails);
      if (props.itemlist.length) {
        console.log(`props.itemlist`, props.itemlist.length);
        dispatch(AddSaleItemList(itemDetails[0], ADDITEMONSALE));
        dispatch(ShowNumpadStatus(false, NUMPADSTATUS));
        setTimeout(() => {
          setBarcode(null);
        }, 300);
      } else {
        dispatch(AgePopUpShow(true, AGEPOPUPSHOW));
      }
    } else {
      // Setup the new Howl.
      const sound = new Howl({ src: [boopSfx] });
      // play the sound
      sound.play();
      // Change global volume.
      Howler.volume(1);
      setShow(STOCKCODECHECK);
      setSearchFocus(true);
    }
  }

  function UpDateCalculation(totalAmount) {
    setSumCash(totalAmount?.Total);
    setSumNonCash(totalAmount?.nonCashAdj);
  }

  function checkSaleList(saleList) {
    const checkQty = saleList.filter((item) => item.qty === "");
    const checkPrice = saleList.filter(
      (item) => item.price === "" || item.price === parseFloat(0).toFixed(2)
    );
    let checkDecimalQty = false;
    let Possetting = getSettingData(POSSETTINGDATA);
    if (!checkValue(Possetting?.SALE?.allow_decimal_qty_sale)) {
      checkDecimalQty = saleList.filter(
        (item) => !Number.isInteger(parseFloat(item.qty))
      );
    }

    if (checkQty && checkQty.length > 0) {
      setTitle("Sale Information");
      setErrorMsg("Please add qty of all items before proceeding!");
      setShow("ERROR");
      return false;
    } else if (checkDecimalQty && checkDecimalQty.length > 0) {
      setTitle("Sale Information");
      setErrorMsg("Quantity can't be a decimal number!");
      setShow("ERROR");
    } else if (checkPrice && checkPrice.length > 0) {
      setTitle("Sale Information");
      setErrorMsg("Please add price of all items before proceeding!");
      setShow("ERROR");
      return false;
    } else {
      return true;
    }
  }

  const handleScanItem = (barcode) => {
    console.log(`barcode`, barcode);
    console.log(`props`, props);
    if (props.labelModal !== true) {
      if (barcode.length > 0 && barcode.match(/^[0-9]+$/) !== null) {
        setBarcode(barcode);
      }
    }
  };

  const handleDailogPayClose = () => {
    setShow(false);
    setErrorMsg(null);
    setTitle(null);
    setBarcode(null);
    setFocusNo('')
  };

  const handleDailogPaxClose = () => {
    setShow(false);
    setErrorMsg(null);
    setTitle(null);
    setBarcode(null);
    dispatch(intialPaxSetError(false, INITIALPAXSETPOPUP));
  };

  const handleBatchDailogClose = () => {
    setShow(false);
    setErrorMsg(null);
    setTitle(null);
    setBarcode(null);
    dispatch(intialPaxSetError("", INITIALBATCHPOPUP));
  };

  const ShowMsgInfo = (modal, type, msg, status) => {
    console.log(`modal`, modal);
    console.log(`type`, type);
    console.log(`msg`, msg);
    console.log(`status`, status);
    setOpenErrorMsg(modal);
    setErrorMsg(type);
    setTitle(msg);
    setStatus(status);
  };

  const handleClosepopUp = () => {
    setPaxMsg(false);
    setOpenErrorMsg(false);
    // setLoader(false);
    setTimeout(() => {
      setErrorMsg("");
      setTitle("");
      setStatus(1);
    }, 200);
  };

  const handleConfirm = (e, type, status, title) => {
    if (type === CLOSEBATCH) {
      handleLoader(true, CLOSEPAXMACHINE);
      setTimeout(
        function () {
          closeBatch();
        },
        [2000]
      );
      handleClosepopUp();
    } else if (title == 'Pax Error') {
      handleLoader(true, "Follow Prompts on Terminal");
      setTimeout(
        function () {
          closeBatch();
        },
        [2000]
      );
      handleClosepopUp();
    }
  };

  const handleLoader = (value, type) => {
    setLoaderType(type);
    setLoader(value);
  };

  const handleBatchClose = () => {
    // handleBatchDailogClose();
    closeBatch();
    setShow(false);
    setBarcode(null);
  };

  useEffect(() => {
    const Data = {
      sound: 0,
      websound: 0,
      searchFontSize: 0,
      posScreenFont: 0,
      customerFont: 0,
      customerScreenZoom: 0,
      screenKeypad: true,
      fixedKeypad: false,
      autolanuchCustomeScreen: false,
      showTotalCusScreen: false,
      showPriceCusScreen: false,
      email_notesInput: false,
      alwayShowShortcut: false,
      enableWebstoreNoti: false,
      asktoPrint: "ask",
      autoHideReciptDialog: "",
      reciptOfPrint: 1,
      baud: 4800,
      connectMethod: 1,
      connectType: 1,
      connectPort: "",
      data_bits: 7,
      stop_bits: 1,
      parity: "none",
      flow_control: "",
      printerPort: "",
      printerIp: "",
      printer: "",
      label_connectPort: "",
      label_data_bits: 7,
      label_stop_bits: 1,
      label_parity: "none",
      label_flow_control: "",
      label_printerPort: "",
      label_printerIp: "",
      label_printer: "",
      cash_draw_connected: false,
      label_printing_enabled: false,
      cutter_commond: "none",
      pageFeed: "",
      escp_receiptMode: "text",
      receiptTemplate: "",
      langauge: "primary",
      orientation: "ltr",
      alter_char_code: "pc864",
      alter_char_code_number: "",
      sheet_width: "",
      currency_codepage: "",
      currency_codes: "",
      override_currency_character: "",
      reportMethod: "bp",
      report_printer: "",
      electron_printType1: "",
      electron_printType2: "",
      reportType1: "",
      reportType2: "",
      label_print_setting: "",
      template: "",
      label_print_type: "",
      pole_display: "",
      pole_printer: "",
      characterPerLine: 20,
      noLinePole: 2,
      message: "Welcome",
      ipAddress: "",
      web_android_port: "",
      remote_printing_enabled: false,
    };
    if (localStorage.getItem("wpos_config") == null) {
      localStorage.setItem("wpos_config", JSON.stringify(Data));
    }
  }, [])

  const closeBatch = () => {
    handleLoader(true, "Follow Prompts on Terminal");
    let parms = {};
    var settings = getPaymentObject("Pax", parms, "sale", null, "batchclose");
    console.log(`settings`, settings);
    $.ajax(settings)
      .done(function (res) {
        console.log(`res`, res);
        handleLoader(false, "");
        let response = handlePaymentResponse("Pax", res);
        setLoader(false);
        console.log("response->", response);
        if (response.isApproved == true) {
          console.log("approved");
          ShowMsgInfo(true, "Batch Closed", "Batch", 2);
          dispatch(intialPaxSetError("", INITIALBATCHPOPUP))
        } else if (response.isApproved == false) {
          ShowMsgInfo(true, CLOSEBATCH, "Error Closing Batch!", 0);
        }
      })
      .fail(function (jqXHR) {
        console.log(`jqXHR`, jqXHR);
        handleLoader(false, "");
        var errormessage;
        if (jqXHR.status === 0) {
          errormessage = "Could not connect.\n Verify your network.";
        } else if (jqXHR.status == 404) {
          errormessage = "Requested page not found. [404]";
        } else if (jqXHR.status == 500) {
          errormessage = "Internal Server Error [500].";
        } else {
          errormessage = "Uncaught Error.\n" + jqXHR.responseText;
        }
        setLoader(false);
        if (window.navigator.onLine == false) {
          errormessage = "Could not connect.\n Verify your network.";
          ShowMsgInfo(true, CLOSEBATCH, errormessage, 0);
        } else {
          setTitle("Error");
          setErrorMsg(errormessage);
          // setErrorMsg("Please Manually Power Cycle the Card machine and Relogin");
          setPaxMsg(true);
          ShowMsgInfo(true, <>
            <div className="text-center">
              <Image src={Cancel} alt="error icon" className="mb-3" />{" "}
              <br />
              <p>Error: {errormessage}</p>
              <p className="mt-2">
                Retry Closing Batch?
              </p>
            </div>
          </>, "Pax Error", 0);
          console.log(`errormessage`, errormessage);
        }
      });
  };

  const getPaymentObject = (
    type,
    parms,
    processtype = "sale",
    cardsaletype
  ) => {
    if (type == "Pax") {
      var settings = getPaymentSettings(parms, processtype, cardsaletype);
    }
    return settings;
  };

  const getPaymentSettings = (params, processtype = "sale", cardsaletype) => {
    var version, transactionType; //,accountInformation={},traceInformation,avsInformation,cashierInformation,commercialInformation,motoEcommerce,additionalInformation;

    var configuration = {
      version: "1.28",
      protocol: "https",
      ip: "127.0.0.1",
      port: "10009",
      promptForSignature: 0,
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
    };

    version = configuration.version;
    transactionType = processTypes[processtype];
    //set transaction Data

    let amountInformation = {};
    amountInformation.TransactionAmount =
      processtype == "void"
        ? ""
        : Math.round(parseFloat(params.transactionAmount) * 100);
    amountInformation.TipAmount = "";
    amountInformation.CashBackAmount = "";
    amountInformation.MerchantFee = "";
    amountInformation.TaxAmount = "";
    amountInformation.FuelAmount = "";
    console.log(amountInformation);

    let accountInformation = {};
    accountInformation.Account = "";
    accountInformation.EXPD = "";
    accountInformation.CVVCode = "";
    accountInformation.EBTtype = cardsaletype == "ebt cash" ? "C" : "";
    accountInformation.VoucherNumber = "";
    accountInformation.Force = "";
    accountInformation.FirstName = "";
    accountInformation.LastName = "";
    accountInformation.CountryCode = "";
    accountInformation.State_ProvinceCode = "";
    accountInformation.CityName = "";
    accountInformation.EmailAddress = "";

    let traceInformation = {};
    traceInformation.ReferenceNumber =
      params.hasOwnProperty("ticketNumber") && params.ticketNumber
        ? params.ticketNumber.substring(0, 15)
        : "";
    traceInformation.InvoiceNumber = "";
    traceInformation.AuthCode = "";
    traceInformation.TransactionNumber = params.hasOwnProperty("transactionId")
      ? params.transactionId
      : "";

    traceInformation.TimeStamp = "";
    traceInformation.ECRTransID = "";

    let avsInformation = {};
    avsInformation.ZipCode = "";
    avsInformation.Address = "";
    avsInformation.Address2 = "";

    let cashierInformation = {};
    cashierInformation.ClerkID = "";
    cashierInformation.ShiftID = "";

    let commercialInformation = {};
    commercialInformation.PONumber = "";
    commercialInformation.CustomerCode = "";
    commercialInformation.TaxExempt = "";
    commercialInformation.TaxExemptID = "";
    commercialInformation.MerchantTaxID = "";
    commercialInformation.DestinationZipCode = "";
    commercialInformation.ProductDescription = "";

    let motoEcommerce = {};
    motoEcommerce.MOTO_E_CommerceMode = "";
    motoEcommerce.TransactionType = "";
    motoEcommerce.SecureType = "";
    motoEcommerce.OrderNumber = "";
    motoEcommerce.Installments = "";
    motoEcommerce.CurrentInstallment = "";

    let additionalInformation = {};
    additionalInformation.TABLE = "";
    additionalInformation.GUEST = "";
    additionalInformation.SIGN =
      processtype == "sale" ? configuration.promptForSignature : "";
    additionalInformation.TICKET = "";
    additionalInformation.HREF = "";
    additionalInformation.TIPREQ = "";
    additionalInformation.SIGNUPLOAD = "";
    additionalInformation.REPORTSTATUS = "";
    additionalInformation.TOKENREQUEST = "";
    additionalInformation.TOKEN = "";
    additionalInformation.CARDTYPE = "";
    additionalInformation.CARDTYPEBITMAP = "";

    additionalInformation.PASSTHRUDATA = "";
    additionalInformation.RETURNREASON = "";
    additionalInformation.ORIGTRANSDATE = "";
    additionalInformation.ORIGPAN = "";
    additionalInformation.ORIGEXPIRYDATE = "";
    additionalInformation.ORIGTRANSTIME = "";
    additionalInformation.DISPROGPROMPTS = "";
    additionalInformation.GATEWAYID = "";
    additionalInformation.GETSIGN =
      processtype == "sale" ? configuration.promptForSignature : "";

    additionalInformation.ENTRYMODEBITMAP = "";
    additionalInformation.RECEIPTPRINT = "1";
    additionalInformation.CPMODE = "";
    additionalInformation.ODOMETER = "";
    additionalInformation.VEHICLENO = "";
    additionalInformation.JOBNO = "";
    additionalInformation.DRIVERID = "";
    additionalInformation.EMPLOYEENO = "";
    additionalInformation.LICENSENO = "";
    additionalInformation.JOBID = "";
    additionalInformation.DEPARTMENTNO = "";
    additionalInformation.CUSTOMERDATA = "";
    additionalInformation.USERID = "";
    additionalInformation.VEHICLEID = "";
    console.log(amountInformation);

    var command = "T00";
    if (cardsaletype == "gift") {
      command = "T06";
    } else if (cardsaletype == "debit") {
      command = "T02";
    } else if (cardsaletype == "ebt" || cardsaletype == "ebt cash") {
      command = "T04";
    } else if (cardsaletype == "batchclose") {
      command = "B00";
    }

    console.log(`command`, command);

    return DoCredit({
      command: command,
      version: version,
      transactionType: transactionType,
      amountInformation: amountInformation,
      accountInformation: accountInformation,
      traceInformation: traceInformation,
      avsInformation: avsInformation,
      cashierInformation: cashierInformation,
      commercialInformation: commercialInformation,
      motoEcommerce: motoEcommerce,
      additionalInformation: additionalInformation,
    });
  };

  //DO Credit
  const DoCredit = (doCreditInfo) => {
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      mDestinationIP: "http://127.0.0.1:10009", // - OLD "http://192.167.2.100:10009";       //http://112.199.49.146:8181
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };

    var params = [
      PAX.mStx.hex,
      doCreditInfo.command,
      PAX.mFS.hex,
      doCreditInfo.version,
    ];
    params.push(PAX.mFS.hex);
    if (doCreditInfo.transactionType != "") {
      params.push(doCreditInfo.transactionType);
    }
    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "amountInformation",
      doCreditInfo.amountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "accountInformation",
      doCreditInfo.accountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "traceInformation",
      doCreditInfo.traceInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "avsInformation", doCreditInfo.avsInformation);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "motoEcommerce", doCreditInfo.motoEcommerce);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    params.push(PAX.mEtx.hex);

    var lrc = getLRC(params);

    console.log(params);

    //prepare for base64 encoding.
    var command_hex = base64ToHex(Base64.btoa(doCreditInfo.command));
    var version_hex = base64ToHex(Base64.btoa(doCreditInfo.version));
    var transactionType_hex = base64ToHex(
      Base64.btoa(doCreditInfo.transactionType)
    );
    // var amountInformation_hex = base64ToHex(
    //   Base64.btoa(doCreditInfo.amountInformation)
    // );
    // var accountInformation_hex = base64ToHex(
    //   Base64.btoa(doCreditInfo.accountInformation)
    // );
    // var traceInformation_hex = base64ToHex(
    //   Base64.btoa(doCreditInfo.traceInformation)
    // );
    // var avsInformation_hex = base64ToHex(
    //   Base64.btoa(doCreditInfo.avsInformation)
    // );
    // var cashierInformation_hex = base64ToHex(
    //   Base64.btoa(doCreditInfo.cashierInformation)
    // );
    // var commercialInformation_hex = base64ToHex(
    //   Base64.btoa(doCreditInfo.commercialInformation)
    // );
    // var motoEcommerce_hex = base64ToHex(
    //   Base64.btoa(doCreditInfo.motoEcommerce)
    // );
    // var additionalInformation_hex = base64ToHex(
    //   Base64.btoa(doCreditInfo.additionalInformation)
    // );

    //var elements = [this.mStx.code, command_hex, PAX.mFS.code, version_hex, PAX.mFS.code, uploadFlag_hex, PAX.mFS.code, timeout, this.mEtx.code, base64ToHex(Base64.btoa(lrc))];
    var elements = [PAX.mStx.code];
    elements.push(command_hex);
    elements.push(PAX.mFS.code);
    elements.push(version_hex);
    elements.push(PAX.mFS.code);

    if (transactionType_hex != "") {
      elements.push(transactionType_hex);
    }
    elements.push(PAX.mFS.code);

    elements = AddBase64(
      elements,
      "amountInformation",
      doCreditInfo.amountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "accountInformation",
      doCreditInfo.accountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "traceInformation",
      doCreditInfo.traceInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "avsInformation",
      doCreditInfo.avsInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(elements, "motoEcommerce", doCreditInfo.motoEcommerce);
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    elements.push(PAX.mEtx.code);
    elements.push(base64ToHex(Base64.btoa(lrc)));
    console.log("elements");
    console.log(elements);

    var final_string = elements.join(" ");
    var final_b64 = hexToBase64(final_string);
    console.log("LRC: " + lrc);
    console.log("Base64: " + final_b64);

    var url = PAX.mDestinationIP + "?" + final_b64;
    console.log("URL: " + url);
    return { url: url, timeout: PAX.timeout.DoCredit };
  };

  const handlePaymentResponse = (type, response) => {
    if (type == "Tripos" || type == "TriposDirect") {
      return response;
    } else if (type == "Pax") {
      return parseResponse(response);
    }
  };

  function parseResponse(response) {
    var commandType = "DoCredit";
    var checkParams = StringToHex(response).split(" ").pop();
    var RedundancyCheck = StringToHex(response).split(" ").pop().substring(1);

    var check = getLRC(checkParams);

    if (check == RedundancyCheck) {
      //get package detail info
      var packetInfo = [];
      var len = StringToHex(response).indexOf("03");
      var hex = StringToHex(response).slice(0, len).split(/02|1c/);

      console.log(hex);
      if (commandType == "DoCredit") {
        var subHex = [],
          subPacketInfo = [];
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            if (hex[i].indexOf("1f") > 0) {
              subHex = hex[i].split("1f");
              console.log(subHex);
              subPacketInfo = [];
              for (var j = 0; j < subHex.length; j++) {
                if (subHex[j] != "") {
                  subPacketInfo.push(HexToString(subHex[j]));
                }
              }
              console.log(subPacketInfo);
              packetInfo.push(subPacketInfo);
            } else {
              packetInfo[i] = HexToString(hex[i]);
            }
          }
        }
      } else {
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            packetInfo[i] = HexToString(hex[i]);
          }
        }
      }
      response = packetInfo;
    }
    if (typeof response == "string") {
      // WPOS.util.okDialog("Error", response, function (dat) { });
      console.log(`responsee`, response);
      return true;
    }

    console.log(`responsee`, response);

    var i = 0,
      j = -1;
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
      LocalTotalReport: {},
      BatchHistory: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
      add: "10",
      activate: "08",
      deactivate: "12",
      balance: "23",
    };
    PacketageInfo.DoCredit.Status = response[++i];
    PacketageInfo.DoCredit.Command = response[++i];
    PacketageInfo.DoCredit.Version = response[++i];
    PacketageInfo.DoCredit.ResponseCode = response[++i];
    PacketageInfo.DoCredit.ResponseMessage = response[++i];
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.Command) {
          commandName = key;
        }
      }
    }
    if (PacketageInfo.DoCredit.ResponseCode == "000000") {
      PacketageInfo.DoCredit.isApproved = true;
    } else {
      PacketageInfo.DoCredit.isApproved = false;
    }

    console.log(`packetInfo->`, PacketageInfo);

    PacketageInfo.DoCredit.HostInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.HostInformation == "") {
      PacketageInfo.DoCredit.HostInformation = {};
      PacketageInfo.DoCredit.HostInformation.HostResponseCode = "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage = "";
      PacketageInfo.DoCredit.HostInformation.AuthCode = "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber = "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber = "";
    } else {
      PacketageInfo.DoCredit.HostInformation.HostResponseCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.AuthCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit._processor = {
      processorLogs: [
        "ExpressResponseCode: [0]\r\nExpressResponseMessage: [" +
          PacketageInfo.DoCredit.isApproved
          ? commandName + "Approved"
          : "" +
          "]\r\nHostResponseCode: [" +
          PacketageInfo.DoCredit.HostInformation.HostResponseCode +
          "]\r\nHostResponseMessage: [ " +
          PacketageInfo.DoCredit.HostInformation.HostResponseMessage +
          "]",
      ],
      processorRawResponse: "",
      processorReferenceNumber:
        PacketageInfo.DoCredit.HostInformation.HostReferenceNumber,
      processorRequestFailed: PacketageInfo.DoCredit.isApproved,
      processorRequestWasApproved: PacketageInfo.DoCredit.isApproved,
      processorResponseCode: PacketageInfo.DoCredit.isApproved
        ? commandName + "Approved"
        : "",
      processorResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      expressResponseCode:
        PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      expressResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      hostResponseCode: PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      hostResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      logs: ["ExpressResponseCode: ''"],
      rawResponse: "",
    };

    PacketageInfo.DoCredit.TransactionType =
      response[++i] != undefined ? response[i] : "";

    PacketageInfo.DoCredit.commandName = "";
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.TransactionType) {
          commandName = key.charAt(0).toUpperCase() + key.slice(1) + " ";
          PacketageInfo.DoCredit.commandName = commandName;
        }
      }
    }

    PacketageInfo.DoCredit.AmountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AmountInformation == "") {
      PacketageInfo.DoCredit.AmountInformation = {};
      PacketageInfo.DoCredit.approvedAmount = "";
      PacketageInfo.DoCredit.subTotalAmount = "0";
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount = "";
      PacketageInfo.DoCredit.AmountInformation.AmountDue = "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount = "";
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount = "";
      PacketageInfo.DoCredit.cashbackAmount =
        PacketageInfo.DoCredit.AmountInformation.CashBackAmount;
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = "";
      PacketageInfo.DoCredit.debitSurchargeAmount = "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount = "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 = "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 = "";
    } else {
      j = -1;
      if (
        PacketageInfo.DoCredit &&
        PacketageInfo.DoCredit.AmountInformation &&
        PacketageInfo.DoCredit.AmountInformation.hasOwnProperty("ApproveAmount")
      ) {
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.approvedAmount = parseFloat(
          PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
        );
        PacketageInfo.DoCredit.subTotalAmount = parseFloat(
          PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
        );

        PacketageInfo.DoCredit.AmountInformation.AmountDue =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.TipAmount =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.tipAmount =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.CashBackAmount =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.TaxAmount =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.Balance1 =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.Balance2 =
          response[i][++j] != undefined ? response[i][j] : "";
      }
    }

    // add params balance 1 and balance 2 for ebt
    if (["T05", "T07", "T09"].includes(PacketageInfo.DoCredit.Command)) {
      PacketageInfo.DoCredit.Balance1 =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance1) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance1 / 100)
          : PacketageInfo.DoCredit.AmountInformation.Balance1;
      PacketageInfo.DoCredit.Balance2 =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance2) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance2 / 100)
          : PacketageInfo.DoCredit.AmountInformation.Balance2;
    } else {
      PacketageInfo.DoCredit.Balance1 = 0;
      PacketageInfo.DoCredit.Balance2 = 0;
    }

    PacketageInfo.DoCredit.fsaCard = "NotApplicable";
    PacketageInfo.DoCredit.convenienceFeeAmount = "";
    PacketageInfo.DoCredit.AccountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AccountInformation == "") {
      PacketageInfo.DoCredit.AccountInformation = {};
      PacketageInfo.DoCredit.AccountInformation.Account = "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode = "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate = "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype = "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber = "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo = "";
      PacketageInfo.DoCredit.AccountInformation.CardType = "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder = "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage = "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = "";
    } else {
      j = -1;
      if (
        PacketageInfo.DoCredit &&
        PacketageInfo.DoCredit.AccountInformation &&
        PacketageInfo.DoCredit.AccountInformation.hasOwnProperty("Account")
      ) {
        PacketageInfo.DoCredit.AccountInformation.Account =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.EntryMode =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.ExpireDate =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.EBTtype =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.VoucherNumber =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.NewAccountNo =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.CardType =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.CardHolder =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.CVDMessage =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator =
          response[i][++j] != undefined ? response[i][j] : "";
      }
    }

    PacketageInfo.DoCredit.accountNumber =
      PacketageInfo.DoCredit.AccountInformation.Account;
    PacketageInfo.DoCredit.isCardInserted =
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator;
    PacketageInfo.DoCredit.cardHolderName =
      PacketageInfo.DoCredit.AccountInformation.CardHolder;

    PacketageInfo.DoCredit.TraceInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.TraceInformation == "") {
      PacketageInfo.DoCredit.TraceInformation = {};
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber = "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AVSinformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AVSinformation == "") {
      PacketageInfo.DoCredit.AVSinformation = {};
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.CommercialInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.CommercialInformation == "") {
      PacketageInfo.DoCredit.CommercialInformation = {};
      PacketageInfo.DoCredit.CommercialInformation.PONumber = "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.CommercialInformation.PONumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription =
        response[i][++j] != undefined ? response[i][j] : "";
    }
    console.log(PacketageInfo.DoCredit.CommercialInformation);

    PacketageInfo.DoCredit.motoEcommerce =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.motoEcommerce == "") {
      PacketageInfo.DoCredit.motoEcommerce = {};
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType = "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType = "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber = "";
      PacketageInfo.DoCredit.motoEcommerce.Installments = "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.Installments =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AdditionalInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AdditionalInformation == "")
      PacketageInfo.DoCredit.AdditionalInformation = {};
    var additionalInfoArr = PacketageInfo.DoCredit.AdditionalInformation,
      keyValue = [];
    for (i = 0; i < additionalInfoArr.length; i++) {
      keyValue = additionalInfoArr[i].split("=");
      PacketageInfo.DoCredit.AdditionalInformation[keyValue[0]] = keyValue[1];
      keyValue = [];
    }
    if (
      PacketageInfo.DoCredit.AdditionalInformation.hasOwnProperty("CARDBIN")
    ) {
      PacketageInfo.DoCredit.binValue =
        PacketageInfo.DoCredit.AdditionalInformation.CARDBIN;
    }

    PacketageInfo.DoCredit.cardLogo = "";
    PacketageInfo.DoCredit.currencyCode = "";
    PacketageInfo.DoCredit.entryMode = "";
    PacketageInfo.DoCredit.expirationYear = "";
    PacketageInfo.DoCredit.expirationMonth = "";
    PacketageInfo.DoCredit.paymentType = "";
    PacketageInfo.DoCredit.pinVerified = "";
    PacketageInfo.DoCredit.signature = {
      statusCode: "SignatureNotRequiredByThresholdAmount",
    };
    PacketageInfo.DoCredit.statusCode = PacketageInfo.DoCredit.isApproved
      ? commandName + "Approved"
      : "";
    PacketageInfo.DoCredit.transactionDateTime =
      PacketageInfo.DoCredit.TraceInformation.TimeStamp;
    PacketageInfo.DoCredit.transactionId =
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber;
    PacketageInfo.DoCredit.merchantId = "";

    console.log(`PacketageInfo`, PacketageInfo.DoCredit);
    return PacketageInfo.DoCredit;
  }

  useEffect(() => {
    let setting = getSettingData(ACCOUNTSETTINGDATA);
    if (setting?.NONCASEADJUSTMENT) {
      if (setting?.NONCASEADJUSTMENT?.showCaseNonCaseButton) {
        setShowCashNoncash(
          parseInt(setting?.NONCASEADJUSTMENT?.showCaseNonCaseButton || 0)
        );
      }
      if (setting?.NONCASEADJUSTMENT?.enable) {
        setNonCashAdjEnable(
          parseInt(setting?.NONCASEADJUSTMENT?.enable || 0)
        );
      }
    }

    if (setting?.DUALPRICING) {
      if (setting?.DUALPRICING?.showCaseRegularButton) {
        setRegularPriceBtn(
          parseInt(setting?.DUALPRICING?.showCaseRegularButton || 0)
        );
      }
      if (setting?.DUALPRICING?.enable) {
        setRegularEnable(
          parseInt(setting?.DUALPRICING?.enable || 0)
        );
      }
    }

  }, [accountsetting, props?.accountSetting]);

  const handleBrowseItem = () => {
    alert("Browse item ");
  };

  const handleClose = (con) => {
    !con && handleDailogPayClose();
  };


  useEffect(() => {
    if (props?.image != undefined && props.image != "") {
      const d_image = getDetailsofImage("businessLogo");
      if (d_image !== undefined && d_image.length > 0) {
        if (d_image[0]?.file !== undefined) {
          let origimage1 = FormatImageBase64Code(d_image[0].file);
          setBusinessLogo(origimage1);
        }
      }
    }
  }, [props?.image]);

  function getDetailsofImage(type) {
    if (type !== undefined) {
      const detailsData = props?.image.filter((item) => item.name === type);
      return detailsData;
    }
  }

  const pointsRequiredColumns = [
    {
      dataField: "name",
      text: "Name",
      // sort: true,
      attrs: {
        "data-title": "name",
      },
    },
    {
      dataField: "pointsrequired",
      text: "Points",
      // sort: true,
      attrs: {
        "data-title": "pointsrequired",
      },
    },
  ];

  return (
    <>
      {loader && (
        <CustomModal
          open={loader}
          dialogClassName={`custom-modal confirmation error-popup`}
          title={loaderType}
          content={<h1 className="mb-3"></h1>}
          action={<LoaderSpinner />}
          removeCloseIcon={`d-none`}
        />
      )}
      <PrintLabel />

      <CommonPusherComponent />
      <BarcodeReader
        onError={handleDailogPayClose}
        onScan={handleScanItem}
        minLength={2}
      />
      <Row className="register-outer">




        <Col lg="8" md="12">
          <div className="register custom-data-table">
            <div
              className="watermark"
              style={{ backgroundImage: `url(${businessLogo})` }}
            ></div>



            <StockInformation
              setSearchFocus={setSearchFocus}
              searchFocus={searchFocus}
              posSideSetting={posSideSetting}
              PosSetting={possetting}
              GeneralSetting={generalsetting}
              AccountSetting={accountsetting}
              itemList={itemList}
              focusNo={focusNo}
            />
          </div>
        </Col>
        <Col lg="4" md="12">
          <Row>
            <Col xs="12" className="right-tab">

              <Shortcut
                posSideSetting={posSideSetting}
                PosSetting={possetting?.OPTION}
                itemList={itemList}
                custMobile={setCustMobile}
              />


              <div className="pay-btn-wrapper">
                <Button
                  onClick={() => handleDialogPayAmount()}
                  className={
                    totalAmount > 0 || isSaleHavePayOut || (totalAmount < 0 && props?.posSetting?.SALE?.allow_negative_item_prices == 1) || isSaleHaveNegativeType
                      ? "mt-3 pay-btn have-price"
                      : "mt-3 pay-btn disabled"
                  }
                >
                  {showCashNonCash > 0 && sumCash > 0 && nonCashAdjEnable > 0
                    ? `Cash: ${currencyFormat(
                      roundOffTotalPay(Number(sumCash).toFixed(2))
                    )} ` +
                    "   " +
                    `Non-Cash: ${currencyFormat(
                      roundOffTotalPay(Number(totalAmount))
                    )} `
                    : ``}

                  {showRegularPriceBtn > 0 && CalculateAdditionalCardPrice(+sumCash) > 0 && regularEnable > 0
                    ? `Cash: ${currencyFormat(
                      roundOffTotalPay(Number(sumCash).toFixed(2))
                    )} ` +
                    "   " +
                    `Regular: ${currencyFormat(parseFloat(+sumCash + CalculateAdditionalCardPrice(+sumCash)).toFixed(2))} `
                    : ``}

                  {
                    !(
                      showCashNonCash > 0 &&
                      sumCash > 0 &&
                      nonCashAdjEnable > 0
                    ) &&
                    !(
                      showRegularPriceBtn > 0 &&
                      CalculateAdditionalCardPrice(+sumCash) > 0 &&
                      regularEnable > 0
                    ) &&
                    `pay ${currencyFormat(roundOffTotalPay(Number(totalAmount)))}`
                  }
                  {/* {CalculateAdditionalCardPrice(totalAmount) > 0 && (
                      ` Regular : ${parseFloat(+sumCash + CalculateAdditionalCardPrice(+sumCash)).toFixed(2)}`
                    )} */}

                </Button>
                <Button
                  onClick={() => handleDialogPayAmount()}
                  className={
                    totalAmount > 0 || isSaleHavePayOut
                      ? "mt-3 pay-btn d-flex d-md-none have-price"
                      : "mt-3 pay-btn d-flex d-md-none disabled"
                  }
                >
                  Card $0.00
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <CustomModal
        open={show === "AMOUNT"}
        handleClose={handleDailogPayClose}
        dialogClassName="custom-modal modal-60w checkout-popup"
        backdrop="static"
        keyboard={false}
        title="Checkout"
        size="lg"
        content={
          <Checkout
            closePopUp={setShow}
            custMobile={custMobile}
            PosSetting={possetting}
            GeneralSetting={generalsetting}
            setCustMobile={setCustMobile}
            AccountSetting={accountsetting}
          />
        }
      />

      <CustomModal
        open={show === "ERROR"}
        handleClose={handleDailogPayClose}
        dialogClassName="custom-modal confirmation success-popup"
        title={title}
        content={<h1 className="mb-3">{errorMsg}</h1>}
        action={
          <>
            {!continueBtn ? (
              <Button
                type="button"
                onClick={handleDailogPayClose}
                variant="primary px-5"
                title="Okay"
              >
                Okay
              </Button>
            ) : (
              <>
                <Button
                  type="button"
                  onClick={() => handleDialogPayAmount(true)}
                  variant="primary px-5"
                  title="Okay"
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  onClick={handleDailogPayClose}
                  variant="danger px-5"
                  title="Okay"
                >
                  No
                </Button>
              </>
            )}
          </>
        }
      />

      <CustomModal
        open={pointsRequiredError}
        handleClose={() => setPointsRequiredError(false)}
        dialogClassName="custom-modal confirmation success-popup"
        title={title}
        content={
          <>
            <div className="custom-data-table">
              <div className="custom-card no-box-shadow req-th">
                <h1 className="mb-3">{errorMsg}</h1>
                <BootsrapTable2
                  keyField={"id"}
                  columns={pointsRequiredColumns}
                  data={pointsRequiredList}
                />
              </div>
            </div>
          </>
        }
        action={
          <>
            <Button
              type="button"
              onClick={() => setPointsRequiredError(false)}
              variant="primary px-5"
              title="Ok"
            >
              Ok
            </Button>
          </>
        }
      />

      <CustomModal
        open={show === STOCKCODECHECK}
        handleClose={handleDailogPayClose}
        dialogClassName="custom-modal confirmation item-not-found with-border-bottom"
        title="Item Not Found"
        content={
          <img
            className="my-3"
            width="80px"
            height="80px"
            src={props?.dynamicImages?.cancel}
            alt="cancel icon"
          />
        }
        action={
          <>
            <Button
              type="button"
              onClick={handleDailogPayClose}
              variant="outline-blackcustom  px-5"
              title="Ok"
            >
              OK
            </Button>
            <Button
              id="browseBtn"
              type="button"
              onClick={handleBrowseItem}
              variant="primary px-5"
              title="Browse"
            >
              Browse
            </Button>
            <Button
              type="button"
              title="Add New Item"
              onClick={() => {
                if (!checkUserPermission("allow_add_item_hide")) {
                  setAuth(true);
                } else {
                  setShow(ITEM);
                }
              }}
              variant="primary px-5"
            >
              Add New Item
            </Button>
          </>
        }
      />

      {/* Add or edit Item*/}

      <CustomModal
        open={show === ITEM}
        size="lg"
        handleClose={handleDailogPayClose}
        dialogClassName="custom-modal modal-60w item-popup"
        title="Add Item"
        content={
          <AddItems
            stockCode={barcode}
            closeEditModel={handleClose}
            AccountSetting={props?.AccountSetting}
            GeneralSetting={props?.GeneralSetting}
          />
        }
        action={
          <Button variant="light px-5" title="Cancel" onClick={handleDailogPayClose}>
            Cancel
          </Button>
        }
      />

      {/* pax */}

      <CustomModal
        open={show === PAXPOPUP}
        size="md"
        handleClose={handleDailogPaxClose}
        dialogClassName="custom-modal confirmation item-not-found with-border-bottom"
        title="Pax Initialization Error"
        content={<h3>{paxError}</h3>}
        action={
          <Button variant="light px-5" title="Ok" onClick={handleDailogPaxClose}>
            Ok
          </Button>
        }
      />

      {/* batch popup */}

      <CustomModal
        open={show === BATCHPOPUP}
        size="md"
        backdrop={false}
        handleClose={handleBatchDailogClose}
        dialogClassName="custom-modal confirmation item-not-found with-border-bottom"
        title="Batch Error"
        content={<h3>{batchError}</h3>}
        action={
          <>
            <Button variant="light px-5" title="Yes" onClick={handleBatchClose}>
              Yes
            </Button>
            <Button variant="light px-5" title="No" onClick={handleBatchDailogClose}>
              No
            </Button>
          </>
        }
      />

      {/* For show the button title and confirmation according to button click */}

      <CustomModal
        open={openErrorMsg}
        handleClose={handleClosepopUp}
        dialogClassName={`custom-modal confirmation ${status === 1 ? `success-popup` : `error-popup`
          }`}
        title={title}
        content={<h1 className="mb-3">{errorMsg}</h1>}
        action={
          <>
            {status != 2 && (
              <Button
                type="button"
                onClick={(e) => handleConfirm(e, errorMsg, status, title)}
                variant={`${status === 1 ? `success` : `danger`} px-5 m-auto`}
                title="Yes"
              >
                Yes
              </Button>
            )}
            <Button
              type="button"
              variant="light px-5"
              onClick={handleClosepopUp}
              title={status == 2 ? "Ok" : "No"}
            >
              {status == 2 ? "Ok" : "No"}
            </Button>
          </>
        }
      />

      <CustomModal
        open={auth}
        handleClose={() => setAuth(false)}
        dialogClassName="custom-modal  with-border-bottom authorization"
        title="Authorization"
        content={
          <Auth setAuthSuccess={setAuthSuccess} userlist={props.userlist} />
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5 me-2"
              onClick={() => setAuth(false)}
              title="Cancel"
            >
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    salesitemList: state.saleItem.saleItemList,
    offlineSale: state.saleItem.offlineSale,
    offlineSaleData: state.saleItem.offlineSaleData,
    totalAmounts: state.saleItem.totalAmount,
    accountSetting: state.inventory.accountSetting.data,
    posSetting: state.inventory.posSetting.data,
    generalSetting: state.inventory.generalSetting.data,
    saleList: state.saleItem.saleList,
    itemlist: state.inventory.itemlist,
    itemType: state.inventory.itemType,
    initialPaxpop: state.auth.initialPaxpop,
    initialBatchpop: state.auth.initialBatchpop,
    image: state.setting.images,
    userlist: state.inventory.userList,
    custlist: state.inventory.customerList,
    labeltemplates: state.saleItem.labeltemplates,
    category: state.inventory.category,
    pointsReedem: state.saleItem.reedemPointDiscount,
  };
};
export default connect(mapStateToprops)(Register);
