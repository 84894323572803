import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

export default function ToggleDark(props) {
  const [isChecked, setIsChecked] = useState(
    localStorage.getItem("darkMode") === "true"
  );
  useEffect(() => {
    localStorage.setItem("darkMode", isChecked);
  }, [isChecked]);
  const handleToggle = () => {
    setIsChecked(!isChecked);
    props.toggleDark();
  };
  return (
    <label className="switch">
      <input
        type="checkbox"
        id="checkbox-toggle"
        checked={isChecked}
        onChange={handleToggle}
      />
      <span className="slider"></span>
    </label>
  );
}
ToggleDark.propTypes = {
  toggleDark: PropTypes.func.isRequired, 
};
