import React, { lazy, Suspense } from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import SalesTables from "./salesTables";
import PrintLabel from "../printLabel";
import { CommonPusherComponent } from "../components/commonPusherConponent";
import LoaderSpinner from "components/Loader";

const Sales = () => {
  return (
    <>
      <PrintLabel />
      <CommonPusherComponent />
      <Row className="mb-3">
        <Col>
          <div className="custom-data-table">
            <SalesTables />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Sales;
