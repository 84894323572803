import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Button, Card, Col, Image, Row } from "@themesberg/react-bootstrap";
import ItemsContain from "./items_contain";
import ReactAutocomplete from "react-autocomplete";
import TotalInfo from "./total-info";
import { connect, useDispatch } from "react-redux";
import CustomModal from "../../components/Modals";
import {
  AGEPOPUPSHOW,
  CANCELED,
  NOSALE,
  ORDER,
  RECALL,
  OTHER,
  PAYOUT,
  REPRINT,
  ITEMSALE,
  ADDCONFIRMSALEITEM,
  COMPLETEVALUE,
  ORDERVALUE,
  REMOVEALLITEMSALE,
  ADDITEMONSALE,
  CANCELEDVALUE,
  DECELINEDVALUE,
  STOCKCODECHECK,
  ITEM,
  ITEMNOTFOUND,
  NUMPADSTATUS,
  ITEMNOSALE,
  GENERALSETIINGDATA,
  REMOVESALELIST,
  ITEMSALELIST,
  DEPRETURN
} from "../../../app/helpers/constant";
import AddItems from "./AddItem";
import {
  base64ToHex,
  checkValue,
  currencyFormat,
  dateFormate,
  debounce,
  FormatImageBase64Code,
  getLRC,
  getRemoveItemsIdSuspendSale,
  hexToBase64,
  HexToString,
  isWeb,
  StringToHex,
} from "../../../app/helpers/custom";
import {
  AddSaleItemList,
  AgePopUpShow,
  checkOutSaleItem,
  ShowNumpadStatus,
  confirmedResponseAction,
  RemoveSaleItemList,
} from "../../../app/actions/saleItemAction";
import AddPayout from "./addPayout";
import Cancel from "../../../assets/images/cancel.png";

import "../../../../src/assets/css/customScroll.css";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { Base64 } from "js-base64";
import $ from "jquery";
import {
  checkUserPermission,
  getDeviceAndStore,
  getSettingData,
} from "../../../axios/authHeader";
import { Auth } from "../../auth/auth";
import { addActionFunction } from "app/actions/getListAction";
import { formatCode } from "../../../app/helpers/custom";
import Localstorage from "app/storage/Localstorage";
import LoaderSpinner from "components/Loader";
import SalesTables from "pages/sales/salesTables";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FastPrint from "./FastPrint";
import PropTypes from 'prop-types';

const StockInformation = (props) => {
  const [darkMode, setDarkMode] = useState(props?.darkMode);
  const [isSlideInOpen, setSlideInOpen] = useState(false);
  const toggleSlideIn = () => {
    if (window.innerWidth <= 767) { // Check the screen width
      setSlideInOpen(!isSlideInOpen);
    }
  };
  const closeSlideIn = () => {
    setSlideInOpen(false);
  };
  const [isSlideInOpen1, setSlideInOpen1] = useState(false);
  const toggleSlideIn1 = () => {
    if (window.innerWidth <= 767) { // Check the screen width
      setSlideInOpen1(!isSlideInOpen1);
    }
  };
  const closeSlideIn1 = () => {
    setSlideInOpen1(false);
  };
  const handleCamera = () => {
    // setCamera(true);
    // console.log(`camera`);
    const eData = JSON.stringify({
      action: 'mobileScan',
    });
  
    // Check if window.ReactNativeWebView is defined before using optional chaining
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(eData);
    }
  };
  const [camera, setCamera] = useState(false);
  const [hideManualButton, setHideManualButton] = useState(false);
  const [manualItem, setManualItem] = useState(false);
  const [stockSearch, setstockSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [saleItemList, setSaleItemList] = useState([]);
  const [open, setopen] = useState(false);
  const [show, setShow] = useState(false);
  const [suspendButton, setSuspendButton] = useState(false);
  const [noSaleButton, setNoSalesButton] = useState(false);
  const [printValues, setPrintValues] = useState(null);
  const [barcode, setBarcode] = useState(null);

  const [openErrorMsg, setOpenErrorMsg] = useState(false);
  const [openErrorMsg1, setOpenErrorMsg1] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [title, setTitle] = useState("");
  const [auth, setAuth] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [suspendRecall, setSuspendRecall] = useState(false);
  const [cancleTransection, setCancleTransection] = useState(false);
  const [openTransactionDetails, setOpenTransactionDetails] = useState(false);
  const [UpcTenCode, setUpcTenCode] = useState(
    props?.genSetting?.OTHER?.use_upc_ten
  );
  const [KeyPad, setKeyPad] = useState(true);
  const [formData, setFormData] = useState(
    JSON.parse(localStorage.getItem("wpos_config"))
  );
  const [paxConfig, setPaxConfig] = useState({
    pax_ip: "",
    pax_port: "",
    pax_protocol: "",
  });
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [authorizedUsers, setAuthorizedUsers] = useState([]);
  const [depositReturn, setDepositReturn] = useState({});
  const [isDepReturn, setIsDepReturn] = useState(false);

  useEffect(() => {
    const setupData = JSON.parse(localStorage.getItem("setupData"));
    if (props?.devices.length > 0) {
      const paxDetails = props.devices.filter(
        (item) => item.id === setupData.device
      );
      if (
        paxDetails != undefined &&
        (paxDetails != "") & (paxDetails != null)
      ) {
        setPaxConfig({
          pax_ip: paxDetails[0].pax_ip,
          pax_port: paxDetails[0].pax_port,
          pax_protocol: paxDetails[0].pax_protocol,
        });
      }
    }
  }, [props.devices, localStorage.getItem("setupData")]);

  useEffect(() => {
    setUpcTenCode(props?.genSetting?.OTHER?.use_upc_ten);
  }, [props?.genSetting]);

  const dispatch = useDispatch();

  const handleButton = (e, type) => {
    e.preventDefault();
    if (localStorage.getItem("SystemStatus") == "false" && type == PAYOUT) {
      setOpenErrorMsg(true);
      setTitle("Error");
      setErrorMsg("Sorry Cannot Do a Payout While Offline.");
      return;
    }
    setShow(type);
  };

  useEffect(() => {
    setFormData(JSON.parse(localStorage.getItem("wpos_config")));
  }, [localStorage.getItem("wpos_config")]);

  const AddMoreData = (e) => {
    e.preventDefault();
    setManualItem(true);
    dispatch(ShowNumpadStatus(true, NUMPADSTATUS));
  };

  const handleLoader = (value, type) => {
    setLoader(value);
    setLoaderType(type);
  };

  // useEffect(() => {
  //   setShow(false);
  // }, [props?.success]);

  useEffect(() => {
    if (authSuccess == true && suspendRecall) {
      handleSales(undefined, auth, true);
      setAuth(false);
      setSuspendRecall(false);
      setAuthSuccess(false);
    }
  }, [auth, authSuccess, handleSales, suspendRecall]);
  useEffect(()=>{
    let filteredUsers = props.userlist?.filter((user)=>{
      if (user?.permission){
        if (user?.permission[getDeviceAndStore()[1]]?.pospermission?.allow_suspend_and_recall){
          return true
        }
      } else if (user?.store_owner == 1){
        return true
      }
      return false;
    });
    setAuthorizedUsers(filteredUsers)
  }, [props.userlist])

  useEffect(() => {
    const posSettings = Localstorage.getItem("posSetting");
    if (posSettings?.OPTION !== undefined) {
      setSuspendButton(
        checkValue(posSettings.OPTION?.enable_suspend_recall_buttons)
      );
      setHideManualButton(checkValue(posSettings.OPTION?.hide_add_manual_item));
    }
    if (posSettings?.SALE !== undefined) {
      setNoSalesButton(!checkValue(posSettings?.SALE?.hide_no_sale_button));
    }
  }, [props.posSetting, props.PosSetting]);

  const handleCancleSalePayment = () => {
    const data = updateSaleDataArray(props.payment);
    if (localStorage.getItem("SystemStatus") == "true") {
      dispatch(
        checkOutSaleItem(ITEMSALE, data, ADDCONFIRMSALEITEM, CANCELEDVALUE)
      );
    } else {
      addOfflineSale(data, "sales/add", null, CANCELEDVALUE);
      dispatch(confirmedResponseAction([], ITEMSALELIST))
      dispatch(confirmedResponseAction(true, REMOVESALELIST))
    }
    setCancleTransection(false);
    // setPrintRecipt(true);
  };

  const updateSaleDataArray = (payment, ignore) => {
    let data = {
      items: props?.salesitemList,
      totalAmt: props?.totalAmounts,
      payment: payment,
      fees: props?.additionalFee,
      taxlist: props?.taxList,
      taxRule: props?.taxRules,
      BottleDeposit: props?.bottleDeposit,
      custMobile: props?.custMobile,
      promotionData: props?.promotionData
    };
    if (!ignore) {
      data = {
        ...data,
        remove_items_id: getRemoveItemsIdSuspendSale(props?.salesitemList?.map(e => e.id))
      }
    }
    return data;
  };

  const handleAddBalance = () => {
    const genSet = props.genSetting.CREDIT;
    if (genSet.gift_item_id != "") {
      const data = props?.itemList.filter(
        (item) => item.id === parseInt(genSet.gift_item_id)
      );
      dispatch(AddSaleItemList(data[0], ADDITEMONSALE));
      dispatch(ShowNumpadStatus(true, NUMPADSTATUS));
      setKeyPad(true)
      dispatch(AgePopUpShow(true, AGEPOPUPSHOW));
      setShow(false);
    }
  };

  function checkSaleList(saleList) {
    const checkQty = saleList.filter((item) => item.qty === "");
    const checkPrice = saleList.filter(
      (item) => item.price === "" || item.price === parseFloat(0).toFixed(2)
    );
    if (checkQty && checkQty.length > 0) {
      ShowMsgInfo(
        true,
        "Please add qty of all items before proceeding!",
        "Sale Information"
      );
      return false;
    } else if (checkPrice && checkPrice.length > 0) {
      ShowMsgInfo(
        true,
        "Please add price of all items before proceeding!",
        "Sale Information"
      );
      return false;
    } else {
      return true;
    }
  }

  // function preparePrinter(type) {
  //   let thermaPrinter;
  //   if (type == "star") {
  //     thermaPrinter = new window.ThermalPrinter({
  //       type: "STAR",
  //     });
  //   } else {
  //     thermaPrinter = new window.ThermalPrinter({
  //       type: "EPSON",
  //     });
  //   }
  //   return thermaPrinter;
  // }

  const TestCashdraw = () => {
    if (formData?.cash_draw_connected) {
      var thermaPrinter = preparePrinter("epson");
      thermaPrinter.openCashDrawer();
      //thermaPrinter.println(data);
      window.nodeprinter.printDirect({
        data: thermaPrinter.getBuffer(),
        type: "RAW", // type: RAW, TEXT, PDF, JPEG, .. depends on platform
        printer: formData.report_printer,
        success: function (jobID) {
          console.log("sent to printer with ID: " + jobID);
        },
        error: function (err) {
          console.log(err);
        },
      });
    } else {
      ShowMsgInfo(true, "Cash draw not connected or configured!!", "Alert!", 2);
    }
  };

  function getRandomId() {
    return "xxxxxxxx".replace(/[xy]/g, function (c) {
      let r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  function refrenceNumber(device) {
    let reference_number = 0;
    const date = new Date().getTime();
    if (device) {
      reference_number =
        date + "-" + device + "-" + Math.floor(Math.random() * 10000 + 1);
    } else {
      reference_number = date;
    }
    return reference_number;
  }

  let payloadData = {
    total: "0.00",
    rounding: 0,
    isroundingenable: false,
    extracosts: {},
    ref: refrenceNumber(JSON.parse(localStorage.getItem("setupData"))?.device),
    userid: JSON.parse(localStorage.getItem("setupData"))?.id,
    device_id: JSON.parse(localStorage.getItem("setupData"))?.device,
    store_id: JSON.parse(localStorage.getItem("setupData"))?.store,
    register_id: JSON.parse(localStorage.getItem("setupData"))?.id,
    locid: "1",
    custid: "0",
    custemail: "",
    custname: "",
    custphone: "",
    cost: "0.00",
    subtotal: 0,
    numitems: 0,
    houseborrow: false,
    housepaid: false,
    processdt: `${Date.now()}`,
    status: 8,
    items: [
      {
        ref:
          refrenceNumber(
            JSON.parse(localStorage.getItem("setupData"))?.device
          ) + getRandomId(),
        sitemid: 0,
        donotdiscountitem: false,
        qty: "0.00",
        name: "N/A",
        unit: "0.00",
        taxid: 0,
        increaseqty: 0,
        tax: {
          total: "0.00",
          inclusive: 0,
        },
        price: "0.00",
        code_id: 0,
        itemrank: "",
        fee: [],
        allowebt: false,
        unit_original: "0.00",
        price_total: "",
        discountamt: "0.00",
        itemdeposit: "0.00",
        additionalcharges: 0,
        additionalfeetaxable: 0,
        additionalfeenontaxable: 0,
        donotdiscountitem: false,
        itemtype: "inventoryitem",
        changed_price: 0,
        itemtags: "",
        cost: "0.00",
        manual_item: false,
        minprice: 0,
        modifier: [
          {
            avg_cost: 0,
          },
        ],
      },
    ],
    codes: [
      {
        sitemid: 0,
        qty: "0.00",
        name: "N/A",
        code: "1",
        code_id: "0",
      },
    ],
    payments: [],
    payout: 0,
    discountamt: "0.00",
    multipleDiscounts: [],
    discountvalue: 0,
    discountindex: 0,
    customerage: 0,
    customerdob: 0,
    discount_amountvalue: 0,
    discount: 0,
    totaltendered: 0,
    promotions: 0,
    additionalcharges: 0,
    manualpriceedit: 0,
    rating: 0,
    tax: 0,
    taxdata: "",
    orderstatus: 7,
    isnosaleorder: true,
    response: [],
  };

  const addOfflineSale = (jsonobj, action, callback = null, value) => {
    console.log(`jsonobj->`, jsonobj);

    var jsonosales = {};
    var card_response = [];

    const storeId = getDeviceAndStore()[1];
    const device = getDeviceAndStore()[0];

    const Details = jsonobj.items;

    if (!jsonobj.hasOwnProperty("response")) {
      jsonobj.response = [];
      jsonobj.response = JSON.parse(JSON.stringify(card_response));
    }

    const totalNumberItem = Details.map((data) => {
      if (data.qty === "NaN") {
        return parseFloat(0);
      } else {
        return parseFloat(data.qty);
      }
    });

    let customer = localStorage.getItem("sale_customer");
    customer = JSON.parse(
      customer && customer !== null && customer !== "null" ? customer : "{}"
    );
    localStorage.setItem("customer_rating", "");
    localStorage.setItem("sale_customer", null);
    localStorage.removeItem("customerNumPadHide", false);

    const reference_number = refrenceNumber(device);

    if (!jsonobj.hasOwnProperty("processdt")) {
      jsonobj.processdt = "";
      jsonobj.processdt = refrenceNumber();
      jsonobj.ref = refrenceNumber(device);
      jsonobj.Payments = jsonobj.payment;
      jsonobj.payments = jsonobj.payment;
      jsonobj.items = Details.map((detail) => {
        return { ...detail, temp_sale_id: jsonobj?.ref.split("-")[2], processdt: jsonobj.processdt }
      });
      jsonobj.numitems = parseFloat(totalNumberItem.reduce((a, b) => a + b, 0));
      jsonobj.status = value ? value : ORDERVALUE;
      jsonobj.total = parseFloat(jsonobj.totalAmt.Total.toFixed(2));
      jsonobj.custid = customer?.id || "";
      jsonobj.custname = customer?.name || "";
      jsonobj.custphone = customer?.mobile || "";
      jsonobj.customer = customer || "";
    }

    console.log(`jsonobj`, jsonobj);
    var newJson = JSON.parse(JSON.stringify(jsonobj));
    jsonosales[reference_number] = {};
    jsonosales[reference_number].a = action;
    jsonosales[reference_number].data = jsonobj;
    jsonobj.store_id = storeId;
    console.log(`newJson`, newJson);
    let off_sales = JSON.parse(localStorage.getItem("off_sale"));
    console.log("off_sales is", off_sales);
    if (off_sales) {
      localStorage.setItem("off_sale", JSON.stringify([jsonobj, ...off_sales]));
    } else {
      localStorage.setItem("off_sale", JSON.stringify([jsonobj]));
    }

    localStorage.setItem("wpos_osales", JSON.stringify(jsonosales));
    if (callback != null) {
      callback(jsonosales);
    }
    console.log("off_sale are", JSON.parse(localStorage.getItem("off_sale")));
  };

  const updateOfflineSales = (jsonobj, action, callback = null, value) => {
    console.log(`suspend data1`, jsonobj);

    var jsonosales = {};
    var card_response = [];

    const storeId = getDeviceAndStore()[1];
    const device = getDeviceAndStore()[0];

    const Details = jsonobj.items;

    if (!jsonobj.hasOwnProperty("response")) {
      jsonobj.response = [];
      jsonobj.response = JSON.parse(JSON.stringify(card_response));
    }

    const totalNumberItem = Details.map((data) => {
      if (data.qty === "NaN") {
        return parseFloat(0);
      } else {
        return parseFloat(data.qty);
      }
    });

    let customer = localStorage.getItem("sale_customer");
    customer = JSON.parse(
      customer && customer !== null && customer !== "null" ? customer : "{}"
    );
    localStorage.setItem("customer_rating", "");
    localStorage.setItem("sale_customer", null);
    localStorage.removeItem("customerNumPadHide", false);

    const reference_number = refrenceNumber(device);

    if (!jsonobj.hasOwnProperty("processdt")) {
      jsonobj.processdt = "";
      jsonobj.processdt = Details[0]?.itemData?.processdt;
      jsonobj.ref = Details[0]?.itemData?.ref;
      jsonobj.Payments = jsonobj.payment;
      jsonobj.payments = jsonobj.payment;
      jsonobj.items = Details.map((detail) => {
        return { ...detail, temp_sale_id: jsonobj?.ref.split("-")[2], processdt: jsonobj.processdt }
      });
      jsonobj.numitems = parseFloat(totalNumberItem.reduce((a, b) => a + b, 0));
      jsonobj.status = value ? value : ORDERVALUE;
      jsonobj.total = parseFloat(jsonobj.totalAmt.Total.toFixed(2));
      jsonobj.custid = customer?.id || "";
      jsonobj.custname = customer?.name || "";
      jsonobj.custphone = customer?.mobile || "";
      jsonobj.customer = customer || "";
    }

    console.log(`jsonobj`, jsonobj);
    var newJson = JSON.parse(JSON.stringify(jsonobj));
    jsonosales[reference_number] = {};
    jsonosales[reference_number].a = action;
    jsonosales[reference_number].data = jsonobj;
    jsonobj.store_id = storeId;
    console.log(`newJson`, newJson);
    let off_sales = JSON.parse(localStorage.getItem("off_sale"));
    console.log("off_sales is", off_sales);
    let updatedOfflineSales = off_sales.map((sale) => {
      if (sale?.ref == jsonobj.ref) {
        return jsonobj;
      }
      return sale;
    })
    if (off_sales) {
      localStorage.setItem("off_sale", JSON.stringify(updatedOfflineSales));
    }
    localStorage.setItem("wpos_osales", JSON.stringify(jsonosales));
    if (callback != null) {
      callback(jsonosales);
    }
    console.log("off_sale are", JSON.parse(localStorage.getItem("off_sale")));
  }

  const handleSales = (e, type, ignore) => {
    e && e.preventDefault();
    let data = updateSaleDataArray([], true);
    if (
      !checkUserPermission("allow_suspend_and_recall") &&
      (data?.items.length || type === RECALL) &&
      !ignore
    ) {
      setAuth(type);
      setSuspendRecall(true);
    } else {
      switch (type) {
        case ORDER:
          localStorage.setItem("suspendSale", "true");
          data = updateSaleDataArray([]);
          if (data?.items.length) {
            if (checkSaleList(data?.items)) {
              if (localStorage.getItem("SystemStatus") == "true") {
                dispatch(
                  checkOutSaleItem(
                    ITEMSALE,
                    data,
                    REMOVEALLITEMSALE,
                    ORDERVALUE,
                    data?.items[0]?.saleId
                  )
                );
                dispatch(RemoveSaleItemList(true, REMOVESALELIST));
              } else {
                dispatch(confirmedResponseAction({ data }, REMOVEALLITEMSALE));
                if (data?.items[0]?.itemData?.temp_sale_id != undefined) {
                  updateOfflineSales(data, "sales/add")
                } else {
                  addOfflineSale(data, "sales/add");
                }
              }
            }
          } else {
            ShowMsgInfo(true, "No item added!", "Error!");
          }
          break;
        case RECALL:
          let saleData = props?.suspendedSale;
          if (saleData && saleData?.status == DECELINEDVALUE) {
            return ShowMsgInfo(
              true,
              "Could not find the transaction record!.",
              "Error"
            );
          } else if (saleData && Object.keys(props?.suspendedSale).length) {
            // history.push({
            //   pathname: "/sales",
            //   state: { saleData },
            // });
            setOpenTransactionDetails(true);
          } else {
            ShowMsgInfo(
              true,
              "No transactions yet for this session.",
              "No Transactions"
            );
          }
          break;
        case NOSALE:
          if (!isWeb()) {
            TestCashdraw();
          }
          if (localStorage.getItem("SystemStatus") == "true") {
            dispatch(
              checkOutSaleItem(
                ITEMNOSALE,
                payloadData,
                ADDCONFIRMSALEITEM,
                COMPLETEVALUE,
                data?.items[0]?.sitemid
              )
            );
          } else {
            payloadData = { ...payloadData, noSale: true }
            let off_sales = JSON.parse(localStorage.getItem("off_sale"));
            if (off_sales) {
              localStorage.setItem("off_sale", JSON.stringify([...off_sales, payloadData]));
            } else {
              localStorage.setItem("off_sale", JSON.stringify([payloadData]));
            }
          }
          break;
        case CANCELED:
          if (data?.items.length) {
            if (checkSaleList(data?.items)) {
              setCancleTransection(true);
            }
          } else {
            ShowMsgInfo(true, "No item added!", "Error!");
          }
          break;

        default:
          break;
      }
    }
  };

  const handleDepReturn = (e)=>{
    e.preventDefault();
    // setManualItem(true);
    setIsDepReturn(true);
    dispatch(ShowNumpadStatus(true, NUMPADSTATUS));
  }

  useEffect(() => {
    if (stockSearch !== "") {
      updateTableSaleUi(stockSearch);
      setopen(false);
      setSearchValue("");
    }
  }, [stockSearch]);

  const updateTableSaleUi = (stockSearch) => {
    setSaleItemList(
      props?.itemList.filter(
        (item) => item.id === parseInt(stockSearch?.split(",")[0])
      )
    );
    setKeyPad(false);
    setstockSearch("setothervalue");
    dispatch(AgePopUpShow(true, AGEPOPUPSHOW));
  };

  //   const updateSaleDataArray =(payment)=>{
  //     return {
  //       items: props.salesitemList,
  //       totalAmt: props.totalAmounts,
  //       payment: payment,
  //       fees: props.additionalFee,
  //       taxlist: props.taxList,
  //       taxRule: props.taxRules,
  //       BottleDeposit: props.bottleDeposit,
  //     };
  // }

  const onSelectItem = (item) => {
    const SettingItemOrder = item.split(",");
    const FinalItem = SettingItemOrder[1] + "," + SettingItemOrder[0];
    setstockSearch(FinalItem);
    setopen(false);
    setSearchValue("");
  };

  const printReceipt = async () => {
    const wosConfig = JSON.parse(localStorage.getItem("wpos_config"));
    if (wosConfig.connectMethod == "ep") {
      if (isWeb()) {
        ShowMsgInfo(
          true,
          "Electron print support only in electron application.",
          "Alert!"
        );
      } else {
        // ESC/P receipt generation
        var esc_init = "\x1B" + "\x40"; // initialize printer
        var esc_a_c = "\x1B" + "\x61" + "\x31"; // align center
        var esc_double = "\x1B" + "\x21" + "\x31"; // heading
        var font_reset = "\x1B" + "\x21" + "\x02"; // styles off
        var esc_bold_on = "\x1B" + "\x45" + "\x31"; // emphasis on

        var cmd = getEscReceiptHeader();
        // cmd += getLogo();
        console.log("cmd1", cmd);
        cmd += getReceiptContent();
        console.log("cmd2", cmd);
        function getEscReceiptHeader() {
          const posSettings = JSON.parse(localStorage.getItem("posSetting"));
          const generalSettings = JSON.parse(
            localStorage.getItem("generalSetting")
          );
          let header =
            esc_init +
              esc_a_c +
              esc_double +
              generalSettings?.BUSINESS?.business_name
              ? generalSettings?.BUSINESS?.business_name
              : "" + font_reset + "\n";
          header +=
            esc_bold_on + posSettings?.RECEIPT?.header_line_2
              ? posSettings?.RECEIPT?.header_line_2
              : "" + "\n";
          header += posSettings?.RECEIPT?.header_line_3
            ? posSettings?.RECEIPT?.header_line_3
            : "" + "\n";
          return header;
        }

        // function getLogo() {
        //   const d_image = getDetailsofImage("PrintReciptLogo");
        //   if (d_image !== undefined && d_image.length > 0) {
        //     if (d_image[0]?.file !== undefined) {
        //       const origimage1 = FormatImageBase64Code(d_image[0].file)
        //       let salesPath = (window.appData) + '/logo';
        //       if (!window.fs.existsSync(salesPath)) {
        //         window.fs.mkdirSync(salesPath);
        //       }
        //       const base64Data = origimage1.replace(/^data:image\/png;base64,/, "");
        //       window.fs.writeFileSync(salesPath + "/image.png", base64Data, 'base64');
        //     }
        //   }
        // }
        const d_image = getDetailsofImage("PrintReciptLogo");
        if (d_image !== undefined && d_image.length > 0) {
          if (d_image[0]?.file !== undefined) {
            const origimage1 = FormatImageBase64Code(d_image[0].file);
            var salesPath = window.appData + "/logo";
            if (!window.fs.existsSync(salesPath)) {
              window.fs.mkdirSync(salesPath);
            }
            const base64Data = origimage1.replace(
              /^data:image\/png;base64,/,
              ""
            );
            window.fs.writeFileSync(
              salesPath + "/image.png",
              base64Data,
              "base64"
            );
          }
        }

        function getReceiptContent() {
          const generalSettings = JSON.parse(
            localStorage.getItem("generalSetting")
          );
          let saledate = parseInt(localStorage.getItem("saleDate"));
          if (!saledate) {
            saledate = new Date().getTime();
          }
          let qty = props?.salesitemList?.reduce(function (prev, current) {
            return prev + +current.qty;
          }, 0);

          // content += props?.payment.map((itm) => {
          //   return itm.method+"  "+currencyFormat(itm?.amount)+"\nTendered "+currencyFormat(itm?.tender)+"\nChange "+currencyFormat(itm?.change)+"\n\n";
          //   ;
          // })
          let content = "\n";
          // let content = '\n' + font_reset;

          content += getEscTableRow(
            "Payout Time:",
            dateFormate(new Date().getTime()),
            false,
            false,
            true
          );
          content += "\n";

          content += getEscTableRow(
            "Date:",
            moment(printValues?.expense_date).format("DD/MM/YY"),
            false,
            false,
            true
          );
          if (
            generalSettings?.BUSINESS?.business_name != undefined &&
            generalSettings?.BUSINESS?.business_name != ""
          ) {
            content += getEscTableRow(
              "Register Name:",
              generalSettings?.BUSINESS?.business_name,
              false,
              false,
              true
            );
          }
          content += getEscTableRow(
            "User Name:",
            props?.user_list
              ?.filter((itm) => itm.id == printValues?.user_id)
              .map((itm) => {
                return itm.username;
              }),
            false,
            false,
            true
          );
          console.log(`type`, printValues);
          if (printValues?.type.id != undefined && printValues?.type.id != "") {
            content += getEscTableRow(
              "Payout Type:",
              props?.expense_type_list
                ?.filter((itm) => itm.id == printValues?.type.id)
                .map((itm) => {
                  return itm.name;
                }),
              false,
              false,
              true
            );
          }
          if (
            printValues?.amount != undefined &&
            printValues?.amount != "" &&
            printValues?.amount != 0
          ) {
            content += getEscTableRow(
              "Payout:",
              currencyFormat(printValues?.amount),
              false,
              false,
              true
            );
          }
          if (printValues?.memo != undefined && printValues?.memo != "") {
            content += getEscTableRow(
              "Memo:",
              printValues?.memo,
              false,
              false,
              true
            );
          }
          if (
            printValues?.category.id != undefined &&
            printValues?.category.id != ""
          ) {
            content += getEscTableRow(
              "Category:",
              props?.expense_category_list
                ?.filter((itm) => itm.id == printValues?.category.id)
                .map((itm) => {
                  return itm.name;
                }),
              false,
              false,
              true
            );
          }
          if (
            printValues?.vendor.id != undefined &&
            printValues?.vendor.id != ""
          ) {
            content += getEscTableRow(
              "Vendor:",
              props?.expense_vendor_list
                ?.filter((itm) => itm.id == printValues?.vendor.id)
                .map((itm) => {
                  return itm.name;
                }),
              false,
              false,
              true
            );
          }
          if (
            printValues?.invoice_number != undefined &&
            printValues?.invoice_number != ""
          ) {
            content += getEscTableRow(
              "Invoice Number:",
              printValues?.invoice_number,
              false,
              false,
              true
            );
          }
          console.log(content);
          return content;
        }

        const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
        const posSettings = JSON.parse(localStorage.getItem("posSetting"));

        let qrtext = posSettings?.RECEIPT?.promo_qr_code;

        var thermaPrinter = preparePrinter("epson");
        console.log("thermaPrinter", thermaPrinter);
        thermaPrinter.alignCenter();
        if (
          salesPath != undefined &&
          posSettings?.RECEIPT?.print_receipt_logo == 1
        ) {
          console.log(`print logo`, salesPath);
          await thermaPrinter.printImage(salesPath + "/image.png");
        } else {
          console.log(`do not print logo`);
        }
        thermaPrinter.drawLine();
        thermaPrinter.newLine();
        console.log("cmd2", cmd);
        thermaPrinter.println(cmd);
        if (qrtext == "" || qrtext == null) {
          thermaPrinter.cut();
        }

        window.nodeprinter.printDirect({
          printerName: wpos_config.report_printer,
          type: "RAW",
          data: thermaPrinter.getBuffer(),
          success: function (jobID) {
            if (qrtext != "" && qrtext != null) {
              printQrCode(qrtext, true);
            }
            console.log(`Printed with Job ID: ${jobID}`);
          },
          error: function (err) {
            console.error(err);
          },
        });
      }
    } else {
      handlePrint();
    }
    setShow(false);
  };

  const printQrCode = async (qrtext, cutpaper) => {
    const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));

    let thermaPrinter = preparePrinter("epson");
    thermaPrinter.newLine();
    thermaPrinter.printQR(qrtext, {
      cellSize: 5, // 1 - 8
      correction: "H", // L(7%), M(15%), Q(25%), H(30%)
      model: 1, // 1 - Model 1
      // 2 - Model 2 (standard)
      // 3 - Micro QR
    });

    if (cutpaper) {
      thermaPrinter.cut();
    }

    window.nodeprinter.printDirect({
      data: thermaPrinter.getBuffer(),
      type: "RAW", // type: RAW, TEXT, PDF, JPEG, .. depends on platform
      printerName: wpos_config.report_printer,
      success: function (jobID) {
        console.log("sent to printer with ID: " + jobID);
      },
      error: function (err) {
        console.log(err);
      },
    });
  };

  function preparePrinter(type) {
    let thermaPrinter;
    if (type == "star") {
      thermaPrinter = new window.ThermalPrinter({
        type: "STAR",
      });
    } else {
      thermaPrinter = new window.ThermalPrinter({
        type: "EPSON",
      });
    }
    return thermaPrinter;
  }

  function getDetailsofImage(type) {
    if (type !== undefined) {
      const detailsData = props?.image.filter((item) => item.name === type);
      return detailsData;
    }
  }

  function getEscTableRow(
    leftstr,
    rightstr,
    bold,
    underline,
    stretch,
    name = ""
  ) {
    var esc_ul_on = "\x1B" + "\x2D" + "\x31"; // underline on
    var esc_ul_off = "\x1B" + "\x2D" + "\x30"; // underline off
    var esc_bold_on = "\x1B" + "\x45" + "\x31"; // emphasis on
    var esc_bold_off = "\x1B" + "\x45" + "\x30"; // emphasis off
    var ltr = true;

    var sheet_width = 48;
    sheet_width = sheet_width ? sheet_width : 48;
    rightstr = rightstr != undefined && rightstr != "" ? rightstr : "-";
    leftstr = leftstr.toString();
    rightstr = rightstr.toString();
    var pad = "";
    // adjust for bytes of escp commands that set the character set
    var llength =
      leftstr.indexOf("\x1B\x74") !== -1
        ? leftstr.length - 3 * (leftstr.match(/\x1B\x74/g) || []).length
        : leftstr.length;
    var rlength =
      rightstr.indexOf("\x1B\x74") !== -1
        ? rightstr.length - 3 * (rightstr.match(/\x1B\x74/g) || []).length
        : rightstr.length;
    if (llength + rlength > sheet_width) {
      // var clip = (llength + rlength) - sheet_width; // get amount to clip
      // leftstr = leftstr.substring(0, (llength - (clip + 3)));
      // pad = "...";
      //no need to add ... we need to show all info lets go to new line
      if (name == "price") {
        var mid = sheet_width - rlength - 1;
        //rightstr = " "+rightstr+"  "+leftstr.substring(mid,llength);
        rightstr =
          " " + rightstr + "  " + "\n" + leftstr.substring(mid, llength);
        leftstr = leftstr.substring(0, mid);
      }
    } else {
      var num = sheet_width - (llength + rlength);
      pad = new Array(num + 1).join(" ");
    }
    var row;
    if (ltr) {
      row =
        leftstr +
        (stretch ? pad : "") +
        (underline ? esc_ul_on : "") +
        rightstr +
        (underline ? esc_ul_off : "") +
        (!stretch ? pad : "") +
        "\n";
    } else {
      row =
        (!stretch ? pad : "") +
        (underline ? esc_ul_on : "") +
        rightstr +
        (underline ? esc_ul_off : "") +
        (stretch ? pad : "") +
        leftstr +
        "\n";
    }
    if (bold) {
      // format row
      row = esc_bold_on + row + esc_bold_off;
    }
    return row;
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const ShowMsgInfo = (modal, type, msg) => {
    setOpenErrorMsg(modal);
    setErrorMsg(type);
    setTitle(msg);
  };

  const ComponentToPrint = React.forwardRef((props, ref) => {

    const posSettings = JSON.parse(localStorage.getItem("posSetting"));
    const generalSettings = JSON.parse(localStorage.getItem("generalSetting"));

    // const images = require.context('../../../assets/images/apptype', true);
    // let imgsrc;
    // if(localStorage.getItem("appLogo") === null){
    //   imgsrc = logo;
    // }else{
    //   imgsrc = images(`./${localStorage.getItem("appLogo")}.png`);
    // }
    let date_format;
    if (generalSettings?.FORMATES?.Date_Format == "m/d/y") {
      date_format = "MM/DD/YYYY";
    } else if (generalSettings?.FORMATES?.Date_Format == "d/m/y") {
      date_format = "DD/MM/YYYY";
    } else if (generalSettings?.FORMATES?.Date_Format == "y/m/d") {
      date_format = "YYYY/MM/DD";
    }
    let origimage1 = "";
    const d_image = getDetailsofImage("PrintReciptLogo");
    if (d_image !== undefined && d_image.length > 0) {
      if (d_image[0]?.file !== undefined) {
        origimage1 = FormatImageBase64Code(d_image[0].file);
      }
    }

    return (
      <>
        <div className="print-container" ref={ref}>
          {origimage1 != undefined &&
            posSettings?.RECEIPT?.print_receipt_logo == 1 && (
              <Image src={props?.dynamicImages?.origimage1} alt="logo" />
            )}
          <div style={{ alignItem: "center" }}>
            <h3 style={{ textAlign: "center" }}>
              {generalSettings?.BUSINESS?.business_name}
            </h3>
            <p style={{ textAlign: "center" }}>
              <strong>{posSettings?.RECEIPT?.header_line_2}</strong>
            </p>
            <p>
              <strong>{posSettings?.RECEIPT?.header_line_3}</strong>
            </p>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>Payout Time:</td>
                  <td>{dateFormate(new Date().getTime())}</td>
                </tr>
                <tr>
                  <td colSpan={2}>Date:</td>
                  <td>
                    {moment(printValues?.expense_date).format(date_format)}
                  </td>
                </tr>
                {generalSettings?.BUSINESS?.business_name != undefined &&
                  generalSettings?.BUSINESS?.business_name != "" && (
                    <tr>
                      <td colSpan={2}>Register Name:</td>
                      <td>{generalSettings?.BUSINESS?.business_name}</td>
                    </tr>
                  )}
                {props?.user_list && props?.user_list.length > 0 && (
                  <tr>
                    <td colSpan={2}>User Name:</td>
                    <td>
                      {props?.user_list
                        ?.filter((itm) => itm.id == printValues?.user_id)
                        .map((itm) => {
                          return itm.username;
                        })}
                    </td>
                  </tr>
                )}

                {printValues?.type.id != undefined &&
                  printValues?.type.id != "" && (
                    <tr>
                      <td colSpan={2}>Payout Type:</td>
                      <td>
                        {props?.expense_type_list
                          ?.filter((itm) => itm.id == printValues?.type.id)
                          .map((itm) => {
                            return itm.name;
                          })}
                      </td>
                    </tr>
                  )}
                {printValues?.amount != undefined &&
                  printValues?.amount != "" &&
                  printValues?.amount != 0 && (
                    <tr>
                      <td colSpan={2}>Payout:</td>
                      <td>{currencyFormat(printValues?.amount)}</td>
                    </tr>
                  )}
                {printValues?.memo != undefined && printValues?.memo != "" && (
                  <tr>
                    <td colSpan={2}>Memo:</td>
                    <td>{printValues?.memo}</td>
                  </tr>
                )}
                {printValues?.category.id != undefined &&
                  printValues?.category.id != "" && (
                    <tr>
                      <td colSpan={2}>Category:</td>
                      <td>{printValues?.category?.label}</td>
                    </tr>
                  )}
                {printValues?.vendor.id != undefined &&
                  printValues?.vendor.id != "" && (
                    <tr>
                      <td colSpan={2}>Vendor:</td>
                      <td>
                        {props?.expense_vendor_list
                          ?.filter((itm) => itm.id == printValues?.vendor.id)
                          .map((itm) => {
                            return itm.name;
                          })}
                      </td>
                    </tr>
                  )}
                {printValues?.invoice_number != undefined &&
                  printValues?.invoice_number != "" && (
                    <tr>
                      <td colSpan={2}>Invoice Number:</td>
                      <td>{printValues?.invoice_number}</td>
                    </tr>
                  )}
              </tbody>
            </table>
            {/* <strong className="footer-details">{posSettings?.RECEIPT?.receipt_footer_text}</strong> */}
            {posSettings?.RECEIPT?.promo_qr_code && (
              <div className="mt-2">
                <QRCode
                  style={{ zIndex: 1 }}
                  size={100}
                  value={posSettings?.RECEIPT?.promo_qr_code}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  });

  const ReprintFn = async () => {
    const wosConfig = JSON.parse(localStorage.getItem("wpos_config"));
    const paymentArray = JSON.parse(localStorage.getItem("payment_reprint"));
    const salesArray = JSON.parse(localStorage.getItem("saleitems_reprint"));
    if (wosConfig) {
      if (wosConfig.connectMethod == "ep") {
        if (isWeb()) {
          ShowMsgInfo(
            true,
            "Electron print support only in electron application.",
            "Alert!"
          );
        } else {
          // ESC/P receipt generation
          var esc_init = "\x1B" + "\x40"; // initialize printer
          var esc_a_c = "\x1B" + "\x61" + "\x31"; // align center
          var esc_double = "\x1B" + "\x21" + "\x31"; // heading
          var font_reset = "\x1B" + "\x21" + "\x02"; // styles off
          var esc_bold_on = "\x1B" + "\x45" + "\x31"; // emphasis on

          var cmd = getEscReceiptHeader();
          // cmd += getLogo();
          cmd += getReceiptContent();

          function getEscReceiptHeader() {
            // var header = esc_init+esc_a_c+"Bottlepos" + "\n";
            // return header;
            const posSettings = JSON.parse(localStorage.getItem("posSetting"));
            const generalSettings = JSON.parse(
              localStorage.getItem("generalSetting")
            );
            let header =
              esc_init +
              esc_a_c +
              esc_double +
              generalSettings?.BUSINESS?.business_name +
              font_reset +
              "\n";
            header += esc_bold_on + posSettings?.RECEIPT?.header_line_2 + "\n";
            header += posSettings?.RECEIPT?.header_line_3 + "\n";
            return header;
          }

          const d_image = getDetailsofImage("PrintReciptLogo");
          if (d_image !== undefined && d_image.length > 0) {
            if (d_image[0]?.file !== undefined) {
              const origimage1 = FormatImageBase64Code(d_image[0].file);
              var salesPath = window.appData + "/logo";
              if (!window.fs.existsSync(salesPath)) {
                window.fs.mkdirSync(salesPath);
              }
              const base64Data = origimage1.replace(
                /^data:image\/png;base64,/,
                ""
              );
              window.fs.writeFileSync(
                salesPath + "/image.png",
                base64Data,
                "base64"
              );
            }
          }

          function getReceiptContent() {
            const posSettings = JSON.parse(localStorage.getItem("posSetting"));
            const reference = JSON.parse(localStorage.getItem("ref"));
            const paymentArray = JSON.parse(
              localStorage.getItem("payment_reprint")
            );
            const salesArray = JSON.parse(
              localStorage.getItem("saleitems_reprint")
            );
            const custMobile = JSON.parse(
              localStorage.getItem("custMobile_reprint")
            );

            let qty = salesArray?.reduce(function (prev, current) {
              return prev + +current.qty;
            }, 0);
            let total_tendered = paymentArray?.reduce(function (prev, current) {
              return prev + +current.tender;
            }, 0);
            let total = paymentArray?.reduce(function (prev, current) {
              return prev + +current.amount;
            }, 0);
            let saledate = parseInt(localStorage.getItem("saleDate"));
            let subtotal = salesArray?.reduce(function (prev, current) {
              return prev + +current.subtotal;
            }, 0);

            let content = "\n" + font_reset;
            if (
              posSettings?.RECEIPT?.print_sale_id == 1 &&
              reference != undefined
            ) {
              content += getEscTableRow(
                "Transaction Ref:",
                reference,
                false,
                false,
                true
              );
            }
            content +=
              getEscTableRow(
                "Sale Time:",
                dateFormate(saledate),
                false,
                false,
                true
              ) + "\n";
            for (var x = 0; x < salesArray.length; x++) {
              content +=
                getEscTableRow(
                  salesArray[x].qty +
                  " x " +
                  salesArray[x].manualname +
                  " (" +
                  currencyFormat(salesArray[x]?.price) +
                  ")",
                  currencyFormat(salesArray[x]?.subtotal),
                  false,
                  false,
                  true
                ) + "\n";
            }
            content +=
              esc_bold_on +
              getEscTableRow(
                "Subtotal:",
                currencyFormat(subtotal),
                false,
                false,
                true
              );

            console.log(`salesArray`, salesArray);
            const uniqueTaxes = salesArray.reduce((acc, current) => {
              if (!acc[current.tax]) {
                acc[current.tax] = parseFloat(current.taxTotal);
              } else {
                acc[current.tax] += parseFloat(current.taxTotal);
              }
              return acc;
            }, {});

            const tip = paymentArray.reduce(
              (acc, item) => acc + parseFloat(item.tip_amount),
              0
            );

            Object.entries(uniqueTaxes).map(([taxName, taxValue], index) => {
              const tax_name = props.taxRules?.filter(
                (item) => item.id == taxName
              );
              if (tax_name && tax_name.length > 0) {
                if (index == Object.keys(uniqueTaxes).length - 1) {
                  if (tip != undefined && tip > 0) {
                    content +=
                      font_reset +
                      getEscTableRow(
                        tax_name[0].name + ":",
                        currencyFormat(taxValue),
                        false,
                        false,
                        true
                      );
                  } else {
                    content +=
                      font_reset +
                      getEscTableRow(
                        tax_name[0].name + ":",
                        currencyFormat(taxValue),
                        false,
                        true,
                        true
                      );
                  }
                } else {
                  content +=
                    font_reset +
                    getEscTableRow(
                      tax_name[0].name + ":",
                      currencyFormat(taxValue),
                      false,
                      false,
                      true
                    );
                }
              }
            });

            console.log(`tip amount->`, tip);
            if (tip != undefined && tip > 0) {
              content += getEscTableRow(
                "Tip Amount: ",
                currencyFormat(tip),
                false,
                true,
                true
              );
            }

            if (total != "" && total != undefined) {
              content +=
                esc_bold_on +
                getEscTableRow(
                  "Total (" + qty + " items):",
                  currencyFormat(total + parseFloat(tip)),
                  false,
                  true,
                  true
                );
            }
            if (total_tendered != "" && total_tendered != undefined) {
              content += getEscTableRow(
                "Total Tendered:",
                currencyFormat(total_tendered + parseFloat(tip)),
                false,
                false,
                true
              );
            }
            content += font_reset;
            console.log(`paymentArray`, paymentArray);
            for (var x = 0; x < paymentArray.length; x++) {
              let itm = paymentArray[x];
              content +=
                getEscTableRow(
                  itm.method + ":",
                  currencyFormat(itm?.amount),
                  false,
                  false,
                  true
                ) +
                getEscTableRow(
                  "Tendered:",
                  currencyFormat(itm?.tender),
                  false,
                  false,
                  true
                ) +
                getEscTableRow(
                  "Change:",
                  currencyFormat(itm?.change),
                  false,
                  false,
                  true
                );
            }

            if (
              paymentArray.length > 0 &&
              paymentArray[0].method == "card" &&
              paymentArray[0].hasOwnProperty("transaction_response")
            ) {
              content += "\n";
              if (
                paymentArray[0].transaction_response?.HostReferenceNumber != ""
              ) {
                content += getEscTableRow(
                  "Reference Number:",
                  paymentArray[0].transaction_response.HostReferenceNumber,
                  false,
                  false,
                  true
                );
              }
              if (paymentArray[0].transaction_response?.cardHolderName != "") {
                content += getEscTableRow(
                  "Card Type:",
                  paymentArray[0].transaction_response.cardHolderName.trim(),
                  false,
                  false,
                  true
                );
              }
              if (paymentArray[0].transaction_response?.accountNumber != "") {
                content += getEscTableRow(
                  "Account:",
                  paymentArray[0].transaction_response.accountNumber,
                  false,
                  false,
                  true
                );
              }
              content += getEscTableRow("Entry:", "Swipe", false, false, true);
              content += getEscTableRow(
                "Response Code:",
                "APPROVED",
                false,
                false,
                true
              );
              if (paymentArray[0].transaction_response?.statusCode != "") {
                content += getEscTableRow(
                  "Status:",
                  paymentArray[0].transaction_response.statusCode,
                  false,
                  false,
                  true
                );
              }
              if (tip != undefined && tip > 0) {
                content += getEscTableRow(
                  "Tip Amount: ",
                  currencyFormat(tip),
                  false,
                  false,
                  true
                );
              }
              if (paymentArray[0].transaction_response?.approvedAmount != "") {
                content += getEscTableRow(
                  "Sale:",
                  paymentArray[0].transaction_response.approvedAmount,
                  false,
                  false,
                  true
                );
              }
            }

            if (
              paymentArray.length > 0 &&
              paymentArray[0].method == "card" &&
              posSettings?.RECEIPT?.print_signature_line == "1"
            ) {
              content += "\n";
              content += "\n";
              content += "X_________________________________";
              content += "\n";
              content +=
                "Cardmember agrees to pay total \n in accordance with agreement \n governing use of such card.";
            }

            if (custMobile != "") {
              const customer = props?.custlist.filter(
                (item) => item.mobile == custMobile
              );
              if (
                customer.length > 0 &&
                posSettings?.RECEIPT?.print_customer_details == 1
              ) {
                if (posSettings?.RECEIPT?.print_customer_name == 1) {
                  content += "\n";
                  content += getEscTableRow(
                    "Customer Name:",
                    customer[0]?.name,
                    false,
                    false,
                    true
                  );
                }
                if (posSettings?.RECEIPT?.print_customer_mobile == 1) {
                  content += getEscTableRow(
                    "Customer Mobile:",
                    customer[0]?.mobile,
                    false,
                    false,
                    true
                  );
                }
                if (posSettings?.RECEIPT?.print_customer_address == 1) {
                  content += getEscTableRow(
                    "Customer Address:",
                    customer[0]?.address,
                    false,
                    false,
                    true
                  );
                }
                content += getEscTableRow(
                  "Customer Postcode:",
                  customer[0]?.postcode,
                  false,
                  false,
                  true
                );
                if (posSettings?.RECEIPT?.print_customer_notes == 1) {
                  content += getEscTableRow(
                    "Notes:",
                    customer[0]?.notes,
                    false,
                    false,
                    true
                  );
                }
              }
            }

            if (posSettings?.RECEIPT?.receipt_footer_text != undefined) {
              content +=
                "\n" +
                esc_bold_on +
                esc_a_c +
                posSettings?.RECEIPT?.receipt_footer_text;
            }
            content += font_reset;

            if (posSettings?.RECEIPT?.promo_qr_code) {
              // content += <QRCode style={{ zIndex: 1 }} value={posSettings?.RECEIPT?.promo_qr_code} />
            }
            console.log(content);
            return content;
          }

          const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
          const posSettings = JSON.parse(localStorage.getItem("posSetting"));
          let qrtext = posSettings?.RECEIPT?.promo_qr_code;

          console.log(wpos_config.report_printer);

          var thermaPrinter = preparePrinter("epson");
          thermaPrinter.alignCenter();

          if (
            salesPath != undefined &&
            posSettings?.RECEIPT?.print_receipt_logo == 1
          ) {
            await thermaPrinter.printImage(salesPath + "/image.png");
          }
          thermaPrinter.drawLine();
          thermaPrinter.newLine();
          thermaPrinter.println(cmd);
          if (qrtext == "" || qrtext == null) {
            thermaPrinter.cut();
          }

          if (salesArray && paymentArray) {
            window.nodeprinter.printDirect({
              printerName: wpos_config.report_printer,
              type: "RAW",
              data: thermaPrinter.getBuffer(),
              success: function (jobID) {
                if (qrtext != "" && qrtext != null) {
                  printQrCode(qrtext, true);
                }
                console.log(`Printed with Job ID: ${jobID}`);
              },
              error: function (err) {
                console.error(err);
              },
            });
          } else {
            ShowMsgInfo(
              true,
              "No transactions yet for this session.",
              "No Transactions"
            );
          }
        }
      } else {
        if (salesArray && paymentArray) {
          // handleRePrint();
          handleBrowserReceiptPrint();
        } else {
          ShowMsgInfo(
            true,
            "No transactions yet for this session.",
            "No Transactions"
          );
        }
      }
    } else {
      if (salesArray && paymentArray) {
        // handleRePrint();
        handleBrowserReceiptPrint();
      } else {
        ShowMsgInfo(
          true,
          "No transactions yet for this session.",
          "No Transactions"
        );
      }
      // ShowMsgInfo(
      //   true,
      //   "Printer is not selected.",
      //   "Alert!"
      // );
    }
  };

  const handleClosepopUp = () => {
    setTransactionType("");
    handleLoader(false, "");
    setOpenErrorMsg(false);
    setOpenErrorMsg1(false);
    setTimeout(() => {
      setErrorMsg("");
      setTitle("");
    }, 200);
  };

  const roundOffToNext = (decimalNumber, rounding) => {
    let roundedNumber =
      Math.ceil(decimalNumber / (rounding / 100)) * (rounding / 100);
    return roundedNumber.toFixed(2);
  };

  const roundOffTotalPay = (amount) => {
    const posSettings = JSON.parse(localStorage.getItem("posSetting"));
    const cashRounding =
      posSettings?.SALE?.cash_rounding ?? posSettings?.SALE?.cash_rounding;
    if (typeof amount !== "number" || isNaN(amount)) {
      return amount; // Return the original amount if it is not a valid number
    }

    if (cashRounding == 0) {
      return amount.toFixed(2);
    } else if (cashRounding == 5 || cashRounding == 10) {
      return roundOffToNext(amount, cashRounding);
    } else {
      return amount.toFixed(2);
    }
  };

  const componentRefRePrint = useRef();

  const handleBrowserReceiptPrint = () => {
    const posSettings = JSON.parse(localStorage.getItem("posSetting"));
    const generalSettings = JSON.parse(localStorage.getItem("generalSetting"));
    const reference = JSON.parse(localStorage.getItem("ref"));
    const paymentArray = JSON.parse(localStorage.getItem("payment_reprint"));
    const salesArray = JSON.parse(localStorage.getItem("saleitems_reprint"));
    const custMobile = JSON.parse(localStorage.getItem("custMobile_reprint"));

    let qty = salesArray?.reduce(function (prev, current) {
      return prev + +current.qty;
    }, 0);
    let total_tendered = paymentArray?.reduce(function (prev, current) {
      return prev + +current.tender;
    }, 0);
    let tax = salesArray?.reduce(function (prev, current) {
      return prev + +current.taxTotal;
    }, 0);
    let saledate = parseInt(localStorage.getItem("saleDate"));
    let subtotal = salesArray?.reduce(function (prev, current) {
      return prev + +current.subtotal;
    }, 0);

    let origimage1 = "";
    const d_image = getDetailsofImage("PrintReciptLogo");
    if (d_image !== undefined && d_image.length > 0) {
      if (d_image[0]?.file !== undefined) {
        origimage1 = FormatImageBase64Code(d_image[0].file);
      }
    }

    let allTax = [];
    let uniqueTaxes = "";

    if (salesArray != undefined && salesArray.length > 0) {
      uniqueTaxes = salesArray.reduce((acc, current) => {
        if (!acc[current.tax]) {
          acc[current.tax] = parseFloat(current.taxTotal);
        } else {
          acc[current.tax] += parseFloat(current.taxTotal);
        }
        return acc;
      }, {});
    }

    Object.entries(uniqueTaxes).map(([taxName, taxValue]) => {
      const tax_name = props.taxRules?.filter((item) => item.id == taxName);
      if (tax_name && tax_name.length > 0) {
        let array = {
          tax_name: tax_name[0].name,
          total: taxValue,
        };
        allTax.push(array);
      }
    });

    const customer = props?.custlist?.filter(
      (item) => item.mobile == custMobile
    );
    console.log(`customer>`, customer);
    console.log(`props?.custMobile>`, custMobile);
    console.log(`props?.custlist>`, props?.custlist);

    let printw = window.open("", "Receive Details", "height=800,width=650");
    printw.document.write(
      `<html>
         <head>
          <style> 
            @media print {} @page {size: landscape} 
          </style>`
    );
    printw.document.write(
      '</head><body style="font-family: \'Open Sans\', \'Helvetica Neue\',Helvetica,Arial,sans-serif;">'
    );

    printw.document.write(`
      <div id="container" style="text-align: center;">
        ${(origimage1 != undefined && posSettings?.RECEIPT?.print_receipt_logo == 1)
        ? `<img style="width: 260px" src=${origimage1} alt="logo" />`
        : ''
      }
        <div>
            <h3 style="margin-top: 5px; margin-bottom: 5px;">
              ${generalSettings?.BUSINESS?.business_name}
            </h3>
            <p style="margin-top: 0px; margin-bottom: 5px;">
              <strong>${posSettings?.RECEIPT?.header_line_2 ? posSettings?.RECEIPT?.header_line_2 : ''}</strong>
            </p>
            <p style="margin-top: 0px; margin-bottom: 5px;">
              <strong>${posSettings?.RECEIPT?.header_line_3 ? posSettings?.RECEIPT?.header_line_3 : ''}</strong>
            </p>
        </div>

        <div>
          <div style="font-size: 0;">
            <table style="margin: 0 auto; font-size: 16px;">
              <tbody>
              ${posSettings?.RECEIPT?.print_sale_id == 1 ?
        `<tr>
                  <td style="text-align: start">Transaction Ref:</td>
                  <td style="text-align: end">${reference}</td>
                </tr>`
        : ''
      }
                <tr>
                  <td style="text-align: start">Sale Time:</td>
                  <td style="text-align: end">${dateFormate(saledate)}</td>
                </tr>
                ${salesArray?.map((itm) => {
        return `
                          <tr>
                            <td style="text-align: start">
                              ${itm.qty} x ${itm.manualname}
                              ${posSettings?.RECEIPT?.print_item_price == 1 ? `(${currencyFormat(itm?.price)})` : ''}
                            </td>
                            <td style="text-align: end">${currencyFormat(itm?.subtotal)}</td>
                          </tr>
                          ${itm?.promotion ?
            `<tr>
                              <td style="text-align: start">${itm?.showText} </td>
                            </tr>`
            : ''}`;
      })
      }
                ${(subtotal != "" && subtotal != undefined)
        ? `<tr>
                      <td style="text-align: start">Subtotal:</td>
                      <td style="text-align: end">${currencyFormat(subtotal)}</td>
                    </tr>`
        : ''
      }
                ${allTax?.length > 0
        ? allTax?.map((itm) => {
          return `<tr>
                        <td style="text-align: start">${itm.tax_name}</td>
                        <td style="text-align: end">${currencyFormat(itm.total)}</td>
                      </tr>`})
        : ''
      }

              <tr>
                <td style="text-align: start">B Deposit:</td>
                <td style="text-align: end">${currencyFormat()}</td>
              </tr>

              ${roundOffTotalPay(subtotal + tax) - (subtotal + tax) > 0 ?
        `<tr>
                  <td style="text-align: start">Rounding:</td>
                  <td style="text-align: end">
                    ${currencyFormat(
          roundOffTotalPay(subtotal + tax) - (subtotal + tax)
        )}
                  </td>
                </tr>`
        : ''}

              ${subtotal != "" && subtotal != undefined
        ? `<tr>
                <td style="text-align: start">
                  <strong>Total (${qty} items):</strong>
                </td>
                <td style="text-align: end; text-decoration: overline;">
                  <strong>
                    ${currencyFormat(roundOffTotalPay(subtotal + tax))}
                  </strong>
                </td>
              </tr>`
        : ''}

              ${total_tendered != "" && total_tendered != undefined
        ? `<tr>
                      <td style="text-align: start">
                        <strong>Total Tendered:</strong>
                      </td>
                      <td style="text-align: end; text-decoration: overline;">
                        <strong>
                          ${currencyFormat(total_tendered)}
                        </strong>
                      </td>
                    </tr>`
        : ''}

              ${paymentArray?.map((item) => {
          return `<>
                        <tr>
                          <td style="text-align: start">${item.method}:</td>
                          <td style="text-align: end">${currencyFormat(item.amount)}</td>
                        </tr>
                        <tr>
                          <td style="text-align: start">Tendered:</td>
                          <td style="text-align: end">${currencyFormat(item.tender)}</td>
                        </tr>
                        <tr>
                          <td style="text-align: start">Change:</td>
                          <td style="text-align: end">${currencyFormat(item.change)}</td>
                        </tr>
                </>`})
      }

              ${(custMobile != "" &&
        posSettings?.RECEIPT?.print_customer_details == 1 &&
        customer != "") ?
        `<>
                    ${posSettings?.RECEIPT?.print_customer_name == 1
          ? `<tr className="mt-2">
                        <td style="text-align: start">Customer Name:</td>
                        <td style="text-align: end">${customer[0]?.name}</td>
                      </tr>`
          : ''}
                    ${posSettings?.RECEIPT?.print_customer_mobile == 1
          ? `<tr>
                        <td style="text-align: start">Customer Mobile:</td>
                        <td style="text-align: end">${customer[0]?.mobile}</td>
                      </tr>`
          : ''}
                    ${posSettings?.RECEIPT?.print_customer_address == 1
          ? `<tr>
                        <td style="text-align: start">Customer Address:</td>
                        <td style="text-align: end">${customer[0]?.address}</td>
                      </tr>`
          : ''}
                    ${posSettings?.RECEIPT?.print_customer_notes == 1
          ? `<tr>
                        <td style="text-align: start">Notes:</td>
                        <td style="text-align: end">${customer[0]?.notes}</td>
                      </tr>`
          : ''}
                  </>`
        : ''
      }
              </tbody>
            </table>
            ${posSettings?.RECEIPT?.print_signature_line == "1"
        ? `<div style="font-size: 16px;">
                <p>X________________________</p>
              </div>`
        : ''}

            <strong style="font-size: 16px;">
              ${posSettings?.RECEIPT?.receipt_footer_text ? posSettings?.RECEIPT?.receipt_footer_text : ''}
            </strong>

            ${posSettings?.RECEIPT?.promo_qr_code !== '' ?
        `<div style="font-size: 16px;">
                ${<QRCode
          style={{ zIndex: 1 }}
          size={100}
          value={posSettings?.RECEIPT?.promo_qr_code}
        />}
              </div>`
        : ''}
          </div>
        </div>
      </div>
    `);
    printw.document.write("</body></html>");
    printw.document.close();
    let printed = false;
    // console.log({ check: printw });
    if (!printed) {
      printed = true;
      printw.focus();
      printw.print();
    }
  }

  const ComponentToRePrint = React.forwardRef((props, ref) => {
    const posSettings = JSON.parse(localStorage.getItem("posSetting"));
    const generalSettings = JSON.parse(localStorage.getItem("generalSetting"));
    const reference = JSON.parse(localStorage.getItem("ref"));
    const paymentArray = JSON.parse(localStorage.getItem("payment_reprint"));
    const salesArray = JSON.parse(localStorage.getItem("saleitems_reprint"));
    const custMobile = JSON.parse(localStorage.getItem("custMobile_reprint"));

    // const images = require.context('../../../assets/images/apptype', true);
    // let imgsrc;
    // if(localStorage.getItem("appLogo") === null){
    //   imgsrc = logo;
    // }else{
    //   imgsrc = images(`./${localStorage.getItem("appLogo")}.png`);
    // }
    let qty = salesArray?.reduce(function (prev, current) {
      return prev + +current.qty;
    }, 0);
    let total_tendered = paymentArray?.reduce(function (prev, current) {
      return prev + +current.tender;
    }, 0);
    let tax = salesArray?.reduce(function (prev, current) {
      return prev + +current.taxTotal;
    }, 0);
    let saledate = parseInt(localStorage.getItem("saleDate"));
    let subtotal = salesArray?.reduce(function (prev, current) {
      return prev + +current.subtotal;
    }, 0);

    let origimage1 = "";
    const d_image = getDetailsofImage("PrintReciptLogo");
    if (d_image !== undefined && d_image.length > 0) {
      if (d_image[0]?.file !== undefined) {
        origimage1 = FormatImageBase64Code(d_image[0].file);
      }
    }

    let allTax = [];
    let uniqueTaxes = "";

    if (salesArray != undefined && salesArray.length > 0) {
      uniqueTaxes = salesArray.reduce((acc, current) => {
        if (!acc[current.tax]) {
          acc[current.tax] = parseFloat(current.taxTotal);
        } else {
          acc[current.tax] += parseFloat(current.taxTotal);
        }
        return acc;
      }, {});
    }

    Object.entries(uniqueTaxes).map(([taxName, taxValue]) => {
      const tax_name = props.taxRules?.filter((item) => item.id == taxName);
      if (tax_name && tax_name.length > 0) {
        let array = {
          tax_name: tax_name[0].name,
          total: taxValue,
        };
        allTax.push(array);
      }
    });

    const customer = props?.custlist?.filter(
      (item) => item.mobile == custMobile
    );
    console.log(`customer>`, customer);
    console.log(`props?.custMobile>`, custMobile);
    console.log(`props?.custlist>`, props?.custlist);

    return (
      <>
        <div className="print-container" ref={ref}>
          {origimage1 != undefined &&
            posSettings?.RECEIPT?.print_receipt_logo == 1 && (
              <Image src={origimage1} alt="logo" />
            )}
          <div style={{ alignItem: "center" }}>
            <h3 style={{ textAlign: "center" }}>
              {generalSettings?.BUSINESS?.business_name}
            </h3>
            <p style={{ textAlign: "center" }}>
              <strong>{posSettings?.RECEIPT?.header_line_2}</strong>
            </p>
            <p>
              <strong>{posSettings?.RECEIPT?.header_line_3}</strong>
            </p>
          </div>
          <div>
            <table>
              <tbody>
                {posSettings?.RECEIPT?.print_sale_id == 1 &&
                  reference != undefined && (
                    <tr>
                      <td colSpan={2}>Transaction Ref:</td>
                      <td>{reference}</td>
                    </tr>
                  )}
                <tr className="mb-2">
                  <td colSpan={2}>Sale Time:</td>
                  <td>{dateFormate(saledate)}</td>
                </tr>
                {salesArray?.map((itm) => {
                  return (
                    <tr>
                      <td colSpan={2}>
                        {itm.qty}*{itm.manualname}
                        {posSettings?.RECEIPT?.print_item_price == 1 &&
                          `(${currencyFormat(itm?.price)})`}
                      </td>
                      <td>{currencyFormat(itm?.subtotal)}</td>
                    </tr>
                  );
                })}
                {subtotal != "" && subtotal != undefined && (
                  <tr className="mt-2">
                    <td colSpan={2}>Subtotal:</td>
                    <td>{currencyFormat(subtotal)}</td>
                  </tr>
                )}
                {allTax?.length > 0 &&
                  allTax?.map((itm) => {
                    return (
                      <tr>
                        <td>{itm.tax_name}</td>
                        <td>{currencyFormat(itm.total)}</td>
                      </tr>
                    );
                  })}
                <tr>
                  <td colSpan={2}>B Deposit:</td>
                  <td>{currencyFormat()}</td>
                </tr>
                {subtotal != "" && subtotal != undefined && (
                  <tr>
                    <td colSpan={2}>
                      <strong>Total ({qty} items):</strong>
                    </td>
                    <td>
                      <strong style={{ textDecoration: "overline" }}>
                        {currencyFormat(subtotal + tax)}
                      </strong>
                    </td>
                  </tr>
                )}
                {total_tendered != "" && total_tendered != undefined && (
                  <tr>
                    <td colSpan={2}>
                      <strong>Total Tendered:</strong>
                    </td>
                    <td>
                      <strong style={{ textDecoration: "overline" }}>
                        {currencyFormat(total_tendered)}
                      </strong>
                    </td>
                  </tr>
                )}
                {paymentArray?.map((item) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={2}>{item.method}:</td>
                        <td>{currencyFormat(item.amount)}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>Tendered:</td>
                        <td>{currencyFormat(item.tender)}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>Change:</td>
                        <td>{currencyFormat(item.change)}</td>
                      </tr>
                    </>
                  );
                })}

                {custMobile != "" &&
                  posSettings?.RECEIPT?.print_customer_details == 1 &&
                  customer != "" && (
                    <>
                      {posSettings?.RECEIPT?.print_customer_name == 1 && (
                        <tr className="mt-2">
                          <td colSpan={2}>Customer Name:</td>
                          <td>{customer?.[0]?.name}</td>
                        </tr>
                      )}
                      {posSettings?.RECEIPT?.print_customer_mobile == 1 && (
                        <tr>
                          <td colSpan={2}>Customer Mobile:</td>
                          <td>{customer?.[0]?.mobile}</td>
                        </tr>
                      )}
                      {posSettings?.RECEIPT?.print_customer_address == 1 && (
                        <tr>
                          <td colSpan={2}>Customer Address:</td>
                          <td>{customer?.[0]?.address}</td>
                        </tr>
                      )}
                      {posSettings?.RECEIPT?.print_customer_notes == 1 && (
                        <tr>
                          <td colSpan={2}>Notes:</td>
                          <td>{customer?.[0]?.notes}</td>
                        </tr>
                      )}
                    </>
                  )}
              </tbody>
            </table>
            {posSettings?.RECEIPT?.print_signature_line == "1" &&
              subtotal != "" &&
              subtotal != undefined && (
                <div style={{ alignItem: "center" }}>
                  <p>X________________________</p>
                </div>
              )}
            <strong className="footer-details">
              {posSettings?.RECEIPT?.receipt_footer_text}
            </strong>
            {posSettings?.RECEIPT?.promo_qr_code != undefined && (
              <div className="mt-2">
                <QRCode
                  style={{ zIndex: 1 }}
                  size={100}
                  value={posSettings?.RECEIPT?.promo_qr_code}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  });

  const generate_ref_number = () => {
    var date = new Date().getTime();
    const setupData = JSON.parse(localStorage.getItem("setupData"));
    let reference_number =
      date +
      "-" +
      setupData.device +
      "-" +
      Math.floor(Math.random() * 10000 + 1);
    return reference_number;
  };

  const giftCardOtherTrans = (transType) => {
    console.log("check balance", transType);
    handleLoader(true, "Follow Prompts on Terminal");
    if (transactionType == "") setTransactionType(transType);
    var ref = generate_ref_number();
    var params = {};
    var dialogtitle = "";

    if (transType === "activate") {
      params = { ticketNumber: ref, transactionAmount: 0 };
      dialogtitle = "To Activate Gift Card";
    } else if (transType === "deactivate") {
      params = { ticketNumber: ref, transactionAmount: 0 };
      dialogtitle = "To Deactivate Gift Card";
    } else if (transType === "balance") {
      params = { ticketNumber: ref, transactionAmount: 0 };
      dialogtitle = "To View Balance Of Gift Card";
    }

    params.headers = getPaymentHeader(); // getpayment headers;

    console.log(`params`, params);
    console.log(`dialogtitle`, dialogtitle);

    var settings = getPaymentSettings(params, transType, "gift");

    $.ajax(settings)
      .done(function (res) {
        console.log(`res`, res);
        let response = handlePaymentResponse("Pax", res);

        console.log("response->", response);
        console.log("response->", response.ResponseMessage);
        if (response.isApproved == true) {
          console.log("approved");
          if (response.statusCode == "Activate Approved") {
            setTitle(response.statusCode);
            setOpenErrorMsg(true);
            ShowMsgInfo(true, "Card activated", "Gift Card");
          } else if (response.statusCode == "Deactivate Approved") {
            setTitle(response.statusCode);
            setOpenErrorMsg(true);
            ShowMsgInfo(true, "Card deactivated", "Gift Card");
          } else if (response.statusCode == "Balance Approved") {
            setTitle(response.statusCode);
            setOpenErrorMsg(true);
            ShowMsgInfo(
              true,
              `Gift Balance: $ ${parseFloat(
                response.AmountInformation.Balance1 / 100
              ).toFixed(2)}`,
              "Gift Card"
            );
          }
        } else {
          if (response.ResponseMessage == "ABORTED") {
            handleLoader(false, "");
            console.log(`aborted`, response.ResponseMessage);
            setOpenErrorMsg1(true);
            setErrorMsg(
              <>
                <div className="text-center">
                  <Image src={Cancel} alt="error icon" className="mb-3" />{" "}
                  <br />
                  <p>Error: Aborted</p>
                  <p className="mt-2">
                    {transType === "balance"
                      ? "Retry Balance?"
                      : transType === "activate"
                        ? "Retry Activate?"
                        : "Retry Deactivate?"}
                  </p>
                </div>
              </>
            );
            setTitle("Error");
          }
        }
      })
      .fail(function (jqXHR) {
        console.log(`jqXHR`, jqXHR);
        var errormessage;
        if (jqXHR.status === 0) {
          errormessage = "Could not connect.\n Verify your network.";
        } else if (jqXHR.status == 404) {
          errormessage = "Requested page not found. [404]";
        } else if (jqXHR.status == 500) {
          errormessage = "Internal Server Error [500].";
        } else {
          errormessage = "Uncaught Error.\n" + jqXHR.responseText;
        }
        handleLoader(false, "");
        setOpenErrorMsg1(true);
        setTitle("Error");
        setErrorMsg(
          <>
            <div className="text-center">
              <Image src={Cancel} alt="error icon" className="mb-3" /> <br />
              <p>Error: {errormessage}</p>
              <p className="mt-2">
                {transType === "balance"
                  ? "Retry Balance?"
                  : transType === "activate"
                    ? "Retry Activate?"
                    : "Retry Deactivate?"}
              </p>
            </div>
          </>
        );
        console.log(`errormessage`, errormessage);
      });
  };

  const getPaymentHeader = () => {
    var header = {};
    return header;
  };

  const handlePaymentResponse = (type, response) => {
    if (type == "Tripos" || type == "TriposDirect") {
      return response;
    } else if (type == "Pax") {
      return parseResponse(response);
    }
  };

  function parseResponse(response) {
    var commandType = "DoCredit";
    var checkParams = StringToHex(response).split(" ").pop();
    var RedundancyCheck = StringToHex(response).split(" ").pop().substring(1);

    var check = getLRC(checkParams);

    if (check == RedundancyCheck) {
      //get package detail info
      var packetInfo = [];
      var len = StringToHex(response).indexOf("03");
      var hex = StringToHex(response).slice(0, len).split(/02|1c/);

      console.log(hex);
      if (commandType == "DoCredit") {
        var subHex = [],
          subPacketInfo = [];
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            if (hex[i].indexOf("1f") > 0) {
              subHex = hex[i].split("1f");
              console.log(subHex);
              subPacketInfo = [];
              for (var j = 0; j < subHex.length; j++) {
                if (subHex[j] != "") {
                  subPacketInfo.push(HexToString(subHex[j]));
                }
              }
              console.log(subPacketInfo);
              packetInfo.push(subPacketInfo);
            } else {
              packetInfo[i] = HexToString(hex[i]);
            }
          }
        }
      } else {
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            packetInfo[i] = HexToString(hex[i]);
          }
        }
      }
      response = packetInfo;
    }
    if (typeof response == "string") {
      // WPOS.util.okDialog("Error", response, function (dat) { });
      console.log(`responsee`, response);
      return true;
    }

    var i = 0,
      j = -1;
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
      add: "10",
      activate: "08",
      deactivate: "12",
      balance: "23",
    };
    PacketageInfo.DoCredit.Status = response[++i];
    PacketageInfo.DoCredit.Command = response[++i];
    PacketageInfo.DoCredit.Version = response[++i];
    PacketageInfo.DoCredit.ResponseCode = response[++i];
    PacketageInfo.DoCredit.ResponseMessage = response[++i];
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.Command) {
          commandName = key;
        }
      }
    }
    if (PacketageInfo.DoCredit.ResponseCode == "000000") {
      PacketageInfo.DoCredit.isApproved = true;
    } else {
      PacketageInfo.DoCredit.isApproved = false;
    }

    PacketageInfo.DoCredit.HostInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.HostInformation == "") {
      PacketageInfo.DoCredit.HostInformation = {};
      PacketageInfo.DoCredit.HostInformation.HostResponseCode = "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage = "";
      PacketageInfo.DoCredit.HostInformation.AuthCode = "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber = "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber = "";
    } else {
      PacketageInfo.DoCredit.HostInformation.HostResponseCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.AuthCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit._processor = {
      processorLogs: [
        "ExpressResponseCode: [0]\r\nExpressResponseMessage: [" +
          PacketageInfo.DoCredit.isApproved
          ? commandName + "Approved"
          : "" +
          "]\r\nHostResponseCode: [" +
          PacketageInfo.DoCredit.HostInformation.HostResponseCode +
          "]\r\nHostResponseMessage: [ " +
          PacketageInfo.DoCredit.HostInformation.HostResponseMessage +
          "]",
      ],
      processorRawResponse: "",
      processorReferenceNumber:
        PacketageInfo.DoCredit.HostInformation.HostReferenceNumber,
      processorRequestFailed: PacketageInfo.DoCredit.isApproved,
      processorRequestWasApproved: PacketageInfo.DoCredit.isApproved,
      processorResponseCode: PacketageInfo.DoCredit.isApproved
        ? commandName + "Approved"
        : "",
      processorResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      expressResponseCode:
        PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      expressResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      hostResponseCode: PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      hostResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      logs: ["ExpressResponseCode: ''"],
      rawResponse: "",
    };

    PacketageInfo.DoCredit.TransactionType =
      response[++i] != undefined ? response[i] : "";

    PacketageInfo.DoCredit.commandName = "";
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.TransactionType) {
          commandName = key.charAt(0).toUpperCase() + key.slice(1) + " ";
          PacketageInfo.DoCredit.commandName = commandName;
        }
      }
    }

    PacketageInfo.DoCredit.AmountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AmountInformation == "") {
      PacketageInfo.DoCredit.AmountInformation = {};
      PacketageInfo.DoCredit.approvedAmount = "";
      PacketageInfo.DoCredit.subTotalAmount = "0";
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount = "";
      PacketageInfo.DoCredit.AmountInformation.AmountDue = "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount = "";
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount = "";
      PacketageInfo.DoCredit.cashbackAmount =
        PacketageInfo.DoCredit.AmountInformation.CashBackAmount;
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = "";
      PacketageInfo.DoCredit.debitSurchargeAmount = "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount = "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 = "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.approvedAmount = parseFloat(
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
      );
      PacketageInfo.DoCredit.subTotalAmount = parseFloat(
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
      );

      PacketageInfo.DoCredit.AmountInformation.AmountDue =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.tipAmount =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.TipAmount) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.TipAmount / 100)
          : PacketageInfo.DoCredit.AmountInformation.TipAmount;
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 =
        response[i][++j] != undefined ? response[i][j] : "";
    }
    PacketageInfo.DoCredit.fsaCard = "NotApplicable";
    PacketageInfo.DoCredit.convenienceFeeAmount = "";
    PacketageInfo.DoCredit.AccountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AccountInformation == "") {
      PacketageInfo.DoCredit.AccountInformation = {};
      PacketageInfo.DoCredit.AccountInformation.Account = "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode = "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate = "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype = "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber = "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo = "";
      PacketageInfo.DoCredit.AccountInformation.CardType = "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder = "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage = "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AccountInformation.Account =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.accountNumber =
      PacketageInfo.DoCredit.AccountInformation.Account;
    PacketageInfo.DoCredit.isCardInserted =
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator;
    PacketageInfo.DoCredit.cardHolderName =
      PacketageInfo.DoCredit.AccountInformation.CardHolder;

    PacketageInfo.DoCredit.TraceInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.TraceInformation == "") {
      PacketageInfo.DoCredit.TraceInformation = {};
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber = "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AVSinformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AVSinformation == "") {
      PacketageInfo.DoCredit.AVSinformation = {};
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.CommercialInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.CommercialInformation == "") {
      PacketageInfo.DoCredit.CommercialInformation = {};
      PacketageInfo.DoCredit.CommercialInformation.PONumber = "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.CommercialInformation.PONumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription =
        response[i][++j] != undefined ? response[i][j] : "";
    }
    console.log(PacketageInfo.DoCredit.CommercialInformation);

    PacketageInfo.DoCredit.motoEcommerce =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.motoEcommerce == "") {
      PacketageInfo.DoCredit.motoEcommerce = {};
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType = "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType = "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber = "";
      PacketageInfo.DoCredit.motoEcommerce.Installments = "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.Installments =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AdditionalInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AdditionalInformation == "")
      PacketageInfo.DoCredit.AdditionalInformation = {};
    var additionalInfoArr = PacketageInfo.DoCredit.AdditionalInformation,
      keyValue = [];
    for (i = 0; i < additionalInfoArr.length; i++) {
      keyValue = additionalInfoArr[i].split("=");
      PacketageInfo.DoCredit.AdditionalInformation[keyValue[0]] = keyValue[1];
      keyValue = [];
    }
    if (
      PacketageInfo.DoCredit.AdditionalInformation.hasOwnProperty("CARDBIN")
    ) {
      PacketageInfo.DoCredit.binValue =
        PacketageInfo.DoCredit.AdditionalInformation.CARDBIN;
    }
    PacketageInfo.DoCredit.cardLogo = "";
    PacketageInfo.DoCredit.currencyCode = "";
    PacketageInfo.DoCredit.entryMode = "";
    PacketageInfo.DoCredit.expirationYear = "";
    PacketageInfo.DoCredit.expirationMonth = "";
    PacketageInfo.DoCredit.paymentType = "";
    PacketageInfo.DoCredit.pinVerified = "";
    PacketageInfo.DoCredit.signature = {
      statusCode: "SignatureNotRequiredByThresholdAmount",
    };
    PacketageInfo.DoCredit.statusCode = PacketageInfo.DoCredit.isApproved
      ? commandName + "Approved"
      : "";
    PacketageInfo.DoCredit.transactionDateTime =
      PacketageInfo.DoCredit.TraceInformation.TimeStamp;
    PacketageInfo.DoCredit.transactionId =
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber;
    PacketageInfo.DoCredit.merchantId = "";

    console.log(`PacketageInfo`, PacketageInfo.DoCredit);
    return PacketageInfo.DoCredit;
  }

  const getPaymentSettings = (params, processtype = "sale", cardsaletype) => {
    console.log(`params`, params);
    console.log(`processtype`, processtype);
    console.log(`cardsaletype`, cardsaletype);
    var version, transactionType; //,accountInformation={},traceInformation,avsInformation,cashierInformation,commercialInformation,motoEcommerce,additionalInformation;
    // if(!ConfigureFlag){
    //  DisplayHandler.RemoveLoading();
    //  var $dialog = $("<div id='dialog' title='Command Dialog'>");
    //  HintDialog.CreateDialog("Configure Data isn't set yet.",$dialog);
    //  return false;
    // }

    var configuration = {
      version: "1.28",
      protocol: "https",
      ip: "127.0.0.1",
      port: "10009",
      promptForSignature: 0,
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
      add: "10",
      activate: "08",
      deactivate: "12",
      balance: "23",
    };

    version = configuration.version;
    transactionType = processTypes[processtype];
    //set transaction Data

    let amountInformation = {};
    amountInformation.TransactionAmount =
      processtype == "void"
        ? ""
        : Math.round(parseFloat(params.transactionAmount) * 100);
    amountInformation.TipAmount = "";
    amountInformation.CashBackAmount = "";
    amountInformation.MerchantFee = "";
    amountInformation.TaxAmount = "";
    amountInformation.FuelAmount = "";
    console.log(amountInformation);

    let accountInformation = {};
    accountInformation.Account = "";
    accountInformation.EXPD = "";
    accountInformation.CVVCode = "";
    accountInformation.EBTtype = cardsaletype == "ebt cash" ? "C" : "";
    accountInformation.VoucherNumber = "";
    accountInformation.Force = "";
    accountInformation.FirstName = "";
    accountInformation.LastName = "";
    accountInformation.CountryCode = "";
    accountInformation.State_ProvinceCode = "";
    accountInformation.CityName = "";
    accountInformation.EmailAddress = "";

    let traceInformation = {};
    traceInformation.ReferenceNumber = params.ticketNumber.substring(0, 15);
    traceInformation.InvoiceNumber = "";
    traceInformation.AuthCode = "";
    traceInformation.TransactionNumber = params.hasOwnProperty("transactionId")
      ? params.transactionId
      : "";
    traceInformation.TimeStamp = "";
    traceInformation.ECRTransID = "";

    let avsInformation = {};
    avsInformation.ZipCode = "";
    avsInformation.Address = "";
    avsInformation.Address2 = "";

    let cashierInformation = {};
    cashierInformation.ClerkID = "";
    cashierInformation.ShiftID = "";

    let commercialInformation = {};
    commercialInformation.PONumber = "";
    commercialInformation.CustomerCode = "";
    commercialInformation.TaxExempt = "";
    commercialInformation.TaxExemptID = "";
    commercialInformation.MerchantTaxID = "";
    commercialInformation.DestinationZipCode = "";
    commercialInformation.ProductDescription = "";

    let motoEcommerce = {};
    motoEcommerce.MOTO_E_CommerceMode = "";
    motoEcommerce.TransactionType = "";
    motoEcommerce.SecureType = "";
    motoEcommerce.OrderNumber = "";
    motoEcommerce.Installments = "";
    motoEcommerce.CurrentInstallment = "";

    let additionalInformation = {};
    additionalInformation.TABLE = "";
    additionalInformation.GUEST = "";
    additionalInformation.SIGN =
      processtype == "sale" ? configuration.promptForSignature : "";
    additionalInformation.TICKET = "";
    additionalInformation.HREF = "";
    additionalInformation.TIPREQ = "";
    additionalInformation.SIGNUPLOAD = "";
    additionalInformation.REPORTSTATUS = "";
    additionalInformation.TOKENREQUEST = "";
    additionalInformation.TOKEN = "";
    additionalInformation.CARDTYPE = "";
    additionalInformation.CARDTYPEBITMAP = "";

    additionalInformation.PASSTHRUDATA = "";
    additionalInformation.RETURNREASON = "";
    additionalInformation.ORIGTRANSDATE = "";
    additionalInformation.ORIGPAN = "";
    additionalInformation.ORIGEXPIRYDATE = "";
    additionalInformation.ORIGTRANSTIME = "";
    additionalInformation.DISPROGPROMPTS = "";
    additionalInformation.GATEWAYID = "";
    additionalInformation.GETSIGN =
      processtype == "sale" ? configuration.promptForSignature : "";

    additionalInformation.ENTRYMODEBITMAP = "";
    additionalInformation.RECEIPTPRINT = "1";
    additionalInformation.CPMODE = "";
    additionalInformation.ODOMETER = "";
    additionalInformation.VEHICLENO = "";
    additionalInformation.JOBNO = "";
    additionalInformation.DRIVERID = "";
    additionalInformation.EMPLOYEENO = "";
    additionalInformation.LICENSENO = "";
    additionalInformation.JOBID = "";
    additionalInformation.DEPARTMENTNO = "";
    additionalInformation.CUSTOMERDATA = "";
    additionalInformation.USERID = "";
    additionalInformation.VEHICLEID = "";
    console.log(amountInformation);

    var command = "T00";
    if (cardsaletype == "gift") {
      command = "T06";
    } else if (cardsaletype == "debit") {
      command = "T02";
    } else if (cardsaletype == "ebt" || cardsaletype == "ebt cash") {
      command = "T04";
    } else if (cardsaletype == "batchclose") {
      command = "B00";
    }

    console.log(`command`, command);

    return DoCredit({
      command: command,
      version: version,
      transactionType: transactionType,
      amountInformation: amountInformation,
      accountInformation: accountInformation,
      traceInformation: traceInformation,
      avsInformation: avsInformation,
      cashierInformation: cashierInformation,
      commercialInformation: commercialInformation,
      motoEcommerce: motoEcommerce,
      additionalInformation: additionalInformation,
    });
  };

  //DO Credit
  const DoCredit = (doCreditInfo) => {
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },

      // mDestinationIP: "http://127.0.0.1:10009", // - OLD "http://192.167.2.100:10009";       //http://112.199.49.146:8181
      mDestinationIP: `${paxConfig.pax_protocol == ""
        ? "http"
        : paxConfig.pax_protocol == "1"
          ? "http"
          : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };

    var params = [
      PAX.mStx.hex,
      doCreditInfo.command,
      PAX.mFS.hex,
      doCreditInfo.version,
    ];
    params.push(PAX.mFS.hex);
    if (doCreditInfo.transactionType != "") {
      params.push(doCreditInfo.transactionType);
    }
    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "amountInformation",
      doCreditInfo.amountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "accountInformation",
      doCreditInfo.accountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "traceInformation",
      doCreditInfo.traceInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "avsInformation", doCreditInfo.avsInformation);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "motoEcommerce", doCreditInfo.motoEcommerce);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    params.push(PAX.mEtx.hex);

    var lrc = getLRC(params);

    console.log(params);

    //prepare for base64 encoding.
    var command_hex = base64ToHex(Base64.btoa(doCreditInfo.command));
    var version_hex = base64ToHex(Base64.btoa(doCreditInfo.version));
    var transactionType_hex = base64ToHex(
      Base64.btoa(doCreditInfo.transactionType)
    );

    //var elements = [this.mStx.code, command_hex, PAX.mFS.code, version_hex, PAX.mFS.code, uploadFlag_hex, PAX.mFS.code, timeout, this.mEtx.code, base64ToHex(Base64.btoa(lrc))];
    var elements = [PAX.mStx.code];
    elements.push(command_hex);
    elements.push(PAX.mFS.code);
    elements.push(version_hex);
    elements.push(PAX.mFS.code);

    if (transactionType_hex != "") {
      elements.push(transactionType_hex);
    }
    elements.push(PAX.mFS.code);

    elements = AddBase64(
      elements,
      "amountInformation",
      doCreditInfo.amountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "accountInformation",
      doCreditInfo.accountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "traceInformation",
      doCreditInfo.traceInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "avsInformation",
      doCreditInfo.avsInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(elements, "motoEcommerce", doCreditInfo.motoEcommerce);
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    elements.push(PAX.mEtx.code);
    elements.push(base64ToHex(Base64.btoa(lrc)));
    console.log("elements");
    console.log(elements);

    var final_string = elements.join(" ");
    var final_b64 = hexToBase64(final_string);
    console.log("LRC: " + lrc);
    console.log("Base64: " + final_b64);

    // if(customData != ''){
    // 	final_b64 = hexToBase64(final_string+"&custom_data=<PAX>"+customData+"</PAX>");
    // }

    var url = PAX.mDestinationIP + "?" + final_b64;
    console.log("URL: " + url);
    return { url: url, timeout: PAX.timeout.DoCredit };
    // this.HttpCommunication('DoCredit',url,function(response){
    // 	callback(response);
    // },PAX.timeout.DoCredit);
  };

  const AddBase64 = (elements, type, objectInfo) => {
    //console.log(objectInfo);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    var empty = 0;
    var arr = [];
    arr = arr.concat(elements);
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.code);
        continue;
      }
      if (type == "additionalInformation") {
        if (objectInfo[name] == "") continue;
        empty++;
        arr.push(
          base64ToHex(Base64.btoa(name + "=" + objectInfo[name].toString()))
        );
      } else {
        empty++;
        arr.push(base64ToHex(Base64.btoa(objectInfo[name].toString())));
      }
      arr.push(PAX.mUS.code);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = elements;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.code);
    }
    //console.log(arr);
    return arr;
  };

  const PushParams = (params, type, objectInfo) => {
    var empty = 0;
    var arr = [];
    arr = arr.concat(params);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.hex);
        continue;
      }

      if (type == "additionalInformation") {
        if (objectInfo[name] == "") {
          continue;
        }
        empty++;
        arr.push(name + "=" + objectInfo[name].toString());
      } else {
        empty++;
        arr.push(objectInfo[name].toString());
      }
      arr.push(PAX.mUS.hex);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = params;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.hex);
    }
    //console.log(params);
    return arr;
  };

  useEffect(() => {
    let elem = document.getElementsByTagName("input");
    if (elem && props?.searchFocus) {
      elem[0].focus();
      props.setSearchFocus(false);
    }
  }, [props]);

  const handleDailogPayClose = () => {
    setShow(false);
    setErrorMsg(null);
    setTitle(null);
  };

  const handleDailogPayItemClose = (e)=>{
    if (e){
      setShow(false);
      setErrorMsg(null);
      setTitle(null);
    }
  }

  const handleBrowseItem = () => {
    alert("Browse item ");
  };

  useEffect(()=>{
    if (props?.accountSetting?.BOTTLEDEPOSIT){
      setDepositReturn(props?.accountSetting?.BOTTLEDEPOSIT)
    }
  }, [props?.accountSetting])
  //   const fontSizeSearch = useSelector((state) => state.setting.fontSizeSearch);

  // useEffect(() => {
  //   let size = fontSizeSearch !== "default" ? fontSizeSearch + "px" : null;

  //   let autoCompleteElem = document.getElementById("changeAutoCompleteFontSize").querySelectorAll("*");
  //   console.log("autocomplete", autoCompleteElem);

  //   for (let i = 0; i < autoCompleteElem.length; i++) {
  //     const element = autoCompleteElem[i];
  //     element.style.fontSize = size;
  //   }
  // }, [fontSizeSearch]);

  // const [posSideSetting, setPosSideSetting] = useState(
  //   JSON.parse(localStorage.getItem("wpos_config"))
  // );
  // useEffect(() => {
  //   window.addEventListener("storage", (event) => {
  //     setPosSideSetting(JSON.parse(localStorage.getItem("wpos_config")) || []);
  //   });
  // }, []);
  // useEffect(() => {
  //   setTimeout(() => {
  //     handleFontSizeChange(posSideSetting?.searchFontSize);
  //   }, [1]);
  // }, [posSideSetting]);

  // // Customer screen font size change
  // const handleFontSizeChange = (selectedOption) => {
  //   alert("asdasd");
  //   const fontSizeSearch = selectedOption ? selectedOption + "px" : null;
  //   // Get all elements in the DOM
  //   const allElements = document
  //     .getElementByClass("react-auto-complete")
  //   const body = document.getElementsByTagName("body")[0];
  //   // Remove the existing font-size class
  //   const fontClasses = body.classList;
  //   for (let i = fontClasses.length - 1; i >= 0; i--) {
  //     if (fontClasses[i].startsWith("font-size-")) {
  //       body.classList.remove(fontClasses[i]);
  //     }
  //   }
  //   // Add the new font-size class if a valid font size is selected
  //   if (fontSizeSearch) {
  //     body.classList.add("font-size-" + fontSizeSearch);
  //   }
  // };

  // Memoize the items list
  const Items = useMemo(() => {
    return props?.itemList?.map((i) => ({
      ...i,
      s_code: i.id + "," + i.name,
    }));
  }, [props?.itemList]);

  // Use a map for faster lookup
  const itemsMap = useMemo(() => {
    const map = new Map();
    Items.forEach((item) => map.set(item?.code[0]?.code, item));
    return map;
  }, [Items]);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value.length === 0) {
        setopen(false);
      } else {
        let finalProduct = itemsMap.get(formatCode(value, UpcTenCode) || value);
        if (finalProduct) {
          setstockSearch(finalProduct.s_code);
          setopen(false);
          return;
        } else {
          if (!isNaN(value) && Number(value) && value.length > 5) {
            setShow(STOCKCODECHECK);
            setBarcode(value);
            setSearchValue("");
            setopen(false);
            dispatch(
              addActionFunction(ITEMNOTFOUND, {
                code: value,
                store: getDeviceAndStore()[1],
                dt: Date.now(),
              })
            );
            return;
          }
        }
        setopen(true);
      }
    }, 300),
    [itemsMap]
  );

  const handleInputChange = (e) => {
    e.persist();
    setSearchValue(e.target.value);
    setKeyPad(true);
    debouncedSearch(e.target.value);
  };

  const handleRetryPopUp = () => {
    handleLoader(true, "Follow Prompts on Terminal");
    giftCardOtherTrans(transactionType);
  };

  return (
    <>
      {loader && (
        <CustomModal
          open={loader}
          dialogClassName={`custom-modal confirmation error-popup`}
          title={loaderType}
          content={<h1 className="mb-3"></h1>}
          action={<LoaderSpinner />}
          removeCloseIcon={`d-none`}
        />
      )}
      {camera === true && <FastPrint />}
      {/* for open recall transaction details */}
      {openTransactionDetails && (
        <SalesTables
          onlyShowTransActionsDetails={true}
          saleData={props?.suspendedSale}
          handleClose={() => setOpenTransactionDetails(false)}
        />
      )}
      <div style={{ display: "none" }}>
        <ComponentToPrint
          ref={componentRef}
          details={props.details}
          expense_type_list={props.expense_type_list}
          expense_category_list={props.expense_category_list}
          expense_vendor_list={props.expense_vendor_list}
          user_list={props.user_list}
          genSetting={props.genSetting}
        />
        <ComponentToRePrint
          ref={componentRefRePrint}
          taxRules={props?.taxRules}
          custMobile={props?.custMobile}
          custlist={props?.custlist}
        />
      </div>
      <Card className="custom-card no-checkbox column-visibility-table">
        <Card.Header className="d-flex justify-content-between align-items-md-center">
          <h2>Stock Information</h2>
          <div className="d-flex align-items-center top-search">
            <div
              id="changeAutoCompleteFontSize"
              className="react-auto-complete navbar-search stock-search  me-3"
            >
              <ReactAutocomplete
                id="stockSearch"
                open={open}
                items={Items}
                shouldItemRender={(item, value) =>
                  item.s_code.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item.name + "," + item.id + ","}
                renderItem={(item, highlighted) => (
                  <div
                    className={
                      highlighted
                        ? "list-group-item focused"
                        : "list-group-item"
                    }
                    key={item.id}
                  >
                    <span>{item?.name}</span>
                    <span>{item?.item_size_name || "-"}</span>
                    <span>
                      {item?.modifier !== undefined
                        ? currencyFormat(item?.modifier[0]?.price)
                        : currencyFormat(0)}
                    </span>
                    <span>{item?.totalitem || 0}</span>
                  </div>
                )}
                value={searchValue}
                inputProps={{ placeholder: "Stock Search" }}
                onChange={handleInputChange}
                onSelect={onSelectItem}
              />
              {/* <FontSizesSearchPos /> */}
            </div>
            {/* </div> */}
            {!hideManualButton ? (
              <Button
                variant="purpule-light add-btn"
                type="button"
                onClick={(e) => AddMoreData(e)}
                title="Manual Item"
              >
                <Image src={props?.dynamicImages?.PlusAdd} alt="Add Icon" />
                <span className="d-none d-md-flex">  Manual Item</span>
              </Button>
            ) : (
              ""
            )}
            <Button variant="purpule-light camera d-flex d-md-none">
              <FontAwesomeIcon
                icon={faCamera}
                className="fa-1x "
                onClick={handleCamera}
              />
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="pb-3">
          <div className="items-contain with-scroll-on-table">
            <ItemsContain
              AddManualItem={manualItem}
              AddDepReturn={isDepReturn}
              depositReturn={depositReturn}
              SetAddManualItem={setManualItem}
              setIsDepReturn={setIsDepReturn}
              SaleItem={saleItemList}
              PosSetting={props?.PosSetting}
              GeneralSetting={props?.GeneralSetting}
              AccountSetting={props?.AccountSetting}
              posSideSetting={props?.posSideSetting}
              keypad={KeyPad}
              focusNo={props?.focusNo}
            />
          </div>
          {/* Total info */}
          <div className="d-flex justify-content-between px-3 mt-3">
            <Button
              type="button"
              title="Total"
              onClick={toggleSlideIn}
              variant="primary d-block d-md-none px-5"
            >
              Total
            </Button>
            <Button
              type="button"
              title="Other"
              onClick={toggleSlideIn1}
              variant="primary d-block d-md-none px-5"
            >
              Other
            </Button>
          </div>
          <div className={`shortcut-overlay ${isSlideInOpen ? 'visible' : ''}`} onClick={closeSlideIn}></div>
          <div className={`shortcut-container bottom ${isSlideInOpen ? 'open' : ''}`}>
            <Button
              type="button"
              onClick={closeSlideIn}
              variant="link d-block d-md-none"
            >
              <Image src={
                !darkMode
                  ? props?.dynamicImages?.crossIcon
                  : props?.dynamicImages?.closeWhite
              }
                alt="cross icon" />
            </Button>
            <TotalInfo
              PosSetting={props?.PosSetting}
              GeneralSetting={props?.GeneralSetting}
              AccountSetting={props?.AccountSetting}
            />
          </div>
          {/* Action buttons */}
          <div className={`shortcut-overlay ${isSlideInOpen1 ? 'visible' : ''}`} onClick={closeSlideIn1}></div>
          <div className={`shortcut-container bottom has-options ${isSlideInOpen1 ? 'open' : ''}`}>
            <Button
              type="button"
              onClick={closeSlideIn1}
              variant="link d-block d-md-none"
            >
              <Image src={
                !darkMode
                  ? props?.dynamicImages?.crossIcon
                  : props?.dynamicImages?.closeWhite
              } alt="cross icon" />
            </Button>
            <Row className="mt-3 cta-btn-wrapper">
              <Col xs="12" lg="9" md="9">
                {suspendButton && (
                  <>
                    <Button
                      type="button"
                      variant="grey-custom-shade"
                      onClick={(e) => handleSales(e, ORDER)}
                    >
                      Suspend
                    </Button>
                    <Button
                      type="button"
                      variant="grey-custom-shade"
                      onClick={(e) => handleSales(e, RECALL)}
                    >
                      Recall
                    </Button>
                  </>
                )}
                {
                  depositReturn?.enable == 1 && (
                    <Button
                      type="button"
                      variant="grey-custom-shade"
                      onClick={(e) => handleDepReturn(e)}
                    >
                      Dep Return
                    </Button>
                  )
                }
                <Button
                  type="button"
                  variant="grey-custom-shade"
                  onClick={(e) => handleSales(e, CANCELED)}
                >
                  Cancel
                </Button>
                {noSaleButton && (
                  <Button
                    type="button"
                    title="No Sale"
                    variant="grey-custom-shade"
                    onClick={(e) => handleSales(e, NOSALE)}
                    className="mt-md-0 mt-lg-0 mt-2 sale-btn"
                  >
                    No Sale
                  </Button>
                )}
                <Button
                  type="button"
                  onClick={(e) => handleButton(e, OTHER)}
                  variant="grey-custom-shade"
                  className="mt-md-0 mt-lg-0 mt-2"
                  title="Open options"
                >
                  <Image src={props?.dynamicImages?.hamburger2} alt="Add Icon" />
                </Button>
                {/* <Button variant="orange">Dep Return</Button> */}
                <Button
                  type="button"
                  className="d-block d-md-none mt-2"
                  // onClick={(e) => handleButton(e, REPRINT)}
                  onClick={(e) => ReprintFn(e)}
                  variant="grey-custom-shade"
                >
                  Reprint
                </Button>
                <Button
                  type="button"
                  className="d-block d-md-none mt-2"
                  onClick={(e) => handleButton(e, PAYOUT)}
                  variant="grey-custom-shade"
                >
                  Payout
                </Button>
              </Col>

              <Col
                className="d-md-flex justify-content-lg-end justify-content-md-end mt-md-0 mt-lg-0 mt-2 d-none"
                xs="12"
                lg="3"
                md="3"
              >
                <Button
                  type="button"
                  // onClick={(e) => handleButton(e, REPRINT)}
                  onClick={(e) => ReprintFn(e)}
                  variant="grey-custom-shade"
                >
                  Reprint
                </Button>
                <Button
                  type="button"
                  onClick={(e) => handleButton(e, PAYOUT)}
                  variant="grey-custom-shade"
                >
                  Payout
                </Button>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
      {/* Add Payout popup */}
      <CustomModal
        open={show === PAYOUT}
        handleClose={() => setShow(null)}
        dialogClassName="custom-modal with-border-bottom"
        title="Add Payout"
        content={<AddPayout print={setShow} printValues={setPrintValues} />}
        action={
          <>
            <Button
              variant="outline-blackcustom px-5"
              onClick={() => setShow(null)}
              title="Cancel"
            >
              Cancel
            </Button>
          </>
        }
      />
      {/* Reprint popup */}
      <CustomModal
        open={show === REPRINT}
        handleClose={() => setShow(null)}
        dialogClassName="custom-modal with-border-bottom"
        title="Checkout Complete"
        content={
          <>
            <h3>Your payout added successfully!</h3>
            <h3>Print Receipt?</h3>
          </>
        }
        action={
          <>
            <Button variant="outline-blackcustom px-5" title="Yes" onClick={printReceipt}>
              Yes
            </Button>
            <Button
              variant="outline-blackcustom px-5"
              onClick={() => setShow(null)}
              title="No"
            >
              No
            </Button>
          </>
        }
      />
      {/* Others popup */}
      <CustomModal
        open={show === OTHER}
        handleClose={() => setShow(null)}
        dialogClassName="custom-modal without-save-btn other-options"
        title="Other Options"
        content={
          <Row>
            <Col md={6} className="mb-3">
              <Button
                style={{ width: "100%" }}
                type="button"
                variant="purpule-light "
                onClick={handleAddBalance}
                title="Add Balance"
              >
                <Image
                  src={props?.dynamicImages?.AddBalance}
                  alt="Add Balance Icon"
                />
                Add Balance
              </Button>
            </Col>
            <Col md={6} className="mb-3">
              <Button
                style={{ width: "100%" }}
                type="button"
                variant="purpule-light"
                onClick={() => giftCardOtherTrans("balance")}
                title="Check Balance"
              >
                <Image
                  src={props?.dynamicImages?.CheckBalance}
                  alt="Check Balance Icon"
                />
                Check Balance
              </Button>
            </Col>
            <Col md={6} className="mb-3 mb-md-0">
              <Button
                style={{ width: "100%" }}
                type="button"
                variant="purpule-light"
                onClick={() => giftCardOtherTrans("activate")}
                title="Activate Card"
              >
                <Image
                  src={props?.dynamicImages?.ActivateCard}
                  alt=" Activate Card Icon"
                />
                Activate Card
              </Button>
            </Col>
            <Col md={6}>
              <Button
                style={{ width: "100%" }}
                type="button"
                variant="purpule-light"
                onClick={() => giftCardOtherTrans("deactivate")}
                title="Deactivate Card Icon"
              >
                <Image
                  src={props?.dynamicImages?.DeactivateCard}
                  alt="  Deactivate Card Icon"
                />
                Deactivate Card
              </Button>
            </Col>
          </Row>
        }
        action={
          <>
            <Button variant="outline px-5 mt-5" title="Cancel" onClick={() => setShow(null)}>
              Cancel
            </Button>
          </>
        }
      />
      {/* Warning Popup*/}
      <CustomModal
        open={openErrorMsg}
        handleClose={handleClosepopUp}
        backdrop={"static"}
        dialogClassName={`custom-modal confirmation error-popup`}
        title={title}
        content={<h1 className="mb-3">{errorMsg}</h1>}
        action={
          <>
            <Button
              type="button"
              variant="primary px-5"
              onClick={handleClosepopUp}
              title="Ok"
            >
              OK
            </Button>
          </>
        }
      />
      {/* Confirm Popup*/}
      <CustomModal
        open={openErrorMsg1}
        handleClose={handleClosepopUp}
        dialogClassName={`custom-modal confirmation error-popup cancel-modal`}
        title={title}
        content={<h1 className="mb-3 justify-content-center">{errorMsg}</h1>}
        action={
          <>
            <Button
              type="button"
              variant="primary px-5"
              onClick={handleRetryPopUp}
              title="Yes"
            >
              Yes
            </Button>
            <Button
              type="button"
              variant="outline-blackcustom px-5"
              onClick={handleClosepopUp}
              title="No"
            >
              No
            </Button>
          </>
        }
      />
      <CustomModal
        open={cancleTransection}
        handleClose={() => setCancleTransection(false)}
        dialogClassName="custom-modal confirmation verify-age"
        title="Confirmation"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.warningRed}
                alt="warning Icon"
              />
              Are you sure want to cancel the transaction?
            </h1>
            <Button
              variant="primary px-3 position-absolute form-cta-btn"
              type="button"
              onClick={(e) => handleCancleSalePayment(e)}
              title="Yes"
            >
              Yes
            </Button>
          </>
        }
        action={
          <Button
            variant="outline-blackcustom px-5 m-auto"
            onClick={() => setCancleTransection(false)}
            title="No"
          >
            No
          </Button>
        }
      />
      {/* ask auth */}
      <CustomModal
        open={auth}
        handleClose={() => setAuth(false)}
        dialogClassName="custom-modal  with-border-bottom authorization"
        title="Authorization"
        content={
          <Auth setAuthSuccess={setAuthSuccess} userlist={authorizedUsers} />
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5"
              onClick={() => setAuth(false)}
              title="Cancel"
            >
              Cancel
            </Button>
          </>
        }
      />
      {/* Item Not Found */}
      <CustomModal
        open={show === STOCKCODECHECK}
        handleClose={handleDailogPayClose}
        dialogClassName="custom-modal confirmation item-not-found with-border-bottom"
        title="Item Not Found"
        content={
          <img
            className="my-3"
            width="80px"
            height="80px"
            src={props?.dynamicImages?.cancel}
            alt="cancel icon"
          />
        }
        action={
          <>
            <Button
              type="button"
              onClick={handleDailogPayClose}
              variant="outline-blackcustom px-5"
              title="Ok"
            >
              OK
            </Button>
            <Button
              id="browseBtn"
              type="button"
              onClick={handleBrowseItem}
              variant="primary px-5"
              title="Browse"
            >
              Browse
            </Button>
            <Button
              type="button"
              onClick={() => {
                if (!checkUserPermission("allow_add_item_hide")) {
                  setAuth(true);
                } else {
                  setShow(ITEM);
                }
              }}
              variant="primary px-5"
              title="Add New Item"
            >
              Add New Item
            </Button>
          </>
        }
      />
      {/* Add or edit Item*/}
      <CustomModal
        open={show === ITEM}
        size="lg"
        handleClose={handleDailogPayItemClose}
        dialogClassName="custom-modal modal-60w item-popup"
        title="Add Item"
        content={
          <AddItems
            stockCode={barcode}
            closeEditModel={handleDailogPayItemClose}
            AccountSetting={props?.accountSetting}
            GeneralSetting={props?.genSetting}
          />
        }
        action={
          <Button variant="light px-5" title="Cancel" onClick={handleDailogPayItemClose}>
            Cancel
          </Button>
        }
      />
    </>
  );
};

StockInformation.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      pax_ip: PropTypes.string,
      pax_protocol: PropTypes.string,
    })
  ),
  salesitemList: PropTypes.array,
  additionalFee: PropTypes.array,
  taxList: PropTypes.array,
  taxRules: PropTypes.array,
  custMobile: PropTypes.string,
  promotionData: PropTypes.object,
  suspendedSale: PropTypes.object,
  expense_type_list: PropTypes.array,
  dynamicImages: PropTypes.shape({
    origimage1: PropTypes.string,
    PlusAdd: PropTypes.string,
    hamburger2: PropTypes.string,
    AddBalance: PropTypes.string,
    CheckBalance: PropTypes.string,
    ActivateCard: PropTypes.string,
    DeactivateCard: PropTypes.string,
    warningRed: PropTypes.string,
    cancel: PropTypes.string,
  }),
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    salesitemList: state.saleItem.saleItemList,
    suspendedSale: state.saleItem.suspendedSale,
    totalAmounts: state.saleItem.totalAmount,
    payment: state.saleItem.payment,
    additionalFee: state.inventory.additionalFee,
    taxList: state.inventory.tax,
    userlist: state.inventory.userList,
    taxRules: state.inventory.taxRule,
    bottleDeposit: state.inventory.bottledeposit,
    posSetting: state.inventory.posSetting.data,
    accountSetting: state.inventory.accountSetting.data,
    genSetting: state.inventory.generalSetting.data,
    success: state.inventory.success,
    expense_type_list: state.inventory.expenseTypeList,
    expense_category_list: state.inventory.expenseCategoryList,
    expense_vendor_list: state.inventory.expenseVendorList,
    user_list: state.inventory.userList,
    image: state.setting.images,
    custlist: state.inventory.customerList,
    saleList: state.saleItem.saleList,
    storeId: state.auth.storeId,
    devices: state.inventory.devices,
    promotionData: state.saleItem.promotionData,
    completeSaleItemList: state.saleItem.completeSaleItemList,
    darkMode: state.setting.darkmode,
  };
};
export default connect(mapStateToprops)(StockInformation);
