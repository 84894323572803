import { AUTHORDER, CANCEL, CREATEORDER, GENERALSETIINGDATA, HARDWARECANCEL, PAYFACREFUND, VERIFYSTATUS } from "app/helpers/constant"
import api from "../../axios/axios.js"
import { getSettingData } from "axios/authHeader.js"

export const payFac = (type, formData) => {
    formData = {...formData , url : getSettingData(GENERALSETIINGDATA)?.CREDIT?.payfac_api_url}
    switch (type) {
        case CREATEORDER:
            return api.post("api/payment/payfac/paymentCreate/", formData)
        case AUTHORDER:
            return api.post("api/payment/payfac/paymentAuth/", formData)
        case VERIFYSTATUS:
            return api.post("api/payment/payfac/getpaymentStatus/",formData)
        case CANCEL:
            return api.post("api/payment/payfac/paymentCancel/", formData)
        case HARDWARECANCEL:
            return api.post("api/payment/payfac/terminalCancel/", formData)
        case PAYFACREFUND:
            return api.post("api/payment/payfac/paymentRefund/", formData)
    }
}