import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Image,
  ListGroup,
  Nav,
  Row,
  Tab,
} from "@themesberg/react-bootstrap";
import ReactSelect, { components } from "react-select";
import FormField from "../components/formField";
import {
  getDeviceAndStoreName,
  getDeviceAndStore,
  DeviceUUId,
  getSettingData,
  checkOnlineStatus,
  authUserId,
  isUserAdminstrator,
} from "../../axios/authHeader";
import CustomModal from "../../pages/components/Modals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import {
  base64ToHex,
  FormatImageBase64Code,
  getSoundEffect,
  hexToBase64,
  HexToString,
  isWeb,
  range,
  StringToHex,
} from "../../app/helpers/custom";
import { connect, useDispatch } from "react-redux";
import {
  addActionData,
  expendUiDataReducer,
} from "../../app/actions/saleItemAction";
import {
  getListActionFunction,
  loadingAction,
  refreshServerData,
} from "../../app/actions/getListAction";
import {
  POSSIDEETTINGS,
  EXPENDUI,
  RESETLOCALCONFIGDATA,
  CLEARLOCALCONFIGDATA,
  REFRESHREMOTEDATA,
  REMOVEDEVICEREGISTRATION,
  RETRYFAILDTRANSTION,
  RESETPAXMACHINE,
  CLOSEPAXMACHINE,
  CHECKFORUPDATES,
  CLOSEBATCH,
  LOADING,
  LABELTEMPLATE_GET_LIST,
} from "../../app/helpers/constant";
import LoaderSpinner from "../../components/Loader";
import { Howl, Howler } from "howler";
import getListService from "../../app/services/getListService";
import { toast } from "react-toastify";
import {
  loadingToggleAction,
  logoutAction,
} from "../../app/actions/authAction";
import { Link } from "react-router-dom";
import ExpanIcon from "../../assets/images/expand.svg";
import general from "../../assets/images/general.svg";
import printing from "../../assets/images/printing.svg";
import actions from "../../assets/images/actions.svg";
import { useReactToPrint } from "react-to-print";
import { Base64 } from "js-base64";
import $ from "jquery";
import ToggleDark from "../../components/toggleDark";
import Localstorage from "../../app/storage/Localstorage";
import { ThemeContext, themes } from "../../context/ThemeContext";
import { CHANGE_MODE } from "../../app/helpers/constant";
import { changeMode } from "../../app/actions/getListAction";
import PrintLabel from "../printLabel";
import { CommonPusherComponent } from "../components/commonPusherConponent";
import FontSizesPos from "./font-sizes-pos";
import SearchFontSize from "./search-font-size";
import Cancel from "../../assets/images/cancel.png";

const Settings = (props) => {
  const [darkMode, setDarkMode] = React.useState(
    Localstorage.getItem("dark_mode") || false
  );
  const [counter, setCounter] = useState(0);
  const [showIcon, setShowIcon] = useState(false);
  const soundsList = [
    { value: 0, label: "None" },
    { value: 1, label: "Modern Bell 1" },
    { value: 2, label: "Modern Bell 2" },
    { value: 3, label: "Modern Bell 3" },
    { value: 4, label: "Modern Bell 4" },
    { value: 5, label: "Modern Bell 5" },
  ];
  const askToprintOption = [
    { value: "ask", label: "Always Ask" },
    { value: "email", label: "Ask if not emailed" },
    { value: "print", label: "Always Print" },
  ];

  const connMethod = [
    { value: "bp", label: "Browser Printing" },
    { value: "wp", label: "Web Print ESCP" },
    { value: "ep", label: "Electron Print" },
  ];

  const labelPrintSetting = [
    { value: "bp", label: "Browser Printing" },
    { value: "wp", label: "Web Print ESCP" },
    { value: "dymo", label: "Dymo" },
    { value: "brother", label: "Brother" },
    { value: "remote", label: "Remote" },
  ];

  const web_Template = [{ value: 1, label: "Web Template" }];

  const dymo_Template = [{ value: 2, label: "Dymo Template" }];

  const connType = [
    { value: "serial", label: "Serial" },
    { value: "raw", label: "Raw" },
    { value: "tcp", label: "Raw TCP" },
  ];

  const connPort = [];

  const ESCPReciptMethod = [
    { value: "text", label: "Text" },
    { value: "bitmap", label: "Bitmap (Unicode support)" },
  ];
  const Receipt_Template = [
    { value: 1, label: "Use Global Setting" },
    { value: 2, label: "Default Receipt" },
    { value: 3, label: "Mixed Language" },
    { value: 4, label: "Alternate Language" },
  ];

  const reportType = [
    { value: "epson", label: "EPSON" },
    { value: "star", label: "Star" },
  ];

  const reportOtherType = [
    { value: "text", label: "TEXT" },
    { value: "row", label: "RAW" },
  ];

  const Invoice_Template = [
    ...Receipt_Template,
    { value: 5, label: "Default Label" },
    { value: 6, label: "Multiple Invoice" },
    { value: 7, label: "Multiple Invoice 1" },
    { value: 8, label: "Task List Notification" },
    { value: 9, label: "Weborder Status" },
  ];
  const Label_Print_Method = [
    { value: "DYMO", label: "DYMO" },
    { value: "Brother", label: "Brother" },
    { value: "Remote", label: "Remote" },
  ];

  const Pole_Display = [
    { value: "br", label: "Browse" },
    { value: "ep", label: "Electron Pole" },
  ];

  const Langauge = [
    { value: "primary", label: "Primary" },
    { value: "mixed", label: "Mixed" },
    { value: "alternate", label: "Alternate" },
  ];

  const Orientation = [
    { value: "ltr", label: "Left to Right" },
    { value: "rtl", label: "Right to Left" },
  ];

  const AlternateCharset_Codepage = [
    { value: "pc864", label: "PC864" },
    { value: "pc1256", label: "PC1256" },
  ];

  const connBaud = [
    { value: 4800, label: 4800 },
    { value: 9600, label: 9600 },
    { value: 19200, label: 19200 },
    { value: 38400, label: 38400 },
    { value: 57600, label: 57600 },
    { value: 115200, label: 115200 },
  ];
  const DataBits = [
    { value: 7, label: 7 },
    { value: 8, label: 8 },
  ];
  const StopBits = [
    { value: 1, label: 1 },
    { value: 0, label: 0 },
  ];
  const Parity = [
    { value: "none", label: "None" },
    { value: "even", label: "Even" },
    { value: "odd", label: "Odd" },
  ];
  const FlowControl = [
    { value: "none", label: "None" },
    { value: "xonxoff", label: "XON/XOFF" },
    { value: "rtscts", label: "RTS/CTS" },
  ];
  const cutterCommand = [
    { value: "none", label: "None" },
    { value: "gs_full", label: "GS full cut" },
    { value: "gs_partial", label: "GS partial cut" },
    { value: "esc_full", label: "ESC full cut" },
    { value: "esc_partial", label: "ESC partial cut" },
  ];

  const NoneValue = { value: 0, label: "Default" };
  const FontSize = range(10, 50);
  const POSFontSize = range(12, 18);
  const CustomerFontSize = range(25, 50);
  const CustomerScreenZoom = range(50, 200);
  const ReciptOfPrint = range(1, 10);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [labelTemplates, setLabelTemplates] = useState([]);

  const dispatch = useDispatch();

  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const [updateData, setUpdateData] = useState(false);
  const [openErrorMsg, setOpenErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [status, setStatus] = useState(1);
  const [title, setTitle] = useState("");
  const [formData, setformData] = useState(InitalData());
  const [printers, setPrinters] = useState([]);
  const [logoImgData, setLogoImgData] = useState("");
  const [paxMsg, setPaxMsg] = useState(false);

  // pax config
  const [paxConfig, setPaxConfig] = useState({
    pax_ip: "",
    pax_port: "",
    pax_protocol: "",
  });

  useEffect(() => {
    console.log("printers are", printers);
  }, [printers]);

  useEffect(() => {
    const setupData = JSON.parse(localStorage.getItem("setupData"));
    if (props?.devices.length > 0 && setupData != undefined) {
      const paxDetails = props.devices.filter(
        (item) => item.id === setupData.device
      );
      if (
        paxDetails != undefined &&
        (paxDetails != "") & (paxDetails != null)
      ) {
        setPaxConfig({
          pax_ip: paxDetails[0].pax_ip,
          pax_port: paxDetails[0].pax_port,
          pax_protocol: paxDetails[0].pax_protocol,
        });
      }
    }
  }, [props.devices]);

  useEffect(() => {
    if (props?.labeltemplates?.length > 0) {
      setLabelTemplates(props?.labeltemplates);
    }
  }, [props?.labeltemplates]);

  function InitalData() {
    const Data = {
      sound: 0,
      websound: 0,
      searchFontSize: 0,
      posScreenFont: 0,
      customerFont: 0,
      customerScreenZoom: 0,
      screenKeypad: true,
      fixedKeypad: false,
      autolanuchCustomeScreen: false,
      showTotalCusScreen: false,
      showPriceCusScreen: false,
      email_notesInput: false,
      alwayShowShortcut: false,
      enableWebstoreNoti: false,
      asktoPrint: "ask",
      autoHideReciptDialog: "",
      reciptOfPrint: 1,
      baud: 4800,
      connectMethod: 1,
      connectType: 1,
      connectPort: "",
      data_bits: 7,
      stop_bits: 1,
      parity: "none",
      flow_control: "",
      printerPort: "",
      printerIp: "",
      printer: "",
      label_connectPort: "",
      label_data_bits: 7,
      label_stop_bits: 1,
      label_parity: "none",
      label_flow_control: "",
      label_printerPort: "",
      label_printerIp: "",
      label_printer: "",
      cash_draw_connected: false,
      label_printing_enabled: false,
      cutter_commond: "none",
      pageFeed: "",
      escp_receiptMode: "text",
      receiptTemplate: "",
      langauge: "primary",
      orientation: "ltr",
      alter_char_code: "pc864",
      alter_char_code_number: "",
      sheet_width: "",
      currency_codepage: "",
      currency_codes: "",
      override_currency_character: "",
      reportMethod: "bp",
      report_printer: "",
      electron_printType1: "",
      electron_printType2: "",
      reportType1: "",
      reportType2: "",
      label_print_setting: "",
      template: "",
      label_print_type: "",
      pole_display: "",
      pole_printer: "",
      characterPerLine: 20,
      noLinePole: 2,
      message: "Welcome",
      ipAddress: "",
      web_android_port: "",
      remote_printing_enabled: false,
    };
    return Data;
  }
  useEffect(() => {
    if (updateData) {
      dispatch(addActionData(POSSIDEETTINGS, formData, POSSIDEETTINGS));
      localStorage.setItem("wpos_config", JSON.stringify(formData));
      setUpdateData(false);
    }
  }, [formData]);

  useEffect(() => {
    getTemplateList();
  }, []);

  const getTemplateList = () => {
    try {
      const store = getDeviceAndStore();
      dispatch(
        getListActionFunction(
          LABELTEMPLATE_GET_LIST,
          store[1],
          LABELTEMPLATE_GET_LIST
        )
      );
    } catch (error) { }
  };

  useEffect(() => {
    if (getSettingData("wpos_config") !== false) {
      setformData(getSettingData("wpos_config"));
    }
    // Change global volume.
    Howler.volume(1);
  }, []);

  const handleSelect = (e, formField) => {
    // if(formfield)
    if (formField == "reportMethod") {
      if (e.value == "ep" && isWeb()) {
        ShowMsgInfo(
          true,
          "Electron print support only in electron application.",
          "Alert!",
          2
        );
      }
    }
    setUpdateData(true);
    if (formField === "websound" || formField === "sound") {
      // Setup the new Howl.
      let sounding = new Howl({
        src: [getSoundEffect(e.value)],
      });
      sounding.play();
    }
    if (formField === "label_print_setting") {
      setformData({ ...formData, [formField]: e.value, label_printer: "" });
      return;
    }
    setformData({ ...formData, [formField]: e.value });
  };
  const handleChange = (e) => {
    setUpdateData(true);
    setformData({ ...formData, [e.target.name]: e.target.checked });
  };

  const handleInputChange = (e) => {
    setUpdateData(true);
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClickEvent = (e, type, msg) => {
    e.preventDefault();
    if (
      type === RESETLOCALCONFIGDATA ||
      type === CLEARLOCALCONFIGDATA ||
      type === REMOVEDEVICEREGISTRATION
    ) {
      if (authUserId(true)) {
        ShowMsgInfo(true, type, msg, 1);
      } else {
        ShowMsgInfo(
          true,
          "Please login as an administrator to use this feature.",
          "Alert!",
          0
        );
      }
    } else if (type === REFRESHREMOTEDATA) {
      ShowMsgInfo(true, type, msg, 1);
    } else if (type === RESETPAXMACHINE) {
      handleLoader(true, RESETPAXMACHINE);
      RestartPax();
    } else if (type === CLOSEPAXMACHINE) {
      handleLoader(true, CLOSEPAXMACHINE);
      setTimeout(
        function () {
          closeBatch();
        },
        [3000]
      );
    }
  };

  const handleLoader = (value, type) => {
    setLoaderType(type);
    setLoader(value);
  };

  const closeBatch = () => {
    let parms = {};
    var settings = getPaymentObject("Pax", parms, "sale", null, "batchclose");
    console.log(`settings`, settings);
    $.ajax(settings)
      .done(function (res) {
        console.log(`res`, res);
        let response = handlePaymentResponse("Pax", res);
        setLoader(false);
        console.log("response->", response);
        if (response.isApproved == true) {
          console.log("approved");
          ShowMsgInfo(true, "Batch Closed", "Batch", 2);
        } else if (response.isApproved == false) {
          ShowMsgInfo(true, CLOSEBATCH, "Error Closing Batch!", 0);
        }
      })
      .fail(function (jqXHR, status, error) {
        console.log(`jqXHR`, jqXHR);
        var errormessage;
        if (jqXHR.status === 0) {
          errormessage = "Could not connect.\n Verify your network.";
          setShowIcon(true);
        } else if (jqXHR.status == 404) {
          errormessage = "Requested page not found. [404]";
          setShowIcon(false);
        } else if (jqXHR.status == 500) {
          errormessage = "Internal Server Error [500].";
          setShowIcon(false);
        } else {
          errormessage = "Uncaught Error.\n" + jqXHR.responseText;
          setShowIcon(false);
        }
        setLoader(false);
        if (window.navigator.onLine == false) {
          errormessage = "Could not connect.\n Verify your network.";
          setShowIcon(true);
          ShowMsgInfo(true, CLOSEBATCH, errormessage, 0);
        } else {
          setTitle("Error");
          setErrorMsg(
            <p>
              {errormessage} <br />
              <p className="text-center">
                {"Retry Closing Batch?"}
              </p>
            </p>);
          // setShowIcon(false);
          // setErrorMsg("Please Manually Power Cycle the Card machine and Relogin");
          setPaxMsg(true);
          console.log(`errormessage`, errormessage);
        }
      });
  };

  const getPaymentObject = (
    type,
    parms,
    processtype = "sale",
    url = null,
    cardsaletype
  ) => {
    if (type == "Pax") {
      var settings = getPaymentSettings(parms, processtype, cardsaletype);
    }
    return settings;
  };

  const getPaymentSettings = (params, processtype = "sale", cardsaletype) => {
    var version, transactionType; //,accountInformation={},traceInformation,avsInformation,cashierInformation,commercialInformation,motoEcommerce,additionalInformation;

    var configuration = {
      version: "1.28",
      protocol: "https",
      ip: "127.0.0.1",
      port: "10009",
      promptForSignature: 0,
    };
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
    };

    version = configuration.version;
    transactionType = processTypes[processtype];
    var timestamp = Date.now();
    //set transaction Data

    let amountInformation = {};
    amountInformation.TransactionAmount =
      processtype == "void"
        ? ""
        : Math.round(parseFloat(params.transactionAmount) * 100);
    amountInformation.TipAmount = "";
    amountInformation.CashBackAmount = "";
    amountInformation.MerchantFee = "";
    amountInformation.TaxAmount = "";
    amountInformation.FuelAmount = "";
    console.log(amountInformation);

    let accountInformation = {};
    accountInformation.Account = "";
    accountInformation.EXPD = "";
    accountInformation.CVVCode = "";
    accountInformation.EBTtype = cardsaletype == "ebt cash" ? "C" : "";
    accountInformation.VoucherNumber = "";
    accountInformation.Force = "";
    accountInformation.FirstName = "";
    accountInformation.LastName = "";
    accountInformation.CountryCode = "";
    accountInformation.State_ProvinceCode = "";
    accountInformation.CityName = "";
    accountInformation.EmailAddress = "";

    let traceInformation = {};
    traceInformation.ReferenceNumber =
      params.hasOwnProperty("ticketNumber") && params.ticketNumber
        ? params.ticketNumber.substring(0, 15)
        : "";
    traceInformation.InvoiceNumber = "";
    traceInformation.AuthCode = "";
    traceInformation.TransactionNumber = params.hasOwnProperty("transactionId")
      ? params.transactionId
      : "";

    traceInformation.TimeStamp = "";
    traceInformation.ECRTransID = "";

    let avsInformation = {};
    avsInformation.ZipCode = "";
    avsInformation.Address = "";
    avsInformation.Address2 = "";

    let cashierInformation = {};
    cashierInformation.ClerkID = "";
    cashierInformation.ShiftID = "";

    let commercialInformation = {};
    commercialInformation.PONumber = "";
    commercialInformation.CustomerCode = "";
    commercialInformation.TaxExempt = "";
    commercialInformation.TaxExemptID = "";
    commercialInformation.MerchantTaxID = "";
    commercialInformation.DestinationZipCode = "";
    commercialInformation.ProductDescription = "";

    let motoEcommerce = {};
    motoEcommerce.MOTO_E_CommerceMode = "";
    motoEcommerce.TransactionType = "";
    motoEcommerce.SecureType = "";
    motoEcommerce.OrderNumber = "";
    motoEcommerce.Installments = "";
    motoEcommerce.CurrentInstallment = "";

    let additionalInformation = {};
    additionalInformation.TABLE = "";
    additionalInformation.GUEST = "";
    additionalInformation.SIGN =
      processtype == "sale" ? configuration.promptForSignature : "";
    additionalInformation.TICKET = "";
    additionalInformation.HREF = "";
    additionalInformation.TIPREQ = "";
    additionalInformation.SIGNUPLOAD = "";
    additionalInformation.REPORTSTATUS = "";
    additionalInformation.TOKENREQUEST = "";
    additionalInformation.TOKEN = "";
    additionalInformation.CARDTYPE = "";
    additionalInformation.CARDTYPEBITMAP = "";

    additionalInformation.PASSTHRUDATA = "";
    additionalInformation.RETURNREASON = "";
    additionalInformation.ORIGTRANSDATE = "";
    additionalInformation.ORIGPAN = "";
    additionalInformation.ORIGEXPIRYDATE = "";
    additionalInformation.ORIGTRANSTIME = "";
    additionalInformation.DISPROGPROMPTS = "";
    additionalInformation.GATEWAYID = "";
    additionalInformation.GETSIGN =
      processtype == "sale" ? configuration.promptForSignature : "";

    additionalInformation.ENTRYMODEBITMAP = "";
    additionalInformation.RECEIPTPRINT = "1";
    additionalInformation.CPMODE = "";
    additionalInformation.ODOMETER = "";
    additionalInformation.VEHICLENO = "";
    additionalInformation.JOBNO = "";
    additionalInformation.DRIVERID = "";
    additionalInformation.EMPLOYEENO = "";
    additionalInformation.LICENSENO = "";
    additionalInformation.JOBID = "";
    additionalInformation.DEPARTMENTNO = "";
    additionalInformation.CUSTOMERDATA = "";
    additionalInformation.USERID = "";
    additionalInformation.VEHICLEID = "";
    console.log(amountInformation);

    var command = "T00";
    if (cardsaletype == "gift") {
      command = "T06";
    } else if (cardsaletype == "debit") {
      command = "T02";
    } else if (cardsaletype == "ebt" || cardsaletype == "ebt cash") {
      command = "T04";
    } else if (cardsaletype == "batchclose") {
      command = "B00";
    }

    console.log(`command`, command);

    return DoCredit({
      command: command,
      version: version,
      transactionType: transactionType,
      amountInformation: amountInformation,
      accountInformation: accountInformation,
      traceInformation: traceInformation,
      avsInformation: avsInformation,
      cashierInformation: cashierInformation,
      commercialInformation: commercialInformation,
      motoEcommerce: motoEcommerce,
      additionalInformation: additionalInformation,
    });
  };

  //DO Credit
  const DoCredit = (doCreditInfo) => {
    var amountInformation,
      accountInformation,
      traceInformation,
      avsInformation,
      cashierInformation,
      commercialInformation,
      motoEcommerce,
      additionalInformation;
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      // mDestinationIP: "http://127.0.0.1:10009", // - OLD "http://192.167.2.100:10009";       //http://112.199.49.146:8181
      mDestinationIP: `${paxConfig.pax_protocol == ""
          ? "https"
          : paxConfig.pax_protocol == "1"
            ? "http"
            : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };

    var params = [
      PAX.mStx.hex,
      doCreditInfo.command,
      PAX.mFS.hex,
      doCreditInfo.version,
    ];
    params.push(PAX.mFS.hex);
    if (doCreditInfo.transactionType != "") {
      params.push(doCreditInfo.transactionType);
    }
    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "amountInformation",
      doCreditInfo.amountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "accountInformation",
      doCreditInfo.accountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "traceInformation",
      doCreditInfo.traceInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "avsInformation", doCreditInfo.avsInformation);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "motoEcommerce", doCreditInfo.motoEcommerce);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    params.push(PAX.mEtx.hex);

    var lrc = getLRC(params);

    console.log(params);

    //prepare for base64 encoding.
    var command_hex = base64ToHex(Base64.btoa(doCreditInfo.command));
    var version_hex = base64ToHex(Base64.btoa(doCreditInfo.version));
    var transactionType_hex = base64ToHex(
      Base64.btoa(doCreditInfo.transactionType)
    );
    var amountInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.amountInformation)
    );
    var accountInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.accountInformation)
    );
    var traceInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.traceInformation)
    );
    var avsInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.avsInformation)
    );
    var cashierInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.cashierInformation)
    );
    var commercialInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.commercialInformation)
    );
    var motoEcommerce_hex = base64ToHex(
      Base64.btoa(doCreditInfo.motoEcommerce)
    );
    var additionalInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.additionalInformation)
    );

    //var elements = [this.mStx.code, command_hex, PAX.mFS.code, version_hex, PAX.mFS.code, uploadFlag_hex, PAX.mFS.code, timeout, this.mEtx.code, base64ToHex(Base64.btoa(lrc))];
    var elements = [PAX.mStx.code];
    elements.push(command_hex);
    elements.push(PAX.mFS.code);
    elements.push(version_hex);
    elements.push(PAX.mFS.code);

    if (transactionType_hex != "") {
      elements.push(transactionType_hex);
    }
    elements.push(PAX.mFS.code);

    elements = AddBase64(
      elements,
      "amountInformation",
      doCreditInfo.amountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "accountInformation",
      doCreditInfo.accountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "traceInformation",
      doCreditInfo.traceInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "avsInformation",
      doCreditInfo.avsInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(elements, "motoEcommerce", doCreditInfo.motoEcommerce);
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    elements.push(PAX.mEtx.code);
    elements.push(base64ToHex(Base64.btoa(lrc)));
    console.log("elements");
    console.log(elements);

    var final_string = elements.join(" ");
    var final_b64 = hexToBase64(final_string);
    console.log("LRC: " + lrc);
    console.log("Base64: " + final_b64);

    var url = PAX.mDestinationIP + "?" + final_b64;
    console.log("URL: " + url);
    return { url: url, timeout: PAX.timeout.DoCredit };
  };

  const AddBase64 = (elements, type, objectInfo) => {
    //console.log(objectInfo);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    var empty = 0;
    var arr = [];
    arr = arr.concat(elements);
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.code);
        continue;
      }
      if (type == "additionalInformation") {
        if (objectInfo[name] == "") continue;
        empty++;
        arr.push(
          base64ToHex(Base64.btoa(name + "=" + objectInfo[name].toString()))
        );
      } else {
        empty++;
        arr.push(base64ToHex(Base64.btoa(objectInfo[name].toString())));
      }
      arr.push(PAX.mUS.code);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = elements;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.code);
    }
    //console.log(arr);
    return arr;
  };

  const PushParams = (params, type, objectInfo) => {
    var empty = 0;
    var arr = [];
    arr = arr.concat(params);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.hex);
        continue;
      }

      if (type == "additionalInformation") {
        if (objectInfo[name] == "") {
          continue;
        }
        empty++;
        arr.push(name + "=" + objectInfo[name].toString());
      } else {
        empty++;
        arr.push(objectInfo[name].toString());
      }
      arr.push(PAX.mUS.hex);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = params;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.hex);
    }
    //console.log(params);
    return arr;
  };

  const handlePaymentResponse = (type, response) => {
    if (type == "Tripos" || type == "TriposDirect") {
      return response;
    } else if (type == "Pax") {
      return parseResponse(response);
    }
  };

  function parseResponse(response) {
    var commandType = "DoCredit";
    var checkParams = StringToHex(response).split(" ").pop();
    var RedundancyCheck = StringToHex(response).split(" ").pop().substring(1);

    var check = getLRC(checkParams);

    if (check == RedundancyCheck) {
      //get package detail info
      var packetInfo = [];
      var len = StringToHex(response).indexOf("03");
      var hex = StringToHex(response).slice(0, len).split(/02|1c/);

      console.log(hex);
      if (commandType == "DoCredit") {
        var subHex = [],
          subPacketInfo = [];
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            if (hex[i].indexOf("1f") > 0) {
              subHex = hex[i].split("1f");
              console.log(subHex);
              subPacketInfo = [];
              for (var j = 0; j < subHex.length; j++) {
                if (subHex[j] != "") {
                  subPacketInfo.push(HexToString(subHex[j]));
                }
              }
              console.log(subPacketInfo);
              packetInfo.push(subPacketInfo);
            } else {
              packetInfo[i] = HexToString(hex[i]);
            }
          }
        }
      } else {
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            packetInfo[i] = HexToString(hex[i]);
          }
        }
      }
      response = packetInfo;
    }
    if (typeof response == "string") {
      // WPOS.util.okDialog("Error", response, function (dat) { });
      console.log(`responsee`, response);
      return true;
    }

    console.log(`responsee`, response);

    var i = 0,
      j = -1;
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
      LocalTotalReport: {},
      BatchHistory: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
      add: "10",
      activate: "08",
      deactivate: "12",
      balance: "23",
    };
    PacketageInfo.DoCredit.Status = response[++i];
    PacketageInfo.DoCredit.Command = response[++i];
    PacketageInfo.DoCredit.Version = response[++i];
    PacketageInfo.DoCredit.ResponseCode = response[++i];
    PacketageInfo.DoCredit.ResponseMessage = response[++i];
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.Command) {
          commandName = key;
        }
      }
    }
    if (PacketageInfo.DoCredit.ResponseCode == "000000") {
      PacketageInfo.DoCredit.isApproved = true;
    } else {
      PacketageInfo.DoCredit.isApproved = false;
    }

    console.log(`packetInfo->`, PacketageInfo);

    PacketageInfo.DoCredit.HostInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.HostInformation == "") {
      PacketageInfo.DoCredit.HostInformation = {};
      PacketageInfo.DoCredit.HostInformation.HostResponseCode = "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage = "";
      PacketageInfo.DoCredit.HostInformation.AuthCode = "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber = "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber = "";
    } else {
      PacketageInfo.DoCredit.HostInformation.HostResponseCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.AuthCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit._processor = {
      processorLogs: [
        "ExpressResponseCode: [0]\r\nExpressResponseMessage: [" +
          PacketageInfo.DoCredit.isApproved
          ? commandName + "Approved"
          : "" +
          "]\r\nHostResponseCode: [" +
          PacketageInfo.DoCredit.HostInformation.HostResponseCode +
          "]\r\nHostResponseMessage: [ " +
          PacketageInfo.DoCredit.HostInformation.HostResponseMessage +
          "]",
      ],
      processorRawResponse: "",
      processorReferenceNumber:
        PacketageInfo.DoCredit.HostInformation.HostReferenceNumber,
      processorRequestFailed: PacketageInfo.DoCredit.isApproved,
      processorRequestWasApproved: PacketageInfo.DoCredit.isApproved,
      processorResponseCode: PacketageInfo.DoCredit.isApproved
        ? commandName + "Approved"
        : "",
      processorResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      expressResponseCode:
        PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      expressResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      hostResponseCode: PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      hostResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      logs: ["ExpressResponseCode: ''"],
      rawResponse: "",
    };

    PacketageInfo.DoCredit.TransactionType =
      response[++i] != undefined ? response[i] : "";

    PacketageInfo.DoCredit.commandName = "";
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.TransactionType) {
          commandName = key.charAt(0).toUpperCase() + key.slice(1) + " ";
          PacketageInfo.DoCredit.commandName = commandName;
        }
      }
    }

    PacketageInfo.DoCredit.AmountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AmountInformation == "") {
      PacketageInfo.DoCredit.AmountInformation = {};
      PacketageInfo.DoCredit.approvedAmount = "";
      PacketageInfo.DoCredit.subTotalAmount = "0";
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount = "";
      PacketageInfo.DoCredit.AmountInformation.AmountDue = "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount = "";
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount = "";
      PacketageInfo.DoCredit.cashbackAmount =
        PacketageInfo.DoCredit.AmountInformation.CashBackAmount;
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = "";
      PacketageInfo.DoCredit.debitSurchargeAmount = "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount = "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 = "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 = "";
    } else {
      j = -1;
      if (
        PacketageInfo.DoCredit &&
        PacketageInfo.DoCredit.AmountInformation &&
        PacketageInfo.DoCredit.AmountInformation.hasOwnProperty("ApproveAmount")
      ) {
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.approvedAmount = parseFloat(
          PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
        );
        PacketageInfo.DoCredit.subTotalAmount = parseFloat(
          PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
        );

        PacketageInfo.DoCredit.AmountInformation.AmountDue =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.TipAmount =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.tipAmount =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.CashBackAmount =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.TaxAmount =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.Balance1 =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AmountInformation.Balance2 =
          response[i][++j] != undefined ? response[i][j] : "";
      }
    }

    // add params balance 1 and balance 2 for ebt
    if (["T05", "T07", "T09"].includes(PacketageInfo.DoCredit.Command)) {
      PacketageInfo.DoCredit.Balance1 =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance1) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance1 / 100)
          : PacketageInfo.DoCredit.AmountInformation.Balance1;
      PacketageInfo.DoCredit.Balance2 =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance2) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance2 / 100)
          : PacketageInfo.DoCredit.AmountInformation.Balance2;
    } else {
      PacketageInfo.DoCredit.Balance1 = 0;
      PacketageInfo.DoCredit.Balance2 = 0;
    }

    PacketageInfo.DoCredit.fsaCard = "NotApplicable";
    PacketageInfo.DoCredit.convenienceFeeAmount = "";
    PacketageInfo.DoCredit.AccountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AccountInformation == "") {
      PacketageInfo.DoCredit.AccountInformation = {};
      PacketageInfo.DoCredit.AccountInformation.Account = "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode = "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate = "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype = "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber = "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo = "";
      PacketageInfo.DoCredit.AccountInformation.CardType = "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder = "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage = "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = "";
    } else {
      j = -1;
      if (
        PacketageInfo.DoCredit &&
        PacketageInfo.DoCredit.AccountInformation &&
        PacketageInfo.DoCredit.AccountInformation.hasOwnProperty("Account")
      ) {
        PacketageInfo.DoCredit.AccountInformation.Account =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.EntryMode =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.ExpireDate =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.EBTtype =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.VoucherNumber =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.NewAccountNo =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.CardType =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.CardHolder =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.CVDMessage =
          response[i][++j] != undefined ? response[i][j] : "";
        PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator =
          response[i][++j] != undefined ? response[i][j] : "";
      }
    }

    PacketageInfo.DoCredit.accountNumber =
      PacketageInfo.DoCredit.AccountInformation.Account;
    PacketageInfo.DoCredit.isCardInserted =
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator;
    PacketageInfo.DoCredit.cardHolderName =
      PacketageInfo.DoCredit.AccountInformation.CardHolder;

    PacketageInfo.DoCredit.TraceInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.TraceInformation == "") {
      PacketageInfo.DoCredit.TraceInformation = {};
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber = "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AVSinformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AVSinformation == "") {
      PacketageInfo.DoCredit.AVSinformation = {};
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.CommercialInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.CommercialInformation == "") {
      PacketageInfo.DoCredit.CommercialInformation = {};
      PacketageInfo.DoCredit.CommercialInformation.PONumber = "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.CommercialInformation.PONumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription =
        response[i][++j] != undefined ? response[i][j] : "";
    }
    console.log(PacketageInfo.DoCredit.CommercialInformation);

    PacketageInfo.DoCredit.motoEcommerce =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.motoEcommerce == "") {
      PacketageInfo.DoCredit.motoEcommerce = {};
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType = "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType = "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber = "";
      PacketageInfo.DoCredit.motoEcommerce.Installments = "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.Installments =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AdditionalInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AdditionalInformation == "")
      PacketageInfo.DoCredit.AdditionalInformation = {};
    var additionalInfoArr = PacketageInfo.DoCredit.AdditionalInformation,
      keyValue = [];
    for (i = 0; i < additionalInfoArr.length; i++) {
      keyValue = additionalInfoArr[i].split("=");
      PacketageInfo.DoCredit.AdditionalInformation[keyValue[0]] = keyValue[1];
      keyValue = [];
    }
    if (
      PacketageInfo.DoCredit.AdditionalInformation.hasOwnProperty("CARDBIN")
    ) {
      PacketageInfo.DoCredit.binValue =
        PacketageInfo.DoCredit.AdditionalInformation.CARDBIN;
    }

    PacketageInfo.DoCredit.cardLogo = "";
    PacketageInfo.DoCredit.currencyCode = "";
    PacketageInfo.DoCredit.entryMode = "";
    PacketageInfo.DoCredit.expirationYear = "";
    PacketageInfo.DoCredit.expirationMonth = "";
    PacketageInfo.DoCredit.paymentType = "";
    PacketageInfo.DoCredit.pinVerified = "";
    PacketageInfo.DoCredit.signature = {
      statusCode: "SignatureNotRequiredByThresholdAmount",
    };
    PacketageInfo.DoCredit.statusCode = PacketageInfo.DoCredit.isApproved
      ? commandName + "Approved"
      : "";
    PacketageInfo.DoCredit.transactionDateTime =
      PacketageInfo.DoCredit.TraceInformation.TimeStamp;
    PacketageInfo.DoCredit.transactionId =
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber;
    PacketageInfo.DoCredit.merchantId = "";

    console.log(`PacketageInfo`, PacketageInfo.DoCredit);
    return PacketageInfo.DoCredit;
  }

  const ShowMsgInfo = (modal, type, msg, status) => {
    console.log(`modal`, modal);
    console.log(`type`, type);
    console.log(`msg`, msg);
    console.log(`status`, status);
    setOpenErrorMsg(modal);
    setErrorMsg(type);
    setTitle(msg);
    setStatus(status);
  };

  const handleClosepopUp = () => {
    setPaxMsg(false);
    setOpenErrorMsg(false);
    // setLoader(false);
    setTimeout(() => {
      setErrorMsg("");
      setTitle("");
      setStatus(1);
    }, 200);
  };

  const handleConfirm = (e, type, status) => {
    console.log(`type-----=`, type);
    e.preventDefault();
    if (type === RESETLOCALCONFIGDATA && status !== 0) {
      setUpdateData(true);
      setformData(InitalData());
      handleClosepopUp();
    } else if (type === CLEARLOCALCONFIGDATA && status !== 0) {
      if (checkOnlineStatus()) {
        console.log(`status is online`, checkOnlineStatus());
        localStorage.removeItem("wpos_auth");
        localStorage.removeItem("wpos_config");
        localStorage.removeItem("posSetting");
        localStorage.removeItem("generalSetting");
        localStorage.removeItem("accountSetting");
        localStorage.removeItem("usersList");
        getDataFromServer(1);
        handleClosepopUp();
      } else {
        // setStatus(0);
        console.log(`Can not clear the cache in offline mode.`);
        ShowMsgInfo(
          true,
          "Can not clear the cache in offline mode.",
          "Alert!",
          2
        );
      }
    } else if (type === REFRESHREMOTEDATA && status !== 0) {
      getDataFromServer(1);
      handleClosepopUp();
    } else if (type === REMOVEDEVICEREGISTRATION && status !== 0) {
      dispatch(loadingToggleAction(true));
      const deleteId = getDeviceAndStore(true)[1];
      if (deleteId) {
        getListService
          .DeleteRow(type, deleteId)
          .then((response) => {
            if (
              response.status === 200 ||
              (response.status === 201 && response.statusText === "OK")
            ) {
              localStorage.removeItem("uuid");
              localStorage.removeItem("setupData");
              localStorage.removeItem("storeName");
              localStorage.removeItem("deviceName");
              localStorage.removeItem("wpos_config");
              localStorage.removeItem("posSetting");
              localStorage.removeItem("generalSetting");
              localStorage.removeItem("accountSetting");
              dispatch(logoutAction(true));
            }
          })
          .catch((error) => {
            console.log("offline", error);
            dispatch(loadingToggleAction(false));
            toast.error(error.response.msg);
          });
      }
      handleClosepopUp();
    } else if (type === CLOSEBATCH) {
      handleLoader(true, CLOSEPAXMACHINE);
      setTimeout(
        function () {
          closeBatch();
        },
        [4000]
      );
      handleClosepopUp();
    }
  };

  function getDataFromServer() {
    dispatch(refreshServerData(REFRESHREMOTEDATA, 1));
  }

  useEffect(() => {
    expendUi(counter);
  }, [counter]);

  const expendUi = (counter) => {
    dispatch(expendUiDataReducer(EXPENDUI, counter));
  };

  const incrementCounter = () => {
    if (localStorage.getItem("width") === "none") {
      setCounter(1);
      localStorage.setItem("width", "none");
    } else {
      setCounter((prevCount) => prevCount + 1);
    }
  };

  useEffect(() => {
    if (isWeb()) {
      // Render browser-specific component
      console.log("web");
    } else {
      console.log("electron ");

      let printers_ = window.electron.remote
        .getCurrentWebContents()
        .getPrinters();
      console.log("printers_ is", printers_);
      let printerList = [];
      for (let printer of printers_) {
        printerList.push({
          value: printer.name,
          label: printer.name,
        });
      }
      console.log("printerList is", printerList);
      setPrinters(printerList);
    }
  }, []);

  const TestPrint = () => {
    // window.electron.remote.getCurrentWindow().webContents.print(options);
    const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));

    for (let i = 1; i <= wpos_config.reciptOfPrint; i++) {
      TestPrintContent();
    }
  };

  const TestPrintContent = async () => {
    // ESC/P receipt generation
    var esc_init = "\x1B" + "\x40"; // initialize printer
    var esc_p = "\x1B" + "\x70" + "\x30"; // open drawer
    var esc_full_cut = "\x1B" + "\x69"; // esc obsolute full (one point left) cut
    var esc_part_cut = "\x1B" + "\x6D"; // esc obsolute partial (three points left) cut
    var gs_full_cut = "\x1D" + "\x56" + "\x30"; // cut paper
    var gs_part_cut = "\x1D" + "\x56" + "\x31"; // partial cut paper
    var esc_a_l = "\x1B" + "\x61" + "\x30"; // align left
    var esc_a_c = "\x1B" + "\x61" + "\x31"; // align center
    var esc_a_r = "\x1B" + "\x61" + "\x32"; // align right
    var esc_double = "\x1B" + "\x21" + "\x31"; // heading
    var font_reset = "\x1B" + "\x21" + "\x02"; // styles off
    var esc_ul_on = "\x1B" + "\x2D" + "\x31"; // underline on
    var esc_ul_off = "\x1B" + "\x2D" + "\x30"; // underline off
    var esc_bold_on = "\x1B" + "\x45" + "\x31"; // emphasis on
    var esc_bold_off = "\x1B" + "\x45" + "\x30"; // emphasis off

    var cmd = getEscReceiptHeader();
    // cmd += getTestContent();

    function getEscReceiptHeader() {
      const posSettings = JSON.parse(localStorage.getItem("posSetting"));
      const generalSettings = JSON.parse(
        localStorage.getItem("generalSetting")
      );
      let header =
        esc_init +
        esc_a_c +
        esc_double +
        generalSettings?.BUSINESS?.business_name +
        font_reset +
        "\n";
      header += esc_bold_on + posSettings?.RECEIPT?.header_line_2 + "\n";
      header += posSettings?.RECEIPT?.header_line_3 + "\n";
      return header;
    }

    const d_image = getDetailsofImage("PrintReciptLogo");
    if (d_image !== undefined && d_image.length > 0) {
      if (d_image[0]?.file !== undefined) {
        const origimage1 = FormatImageBase64Code(d_image[0].file);
        var salesPath = window.appData + "/logo";
        if (!window.fs.existsSync(salesPath)) {
          window.fs.mkdirSync(salesPath);
        }
        const base64Data = origimage1.replace(/^data:image\/png;base64,/, "");
        window.fs.writeFileSync(salesPath + "/image.png", base64Data, "base64");
      }
    }

    var thermaPrinter = preparePrinter("epson");
    const posSettings = props.posSettings;

    var qrtext = posSettings?.RECEIPT?.promo_qr_code;

    thermaPrinter.alignCenter();
    if (
      salesPath != undefined &&
      posSettings?.RECEIPT.print_receipt_logo == 1
    ) {
      await thermaPrinter.printImage(salesPath + "/image.png");
    }
    thermaPrinter.drawLine();
    thermaPrinter.newLine();
    thermaPrinter.println(cmd);
    if (qrtext == "" || qrtext == null) {
      thermaPrinter.cut();
    }

    window.nodeprinter.printDirect({
      printerName: formData.report_printer,
      type: "RAW",
      data: thermaPrinter.getBuffer(),
      success: function (jobID) {
        if (qrtext != "" && qrtext != null) {
          printQrCode(qrtext, true);
        }
        console.log(`Printed with Job ID: ${jobID}`);
      },
      error: function (err) {
        console.error(err);
      },
    });
  };

  const printQrCode = async (qrtext, cutpaper) => {
    let thermaPrinter = preparePrinter("epson");
    thermaPrinter.newLine();
    thermaPrinter.printQR(qrtext, {
      cellSize: 5, // 1 - 8
      correction: "H", // L(7%), M(15%), Q(25%), H(30%)
      model: 1, // 1 - Model 1
      // 2 - Model 2 (standard)
      // 3 - Micro QR
    });

    if (cutpaper) {
      thermaPrinter.cut();
    }

    window.nodeprinter.printDirect({
      data: thermaPrinter.getBuffer(),
      type: "RAW", // type: RAW, TEXT, PDF, JPEG, .. depends on platform
      printerName: formData.report_printer,
      success: function (jobID) {
        console.log("sent to printer with ID: " + jobID);
      },
      error: function (err) {
        console.log(err);
      },
    });
  };

  function getDetailsofImage(type) {
    if (type !== undefined) {
      const detailsData = props?.image.filter((item) => item.name === type);
      return detailsData;
    }
  }

  function preparePrinter(type) {
    let thermaPrinter;
    if (type == "star") {
      thermaPrinter = new window.ThermalPrinter({
        type: "STAR",
      });
    } else {
      thermaPrinter = new window.ThermalPrinter({
        type: "EPSON",
      });
    }
    return thermaPrinter;
  }

  const TestCashdraw = () => {
    if (formData.cash_draw_connected) {
      var thermaPrinter = preparePrinter("epson");
      thermaPrinter.openCashDrawer();
      //thermaPrinter.println(data);
      window.nodeprinter.printDirect({
        data: thermaPrinter.getBuffer(),
        type: "RAW", // type: RAW, TEXT, PDF, JPEG, .. depends on platform
        printer: formData.report_printer,
        success: function (jobID) {
          console.log("sent to printer with ID: " + jobID);
        },
        error: function (err) {
          console.log(err);
        },
      });
    } else {
      ShowMsgInfo(true, "Cash draw not connected or configured!!", "Alert!", 2);
    }
  };

  //Get LRC
  const getLRC = (params) => {
    var lrc = 0;
    for (let i = 1; i < params.length; i++) {
      var type_of = typeof params[i];
      if (type_of == "string") {
        var element = params[i].split("");
        for (let ii = 0; ii < element.length; ii++) {
          lrc ^= element[ii].charCodeAt(0);
        }
      } else {
        lrc ^= params[i];
      }
    }
    return String.fromCharCode(lrc);
  };

  function getRestSettings() {
    let version = "1.28";
    let command = "A26";
    return RebootMe({ command: command, version: version });
  }

  const RestartPax = () => {
    // let settings = getRestSettings();
    // console.log(settings);
    // $.ajax(settings).done(function (response) {
    //   let commandType = null;
    //   let res = parseResponse(response);
    //   console.log(`res`, res);
    // }).fail(function (jqXHR, status, error) {
    //   if (jqXHR.status === 0) {
    //   } else {
    //     let errormessage;
    //     if (jqXHR.status === 0) {
    //       errormessage = 'Could not connect.\n Verify your network.';
    //     } else if (jqXHR.status == 404) {
    //       errormessage = 'Requested page not found. [404]';
    //     } else if (jqXHR.status == 500) {
    //       errormessage = 'Internal Server Error [500].';
    //     } else {
    //       errormessage = 'Uncaught Error.\n' + jqXHR.responseText;
    //     }
    //   }
    // });

    ////////////////////

    var initialInfo = {
      command: "A00",
      version: "1.28",
    };
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      // mDestinationIP: "http://127.0.0.1:10009",
      mDestinationIP: `${paxConfig.pax_protocol == ""
          ? "https"
          : paxConfig.pax_protocol == "1"
            ? "http"
            : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };
    var params = [
      PAX.mStx.hex,
      initialInfo.command,
      PAX.mFS.hex,
      initialInfo.version,
      PAX.mEtx.hex,
    ];
    var lrc = getLRC(params);

    var command_hex = base64ToHex(Base64.btoa(initialInfo.command));
    var version_hex = base64ToHex(Base64.btoa(initialInfo.version));
    //var elements = [this.mStx, command_hex, this.mFS, version_hex, this.mEtx, base64ToHex(Base64.btoa(lrc))];
    var elements = [
      PAX.mStx.code,
      command_hex,
      PAX.mFS.code,
      version_hex,
      PAX.mEtx.code,
      base64ToHex(Base64.btoa(lrc)),
    ];

    var final_string = elements.join(" ");

    var final_b64 = hexToBase64(final_string);

    // var url = PAX.mDestinationIP + "?" + final_b64;
    var url = PAX.mDestinationIP + "?AkEyNhwxLjI4A08=";

    HttpCommunication(
      "Initialize",
      url,
      function (response) {
        console.log(`paxres`, response);
        if (response[5] == "OK") {
          setTimeout(
            function () {
              setLoader(false);
              setTitle("Success");
              setErrorMsg("Restart Done");
              setShowIcon(false);
              setPaxMsg(true);
            },
            [30000]
          );
        }
        // setLoader(false)
      },
      PAX.timeout.Initialize
    );
  };

  const RebootMe = (resetInfo) => {
    var initialInfo = {
      command: "A00",
      version: "1.28",
    };

    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      mDestinationIP: `${paxConfig.pax_protocol == ""
          ? "https"
          : paxConfig.pax_protocol == "1"
            ? "http"
            : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      // mDestinationIP: "http://127.0.0.1:10009", // - OLD "http://192.167.2.100:10009";       //http://112.199.49.146:8181
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
        Reset: 60 * 1000,
      },
    };
    var params = [
      PAX.mStx.hex,
      initialInfo.command,
      PAX.mFS.hex,
      initialInfo.version,
      PAX.mEtx.hex,
    ];

    var lrc = getLRC(params);

    //prepare for base64 encoding.
    var command_hex = base64ToHex(Base64.btoa(resetInfo.command));
    var version_hex = base64ToHex(Base64.btoa(resetInfo.version));

    var elements = [PAX.mStx.code];
    elements.push(command_hex);
    elements.push(PAX.mFS.code);
    elements.push(version_hex);
    elements.push(PAX.mEtx.code);
    elements.push(base64ToHex(Base64.btoa(lrc)));

    var final_string = elements.join(" ");
    var final_b64 = hexToBase64(final_string);

    var url = PAX.mDestinationIP + "?" + final_b64;

    return { url: url, timeout: PAX.timeout.Reset };
  };

  const HttpCommunication = (commandType, url, callback, timeout) => {
    $.ajax({
      url: url,
      timeout: timeout,
      error: function (xmlHttpRequest, error) {
        setTimeout(
          function () {
            setLoader(false);
            setTitle("Error");
            setShowIcon(false);
            setErrorMsg(
              "Please Manually Power Cycle the Card machine and Relogin"
            );
            setPaxMsg(true);
          },
          [3 * 1000]
        );
        console.log(`testing->`, timeout);
        console.log("Ajax error info: " + error);
        callback(error);
      },
      success: function (response) {
        // setLoader(false);
        console.log("success");
        console.log("Raw response: " + response);

        var checkParams = StringToHex(response).split(" ").pop();
        var RedundancyCheck = StringToHex(response)
          .split(" ")
          .pop()
          .substring(1);

        var check = getLRC(checkParams);

        if (check == RedundancyCheck) {
          //get package detail info
          var packetInfo = [];
          var len = StringToHex(response).indexOf("03");
          var hex = StringToHex(response).slice(0, len).split(/02|1c/);

          console.log(hex);
          if (commandType == "DoCredit") {
            var subHex = [],
              subPacketInfo = [];
            for (var i = 0; i < hex.length; i++) {
              if (hex[i] != "") {
                if (hex[i].indexOf("1f") > 0) {
                  subHex = hex[i].split("1f");
                  console.log(subHex);
                  subPacketInfo = [];
                  for (var j = 0; j < subHex.length; j++) {
                    if (subHex[j] != "") {
                      subPacketInfo.push(HexToString(subHex[j]));
                    }
                  }
                  console.log(subPacketInfo);
                  packetInfo.push(subPacketInfo);
                } else {
                  packetInfo[i] = HexToString(hex[i]);
                }
              }
            }
          } else {
            for (var i = 0; i < hex.length; i++) {
              if (hex[i] != "") {
                packetInfo[i] = HexToString(hex[i]);
              }
            }
          }

          console.log("Separate package info: ");
          console.log(packetInfo);
          callback(packetInfo);
        }
      },
    });
  };

  return (
    <>
      <PrintLabel />
      <CommonPusherComponent />

      {!isUserAdminstrator() ? (
        <h1>Permission denied</h1>
      ) : (
        <>
          {props?.loading && <LoaderSpinner />}
          {loader && (
            <CustomModal
              open={loader}
              dialogClassName={`custom-modal confirmation error-popup`}
              title="TroubleShooting"
              content={
                <h1 className="mb-3">
                  {loaderType == CLOSEPAXMACHINE
                    ? "Follow Prompts on Terminal"
                    : "Restarting"}
                </h1>
              }
              action={<LoaderSpinner />}
            />
          )}
          <Row className="mb-3 settings">
            <Col xs="12">
              <h1 className="mb-3">Settings</h1>
            </Col>
            <Col>
              <Tab.Container defaultActiveKey="General">
                <Nav
                  fill
                  variant="pills"
                  className="custom-tab-nav flex-sm-row"
                  style={{ border: "none" }}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="General" className="mb-sm-3 mb-md-0">
                      <Image
                        className="me-2"
                        src={props?.dynamicImages?.general}
                        alt="general Icon"
                      />
                      General
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Printing" className="mb-sm-3 mb-md-0">
                      <Image
                        className="me-2"
                        src={props?.dynamicImages?.printing}
                        alt="printing Icon"
                      />
                      Printing
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Actions" className="mb-sm-3 mb-md-0">
                      <Image
                        className="me-2"
                        src={props?.dynamicImages?.actions}
                        alt="actions Icon"
                      />
                      Actions
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="settings-tab">
                  <Tab.Pane eventKey="General" className="general">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>General</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col xs={12} lg={8} className="right-select">
                              {formData.enableWebstoreNoti &&
                                (
                                  <Form.Group className="custom-react-select">
                                    <ReactSelect
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      styles={{
                                        valueContainer: (provided, state) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue ||
                                              state.selectProps.inputValue
                                              ? -15
                                              : "7px",
                                          transition: "top 0.1s, font-size 0.1s",

                                          transform:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            "scale(0.85)",
                                          opacity:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            1,
                                        }),
                                      }}
                                      options={soundsList}
                                      value={soundsList.filter((item) => {
                                        return item.value === formData.websound;
                                      })}
                                      onChange={(e) => handleSelect(e, "websound")}
                                      classNamePrefix="react-select"
                                      placeholder={"WebOrder Notification Sound"}
                                    />
                                  </Form.Group>
                                )}
                              <Form.Group className="custom-react-select">
                                <ReactSelect
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  styles={{
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                          state.selectProps.inputValue
                                          ? -15
                                          : "7px",
                                      transition: "top 0.1s, font-size 0.1s",

                                      transform:
                                        (state.hasValue ||
                                          state.selectProps.inputValue) &&
                                        "scale(0.85)",
                                      opacity:
                                        (state.hasValue ||
                                          state.selectProps.inputValue) &&
                                        1,
                                    }),
                                  }}
                                  options={soundsList}
                                  value={soundsList.filter((item) => {
                                    return item.value === formData.sound;
                                  })}
                                  onChange={(e) => handleSelect(e, "sound")}
                                  classNamePrefix="react-select"
                                  placeholder={"Tasklist Notification Sound"}
                                />
                              </Form.Group>
                              <Form.Group className="custom-react-select">
                                <SearchFontSize />
                              </Form.Group>
                              <Form.Group className="custom-react-select">
                                <FontSizesPos />
                              </Form.Group>
                              <Form.Group className="custom-react-select">
                                <ReactSelect
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  styles={{
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                          state.selectProps.inputValue
                                          ? -15
                                          : "7px",
                                      transition: "top 0.1s, font-size 0.1s",

                                      transform:
                                        (state.hasValue ||
                                          state.selectProps.inputValue) &&
                                        "scale(0.85)",
                                      opacity:
                                        (state.hasValue ||
                                          state.selectProps.inputValue) &&
                                        1,
                                    }),
                                  }}
                                  classNamePrefix="react-select"
                                  options={[NoneValue].concat(
                                    CustomerFontSize.map((item) => {
                                      return {
                                        value: item,
                                        label: item + "px",
                                      };
                                    })
                                  )}
                                  value={CustomerFontSize.map((item) => {
                                    return {
                                      ...item,
                                      value: item,
                                      label: item + "px",
                                    };
                                  })
                                    .concat(NoneValue)
                                    .filter((item) => {
                                      return (
                                        item.value === formData.customerFont
                                      );
                                    })}
                                  onChange={(e) =>
                                    handleSelect(e, "customerFont")
                                  }
                                  placeholder={"Customer Screen Font Size"}
                                  defaultValue={NoneValue}
                                />
                              </Form.Group>
                              <Form.Group className="custom-react-select">
                                <ReactSelect
                                  components={{
                                    ValueContainer: CustomValueContainer,
                                  }}
                                  styles={{
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflow: "visible",
                                    }),
                                    placeholder: (provided, state) => ({
                                      ...provided,
                                      position: "absolute",
                                      top:
                                        state.hasValue ||
                                          state.selectProps.inputValue
                                          ? -15
                                          : "7px",
                                      transition: "top 0.1s, font-size 0.1s",

                                      transform:
                                        (state.hasValue ||
                                          state.selectProps.inputValue) &&
                                        "scale(0.85)",
                                      opacity:
                                        (state.hasValue ||
                                          state.selectProps.inputValue) &&
                                        1,
                                    }),
                                  }}
                                  classNamePrefix="react-select"
                                  options={[NoneValue].concat(
                                    CustomerScreenZoom.filter((item) => {
                                      return item % 10 === 0;
                                    }).map((item) => {
                                      return {
                                        ...item,
                                        value: item,
                                        label: item + "%",
                                      };
                                    })
                                  )}
                                  value={CustomerScreenZoom.map((item) => {
                                    return {
                                      value: item,
                                      label: item + "%",
                                    };
                                  })
                                    .concat(NoneValue)
                                    .filter((item) => {
                                      return (
                                        item.value ===
                                        formData.customerScreenZoom
                                      );
                                    })}
                                  onChange={(e) =>
                                    handleSelect(e, "customerScreenZoom")
                                  }
                                  placeholder={"Customer Screen Zoom"}
                                  defaultValue={NoneValue}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={12} lg={4} className="mt-4 mt-lg-0">
                              <Form.Check
                                className="custom-checkbox"
                                type="checkbox"
                              >
                                <FormCheck.Input
                                  id="screenKeypad"
                                  name="screenKeypad"
                                  checked={formData.screenKeypad}
                                  onChange={(e) => handleChange(e)}
                                  className="me-2 custom-checkbox-bg"
                                />
                                <FormCheck.Label
                                  htmlFor="screenKeypad"
                                  className="mb-0"
                                >
                                  Use On-Screen Keypad
                                </FormCheck.Label>
                              </Form.Check>
                              <Form.Check
                                className="custom-checkbox mt-2"
                                type="checkbox"
                              >
                                <FormCheck.Input
                                  id="fixedKeypad"
                                  name="fixedKeypad"
                                  checked={formData.fixedKeypad}
                                  onChange={(e) => handleChange(e)}
                                  className="me-2 custom-checkbox-bg"
                                />
                                <FormCheck.Label
                                  htmlFor="fixedKeypad"
                                  className="mb-0"
                                >
                                  Show Fixed Keypad
                                </FormCheck.Label>
                              </Form.Check>
                              <Form.Check
                                className="custom-checkbox mt-2"
                                type="checkbox"
                              >
                                <FormCheck.Input
                                  id="autolanuchCustomeScreen"
                                  name="autolanuchCustomeScreen"
                                  checked={formData.autolanuchCustomeScreen}
                                  onChange={(e) => handleChange(e)}
                                  className="me-2 custom-checkbox-bg"
                                />
                                <FormCheck.Label
                                  htmlFor="autolanuchCustomeScreen"
                                  className="mb-0"
                                >
                                  Auto Launch Customer Screen
                                </FormCheck.Label>
                              </Form.Check>
                              <Form.Check
                                className="custom-checkbox mt-2"
                                type="checkbox"
                              >
                                <FormCheck.Input
                                  id="showTotalCusScreen"
                                  name="showTotalCusScreen"
                                  checked={formData.showTotalCusScreen}
                                  onChange={(e) => handleChange(e)}
                                  className="me-2 custom-checkbox-bg"
                                />
                                <FormCheck.Label
                                  htmlFor="showTotalCusScreen"
                                  className="mb-0"
                                >
                                  Show Total On Customer Screen
                                </FormCheck.Label>
                              </Form.Check>
                              <Form.Check
                                className="custom-checkbox mt-2"
                                type="checkbox"
                              >
                                <FormCheck.Input
                                  id="showPriceCusScreen"
                                  name="showPriceCusScreen"
                                  checked={formData.showPriceCusScreen}
                                  onChange={(e) => handleChange(e)}
                                  className="me-2 custom-checkbox-bg"
                                />
                                <FormCheck.Label
                                  htmlFor="showPriceCusScreen"
                                  className="mb-0"
                                >
                                  Show Price On Customer Screen
                                </FormCheck.Label>
                              </Form.Check>
                              <Form.Check
                                className="custom-checkbox mt-2"
                                type="checkbox"
                              >
                                <FormCheck.Input
                                  id="email_notesInput"
                                  name="email_notesInput"
                                  checked={formData.email_notesInput}
                                  onChange={(e) => handleChange(e)}
                                  className="me-2 custom-checkbox-bg"
                                />
                                <FormCheck.Label
                                  htmlFor="email_notesInput"
                                  className="mb-0"
                                >
                                  Show Email &amp; Notes Input
                                </FormCheck.Label>
                              </Form.Check>
                              <Form.Check
                                className="custom-checkbox mt-2"
                                type="checkbox"
                              >
                                <FormCheck.Input
                                  id="alwayShowShortcut"
                                  name="alwayShowShortcut"
                                  checked={formData.alwayShowShortcut}
                                  onChange={(e) => handleChange(e)}
                                  className="me-2 custom-checkbox-bg"
                                />
                                <FormCheck.Label
                                  htmlFor="alwayShowShortcut"
                                  className="mb-0"
                                >
                                  Always Show Shortcuts
                                </FormCheck.Label>
                              </Form.Check>
                              <Form.Check
                                className="custom-checkbox mt-2"
                                type="checkbox"
                              >
                                <FormCheck.Input
                                  id="enableWebstoreNoti"
                                  name="enableWebstoreNoti"
                                  checked={formData.enableWebstoreNoti}
                                  onChange={(e) => handleChange(e)}
                                  className="me-2 custom-checkbox-bg"
                                />
                                <FormCheck.Label
                                  htmlFor="enableWebstoreNoti"
                                  className="mb-0"
                                >
                                  Enable Weborder Notifications
                                </FormCheck.Label>
                              </Form.Check>
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Registration</Accordion.Header>
                        <Accordion.Body>
                          <ListGroup as="ol" className="registration-list">
                            <ListGroup.Item
                              as="li"
                              className="d-flex  align-items-center"
                            >
                              <div className="ms-2">
                                <div className="fw-bold">Device ID:</div>
                              </div>
                              <Badge bg="primary" pill>
                                {getDeviceAndStore()[0]}
                              </Badge>
                            </ListGroup.Item>
                            <ListGroup.Item
                              as="li"
                              className="d-flex align-items-center"
                            >
                              <div className="ms-2">
                                <div className="fw-bold">Device Name:</div>
                              </div>
                              <Badge bg="primary" pill>
                                {getDeviceAndStoreName(true)[0]}
                              </Badge>
                            </ListGroup.Item>
                            <ListGroup.Item
                              as="li"
                              className="d-flex align-items-center"
                            >
                              <div className="ms-2">
                                <div className="fw-bold">Store Name:</div>
                              </div>
                              <Badge bg="primary" pill>
                                {getDeviceAndStoreName(true)[1]}
                              </Badge>
                            </ListGroup.Item>
                            <ListGroup.Item
                              as="li"
                              className="d-flex  align-items-center"
                            >
                              <div className="ms-2">
                                <div className="fw-bold">Registration ID:</div>
                              </div>
                              <Badge bg="primary" pill>
                                {getDeviceAndStore(true)[1]}
                              </Badge>
                            </ListGroup.Item>
                            <ListGroup.Item
                              as="li"
                              className="d-flex  align-items-center regestration-ui"
                            >
                              <div className="ms-2">
                                <div className="fw-bold">
                                  Registration UUID:
                                </div>
                              </div>
                              <Badge bg="primary" pill>
                                {DeviceUUId(true)}
                              </Badge>
                            </ListGroup.Item>
                            <ListGroup.Item
                              as="li"
                              className="d-flex align-items-center"
                            >
                              <div className="ms-2">
                                <div className="fw-bold">Registration DT:</div>
                              </div>
                              <Badge bg="primary" pill>
                                {moment(getDeviceAndStore(true)[0]).format(
                                  "MMMM DD YYYY, h:mm:ss a"
                                )}
                              </Badge>
                            </ListGroup.Item>
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Printing" className="pt-2 stats">
                    <Card className="custom-card">
                      <Card.Body>
                        <Row>
                          <Col md="12" lg="4" className="mb-4 mb-lg-0">
                            <h2 className="mb-4">Receipt Printing</h2>

                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>General</Accordion.Header>
                                <Accordion.Body>
                                  <Form.Group className="custom-react-select">
                                    <ReactSelect
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      styles={{
                                        valueContainer: (provided, state) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue ||
                                              state.selectProps.inputValue
                                              ? -15
                                              : "7px",
                                          transition:
                                            "top 0.1s, font-size 0.1s",

                                          transform:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            "scale(0.85)",
                                          opacity:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            1,
                                        }),
                                      }}
                                      classNamePrefix="react-select"
                                      options={askToprintOption}
                                      value={askToprintOption.filter((item) => {
                                        return (
                                          item.value === formData.asktoPrint
                                        );
                                      })}
                                      onChange={(e) =>
                                        handleSelect(e, "asktoPrint")
                                      }
                                      placeholder={"Ask To Print"}
                                    />
                                  </Form.Group>
                                  <Form.Group className="custom-react-select mt-4">
                                    <ReactSelect
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      styles={{
                                        valueContainer: (provided, state) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue ||
                                              state.selectProps.inputValue
                                              ? -15
                                              : "7px",
                                          transition:
                                            "top 0.1s, font-size 0.1s",

                                          transform:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            "scale(0.85)",
                                          opacity:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            1,
                                        }),
                                      }}
                                      classNamePrefix="react-select"
                                      options={ReciptOfPrint.map((item) => {
                                        return {
                                          ...item,
                                          value: item,
                                          label: item,
                                        };
                                      })}
                                      value={ReciptOfPrint.map((item) => {
                                        return {
                                          value: item,
                                          label: item,
                                        };
                                      }).filter((item) => {
                                        return (
                                          item.value === formData.reciptOfPrint
                                        );
                                      })}
                                      onChange={(e) =>
                                        handleSelect(e, "reciptOfPrint")
                                      }
                                      placeholder={"# Of Receipts To Print"}
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-floating mt-4">
                                    <FormField
                                      label="Auto Hide Receipt Dialog (sec)"
                                      type="number"
                                      name="autoHideReciptDialog"
                                      value={formData.autoHideReciptDialog}
                                      onChange={(e) => handleInputChange(e)}
                                      placeholder="Auto Hide Receipt Dialog (sec)"
                                    />
                                  </Form.Group>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1">
                                <Accordion.Header>Connection</Accordion.Header>
                                <Accordion.Body>
                                  <Form.Group className="custom-react-select">
                                    <ReactSelect
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      styles={{
                                        valueContainer: (provided, state) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue ||
                                              state.selectProps.inputValue
                                              ? -15
                                              : "7px",
                                          transition:
                                            "top 0.1s, font-size 0.1s",

                                          transform:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            "scale(0.85)",
                                          opacity:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            1,
                                        }),
                                      }}
                                      classNamePrefix="react-select"
                                      options={connMethod}
                                      value={connMethod.filter((item) => {
                                        return (
                                          item.value === formData.connectMethod
                                        );
                                      })}
                                      onChange={(e) =>
                                        handleSelect(e, "connectMethod")
                                      }
                                      placeholder={"Method"}
                                    />
                                  </Form.Group>
                                  {formData.connectMethod === "wp" && (
                                    <>
                                      <Form.Group className="custom-react-select mt-4 mb-4">
                                        <ReactSelect
                                          components={{
                                            ValueContainer:
                                              CustomValueContainer,
                                          }}
                                          styles={{
                                            valueContainer: (
                                              provided,
                                              state
                                            ) => ({
                                              ...provided,
                                              overflow: "visible",
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              position: "absolute",
                                              top:
                                                state.hasValue ||
                                                  state.selectProps.inputValue
                                                  ? -15
                                                  : "7px",
                                              transition:
                                                "top 0.1s, font-size 0.1s",

                                              transform:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                "scale(0.85)",
                                              opacity:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                1,
                                            }),
                                          }}
                                          classNamePrefix="react-select"
                                          options={connType}
                                          value={connType.filter((item) => {
                                            return (
                                              item.value ===
                                              formData.connectType
                                            );
                                          })}
                                          onChange={(e) =>
                                            handleSelect(e, "connectType")
                                          }
                                          placeholder={"type"}
                                        />
                                      </Form.Group>
                                      {formData.connectType === "serial" && (
                                        <>
                                          <div className="d-flex align-items-end mt-4">
                                            <Form.Group className="custom-react-select mt-2 w-100">
                                              <ReactSelect
                                                components={{
                                                  ValueContainer:
                                                    CustomValueContainer,
                                                }}
                                                styles={{
                                                  valueContainer: (
                                                    provided,
                                                    state
                                                  ) => ({
                                                    ...provided,
                                                    overflow: "visible",
                                                  }),
                                                  placeholder: (
                                                    provided,
                                                    state
                                                  ) => ({
                                                    ...provided,
                                                    position: "absolute",
                                                    top:
                                                      state.hasValue ||
                                                        state.selectProps
                                                          .inputValue
                                                        ? -15
                                                        : "7px",
                                                    transition:
                                                      "top 0.1s, font-size 0.1s",

                                                    transform:
                                                      (state.hasValue ||
                                                        state.selectProps
                                                          .inputValue) &&
                                                      "scale(0.85)",
                                                    opacity:
                                                      (state.hasValue ||
                                                        state.selectProps
                                                          .inputValue) &&
                                                      1,
                                                  }),
                                                }}
                                                classNamePrefix="react-select"
                                                options={connPort}
                                                value={connPort.filter(
                                                  (item) => {
                                                    return (
                                                      item.value ===
                                                      formData.connectPort
                                                    );
                                                  }
                                                )}
                                                onChange={(e) =>
                                                  handleSelect(e, "connectPort")
                                                }
                                                placeholder={"Port"}
                                              />
                                            </Form.Group>
                                            <Button
                                              title="Refresh"
                                              variant="refresh-btn ms-2"
                                              style={{ background: "blue" }}
                                            >
                                              <FontAwesomeIcon icon={faSync} />
                                            </Button>
                                          </div>
                                          <Form.Group className="custom-react-select mt-4">
                                            <ReactSelect
                                              components={{
                                                ValueContainer:
                                                  CustomValueContainer,
                                              }}
                                              styles={{
                                                valueContainer: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  overflow: "visible",
                                                }),
                                                placeholder: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  position: "absolute",
                                                  top:
                                                    state.hasValue ||
                                                      state.selectProps.inputValue
                                                      ? -15
                                                      : "7px",
                                                  transition:
                                                    "top 0.1s, font-size 0.1s",

                                                  transform:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    "scale(0.85)",
                                                  opacity:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    1,
                                                }),
                                              }}
                                              classNamePrefix="react-select"
                                              options={connBaud}
                                              value={connBaud.filter((item) => {
                                                return (
                                                  item.value === formData.baud
                                                );
                                              })}
                                              onChange={(e) =>
                                                handleSelect(e, "baud")
                                              }
                                              placeholder={"Baud"}
                                            />
                                          </Form.Group>
                                          <Form.Group className="custom-react-select mt-4">
                                            <ReactSelect
                                              components={{
                                                ValueContainer:
                                                  CustomValueContainer,
                                              }}
                                              styles={{
                                                valueContainer: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  overflow: "visible",
                                                }),
                                                placeholder: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  position: "absolute",
                                                  top:
                                                    state.hasValue ||
                                                      state.selectProps.inputValue
                                                      ? -15
                                                      : "7px",
                                                  transition:
                                                    "top 0.1s, font-size 0.1s",

                                                  transform:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    "scale(0.85)",
                                                  opacity:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    1,
                                                }),
                                              }}
                                              classNamePrefix="react-select"
                                              options={DataBits}
                                              value={DataBits.filter((item) => {
                                                return (
                                                  item.value ===
                                                  formData.data_bits
                                                );
                                              })}
                                              onChange={(e) =>
                                                handleSelect(e, "data_bits")
                                              }
                                              placeholder={"Data Bits"}
                                            />
                                          </Form.Group>
                                          <Form.Group className="custom-react-select mt-4">
                                            <ReactSelect
                                              components={{
                                                ValueContainer:
                                                  CustomValueContainer,
                                              }}
                                              styles={{
                                                valueContainer: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  overflow: "visible",
                                                }),
                                                placeholder: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  position: "absolute",
                                                  top:
                                                    state.hasValue ||
                                                      state.selectProps.inputValue
                                                      ? -15
                                                      : "7px",
                                                  transition:
                                                    "top 0.1s, font-size 0.1s",

                                                  transform:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    "scale(0.85)",
                                                  opacity:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    1,
                                                }),
                                              }}
                                              classNamePrefix="react-select"
                                              options={StopBits}
                                              value={StopBits.filter((item) => {
                                                return (
                                                  item.value ===
                                                  formData.stop_bits
                                                );
                                              })}
                                              onChange={(e) =>
                                                handleSelect(e, "stop_bits")
                                              }
                                              placeholder={"Stop Bits"}
                                            />
                                          </Form.Group>
                                          <Form.Group className="custom-react-select mt-4">
                                            <ReactSelect
                                              components={{
                                                ValueContainer:
                                                  CustomValueContainer,
                                              }}
                                              styles={{
                                                valueContainer: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  overflow: "visible",
                                                }),
                                                placeholder: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  position: "absolute",
                                                  top:
                                                    state.hasValue ||
                                                      state.selectProps.inputValue
                                                      ? -15
                                                      : "7px",
                                                  transition:
                                                    "top 0.1s, font-size 0.1s",

                                                  transform:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    "scale(0.85)",
                                                  opacity:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    1,
                                                }),
                                              }}
                                              classNamePrefix="react-select"
                                              options={Parity}
                                              value={Parity.filter((item) => {
                                                return (
                                                  item.value === formData.parity
                                                );
                                              })}
                                              onChange={(e) =>
                                                handleSelect(e, "parity")
                                              }
                                              placeholder={"Parity"}
                                            />
                                          </Form.Group>
                                          <Form.Group className="custom-react-select mt-4">
                                            <ReactSelect
                                              components={{
                                                ValueContainer:
                                                  CustomValueContainer,
                                              }}
                                              styles={{
                                                valueContainer: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  overflow: "visible",
                                                }),
                                                placeholder: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  position: "absolute",
                                                  top:
                                                    state.hasValue ||
                                                      state.selectProps.inputValue
                                                      ? -15
                                                      : "7px",
                                                  transition:
                                                    "top 0.1s, font-size 0.1s",

                                                  transform:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    "scale(0.85)",
                                                  opacity:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    1,
                                                }),
                                              }}
                                              classNamePrefix="react-select"
                                              options={FlowControl}
                                              value={FlowControl.filter(
                                                (item) => {
                                                  return (
                                                    item.value ===
                                                    formData.flow_control
                                                  );
                                                }
                                              )}
                                              onChange={(e) =>
                                                handleSelect(e, "flow_control")
                                              }
                                              placeholder={"Flow Control"}
                                            />
                                          </Form.Group>
                                        </>
                                      )}
                                      {formData.connectType === "raw" && (
                                        <>
                                          <div className="d-flex align-items-end">
                                            <Form.Group className="custom-react-select mt-4 w-100">
                                              <ReactSelect
                                                components={{
                                                  ValueContainer:
                                                    CustomValueContainer,
                                                }}
                                                styles={{
                                                  valueContainer: (
                                                    provided,
                                                    state
                                                  ) => ({
                                                    ...provided,
                                                    overflow: "visible",
                                                  }),
                                                  placeholder: (
                                                    provided,
                                                    state
                                                  ) => ({
                                                    ...provided,
                                                    position: "absolute",
                                                    top:
                                                      state.hasValue ||
                                                        state.selectProps
                                                          .inputValue
                                                        ? -15
                                                        : "7px",
                                                    transition:
                                                      "top 0.1s, font-size 0.1s",

                                                    transform:
                                                      (state.hasValue ||
                                                        state.selectProps
                                                          .inputValue) &&
                                                      "scale(0.85)",
                                                    opacity:
                                                      (state.hasValue ||
                                                        state.selectProps
                                                          .inputValue) &&
                                                      1,
                                                  }),
                                                }}
                                                classNamePrefix="react-select"
                                                options={connPort}
                                                value={connPort.filter(
                                                  (item) => {
                                                    return (
                                                      item.value ===
                                                      formData.printer
                                                    );
                                                  }
                                                )}
                                                onChange={(e) =>
                                                  handleSelect(e, "printer")
                                                }
                                                placeholder={"Printer"}
                                              />
                                            </Form.Group>
                                            <Button
                                              title="Refresh"
                                              variant="yellow refresh-btn ms-2"
                                            >
                                              <FontAwesomeIcon icon={faSync} />
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                      {formData.connectType === "tcp" && (
                                        <>
                                          <Form.Group className="form-floating mt-4">
                                            <FormField
                                              label="Printer IP"
                                              type="text"
                                              name="printerIp"
                                              value={formData.printerIp}
                                              onChange={(e) =>
                                                handleInputChange(e)
                                              }
                                              placeholder="192.168.1.100"
                                            />
                                          </Form.Group>
                                          <Form.Group className="form-floating mt-4">
                                            <FormField
                                              label="Printer Port"
                                              type="number"
                                              name="printerPort"
                                              value={formData.printerPort}
                                              onChange={(e) =>
                                                handleInputChange(e)
                                              }
                                              placeholder="9100"
                                            />
                                          </Form.Group>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {formData.connectMethod === "ep" && (
                                    <>
                                      <Form.Group className="custom-react-select mt-4">
                                        <ReactSelect
                                          components={{
                                            ValueContainer:
                                              CustomValueContainer,
                                          }}
                                          styles={{
                                            valueContainer: (
                                              provided,
                                              state
                                            ) => ({
                                              ...provided,
                                              overflow: "visible",
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              position: "absolute",
                                              top:
                                                state.hasValue ||
                                                  state.selectProps.inputValue
                                                  ? -15
                                                  : "7px",
                                              transition:
                                                "top 0.1s, font-size 0.1s",

                                              transform:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                "scale(0.85)",
                                              opacity:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                1,
                                            }),
                                          }}
                                          classNamePrefix="react-select"
                                          options={reportType}
                                          value={reportType.filter((item) => {
                                            return (
                                              item.value ===
                                              formData.electron_printType1
                                            );
                                          })}
                                          onChange={(e) =>
                                            handleSelect(
                                              e,
                                              "electron_printType1"
                                            )
                                          }
                                          placeholder={"Type"}
                                        />
                                      </Form.Group>
                                      <Form.Group className="custom-react-select mt-4">
                                        <ReactSelect
                                          components={{
                                            ValueContainer:
                                              CustomValueContainer,
                                          }}
                                          styles={{
                                            valueContainer: (
                                              provided,
                                              state
                                            ) => ({
                                              ...provided,
                                              overflow: "visible",
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              position: "absolute",
                                              top:
                                                state.hasValue ||
                                                  state.selectProps.inputValue
                                                  ? -15
                                                  : "7px",
                                              transition:
                                                "top 0.1s, font-size 0.1s",

                                              transform:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                "scale(0.85)",
                                              opacity:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                1,
                                            }),
                                          }}
                                          classNamePrefix="react-select"
                                          options={reportOtherType}
                                          value={reportOtherType.filter(
                                            (item) => {
                                              return (
                                                item.value ===
                                                formData.electron_printType2
                                              );
                                            }
                                          )}
                                          onChange={(e) =>
                                            handleSelect(
                                              e,
                                              "electron_printType2"
                                            )
                                          }
                                          placeholder={"Type"}
                                        />
                                      </Form.Group>
                                      <Col xs={12}>
                                        <Form.Group className="custom-react-select mt-4 mb-4">
                                          <ReactSelect
                                            components={{
                                              ValueContainer:
                                                CustomValueContainer,
                                            }}
                                            styles={{
                                              valueContainer: (
                                                provided,
                                                state
                                              ) => ({
                                                ...provided,
                                                overflow: "visible",
                                              }),
                                              placeholder: (
                                                provided,
                                                state
                                              ) => ({
                                                ...provided,
                                                position: "absolute",
                                                top:
                                                  state.hasValue ||
                                                    state.selectProps.inputValue
                                                    ? -15
                                                    : "7px",
                                                transition:
                                                  "top 0.1s, font-size 0.1s",

                                                transform:
                                                  (state.hasValue ||
                                                    state.selectProps
                                                      .inputValue) &&
                                                  "scale(0.85)",
                                                opacity:
                                                  (state.hasValue ||
                                                    state.selectProps
                                                      .inputValue) &&
                                                  1,
                                              }),
                                            }}
                                            value={printers.filter(
                                              (item) =>
                                                item.value ===
                                                formData.report_printer
                                            )}
                                            options={printers && printers}
                                            onChange={(e) =>
                                              handleSelect(e, "report_printer")
                                            }
                                            classNamePrefix="react-select"
                                            placeholder={"Printers"}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </>
                                  )}

                                  {(formData.connectMethod === "wp" ||
                                    formData.connectMethod === "ep") && (
                                      <>
                                        <Button
                                          title="Test"
                                          variant="success refresh-btn ms-2"
                                          onClick={TestPrint}
                                        >
                                          Test
                                        </Button>
                                        <Button
                                          title="Print QR"
                                          variant="success refresh-btn ms-2"
                                        >
                                          Print QR
                                        </Button>
                                      </>
                                    )}
                                </Accordion.Body>
                              </Accordion.Item>
                              {(formData.connectMethod === "wp" ||
                                formData.connectMethod === "ep") && (
                                  <Accordion.Item>
                                    <Accordion.Header>
                                      Cashdraw, Cutter &amp; Page Feed
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="d-flex align-items-center mb-4">
                                        <Form.Check
                                          className="custom-checkbox"
                                          type="checkbox"
                                        >
                                          <FormCheck.Input
                                            id="cash_draw_connected"
                                            name="cash_draw_connected"
                                            checked={formData.cash_draw_connected}
                                            onChange={(e) => handleChange(e)}
                                            className="me-2 custom-checkbox-bg"
                                          />
                                          <FormCheck.Label
                                            htmlFor="cash_draw_connected"
                                            className="mb-0"
                                          >
                                            Cash Draw Connected
                                          </FormCheck.Label>
                                        </Form.Check>
                                        <Button
                                          title="Test"
                                          onClick={TestCashdraw}
                                          variant="success refresh-btn ms-2"
                                        >
                                          Test
                                        </Button>
                                      </div>
                                      {
                                        <>
                                          <Form.Group className="custom-react-select">
                                            <ReactSelect
                                              components={{
                                                ValueContainer:
                                                  CustomValueContainer,
                                              }}
                                              styles={{
                                                valueContainer: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  overflow: "visible",
                                                }),
                                                placeholder: (
                                                  provided,
                                                  state
                                                ) => ({
                                                  ...provided,
                                                  position: "absolute",
                                                  top:
                                                    state.hasValue ||
                                                      state.selectProps.inputValue
                                                      ? -15
                                                      : "7px",
                                                  transition:
                                                    "top 0.1s, font-size 0.1s",

                                                  transform:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    "scale(0.85)",
                                                  opacity:
                                                    (state.hasValue ||
                                                      state.selectProps
                                                        .inputValue) &&
                                                    1,
                                                }),
                                              }}
                                              classNamePrefix="react-select"
                                              options={cutterCommand}
                                              value={cutterCommand.filter(
                                                (item) => {
                                                  return (
                                                    item.value ===
                                                    formData.cutter_commond
                                                  );
                                                }
                                              )}
                                              onChange={(e) =>
                                                handleSelect(e, "cutter_commond")
                                              }
                                              placeholder={"Cutter Command"}
                                            />
                                          </Form.Group>
                                          <Form.Group className="form-floating mt-4">
                                            <FormField
                                              label="Page Feed"
                                              type="number"
                                              name="pageFeed"
                                              value={formData.pageFeed}
                                              onChange={(e) =>
                                                handleInputChange(e)
                                              }
                                              placeholder="4"
                                            />
                                          </Form.Group>
                                        </>
                                      }
                                    </Accordion.Body>
                                  </Accordion.Item>
                                )}
                              <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                  Format &amp; Layout
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Form.Group className="custom-react-select">
                                    <ReactSelect
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      styles={{
                                        valueContainer: (provided, state) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue ||
                                              state.selectProps.inputValue
                                              ? -15
                                              : "7px",
                                          transition:
                                            "top 0.1s, font-size 0.1s",

                                          transform:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            "scale(0.85)",
                                          opacity:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            1,
                                        }),
                                      }}
                                      classNamePrefix="react-select"
                                      options={ESCPReciptMethod}
                                      value={ESCPReciptMethod.filter((item) => {
                                        return (
                                          item.value ===
                                          formData.escp_receiptMode
                                        );
                                      })}
                                      onChange={(e) =>
                                        handleSelect(e, "escp_receiptMode")
                                      }
                                      placeholder={"ESCP Receipt Mode"}
                                    />
                                  </Form.Group>
                                  {formData.escp_receiptMode === "text" && (
                                    <>
                                      <Form.Group className="custom-react-select mt-4">
                                        <ReactSelect
                                          components={{
                                            ValueContainer:
                                              CustomValueContainer,
                                          }}
                                          styles={{
                                            valueContainer: (
                                              provided,
                                              state
                                            ) => ({
                                              ...provided,
                                              overflow: "visible",
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              position: "absolute",
                                              top:
                                                state.hasValue ||
                                                  state.selectProps.inputValue
                                                  ? -15
                                                  : "7px",
                                              transition:
                                                "top 0.1s, font-size 0.1s",

                                              transform:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                "scale(0.85)",
                                              opacity:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                1,
                                            }),
                                          }}
                                          classNamePrefix="react-select"
                                          options={Langauge}
                                          value={Langauge.filter((item) => {
                                            return (
                                              item.value === formData.langauge
                                            );
                                          })}
                                          onChange={(e) =>
                                            handleSelect(e, "langauge")
                                          }
                                          placeholder={"Language"}
                                        />
                                      </Form.Group>
                                      <Form.Group className="custom-react-select mt-4">
                                        <ReactSelect
                                          components={{
                                            ValueContainer:
                                              CustomValueContainer,
                                          }}
                                          styles={{
                                            valueContainer: (
                                              provided,
                                              state
                                            ) => ({
                                              ...provided,
                                              overflow: "visible",
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              position: "absolute",
                                              top:
                                                state.hasValue ||
                                                  state.selectProps.inputValue
                                                  ? -15
                                                  : "7px",
                                              transition:
                                                "top 0.1s, font-size 0.1s",

                                              transform:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                "scale(0.85)",
                                              opacity:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                1,
                                            }),
                                          }}
                                          classNamePrefix="react-select"
                                          options={Orientation}
                                          value={Orientation.filter((item) => {
                                            return (
                                              item.value ===
                                              formData.orientation
                                            );
                                          })}
                                          onChange={(e) =>
                                            handleSelect(e, "orientation")
                                          }
                                          placeholder={"Orientation"}
                                        />
                                      </Form.Group>
                                      <Form.Group className="custom-react-select mt-4">
                                        <ReactSelect
                                          components={{
                                            ValueContainer:
                                              CustomValueContainer,
                                          }}
                                          styles={{
                                            valueContainer: (
                                              provided,
                                              state
                                            ) => ({
                                              ...provided,
                                              overflow: "visible",
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              position: "absolute",
                                              top:
                                                state.hasValue ||
                                                  state.selectProps.inputValue
                                                  ? -15
                                                  : "7px",
                                              transition:
                                                "top 0.1s, font-size 0.1s",

                                              transform:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                "scale(0.85)",
                                              opacity:
                                                (state.hasValue ||
                                                  state.selectProps
                                                    .inputValue) &&
                                                1,
                                            }),
                                          }}
                                          classNamePrefix="react-select"
                                          options={AlternateCharset_Codepage}
                                          value={AlternateCharset_Codepage.filter(
                                            (item) => {
                                              return (
                                                item.value ===
                                                formData.alter_char_code
                                              );
                                            }
                                          )}
                                          onChange={(e) =>
                                            handleSelect(e, "alter_char_code")
                                          }
                                          placeholder={
                                            "Alternate Charset & Codepage"
                                          }
                                        />
                                      </Form.Group>
                                      <Form.Group className="form-floating mt-4">
                                        <FormField
                                          label="Alternate Charset & Codepage"
                                          type="number"
                                          name="alter_char_code_number"
                                          value={
                                            formData.alter_char_code_number
                                          }
                                          onChange={(e) => handleInputChange(e)}
                                          placeholder="4"
                                        />
                                      </Form.Group>
                                      <Form.Check
                                        className="custom-checkbox mt-4"
                                        type="checkbox"
                                      >
                                        <FormCheck.Input
                                          id="override_currency_character"
                                          name="override_currency_character"
                                          checked={
                                            formData.override_currency_character
                                          }
                                          onChange={(e) => handleChange(e)}
                                          className="me-2 custom-checkbox-bg"
                                        />
                                        <FormCheck.Label
                                          htmlFor="override_currency_character"
                                          className="mb-0"
                                        >
                                          Override Currency Character
                                        </FormCheck.Label>
                                      </Form.Check>
                                      <Form.Group className="form-floating mt-4">
                                        <FormField
                                          label="Currency Codepage"
                                          type="number"
                                          name="currency_codepage"
                                          value={formData.currency_codepage}
                                          onChange={(e) => handleInputChange(e)}
                                          placeholder="4"
                                        />
                                      </Form.Group>
                                      <Form.Group className="form-floating mt-4">
                                        <FormField
                                          label="Currency Codes"
                                          type="number"
                                          name="currency_codes"
                                          value={formData.currency_codes}
                                          onChange={(e) => handleInputChange(e)}
                                          placeholder="4"
                                        />
                                      </Form.Group>
                                      <Form.Group className="form-floating mt-4">
                                        <FormField
                                          label="Sheet Width"
                                          type="number"
                                          name="sheet_width"
                                          value={formData.sheet_width}
                                          onChange={(e) => handleInputChange(e)}
                                          placeholder="4"
                                        />
                                      </Form.Group>
                                    </>
                                  )}
                                  {formData.escp_receiptMode === "bitmap" && (
                                    <Form.Group className="custom-react-select mt-4">
                                      <ReactSelect
                                        components={{
                                          ValueContainer: CustomValueContainer,
                                        }}
                                        styles={{
                                          valueContainer: (
                                            provided,
                                            state
                                          ) => ({
                                            ...provided,
                                            overflow: "visible",
                                          }),
                                          placeholder: (provided, state) => ({
                                            ...provided,
                                            position: "absolute",
                                            top:
                                              state.hasValue ||
                                                state.selectProps.inputValue
                                                ? -15
                                                : "7px",
                                            transition:
                                              "top 0.1s, font-size 0.1s",

                                            transform:
                                              (state.hasValue ||
                                                state.selectProps.inputValue) &&
                                              "scale(0.85)",
                                            opacity:
                                              (state.hasValue ||
                                                state.selectProps.inputValue) &&
                                              1,
                                          }),
                                        }}
                                        classNamePrefix="react-select"
                                        options={Receipt_Template}
                                        value={Receipt_Template.filter(
                                          (item) => {
                                            return (
                                              item.value ===
                                              formData.receiptTemplate
                                            );
                                          }
                                        )}
                                        onChange={(e) =>
                                          handleSelect(e, "receiptTemplate")
                                        }
                                        placeholder={"Receipt Template"}
                                      />
                                    </Form.Group>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Col>
                          <Col md="12" lg="4">
                            <h2 className="mb-4">Report Printing</h2>


                            <Accordion defaultActiveKey="0" className="mt-4">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  Connection
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Form.Group className="custom-react-select">
                                    <ReactSelect
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      styles={{
                                        valueContainer: (provided, state) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue ||
                                              state.selectProps.inputValue
                                              ? -15
                                              : "7px",
                                          transition: "top 0.1s, font-size 0.1s",

                                          transform:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            "scale(0.85)",
                                          opacity:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            1,
                                        }),
                                      }}
                                      classNamePrefix="react-select"
                                      options={connMethod}
                                      value={connMethod.filter((item) => {
                                        return item.value === formData.reportMethod;
                                      })}
                                      onChange={(e) =>
                                        handleSelect(e, "reportMethod")
                                      }
                                      placeholder={"Method"}
                                    />
                                  </Form.Group>
                                  {formData.reportMethod === "ep" && (
                                    <>
                                      <Form.Group className="custom-react-select mt-4">
                                        <ReactSelect
                                          components={{
                                            ValueContainer: CustomValueContainer,
                                          }}
                                          styles={{
                                            valueContainer: (provided, state) => ({
                                              ...provided,
                                              overflow: "visible",
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              position: "absolute",
                                              top:
                                                state.hasValue ||
                                                  state.selectProps.inputValue
                                                  ? -15
                                                  : "7px",
                                              transition: "top 0.1s, font-size 0.1s",

                                              transform:
                                                (state.hasValue ||
                                                  state.selectProps.inputValue) &&
                                                "scale(0.85)",
                                              opacity:
                                                (state.hasValue ||
                                                  state.selectProps.inputValue) &&
                                                1,
                                            }),
                                          }}
                                          classNamePrefix="react-select"
                                          options={reportType}
                                          value={reportType.filter((item) => {
                                            return (
                                              item.value === formData.reportType1
                                            );
                                          })}
                                          onChange={(e) =>
                                            handleSelect(e, "reportType1")
                                          }
                                          placeholder={"Type"}
                                        />
                                      </Form.Group>
                                      <Form.Group className="custom-react-select mt-4">
                                        <ReactSelect
                                          components={{
                                            ValueContainer: CustomValueContainer,
                                          }}
                                          styles={{
                                            valueContainer: (provided, state) => ({
                                              ...provided,
                                              overflow: "visible",
                                            }),
                                            placeholder: (provided, state) => ({
                                              ...provided,
                                              position: "absolute",
                                              top:
                                                state.hasValue ||
                                                  state.selectProps.inputValue
                                                  ? -15
                                                  : "7px",
                                              transition: "top 0.1s, font-size 0.1s",

                                              transform:
                                                (state.hasValue ||
                                                  state.selectProps.inputValue) &&
                                                "scale(0.85)",
                                              opacity:
                                                (state.hasValue ||
                                                  state.selectProps.inputValue) &&
                                                1,
                                            }),
                                          }}
                                          classNamePrefix="react-select"
                                          options={reportOtherType}
                                          value={reportOtherType.filter((item) => {
                                            return (
                                              item.value === formData.reportType2
                                            );
                                          })}
                                          onChange={(e) =>
                                            handleSelect(e, "reportType2")
                                          }
                                          placeholder={"Type"}
                                        />
                                      </Form.Group>
                                    </>
                                  )}
                                  {(formData.reportMethod === "wp" ||
                                    formData.reportMethod === "ep") && (
                                      <>
                                        <div className="d-flex align-items-end">
                                          {/* <Form.Group className="form-floating mt-4">
                                  <FormField
                                    label="Printer"
                                    type="number"
                                    name="report_printer"
                                    value={formData.report_printer}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder="4"
                                  />
                                </Form.Group> */}
                                          <Col xs={12} lg={10}>
                                            <Form.Group className="custom-react-select mt-4">
                                              <ReactSelect
                                                components={{
                                                  ValueContainer: CustomValueContainer,
                                                }}
                                                styles={{
                                                  valueContainer: (
                                                    provided,
                                                    state
                                                  ) => ({
                                                    ...provided,
                                                    overflow: "visible",
                                                  }),
                                                  placeholder: (provided, state) => ({
                                                    ...provided,
                                                    position: "absolute",
                                                    top:
                                                      state.hasValue ||
                                                        state.selectProps.inputValue
                                                        ? -15
                                                        : "7px",
                                                    transition:
                                                      "top 0.1s, font-size 0.1s",

                                                    transform:
                                                      (state.hasValue ||
                                                        state.selectProps.inputValue) &&
                                                      "scale(0.85)",
                                                    opacity:
                                                      (state.hasValue ||
                                                        state.selectProps.inputValue) &&
                                                      1,
                                                  }),
                                                }}
                                                value={printers.filter(
                                                  (item) =>
                                                    item.value ===
                                                    formData.report_printer
                                                )}
                                                options={printers && printers}
                                                onChange={(e) =>
                                                  handleSelect(e, "report_printer")
                                                }
                                                classNamePrefix="react-select"
                                                placeholder={"Printers"}
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col xs={12} lg={2}>
                                            <Button
                                              title="Refresh"
                                              variant="yellow refresh-btn ms-2"
                                            >
                                              <FontAwesomeIcon icon={faSync} />
                                            </Button>
                                          </Col>
                                        </div>
                                      </>
                                    )}
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                  Label Printer Settings
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Form.Check
                                    className="custom-checkbox"
                                    type="checkbox"
                                  >
                                    <FormCheck.Input
                                      id="label_printing_enabled"
                                      name="label_printing_enabled"
                                      checked={formData.label_printing_enabled}
                                      onChange={(e) => handleChange(e)}
                                      className="me-2 custom-checkbox-bg"
                                    />
                                    <FormCheck.Label
                                      htmlFor="label_printing_enabled"
                                      className="mb-0"
                                    >
                                      Label Printing Enabled
                                    </FormCheck.Label>
                                  </Form.Check>
                                  {formData.label_printing_enabled && (
                                    <Form.Check
                                      className="custom-checkbox mt-3"
                                      type="checkbox"
                                    >
                                      <FormCheck.Input
                                        id="remote_printing_enabled"
                                        name="remote_printing_enabled"
                                        checked={
                                          formData.remote_printing_enabled
                                        }
                                        onChange={(e) => handleChange(e)}
                                        className="me-2 custom-checkbox-bg"
                                      />
                                      <FormCheck.Label
                                        htmlFor="remote_printing_enabled"
                                        className="mb-0"
                                      >
                                        Remote Printing Enabled
                                      </FormCheck.Label>
                                    </Form.Check>
                                  )}
                                  <Form.Group className="custom-react-select mt-4">
                                    <ReactSelect
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      styles={{
                                        valueContainer: (provided, state) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue ||
                                              state.selectProps.inputValue
                                              ? -15
                                              : "7px",
                                          transition:
                                            "top 0.1s, font-size 0.1s",

                                          transform:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            "scale(0.85)",
                                          opacity:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            1,
                                        }),
                                      }}
                                      classNamePrefix="react-select"
                                      options={labelPrintSetting}
                                      value={labelPrintSetting.filter(
                                        (item) =>
                                          item.value ===
                                          formData.label_print_setting
                                      )}
                                      onChange={(e) =>
                                        handleSelect(e, "label_print_setting")
                                      }
                                      placeholder={"Method"}
                                    />
                                  </Form.Group>
                                  {(formData.label_print_setting === "wp" ||
                                    formData.label_print_setting === "dymo" ||
                                    formData.label_print_setting ===
                                    "brother") && (
                                      <>
                                        <Form.Group className="custom-react-select mt-4">
                                          <ReactSelect
                                            components={{
                                              ValueContainer:
                                                CustomValueContainer,
                                            }}
                                            styles={{
                                              valueContainer: (
                                                provided,
                                                state
                                              ) => ({
                                                ...provided,
                                                overflow: "visible",
                                              }),
                                              placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top:
                                                  state.hasValue ||
                                                    state.selectProps.inputValue
                                                    ? -15
                                                    : "7px",
                                                transition:
                                                  "top 0.1s, font-size 0.1s",

                                                transform:
                                                  (state.hasValue ||
                                                    state.selectProps
                                                      .inputValue) &&
                                                  "scale(0.85)",
                                                opacity:
                                                  (state.hasValue ||
                                                    state.selectProps
                                                      .inputValue) &&
                                                  1,
                                              }),
                                            }}
                                            classNamePrefix="react-select"
                                            options={
                                              formData.label_print_setting ===
                                                "wp"
                                                ? web_Template
                                                : formData.label_print_setting ===
                                                  "dymo"
                                                  ? labelTemplates?.map((list) => {
                                                    return {
                                                      ...list,
                                                      value: list.id,
                                                      label: list.name,
                                                    };
                                                  })
                                                  : connPort
                                            }
                                            value={
                                              formData.label_print_setting ===
                                                "wp"
                                                ? web_Template.filter(
                                                  (item) =>
                                                    item.value ===
                                                    formData.template
                                                )
                                                : formData.label_print_setting ===
                                                  "dymo"
                                                  ? labelTemplates
                                                    ?.map((item) => {
                                                      return {
                                                        ...item,
                                                        value: item.id,
                                                        label: item.name,
                                                      };
                                                    })
                                                    .filter((item) => {
                                                      return (
                                                        item.value ===
                                                        formData.template
                                                      );
                                                    })
                                                  : connPort.filter(
                                                    (item) =>
                                                      item.value ===
                                                      formData.template
                                                  )
                                            }
                                            onChange={(e) =>
                                              handleSelect(e, "template")
                                            }
                                            placeholder={"Template"}
                                          />
                                        </Form.Group>
                                        {formData.label_print_setting ===
                                          "wp" && (
                                            <>
                                              <Form.Group className="custom-react-select mt-4">
                                                <ReactSelect
                                                  components={{
                                                    ValueContainer:
                                                      CustomValueContainer,
                                                  }}
                                                  styles={{
                                                    valueContainer: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      overflow: "visible",
                                                    }),
                                                    placeholder: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      position: "absolute",
                                                      top:
                                                        state.hasValue ||
                                                          state.selectProps.inputValue
                                                          ? -15
                                                          : "7px",
                                                      transition:
                                                        "top 0.1s, font-size 0.1s",

                                                      transform:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        "scale(0.85)",
                                                      opacity:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        1,
                                                    }),
                                                  }}
                                                  classNamePrefix="react-select"
                                                  options={connType}
                                                  value={connType.filter(
                                                    (item) =>
                                                      item.value ===
                                                      formData.label_print_type
                                                  )}
                                                  onChange={(e) =>
                                                    handleSelect(
                                                      e,
                                                      "label_print_type"
                                                    )
                                                  }
                                                  placeholder={"Type"}
                                                />
                                              </Form.Group>
                                            </>
                                          )}
                                        {formData.label_print_type === "serial" &&
                                          formData.label_print_setting ===
                                          "wp" && (
                                            <>
                                              <div className="d-flex align-items-end">
                                                <Form.Group className="custom-react-select mt-4 w-100">
                                                  <ReactSelect
                                                    components={{
                                                      ValueContainer:
                                                        CustomValueContainer,
                                                    }}
                                                    styles={{
                                                      valueContainer: (
                                                        provided,
                                                        state
                                                      ) => ({
                                                        ...provided,
                                                        overflow: "visible",
                                                      }),
                                                      placeholder: (
                                                        provided,
                                                        state
                                                      ) => ({
                                                        ...provided,
                                                        position: "absolute",
                                                        top:
                                                          state.hasValue ||
                                                            state.selectProps
                                                              .inputValue
                                                            ? -15
                                                            : "7px",
                                                        transition:
                                                          "top 0.1s, font-size 0.1s",

                                                        transform:
                                                          (state.hasValue ||
                                                            state.selectProps
                                                              .inputValue) &&
                                                          "scale(0.85)",
                                                        opacity:
                                                          (state.hasValue ||
                                                            state.selectProps
                                                              .inputValue) &&
                                                          1,
                                                      }),
                                                    }}
                                                    classNamePrefix="react-select"
                                                    options={connPort}
                                                    value={connPort.filter(
                                                      (item) => {
                                                        return (
                                                          item.value ===
                                                          formData.label_connectPort
                                                        );
                                                      }
                                                    )}
                                                    onChange={(e) =>
                                                      handleSelect(
                                                        e,
                                                        "label_connectPort"
                                                      )
                                                    }
                                                    placeholder={"Port"}
                                                  />
                                                </Form.Group>
                                                <Button
                                                  title="Refresh"
                                                  variant="refresh-btn ms-2"
                                                  style={{ background: "blue" }}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faSync}
                                                  />
                                                </Button>
                                              </div>
                                              <Form.Group className="custom-react-select mt-4">
                                                <ReactSelect
                                                  components={{
                                                    ValueContainer:
                                                      CustomValueContainer,
                                                  }}
                                                  styles={{
                                                    valueContainer: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      overflow: "visible",
                                                    }),
                                                    placeholder: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      position: "absolute",
                                                      top:
                                                        state.hasValue ||
                                                          state.selectProps
                                                            .inputValue
                                                          ? -15
                                                          : "7px",
                                                      transition:
                                                        "top 0.1s, font-size 0.1s",

                                                      transform:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        "scale(0.85)",
                                                      opacity:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        1,
                                                    }),
                                                  }}
                                                  classNamePrefix="react-select"
                                                  options={connBaud}
                                                  value={connBaud.filter(
                                                    (item) => {
                                                      return (
                                                        item.value ===
                                                        formData.label_baud
                                                      );
                                                    }
                                                  )}
                                                  onChange={(e) =>
                                                    handleSelect(e, "label_baud")
                                                  }
                                                  placeholder={"Baud"}
                                                />
                                              </Form.Group>
                                              <Form.Group className="custom-react-select mt-4">
                                                <ReactSelect
                                                  components={{
                                                    ValueContainer:
                                                      CustomValueContainer,
                                                  }}
                                                  styles={{
                                                    valueContainer: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      overflow: "visible",
                                                    }),
                                                    placeholder: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      position: "absolute",
                                                      top:
                                                        state.hasValue ||
                                                          state.selectProps
                                                            .inputValue
                                                          ? -15
                                                          : "7px",
                                                      transition:
                                                        "top 0.1s, font-size 0.1s",

                                                      transform:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        "scale(0.85)",
                                                      opacity:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        1,
                                                    }),
                                                  }}
                                                  classNamePrefix="react-select"
                                                  options={DataBits}
                                                  value={DataBits.filter(
                                                    (item) => {
                                                      return (
                                                        item.value ===
                                                        formData.label_data_bits
                                                      );
                                                    }
                                                  )}
                                                  onChange={(e) =>
                                                    handleSelect(
                                                      e,
                                                      "label_data_bits"
                                                    )
                                                  }
                                                  placeholder={"Data Bits"}
                                                />
                                              </Form.Group>
                                              <Form.Group className="custom-react-select mt-4">
                                                <ReactSelect
                                                  components={{
                                                    ValueContainer:
                                                      CustomValueContainer,
                                                  }}
                                                  styles={{
                                                    valueContainer: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      overflow: "visible",
                                                    }),
                                                    placeholder: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      position: "absolute",
                                                      top:
                                                        state.hasValue ||
                                                          state.selectProps
                                                            .inputValue
                                                          ? -15
                                                          : "7px",
                                                      transition:
                                                        "top 0.1s, font-size 0.1s",

                                                      transform:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        "scale(0.85)",
                                                      opacity:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        1,
                                                    }),
                                                  }}
                                                  classNamePrefix="react-select"
                                                  options={StopBits}
                                                  value={StopBits.filter(
                                                    (item) => {
                                                      return (
                                                        item.value ===
                                                        formData.label_stop_bits
                                                      );
                                                    }
                                                  )}
                                                  onChange={(e) =>
                                                    handleSelect(
                                                      e,
                                                      "label_stop_bits"
                                                    )
                                                  }
                                                  placeholder={"Stop Bits"}
                                                />
                                              </Form.Group>
                                              <Form.Group className="custom-react-select mt-4">
                                                <ReactSelect
                                                  components={{
                                                    ValueContainer:
                                                      CustomValueContainer,
                                                  }}
                                                  styles={{
                                                    valueContainer: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      overflow: "visible",
                                                    }),
                                                    placeholder: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      position: "absolute",
                                                      top:
                                                        state.hasValue ||
                                                          state.selectProps
                                                            .inputValue
                                                          ? -15
                                                          : "7px",
                                                      transition:
                                                        "top 0.1s, font-size 0.1s",

                                                      transform:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        "scale(0.85)",
                                                      opacity:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        1,
                                                    }),
                                                  }}
                                                  classNamePrefix="react-select"
                                                  options={Parity}
                                                  value={Parity.filter((item) => {
                                                    return (
                                                      item.value ===
                                                      formData.label_parity
                                                    );
                                                  })}
                                                  onChange={(e) =>
                                                    handleSelect(
                                                      e,
                                                      "label_parity"
                                                    )
                                                  }
                                                  placeholder={"Parity"}
                                                />
                                              </Form.Group>
                                              <Form.Group className="custom-react-select mt-4">
                                                <ReactSelect
                                                  components={{
                                                    ValueContainer:
                                                      CustomValueContainer,
                                                  }}
                                                  styles={{
                                                    valueContainer: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      overflow: "visible",
                                                    }),
                                                    placeholder: (
                                                      provided,
                                                      state
                                                    ) => ({
                                                      ...provided,
                                                      position: "absolute",
                                                      top:
                                                        state.hasValue ||
                                                          state.selectProps
                                                            .inputValue
                                                          ? -15
                                                          : "7px",
                                                      transition:
                                                        "top 0.1s, font-size 0.1s",

                                                      transform:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        "scale(0.85)",
                                                      opacity:
                                                        (state.hasValue ||
                                                          state.selectProps
                                                            .inputValue) &&
                                                        1,
                                                    }),
                                                  }}
                                                  classNamePrefix="react-select"
                                                  options={FlowControl}
                                                  value={FlowControl.filter(
                                                    (item) => {
                                                      return (
                                                        item.value ===
                                                        formData.label_flow_control
                                                      );
                                                    }
                                                  )}
                                                  onChange={(e) =>
                                                    handleSelect(
                                                      e,
                                                      "label_flow_control"
                                                    )
                                                  }
                                                  placeholder={"Flow Control"}
                                                />
                                              </Form.Group>
                                            </>
                                          )}
                                        {(formData.label_print_type === "raw" ||
                                          formData.label_print_setting ===
                                          "dymo" ||
                                          formData.label_print_setting ===
                                          "brother") && (
                                            <>
                                              <div className="d-flex align-items-end">
                                                <Form.Group className="custom-react-select mt-4 w-100">
                                                  <ReactSelect
                                                    components={{
                                                      ValueContainer:
                                                        CustomValueContainer,
                                                    }}
                                                    styles={{
                                                      valueContainer: (
                                                        provided,
                                                        state
                                                      ) => ({
                                                        ...provided,
                                                        overflow: "visible",
                                                      }),
                                                      placeholder: (
                                                        provided,
                                                        state
                                                      ) => ({
                                                        ...provided,
                                                        position: "absolute",
                                                        top:
                                                          state.hasValue ||
                                                            state.selectProps
                                                              .inputValue
                                                            ? -15
                                                            : "7px",
                                                        transition:
                                                          "top 0.1s, font-size 0.1s",

                                                        transform:
                                                          (state.hasValue ||
                                                            state.selectProps
                                                              .inputValue) &&
                                                          "scale(0.85)",
                                                        opacity:
                                                          (state.hasValue ||
                                                            state.selectProps
                                                              .inputValue) &&
                                                          1,
                                                      }),
                                                    }}
                                                    classNamePrefix="react-select"
                                                    options={printers && printers}
                                                    value={printers.filter(
                                                      (item) => {
                                                        return (
                                                          item.value ===
                                                          formData.label_printer
                                                        );
                                                      }
                                                    )}
                                                    onChange={(e) =>
                                                      handleSelect(
                                                        e,
                                                        "label_printer"
                                                      )
                                                    }
                                                    placeholder={"Printer"}
                                                  />
                                                </Form.Group>
                                                <Button
                                                  title="Refresh"
                                                  variant="yellow refresh-btn ms-2"
                                                >
                                                  <FontAwesomeIcon icon={faSync} />
                                                </Button>
                                              </div>
                                            </>
                                          )}
                                        {formData.label_print_type === "tcp" &&
                                          formData.label_print_setting ===
                                          "wp" && (
                                            <>
                                              <Form.Group className="form-floating mt-4">
                                                <FormField
                                                  label="Printer IP"
                                                  type="text"
                                                  name="printerIp"
                                                  value={formData.label_printerIp}
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                  placeholder="192.168.1.100"
                                                />
                                              </Form.Group>
                                              <Form.Group className="form-floating mt-4">
                                                <FormField
                                                  label="Printer Port"
                                                  type="number"
                                                  name="printerPort"
                                                  value={
                                                    formData.label_printerPort
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange(e)
                                                  }
                                                  placeholder="9100"
                                                />
                                              </Form.Group>
                                            </>
                                          )}
                                      </>
                                    )}
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                  Pole Display
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Form.Group className="custom-react-select">
                                    <ReactSelect
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                      styles={{
                                        valueContainer: (provided, state) => ({
                                          ...provided,
                                          overflow: "visible",
                                        }),
                                        placeholder: (provided, state) => ({
                                          ...provided,
                                          position: "absolute",
                                          top:
                                            state.hasValue ||
                                              state.selectProps.inputValue
                                              ? -15
                                              : "7px",
                                          transition:
                                            "top 0.1s, font-size 0.1s",

                                          transform:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            "scale(0.85)",
                                          opacity:
                                            (state.hasValue ||
                                              state.selectProps.inputValue) &&
                                            1,
                                        }),
                                      }}
                                      classNamePrefix="react-select"
                                      options={Pole_Display}
                                      value={Pole_Display.filter((item) => {
                                        return (
                                          item.value === formData.pole_display
                                        );
                                      })}
                                      onChange={(e) =>
                                        handleSelect(e, "pole_display")
                                      }
                                      placeholder={"Method"}
                                    />
                                  </Form.Group>
                                  {formData.pole_display === "ep" && (
                                    <>
                                      <div className="d-flex align-items-end">
                                        <Form.Group className="custom-react-select mt-4 w-100">
                                          <ReactSelect
                                            components={{
                                              ValueContainer:
                                                CustomValueContainer,
                                            }}
                                            styles={{
                                              valueContainer: (
                                                provided,
                                                state
                                              ) => ({
                                                ...provided,
                                                overflow: "visible",
                                              }),
                                              placeholder: (
                                                provided,
                                                state
                                              ) => ({
                                                ...provided,
                                                position: "absolute",
                                                top:
                                                  state.hasValue ||
                                                    state.selectProps.inputValue
                                                    ? -15
                                                    : "7px",
                                                transition:
                                                  "top 0.1s, font-size 0.1s",

                                                transform:
                                                  (state.hasValue ||
                                                    state.selectProps
                                                      .inputValue) &&
                                                  "scale(0.85)",
                                                opacity:
                                                  (state.hasValue ||
                                                    state.selectProps
                                                      .inputValue) &&
                                                  1,
                                              }),
                                            }}
                                            classNamePrefix="react-select"
                                            options={connPort}
                                            value={connPort.filter((item) => {
                                              return (
                                                item.value ===
                                                formData.pole_printer
                                              );
                                            })}
                                            onChange={(e) =>
                                              handleSelect(e, "pole_printer")
                                            }
                                            placeholder={"Printer"}
                                          />
                                        </Form.Group>
                                        <Button
                                          title="Refresh"
                                          variant="yellow refresh-btn ms-2"
                                        >
                                          <FontAwesomeIcon icon={faSync} />
                                        </Button>
                                      </div>
                                      <Form.Group className="form-floating mt-4">
                                        <FormField
                                          label="Message"
                                          type="text"
                                          name="message"
                                          value={formData.message}
                                          onChange={(e) => handleInputChange(e)}
                                          placeholder="Welcome"
                                        />
                                      </Form.Group>
                                      <Form.Group className="form-floating mt-4">
                                        <FormField
                                          label="No Of Line On Pole"
                                          type="number"
                                          name="noLinePole"
                                          value={formData.noLinePole}
                                          onChange={(e) => handleInputChange(e)}
                                          placeholder="9100"
                                        />
                                      </Form.Group>
                                      <Form.Group className="form-floating mt-4">
                                        <FormField
                                          label="Characters Per Line"
                                          type="number"
                                          name="characterPerLine"
                                          value={formData.characterPerLine}
                                          onChange={(e) => handleInputChange(e)}
                                          placeholder="9100"
                                        />
                                      </Form.Group>
                                    </>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Col>
                          <Col md="12" lg="4" className="mt-4 mt-lg-0">
                            <h2 className="mb-4">
                              Print Service options (WebPrint/Android)
                            </h2>

                            <Form.Group className="form-floating mt-2">
                              <FormField
                                label="IP Address"
                                type="text"
                                name="ipAddress"
                                value={formData.ipAddress}
                                onChange={(e) => handleInputChange(e)}
                                placeholder="ipAddress"
                              />
                            </Form.Group>
                            <Form.Group className="form-floating mt-3">
                              <FormField
                                label="Port"
                                type="number"
                                name="web_android_port"
                                value={formData.web_android_port}
                                onChange={(e) => handleInputChange(e)}
                                placeholder="Port"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Actions" className="pt-2 stats">
                    <Card className="custom-card">
                      <Card.Header style={{ borderBottom: "135px" }}>
                        <h2 className="mb-0">Actions</h2>
                      </Card.Header>
                      <Card.Body>
                        <div className="d-flex justify-content-between cta-btn-wrapper">
                          <Button
                            title="Reset Local Config"
                            variant="grey-custom-shade"
                            onClick={(e) =>
                              handleClickEvent(
                                e,
                                RESETLOCALCONFIGDATA,
                                "Reset Local Config"
                              )
                            }
                          >
                            Reset Local Config
                          </Button>
                          <Button
                            variant="grey-custom-shade"
                            title="Clear Local Data"
                            onClick={(e) =>
                              handleClickEvent(
                                e,
                                CLEARLOCALCONFIGDATA,
                                "Clear Local Data"
                              )
                            }
                          >
                            Clear Local Data
                          </Button>
                          <Button
                            variant="grey-custom-shade"
                            title="Refresh Remote Data"
                            onClick={(e) =>
                              handleClickEvent(
                                e,
                                REFRESHREMOTEDATA,
                                "Refresh Remote Data"
                              )
                            }
                          >
                            Refresh Remote Data
                          </Button>
                          <Button
                            variant="grey-custom-shade"
                            title="Remove Device Registration"
                            onClick={(e) =>
                              handleClickEvent(
                                e,
                                REMOVEDEVICEREGISTRATION,
                                "Remove Device Registration"
                              )
                            }
                          >
                            Remove Device Registration
                          </Button>
                          <Button
                            variant="grey-custom-shade"
                            title="Retry Failed Transactions"
                            onClick={(e) =>
                              handleClickEvent(
                                e,
                                RETRYFAILDTRANSTION,
                                "Retry Failed Transactions"
                              )
                            }
                          >
                            Retry Failed Transactions
                          </Button>
                          <Button
                            variant="grey-custom-shade"
                            title="Restart Pax Machine"
                            onClick={(e) =>
                              handleClickEvent(
                                e,
                                RESETPAXMACHINE,
                                "Restart Pax Machine"
                              )
                            }
                          >
                            Restart Pax Machine
                          </Button>
                          <Button
                            variant="grey-custom-shade"
                            title="Close Batch PAX Machine"
                            onClick={(e) =>
                              handleClickEvent(
                                e,
                                CLOSEPAXMACHINE,
                                "Close Batch PAX Machine"
                              )
                            }
                          >
                            Close Batch PAX Machine
                          </Button>
                          <Button
                            variant="grey-custom-shade"
                            title="Check For Updates"
                            onClick={(e) =>
                              handleClickEvent(
                                e,
                                CHECKFORUPDATES,
                                "Check For Updates"
                              )
                            }
                          >
                            Check For Updates
                          </Button>
                          <Button
                            variant="grey-custom-shade"
                            onClick={() => incrementCounter()}
                            title="Expand Window"
                          >
                            <Image
                              className="me-2"
                              src={props?.dynamicImages?.ExpanIcon}
                              alt="list Icon"
                            />
                            Expand Window
                          </Button>
                          <div className="mode">
                            <span>Change Mode ( Light Mode | Dark Mode )</span>
                            <ThemeContext.Consumer>
                              {({ changeTheme }) => (
                                <ToggleDark
                                  toggleDark={() => {
                                    setDarkMode(!darkMode);
                                    Localstorage.setItem(
                                      "dark_mode",
                                      !darkMode
                                    );
                                    dispatch(
                                      changeMode(!darkMode, CHANGE_MODE)
                                    );
                                    changeTheme(
                                      darkMode ? themes.dark : themes.light
                                    );
                                  }}
                                />
                              )}
                            </ThemeContext.Consumer>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
          {/* For show the button title and confirmation according to button click */}
          <CustomModal
            open={openErrorMsg}
            handleClose={handleClosepopUp}
            dialogClassName={`custom-modal confirmation ${status === 1 ? `success-popup` : `error-popup align-btn`
              }`}
            title={title}
            content={
              <>
                <div className="text-center">
                  {openErrorMsg && title != "Batch" && (
                    <Image src={Cancel} alt="error icon" className="mb-3" />
                  )}
                </div>
                <h1
                  className={`mb-3 ${openErrorMsg ? "justify-content-center" : ""
                    }`}
                >
                  {errorMsg}
                </h1>
              </>
            }
            action={
              <>
                {status != 2 && (
                  <Button
                    type="button"
                    onClick={(e) => handleConfirm(e, errorMsg, status)}
                    // variant={`${status === 1 ? `success` : `danger`} px-5 `}
                    variant="primary px-5"
                    title="Yes"
                  >
                    Yes
                  </Button>
                )}
                <Button
                  type="button"
                  title={status == 2 ? "Ok" : "No"}
                  // variant="light px-5"
                  variant="outline-blackcustom px-5"
                  onClick={handleClosepopUp}
                >
                  {status == 2 ? "Ok" : "No"}
                </Button>
              </>
            }
          />

          {/* For pax*/}
          <CustomModal
            open={paxMsg}
            handleClose={handleClosepopUp}
            dialogClassName={`custom-modal confirmation align-btn ${status === 1 ? `success-popup` : `error-popup`
              }`}
            title={title}
            content={
              <>
                <div className="text-center">
                  {showIcon && (
                    <Image src={Cancel} alt="error icon" className="mb-3" />
                  )}
                </div>
                <h1
                  className={`mb-3 ${showIcon ? "justify-content-center" : ""}`}
                >
                  {errorMsg}
                </h1>
              </>
            }
            action={
              <>
                {/* {
                  <Button
                    type="button"
                    onClick={(e) => handleClosepopUp()}
                    variant={`${
                      status === 1 ? `success` : `danger`
                    } px-5 m-auto`}
                  >
                    Ok
                  </Button>
                } */}
                {showIcon ? (
                  <>
                    <Button
                      type="button"
                      onClick={(e) => {
                        setPaxMsg(false);
                        handleLoader(true, CLOSEPAXMACHINE);
                        closeBatch();
                      }}
                      variant="primary"
                      title="Yes"
                    >
                      Yes
                    </Button>
                    <Button
                      type="button"
                      onClick={(e) => handleClosepopUp()}
                      variant="outline-blackcustom px-5"
                      title="No"
                    >
                      No
                    </Button>
                  </>
                ) : (
                  <Button
                    type="button"
                    onClick={(e) => handleClosepopUp()}
                    variant="light px-5 m-auto"
                    title="Ok"
                  >
                    Ok
                  </Button>
                )}
              </>
            }
          />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    loading: state.auth.showloading,
    image: state.setting.images,
    devices: state.inventory.devices,
    posSettings: state.inventory.posSetting.data,
    labeltemplates: state.saleItem.labeltemplates,
  };
};
export default connect(mapStateToProps)(Settings);
