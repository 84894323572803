import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import {
  Col,
  Container,
  Row,
  ListGroup,
  Badge,
  Image,
  Button,
} from "@themesberg/react-bootstrap";
import BootstrapTable2 from "../../components/BootsrapTable2";
import ThankYou from "./thankyou";
import {
  CustomerBusinessName,
  currencyFormat,
} from "../../../app/helpers/custom";
import CustomScroll from "react-custom-scroll";
import SliderMain from "./slider";
import FormField from "../formField";
import CustomNumpad from "../customNumpad";
import {
  CUSTOMERDATA,
  CUSTOMERUPDATE,
  SLIDESHOWFORPOSSCREEN,
  UPLOADFIXEDIMAGE,
  UPLOADSTATICIMAGEFORNUMBER,
} from "../../../app/helpers/constant";
import crossIcon from "../../../assets/images/cross-screen.svg";
import defaultImage from "../../../assets/images/shop-with-us.svg";
import { editActionData } from "app/actions/saleItemAction";
import { useDispatch } from "react-redux";
import Localstorage from "app/storage/Localstorage";
import PropTypes from 'prop-types';

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const CustomerDetail = (props) => {
  const dispatch = useDispatch();
  const [custNumber, setCustNumber] = useState("");
  const [welcomeData, setWelcomeData] = useState(props.posSettingData);
  const [msg, setMsg] = useState("");
  const [width, height] = useWindowSize();
  const [customerDetail, setCustomerDetails] = useState(props?.customer);
  const [thank, setThank] = useState(true);
  const [orderItem, setOrderItem] = useState([]);
  const tableRef = useRef(null); // Ref for the table element
  const [details, setDetails] = useState(props?.customerScreen);
  const [nonCashAdjust, setNonCashAdj] = useState(
    props?.nonCashAdjust.nonCashAdj
  );
  const [grandTotal, setGrandTotal] = useState(props?.nonCashAdjust.Total);
  const [stringData, setStringData] = useState("");
  const [smsAndEmailPromotionPopup, setSmaAndEmailPromotionPopup] =
    useState(false);
  const [ViewType, setViewType] = useState(
    props?.posSetting?.CUSTOMER?.pos_view_display_type || 1
  );
  const [tenderTotal, setTenderTotal] = useState(0);
  const { showPriceCusScreen, showTotalCusScreen } =
    props?.posSideSetting;

  useEffect(() => {
    if (msg !== "") {
      setTimeout(() => {
        setMsg("");
        setCustNumber("");
      }, 4000);
    }
  }, [msg]);

  useEffect(() => {
    if (props.posSettingData !== undefined) {
      setWelcomeData(props.posSettingData);
    }
  }, [props.posSettingData]);

  useEffect(() => {
    setViewType(props?.posSetting?.CUSTOMER?.pos_view_display_type || 1);
  }, [props?.posSetting?.CUSTOMER?.pos_view_display_type]);
  // const ViewType = props?.posSetting?.CUSTOMER?.pos_view_display_type;

  useEffect(() => {
    if (ViewType == 4 && props?.hideCustomerNumPad && !thank) {
      setViewType(3);
    }
  }, [ViewType, props?.hideCustomerNumPad, thank]);

  useEffect(() => {
    setThank(props?.posSetting?.CUSTOMER?.customer_rating === "1");
  }, [props?.posSetting?.CUSTOMER?.customer_rating]);

  const thankYouHide = (value) => {
    setThank(value);
  };

  const getValue = (data) => {
    if (msg === "Enter Valid Number!") {
      setMsg("");
    }
    let mobileNumber = "";
    if (data.value === "") {
      mobileNumber = custNumber.slice(0, -1);
      setCustNumber(mobileNumber);
      return;
    } else if (data.value === "<-") {
      if (custNumber !== "") {
        mobileNumber = custNumber.slice(0, -1);
      }
    } else if (data.value === "Enter") {
      if (custNumber.length) {
        setMsg("Enter Valid Number!");
      }
    } else {
      mobileNumber = data.value
        ? custNumber !== ""
          ? custNumber + data.value
          : data.value
        : "";
    }
    if (mobileNumber.length <= 10) {
      if (mobileNumber.length === 10) {
        setMsg("Thank You!");
        localStorage.setItem("customerScreenMobileNumber", mobileNumber);
        setCustNumber("");
      } else {
        setCustNumber(mobileNumber);
      }
    }
  };

  useEffect(() => {
    setDetails(props?.customerScreen);
  }, [props.customerScreen]);

  useEffect(() => {
    setStringData(
      addStringData(props?.customerScreen[2]?.nonCashEnable?.customerScreenTaxt)
    );
  }, [grandTotal, nonCashAdjust]);

  useEffect(() => {
    setCustomerDetails(props.customer);
    setSmaAndEmailPromotionPopup(
      Object.keys(props.customer).length > 0 &&
        !props?.customer?.sms_promotion &&
        !props?.customer?.promotion_subscribed &&
        props?.posSetting?.CUSTOMER?.smsEmail_promotion_subscription === "1"
    );
  }, [props.customer]);

  useEffect(() => {
    setNonCashAdj(
      props?.nonCashAdjust.nonCashAdj !== undefined
        ? props?.nonCashAdjust.nonCashAdj
        : 0
    );
    setGrandTotal(
      props?.nonCashAdjust.Total !== undefined ? props?.nonCashAdjust.Total : 0
    );
  }, [props?.nonCashAdjust]);

  const addStringData = (str) => {
    if (str !== undefined && str !== "") {
      return str
        .replace(
          "${{amount}}",
          currencyFormat(parseFloat(nonCashAdjust).toFixed(2))
        )
        .replace(
          "${{totalamount}}",
          currencyFormat(
            parseFloat(
              parseFloat(nonCashAdjust) + parseFloat(grandTotal)
            ).toFixed(2)
          )
        );
    } else {
      return "-";
    }
  };

  // Function to add a new row to the orderItem state

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = tableRef.current.scrollHeight;
    }
  }, [orderItem]);

  useEffect(() => {
    const updatedOrderItem = updateOrderList(props?.OrderData, props?.payments);
    setOrderItem(updatedOrderItem);
  }, [
    props.OrderData,
    props?.payments,
    props?.nonCashAdjust?.discountVal,
  ]);

  const updateOrderList = (list, payments) => {
    let arr = [];
    for (let item of list) {
      arr.push({
        id: item.id,
        Quantity: item.qty,
        Name: item.manualname,
        Price: (!isNaN(+item.showPrice) && item.showPrice !== "") ? parseFloat(item.showPrice).toFixed(2) : 0,
        Total: !isNaN(+item.showTotal) ? parseFloat(item.showTotal).toFixed(2) : 0,
        additionalFee: item.additionalFee,
        bottledeposit: item.bottleDeposit,
        taxTotal: item.taxTotal,
      });
      if (item?.promotion) {
        arr.push({
          Name: (
            <span style={{ color: "green" }}>
              {item?.showText}
            </span>
          ),
          Total : (
            <span style={{ color: "green" }}>
              {item?.showTotalText}
            </span>
          )
        });
      }

      if (item?.promotion2) {
        arr.push({
          Name: (
            <span style={{ color: "green" }}>
              {item?.showText2}
            </span>
          ),
          Total : (
            <span style={{ color: "green" }}>
              {item?.showTotalText2}
            </span>
          )
        });
      }

      if (item?.totalDiscount > 0) {
        const text = item?.discountValue;
        arr.push({
          Name: (
            <span style={{ color: "green" }}>
              DISCOUNT ({parseFloat(text).toFixed(2)}%)
            </span>
          ),
          Total : (
            <span style={{ color: "green" }}>
              {currencyFormat(parseFloat(item?.totalDiscount).toFixed(2))}
            </span>
          )
        });
      }
    }
    if (list.length) {
      let totalTen = 0;
      for (let payment of payments) {
        totalTen = totalTen + +payment.tender;
        arr.push({
          Name: (
            <span style={{ color: "green" }}> Payment {payment.method}</span>
          ),
          Total: <span style={{ color: "green" }}> {payment.tender}</span>,
        });
      }
      setTenderTotal(totalTen);
    }

    return arr;
  };

  const column = [
    {
      dataField: "Quantity",
      text: "QTY",
      attrs: {
        "data-title": "Quantity",
        className: "text-center",
      },
      headerAttrs: {
        className: "text-center",
      },
    },
    {
      dataField: "Name",
      text: "NAME",
      attrs: {
        "data-title": "Name",
      },
    },
    {
      dataField: "Price",
      text: "PRICE",
      attrs: {
        "data-title": "Price",
      },
    },
    {
      dataField: "Total",
      text: "TOTAL",
      attrs: {
        "data-title": "Total",
      },
    },
  ];

  const [columns, setColumns] = useState(column);

  useEffect(() => {
    setColumns(
      column.filter((e) => {
        if (
          (e.dataField === "Price" && !showPriceCusScreen) ||
          (e.dataField === "Total" && !showTotalCusScreen)
        ) {
          //pass
        } else {
          return e;
        }
      })
    );
  }, [showTotalCusScreen, showPriceCusScreen]);

  const redirectToThanks = () => {
    props.setCustomerNumPadHide(true);
  };
  const clearValue = () => {
    setCustNumber("");
  };

  const ShowDiscountOnTotal = () => {
    if (+props?.nonCashAdjust?.discountVal > 0) {
      return (
        <ListGroup.Item>
          <p>Discount</p>
          <span>
            {currencyFormat(
              parseFloat(props?.nonCashAdjust?.totalDiscount).toFixed(2)
            )}
          </span>
        </ListGroup.Item>
      );
    } else {
      return "";
    }
  };

  const handleCustomerSmsEmailClick = (subscribe) => {
    setSmaAndEmailPromotionPopup(false);
    let updateData = {
      ...customerDetail,
      sms_promotion: true,
      promotion_subscribed: subscribe,
    };
    dispatch(
      editActionData(
        CUSTOMERDATA,
        updateData,
        updateData?.id,
        CUSTOMERUPDATE,
        true
      )
    );
    Localstorage.setItem("sale_customer", updateData);
  };

  const SmsAndEmailPromotion = () => {
    return (
      <>
        <div className="thankyou-wrapper  email-promition">
          <h1>Would you like to subscribe to SMS & EMAIL Promotions?</h1>
          <div className="d-flex mt-2">
            <Button
              variant="outline-blackcustom px-5"
              type="button"
              onClick={() => handleCustomerSmsEmailClick(1)}
            >
              Sure
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => handleCustomerSmsEmailClick(0)}
            >
              No Thanks
            </Button>
          </div>
        </div>
      </>
    );
  };

  let image_url;
  let askForNumberImg;
  if (ViewType == 3 && props?.image.hasOwnProperty(UPLOADFIXEDIMAGE)) {
    image_url = props?.image[UPLOADFIXEDIMAGE][0];
  }

  if (
    props.withoutitemdisplayType == 5 &&
    props?.image.hasOwnProperty(UPLOADSTATICIMAGEFORNUMBER)
  ) {
    askForNumberImg = props?.image[UPLOADSTATICIMAGEFORNUMBER][0];
  }

  return (
    <>
      {!props?.hideCustomerNumPad &&
      (props?.withoutitemdisplayType == 3 ||
        props?.withoutitemdisplayType == 5) ? (
        <>
          {props?.withoutitemdisplayType == 5 ? (
            <Container fluid className="number-entry with-entry">
              <Row className="h-100 m-0">
                <Col lg="6" xl="7" className="left-side p-0 pt-5">
                  <div className="inner-wrapper mt-5">
                    <h1>
                      Welcome&nbsp;
                      <span>
                        {CustomerBusinessName(props?.bussines?.business_name)}
                      </span>
                    </h1>
                    {props.cond !== 1 &&
                      welcomeData.heading_1 !== "" &&
                      welcomeData.heading_1 !== undefined && (
                        <h2>{welcomeData.heading_1}</h2>
                      )}
                    {props.cond !== 1 &&
                      welcomeData.heading_2 !== "" &&
                      welcomeData.heading_2 !== undefined && (
                        <p>{welcomeData.heading_2}</p>
                      )}

                    <div className="mt-5 pt-4">
                      {!askForNumberImg ? (
                        <Image
                          className="cacl-height"
                          src={defaultImage}
                          alt="shop"
                        />
                      ) : (
                        <Image className="cacl-height" src={askForNumberImg} />
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg="6" xl="5" className="right-side py-4 ">
                  <div className="inner-wrapper">
                    <FormField
                      type="text"
                      readOnly
                      value={msg !== "" ? msg : custNumber}
                      onChange={(e) => e}
                      placeholder="Enter Phone Number"
                      className="enter-number"
                    />
                    <CustomNumpad
                      type="customerScreenKeypad"
                      setValue={getValue}
                      hideNumpad={false}
                    />
                    {props?.noThanksButton && (
                      <Button
                        variant="link no-thanks"
                        onClick={() => redirectToThanks()}
                      >
                        No Thanks
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container fluid className={`number-entry welcome-screen ${props.noThanksButton ? 'number-entry-no-thanks' : ''}`}>
              <Row className="h-100 m-0">
                <Col className="left-side p-0 right-side">
                  <div className="inner-wrapper">
                    <h1>
                      Welcome&nbsp;
                      <span>
                        {CustomerBusinessName(props?.bussines?.business_name)}
                      </span>
                    </h1>
                    {props.cond !== 1 &&
                      welcomeData.heading_1 !== "" &&
                      welcomeData.heading_1 !== undefined && (
                        <h2>{welcomeData.heading_1}</h2>
                      )}
                    {props.cond !== 1 &&
                      welcomeData.heading_2 !== "" &&
                      welcomeData.heading_2 !== undefined && (
                        <p>{welcomeData.heading_2}</p>
                      )}
                    <div className="number-wrapper">
                      <div className="field-wrapper">
                        <FormField
                          type="text"
                          readOnly
                          value={msg !== "" ? msg : custNumber}
                          onChange={(e) => e}
                          placeholder="Enter Phone Number"
                          className="enter-number"
                        />
                        {custNumber.length > 0 && (
                          <Button variant="link" onClick={clearValue}>
                            <Image src={crossIcon} alt="cross icon" />
                          </Button>
                        )}
                      </div>
                      <CustomNumpad
                        type="customerScreenKeypad"
                        setValue={getValue}
                        hideNumpad={false}
                      />
                      {props?.noThanksButton && (
                        <div className="thank-btn">
                        <Button
                          variant="link no-thanks"
                          onClick={() => redirectToThanks()}
                        >
                          No Thanks
                        </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </>
      ) : (
        <>
          {/* customer details */}
          {/* {Object.entries(customerDetail).length ? (
            <Col
              lg={`${thank ? "6" : "12"}`}
              xl={`${thank ? "7" : "12"}`}
              className="welocme-customer"
            >
              <div className="d-flex justify-content-between">
                <h2>Welcome {customerDetail.name}</h2>
                <h2>
                  Your reward points: <span>{customerDetail.points}</span>
                </h2>
              </div>
            </Col>
          ) : (
            ""
          )} */}

          {/* Items Only -- selected ==1 */}
          {ViewType === 1 && (
            <Container fluid className="items-only">
              <Row>
                <Col
                  lg={`${thank || smsAndEmailPromotionPopup ? "6" : "12"}`}
                  xl={`${thank || smsAndEmailPromotionPopup ? "7" : "12"}`}
                  className="d-flex flex-column left mt-3 "
                  id="item-table"
                >
                  {Object.entries(customerDetail).length ? (
                    <div className="welocme-customer">
                      <h2>
                        Welcome <span>{customerDetail.name}</span>{" "}
                      </h2>
                      <h2>
                        Your Reward Points: <span>{customerDetail.points}</span>
                      </h2>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="custom-data-table w-100">
                    <div className="custom-card  card no-checkbox no-box-shadow column-visibility-table auto-width">
                      <div className="with-scroll-on-table">
                        {/* <CustomScroll
                          addScrolledClass
                          // keepAtBottom={true}
                          heightRelativeToParent="calc(100% - 0px)"
                        > */}
                        <div
                          className="table-container"
                          ref={tableRef}
                          style={{ maxHeight: "500", overflowY: "auto" }}
                        >
                          <BootstrapTable2
                            columns={columns}
                            data={orderItem}
                            tbodyClassName="custom-tbody"
                            keyField="id"
                          />
                        </div>
                        {/* </CustomScroll> */}
                      </div>
                      {/* <ShowDiscountOnTable /> */}
                    </div>
                  </div>
                  <ListGroup>
                    <ListGroup.Item>
                      <p>Total Items</p>
                      <span>
                        {orderItem.length > 0
                          ? orderItem.reduce(
                              (a, b) => a + parseInt(b.Quantity || 0),
                              0
                            )
                          : 0}
                      </span>
                    </ListGroup.Item>
                    {details.length > 0 &&
                      details[1].bottleEnable !== undefined &&
                      parseInt(details[1].bottleEnable.enable) === 1 && (
                        <ListGroup.Item>
                          <p>
                            {details.length > 0 &&
                            details[1].bottleEnable.lable !== undefined &&
                            details[1].bottleEnable.lable !== ""
                              ? details[1].bottleEnable.lable
                              : "Deposit"}
                          </p>
                          <span>
                            {orderItem.length > 0
                              ? currencyFormat(
                                  Number(
                                    orderItem.reduce(
                                      (a, b) =>
                                        a + parseFloat(b.bottledeposit || 0),
                                      0
                                    )
                                  ).toFixed(2)
                                )
                              : currencyFormat(parseFloat(0).toFixed(2))}
                          </span>
                        </ListGroup.Item>
                      )}
                    {details.length > 0 &&
                      details[0].additionalFeeEnable !== undefined &&
                      parseInt(details[0].additionalFeeEnable) === 1 && (
                        <ListGroup.Item>
                          <p> Additional Fees</p>
                          <span>
                            {orderItem.length > 0
                              ? currencyFormat(
                                  Number(
                                    orderItem.reduce(
                                      (a, b) =>
                                        a + parseFloat(b.additionalFee || 0),
                                      0
                                    )
                                  ).toFixed(2)
                                )
                              : currencyFormat(parseFloat(0).toFixed(2))}
                          </span>
                        </ListGroup.Item>
                      )}
                    {details.length > 0 &&
                      details[2].nonCashEnable !== undefined &&
                      parseInt(details[2].nonCashEnable.enable) === 1 && (
                        <ListGroup.Item>
                          <p>
                            {details.length > 0 &&
                            details[2].nonCashEnable.label !== undefined &&
                            details[2].nonCashEnable.label !== ""
                              ? details[2].nonCashEnable.label
                              : "Non-Cash Adj"}
                          </p>
                          <span>
                            {currencyFormat(
                              parseFloat(nonCashAdjust).toFixed(2)
                            )}
                          </span>
                        </ListGroup.Item>
                      )}
                    <ListGroup.Item>
                      <p>Tax</p>
                      <span>
                        {orderItem.length > 0
                          ? currencyFormat(
                              Number(
                                orderItem.reduce(
                                  (a, b) => a + parseFloat(b.taxTotal || 0),
                                  0
                                )
                              ).toFixed(2)
                            )
                          : currencyFormat(parseFloat(0).toFixed(2))}
                      </span>
                    </ListGroup.Item>
                    <ShowDiscountOnTotal />
                  </ListGroup>

                  {/* <hr></hr> */}
                  {(showPriceCusScreen ||
                    showTotalCusScreen ||
                    grandTotal - tenderTotal < 0) && (
                    <div className="grand-total-info">
                      <div className="d-flex align-items-center justify-content-between">
                        {grandTotal - tenderTotal >= 0 ? (
                          <>
                            <h2>Grand Total</h2>
                            <Badge pill bg="primary">
                              {currencyFormat(
                                parseFloat(grandTotal - tenderTotal).toFixed(2)
                              )}
                            </Badge>
                          </>
                        ) : (
                          <>
                            <h2>Change Due</h2>
                            <Badge pill bg="primary">
                              {currencyFormat(
                                parseFloat(tenderTotal - grandTotal).toFixed(2)
                              )}
                            </Badge>
                          </>
                        )}
                      </div>
                      {details.length > 0 &&
                        details[2]?.nonCashEnable !== undefined &&
                        parseInt(details[2]?.nonCashEnable.enable) === 1 && (
                          <p className="mx-3">
                            {stringData !== undefined ? stringData : ""}
                          </p>
                        )}
                    </div>
                  )}

                  {customerDetail.offerList &&
                  customerDetail.offerList.length ? (
                    <div className="reward-offers scroll-auto mt-4">
                      <h2>Reward/Offers</h2>
                      <ul>
                        {customerDetail.offerList.map((e) => {
                          return <li>{e}</li>;
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col className=" p-0 mt-3 mt-lg-0" lg="6" xl="5">
                  {smsAndEmailPromotionPopup ? (
                    <>
                      <SmsAndEmailPromotion />
                    </>
                  ) : (
                    <>
                      {thank && (
                        <ThankYou
                          review_url={
                            props?.posSettingData?.customer_review_url
                          }
                          rating={props?.posSettingData?.customer_rating}
                          thankYouHide={thankYouHide}
                        />
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          )}
          {/* Items With Slideshow -- selected  ==2  */}
          {ViewType === 2 && (
            <Container fluid className="items-only">
              <Row>
                <Col
                  lg="6"
                  xl="7"
                  className="d-flex  flex-column left  mt-3 "
                  id="item-table"
                >
                  {Object.entries(customerDetail).length ? (
                    <div className="welocme-customer">
                      <h2>
                        Welcome <span>{customerDetail.name}</span>{" "}
                      </h2>
                      <h2>
                        Your Reward Points: <span>{customerDetail.points}</span>
                      </h2>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="custom-data-table w-100">
                    <div className="custom-card  card no-checkbox no-box-shadow column-visibility-table auto-width">
                      <div className="with-scroll-on-table">
                        <CustomScroll
                          addScrolledClass
                          heightRelativeToParent="calc(100% - 0px)"
                        >
                          <BootstrapTable2
                            columns={columns}
                            data={orderItem}
                            keyField="id"
                          />
                        </CustomScroll>
                      </div>
                      {/* <ShowDiscountOnTable /> */}
                    </div>
                  </div>
                  <ListGroup>
                    <ListGroup.Item>
                      <p>Total Items</p>
                      <span>
                        {orderItem.length > 0
                          ? orderItem.reduce(
                              (a, b) => a + parseInt(b.Quantity || 0),
                              0
                            )
                          : 0}
                      </span>
                    </ListGroup.Item>
                    {details.length > 0 &&
                      details[1].bottleEnable !== undefined &&
                      parseInt(details[1].bottleEnable.enable) === 1 && (
                        <ListGroup.Item>
                          <p>
                            {details.length > 0 &&
                            details[1].bottleEnable.lable !== undefined &&
                            details[1].bottleEnable.lable !== ""
                              ? details[1].bottleEnable.lable
                              : "Deposit"}
                          </p>
                          <span>
                            {orderItem.length > 0
                              ? currencyFormat(
                                  Number(
                                    orderItem.reduce(
                                      (a, b) =>
                                        a + parseFloat(b.bottledeposit || 0),
                                      0
                                    )
                                  ).toFixed(2)
                                )
                              : currencyFormat(parseFloat(0).toFixed(2))}
                          </span>
                        </ListGroup.Item>
                      )}
                    {details.length > 0 &&
                      details[0].additionalFeeEnable !== undefined &&
                      parseInt(details[0].additionalFeeEnable) === 1 && (
                        <ListGroup.Item>
                          <p> Additional Fees</p>
                          <span>
                            {orderItem.length > 0
                              ? currencyFormat(
                                  Number(
                                    orderItem.reduce(
                                      (a, b) =>
                                        a + parseFloat(b.additionalFee || 0),
                                      0
                                    )
                                  ).toFixed(2)
                                )
                              : currencyFormat(parseFloat(0).toFixed(2))}
                          </span>
                        </ListGroup.Item>
                      )}
                    {details.length > 0 &&
                      details[2].nonCashEnable !== undefined &&
                      parseInt(details[2].nonCashEnable.enable) === 1 && (
                        <ListGroup.Item>
                          <p>
                            {details.length > 0 &&
                            details[2].nonCashEnable.label !== undefined &&
                            details[2].nonCashEnable.label !== ""
                              ? details[2].nonCashEnable.label
                              : "Non-Cash Adj"}
                          </p>
                          <span>
                            {currencyFormat(
                              parseFloat(nonCashAdjust).toFixed(2)
                            )}
                          </span>
                        </ListGroup.Item>
                      )}
                    <ListGroup.Item>
                      <p>Tax</p>
                      <span>
                        {orderItem.length > 0
                          ? currencyFormat(
                              Number(
                                orderItem.reduce(
                                  (a, b) => a + parseFloat(b.taxTotal || 0),
                                  0
                                )
                              ).toFixed(2)
                            )
                          : currencyFormat(parseFloat(0).toFixed(2))}
                      </span>
                    </ListGroup.Item>
                    <ShowDiscountOnTotal />
                  </ListGroup>

                  {(showPriceCusScreen ||
                    showTotalCusScreen ||
                    grandTotal - tenderTotal < 0) && (
                    <div className="grand-total-info">
                      <div className="d-flex align-items-center justify-content-between">
                        <h2>Grand Total</h2>
                        <Badge pill bg="primary">
                          {currencyFormat(
                            parseFloat(grandTotal - tenderTotal).toFixed(2)
                          )}
                        </Badge>
                      </div>
                      {details.length > 0 &&
                        details[2]?.nonCashEnable !== undefined &&
                        parseInt(details[2]?.nonCashEnable.enable) === 1 && (
                          <p className="mx-3">
                            {stringData !== undefined ? stringData : ""}
                          </p>
                        )}
                    </div>
                  )}

                  {customerDetail.offerList &&
                  customerDetail.offerList.length ? (
                        <div className="reward-offers scroll-auto mt-4">
                      <h2>Reward/Offers</h2>
                      <ul>
                        {customerDetail.offerList.map((e, index) => {
                          return <li key={index}>{e}</li>;
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col className="p-0 mt-3 mt-lg-0" lg="6" xl="5">
                  {smsAndEmailPromotionPopup ? (
                    <>
                      <SmsAndEmailPromotion />
                    </>
                  ) : (
                    <>
                      {thank ? (
                        <ThankYou
                          review_url={
                            props?.posSettingData?.customer_review_url
                          }
                          thankYouHide={thankYouHide}
                        />
                      ) : (
                        <SliderMain
                          image={
                            props?.image && props?.image[SLIDESHOWFORPOSSCREEN]
                              ? props?.image[SLIDESHOWFORPOSSCREEN]
                              : []
                          }
                          posSettingData={props?.posSettingData}
                        />
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          )}
          {/* Items With Fixed Image -- selected==3   */}
          {ViewType === 3 && (
            <Container fluid className="items-only">
              <Row>
                <Col
                  lg="6"
                  xl="7"
                  className="d-flex flex-column left mt-3"
                  id="item-table"
                >
                  {Object.entries(customerDetail).length ? (
                    <div className="welocme-customer">
                      <h2>
                        Welcome <span>{customerDetail.name}</span>{" "}
                      </h2>
                      <h2>
                        Your Reward Points: <span>{customerDetail.points}</span>
                      </h2>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="custom-data-table w-100">
                    <div className="custom-card  card no-checkbox no-box-shadow column-visibility-table auto-width">
                      <div className="with-scroll-on-table">
                        <CustomScroll
                          addScrolledClass
                          heightRelativeToParent="calc(100% - 0px)"
                        >
                          <BootstrapTable2
                            columns={columns}
                            data={orderItem}
                            keyField="id"
                          />
                        </CustomScroll>
                      </div>
                      {/* <ShowDiscountOnTable /> */}
                    </div>
                  </div>
                  <ListGroup>
                    <ListGroup.Item>
                      <p>Total Items</p>
                      <span>
                        {orderItem.length > 0
                          ? orderItem.reduce(
                              (a, b) => a + parseInt(b.Quantity || 0),
                              0
                            )
                          : 0}
                      </span>
                    </ListGroup.Item>
                    {details.length > 0 &&
                      details[1].bottleEnable !== undefined &&
                      parseInt(details[1].bottleEnable.enable) === 1 && (
                        <ListGroup.Item>
                          <p>
                            {details.length > 0 &&
                            details[1].bottleEnable.lable !== undefined &&
                            details[1].bottleEnable.lable !== ""
                              ? details[1].bottleEnable.lable
                              : "Deposit"}
                          </p>
                          <span>
                            {orderItem.length > 0
                              ? currencyFormat(
                                  Number(
                                    orderItem.reduce(
                                      (a, b) =>
                                        a + parseFloat(b.bottledeposit || 0),
                                      0
                                    )
                                  ).toFixed(2)
                                )
                              : currencyFormat(parseFloat(0).toFixed(2))}
                          </span>
                        </ListGroup.Item>
                      )}
                    {details.length > 0 &&
                      details[0].additionalFeeEnable !== undefined &&
                      parseInt(details[0].additionalFeeEnable) === 1 && (
                        <ListGroup.Item>
                          <p> Additional Fees</p>
                          <span>
                            {orderItem.length > 0
                              ? currencyFormat(
                                  Number(
                                    orderItem.reduce(
                                      (a, b) =>
                                        a + parseFloat(b.additionalFee || 0),
                                      0
                                    )
                                  ).toFixed(2)
                                )
                              : currencyFormat(parseFloat(0).toFixed(2))}
                          </span>
                        </ListGroup.Item>
                      )}
                    {details.length > 0 &&
                      details[2].nonCashEnable !== undefined &&
                      parseInt(details[2].nonCashEnable.enable) === 1 && (
                        <ListGroup.Item>
                          <p>
                            {details.length > 0 &&
                            details[2].nonCashEnable.label !== undefined &&
                            details[2].nonCashEnable.label !== ""
                              ? details[2].nonCashEnable.label
                              : "Non-Cash Adj"}
                          </p>
                          <span>
                            {currencyFormat(
                              parseFloat(nonCashAdjust).toFixed(2)
                            )}
                          </span>
                        </ListGroup.Item>
                      )}
                    <ListGroup.Item>
                      <p>Tax</p>
                      <span>
                        {orderItem.length > 0
                          ? currencyFormat(
                              Number(
                                orderItem.reduce(
                                  (a, b) => a + parseFloat(b.taxTotal || 0),
                                  0
                                )
                              ).toFixed(2)
                            )
                          : currencyFormat(parseFloat(0).toFixed(2))}
                      </span>
                    </ListGroup.Item>
                    <ShowDiscountOnTotal />
                  </ListGroup>
                  {(showPriceCusScreen ||
                    showTotalCusScreen ||
                    grandTotal - tenderTotal < 0) && (
                    <div className="grand-total-info">
                      <div className="d-flex align-items-center justify-content-between">
                        {grandTotal - tenderTotal >= 0 ? (
                          <>
                            <h2>Grand Total</h2>
                            <Badge pill bg="primary">
                              {currencyFormat(
                                parseFloat(grandTotal - tenderTotal).toFixed(2)
                              )}
                            </Badge>
                          </>
                        ) : (
                          <>
                            <h2>Change Due</h2>
                            <Badge pill bg="primary">
                              {currencyFormat(
                                parseFloat(tenderTotal - grandTotal).toFixed(2)
                              )}
                            </Badge>
                          </>
                        )}
                      </div>
                      {details.length > 0 &&
                        details[2]?.nonCashEnable !== undefined &&
                        parseInt(details[2]?.nonCashEnable.enable) === 1 && (
                          <p className="mx-3">
                            {stringData !== undefined ? stringData : ""}
                          </p>
                        )}
                    </div>
                  )}

                  {customerDetail.offerList &&
                  customerDetail.offerList.length ? (
                        <div className="reward-offers scroll-auto mt-4">
                      <h2>Reward/Offers</h2>
                      <ul>
                        {customerDetail.offerList.map((e) => {
                          return <li>{e}</li>;
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col className="p-0 mt-3 mt-lg-0" lg="6" xl="5">
                  {smsAndEmailPromotionPopup ? (
                    <>
                      <SmsAndEmailPromotion />
                    </>
                  ) : (
                    <>
                      {thank ? (
                        <ThankYou
                          review_url={
                            props?.posSettingData?.customer_review_url
                          }
                          thankYouHide={thankYouHide}
                        />
                      ) : (
                        <Image
                          className="objec-fit"
                          src={image_url}
                          height={height}
                          width={width}
                        />
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          )}
          {/* Items With Keypad -- selected ===4 */}
          {ViewType === 4 && (
            <Container fluid className="items-only number-entry with-keypad">
              <Row>
                <Col
                  lg="6"
                  xl="7"
                  className="d-flex  flex-column left mt-3 "
                  id="item-table"
                >
                  {Object.entries(customerDetail).length ? (
                    <div className="welocme-customer">
                      <h2>
                        Welcome <span>{customerDetail.name}</span>{" "}
                      </h2>
                      <h2>
                        Your Reward Points: <span>{customerDetail.points}</span>
                      </h2>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="custom-data-table w-100">
                    <div className="custom-card  card no-checkbox no-box-shadow column-visibility-table auto-width">
                      <div className="with-scroll-on-table">
                        <CustomScroll
                          addScrolledClass
                          heightRelativeToParent="calc(100% - 0px)"
                        >
                          <BootstrapTable2
                            columns={columns}
                            data={orderItem}
                            keyField="id"
                          />
                        </CustomScroll>
                      </div>
                      {/* <ShowDiscountOnTable /> */}
                    </div>
                  </div>
                  <ListGroup>
                    <ListGroup.Item>
                      <p>Total Items</p>
                      <span>
                        {orderItem.length > 0
                          ? orderItem.reduce(
                              (a, b) => a + parseInt(b.Quantity || 0),
                              0
                            )
                          : 0}
                      </span>
                    </ListGroup.Item>
                    {details.length > 0 &&
                      details[1].bottleEnable !== undefined &&
                      parseInt(details[1].bottleEnable.enable) === 1 && (
                        <ListGroup.Item>
                          <p>
                            {details.length > 0 &&
                            details[1].bottleEnable.lable !== undefined &&
                            details[1].bottleEnable.lable !== ""
                              ? details[1].bottleEnable.lable
                              : "Deposit"}
                          </p>
                          <span>
                            {orderItem.length > 0
                              ? currencyFormat(
                                  Number(
                                    orderItem.reduce(
                                      (a, b) =>
                                        a + parseFloat(b.bottledeposit || 0),
                                      0
                                    )
                                  ).toFixed(2)
                                )
                              : currencyFormat(parseFloat(0).toFixed(2))}
                          </span>
                        </ListGroup.Item>
                      )}
                    {details.length > 0 &&
                      details[0].additionalFeeEnable !== undefined &&
                      parseInt(details[0].additionalFeeEnable) === 1 && (
                        <ListGroup.Item>
                          <p> Additional Fees</p>
                          <span>
                            {orderItem.length > 0
                              ? currencyFormat(
                                  Number(
                                    orderItem.reduce(
                                      (a, b) =>
                                        a + parseFloat(b.additionalFee || 0),
                                      0
                                    )
                                  ).toFixed(2)
                                )
                              : currencyFormat(parseFloat(0).toFixed(2))}
                          </span>
                        </ListGroup.Item>
                      )}
                    {details.length > 0 &&
                      details[2].nonCashEnable !== undefined &&
                      parseInt(details[2].nonCashEnable.enable) === 1 && (
                        <ListGroup.Item>
                          <p>
                            {details.length > 0 &&
                            details[2].nonCashEnable.label !== undefined &&
                            details[2].nonCashEnable.label !== ""
                              ? details[2].nonCashEnable.label
                              : "Non-Cash Adj"}
                          </p>
                          <span>
                            {currencyFormat(
                              parseFloat(nonCashAdjust).toFixed(2)
                            )}
                          </span>
                        </ListGroup.Item>
                      )}
                    <ListGroup.Item>
                      <p>Tax</p>
                      <span>
                        {orderItem.length > 0
                          ? currencyFormat(
                              Number(
                                orderItem.reduce(
                                  (a, b) => a + parseFloat(b.taxTotal || 0),
                                  0
                                )
                              ).toFixed(2)
                            )
                          : currencyFormat(parseFloat(0).toFixed(2))}
                      </span>
                    </ListGroup.Item>
                    <ShowDiscountOnTotal />
                  </ListGroup>

                  {(showPriceCusScreen ||
                    showTotalCusScreen ||
                    grandTotal - tenderTotal < 0) && (
                    <div className="grand-total-info">
                      <div className="d-flex align-items-center justify-content-between">
                        {grandTotal - tenderTotal >= 0 ? (
                          <>
                            <h2>Grand Total</h2>
                            <Badge pill bg="primary">
                              {currencyFormat(
                                parseFloat(grandTotal - tenderTotal).toFixed(2)
                              )}
                            </Badge>
                          </>
                        ) : (
                          <>
                            <h2>Change Due</h2>
                            <Badge pill bg="primary">
                              {currencyFormat(
                                parseFloat(tenderTotal - grandTotal).toFixed(2)
                              )}
                            </Badge>
                          </>
                        )}
                      </div>
                      {details.length > 0 &&
                        details[2]?.nonCashEnable !== undefined &&
                        parseInt(details[2]?.nonCashEnable.enable) === 1 && (
                          <p className="mx-3">
                            {stringData !== undefined ? stringData : ""}
                          </p>
                        )}
                    </div>
                  )}

                  {customerDetail.offerList &&
                  customerDetail.offerList.length ? (
                    <div className="reward-offers scroll-auto mt-4">
                      <h2>Reward/Offers</h2>
                      <ul>
                        {customerDetail.offerList.map((e) => {
                          return <li>{e}</li>;
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>

                {smsAndEmailPromotionPopup ? (
                  <Col className="p-0 mt-3 mt-lg-0" lg="6" xl="5">
                    <SmsAndEmailPromotion />
                  </Col>
                ) : (
                  <>
                    {!props?.hideCustomerNumPad ? (
                      <Col
                        xl="5"
                        lg="6"
                        className="right-side mt-3 mt-lg-0 pt-5 pt-lg-0"
                      >
                        <div className="inner-wrapper">
                          <div className="step-4">
                            <h1>
                              Welcome&nbsp;
                              <span>
                                {CustomerBusinessName(
                                  props?.bussines?.business_name
                                )}
                              </span>
                            </h1>
                            {props.cond !== 1 &&
                              welcomeData.heading_1 !== "" &&
                              welcomeData.heading_1 !== undefined && (
                                <h2>{welcomeData.heading_1}</h2>
                              )}
                            {props.cond !== 1 &&
                              welcomeData.heading_2 !== "" &&
                              welcomeData.heading_2 !== undefined && (
                                <p>{welcomeData.heading_2}</p>
                              )}
                          </div>
                          <div className="field-wrapper">
                            <FormField
                              type="text"
                              readOnly
                              value={msg !== "" ? msg : custNumber}
                              onChange={(e) => e}
                              placeholder="Enter Phone Number"
                              className="enter-number"
                            />
                            {custNumber.length > 0 && (
                              <Button variant="link" onClick={clearValue}>
                                <Image src={crossIcon} alt="cross icon" />
                              </Button>
                            )}
                          </div>
                          <CustomNumpad
                            type="customerScreenKeypad"
                            setValue={getValue}
                            hideNumpad={false}
                          />
                          {props?.noThanksButton && (
                            <Button
                              variant="link no-thanks"
                              onClick={() => redirectToThanks()}
                            >
                              No Thanks
                            </Button>
                          )}
                        </div>
                      </Col>
                    ) : (
                      <Col xl="5" lg="6" className="mt-3 mt-lg-0 pt-5 pt-lg-0">
                        {thank ? (
                          <ThankYou
                            review_url={
                              props?.posSettingData?.customer_review_url
                            }
                            rating={props?.posSettingData?.customer_rating}
                            thankYouHide={thankYouHide}
                          />
                        ) : (
                          <Image
                            className="objec-fit"
                            src={image_url}
                            height={height}
                            width={width}
                          />
                        )}
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  );
};

CustomerDetail.propTypes = {
  posSettingData: PropTypes.shape({
    customer_review_url: PropTypes.string,
  }), 
  nonCashAdjust: PropTypes.shape({
    nonCashAdj: PropTypes.any, 
    Total: PropTypes.number,
  }),
  posSetting: PropTypes.shape({
    CUSTOMER: PropTypes.shape({
      pos_view_display_type: PropTypes.number,
      customer_rating: PropTypes.string,
    }),
  }),
  hideCustomerNumPad: PropTypes.bool,
  customerScreen: PropTypes.arrayOf(
    PropTypes.shape({
      nonCashEnable: PropTypes.shape({
        customerScreenTaxt: PropTypes.string,
      }),
    })
  ),
  OrderData: PropTypes.array.isRequired,
  payments: PropTypes.array.isRequired,
  setCustomerNumPadHide: PropTypes.func,
  image: PropTypes.object,
  withoutitemdisplayType: PropTypes.number,
  bussines: PropTypes.shape({
    business_name: PropTypes.string,
  }),
};
export default CustomerDetail;
