import React, { useEffect, useState } from "react";
import { Card } from "@themesberg/react-bootstrap";

// Other imports
import BootstrapTable2 from "../components/BootsrapTable2";
import { connect } from "react-redux";
import { currencyFormat, getTodaysRecords } from "../../app/helpers/custom";
import CustomScroll from "react-custom-scroll";

const WhatsSelling = (props) => {
  const [whatsSellingData, setWhatsSellingData] = useState({});
  const [sellingReport, setSellingReport] = useState([]);

  useEffect(() => {
    if (props?.reportlist)
      setWhatsSellingData(getWhatsSellingStats(props?.reportlist));
  }, [props.reportlist]);

  useEffect(() => {
    if (Object.entries(whatsSellingData).length > 0) {
      let whatSellingObj = {...whatsSellingData}
      if (localStorage.getItem("off_sale") != null){
        let sales = JSON.parse(localStorage.getItem("off_sale"));
        for (let sale of sales){
          let obj = sale?.items.map((item)=>{
            if (whatSellingObj?.items[item?.id]){
              whatSellingObj.items[item?.id].qty += +item?.qty
              whatSellingObj.items[item?.id].total += +item?.total
            } else {
              whatSellingObj.items[item?.id] = {};
              whatSellingObj.items[item?.id].name = item?.itemData?.name
              whatSellingObj.items[item?.id].qty = +item?.qty
              whatSellingObj.items[item?.id].total = +item?.total
            }
          })
        }
      }
      let sellingArr = [...renderOnTableData(whatSellingObj)];
      setSellingReport(sellingArr);
    }
  }, [whatsSellingData]);

  function renderOnTableData(stats) {
    const returnArray = [];
    let item;
    let i = 1;
    for (let id in stats.items) {
      item = stats.items[id];
      returnArray.push({
        id: i,
        Item: item.name,
        Sold: item.qty,
        Total: currencyFormat(item.total),
      });
      i++;
    }
    return returnArray;
  }

  function getWhatsSellingStats(saleList) {
    let itemstats = { items: [], totalsold: 0 };
    let records = getTodaysRecords(false, saleList);
    let items = [];
    let item = {};
    let discount = 0;
    let discprice = 0;
    for (const ref in records) {
      if (!records[ref].hasOwnProperty("voiddata")) {
        // do not count voided sales
        discount = parseFloat(records[ref].discount);
        items = records[ref].items;
        for (const index in items) {
          item = items[index];
          discprice = parseFloat(item.price) - item.price * (discount / 100);
          // check if record exists
          if (itemstats.items.hasOwnProperty(item.storeditemid)) {
            // sum values
            itemstats.items[item.storeditemid].qty += parseInt(item.qty);
            itemstats.items[item.storeditemid].total += discprice;
          } else {
            // create new record
            const itemname = item.name === "" ? "Miscellaneous" : item.name;
            itemstats.items[item.storeditemid] = {
              qty: parseInt(item.qty),
              total: discprice,
              name: itemname,
            };
          }
          itemstats.totalsold += item.qty;
        }
      }
    }
    return itemstats;
  }

  const columns = [
    {
      dataField: "Item",
      text: "Item",
      sort: true,
      attrs: {
        "data-title": "Item",
      },
    },
    {
      dataField: "Sold",
      text: "# Sold",
      sort: true,
      attrs: {
        "data-title": "# Sold",
      },
    },
    {
      dataField: "Total",
      text: "Total",
      sort: true,
      attrs: {
        "data-title": "Total",
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size}
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "8",
        value: 8,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: sellingReport.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <Card className="custom-card no-checkbox no-box-shadow border-0  report-with-scroll column-visibility-table auto-width whats-selling">
      <div className=" with-scroll-on-table">
          <CustomScroll
            addScrolledClass
            heightRelativeToParent="calc(100% - 0px)"
          >
        <BootstrapTable2
          columns={columns}
          data={sellingReport}
          keyField="id"
          pagination={true}
          PaginationOptions={options}
          noDataIndication={sellingReport.length === 0 ? true : false}
        />
          </CustomScroll>
          </div>
      </Card>
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    reportlist: state.inventory.reportList,
  };
};
export default connect(mapStateToprops)(WhatsSelling);
