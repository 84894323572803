import {
  ACCEPTED,
  ACCEPTEDVALUE,
  CANCELED,
  CANCELEDVALUE,
  COMPLETE,
  COMPLETEVALUE,
  DECELINED,
  DECELINEDVALUE,
  NOSALE,
  NOSALEVALUE,
  ORDER,
  ORDERVALUE,
  REFUNDED,
  REFUNDEDVALUE,
  VOID,
  VOIDVALUE,
  GENERALSETIINGDATA,
  POSSETTINGDATA,
  SALEITEMS,
  SALECODES,
  SALEPAYMENTS,
  SALEEXTRACOST,
  SALEVOID,
  ACCOUNTSETTINGDATA,
} from "./constant";
import bell_modern from "../../assets/sounds/bell_modern.mp3";
import item_not_found from "../../assets/sounds/item_not_found.mp3";
import doorbell_2 from "../../assets/sounds/doorbell_2.mp3";
import doorbell_3 from "../../assets/sounds/doorbell_3.mp3";
import doorbell_7 from "../../assets/sounds/doorbell_7.mp3";
import { getDeviceAndStore, getSettingData } from "../../axios/authHeader";
import { Base64 } from "js-base64";
import $ from "jquery";
import Localstorage from "app/storage/Localstorage";
import moment from "moment";

export function refrenceNumber(device) {
  let reference_number = 0;
  const date = new Date().getTime();
  if (device) {
    reference_number =
      date + "-" + device + "-" + Math.floor(Math.random() * 10000 + 1);
  } else {
    reference_number = date;
  }
  return reference_number;
}

export function SaleStatusData(status) {
  switch (status) {
    case ORDERVALUE:
      return { color: "primary", value: ORDER };
    case COMPLETEVALUE:
      return { color: "seagreen", value: COMPLETE };
    case VOIDVALUE:
      return { color: "danger", value: VOID };
    case DECELINEDVALUE:
      return { color: "danger", value: DECELINED };
    case CANCELEDVALUE:
      return { color: "danger", value: CANCELED };
    case REFUNDEDVALUE:
      return { color: "orange", value: REFUNDED };
    case NOSALEVALUE:
      return { color: "danger", value: NOSALE };
    case ACCEPTEDVALUE:
      return { color: "seagreen", value: ACCEPTED };
    default:
      return false;
  }
}

export function GetDateByTimeStemp(timestamp) {
  const date = new Date(timestamp);
  return (
    date.getFullYear() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0") +
    " " +
    String(date.getUTCHours()).padStart(2, "0") +
    ":" +
    String(date.getUTCMinutes()).padStart(2, "0") +
    ":" +
    String(date.getUTCSeconds()).padStart(2, "0")
  );
}

export function mergeArrayData(arr) {
  if (arr.length > 0) {
    return [...new Set([].concat(...arr))];
  } else {
    return arr;
  }
}

export function findUniqueData(arr, key) {
  if (arr.length > 0 && key !== "") {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  } else {
    return arr;
  }
}

export const checkEbt = (payment) => {
  for (let i of payment) {
    if (["ebt", "ebt cash"].includes(i.method)) {
      return true
    }
  }
  return false
}

// random ids
export function getRandomId() {
  return "xxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}


export function getDeviceTerminalId() {
  try {
    return JSON.parse(localStorage.getItem("p5_terminal_id"));
  } catch (e) {
    return ""
  }
}

//check undefined and null value
export function checkUndefindAndNull(value, returnValue) {
  return value !== undefined && value !== null
    ? value
    : returnValue !== undefined
      ? returnValue
      : "";
}

export function taxDetailsByItemId(
  taxid,
  qty,
  price,
  taxlist,
  taxrule,
  itemsFees,
  feeArry,
  itembottle_deposit,
  BottleDepositArry,
  feeEnable,
  bottleEnable,
  botttleTaxable
) {
  if (taxid !== "") {
    const itemData = taxrule?.find((item) => item.id === parseInt(taxid));
    if (
      itemData !== undefined &&
      itemData?.multi_mode !== undefined &&
      parseInt(itemData?.multi_mode) === 2
    ) {
      let sumtax = parseFloat(0);
      let values = [];
      let feetaxable = parseFloat(0);
      let bottletaxable = parseFloat(0);
      for (let i = 0; i < itemData?.base_tax?.length; i++) {
        const appliedTax = taxlist?.find(
          (item) => item.id === itemData?.base_tax[i]?.basetax_id
        );
        sumtax += parseFloat(appliedTax?.value);
        if (feeEnable > 0) {
          feetaxable = calculateAdditionalFeeTaxable(
            feeArry,
            itemsFees,
            price,
            qty,
            parseFloat(appliedTax?.value)
          );
        }
        if (botttleTaxable > 0 && bottleEnable > 0) {
          bottletaxable = calculateBottleDepositTaxable(
            BottleDepositArry,
            itembottle_deposit,
            qty,
            parseFloat(appliedTax?.value)
          );
        }
        const taxableamt =
          ((parseFloat(calculateSubtotal(price, qty)) +
            parseFloat(feetaxable) +
            parseFloat(bottletaxable)) *
            parseFloat(appliedTax?.value)) /
          100;
        values = Object.assign(
          {
            [appliedTax?.id]: parseFloat(Number(taxableamt).toFixed(2)),
          },
          values
        );
      }
      return {
        total: Number(
          ((parseFloat(calculateSubtotal(price, qty)) +
            parseFloat(feetaxable) +
            parseFloat(bottletaxable)) *
            sumtax) /
          100
        ).toFixed(2),
        values: values,
        inclusive: itemData?.inclusive_tax === 0 ? false : true,
        ruleid: itemData?.id,
      };
    } else if (
      itemData !== undefined &&
      itemData?.multi_mode !== undefined &&
      parseInt(itemData?.multi_mode) === 1
    ) {
      let feetaxable = parseFloat(0);
      let bottletaxable = parseFloat(0);
      let values = [];
      if (itemData?.base_tax?.length > 0) {
        const appliedTax = taxlist?.find(
          (item) => item.id === itemData?.base_tax[0]?.basetax_id
        );
        if (feeEnable > 0) {
          feetaxable = calculateAdditionalFeeTaxable(
            feeArry,
            itemsFees,
            price,
            qty,
            parseFloat(appliedTax?.value)
          );
        }
        if (botttleTaxable > 0 && bottleEnable > 0) {
          bottletaxable = calculateBottleDepositTaxable(
            BottleDepositArry,
            itembottle_deposit,
            qty,
            parseFloat(appliedTax?.value)
          );
        }
        const taxTotal =
          ((parseFloat(calculateSubtotal(price, qty)) +
            parseFloat(feetaxable) +
            parseFloat(bottletaxable)) *
            parseFloat(appliedTax?.value)) /
          100;
        values = Object.assign(
          {
            [appliedTax?.id]: parseFloat(Number(taxTotal).toFixed(2)),
          },
          values
        );
        return {
          total: !isNaN(Number(taxTotal)) ? Number(taxTotal).toFixed(2) : Number(0).toFixed(2),
          values: values,
          inclusive: itemData?.inclusive_tax === 0 ? false : true,
          ruleid: itemData?.id,
        };
      } else {
        return {
          total: Number(0).toFixed(2),
          values: {},
          inclusive: itemData?.inclusive_tax === 0 ? false : true,
          ruleid: itemData?.id,
        };
      }
    }
  }
}

function calculateAdditionalFeeTaxable(
  feeArry,
  itemFees,
  price,
  qty,
  taxvalue
) {
  if (itemFees !== undefined && itemFees?.length > 0) {
    let sumfeeTax = parseFloat(0);
    let perfeeTax = parseFloat(0);
    for (let i = 0; i < itemFees?.length; i++) {
      const appliedFee = feeArry?.find(
        (item) => item.id === parseInt(itemFees[i]?.fee)
      );
      if (appliedFee?.type === 1) {
        if (appliedFee?.taxable === 1) {
          perfeeTax += (parseFloat(appliedFee?.value) * price) / 100;
        }
      } else {
        if (appliedFee?.taxable === 1) {
          sumfeeTax += parseFloat(appliedFee?.value);
        }
      }
    }
    if (taxvalue > 0 && parseFloat(sumfeeTax + perfeeTax) > 0) {
      return parseInt(qty) * parseFloat(sumfeeTax + perfeeTax);
    }
  } else {
    return Number(0).toFixed(2);
  }
}

function calculateBottleDepositTaxable(bottleArry, itemdeposit, qty, taxvalue) {
  if (itemdeposit !== undefined && itemdeposit !== null) {
    const appliedbottle = bottleArry?.find(
      (item) => item.id === parseInt(itemdeposit)
    );
    if (taxvalue > 0) {
      return appliedbottle?.amount * parseInt(qty);
    }
  } else {
    return Number(0).toFixed(2);
  }
}

function calculateSubtotal(price, qty) {
  if (price !== "" && qty !== "") {
    return parseFloat(price) * parseInt(qty);
  } else {
    return parseFloat(0);
  }
}

export function CalculateAdditionalCardPrice(price) {
  let setting = getSettingData(ACCOUNTSETTINGDATA);
  const enable = +setting?.DUALPRICING?.enable;
  const rate = +setting?.DUALPRICING?.percentage;
  if(enable && rate){
    return parseFloat((rate/100) * price)
  }else{
    return 0;
  }
}


export function sumObjectsByKey(objs) {
  console.log("Tax obj => ", objs);
  if (objs.length > 0) {
    return objs.reduce((a, b) => {
      for (let k in b) {
        if (Object.prototype.hasOwnProperty.call(b, k)) a[k] = (a[k] || 0) + b[k];
      }
      return a;
    }, {});
  } else {
    return objs;
  }
}

export function combineObjectsAdditionalFee(objs) {
  if (objs.length > 0) {
    const arrayReturn = [];
    return objs.reduce((a, b) => {
      for (let k in b) {
        if (Object.hasOwnProperty.call(b, k)) arrayReturn.push(b[k]);
      }
      return arrayReturn;
    }, {});
  } else {
    return objs;
  }
}

export function sumAdditionalFeebyFee(data) {
  if (data.length > 0) {
    return data.reduce(
      (r, { id, costtype, costname, total, discount, charge, item_id }) => {
        var temp = r.find((o) => o.id === id);
        if (!temp) {
          r.push({ id, costtype, costname, total, discount, charge, item_id });
        } else {
          temp.total += total;
          temp.charge += charge;
        }
        return r;
      },
      []
    );
  } else {
    return data;
  }
}

export function calculateFee(itemfee, feesArray, FeeEnable, qty, price, id) {
  if (FeeEnable > 0) {
    if (itemfee !== undefined && itemfee?.length > 0) {
      let additionalCharge = [];
      for (let i = 0; i < itemfee?.length; i++) {
        const appliedFee = feesArray?.find(
          (item) => item.id === parseInt(itemfee[i]?.fee)
        );
        if (appliedFee?.type === 1) {
          additionalCharge = Object.assign(
            {
              [appliedFee?.id]: {
                id: appliedFee?.id,
                item_id: id,
                costtype: "additional_charges",
                costname: appliedFee?.name,
                total: parseFloat(
                  Number(
                    (parseFloat(appliedFee?.value) *
                      parseFloat(price) *
                      parseInt(qty)) /
                    100
                  ).toFixed(2)
                ),
                discount: 0,
                charge: parseFloat(
                  Number(
                    (parseFloat(appliedFee?.value) *
                      parseFloat(price) *
                      parseInt(qty)) /
                    100
                  ).toFixed(2)
                ),
              },
            },
            additionalCharge
          );
        } else {
          additionalCharge = Object.assign(
            {
              [appliedFee?.id]: {
                id: appliedFee?.id,
                item_id: id,
                costtype: "additional_charges",
                costname: appliedFee?.name,
                total: parseFloat(
                  Number(parseFloat(appliedFee?.value) * parseInt(qty)).toFixed(
                    2
                  )
                ),
                discount: 0,
                charge: parseFloat(
                  Number(parseFloat(appliedFee?.value) * parseInt(qty)).toFixed(
                    2
                  )
                ),
              },
            },
            additionalCharge
          );
        }
      }
      return additionalCharge;
    } else {
      return {};
    }
  } else {
    return {};
  }
}

export function BottleDepositCalculate(record) {
  var itemdeposits = parseFloat(0);
  for (var key in record.items) {
    if (Object.prototype.hasOwnProperty.call(record.items[key], "itemdeposit")) {
      itemdeposits += parseFloat(record.items[key].itemdeposit);
    }
  }
  return itemdeposits;
}

export function NoDataMsg() {
  return [{ label: "Loading data ...", value: "" }];
}

export function FirtsLetterUpperCase(str) {
  if (str !== undefined && str !== null)
    return str.charAt(0).toUpperCase() + str.slice(1);
  else return "-";
}

export const CustomerBusinessName = (str) => {
  if (str !== undefined && str !== null && str !== "")
    return "to " + str.charAt(0).toUpperCase() + str.slice(1);
  else return "";
};

export function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export function getSoundEffect(id) {
  switch (id) {
    case 1:
      return bell_modern;
    case 2:
      return item_not_found;
    case 3:
      return doorbell_2;
    case 4:
      return doorbell_3;
    case 5:
      return doorbell_7;
    case 0:
      return false;
    default:
      return false;
  }
}

export function todayDate(type, time) {
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  const hour = today.getHours();
  const minutes = today.getMinutes();
  const sec = today.getSeconds();
  today = `${yyyy}${type}${mm}${type}${dd}`;
  return time ? `${today}  ${hour}:${minutes}:${sec}` : today;
}

export function getTodaysRecords(includerefunds, sale) {
  var sales = sale;
  var todaysales = {};
  var stime = "";
  var etime = new Date();
  etime.setHours(23);
  etime.setMinutes(59);
  etime.setSeconds(59);
  etime = etime.getTime();
  //check Im I close today my account
  const accountClosingTime = localStorage.getItem("accountClosingTime");
  if (accountClosingTime !== null && accountClosingTime !== undefined) {
    stime = new Date(parseInt(accountClosingTime));
    stime = stime.getTime();
  } else {
    let lastclosing = false;
    if (
      lastclosing !== false &&
      Array.isArray(lastclosing) &&
      lastclosing.length > 0
    ) {
      lastclosing = lastclosing[0];
      let parseuserinput = JSON.parse(lastclosing.userinput);
      if (
        parseuserinput.accountClosingTime !== null &&
        Object.prototype.hasOwnProperty.call(parseuserinput, "accountClosingTime")
      ) {
        stime = new Date(parseInt(parseuserinput.accountClosingTime));
        stime = stime.getTime();
        localStorage.setItem("accountClosingTime", stime);
      } else {
        stime.setHours(0);
        stime.setMinutes(0);
        stime.setSeconds(0);
        stime = stime.getTime();
      }
    } else {
      stime.setHours(0);
      stime.setMinutes(0);
      stime.setSeconds(0);
      stime = stime.getTime();
    }
    //sales = getReportData(REPORT,stime,getDeviceAndStore()[0]);
  }
  //end here
  for (var key in sales) {
    // ignore if an order
    if (sales[key]?.hasOwnProperty("isorder") === false) {
      // ignore if the sale was not made today or refunded today
      const proccesDt = parseInt(sales[key].processdt);
      if (proccesDt > stime && proccesDt < etime) {
        // ignore if not made by this device
        if (sales[key].device_id === getDeviceAndStore()[0]) {
          if (!sales[key]?.hasOwnProperty("declined") || sales[key]?.declined === false) {
            todaysales[key] = sales[key];
          }
        }
      } else {
        if (includerefunds)
          if (sales[key]?.refunddata) {
            // check for refund made today
            var refdata = sales[key].refunddata;
            for (var record in refdata) {
              if (
                refdata[record].processdt > stime &&
                refdata[record].processdt < etime
              ) {
                // ignore if not made by this device
                if (refdata[record].deviceid === getDeviceAndStore()[0]) {
                  if (
                    sales[key]?.hasOwnProperty("declined") &&
                    sales[key]?.declined === false
                  ) {
                    todaysales[key] = sales[key];
                  }
                }
              }
            }
          }
      }
    }
  }
  return todaysales;
}

export function getTransactionStatus(saleobj) {
  if (saleobj?.voiddata !== undefined) {
    return 2;
  } else if (saleobj?.refunddata !== undefined) {
    return 3;
  } else if (saleobj.declined) {
    return 4;
  } else if (saleobj.iscancelorder) {
    return 6;
  } else if (saleobj.isorder) {
    return 5;
  } else if (saleobj.iscancelorder) {
    return 7;
  }
  return 1;
}

export function getStartTimeInReport() {
  const stime = localStorage.getItem("accountClosingTime");
  if (stime !== undefined && stime !== null) {
    return parseInt(stime);
  } else {
    return false;
  }
}

export function formatCode(code, use_upc_10) {
  if (use_upc_10 == 1) {
    let gencode = convertToUPC(code);
    switch (gencode?.length) {
      case 7:
        code = gencode.substring(1, 6);
        break;
      case 8:
        code = gencode.substring(1, 7);
        break;
      case 11:
        code = gencode.substring(1);
        break;
      case 12:
        code = gencode.substring(1, 11);
        break;
      case 13:
        code = gencode.substring(2, 12);
        break;
      case 14:
        code = gencode.substring(3, 13);
        break;
      default:
        return code;
    }
  }
  return code;
}

function isNumeric(x) {
  var numbers = ".0123456789";
  // is x a String or a character?
  if (x.length > 1) {
    // remove negative sign
    x = Math.abs(x) + "";
    var number = "";
    for (var j = 0; j < x.length; j++) {
      // call isNumeric recursively for each character
      number = isNumeric(x.substring(j, j + 1));
      if (!number) return number;
    }
    return number;
  } else {
    // if x is number return true
    if (numbers.indexOf(x) >= 0) return true;
    return false;
  }
}

function convertToUPC(code) {
  let UPCE = new String(code);
  // var UPCA = new String(document.forms["UPC"].txtUPCA.value);
  let UPCEString = new String();
  let ManufacturerNumber = new String();
  let ItemNumber = new String();
  let Msg = new String();

  if (isNumeric(UPCE)) {
    switch (UPCE.length) {
      case 6:
        UPCEString = UPCE;
        break;
      case 7:
        UPCEString = UPCE.substring(1, 7);
        break;
      case 8:
        UPCEString = UPCE.substring(1, 7);
        break;
      case 9:
        UPCEString = UPCE.substring(1, 7);
        break;
      default:
    } //End Select

    // break up the string into its 6 individual digits
    let Digit1 = UPCEString.substr(0, 1);
    let Digit2 = UPCEString.substr(1, 1);
    let Digit3 = UPCEString.substr(2, 1);
    let Digit4 = UPCEString.substr(3, 1);
    let Digit5 = UPCEString.substr(4, 1);
    let Digit6 = UPCEString.substr(5, 1);
    switch (Digit6) {
      case "0":
        ManufacturerNumber = ManufacturerNumber.concat(
          Digit1,
          Digit2,
          Digit6,
          "00"
        );
        ItemNumber = ItemNumber.concat("00", Digit3, Digit4, Digit5);
        break;

      case "1":
        ManufacturerNumber = ManufacturerNumber.concat(
          Digit1,
          Digit2,
          Digit6,
          "00"
        );
        ItemNumber = "00" + Digit3 + Digit4 + Digit5;
        break;

      case "2":
        ManufacturerNumber = Digit1 + Digit2 + Digit6 + "00";
        ItemNumber = "00" + Digit3 + Digit4 + Digit5;
        break;

      case "3":
        ManufacturerNumber = Digit1 + Digit2 + Digit3 + "00";
        ItemNumber = "000" + Digit4 + Digit5;
        break;

      case "4":
        ManufacturerNumber = Digit1 + Digit2 + Digit3 + Digit4 + "0";
        ItemNumber = "0000" + Digit5;
        break;

      default:
        ManufacturerNumber = ManufacturerNumber.concat(
          Digit1,
          Digit2,
          Digit3,
          Digit4,
          Digit5
        );
        ItemNumber = ItemNumber.concat("0000", Digit6);
        break;
    } //End Select

    // put the number system digit "0" together with the manufacturer code and Item number
    Msg = Msg.concat("0", ManufacturerNumber, ItemNumber);
    const CheckChar = CalcCheckDigit(Msg);
    //return(Msg + CheckChar);	// put the pieces together and return
    if (!isNaN(CheckChar)) {
      // document.forms["UPC"].txtUPCA.value = Msg.concat(CheckChar);
      // document.forms["UPC"].txtChk.value = CheckChar;
      code = Msg.concat(CheckChar);
    } //End If isNaN
  } //End If is numeric
  else {
    // alert("UPCs must contain numeric data only!");
    // document.forms["UPC"].txtUPCE.value = "";
    // document.forms["UPC"].txtUPCE.focus();
  }
  return code;
} //End Function

function CalcCheckDigit(strMsg) {
  // calculate the check digit - note UPCE and UPCA check digits are the same
  let Check = 0; // initialize the check digit value
  for (let X = 1; X <= 11; X++) {
    let Test = strMsg.substr(X - 1, 1);
    if (isOdd(X) == true) {
      Check = Check + parseInt(Test) * 7; // odd position digits multiplied by 7
    } else {
      Check = Check + parseInt(Test) * 9; // even position digits multiplied by 9
    }
  }
  Check = (Check % 10) + 48; // convert value to ASCII character value;
  return charFromCharCode(Check); // check character
}

function charFromCharCode(charCode) {
  return unescape("%" + charCode.toString(16));
}

function isEven(y) {
  return y % 2 ? false : true;
}

function isOdd(y) {
  return !isEven(y);
}

export function validateLengthofString(str, min, max) {
  if (str !== "") return str.length > min && str.length <= max ? true : false;
  else return true;
}

export function currencyFormat(str, nosymbol, usesymboloverride) {
  let result = "";
  let printcursymbol = "";
  const setting = getSettingData(GENERALSETIINGDATA);
  const currencySymbol =
    (setting?.FORMATES && setting?.FORMATES?.Currency_Symbol) ??
    setting?.FORMATES?.Currency_Symbol;
  const CurrencyDecimals =
    (setting?.FORMATES && setting?.FORMATES?.Currency_Decimals) ??
    setting?.FORMATES?.Currency_Decimals;
  const DecimalSeparator =
    (setting?.FORMATES && setting?.FORMATES?.Decimal_Separator) ??
    setting?.FORMATES?.Decimal_Separator;
  const ThousandSeparator =
    (setting?.FORMATES && setting?.FORMATES?.Thousand_Separator) ??
    setting?.FORMATES?.Thousand_Separator;
  const SymbolPosition =
    (setting?.FORMATES && setting?.FORMATES?.Symbol_Position) ??
    setting?.FORMATES?.Symbol_Position;
  try {
    if (CurrencyDecimals || DecimalSeparator || ThousandSeparator)
      result = number_format(
        parseFloat(str),
        CurrencyDecimals,
        DecimalSeparator,
        ThousandSeparator
      );
    else result = number_format(parseFloat(str), 2, ".", "");
    let sign = "";
    if (Math.sign(result) === -1) {
      sign = "-";
      result = result.replace("-", "");
    }
    let cursymbol = "";
    if (!nosymbol) {
      cursymbol =
        printcursymbol !== "" && usesymboloverride
          ? printcursymbol
          : currencySymbol;
      if (
        CurrencyDecimals ||
        DecimalSeparator ||
        (ThousandSeparator && cursymbol)
      ) {
        result =
          parseInt(SymbolPosition) === 1
            ? cursymbol + result
            : result + cursymbol;
      } else {
        result = "$" + number_format(result, 2, ".", "");
      }
    }
    return sign + result;
  } catch (ee) {
    console.log("str ", str);
    return str;
  }
}

export function isNumericData(value) {
  return value && !isNaN(parseFloat(value)) && isFinite(value);
}

export function dateFormate(timestamp) {
  if (
    timestamp === "" ||
    !timestamp ||
    timestamp === "null" ||
    timestamp == null
  ) {
    return "";
  }
  // get the config if available
  let date = new Date(timestamp);
  const general = getSettingData(GENERALSETIINGDATA);
  const timezone =
    (general?.FORMATES && general?.FORMATES?.Timezone) ??
    general?.FORMATES?.Timezone;
  const dateFormat =
    (general?.FORMATES && general?.FORMATES?.Date_Format) ?
      general?.FORMATES?.Date_Format : "d/m/y";
  if (timezone) {
    date = new Date(
      new Date(timestamp).toLocaleString("en-US", { timeZone: timezone })
    );
  }
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let min = date.getMinutes();
  let sec = date.getSeconds();
  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  if (sec < 10) sec = "0" + sec;
  let datestr;
  if (dateFormat === "d/m/y" || dateFormat === "m/d/y") {
    datestr =
      (dateFormat === "d/m/y" ? day + "/" + month : month + "/" + day) +
      "/" +
      year.toString().substring(2, 4) +
      " " +
      hour +
      ":" +
      min +
      ":" +
      sec;
  } else if (dateFormat === "MM/DD/YYYY hh:mm A") {
    datestr =
      month + "/" + day + "/" + year + " " + hour + ":" + min + ":" + sec;
  } else {
    datestr =
      year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
  }
  return datestr;
}

export function dateFormateStructure(timestamp) {
  if (
    timestamp === "" ||
    !timestamp ||
    timestamp === "null" ||
    timestamp == null
  ) {
    return "";
  }
  // get the config if available
  let date = new Date(timestamp);
  const general = getSettingData(GENERALSETIINGDATA);
  const timezone =
    (general?.FORMATES && general?.FORMATES?.Timezone) ??
    general?.FORMATES?.Timezone;
  const dateFormat =
    (general?.FORMATES && general?.FORMATES?.Date_Format) ?
      general?.FORMATES?.Date_Format : "d/m/y";
  if (timezone) {
    date = new Date(timestamp);
  }
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let min = date.getMinutes();
  let sec = date.getSeconds();
  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  if (sec < 10) sec = "0" + sec;
  let datestr;
  if (dateFormat === "d/m/y" || dateFormat === "m/d/y") {
    datestr =
      (dateFormat === "d/m/y" ? day + "/" + month : month + "/" + day) +
      "/" +
      year.toString().substring(2, 4) +
      " " +
      hour +
      ":" +
      min +
      ":" +
      sec;
  } else if (dateFormat === "MM/DD/YYYY hh:mm A") {
    datestr =
      month + "/" + day + "/" + year + " " + hour + ":" + min + ":" + sec;
  } else {
    datestr =
      year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
  }
  return datestr;
}

// javascript equiv number_format
function number_format(number, decimals, dec_point, thousands_sep) {
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  let n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return "" + (Math.round(n * k) / k).toFixed(prec);
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

export function roundToNearestCents(cents, value) {
  const x = 100 / parseInt(cents);
  return value > 0
    ? (Math.ceil(value * x) / x).toFixed(2)
    : (Math.floor(value * x) / x).toFixed(2);
}

export function PusherUpdateFunction(type, listArray, pusherData) {
  if (type === "put") {
    const Index = listArray.findIndex(
      (list) => list.id === parseInt(pusherData.id)
    );
    listArray[Index] = pusherData;
  } else if (type === "add") {
    listArray.unshift(pusherData);
  } else if (type === "delete") {
    const Index = listArray.findIndex(
      (data) => data.id === parseInt(pusherData.id)
    );
    listArray.splice(Index, 1);
  }
  return listArray;
}

function replaceLastHyphen(inputString) {
  if (inputString === "-") {
    return "-0";
  }
  // Use a regular expression to match a hyphen at the end of the string
  const regex = /-$/;

  // Use the replace method to replace the matched hyphen with an empty string
  const resultString = inputString?.toString()?.replace(regex, "");

  return resultString;
}

export function qtyFormat(decimalQty, qty) {
  qty = replaceLastHyphen(qty);
  let posSetting = getSettingData(POSSETTINGDATA);
  if (posSetting?.SALE?.allow_decimal_qty_sale === "1") {
    return qty;
  } else {
    // Remove dots from the beginning (^) and end ($) of the string
    qty = qty?.toString().replace(/^\.+|\.+$/g, "");
    if (qty == -0 || qty === "-0") {
      return qty;
    }
    return parseInt(qty);
  }
}

export function checkEmptyandZeroValue(value, returnValue) {
  if (
    value === "" ||
    value === 0 ||
    value === parseFloat(0) ||
    value === undefined ||
    value === null
  )
    return 0;
  else
    return returnValue !== undefined
      ? returnValue
      : parseFloat(value).toFixed(2);
}

export function validateInputVal(value, colVal, clearValue, dir, EnableKeypadDecimal) {
  colVal = String(colVal) || "";
  if (clearValue) {
    colVal = "";
  }
  if (EnableKeypadDecimal == 1 && dir == 0) {
    if (colVal?.length == 1) {
      colVal = "00" + colVal
    } else if (colVal?.length == 2) {
      colVal = "0" + colVal
    }
  }
  if (dir < colVal?.length) {
    if (dir == 0) {
      return value + colVal;
    } else {
      return colVal.slice(0, dir) + value + colVal.slice(dir);
    }
  } else {
    return colVal + value;
  }

}

export function containsDuplicates(arr, key) {
  const uniqueValues = new Set(arr.map((v) => v[key]));
  if (uniqueValues.size < arr.length) return false;
  else return true;
}

export function validateInputField(value) {
  if (!/^\s/.test(value)) return true;
  else return false;
}

export function VailidateImageExtention(file) {
  if (!file.name.match(/\.(jpg|jpeg|png)$/)) return false;
  else return true;
}

export function checkValue(value) {
  if (value !== "" && value !== undefined && value !== "0") return true;
  else return false;
}

export const isWeb = () => {
  var userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf(" electron/") > -1) {
    return false;
    // Electron-specific code
  } else {
    return true;
  }
};

function isBase64(str) {
  str = str.split("base64,")[1];
  if (!str) {
    return false;
  }
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}

export function FormatImageBase64Code(str) {
  if (!isBase64(str)) {
    return str;
  }
  if (str !== undefined && str !== null)
    return str.replace(/['"]+/g, "").trim();
  else return "-";
}

//HEX TO BASE64
export function hexToBase64(str) {
  return Base64.btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" ")
    )
  );
}

//BASE64 TO HEX
export function base64ToHex(str) {
  for (var i = 0, bin = Base64.atob(str), hex = []; i < bin.length; ++i) {
    var tmp = bin.charCodeAt(i).toString(16);
    if (tmp.length === 1) tmp = "0" + tmp;
    hex[hex.length] = tmp;
  }
  return hex.join(" ");
}

//String to Hex
export function StringToHex(response) {
  var responseHex = "";
  for (var i = 0; i < response.length; i++) {
    if (responseHex == "")
      responseHex =
        response.charCodeAt(i).toString(16).length < 2
          ? "0" + response.charCodeAt(i).toString(16)
          : response.charCodeAt(i).toString(16);
    else
      responseHex +=
        response.charCodeAt(i).toString(16).length < 2
          ? " " + "0" + response.charCodeAt(i).toString(16)
          : " " + response.charCodeAt(i).toString(16);
  }
  return responseHex;
}

//Hex to String
export function HexToString(response) {
  var responseHex = "";
  var arr = response.split(" ");
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] == "") continue;
    responseHex += String.fromCharCode(parseInt(arr[i], 16));
  }
  return responseHex;
}

//Get LRC
export const getLRC = (params) => {
  var lrc = 0;
  for (let i = 1; i < params.length; i++) {
    var type_of = typeof params[i];
    if (type_of == "string") {
      var element = params[i].split("");
      for (let ii = 0; ii < element.length; ii++) {
        lrc ^= element[ii].charCodeAt(0);
      }
    } else {
      lrc ^= params[i];
    }
  }
  return lrc > 0 ? String.fromCharCode(lrc) : 0;
};

export const InitializePax = () => {
  var initialInfo = {
    command: "A00",
    version: "1.28",
  };
  var PAX = {
    mStx: {
      hex: 0x02,
      code: "02",
    },

    mFS: {
      hex: 0x1c,
      code: "1c",
    },

    mEtx: {
      hex: 0x03,
      code: "03",
    },

    mUS: {
      hex: 0x1f,
      code: "1F",
    },
    mDestinationIP: "http://127.0.0.1:10009", // - OLD "http://192.167.2.100:10009";       //http://112.199.49.146:8181
    timeout: {
      Initialize: 120 * 1000,
      GetSignature: 120 * 1000,
      DoSignature: 120 * 1000,
      DoCredit: 120 * 1000,
    },
  };
  var params = [
    PAX.mStx.hex,
    initialInfo.command,
    PAX.mFS.hex,
    initialInfo.version,
    PAX.mEtx.hex,
  ];
  //[02]A08[1c]1.28[1c]0[1c]90000[03]
  //var params = [0x02,"A08",0x1c,"1.28",0x1c, "0", 0x1c,"90000",0x03];
  var lrc = getLRC(params);

  var command_hex = base64ToHex(Base64.btoa(initialInfo.command));
  var version_hex = base64ToHex(Base64.btoa(initialInfo.version));
  //var elements = [this.mStx, command_hex, this.mFS, version_hex, this.mEtx, base64ToHex(Base64.btoa(lrc))];
  var elements = [
    PAX.mStx.code,
    command_hex,
    PAX.mFS.code,
    version_hex,
    PAX.mEtx.code,
    base64ToHex(Base64.btoa(lrc)),
  ];

  var final_string = elements.join(" ");
  //console.log("final_string: " + final_string);

  var final_b64 = hexToBase64(final_string);
  console.log("LRC: " + lrc);
  console.log("Base64: " + final_b64);
  var url = PAX.mDestinationIP + "?" + final_b64;
  console.log("URL: " + url);

  HttpCommunication(
    "Initialize",
    url,
    function () {
      // callback(response);
    },
    PAX.timeout.Initialize
  );
};

export const HttpCommunication = (commandType, url, callback, timeout) => {
  $.ajax({
    url: url,
    timeout: timeout,
    error: function (xmlHttpRequest, error) {
      // setTitle("Error");
      // setErrorMsg("Please Manually Power Cycle the Card machine and Relogin");
      // setPaxMsg(true);
      console.log("Ajax error info: " + error);
      callback(error);
      return 0;
    },
    success: function (response) {
      console.log("success");
      console.log("Raw response: " + response);

      var checkParams = StringToHex(response).split(" ").pop();
      var RedundancyCheck = StringToHex(response).split(" ").pop().substring(1);

      var check = getLRC(checkParams);

      if (check == RedundancyCheck) {
        //get package detail info
        var packetInfo = [];
        var len = StringToHex(response).indexOf("03");
        var hex = StringToHex(response).slice(0, len).split(/02|1c/);

        console.log(hex);
        if (commandType == "DoCredit") {
          var subHex = [],
            subPacketInfo = [];
          for (var i = 0; i < hex.length; i++) {
            if (hex[i] != "") {
              if (hex[i].indexOf("1f") > 0) {
                subHex = hex[i].split("1f");
                console.log(subHex);
                subPacketInfo = [];
                for (var j = 0; j < subHex.length; j++) {
                  if (subHex[j] != "") {
                    subPacketInfo.push(HexToString(subHex[j]));
                  }
                }
                console.log(subPacketInfo);
                packetInfo.push(subPacketInfo);
              } else {
                packetInfo[i] = HexToString(hex[i]);
              }
            }
          }
        } else {
          for (var i = 0; i < hex.length; i++) {
            if (hex[i] != "") {
              packetInfo[i] = HexToString(hex[i]);
            }
          }
        }

        console.log("Separate package info: ");
        console.log(packetInfo);
        callback(packetInfo);
        return 1;
      }
    },
  });
};

export const AddBase64 = (elements, type, objectInfo) => {
  //console.log(objectInfo);
  var PAX = {
    mStx: {
      hex: 0x02,
      code: "02",
    },

    mFS: {
      hex: 0x1c,
      code: "1c",
    },

    mEtx: {
      hex: 0x03,
      code: "03",
    },

    mUS: {
      hex: 0x1f,
      code: "1F",
    },
  };
  var empty = 0;
  var arr = [];
  arr = arr.concat(elements);
  for (let name in objectInfo) {
    if (objectInfo[name] == "" && type != "additionalInformation") {
      arr.push(PAX.mUS.code);
      continue;
    }
    if (type == "additionalInformation") {
      if (objectInfo[name] == "") continue;
      empty++;
      arr.push(
        base64ToHex(Base64.btoa(name + "=" + objectInfo[name].toString()))
      );
    } else {
      empty++;
      arr.push(base64ToHex(Base64.btoa(objectInfo[name].toString())));
    }
    arr.push(PAX.mUS.code);
  }
  arr.pop();
  if (empty == 0 && type != "additionalInformation") {
    arr = elements;
  }
  if (empty == 0 && type == "additionalInformation") {
    arr.push(PAX.mFS.code);
  }
  //console.log(arr);
  return arr;
};

export const PushParams = (params, type, objectInfo) => {
  var empty = 0;
  var arr = [];
  arr = arr.concat(params);
  var PAX = {
    mStx: {
      hex: 0x02,
      code: "02",
    },

    mFS: {
      hex: 0x1c,
      code: "1c",
    },

    mEtx: {
      hex: 0x03,
      code: "03",
    },

    mUS: {
      hex: 0x1f,
      code: "1F",
    },
  };
  for (let name in objectInfo) {
    if (objectInfo[name] == "" && type != "additionalInformation") {
      arr.push(PAX.mUS.hex);
      continue;
    }

    if (type == "additionalInformation") {
      if (objectInfo[name] == "") {
        continue;
      }
      empty++;
      arr.push(name + "=" + objectInfo[name].toString());
    } else {
      empty++;
      arr.push(objectInfo[name].toString());
    }
    arr.push(PAX.mUS.hex);
  }
  arr.pop();
  if (empty == 0 && type != "additionalInformation") {
    arr = params;
  }
  if (empty == 0 && type == "additionalInformation") {
    arr.push(PAX.mFS.hex);
  }
  //console.log(params);
  return arr;
};

//Batch History
export const getBatchHistory = (paxConfig) => {
  const reportInfo = {
    command: "R08",
    version: "1.28",
  };
  var PAX = {
    mStx: {
      hex: 0x02,
      code: "02",
    },

    mFS: {
      hex: 0x1c,
      code: "1c",
    },

    mEtx: {
      hex: 0x03,
      code: "03",
    },

    mUS: {
      hex: 0x1f,
      code: "1F",
    },
    // mDestinationIP: "http://127.0.0.1:10009", // - OLD "http://192.167.2.100:10009";       //http://112.199.49.146:8181
    mDestinationIP: `${paxConfig?.pax_protocol == ""
      ? "http"
      : paxConfig?.pax_protocol == "1"
        ? "http"
        : "https"
      }://${paxConfig?.pax_ip == "" ? "127.0.0.1" : paxConfig?.pax_ip}:${paxConfig?.pax_port == "" ? "10009" : paxConfig?.pax_port
      }`,
    timeout: {
      Initialize: 120 * 1000,
      GetSignature: 120 * 1000,
      DoSignature: 120 * 1000,
      DoCredit: 120 * 1000,
    },
  };
  var params = [
    PAX.mStx.hex,
    reportInfo.command,
    PAX.mFS.hex,
    reportInfo.version,
    PAX.mEtx.hex,
  ];

  var lrc = getLRC(params);
  var command_hex = base64ToHex(Base64.btoa(reportInfo.command));
  var version_hex = base64ToHex(Base64.btoa(reportInfo.version));

  var elements = [
    PAX.mStx.code,
    command_hex,
    PAX.mFS.code,
    version_hex,
    PAX.mEtx.code,
    base64ToHex(Base64.btoa(lrc)),
  ];

  var final_string = elements.join(" ");
  var final_b64 = hexToBase64(final_string);
  var url = PAX.mDestinationIP + "?" + final_b64;

  return { url: url, timeout: PAX.timeout.Reset };
};

export const parseBatchHistory = (response) => {
  var PacketageInfo = {
    Initialize: {},
    GetSignature: {},
    DoSignature: {},
    DoCredit: {},
    LocalTotalReport: {},
    BatchHistory: {},
  };

  var checkParams = StringToHex(response).split(" ").pop();
  var RedundancyCheck = StringToHex(response).split(" ").pop().substring(1);

  var check = getLRC(checkParams);

  if (check == RedundancyCheck) {
    //get package detail info
    var packetInfo = [];
    var len = StringToHex(response).indexOf("03");
    var hex = StringToHex(response).slice(0, len).split(/02|1c/);

    for (var i = 0; i < hex.length; i++) {
      if (hex[i] != "") {
        packetInfo[i] = HexToString(hex[i]);
      }
    }
    response = packetInfo;
  }

  if (typeof response == "string") {
    // WPOS.util.okDialog("Error", response, function (dat) { });
    return true;
  }

  var i = 0,
    j = -1;
  PacketageInfo.BatchHistory.Status = response[++i];
  PacketageInfo.BatchHistory.Command = response[++i];
  PacketageInfo.BatchHistory.Version = response[++i];
  PacketageInfo.BatchHistory.ResponseCode = response[++i];
  PacketageInfo.BatchHistory.ResponseMessage = response[++i];
  PacketageInfo.BatchHistory.TotalCount = response[++i];
  PacketageInfo.BatchHistory.TotalAmount = response[++i];
  PacketageInfo.BatchHistory.StartTime = response[++i];
  return PacketageInfo.BatchHistory;
};

//Local total report
export const GetLocalTotalReport = (paxConfig) => {
  const reportInfo = {
    command: "R00",
    version: "1.28",
    edcType: "00",
    transactionType: "01",
  };
  var PAX = {
    mStx: {
      hex: 0x02,
      code: "02",
    },

    mFS: {
      hex: 0x1c,
      code: "1c",
    },

    mEtx: {
      hex: 0x03,
      code: "03",
    },

    mUS: {
      hex: 0x1f,
      code: "1F",
    },
    // mDestinationIP: "http://127.0.0.1:10009", // - OLD "http://192.167.2.100:10009";       //http://112.199.49.146:8181
    mDestinationIP: `${paxConfig?.pax_protocol == ""
      ? "http"
      : paxConfig?.pax_protocol == "1"
        ? "http"
        : "https"
      }://${paxConfig?.pax_ip == "" ? "127.0.0.1" : paxConfig?.pax_ip}:${paxConfig?.pax_port == "" ? "10009" : paxConfig?.pax_port
      }`,
    timeout: {
      Initialize: 120 * 1000,
      GetSignature: 120 * 1000,
      DoSignature: 120 * 1000,
      DoCredit: 120 * 1000,
    },
  };
  var params = [
    PAX.mStx.hex,
    reportInfo.command,
    PAX.mFS.hex,
    reportInfo.version,
    PAX.mFS.hex,
    reportInfo.edcType,
    PAX.mFS.hex,
    PAX.mEtx.hex,
  ];

  var lrc = getLRC(params);
  var command_hex = base64ToHex(Base64.btoa(reportInfo.command));
  var version_hex = base64ToHex(Base64.btoa(reportInfo.version));
  var edc_type = base64ToHex(Base64.btoa(reportInfo.edcType));

  var elements = [
    PAX.mStx.code,
    command_hex,
    PAX.mFS.code,
    version_hex,
    PAX.mFS.code,
    edc_type,
    PAX.mFS.code,
    PAX.mEtx.code,
    base64ToHex(Base64.btoa(lrc)),
  ];

  var final_string = elements.join(" ");
  var final_b64 = hexToBase64(final_string);
  var url = PAX.mDestinationIP + "?" + final_b64;

  return { url: url, timeout: PAX.timeout.Reset };
};

export const parseReportTotalResponse = (response) => {
  var checkParams = StringToHex(response).split(" ").pop();
  var RedundancyCheck = StringToHex(response).split(" ").pop().substring(1);

  var check = getLRC(checkParams);

  if (check == RedundancyCheck) {
    //get package detail info
    var packetInfo = [];
    var len = StringToHex(response).indexOf("03");
    var hex = StringToHex(response).slice(0, len).split(/02|1c/);

    for (var i = 0; i < hex.length; i++) {
      if (hex[i] != "") {
        packetInfo[i] = HexToString(hex[i]);
      }
    }
    response = packetInfo;
  }

  if (typeof response == "string") {
    // WPOS.util.okDialog("Error", response, function (dat) { });
    return true;
  }

  var i = 0,
    j = -1;
  var PacketageInfo = {
    Initialize: {},
    GetSignature: {},
    DoSignature: {},
    DoCredit: {},
    LocalTotalReport: {},
    BatchHistory: {},
  };

  PacketageInfo.LocalTotalReport.Status = response[++i];
  PacketageInfo.LocalTotalReport.Command = response[++i];
  PacketageInfo.LocalTotalReport.Version = response[++i];
  PacketageInfo.LocalTotalReport.ResponseCode = response[++i];
  PacketageInfo.LocalTotalReport.ResponseMessage = response[++i];
  PacketageInfo.LocalTotalReport.EdcType = response[++i];
  PacketageInfo.LocalTotalReport.CardsTotal = {};
  PacketageInfo.LocalTotalReport.TotalCount = 0;
  PacketageInfo.LocalTotalReport.TotalAmount = 0;

  const repdata = response[++i];
  if (typeof repdata === "string") {
    const breakRep = repdata.split("&");
    const creditBreak = breakRep[0].split("=");
    const debitBreak = breakRep[1].split("=");
    const ebtBreak = breakRep[2].split("=");
    const giftBreak = breakRep[3].split("=");
    const loyaltyBreak = breakRep[4].split("=");
    const cashBreak = breakRep[5].split("=");
    const checkBreak = breakRep[6].split("=");

    const allCardData = {
      CREDIT: {
        saleCount: creditBreak[0],
        SaleAmount: parseFloat(creditBreak[1] / 100),
        forcedCount: creditBreak[2],
        forcedAmount: parseFloat(creditBreak[3] / 100),
        returnCount: creditBreak[4],
        returnAmount: parseFloat(creditBreak[5] / 100),
        authCount: creditBreak[6],
        authAmount: parseFloat(creditBreak[7] / 100),
        postauthCount: creditBreak[8],
        postauthAmount: parseFloat(creditBreak[9] / 100),
      },
      DEBIT: {
        saleCount: debitBreak[0],
        SaleAmount: parseFloat(debitBreak[1] / 100),
        returnCount: debitBreak[2],
        returnAmount: parseFloat(debitBreak[3] / 100),
      },
      EBT: {
        saleCount: ebtBreak[0],
        SaleAmount: parseFloat(ebtBreak[1] / 100),
        returnCount: ebtBreak[2],
        returnAmount: parseFloat(ebtBreak[3] / 100),
        withdrawalCount: ebtBreak[4],
        withdrawalAmount: parseFloat(ebtBreak[5] / 100),
      },
      GIFT: {
        saleCount: giftBreak[0],
        SaleAmount: parseFloat(giftBreak[1] / 100),
        authCount: giftBreak[2],
        authAmount: parseFloat(giftBreak[3] / 100),
        postauthCount: giftBreak[4],
        postauthAmount: parseFloat(giftBreak[5] / 100),
        activateCount: giftBreak[6],
        activateAmount: parseFloat(giftBreak[7] / 100),
        issueCount: giftBreak[8],
        issueAmount: parseFloat(giftBreak[9] / 100),
        addCount: giftBreak[10],
        addAmount: parseFloat(giftBreak[11] / 100),
        returnCount: giftBreak[12],
        returnAmount: parseFloat(giftBreak[13] / 100),
        forcedCount: giftBreak[14],
        forcedAmount: parseFloat(giftBreak[15] / 100),
        cashoutCount: giftBreak[16],
        cashoutAmount: parseFloat(giftBreak[17] / 100),
        deactivateCount: giftBreak[18],
        deactivateAmount: parseFloat(giftBreak[19] / 100),
        adjustCount: giftBreak[20],
        adjustAmount: parseFloat(giftBreak[21] / 100),
      },
      LOYALTY: {
        redeemCount: loyaltyBreak[0],
        redeemAmount: parseFloat(loyaltyBreak[1] / 100),
        issueCount: loyaltyBreak[2],
        issueAmount: parseFloat(loyaltyBreak[3] / 100),
        addCount: loyaltyBreak[4],
        addAmount: parseFloat(loyaltyBreak[5] / 100),
        returnCount: loyaltyBreak[6],
        returnAmount: parseFloat(loyaltyBreak[7] / 100),
        forcedCount: loyaltyBreak[8],
        forcedAmount: parseFloat(loyaltyBreak[9] / 100),
        activateCount: loyaltyBreak[10],
        activateAmount: parseFloat(loyaltyBreak[11] / 100),
        deactivateCount: loyaltyBreak[12],
        deactivateAmount: parseFloat(loyaltyBreak[13] / 100),
      },
      CASH: {
        saleCount: cashBreak[0],
        SaleAmount: parseFloat(cashBreak[1] / 100),
        returnCount: cashBreak[2],
        returnAmount: parseFloat(cashBreak[3] / 100),
      },
      CHECK: {
        saleCount: checkBreak[0],
        SaleAmount: parseFloat(checkBreak[1] / 100),
        AdjustCount: checkBreak[2],
        AdjustAmount: parseFloat(checkBreak[3] / 100),
      },
    };

    PacketageInfo.LocalTotalReport.CardsTotal = allCardData;

    PacketageInfo.LocalTotalReport.TotalCount =
      parseInt(allCardData["CREDIT"]["saleCount"]) +
      parseInt(allCardData["DEBIT"]["saleCount"]) +
      parseInt(allCardData["EBT"]["saleCount"]) +
      parseInt(allCardData["GIFT"]["saleCount"]) +
      parseInt(allCardData["CASH"]["saleCount"]) +
      parseInt(allCardData["CHECK"]["saleCount"]);
    PacketageInfo.LocalTotalReport.TotalAmount =
      parseFloat(allCardData["CREDIT"]["SaleAmount"]) +
      parseFloat(allCardData["DEBIT"]["SaleAmount"]) +
      parseFloat(allCardData["EBT"]["SaleAmount"]) +
      parseFloat(allCardData["GIFT"]["SaleAmount"]) +
      parseFloat(allCardData["CASH"]["SaleAmount"]) +
      parseFloat(allCardData["CHECK"]["SaleAmount"]);
  }

  return PacketageInfo.LocalTotalReport;
};

export const getDateFromTimestamp = (
  timestamp,
  format,
  currentlocale = false,
  timeformat = "24",
  type = null,
  minutes = null
) => {
  const general = getSettingData(GENERALSETIINGDATA);
  let timezone =
    (general?.FORMATES && general?.FORMATES?.Timezone) ??
    general?.FORMATES?.Timezone;
  let dateFormat =
    (general?.FORMATES && general?.FORMATES?.Date_Format) ??
    general?.FORMATES?.Date_Format;

  if (type != null && type == "addition" && minutes != null) {
    if (!currentlocale && timezone) {
      var date = new Date(timestamp + minutes * 60000).toLocaleString("en-US", {
        timeZone: timezone,
      });
      date = new Date(date);
    } else {
      var date = new Date(timestamp + minutes * 60000);
    }
  } else if (type != null && type == "subtraction" && minutes != null) {
    if (!currentlocale && timezone) {
      var date = new Date(timestamp - minutes * 60000).toLocaleString("en-US", {
        timeZone: timezone,
      });
      date = new Date(date);
    } else {
      var date = new Date(timestamp - minutes * 60000);
    }
  } else {
    if (!currentlocale && timezone) {
      var date = new Date(timestamp).toLocaleString("en-US", {
        timeZone: timezone,
      });
      date = new Date(date);
    } else {
      var date = new Date(timestamp);
    }
  }

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();

  if (timeformat === "12") {
    sec = " AM";
    if (hour > 12) {
      hour = hour - 12;
      sec = " PM";
    } else if (hour == 0) {
      hour = hour + 12;
      sec = " AM";
    } else if (hour == 12) {
      hour = hour;
      sec = " PM";
    }
    hour = hour.toString();
    min = min.toString();
    if (hour < 10) hour = "0" + hour;
    if (min < 10) min = "0" + min;
  } else {
    if (hour < 10) hour = "0" + hour;

    if (min < 10) min = "0" + min;

    if (sec < 10) sec = "0" + sec;
  }

  var datestr;
  if (dateFormat == "d/m/y" || dateFormat == "m/d/y") {
    datestr =
      (dateFormat == "d/m/y" ? day + "/" + month : month + "/" + day) +
      "/" +
      year.toString().substring(2, 4) +
      " " +
      hour +
      ":" +
      min +
      ":" +
      sec;
  } else if (dateFormat == "MM/DD/YYYY hh:mm A") {
    datestr =
      month + "/" + day + "/" + year + " " + hour + ":" + min + ":" + sec;
  } else {
    datestr =
      year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
  }
  return datestr;
};

export const diffrenceBetweenTwoTimeStamp = (start, end) => {
  if (start == null) {
    //start = new Date().getTime();
    return "NA";
  }
  if (end == null) {
    //end = new Date().getTime();
    return "NA";
  }
  var Date1 = new Date(parseInt(start));
  var Date2 = new Date(parseInt(end));
  var seconds = Math.floor((Date1 - Date2) / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);
  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  hours = hours + days * 24;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  hours = hours < 10 ? "0" + hours : hours;
  var returns = hours + ":" + minutes + ":" + seconds;
  return returns;
};

export const isOnline = () => {
  console.log(`online status`, window.navigator.onLine);
  // if(window.navigator.onLine == true){
  //   return true;
  // }else{
  //   return false;
  // }
  return window.navigator.onLine;
};

export const OfflineData = (callback) => {
  if (!isWeb()) {
    window.knex = window.Knex({
      client: "sqlite3",
      debug: false,
      acquireConnectionTimeout: 180000,
      connection: {
        filename: window.appData + "database.sqlite",
      },
      useNullAsDefault: true,
      log: {
        warn(message) {
          console.log(message);
        },
        error(message) {
          console.log(message);
        },
        deprecate(message) {
          console.log(message);
        },
        debug(message) {
          //console.log(message)
        },
      },
    });
    window.knex.schema.hasTable("data").then(function (exists) {
      if (!exists) {
        return window.knex.schema.createTable("data", function (t) {
          t.string("key", 100).primary();
          t.jsonb("data");
        });
      }
    });
    window.knex.schema.hasTable("wpos_osales").then(function (exists) {
      if (!exists) {
        return window.knex.schema.createTable("wpos_osales", function (t) {
          t.string("ref", 100).primary();
          t.jsonb("data");
        });
      }
    });
    var query = window.knex("wpos_osales").select("*");
    query
      .then((rows) => {
        console.log("rows", rows);
        if (rows) {
          var jsondata = {};
          for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            jsondata = JSON.parse(element.data);
          }
          callback(jsondata);
        } else {
          callback({});
        }
      })
      .catch(function (e) {
        console.error("error reading from db", e);
        callback({});
      });
  } else {
    const wpos_osales = localStorage.getItem("wpos_osales");
    callback(wpos_osales);
  }
};

export const Queue = () => {
  Object.defineProperties(this, {
    add: {
      enumerable: true,
      writable: false,
      value: addToQueue,
    },
    next: {
      enumerable: true,
      writable: false,
      value: run,
    },
    clear: {
      enumerable: true,
      writable: false,
      value: clearQueue,
    },
    contents: {
      enumerable: false,
      get: getQueue,
      set: setQueue,
    },
    autoRun: {
      enumerable: true,
      writable: true,
      value: true,
    },
    stop: {
      enumerable: true,
      writable: true,
      value: false,
    },
  });

  var queue = [];
  var running = false;
  var stop = false;

  function clearQueue() {
    queue = [];
    return queue;
  }

  function getQueue() {
    return queue;
  }

  function setQueue(val) {
    queue = val;
    return queue;
  }

  function addToQueue() {
    for (var i in arguments) {
      queue.push(arguments[i]);
    }
    if (!running && !this.stop && this.autoRun) {
      this.next();
    }
  }

  function run() {
    running = true;
    if (queue.length < 1 || this.stop) {
      running = false;
      return;
    }

    queue.shift().bind(this)();
  }
};

export const numDigits = (x) => {
  return (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1;
};

const getSubKey = (type) => {
  if (type === "item") {
    return "stored_item";
  } else if (type === "category") {
    return "category";
  } else if (type === "size") {
    return "supplier";
  } else if (type === "tag") {
    return "tag";
  } else {
    return "";
  }
};

function findNearestNumbers(input, arr) {
  console.log(input, arr,"input, arr");
  arr.sort((a, b) => a - b);

  let smaller = null;
  let greater = null;

  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i] <= input) {
      smaller = arr[i];
      break;
    }
  }

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] >= input) {
      greater = arr[i];
      break;
    }
  }

  return { smaller, greater };
}

export const checkCustomerInPromotion = (item , customer) => {
  if(item.all_customer){
    return true;
  };
  
  if((!item?.customer?.length && !item?.customergroup?.length) || !customer){
    return false;
  }

  let custIds = item.customer.map((e) => e.customer_id);

  let custGIds = item?.customergroup?.map((e) => {
    let customersInGroup = JSON.parse(e.customergroupdetail?.data || "{}");
    return customersInGroup.map((it) => it.id);
  });

  let found = false;
  if (custGIds?.length) {
    for (let customer of custIds) {
      if (customer.includes(customer.id)) {
        found = true;
        break;
      }
    }
  } 
  return custIds.includes(customer.id) || found;
}

const getBestOnePromotion = (
  foundList,
  itemqty,
  itemprice,
  checkForGroupPromo,
  id,
  mainQty,
  currentItm
) => {
  let bestPromo = {
    name: "",
    promotext: undefined,
    updatePrice: 0,
    off: 0,
  };  
  
  for (let item of foundList) {
    let cond = false;
    cond = item.promotionstatus == 1;

    if (item.start_date) {
      cond =
        cond &&
        new Date(item.start_date).getTime() <=
        new Date(moment().startOf("day")).getTime();
    }
    if (item.end_date) {
      cond =
        cond &&
        new Date(moment(item.end_date).endOf("day")).getTime() >=
        new Date(moment().endOf("day")).getTime();
    }

    if (item?.promotion_applied_type === "Weekly") {
      let repeatsOn = JSON.parse(item?.repeats_on);
      var dayName = `repeat_${moment()
        .format("dddd")
        .substring(0, 3)
        .toLowerCase()}`;
      cond = cond && repeatsOn[dayName];
    }
    let groupPromoCond = checkForGroupPromo ? !item.group_promotion : true;
    cond = cond && groupPromoCond;

    if (cond) {
      let data = JSON.parse(item.data || []);
      if (item.promotiontype !== "custom") {
        let nearest = findNearestNumbers(itemqty, data?.map(e => +e.Qty));
        if (nearest.smaller && nearest.greater && nearest.smaller != nearest.greater) {
          data = data.filter(e => e.Qty >= nearest.smaller && e.Qty < nearest.greater)
        } else if (!nearest.smaller) {
          data = []
        } else if (!nearest.greater) {
          data = [data[data.length - 1]]
        } else if (nearest.smaller === nearest.greater) {
          data = data.filter(e => +e.Qty == +nearest.smaller)
        } else {
          data = data
        }
      }
      for (let modifier of data) {
        modifier.Qty = +modifier.Qty;
        modifier.EnterPromotionPrice = +modifier.EnterPromotionPrice;
        let negativeItemQty = +currentItm?.qty < 0;
        if (item.promotiontype === "simple") {
          let off = 0;
          if (itemqty >= +modifier.Qty) {
            let updatedPrice = parseFloat(
              (Math.floor(itemqty / modifier.Qty) *
                modifier.EnterPromotionPrice +
                (itemqty % +modifier.Qty) * itemprice ) /
                mainQty
            );
            off = itemprice - updatedPrice;
            if (bestPromo.off < off) {
              let offPer =
                itemprice - updatedPrice !== Infinity
                  ? (itemprice - updatedPrice) * mainQty
                  : 0;
              bestPromo = {
                name: item.name,
                promoid: item.id,
                promotext: `$${parseFloat(negativeItemQty ? -offPer : offPer).toFixed(2)} off`,
                updatePrice: updatedPrice,
                off,
                type: item.promotiontype,
                promoqty: itemqty,
                scandatapromotion: false,
                couponstatus: false,
                discprice: off,
                customer_tax: item.customer_tax,
              };
            }
          }
        } else if (item.promotiontype === "dollar") {
          let off = 0;
          // modifier.EnterPromotionPrice = (+modifier.EnterPromotionPrice / itemqty) * mainQty;
          if (itemqty >= modifier.Qty) {
            let updatedPrice = parseFloat(
              (parseFloat(itemprice) * mainQty -
                parseFloat(modifier.EnterPromotionPrice) *
                Math.floor(itemqty / modifier.Qty)) /
                mainQty
            );

            off = itemprice - updatedPrice;
            if (bestPromo.off < off) {
              let offPer =
                itemprice - updatedPrice !== Infinity
                  ? (parseFloat(itemprice) - parseFloat(updatedPrice)) * mainQty
                  : 0;
              bestPromo = {
                name: item.name,
                promoid: item.id,
                promotext: `$${parseFloat(negativeItemQty ? -offPer : offPer).toFixed(2)} off`,
                updatePrice: updatedPrice,
                off,
                type: item.promotiontype,
                promoqty: itemqty,
                scandatapromotion: false,
                couponstatus: false,
                discprice: off,
                customer_tax: item.customer_tax,
              };
            }
          }
        } else if (item.promotiontype === "percent") {
          let off = 0;
          if (itemqty >= modifier.Qty) {
            let totalPrice = parseFloat(itemprice);

            let discQty = modifier.Qty * Math.floor(itemqty / modifier.Qty);
            let discPrice = parseFloat(
              totalPrice -
              (parseFloat(modifier.EnterPromotionPrice) / 100) * totalPrice
            );

            let withoutDiscPrice =
              (totalPrice * (itemqty - discQty)) / (itemqty * 2);

            let updatedPrice = discPrice + withoutDiscPrice;

            off = itemprice - updatedPrice;

            if (bestPromo.off < off) {
              let offPer =
                +itemprice - +updatedPrice !== Infinity
                  ? ((parseFloat(itemprice) - parseFloat(updatedPrice)) /
                    parseFloat(itemprice)) *
                  100
                  : 0;
              bestPromo = {
                name: item.name,
                promoid: item.id,
                promotext: `${Math.round(negativeItemQty ? -offPer : offPer)}% off`,
                updatePrice: updatedPrice,
                off,
                promoqty: itemqty,
                type: item.promotiontype,
                scandatapromotion: false,
                couponstatus: false,
                discprice: off,
                customer_tax: item.customer_tax,
              };
            }
          }
        } else if (item.promotiontype === "percentonabove") {
          let off = 0;
          if (itemqty >= modifier.Qty) {
            let totalPrice = parseFloat(itemprice);

            let discPrice = parseFloat(
              totalPrice -
              (parseFloat(modifier.EnterPromotionPrice) / 100) * totalPrice
            );

            let updatedPrice = discPrice;

            off = itemprice - updatedPrice;

            if (bestPromo.off < off) {
              let offPer =
                +itemprice - +updatedPrice !== Infinity
                  ? ((parseFloat(itemprice) - parseFloat(updatedPrice)) /
                    parseFloat(itemprice)) *
                  100
                  : 0;
              bestPromo = {
                name: item.name,
                promoid: item.id,
                promotext: `${Math.round(negativeItemQty ? -offPer : offPer)}% off`,
                updatePrice: updatedPrice,
                off,
                type: item.promotiontype,
                promoqty: itemqty,
                scandatapromotion: false,
                couponstatus: false,
                discprice: off,
                customer_tax: item.customer_tax,
              };
            }
          }
        } else if (item.promotiontype === "fixed") {
          let off = 0;
          if (itemqty >= +modifier.Qty) {
            let updatedPrice = parseFloat(modifier.EnterPromotionPrice);
            off = itemprice * itemqty - updatedPrice;
            if (bestPromo.off < off) {
              let offPer =
                itemprice - updatedPrice !== Infinity
                  ? ((itemprice - updatedPrice) / itemprice) * 100
                  : 0;
              bestPromo = {
                name: item.name,
                promoid: item.id,
                promotext: `${Math.round(negativeItemQty ? -offPer : offPer)}% off`,
                updatePrice: updatedPrice,
                off,
                type: item.promotiontype,
                promoqty: itemqty,
                scandatapromotion: false,
                couponstatus: false,
                discprice: off,
                customer_tax: item.customer_tax,
              };
            }
          }
        } else if (item.promotiontype === "custom") {
          if (+modifier.itemId == +id && modifier.combo) {
            let updatedPrice = +modifier.new_price;
            let off = itemprice - updatedPrice;
            if (bestPromo.off < off) {
              let offPer =
                itemprice - updatedPrice !== Infinity
                  ? ((itemprice - updatedPrice) / itemprice) * 100
                  : 0;
              bestPromo = {
                name: item.name,
                promoid: item.id,
                promotext: `${Math.round(negativeItemQty ? -offPer : offPer)}% off`,
                updatePrice: updatedPrice,
                off,
                type: item.promotiontype,
                promoqty: itemqty,
                scandatapromotion: false,
                couponstatus: false,
                discprice: off,
                customer_tax: item.customer_tax,
              };
            }
          }
        }else if (item.promotiontype === "cost") {
          let off = 0;
          if (itemqty >= +modifier.Qty) {
            // let promoCal = currentItm?.cost / (1 - modifier.EnterPromotionPrice / 100 ) / itemqty
            let promoCal = (currentItm?.cost + (modifier.EnterPromotionPrice / 100) * currentItm?.cost )/ itemqty
            let remaining = (itemqty % modifier.Qty) * currentItm?.cost / itemqty;
            let totalCal = (promoCal * modifier.Qty + remaining) / itemqty
              bestPromo = {
                cost_promo : true,
                name: item.name,
                promoid: item.id,
                promotext: `Special Pricing`,
                updatePrice: totalCal,
                type: item.promotiontype,
                promoqty: itemqty,
                scandatapromotion: false,
                couponstatus: false,
                discprice: off,
                customer_tax: item.customer_tax,
              };
          }
        }
      }
    }
  }
  return bestPromo;
};

const getOverallQty = (list, itemsPromotions) => {
  let overallQty = {};
  let promoList = Object.values(itemsPromotions).flat().filter((obj, index, self) =>
    index === self.findIndex((o) => o.id === obj.id)
  );
  for (let eachPro of promoList) {
    for (let eachItemPro of Object.entries(itemsPromotions)) {
      let itmId = eachItemPro[0];
        let promoIds = eachItemPro[1]?.map(e => e.id);
        if (promoIds.includes(eachPro?.id)) {
          let itmQty = list[1]?.find(e => e.id == itmId)?.qty || 0;
          overallQty[itmId] = +itmQty
        }
    }
  }
  return Object.values(overallQty)?.reduce((a , b) => a + b , 0);
}
// for (let pitem of list[0]) {
  //   let subList = pitem[type];
  //   if (subList) {
  //     for (let subItem of subList) {
  //       if (type !== "tag") {
  //         if (+subItem[getSubKey(type)] == +id) {
  //           foundList.push(pitem);
  //         }
  //       } else {
  //         id = id?.map((e) => e?.tag ? e?.tag : e);
  //         if (id?.includes(+subItem[getSubKey(type)])) {
  //           foundList.push(pitem);
  //         }
  //       }
  //     }
  //   }
  // }
const findBestPromotion = (list, id, type, itemqty, itemprice, itemsPromotions, currentItmId ) => {
  itemprice = +itemprice;
  itemqty = +itemqty;
  let foundList = itemsPromotions[currentItmId];
  // let overallQty = getOverallQty(list, itemsPromotions)
  let bestPromo = getBestOnePromotion(foundList,itemqty, itemprice, true, id , itemqty , list[1]?.find(e => e.id == currentItmId));
  return bestPromo;
};

const findBestGroupPromotion = (
  list,
  itemid,
  catId,
  sizeId,
  tagId,
  itemqty,
  itemprice,
  itemsPromotions,
  currentItmId,
) => {
  itemprice = +itemprice;
  itemqty = +itemqty;
  let foundList = [];
  for (let pitem of list[0]) {
    let [foundItem, foundCate, foundSize, foundTag] = [
      false,
      false,
      false,
      false,
    ];

    //checking item exist in the promotion item
    for (let sitem of pitem.item) {
      if (sitem.stored_item == itemid) {
        foundItem = true;
        break;
      }
    }

    //checking item exist in the promotion category
    for (let scate of pitem.category) {
      if (scate.category == catId) {
        foundCate = true;
        break;
      }
    }

    //checking item exist in the promotion size
    for (let sSize of pitem.size) {
      if (sSize.supplier == sizeId) {
        foundSize = true;
        break;
      }
    }

    //checking item exist in the promotion tag
    for (let stag of pitem.tag) {
      tagId = tagId?.map((e) => e?.tag ? e?.tag : e);
      if (tagId.includes(stag.tag)) {
        foundTag = true;
        break;
      }
    }
    if (!itemqty && !itemprice) {
      if (foundItem || foundCate || foundSize || foundTag) {
        if(!foundList?.map(e => e.id).includes(pitem.id)){
          foundList.push(pitem);
        }
      }
    } else {
      if (foundItem + foundCate + foundSize + foundTag >= 2) {
        foundList.push(pitem);
      }
    }

  }
  if (!itemqty && !itemprice) {
    return foundList
  }else {
    let bestPromo = getBestOnePromotion(foundList, itemqty, itemprice, false , false , itemqty , list[1]?.find(e => e.id == currentItmId) );
    return bestPromo;
  }
};

export const getTheBestPromotion = (
  list,
  itemid,
  catId,
  sizeId,
  tagId,
  itemQty,
  itemPrice,
) => {

  let itemsPromotions = {};
  for (let eachItem of list[1]) {
    if (!eachItem.manual_item) {
      itemsPromotions[eachItem?.id] = findBestGroupPromotion(
        list,
        eachItem.id,
        eachItem.category,
        eachItem.size,
        eachItem.tag || []
      )
    }
  }
  const groupPromo = findBestGroupPromotion(
    list,
    itemid,
    catId,
    sizeId,
    tagId,
    itemQty,
    itemPrice,
    itemid,

  );

  const itemPromo = findBestPromotion(list, itemid, "item", itemQty, itemPrice, itemsPromotions, itemid);
  const categoryPromo = findBestPromotion(
    list,
    catId,
    "category",
    itemQty,
    itemPrice,
    itemsPromotions,
    itemid,

  );
  const itemSizePromo = findBestPromotion(
    list,
    sizeId,
    "size",
    itemQty,
    itemPrice,
    itemsPromotions,
    itemid,

  );
  const itemTagPromo = findBestPromotion(
    list,
    tagId,
    "tag",
    itemQty,
    itemPrice,
    itemsPromotions,
    itemid,

  );
  let bestPromo = itemPromo;
  if (categoryPromo?.off > bestPromo?.off) {
    bestPromo = categoryPromo;
  }

  if (itemSizePromo?.off > bestPromo?.off) {
    bestPromo = itemSizePromo;
  }

  if (itemTagPromo?.off > bestPromo?.off) {
    bestPromo = itemTagPromo;
  }

  if (groupPromo?.off > bestPromo?.off) {
    bestPromo = groupPromo;
  }
  return bestPromo;
};

export const getFormattedItemList = (
  itemlistraw,
  itemmodifierlist,
  itemcodelist,
  itemfeelist,
  itemtaglist
) => {
  const indexedModifiers = itemmodifierlist.reduce((acc, modifier) => {
    const itemId = modifier.stored_item;
    if (!acc[itemId]) acc[itemId] = [];
    acc[itemId].push(modifier);
    return acc;
  }, {});

  const indexedCodes = itemcodelist.reduce((acc, code) => {
    const itemId = code.stored_item;
    if (!acc[itemId]) acc[itemId] = [];
    acc[itemId].push(code);
    return acc;
  }, {});

  const indexedFees = itemfeelist.reduce((acc, fee) => {
    const itemId = fee.stored_item;
    if (!acc[itemId]) acc[itemId] = [];
    acc[itemId].push(fee);
    return acc;
  }, {});

  const indexedTags = itemtaglist.reduce((acc, tag) => {
    const itemId = tag.stored_item;
    if (!acc[itemId]) acc[itemId] = [];
    acc[itemId].push(tag);
    return acc;
  }, {});

  const formattedList = itemlistraw
    ? itemlistraw.map((item) => ({
      ...item,
      modifier: indexedModifiers[item.id] || [],
      code: indexedCodes[item.id] || [],
      fee: indexedFees[item.id] || [],
      tag: indexedTags[item.id] || [],
    }))
    : [];
  return formattedList.sort((a, b) => a.name.localeCompare(b.name));
};

export const formateSalesData = (data, module) => {
  const customerList = Localstorage.getItem("customer");
  const indexedItems = data[SALEITEMS].reduce((acc, items) => {
    const saleId = items.sale;
    if (!acc[saleId]) acc[saleId] = [];
    acc[saleId].push(items);
    return acc;
  }, {});

  const indexedCodes = data[SALECODES].reduce((acc, code) => {
    const saleId = code.sale;
    if (!acc[saleId]) acc[saleId] = [];
    acc[saleId].push(code);
    return acc;
  }, {});

  const indexedExtraCost = data[SALEEXTRACOST].reduce((acc, cost) => {
    const saleId = cost.sale;
    if (!acc[saleId]) acc[saleId] = [];
    acc[saleId].push(cost);
    return acc;
  }, {});

  const indexedPayments = data[SALEPAYMENTS].reduce((acc, payment) => {
    const saleId = payment.sale;
    if (!acc[saleId]) acc[saleId] = [];
    acc[saleId].push(payment);
    return acc;
  }, {});

  const indexedVoids = data[SALEVOID].reduce((acc, voids) => {
    const saleId = voids.sale;
    if (!acc[saleId]) acc[saleId] = [];
    acc[saleId].push(voids);
    return acc;
  }, {});

  const formattedList = data[module]
    ? data[module].map((item) => ({
      ...item,
      customer: customerList.find((e) => +e.id == +item.custid),
      items: indexedItems[item.id] || [],
      payments: indexedPayments[item.id] || [],
      extracosts: indexedExtraCost[item.id] || [],
      sale_code: indexedCodes[item.id] || [],
      salevoid: indexedVoids[item.id] || [],
    }))
    : [];

  return formattedList;
};

// Debounce helper function
export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};

export const getStimeForSale = (str) => {
  let time = {
    week: moment().subtract(7, "days").startOf("day").valueOf(),
    day: moment().startOf("day").valueOf(),
    month: moment().subtract(30, "days").startOf("day").valueOf(),
  };
  return time[str];
};

export const getFormateImages = (array) => {
  return array.map((e) => {
    return {
      ...e,
      file: e.signed_url,
    };
  });
};

export function isColorDark(color) {
  // Ensure the color is in the correct format (#RRGGBB or #RRGGBBAA)
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/.test(color)) {
    throw new Error("Invalid color format. Use #RRGGBB or #RRGGBBAA.");
  }

  // Remove the '#' character if present
  color = color.replace("#", "");

  // Parse the color components
  const red = parseInt(color.slice(0, 2), 16);
  const green = parseInt(color.slice(2, 4), 16);
  const blue = parseInt(color.slice(4, 6), 16);

  // Calculate brightness using the relative luminance formula
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

  // You can adjust this threshold to your preference
  // Typically, values below 128 are considered dark, and values above 128 are considered light
  return brightness < 128;
}

export const getRemoveItemsIdSuspendSale = (currentSalesItems) => {
  let suspendSalesItems = Localstorage.getItem("complete_sale_item_ids");
  let removeIds = [];
  if (Array.isArray(suspendSalesItems)) {
    suspendSalesItems.map(e => {
      if (!currentSalesItems.includes(e)) {
        removeIds.push(e)
      }
    })
    localStorage.removeItem("complete_sale_item_ids");
  }
  return removeIds;
}


export function getTextAccToAppType(text) {
  const appType = localStorage.getItem("appType");
  if (
    appType === "PNCstore" ||
    appType === "PNLiquor" ||
    appType === "PNTobacco" ||
    appType === "PNRetail"
  ) {
    let names = {
      "Bottle": "Unit",
      "Bottles": "Units"
    }
    return names[text];
  } else {
    return text;
  }
}
