import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";
import RootReducer from "./reducers/rootReducers";

const config = {
  whitelist: ["USER_LOGGED_OUT"],
};
const initialState = {};

const middlewares = [thunk, createStateSyncMiddleware(config)];

let devtools = (x) => x;

if (
  process.env.NODE_ENV !== "production" &&
  process.browser &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

//create store for store management Functionality...
export const store = createStore(
  RootReducer,
  initialState,
  compose(applyMiddleware(...middlewares), devtools)
);

initStateWithPrevTab(store);

