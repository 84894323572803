import React, { useState, useEffect, useRef } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  Image,
  ListGroup,
  Row,
} from "@themesberg/react-bootstrap";
import {
  CASH,
  CHECK,
  SIDECARD,
  DEBITCARD,
  EBTSALE,
  CARD,
  GIFTCARD,
  ADDCONFIRMSALEITEM,
  PAYMENT,
  REVERTPAYMENT,
  ITEMSALE,
  REMOVEALLITEMSALE,
  COMPLETEVALUE,
  CANCELEDVALUE,
  EBTCASH,
  UPDATE_ITEM_QTY_IN_HAND,
  GENERALSETIINGDATA,
  POSSETTINGDATA,
  HOUSEPAY,
  PAXERROR,
  SETTING_CHANNEL,
  SETTING_EVENT,
  UPDATEGENERALSETTINGS,
  EMAILINVOICE,
  CREATEORDER,
  AUTHORDER,
  VERIFYSTATUS,
  PAYFACAUTHTOKEN,
  CANCEL,
  HARDWARECANCEL,
  PAYFACREFUND,
  ACCOUNTSETTINGDATA,
  DECLINECARDADD,
  REFUNDEDVALUE,
  DEPOSITRETURNTYPE,
  GIFTITEMTYPE
} from "../../../app/helpers/constant";
import FormField from "../../../pages/components/formField";
import CustomNumpad from "../../components/customNumpad";
import CustomModal from "../../components/Modals";
import { connect, useDispatch } from "react-redux";
import {
  checkOutSaleItem,
  updatePaymentArrayReducer,
  RemoveSaleItemList,
  confirmedResponseAction,
  paxErrorState,
  addActionData,
} from "../../../app/actions/saleItemAction";
import {
  currencyFormat,
  FirtsLetterUpperCase,
  qtyFormat,
  dateFormate,
  isWeb,
  FormatImageBase64Code,
  base64ToHex,
  HexToString,
  StringToHex,
  hexToBase64,
  getLRC,
  taxDetailsByItemId,
  refrenceNumber,
  SaleStatusData,
  getRemoveItemsIdSuspendSale,
  getDeviceTerminalId,
  checkEbt,
  CalculateAdditionalCardPrice,
  combineObjectsAdditionalFee,
  calculateFee
} from "../../../app/helpers/custom";
import debit from "../../../assets/images/debit.svg";
import ebt from "../../../assets/images/ebt.svg";
import sideCard from "../../../assets/images/cash-icon.svg";
import check from "../../../assets/images/check1.svg";
import giftCard from "../../../assets/images/gist-card.svg";
import cashIcon from "../../../assets/images/cash-icon.svg";
import attention from "../../../assets/images/attention.png";
import { useReactToPrint } from "react-to-print";
import "../../../scss/print.scss";
import moment from "moment";
import QRCode from "react-qr-code";
import { Base64 } from "js-base64";

import $ from "jquery";
import {
  authUserId,
  getDeviceAndStore,
  getSettingData,
} from "../../../axios/authHeader";
import Localstorage from "app/storage/Localstorage";
import { usePusher } from "context/PusherContext";
import { addActionFunction, updateGeneralSettings } from "app/actions/getListAction";
import Cancel from "../../../assets/images/cancel.png";
import { payFac } from "app/services/payFacService";
import LoaderSpinner from "components/Loader";

const Checkout = (props) => {
  const sale_customer = Localstorage.getItem("sale_customer");
  const [sumQtySaleitem, setSumQtySaleitem] = useState([]);
  const [show, setShow] = useState(false);
  const [checkOut, setCheckOut] = useState(false);
  const [partial, setPartial] = useState(false);
  const [tenderError, setTenderError] = useState(false);
  const [tendor_amount, setTendorAmount] = useState("");
  const [, setPrintTendorAmount] = useState("");
  const [actualAmount, setActualAmount] = useState("");
  const [changeAmount, setChangeAmount] = useState(Number(0).toFixed(2));
  const [sumCash, setSumCash] = useState(parseFloat(0));
  const [sumCashBalance, setSumCashBalance] = useState(parseFloat(0));
  const [sumNonCash, setSumNonCash] = useState(parseFloat(0));
  const [sumNonCashBalance, setSumNonCashBalance] = useState(parseFloat(0));
  const [sumRegularBalance, setRegularBalance] = useState(parseFloat(0));
  const [sumRegularTotal, setSumRegularTotal] = useState(parseFloat(0));
  const [nonCashAdjEnable, setNonCashAdjEnable] = useState(0);
  const [NonCashAdjLable, setNonCashAdjLable] = useState("");
  const [checkoutChangeAmt, setCheckOutChangeAmt] = useState(parseFloat(0));
  const [checkedEbtAny, setEbtAnyChecked] = useState(false);
  const [deafultEbtTaxCheck, setDeafultEbtTaxCheck] = useState(0);
  const [sumEBTTotal, setSumEBTTotal] = useState(0);
  const [cancelTransaction, setcancelTransaction] = useState(false);
  const [refundConfirmation, setRefundConfirmation] = useState(false);
  const [suggesstionButton, setSuggesstionButton] = useState("");
  const [button1, setButton1] = useState({ disable: "", value: "" });
  const [button2, setButton2] = useState({ disable: "", value: "" });
  const [button3, setButton3] = useState({ disable: "", value: "" });
  const [button4, setButton4] = useState({ disable: "", value: "" });
  const [button5, setButton5] = useState({ disable: "", value: "" });
  const [button6, setButton6] = useState({ disable: "", value: "" });
  const [button7, setButton7] = useState({ disable: "", value: "" });
  const [button8, setButton8] = useState({ disable: "", value: "" });
  const [button9, setButton9] = useState({ disable: "", value: "" });
  const [tenderButton, setTenderButton] = useState([]);
  const [printRecipt, setPrintRecipt] = useState(false);
  const [showIcon, setShowIcon] = useState(null);
  const [otherButton, setOtherButton] = useState({
    gift: 0,
    debit: 0,
    ebtSale: 0,
    ebtCash: 0,
    side: 0,
    check: 0,
  });
  const totalAmount = Number(sumNonCash + sumCash).toFixed(2);
  const decimalQty =
    (props?.PosSetting?.SALE?.allow_decimal_qty_sale ??
      props?.PosSetting?.SALE?.allow_decimal_qty_sale) ||
    props?.posSetting?.SALE?.allow_decimal_qty_sale;
  const dispatch = useDispatch();
  const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
  const [timer, setTimer] = useState(
    parseInt(wpos_config?.autoHideReciptDialog)
  );
  const [openErrorMsg, setOpenErrorMsg] = useState(false);
  const [openErrorMsg1, setOpenErrorMsg1] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [title, setTitle] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [reference, setReference] = useState("");
  const [refund, setRefund] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [tbsBtn, setTbsBtn] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState(false);
  const [, setPaxError] = useState("");
  const [cardType, setCardType] = useState("");
  const [fraudBypass, setFraudBypass] = useState(false);
  const [cashPayments, setCashPayments] = useState([]);
  const [cashTotalAmount, setCashTotalAmount] = useState([]);
  const [cardResponse, setCardResponse] = useState("");
  const [saleStatus, setSaleStatus] = useState("");
  const [transId, setTransId] = useState("");
  const [categoryList, setCategoryList] = useState({});
  const [onlineStatus, setOnlineStatus] = useState(
    localStorage.getItem("SystemStatus")
  );
  const [saleRef, setSaleRef] = useState(props?.salesitemList[0]?.itemData?.ref
    ? props?.salesitemList[0]?.itemData?.ref
    : refrenceNumber(getDeviceAndStore()[0]))

  // pax config
  const [paxConfig, setPaxConfig] = useState({
    pax_ip: "",
    pax_port: "",
    pax_protocol: "",
  });
  const [fraudPrevent, setFraudPrevent] = useState(false);
  const [isTenderClicked, setTenderClicked] = useState(false);
  const [giftResponse, setGiftResponse] = useState({});
  const [giftPayment, setGiftPayment] = useState([]);
  const [electronError, setElectronError] = useState(false);
  const [payfac_manual_card, setPayfac_manual_card] = useState(false);
  const pusher = usePusher();

  useEffect(() => {
    let sett = getSettingData(GENERALSETIINGDATA);
    setPayfac_manual_card(sett?.CREDIT?.payfac_allow_manual_entry === "1" && sett?.CREDIT?.card_type == 4)
  }, [props?.genSetting])


  useEffect(() => {
    Localstorage.setItem("checkOut", checkOut);
  }, [checkOut]);

  useEffect(() => {
    let totalAmt = Localstorage.getItem("totalAmount");
    let newTotalAmt = { ...totalAmt, changeAmount };
    Localstorage.setItem("totalAmount", newTotalAmt);
  }, [changeAmount]);

  useEffect(() => {
    if (props?.totalAmounts) {
      setSumCash(props.totalAmounts.Total);
      setSumNonCash(props.totalAmounts.nonCashAdj);
      setSumEBTTotal(props.totalAmounts.EBTTotal);
      setSumCashBalance(props.totalAmounts.Total);
      setSumNonCashBalance(
        parseFloat(props.totalAmounts.Total) +
        parseFloat(props.totalAmounts.nonCashAdj)
      );
      // setRegularBalance(CalculateAdditionalCardPrice(props.totalAmounts.Total) + props.totalAmounts.Total)
      setSumRegularTotal(getCardBalance(props.totalAmounts.Total))
    }
  }, [props.totalAmounts]);
  useEffect(() => {
    function childEventCallback(data) {
      const generalSettings = JSON.parse(data?.data?.data);
      dispatch(
        updateGeneralSettings({ data: generalSettings }, UPDATEGENERALSETTINGS)
      );
    }
    const channel = pusher.subscribe(SETTING_CHANNEL);
    channel.bind(SETTING_EVENT, childEventCallback);
    return () => {
      channel.unbind(SETTING_EVENT, childEventCallback);
    };
  }, [pusher, props]);

  useEffect(() => {
    let side_card = 0;
    let check = 0;
    let gift_card = 0;
    let debit_sale = 0;
    let ebt_sale = 0;
    let ebt_cash = 0;
    if (props?.generalSetting?.TENDER !== undefined) {
      setTenderButton(props?.generalSetting?.TENDER.list || []);
      side_card = props?.generalSetting?.TENDER?.side_card || 0;
      check = props?.generalSetting?.TENDER?.check || 0;
    }
    if (props?.generalSetting?.CREDIT !== undefined) {
      gift_card = props?.generalSetting?.CREDIT?.gift_card || 0;
      debit_sale = props?.generalSetting?.CREDIT?.debit_sales || 0;
      ebt_sale = props?.generalSetting?.CREDIT?.ebt_sales || 0;
      ebt_cash = props?.generalSetting?.CREDIT?.ebt_cash || 0;
    }
    if (props?.generalSetting?.CREDIT || props?.generalSetting?.TENDER) {
      setOtherButton({
        ...otherButton,
        gift: parseInt(gift_card),
        debit: parseInt(debit_sale),
        ebtSale: parseInt(ebt_sale),
        ebtCash: parseInt(ebt_cash),
        side: parseInt(side_card),
        check: parseInt(check),
      });
    }
  }, [props.generalSetting]);

  useEffect(() => {
    if (props?.salesitemList !== undefined) {
      setSumQtySaleitem(
        props?.salesitemList.map((data) => {
          if (data.qty === "") return qtyFormat(decimalQty, 0);
          else return qtyFormat(decimalQty, data.qty);
        })
      );
      setEbtAnyChecked(
        props?.salesitemList.filter((data) => data.ebt === true).length > 0
          ? true
          : false
      );
    }
    const categoryId = props?.salesitemList[0]?.itemData?.category;
    const categories = props?.category;
    const findCategoryById = (categoryId) => {
      const category = categories.find(
        (category) => category.id === categoryId
      );
      return category ? category : "Category not found";
    };
    const category = findCategoryById(categoryId);
    if (category !== "Category not found") {
      setCategoryList(category);
    }
    console.log(categoryList, "category");
  }, [props.salesitemList]);

  useEffect(() => {
    if (sumCashBalance !== "")
      setSuggesstionButton(
        amountSuggestions(Number(roundOffTotalPay(sumCashBalance)).toFixed(2))
      );
      setRegularBalance(getCardBalance(sumCashBalance))
  }, [sumCashBalance]);

  useEffect(() => {
    if (props?.AccountSetting?.NONCASEADJUSTMENT !== undefined) {
      setNonCashAdjEnable(
        parseInt(
          props?.AccountSetting?.NONCASEADJUSTMENT?.enable ||
          props?.accountSetting?.NONCASEADJUSTMENT?.enable
        )
      );
    }
    if (props?.AccountSetting?.NONCASEADJUSTMENT?.label !== undefined) {
      setNonCashAdjLable(
        props?.AccountSetting?.NONCASEADJUSTMENT?.label ||
        props?.accountSetting?.NONCASEADJUSTMENT?.label
      );
    }
    if (props?.AccountSetting?.TAXRULEDATA !== undefined) {
      setDeafultEbtTaxCheck(
        parseInt(
          props?.AccountSetting?.TAXRULEDATA?.deafultEbtTaxCheck ||
          props?.accountSetting?.TAXRULEDATA?.deafultEbtTaxCheck
        )
      );
    }
  }, [props.AccountSetting, props.accountSetting]);

  useEffect(() => {
    if (tendor_amount !== "") {
      if (show === "OnChangeValue") {
        setActualAmount(tendor_amount);
        if (parseFloat(tendor_amount) > parseFloat(sumCashBalance)) {
          setChangeAmount(
            Number(
              parseFloat(tendor_amount) - parseFloat(sumCashBalance).toFixed(2)
            )
          );
        } else {
          setChangeAmount(Number(0).toFixed(2));
        }
        setShow(false);
      } else {
        console.log(`tendor_amount`, tendor_amount);
        console.log(`sumCashBalance`, sumCashBalance);
        setActualAmount(Number(parseInt(tendor_amount) / 100).toFixed(2));
        if (
          parseFloat(parseInt(tendor_amount) / 100) > parseFloat(sumCashBalance)
        ) {
          setChangeAmount(
            Number(
              parseFloat(parseInt(tendor_amount) / 100) -
              parseFloat(sumCashBalance)
            ).toFixed(2)
          );
        } else {
          setChangeAmount(Number(0).toFixed(2));
        }
      }
    }
  }, [tendor_amount]);

  useEffect(() => {
    if (suggesstionButton.button1 !== undefined) {
      setButton1({
        disable: suggesstionButton.button1.isdisabled,
        value: suggesstionButton.button1.value,
      });
    }
    if (suggesstionButton.button2 !== undefined) {
      setButton2({
        disable: suggesstionButton.button2.isdisabled,
        value: suggesstionButton.button2.value,
      });
    }
    if (suggesstionButton.button3 !== undefined) {
      setButton3({
        disable: suggesstionButton.button3.isdisabled,
        value: suggesstionButton.button3.value,
      });
    }
    if (suggesstionButton.button4 !== undefined) {
      setButton4({
        disable: suggesstionButton.button4.isdisabled,
        value: suggesstionButton.button4.value,
      });
    }
    if (suggesstionButton.button5 !== undefined) {
      setButton5({
        disable: suggesstionButton.button5.isdisabled,
        value: suggesstionButton.button5.value,
      });
    }
    if (suggesstionButton.button6 !== undefined) {
      setButton6({
        disable: suggesstionButton.button6.isdisabled,
        value: suggesstionButton.button6.value,
      });
    }
    if (suggesstionButton.button7 !== undefined) {
      setButton7({
        disable: suggesstionButton.button7.isdisabled,
        value: suggesstionButton.button7.value,
      });
    }
    if (suggesstionButton.button8 !== undefined) {
      setButton8({
        disable: suggesstionButton.button8.isdisabled,
        value: suggesstionButton.button8.value,
      });
    }
    if (suggesstionButton.button9 !== undefined) {
      setButton9({
        disable: suggesstionButton.button9.isdisabled,
        value: suggesstionButton.button9.value,
      });
    }
  }, [suggesstionButton]);

  const handleChange = (e) => {
    setShow("OnChangeValue");
    if (e.target.value !== "") {
      setPrintTendorAmount(e.target.value);
      setTendorAmount(e.target.value);
    } else {
      setActualAmount("");
      setTendorAmount("");
      setChangeAmount("");
    }
  };

  const handleFraud = () => {
    if (payFacInitiateData.type) {
      setOpenErrorMsg(false);
      handleLoader()
      setPayFacInitiateData({
        ...payFacInitiateData,
        init: true
      })
      handleLoader(true, "Follow Prompts on Terminal");
      return;
    }
    handleLoader(false, "");
    setOpenErrorMsg(false);
    setTbsBtn(false);
    setFraudBypass(true);
    setPartial(false);
    if (window.navigator.onLine === false) {
      const troubleShoot = props.generalSetting?.CREDIT?.tbs_btn;
      if (troubleShoot == 1) {
        setTbsBtn(true);
      } else {
        setTbsBtn(false);
      }
      setTimeout(
        function () {
          handleLoader(false, "");
          setOpenErrorMsg(true);
          setTitle("Pax Error");
          setErrorMsg("Could not connect to pinpad");
          setShowIcon(Cancel);
          setCardType("");
        },
        [3000]
      );
    }

    setCashTotalAmount(props?.salesitemList);

    if (actualAmount == "") {
      setActualAmount(sumCashBalance);
    }
    if (cardType == "EBT Sale") {
      setActualAmount(
        actualAmount == ""
          ? parseFloat(props?.totalAmounts.EBTTotal)
          : actualAmount
      );
    }

    if (cardType == "Card" || cardType == "Debit Card") {
      if (props?.GeneralSetting?.CREDIT.custom_tips == 1) {
        handleLoader(true, "Follow Prompts on Terminal");
        checkPaxSettingCustomTip(cardType, function (tipresponse) {
          console.log(`tipresponse`, tipresponse);
          if (
            tipresponse.hasOwnProperty("tipvalue") &&
            tipresponse.tipvalue !== null &&
            tipresponse.tipvalue > 0
          ) {
            let customtipamt = 0;
            let tipamount = 0;
            if (actualAmount == "") {
              customtipamt = parseFloat(
                (sumCashBalance * tipresponse.tipvalue) / 100
              ).toFixed(2);
            } else {
              customtipamt = parseFloat(
                (actualAmount * tipresponse.tipvalue) / 100
              ).toFixed(2);
            }
            handlePayment(cardType, customtipamt);
          } else if (
            tipresponse.hasOwnProperty("timeout") &&
            tipresponse.timeout == true
          ) {
            // setPaxError("timeout");
            // setResponseMsg(`Error: ${tipresponse.ResponseMessage}`);
          } else {
            if (tipresponse.tipvalue == 0) {
              handlePayment(cardType);
            }
          }
        });
      } else {
        handlePayment(cardType);
      }
    } else {
      handlePayment(cardType);
    }
  };

  const getKeyPadValue = (data) => {
    setShow(false);
    if (data.type === "checkout") {
      if (data.value === "C") {
        setTendorAmount("");
        setActualAmount("");
        setChangeAmount(Number(0).toFixed(2));
      } else {
        setTendorAmount(tendor_amount + data.value);
      }
    }
  };

  const checkPayBalance = () => {
    let method = props?.payment[props?.payment?.length - 1]?.method;
    if(method === CARD){
      // sumCashBalance(60);
    }
  };

  useEffect(() => {
    checkPayBalance();
    const fraud_prevention = props.generalSetting?.CREDIT?.fraud_prevention;
    if (props.payment.length > 0) {
      let isCardPayment = false;
      let methods = ["card", "debit", "ebt cash", "ebt", "gift"];
      if (methods.includes(props.payment[props.payment.length - 1].method)) {
        isCardPayment = true;
      }
      console.warn("props.payment props.payment  ", props.payment)
      if (refund == false) {
        if (
          props.payment[props.payment.length - 1].method != "Cash" &&
          props.payment[props.payment.length - 1].method != "Side Card" &&
          props.payment[props.payment.length - 1].method != "Check"
        ) {
          if (
            addGiftCard(props.payment, function (giftResponse) {
              console.log(
                "checkPaymentAmount",
                checkPaymentAmount(),
                fraud_prevention
              );
              if (checkPaymentAmount() && fraud_prevention != 1) {
                console.log(`this print->`);
                handlePrintRecipt(
                  "",
                  changeAmount,
                  actualAmount,
                  null,
                  giftResponse !== "timeout" && giftResponse !== true
                    ? giftResponse
                    : null
                );
                setPartial(false);
              } else if (checkPaymentAmount() && fraud_prevention == 1) {
                console.log(`this print->2`);
                handlePrintRecipt(
                  "",
                  changeAmount,
                  actualAmount,
                  null,
                  giftResponse !== "timeout" && giftResponse !== true
                    ? giftResponse
                    : null
                );
                setPartial(false);
              } else if (props.totalAmounts?.EBTTotal == actualAmount) {
                console.log(`this print->3`);
                handlePrintRecipt(
                  "",
                  changeAmount,
                  actualAmount,
                  null,
                  giftResponse !== "timeout" && giftResponse !== true
                    ? giftResponse
                    : null
                );
                setPartial(false);
              } else {
                setPartial(true);
              }
            })
          );
        } else {
          console.log(`props`, props);
          const total = props?.payment?.reduce(
            (prev, item) => prev + parseFloat(item.tender),
            0
          );

          if (
            total >= props?.totalAmounts?.Total &&
            fraud_prevention != 1 &&
            isTenderClicked === false
          ) {
            console.log("isTenderClicked", isTenderClicked);
            handlePrintRecipt("", changeAmount, actualAmount);
          } else if (
            total >= props?.totalAmounts?.Total &&
            fraud_prevention == 1 &&
            isCardPayment === false &&
            isTenderClicked === false
          ) {
            console.log("isCardPayment", isCardPayment);
            handlePrintRecipt("", changeAmount, actualAmount);
          } else if (
            total >= props?.totalAmounts?.Total &&
            fraud_prevention == 1 &&
            isCardPayment === true &&
            isTenderClicked === false
          ) {
            console.log(
              "isCardPayment final",
              isCardPayment,
              fraud_prevention,
              isTenderClicked
            );
            setFraudPrevent(true);
          }
        }
      }
    }
  }, [props.payment]);

  const checkGiftItem = (salesList) => {
    const data = salesList.filter(
      (item) => item?.itemData?.item_type == GIFTITEMTYPE
    );
    return data.length > 0;
  }

  const addGiftCard = (payment, callback) => {
    const genSet = props.genSetting.CREDIT;
    const isGiftItem = checkGiftItem(props.salesitemList)
    if (genSet?.gift_item_id != "" || isGiftItem) {
      const data = props.salesitemList.filter(
        (item) => item.id === parseInt(genSet?.gift_item_id) || item?.itemData?.item_type == GIFTITEMTYPE
      );
      console.log(`data-->`, props.salesitemList);
      console.log(`data-->`, data);
      if (data.length > 0) {
        handleLoader(true, "Follow Prompts on Terminal");
        var parms = getGiftCardIssueParams(payment);
        var settings = getPaymentSettings(parms, "add", "gift");
        $.ajax(settings)
          .done(function (giftResponse) {
            handleLoader(false, "");
            console.log(`giftResponse`, giftResponse);
            var giftResponse = handlePaymentResponse("Pax", giftResponse);
            console.log(`giftResponse`, giftResponse);
            setGiftResponse(giftResponse);
            setGiftPayment(payment);
            if (giftResponse.isApproved == true) {
              callback(giftResponse);
            } else if (giftResponse.isApproved == false) {
              if (giftResponse.ResponseMessage == "ABORTED") {
                handleLoader(false, "");
                callback("ABORTED");
                console.log(`aborted`, giftResponse.ResponseMessage);
                setOpenErrorMsg1(true);
                setErrorMsg(
                  <>
                    <div className="text-center">
                      <Image src={Cancel} alt="error icon" className="mb-3" />{" "}
                      <br />
                      <p>Error: ABORTED</p>
                      <p className="mt-2">Retry Adding Balance?</p>
                    </div>
                  </>
                );
                setTitle("Error");
              }
            }
          })
          .fail(function (jqXHR, status, error) {
            console.log(`jqXHR`, jqXHR);
            var errormessage;
            if (jqXHR.status === 0) {
              errormessage = "Could not connect to pinpad";
            } else if (jqXHR.status == 404) {
              errormessage = "Requested page not found. [404]";
            } else if (jqXHR.status == 500) {
              errormessage = "Internal Server Error [500].";
            } else {
              errormessage = "Uncaught Error.\n" + jqXHR.responseText;
            }
            const response = {
              readyState: jqXHR?.readyState,
              status: jqXHR?.status,
              statusText: jqXHR?.statusText,
            };
            setGiftResponse(response);
            setGiftPayment(payment);
            handleLoader(false, "");
            setTitle("Pax Error");
            setOpenErrorMsg1(true);
            setErrorMsg(
              <>
                <div className="text-center">
                  <Image src={Cancel} alt="error icon" className="mb-3" />{" "}
                  <br />
                  <p>Error: {errormessage}</p>
                  <p className="mt-2">Retry Adding Balance?</p>
                </div>
              </>
            );
            setCardType("");

            const troubleShoot = props.generalSetting?.CREDIT?.tbs_btn;
            if (troubleShoot == 1) {
              setTbsBtn(true);
            } else {
              setTbsBtn(false);
            }
            console.log(`errormessage`, errormessage);
          });
      } else {
        callback(true);
      }
    } else {
      callback(true);
    }
  };

  const getGiftCardIssueParams = (payment) => {
    var totalprice = 0;
    console.log(`payment`, payment);
    for (var i = 0; i < payment.length; i++) {
      totalprice += parseFloat(payment[i].tender);
    }
    console.log(`totalprice`, totalprice);
    return {
      ticketNumber: generate_ref_number(),
      transactionAmount: totalprice,
    };
  };

  const closedCheckOutPopUp = (value, type) => {
    props.closePopUp(value);
    if (type !== undefined) dispatch(RemoveSaleItemList([], REMOVEALLITEMSALE));
  };

  /* Calculate Amount suggestions for given amount based on coins and dollars */
  function calculateAmountSuggestions(amt) {
    let coins = [0.05, 0.1, 0.25, 1, 5, 10, 20, 50, 100]; //.01 neglected as it the amount is submitted as it is
    const suggestions = [];
    let amount = Number(amt).toFixed(2);
    suggestions.push(amount);
    for (let i = 0; i < coins.length; i++) {
      let coin = coins[i];
      let remainder = amount % coin;
      if (remainder > 0) {
        let suggestionAmout = ((amount - remainder) / coin) * coin + coin;
        suggestionAmout = Number(suggestionAmout).toFixed(2);
        if (suggestions.indexOf(suggestionAmout) === -1) {
          suggestions.push(suggestionAmout);
        }
      }
    }
    return suggestions;
  }

  //Amount Suggestions
  const amountSuggestions = (current) => {
    let currentAmount = Number(current).toFixed(2);
    let str = currentAmount.toString();
    let sign = "";
    if (currentAmount < 0) {
      sign = "-";
      str = str.replace("-", "");
    }
    let numarray = str.split(".");
    let intValue = parseInt(numarray[0]);
    let decValue = parseFloat("." + numarray[1]);
    //define variables
    const suggestions = {
      button1: {
        value: 0,
        isdisabled: true,
      },
      button2: {
        value: 0,
        isdisabled: true,
      },
      button3: {
        value: 0,
        isdisabled: true,
      },
      button4: {
        value: 0,
        isdisabled: true,
      },
      button5: {
        value: 0,
        isdisabled: true,
      },
      button6: {
        value: 0,
        isdisabled: true,
      },
      button7: {
        value: 0,
        isdisabled: true,
      },
      button8: {
        value: 0,
        isdisabled: true,
      },
      button9: {
        value: 0,
        isdisabled: true,
      },
    };

    let total = 0;
    let amount = intValue + decValue;
    let amountsuggestions = calculateAmountSuggestions(amount);
    for (let i = 0; i < amountsuggestions.length; i++) {
      amount = sign + amountsuggestions[i];
      if (currentAmount < 0) {
        // In case of negative amount
        suggestions.button1.value = amount;
        suggestions.button1.isdisabled = false;
        total++;
        break;
      }

      if (i === 0) {
        suggestions.button1.value = amount;
        suggestions.button1.isdisabled = false;
        total++;
      }

      if (i === 1) {
        suggestions.button2.value = amount;
        suggestions.button2.isdisabled = false;
        total++;
      }

      if (i === 2) {
        suggestions.button3.value = amount;
        suggestions.button3.isdisabled = false;
        total++;
      }

      if (i === 3) {
        suggestions.button4.value = amount;
        suggestions.button4.isdisabled = false;
        total++;
      }

      if (i === 4) {
        suggestions.button5.value = amount;
        suggestions.button5.isdisabled = false;
        total++;
      }

      if (i === 5) {
        suggestions.button6.value = amount;
        suggestions.button6.isdisabled = false;
        total++;
      }

      if (i === 6) {
        suggestions.button7.value = amount;
        suggestions.button7.isdisabled = false;
        total++;
      }

      if (i === 7) {
        suggestions.button8.value = amount;
        suggestions.button8.isdisabled = false;
        total++;
      }

      if (i === 8) {
        suggestions.button9.value = amount;
        suggestions.button9.isdisabled = false;
        total++;
      }
    }
    suggestions.total = total;
    return suggestions;
  };

  useEffect(() => {
    if (timer >= 0 && props?.paxerror != "timeout") {
      console.log(`timer`, timer);
      if (checkOut === true && timer > 0) {
        const intervalId = setInterval(() => {
          setTimer((t) => t - 1);
        }, 1000);
        return () => clearInterval(intervalId);
      } else if (timer === 0) {
        console.log(`timer`, timer);
        handleClosepopUp();
      }
    } else {
      console.log(`timer else`, timer);
    }
  }, [timer, checkOut]);

  useEffect(() => {
    if (props?.saleList != undefined && props?.saleList.length > 0) {
      setTransId(Number(props?.saleList[0]?.id) + 1);
    }
  }, [props?.saleList]);

  const checkMaxHousePayLimit = (pmt) => {
    let totalPayment = pmt
      .filter((e) => e.method === HOUSEPAY)
      ?.reduce((a, b) => a + parseFloat(b.amount), 0);

    if (
      sale_customer.houseacclimit &&
      totalPayment + parseFloat(sale_customer.pendingbalance || 0) >
      sale_customer.houseacclimit
    ) {
      setOpenErrorMsg(true);
      setTitle("Alert");
      setErrorMsg(
        `This customer house sale limit exceedes and house sale limit is ${parseFloat(
          sale_customer.houseacclimit
        )}`
      );
      return false;
    } else {
      return true;
    }
  };

  const handlePrintRecipt = (
    type,
    amtValue,
    actualAmount,
    tenderPayment,
    response
  ) => {
    console.log(`handle receipt`, type);
    console.log(`checkout`);
    const genSet = props.genSetting.CREDIT;
    const data = props.salesitemList.filter(
      (item) => item.id === parseInt(genSet?.gift_item_id) || item?.itemData?.item_type == GIFTITEMTYPE
    );
    const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
    if (wpos_config?.autoHideReciptDialog) {
      setTimeout(() => setTimer((t) => t - 1), 1000);
    }
    let payments = [];
    if (type === "button") {
      const chvalue =
        parseFloat(amtValue) -
        parseFloat(roundOffTotalPay(Number(sumCashBalance)));
      const paymentArry = getPaymentArray(
        CASH,
        amtValue,
        chvalue > 0 ? chvalue : 0
      );
      payments = props.payment.concat(paymentArry);
      if ((genSet?.gift_item_id != "" || checkGiftItem(props.salesitemList)) && data.length > 0) {
        addGiftCard(payments, (giftResponse) => {
          if (giftResponse === "ABORTED") {
            console.log("giftResponse sumCashBalance", props?.salesitemList);
            setSumCashBalance(parseFloat(paymentArry?.amount));
            setSumNonCashBalance(parseFloat(paymentArry?.amount));
            setChangeAmount(chvalue);
            setSumCash(parseFloat(paymentArry?.amount));
            setTendorAmount("");
            setActualAmount("");
          } else {
            setCashPayments(payments);
            setCashTotalAmount(props?.salesitemList);
            ConfirmCheckOutSale(
              [paymentArry],
              giftResponse !== "timeout" && giftResponse !== true
                ? giftResponse
                : null
            );
            handleLoader(false, "");
            setCheckOut(true);
          }
        });
      } else {
        setTenderClicked(true);
        updateArray(paymentArry);
        setCheckOutChangeAmt(chvalue > 0 ? chvalue : 0);
        UpdateButtonValues(sumCashBalance, amtValue);
        setCashPayments(payments);
        Localstorage.setItem("payment", payments);
        setCashTotalAmount(props?.salesitemList);
        localStorage.setItem("payment_reprint", JSON.stringify(payments));
      }
    } else if (type === HOUSEPAY) {
      const paymentArry = getPaymentArray(HOUSEPAY, sumCashBalance, 0);
      payments = props.payment.concat(paymentArry);
      if (checkMaxHousePayLimit(payments)) {
        setCheckOutChangeAmt(amtValue);
        UpdateButtonValues(sumCashBalance, actualAmount);
        setCashPayments(payments);
        setCashTotalAmount(props?.salesitemList);
        localStorage.setItem("payment_reprint", JSON.stringify(payments));
      } else {
        return false;
      }
    } else if (type === "Other") {
      payments.push(tenderPayment);
      setCheckOutChangeAmt(amtValue);
      setCashPayments(payments);
      // UpdateButtonValues(sumCashBalance, actualAmount);
    } else {
      payments = props.payment;
      setCheckOutChangeAmt(amtValue);
      UpdateButtonValues(sumCashBalance, actualAmount);
      setCashPayments(payments);
      setCashTotalAmount(props?.salesitemList);
    }
    console.log(`handle receipt`, payments);
    console.log(`handle receipt type`, typeof payments);

    const fraud_prevention = props.generalSetting?.CREDIT?.fraud_prevention;
    const fraudAmount = props.generalSetting?.CREDIT?.show_fraud_amount_above;
    let isCardPayment = false;
    let methods = ["card", "debit", "ebt cash", "ebt", "gift"];
    console.log("button payment", payments);
    if (methods.includes(payments[payments.length - 1].method)) {
      isCardPayment = true;
    }
    const TotalPayment = payments.reduce((acc, curr) => {
      if (methods.includes(curr.method)) {
        acc += parseFloat(curr.amount);
      }
      return acc;
    }, 0);
    console.log("TotalPayment", TotalPayment);
    console.log("button isCardPayment", isCardPayment, fraud_prevention);
    if (fraud_prevention != 1 && (data.length === 0 || type === "")) {
      console.log("fraudPrevent1", fraudPrevent);
      ConfirmCheckOutSale(payments, response);
    } else if (
      fraud_prevention == 1 &&
      isCardPayment === false &&
      (data.length === 0 || type === "")
    ) {
      console.log("fraudPrevent2", fraudPrevent);
      ConfirmCheckOutSale(payments, response);
    } else {
      console.log("fraudPrevent3", fraudPrevent, type, fraudPrevent);
      if (
        (data.length === 0 || type === "") &&
        (TotalPayment >= fraudAmount ||
          (fraudAmount == "" && fraud_prevention == 1))
      ) {
        setFraudPrevent(true);
        return;
      } else if (data.length === 0 || type === "") {
        console.log("fraudPrevent4", TotalPayment);
        ConfirmCheckOutSale(payments, response);
      }
    }

    if (!isWeb()) {
      // open cash drawer
      var thermaPrinter = preparePrinter("epson");
      if (
        wpos_config &&
        wpos_config?.hasOwnProperty("report_printer") &&
        wpos_config.report_printer != undefined
      ) {
        window.nodeprinter.printDirect({
          printerName: wpos_config.report_printer,
          type: "RAW",
          data: thermaPrinter.getBuffer(),
          success: function (jobID) {
            for (var x = 0; x < payments.length; x++) {
              let itm = payments[x];
              console.log(`itm.method`, itm.method);
              console.log(
                `cash_draw_connected`,
                wpos_config.cash_draw_connected
              );
              if (
                itm.method == "Cash" &&
                wpos_config.cash_draw_connected === true
              ) {
                Cashdraw(false);
              }
            }
          },
          error: function (err) {
            console.error(err);
          },
        });
      } else if (
        !wpos_config &&
        !wpos_config?.hasOwnProperty("report_printer")
      ) {
        // ShowMsgInfo(
        //   true,
        //   `Printer is not selected`,
        //   "Alert",
        //   ""
        // );
      }
    }

    if (
      wpos_config?.asktoPrint == "ask" &&
      (data.length === 0 || type === "")
    ) {
      setCheckOut(true);
    } else if (
      wpos_config?.asktoPrint == "print" &&
      (data.length === 0 || type === "")
    ) {
      // for (let i = 1; i <= wpos_config?.reciptOfPrint; i++) {
      // console.log(wpos_config?.reciptOfPrint);
      // const payments_arr = Object.keys(payments).map((key) => payments[key]);
      // console.log(`payments_arr--->`, typeof payments_arr);
      PrintFn();
      // }
    } else {
      console.log("printer is not selected");
      if (data.length === 0 || type === "") {
        PrintFn();
      }
    }
  };

  const sendInvoice = () => {
    let invoice_detail = Localstorage.getItem("invoice_mail_detail");
    if (invoice_detail) {
      invoice_detail.content = componentRef.current.outerHTML;
      dispatch(addActionData(EMAILINVOICE, invoice_detail));
    }
  };

  const handleClosepopUp = () => {
    sendInvoice();
    setCheckOut(false);
    setTbsBtn(false);
    setCheckOutChangeAmt(parseFloat(0));
    closedCheckOutPopUp(false, "withoutPrint");
    setFraudPrevent(false);
    if (props?.custMobile?.length == 0) {
      localStorage.removeItem("custMobile_reprint")
    }
    Localstorage.setItem("invoice_mail_detail", false);
  };

  const handleClosePaxpopUp = () => {
    setResponseMsg("");
    setPaxError("");
    dispatch(paxErrorState("", PAXERROR));
    localStorage.setItem("paxerror", "");
    setCheckOut(false);
    handleLoader(false, "");
    setTbsBtn(false);
    setCardResponse("");
    setActualAmount("");
  };

  const handleCloseThispopUp = () => {
    setResponseMsg("");
    handleLoader(false, "");
    setOpenErrorMsg(false);
    setTbsBtn(false);
    // props.closePopUp(false);
    // setCheckOutChangeAmt(parseFloat(0));
    // closedCheckOutPopUp(false, "withoutPrint");
  };

  const getInvoiceEmailData = () => {
    if (
      sale_customer &&
      sale_customer.promotion_subscribed &&
      sale_customer?.email &&
      sale_customer?.email !== "" &&
      sale_customer?.email !== "null"
    ) {
      return {
        to: sale_customer?.email,
        content: componentRef?.current?.outerHTML,
      };
    } else {
      return false;
    }
  };

  const handleElectronPopUpClose = () => {
    setResponseMsg("");
    handleLoader(false, "");
    setOpenErrorMsg(false);
    setTbsBtn(false);
    props.closePopUp(false);
    setElectronError(false);
  };

  const checkDepositReturn = () => {
    return props?.salesitemList.filter((item) => {
      return item?.item_type === DEPOSITRETURNTYPE || item?.itemData?.item_type === DEPOSITRETURNTYPE
    }).length > 0
  }

  const ConfirmCheckOutSale = (payment, response) => {
    const data = updateSaleDataArray(payment);
    if (!isWeb()) {
      if (window.navigator.onLine === false) {
        console.log(`we are offline`, data);
        if (data?.items[0]?.itemData?.saleId !== undefined) {
          updateOfflineSales(data, "sales/add");
        } else {
          addOfflineSale(data, "sales/add");
        }
      }
      dispatch(
        checkOutSaleItem(
          ITEMSALE,
          data,
          ADDCONFIRMSALEITEM,
          checkDepositReturn() ? REFUNDEDVALUE : COMPLETEVALUE,
          data?.items[0].saleId,
          null,
          response
        )
      );
    } else {
      if (window.navigator.onLine === false) {
        console.log(`we are offline in browser`, data);
        if (data?.items[0]?.itemData?.saleId !== undefined) {
          updateOfflineSales(data, "sales/add");
        } else {
          addOfflineSale(data, "sales/add");
        }
      }
      dispatch(
        checkOutSaleItem(
          ITEMSALE,
          data,
          ADDCONFIRMSALEITEM,
          checkDepositReturn() ? REFUNDEDVALUE : COMPLETEVALUE,
          data?.items[0].saleId,
          null,
          response
        )
      );
    }
    dispatch(confirmedResponseAction(data?.items, UPDATE_ITEM_QTY_IN_HAND));
  };

  const roundOffToNext = (decimalNumber, rounding) => {
    let roundedNumber =
      Math.ceil(decimalNumber / (rounding / 100)) * (rounding / 100);
    return roundedNumber.toFixed(2);
  };

  const roundOffTotalPay = (amount) => {
    const posSettings = JSON.parse(localStorage.getItem("posSetting"));
    const cashRounding =
      posSettings?.SALE?.cash_rounding ?? posSettings?.SALE?.cash_rounding;
    if (typeof amount !== "number" || isNaN(amount)) {
      return amount; // Return the original amount if it is not a valid number
    }

    if (cashRounding == 0) {
      return amount.toFixed(2);
    } else if (cashRounding == 5 || cashRounding == 10) {
      return roundOffToNext(amount, cashRounding);
    } else {
      return amount.toFixed(2);
    }
  };

  const addOfflineSale = (jsonobj, action, callback = null) => {
    console.log(`jsonobj->`, jsonobj);

    var jsonosales = {};
    var card_response = [];

    const storeId = getDeviceAndStore()[1];
    const setting = getSettingData(GENERALSETIINGDATA);
    const device = getDeviceAndStore()[0];

    const Details = jsonobj.items;

    if (!jsonobj.hasOwnProperty("response")) {
      jsonobj.response = [];
      jsonobj.response = JSON.parse(JSON.stringify(card_response));
    }

    const totalNumberItem = Details.map((data) => {
      if (data.qty === "NaN") {
        return parseFloat(0);
      } else {
        return parseFloat(data.qty);
      }
    });

    let customer = localStorage.getItem("sale_customer");
    customer = JSON.parse(
      customer && customer !== null && customer !== "null" ? customer : "{}"
    );
    localStorage.setItem("customer_rating", "");
    localStorage.setItem("sale_customer", null);
    localStorage.removeItem("customerNumPadHide", false);

    const reference_number = refrenceNumber(device);

    if (!jsonobj.hasOwnProperty("processdt")) {
      jsonobj.processdt = "";
      jsonobj.processdt = refrenceNumber();
      jsonobj.ref = refrenceNumber(device);
      jsonobj.Payments = jsonobj.payment;
      jsonobj.payments = jsonobj.payment;
      jsonobj.items = Details;
      jsonobj.numitems = parseFloat(totalNumberItem.reduce((a, b) => a + b, 0));
      jsonobj.status = COMPLETEVALUE;
      jsonobj.total = parseFloat(jsonobj.totalAmt.Total.toFixed(2));
      jsonobj.custid = customer?.id || "";
      jsonobj.custname = customer?.name || "";
      jsonobj.custphone = customer?.mobile || "";
      jsonobj.customer = customer || "";
    }

    console.log(`jsonobj`, jsonobj);
    var newJson = JSON.parse(JSON.stringify(jsonobj));
    jsonosales[reference_number] = {};
    jsonosales[reference_number].a = action;
    jsonosales[reference_number].data = jsonobj;
    jsonobj.store_id = storeId;
    jsonobj.temp_id = jsonobj?.ref.split("-")[2];
    console.log(`newJson`, newJson);
    let off_sales = JSON.parse(localStorage.getItem("off_sale"));
    console.log("off_sales is", off_sales);
    if (off_sales) {
      localStorage.setItem("off_sale", JSON.stringify([jsonobj, ...off_sales]));
    } else {
      localStorage.setItem("off_sale", JSON.stringify([jsonobj]));
    }

    if (!isWeb()) {
      // for electron
      saveSaleToFile(JSON.stringify(jsonosales[reference_number]), reference);

      if (reference == "" || reference == null) {
        let random_ref = Math.floor(Math.random() * (100000 - 10 + 1)) + 10;
        addOfflineSaleIdb(
          random_ref,
          jsonosales[reference_number],
          function (response) {
            if (callback != null) {
              callback(jsonosales);
            }
          }
        );
      } else {
        addOfflineSaleIdb(
          reference,
          jsonosales[reference_number],
          function (response) {
            if (callback != null) {
              callback(jsonosales);
            }
          }
        );
      }
    } else {
      localStorage.setItem("wpos_osales", JSON.stringify(jsonosales));
      // JSON.stringify
      if (callback != null) {
        callback(jsonosales);
      }
    }
    console.log("off_sale are", JSON.parse(localStorage.getItem("off_sale")));
  };

  const updateOfflineSales = (jsonobj) => {
    let OfflineSales = localStorage.getItem("off_sale");
    if (OfflineSales) {
      OfflineSales = JSON.parse(localStorage.getItem("off_sale"));
      let Modified = OfflineSales.map((item) => {
        if (item?.status == 0 && jsonobj?.ref == item?.ref) {
          return {
            ...item,
            status: 1,
          };
        }
        return item;
      });
      localStorage.setItem("off_sale", JSON.stringify(Modified));
    }
  };

  const addOfflineSaleIdb = function (ref, data, callback) {
    window.knex = window.Knex({
      client: "sqlite3",
      debug: false,
      acquireConnectionTimeout: 180000,
      connection: {
        filename: window.appData + "database.sqlite",
      },
      useNullAsDefault: true,
      log: {
        warn(message) {
          console.log(message);
        },
        error(message) {
          console.log(message);
        },
        deprecate(message) {
          console.log(message);
        },
        debug(message) {
          console.log(message)
        },
      },
    });
    window.knex.schema.hasTable("data").then(function (exists) {
      if (!exists) {
        return window.knex.schema.createTable("data", function (t) {
          t.string("key", 100).primary();
          t.jsonb("data");
        });
      }
    });
    window.knex.schema.hasTable("wpos_osales").then(function (exists) {
      if (!exists) {
        return window.knex.schema.createTable("wpos_osales", function (t) {
          t.string("ref", 100).primary();
          t.jsonb("data");
        });
      }
    });

    window.knex.schema.hasTable("wpos_osales").then(function (exists) {
      if (exists) {
        console.log(`data`, data);
        console.log(`ref`, ref);
        // window.knex('wpos_osales')
        //   .where('ref', ref)
        //   .del().then(function () {
        window
          .knex("wpos_osales")
          .insert({ ref: ref, data: JSON.stringify(data) })
          .then(function (row) {
            console.log("inserted>");
            callback(true);
          })
          .catch(function (e) {
            saveSaleToFile(JSON.stringify(data), ref, "storageerrors");
            console.error("error inserting to db", e);
            callback({});
          });
        // }).catch(function (e) {
        //   saveSaleToFile(JSON.stringify(data), ref, 'storageerrors')
        //   console.error('error inserting to db', e)
        //   callback({});
        // })
      }
    });
  };

  const saveSaleToFile = (sale, ref, folder = "saleslogs") => {
    if (!isWeb()) {
      try {
        var salesPath = window.appData + "/" + folder;
        if (!window.fs.existsSync(salesPath)) {
          window.fs.mkdirSync(salesPath);
        }
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + "-" + dd + "-" + yyyy;
        var SaleFolder = salesPath + "/" + today;
        if (!window.fs.existsSync(SaleFolder)) {
          window.fs.mkdirSync(SaleFolder);
        }
        var d = new Date(); // for now
        var h = d.getHours();
        var m = d.getMinutes();
        var s = d.getSeconds();
        var filename = h + "_" + m + "_" + s + "_" + ref + ".json";
        var path = SaleFolder + "/" + filename;
        window.fs.writeFile(path, sale, { flag: "wx" }, function (err) {
          if (err) throw err;
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleLoader = (value, type) => {
    setLoader(value);
    setLoaderType(type);
  };

  // const [printForwd, setPrintForwd,] = useState(null);
  // useEffect(() => {
  //   setPrintForwd(forwardRef(componentRef));
  // }, [componentRef]);
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const PrintFn = () => {
    const wosConfig = JSON.parse(localStorage.getItem("wpos_config"));
    if (wosConfig?.connectMethod === "ep") {
      if (isWeb()) {
        ShowMsgInfo(
          true,
          "Electron print support only in electron application.",
          "Alert!",
          ""
        );
        setElectronError(true);
      } else {
        if (localStorage.getItem("paxerror") == "timeout") {
          handleClosePaxpopUp();
        } else {
          handleClosepopUp();
        }
        const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
        for (let i = 1; i <= wpos_config.reciptOfPrint; i++) {
          ThermalPrintContent();
        }
      }
    } else {
      setTimeout(
        function () {
          handleClosepopUp();
        },
        [500]
      );
      // handlePrint();
      handleBrowserReceiptPrint();
    }
  };

  const ThermalPrintContent = async () => {
    // window.electron.remote.getCurrentWindow().webContents.print(options);

    // ESC/P receipt generation
    var esc_init = "\x1B" + "\x40"; // initialize printer
    var esc_p = "\x1B" + "\x70" + "\x30"; // open drawer
    var esc_full_cut = "\x1B" + "\x69"; // esc obsolute full (one point left) cut
    var esc_part_cut = "\x1B" + "\x6D"; // esc obsolute partial (three points left) cut
    var gs_full_cut = "\x1D" + "\x56" + "\x30"; // cut paper
    var gs_part_cut = "\x1D" + "\x56" + "\x31"; // partial cut paper
    var esc_a_l = "\x1B" + "\x61" + "\x30"; // align left
    var esc_a_c = "\x1B" + "\x61" + "\x31"; // align center
    var esc_a_r = "\x1B" + "\x61" + "\x32"; // align right
    var esc_double = "\x1B" + "\x21" + "\x31"; // heading
    var font_reset = "\x1B" + "\x21" + "\x02"; // styles off
    var esc_ul_on = "\x1B" + "\x2D" + "\x31"; // underline on
    var esc_ul_off = "\x1B" + "\x2D" + "\x30"; // underline off
    var esc_bold_on = "\x1B" + "\x45" + "\x31"; // emphasis on
    var esc_bold_off = "\x1B" + "\x45" + "\x30"; // emphasis off

    var cmd = getEscReceiptHeader();
    cmd += getReceiptContent();

    // getLogo();

    function getEscReceiptHeader() {
      // var header = esc_init+esc_a_c+"Bottlepos" + "\n";
      // return header;
      const posSettings = JSON.parse(localStorage.getItem("posSetting"));
      const generalSettings = JSON.parse(
        localStorage.getItem("generalSetting")
      );
      let header =
        esc_init +
        esc_a_c +
        esc_double +
        generalSettings?.BUSINESS?.business_name +
        font_reset +
        "\n";
      header += esc_bold_on + posSettings?.RECEIPT?.header_line_2 + "\n";
      header += posSettings?.RECEIPT?.header_line_3 + "\n";
      return header;
    }

    const d_image = getDetailsofImage("PrintReciptLogo");
    if (d_image !== undefined && d_image.length > 0) {
      if (d_image[0]?.file !== undefined) {
        const origimage1 = FormatImageBase64Code(d_image[0].file);
        var salesPath = window.appData + "/logo";
        if (!window.fs.existsSync(salesPath)) {
          window.fs.mkdirSync(salesPath);
        }
        const base64Data = origimage1.replace(/^data:image\/png;base64,/, "");
        window.fs.writeFileSync(salesPath + "/image.png", base64Data, "base64");
      }
    }

    function getReceiptContent() {
      const posSettings = JSON.parse(localStorage.getItem("posSetting"));
      const generalSettings = JSON.parse(
        localStorage.getItem("generalSetting")
      );
      const reference = JSON.parse(localStorage.getItem("ref"));
      console.log(`props---->`, props);
      let saledate = parseInt(localStorage.getItem("saleDate"));
      var tax = 0;
      var qty = 0;
      var subtotal = 0;
      if (props?.salesitemList.length == 0) {
        console.log(`cashTotalAmount`, cashTotalAmount);
        tax = cashTotalAmount?.reduce(function (prev, current) {
          return prev + +current.taxTotal;
        }, 0);
        qty = cashTotalAmount?.reduce(function (prev, current) {
          return prev + +current.qty;
        }, 0);
        subtotal = cashTotalAmount?.reduce(function (prev, current) {
          return prev + +current.subtotal;
        }, 0);
      } else {
        tax = props?.salesitemList?.reduce(function (prev, current) {
          return prev + +current.taxTotal;
        }, 0);
        qty = props?.salesitemList?.reduce(function (prev, current) {
          return prev + +current.qty;
        }, 0);
        subtotal = props?.salesitemList?.reduce(function (prev, current) {
          return prev + +current.subtotal;
        }, 0);
      }

      const total = subtotal + tax;
      console.log(`total`, total);

      var isGiftCardPayment = false;
      if (props?.payment.length > 0) {
        for (let i = 0; i < props.payment.length; i++) {
          if (props.payment[i].method == "gift") {
            isGiftCardPayment = true;
            break;
          }
        }
      }

      console.log(`salesitemList------`, props.salesitemList);

      var total_tendered = 0;
      if (props?.payment.length == 0) {
        total_tendered = cashPayments?.reduce(function (prev, current) {
          return prev + +current.tender;
        }, 0);
      } else {
        total_tendered = props?.payment?.reduce(function (prev, current) {
          return prev + +current.tender;
        }, 0);
      }

      let content = "\n" + font_reset;
      if (posSettings?.RECEIPT?.print_sale_id == 1) {
        content += getEscTableRow(
          "Transaction Ref:",
          reference,
          false,
          false,
          true
        );
      }

      if (transId != undefined && transId != "") {
        content += getEscTableRow(
          "Transaction ID:",
          transId,
          false,
          false,
          true
        );
      }

      content +=
        getEscTableRow(
          "Sale Time:",
          dateFormate(saledate),
          false,
          false,
          true
        ) + "\n";

      if (props?.salesitemList.length == 0) {
        for (var x = 0; x < cashTotalAmount.length; x++) {
          let item = cashTotalAmount[x];
          content +=
            getEscTableRow(
              item.qty +
              " x " +
              item.manualname +
              " (" +
              currencyFormat(item?.price) +
              ")",
              currencyFormat(item?.subtotal),
              false,
              false,
              true
            ) + "\n";
          if (item.promotion) {
            content +=
              getEscTableRow(item.showText, false, false, false, true) + "\n";
          }
        }
      } else {
        for (var x = 0; x < props.salesitemList.length; x++) {
          let item = props.salesitemList[x];
          content +=
            getEscTableRow(
              item.qty +
              " x " +
              item.manualname +
              " (" +
              currencyFormat(item?.price) +
              ")",
              currencyFormat(item?.subtotal),
              false,
              false,
              true
            ) + "\n";
          if (item.promotion) {
            content +=
              getEscTableRow(item.showText, false, false, false, true) + "\n";
          }
        }
      }

      content +=
        esc_bold_on +
        getEscTableRow(
          "Subtotal:",
          currencyFormat(subtotal),
          false,
          false,
          true
        );

      let uniqueTaxes = "";
      console.log(`cashTotalAmount`, cashTotalAmount);
      console.log(`props.salesitemList`, props.salesitemList);

      if (props?.salesitemList.length == 0) {
        uniqueTaxes = cashTotalAmount.reduce((acc, current) => {
          if (!acc[current.tax]) {
            acc[current.tax] = parseFloat(current.taxTotal);
          } else {
            acc[current.tax] += parseFloat(current.taxTotal);
          }
          return acc;
        }, {});
      } else {
        uniqueTaxes = props.salesitemList.reduce((acc, current) => {
          if (!acc[current.tax]) {
            acc[current.tax] = parseFloat(current.taxTotal);
          } else {
            acc[current.tax] += parseFloat(current.taxTotal);
          }
          return acc;
        }, {});
      }

      let tip = 0;

      if (props?.payment.length == 0) {
        tip = cashPayments.reduce((acc, item) => acc + item.tip_amount, 0);
      } else {
        tip = props?.payment.reduce((acc, item) => acc + item.tip_amount, 0);
      }

      Object.entries(uniqueTaxes).map(([taxName, taxValue], index) => {
        const tax_name = props.taxRules?.filter((item) => item.id == taxName);
        if (tax_name && tax_name.length > 0) {
          if (index == Object.keys(uniqueTaxes).length - 1) {
            if (tip != undefined && tip > 0) {
              content +=
                font_reset +
                getEscTableRow(
                  tax_name[0].name + ":",
                  currencyFormat(taxValue),
                  false,
                  false,
                  true
                );
            } else {
              content +=
                font_reset +
                getEscTableRow(
                  tax_name[0].name + ":",
                  currencyFormat(taxValue),
                  false,
                  true,
                  true
                );
            }
          } else {
            content +=
              font_reset +
              getEscTableRow(
                tax_name[0].name + ":",
                currencyFormat(taxValue),
                false,
                false,
                true
              );
          }
        }
      });

      if (tip != undefined && tip > 0) {
        content += getEscTableRow(
          "Tip Amount: ",
          currencyFormat(tip),
          false,
          true,
          true
        );
      }
      // content += esc_bold_on + getEscTableRow('Total (' + qty + ' items):', currencyFormat(sumCash), false, true, true);
      content +=
        esc_bold_on +
        getEscTableRow(
          "Total (" + qty + " items):",
          currencyFormat(total + parseFloat(tip)),
          false,
          true,
          true
        );
      content += getEscTableRow(
        "Total Tendered:",
        currencyFormat(total_tendered + parseFloat(tip)),
        false,
        false,
        true
      );
      content += font_reset;

      if (props?.payment.length == 0) {
        for (var x = 0; x < cashPayments.length; x++) {
          let itm = cashPayments[x];
          content +=
            getEscTableRow(
              itm.method + ":",
              currencyFormat(itm?.amount),
              false,
              false,
              true
            ) +
            getEscTableRow(
              "Tendered:",
              currencyFormat(itm?.tender),
              false,
              false,
              true
            ) +
            getEscTableRow(
              "Change:",
              currencyFormat(itm?.change),
              false,
              false,
              true
            );
        }
      } else {
        for (var x = 0; x < props?.payment.length; x++) {
          let itm = props?.payment[x];
          if (saleStatus != "" && saleStatus == "Canceled") {
            content += getEscTableRow(
              itm.method + ":",
              currencyFormat(itm?.amount),
              false,
              false,
              true
            );
          } else {
            content +=
              getEscTableRow(
                itm.method + ":",
                currencyFormat(itm?.amount),
                false,
                false,
                true
              ) +
              getEscTableRow(
                "Tendered:",
                currencyFormat(itm?.tender),
                false,
                false,
                true
              ) +
              getEscTableRow(
                "Change:",
                currencyFormat(itm?.change),
                false,
                false,
                true
              );
          }
        }
        console.log("saleStatus", saleStatus);
        if (saleStatus != "" && saleStatus == "Canceled") {
          content += esc_bold_on + esc_a_c + "\n" + "Sale Canceled";
        }
      }
      content += font_reset;

      if (props?.payment.length == 0) {
        for (var x = 0; x < cashPayments.length; x++) {
          let itm = cashPayments[x];
          if (itm.method == "ebt" || itm.method == "ebt cash") {
            content += getEscTableRow(
              "EBT Cash Balance:",
              currencyFormat(itm?.ebt.Balance1),
              false,
              false,
              true
            );
            content += getEscTableRow(
              "EBT Food Stamp Balance:",
              currencyFormat(itm?.ebt.Balance2),
              false,
              false,
              true
            );
          } else if (itm.method == "gift") {
            content += getEscTableRow(
              "Gift Card Balance:",
              currencyFormat(itm?.ebt.Balance1),
              false,
              false,
              true
            );
          }
        }
      } else {
        for (var x = 0; x < props?.payment.length; x++) {
          let itm = props?.payment[x];
          if (itm.method == "ebt" || itm.method == "ebt cash") {
            content += getEscTableRow(
              "EBT Cash Balance:",
              currencyFormat(itm?.ebt.Balance1),
              false,
              false,
              true
            );
            content += getEscTableRow(
              "EBT Food Stamp Balance:",
              currencyFormat(itm?.ebt.Balance2),
              false,
              false,
              true
            );
          }
        }
      }
      let payment = props?.payment[0];
      if (cardResponse != "" && saleStatus != "Canceled") {
        content += "\n";
        if (cardResponse.HostInformation.HostReferenceNumber != "") {
          content += getEscTableRow(
            "Reference Number:",
            cardResponse.HostInformation.HostReferenceNumber,
            false,
            false,
            true
          );
        }
        if (cardResponse.cardHolderName != "") {
          content += getEscTableRow(
            "Card Type:",
            cardResponse.cardHolderName.trim(),
            false,
            false,
            true
          );
        }
        if (cardResponse.accountNumber != "") {
          content += getEscTableRow(
            "Account:",
            cardResponse.accountNumber,
            false,
            false,
            true
          );
        }
        content += getEscTableRow("Entry:", "Swipe", false, false, true);
        if (cardResponse.isApproved === true) {
          content += getEscTableRow(
            "Response Code:",
            "APPROVED",
            false,
            false,
            true
          );
        }
        if (cardResponse.statusCode != "") {
          content += getEscTableRow(
            "Status:",
            cardResponse.statusCode,
            false,
            false,
            true
          );
        }
        if (payment.method == "gift") {
          content += getEscTableRow(
            "Gift Card Balance:",
            currencyFormat(payment?.ebt.Balance1),
            false,
            false,
            true
          );
        }
        if (tip != undefined && tip > 0) {
          content += getEscTableRow(
            "Tip Amount: ",
            currencyFormat(tip),
            false,
            false,
            true
          );
        }
        if (cardResponse.approvedAmount != "") {
          content += getEscTableRow(
            "Sale:",
            cardResponse.approvedAmount,
            false,
            false,
            true
          );
        }
      }

      if (
        cardResponse != "" &&
        posSettings?.RECEIPT?.print_signature_line == "1" &&
        isGiftCardPayment == false
      ) {
        content += "\n";
        content += "\n";
        content += "X_________________________________";
        content += "\n";
        content +=
          "Cardmember agrees to pay total \n in accordance with agreement \n governing use of such card.";
      }

      console.log(`props?.custlist`, props?.custlist);

      if (props?.custMobile?.length > 0) {
        const customer = props?.custlist.filter(
          (item) => item.mobile == props?.custMobile[0]
        );
        if (
          customer.length > 0 &&
          posSettings?.RECEIPT?.print_customer_details == 1
        ) {
          if (posSettings?.RECEIPT?.print_customer_name == 1) {
            content += "\n";
            content += getEscTableRow(
              "Customer Name:",
              customer[0]?.name,
              false,
              false,
              true
            );
          }
          if (posSettings?.RECEIPT?.print_customer_mobile == 1) {
            content += getEscTableRow(
              "Customer Mobile:",
              customer[0]?.mobile,
              false,
              false,
              true
            );
          }
          if (posSettings?.RECEIPT?.print_customer_address == 1) {
            content += getEscTableRow(
              "Customer Address:",
              customer[0]?.address,
              false,
              false,
              true
            );
          }
          content += getEscTableRow(
            "Customer Postcode:",
            customer[0]?.postcode,
            false,
            false,
            true
          );
          if (posSettings?.RECEIPT?.print_customer_notes == 1) {
            content += getEscTableRow(
              "Notes:",
              customer[0]?.notes,
              false,
              false,
              true
            );
          }
        }
      }

      if (posSettings?.RECEIPT?.receipt_footer_text != undefined) {
        content +=
          "\n" +
          esc_bold_on +
          esc_a_c +
          posSettings?.RECEIPT?.receipt_footer_text;
      }
      content += font_reset;

      if (posSettings?.RECEIPT?.promo_qr_code) {
        // content += <QRCode style={{ zIndex: 1 }} value={posSettings?.RECEIPT?.promo_qr_code} />
      }
      return content;
    }

    const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
    const posSettings = JSON.parse(localStorage.getItem("posSetting"));
    let qrtext = posSettings?.RECEIPT?.promo_qr_code;

    var thermaPrinter = preparePrinter("epson");
    thermaPrinter.alignCenter();
    if (
      salesPath != undefined &&
      posSettings?.RECEIPT?.print_receipt_logo == 1
    ) {
      await thermaPrinter.printImage(salesPath + "/image.png");
    }
    thermaPrinter.drawLine();
    thermaPrinter.newLine();
    thermaPrinter.println(cmd);
    if (qrtext == "" || qrtext == null) {
      thermaPrinter.cut();
    }

    window.nodeprinter.printDirect({
      printerName: wpos_config.report_printer,
      type: "RAW",
      data: thermaPrinter.getBuffer(),
      success: function (jobID) {
        if (qrtext != "" && qrtext != null) {
          printQrCode(qrtext, true);
        }
        console.log(`Printed with Job ID: ${jobID}`);
        console.log(`props?.payment.length`, props);
        // if(props?.payment.length == 0){
        //   for (var x = 0; x < cashPayments.length; x++) {
        //     let itm =cashPayments[x];
        //     console.log(`itm.method`,itm.method);
        //     if (itm.method == "Cash" && wpos_config.cash_draw_connected == true) {
        //       Cashdraw(true);
        //     }
        //   }
        // }else{
        //   for (var x = 0; x < props?.payment.length; x++) {
        //     let itm = props?.payment[x];
        //     console.log(`itm.method`,itm.method);
        //     if (itm.method == "Cash" && wpos_config.cash_draw_connected == true) {
        //       Cashdraw(true);
        //     }
        //   }
        // }
      },
      error: function (err) {
        console.error(err);
      },
    });
  };

  const printQrCode = async (qrtext, cutpaper) => {
    const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));

    let thermaPrinter = preparePrinter("epson");
    thermaPrinter.newLine();
    thermaPrinter.printQR(qrtext, {
      cellSize: 5, // 1 - 8
      correction: "H", // L(7%), M(15%), Q(25%), H(30%)
      model: 1, // 1 - Model 1
      // 2 - Model 2 (standard)
      // 3 - Micro QR
    });

    if (cutpaper) {
      thermaPrinter.cut();
    }

    window.nodeprinter.printDirect({
      data: thermaPrinter.getBuffer(),
      type: "RAW", // type: RAW, TEXT, PDF, JPEG, .. depends on platform
      printerName: wpos_config.report_printer,
      success: function (jobID) {
        console.log("sent to printer with ID: " + jobID);
      },
      error: function (err) {
        console.log(err);
      },
    });
  };

  function getDetailsofImage(type) {
    if (type !== undefined) {
      const detailsData = props?.image.filter((item) => item.name === type);
      return detailsData;
    }
  }

  function getEscTableRow(
    leftstr,
    rightstr,
    bold,
    underline,
    stretch,
    name = ""
  ) {
    var esc_init = "\x1B" + "\x40"; // initialize printer
    var esc_p = "\x1B" + "\x70" + "\x30"; // open drawer
    var esc_full_cut = "\x1B" + "\x69"; // esc obsolute full (one point left) cut
    var esc_part_cut = "\x1B" + "\x6D"; // esc obsolute partial (three points left) cut
    var gs_full_cut = "\x1D" + "\x56" + "\x30"; // cut paper
    var gs_part_cut = "\x1D" + "\x56" + "\x31"; // partial cut paper
    var esc_a_l = "\x1B" + "\x61" + "\x30"; // align left
    var esc_a_c = "\x1B" + "\x61" + "\x31"; // align center
    var esc_a_r = "\x1B" + "\x61" + "\x32"; // align right
    var esc_double = "\x1B" + "\x21" + "\x31"; // heading
    var font_reset = "\x1B" + "\x21" + "\x02"; // styles off
    var esc_ul_on = "\x1B" + "\x2D" + "\x31"; // underline on
    var esc_ul_off = "\x1B" + "\x2D" + "\x30"; // underline off
    var esc_bold_on = "\x1B" + "\x45" + "\x31"; // emphasis on
    var esc_bold_off = "\x1B" + "\x45" + "\x30"; // emphasis off
    var ltr = true;

    var sheet_width = 48;
    sheet_width = sheet_width ? sheet_width : 48;
    rightstr = rightstr != undefined && rightstr != "" ? rightstr : "-";
    leftstr = leftstr.toString();
    rightstr = rightstr.toString();
    var pad = "";
    // adjust for bytes of escp commands that set the character set
    var llength =
      leftstr.indexOf("\x1B\x74") !== -1
        ? leftstr.length - 3 * (leftstr.match(/\x1B\x74/g) || []).length
        : leftstr.length;
    var rlength =
      rightstr.indexOf("\x1B\x74") !== -1
        ? rightstr.length - 3 * (rightstr.match(/\x1B\x74/g) || []).length
        : rightstr.length;
    if (llength + rlength > sheet_width) {
      // var clip = (llength + rlength) - sheet_width; // get amount to clip
      // leftstr = leftstr.substring(0, (llength - (clip + 3)));
      // pad = "...";
      //no need to add ... we need to show all info lets go to new line
      if (name == "price") {
        var mid = sheet_width - rlength - 1;
        //rightstr = " "+rightstr+"  "+leftstr.substring(mid,llength);
        rightstr =
          " " + rightstr + "  " + "\n" + leftstr.substring(mid, llength);
        leftstr = leftstr.substring(0, mid);
      }
    } else {
      var num = sheet_width - (llength + rlength);
      pad = new Array(num + 1).join(" ");
    }
    var row;
    if (ltr) {
      row =
        leftstr +
        (stretch ? pad : "") +
        (underline ? esc_ul_on : "") +
        rightstr +
        (underline ? esc_ul_off : "") +
        (!stretch ? pad : "") +
        "\n";
    } else {
      row =
        (!stretch ? pad : "") +
        (underline ? esc_ul_on : "") +
        rightstr +
        (underline ? esc_ul_off : "") +
        (stretch ? pad : "") +
        leftstr +
        "\n";
    }
    if (bold) {
      // format row
      row = esc_bold_on + row + esc_bold_off;
    }
    return row;
  }

  const Cashdraw = (close_value) => {
    const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
    var thermaPrinter = preparePrinter("epson");
    thermaPrinter.openCashDrawer();
    //thermaPrinter.println(data);
    window.nodeprinter.printDirect({
      data: thermaPrinter.getBuffer(),
      type: "RAW", // type: RAW, TEXT, PDF, JPEG, .. depends on platform
      printer: wpos_config.report_printer,
      success: function (jobID) {
        console.log("sent to printer with ID: " + jobID);
        if (close_value === true) {
          handleClosepopUp();
        }
      },
      error: function (err) {
        console.log(err);
      },
    });
  };

  function preparePrinter(type) {
    let thermaPrinter;
    if (type == "star") {
      thermaPrinter = new window.ThermalPrinter({
        type: "STAR",
      });
    } else {
      thermaPrinter = new window.ThermalPrinter({
        type: "EPSON",
      });
    }
    return thermaPrinter;
  }

  const ShowMsgInfo = (modal, type, msg, card_type = null) => {
    setOpenErrorMsg(modal);
    setErrorMsg(type);
    setTitle(msg);
    setCardType(card_type);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleBrowserReceiptPrint = () => {
    let todayDate = moment(new Date()).format("DD/MM/YYYY");
    let timeNow = moment(new Date()).format("HH:mm");
    const posSettings = JSON.parse(localStorage.getItem("posSetting"));
    const generalSettings = JSON.parse(localStorage.getItem("generalSetting"));
    const reference = JSON.parse(localStorage.getItem("ref"));
    const paymentArr = localStorage.getItem("payment_reprint") ? JSON.parse(localStorage.getItem("payment_reprint")) : [];

    let date_format;
    if (generalSettings?.FORMATES?.Date_Format == "m/d/y") {
      date_format = "MM/DD/YYYY";
    } else if (generalSettings?.FORMATES?.Date_Format == "d/m/y") {
      date_format = "DD/MM/YYYY";
    } else if (generalSettings?.FORMATES?.Date_Format == "y/m/d") {
      date_format = "YYYY/MM/DD";
    }

    let qty = 0;
    let tax = 0;
    let subtotal = 0;
    let additionalCharges = []
    if (props?.saleslist && props?.saleslist.length != 0) {
      qty = props?.saleslist?.reduce(function (prev, current) {
        return prev + +current.qty;
      }, 0);
      tax = props?.saleslist?.reduce(function (prev, current) {
        return prev + +current.taxTotal;
      }, 0);
      subtotal = props?.saleslist?.reduce(function (prev, current) {
        return prev + +current.qty * +current?.price;
      }, 0);
      additionalCharges = combineObjectsAdditionalFee(
        props?.salesitemList.map((data) => {
          return calculateFee(
            data.fee,
            props?.additionalFee,
            data.additionalFeeEnable,
            data.qty,
            data.price,
            data.id
          );
        })
      );
    } else if (cashTotalAmount && cashTotalAmount?.length != 0) {
      qty = cashTotalAmount?.reduce(function (prev, current) {
        return prev + +current.qty;
      }, 0);
      tax = cashTotalAmount?.reduce(function (prev, current) {
        return prev + +current.taxTotal;
      }, 0);
      subtotal = cashTotalAmount?.reduce(function (prev, current) {
        return prev + +current.qty * +current?.price;
      }, 0);
      additionalCharges = combineObjectsAdditionalFee(
        props?.salesitemList.map((data) => {
          return calculateFee(
            data.fee,
            props?.additionalFee,
            data.additionalFeeEnable,
            data.qty,
            data.price,
            data.id
          );
        })
      );
    } else {
      qty = props?.salesitemList?.reduce(function (prev, current) {
        return prev + +current.qty;
      }, 0);
      tax = props?.salesitemList?.reduce(function (prev, current) {
        return prev + +current.taxTotal;
      }, 0);
      subtotal = props?.salesitemList?.reduce(function (prev, current) {
        return prev + +current.qty * +current?.price;
      }, 0);
      additionalCharges = combineObjectsAdditionalFee(
        props?.salesitemList.map((data) => {
          return calculateFee(
            data.fee,
            props?.additionalFee,
            data.additionalFeeEnable,
            data.qty,
            data.price,
            data.id
          );
        })
      );
    }

    var total_tendered = 0;
    let tip_amount = 0;
    let isEbtSale = false;
    if (cashPayments?.length != 0) {
      total_tendered = cashPayments?.reduce(function (prev, current) {
        return prev + +current.tender;
      }, 0);
      tip_amount = cashPayments?.reduce(function (prev, current) {
        return prev + +(current.tip_amount ? current.tip_amount : 0);
      }, 0);
      if (checkEbt(cashPayments)) {
        isEbtSale = true
      }
    } else if (props?.payment?.length != 0) {
      total_tendered = props?.payment?.reduce(function (prev, current) {
        return prev + +current.tender;
      }, 0);
      tip_amount = props?.payment?.reduce(function (prev, current) {
        return prev + +(current.tip_amount ? current.tip_amount : 0);
      }, 0);
      if (checkEbt(props?.payment)) {
        isEbtSale = true
      }
    } else {
      total_tendered = paymentArr?.reduce(function (prev, current) {
        return prev + +current.tender;
      }, 0);
      tip_amount = paymentArr?.reduce(function (prev, current) {
        return prev + +(current.tip_amount ? current.tip_amount : 0);
      }, 0);
      if (checkEbt(paymentArr)) {
        isEbtSale = true
      }
    }
    let saledate = parseInt(localStorage.getItem("saleDate"));

    let origimage1 = "";
    const d_image = getDetailsofImage("PrintReciptLogo");
    if (d_image !== undefined && d_image.length > 0) {
      if (d_image[0]?.file !== undefined) {
        origimage1 = FormatImageBase64Code(d_image[0].file);
      }
    }

    let allTax = [];
    let uniqueTaxes = "";

    if (props?.salesitemList != undefined && props?.salesitemList.length > 0) {
      tax = 0;
      uniqueTaxes = props?.salesitemList.reduce((acc, current) => {
        if (isEbtSale && current?.ebt) {
          if (props?.accountSetting?.TAXRULEDATA?.deafultEbtTax == 47) {
            return acc;
          }
          let taxTotal = taxDetailsByItemId(
            props?.accountSetting?.TAXRULEDATA?.deafultEbtTax,
            current.qty,
            current.price,
            props.taxList,
            props.taxRules,
            current.fee,
            props?.additionalFee,
            current.bottle_deposit,
            props?.bottleDeposit,
            current.additionalFeeEnable,
            current.bottleDepositEnable,
            current.bottleDepositTaxable
          )?.total;
          tax += +taxTotal
          if (!acc[props?.accountSetting?.TAXRULEDATA?.deafultEbtTax]) {
            acc[props?.accountSetting?.TAXRULEDATA?.deafultEbtTax] = parseFloat(taxTotal);
          } else {
            acc[props?.accountSetting?.TAXRULEDATA?.deafultEbtTax] += parseFloat(taxTotal);
          }

        } else {
          tax += +current.taxTotal
          if (!acc[current.tax]) {
            acc[current.tax] = parseFloat(current.taxTotal);
          } else {
            acc[current.tax] += parseFloat(current.taxTotal);
          }
        }
        return acc;
      }, {});
    } else {
      uniqueTaxes = cashTotalAmount.reduce((acc, current) => {
        if (isEbtSale && current?.ebt) {
          if (props?.accountSetting?.TAXRULEDATA?.deafultEbtTax == 47) {
            return acc;
          }
          let taxTotal = taxDetailsByItemId(
            props?.accountSetting?.TAXRULEDATA?.deafultEbtTax,
            current.qty,
            current.price,
            props.taxList,
            props.taxRules,
            current.fee,
            props?.additionalFee,
            current.bottle_deposit,
            props?.bottleDeposit,
            current.additionalFeeEnable,
            current.bottleDepositEnable,
            current.bottleDepositTaxable
          )?.total;
          tax += +taxTotal
          if (!acc[props?.accountSetting?.TAXRULEDATA?.deafultEbtTax]) {
            acc[props?.accountSetting?.TAXRULEDATA?.deafultEbtTax] = parseFloat(taxTotal);
          } else {
            acc[props?.accountSetting?.TAXRULEDATA?.deafultEbtTax] += parseFloat(taxTotal);
          }
        } else {
          tax += +current.taxTotal
          if (!acc[current.tax]) {
            acc[current.tax] = parseFloat(current.taxTotal);
          } else {
            acc[current.tax] += parseFloat(current.taxTotal);
          }
        }
        return acc;
      }, {});
    }

    Object.entries(uniqueTaxes).map(([taxName, taxValue]) => {
      const tax_name = props.taxRules?.filter((item) => item.id == taxName);
      if (tax_name && tax_name.length > 0) {
        let array = {
          tax_name: tax_name[0].name,
          total: taxValue,
        };
        allTax.push(array);
      }
    });
    const customer = props?.custlist?.filter(
      (item) => item.mobile == props?.custMobile?.[0]
    );
    console.log("allTax", allTax, tax, subtotal)
    const transaction_response = props?.payment[props?.payment?.length - 1]?.transaction_response
    const paymentStatus = transaction_response?.status === "capture_requested" || transaction_response?.status === "succeeded" ? "Approved" : ""

    let printw = window.open("", "Receive Details", "height=800,width=650");
    if (printw) {
      printw.document.write(
        `<html>
           <head>
            <style> 
              @media print {} @page {size: landscape} 
            </style>`
      );
      printw.document.write(
        '</head><body style="font-family: \'Open Sans\', \'Helvetica Neue\',Helvetica,Arial,sans-serif;">'
      );

      printw.document.write(`
      <div id="container" style="text-align: center;">
        ${(origimage1 != undefined && posSettings?.RECEIPT?.print_receipt_logo == 1)
          ? `<img style="width: 260px" src=${origimage1} alt="logo" />`
          : ''
        }
        <div>
            <h3 style="margin-top: 5px; margin-bottom: 5px;">
              ${generalSettings?.BUSINESS?.business_name}
            </h3>
            <p style="margin-top: 0px; margin-bottom: 5px;">
              <strong>${posSettings?.RECEIPT?.header_line_2 ? posSettings?.RECEIPT?.header_line_2 : ''}</strong>
            </p>
            <p style="margin-top: 0px; margin-bottom: 5px;">
              <strong>${posSettings?.RECEIPT?.header_line_3 ? posSettings?.RECEIPT?.header_line_3 : ''}</strong>
            </p>
        </div>

        <div>
          <div style="font-size: 0;">
            <table style="margin: 0 auto; font-size: 16px;">
              <tbody>
              ${posSettings?.RECEIPT?.print_sale_id == 1 ?
          `<tr>
                  <td style="text-align: start">Transaction Ref:</td>
                  <td style="text-align: end">${reference}</td>
                </tr>`
          : ''
        }
                <tr>
                  <td style="text-align: start">Transaction ID:</td>
                  <td style="text-align: end">${transId}</td>
                </tr>
                <tr>
                  <td style="text-align: start">Sale Time:</td>
                  <td style="text-align: end">${todayDate} ${timeNow}</td>
                </tr>
                ${categoryList?.ageverification?.length > 0 && props.enterAge != ''
          ? `<tr>
                      <td style="text-align: start">Age Verification Checked</td>
                      <td style="text-align: end">${props.enterAge}</td>
                    </tr>`
          : ''
        }
                ${props?.saleslist?.length > 0
          ? props?.saleslist?.map((itm) => {
            return `
                          <tr>
                            <td style="text-align: start">
                              ${itm.qty} x ${itm.manualname}
                              ${posSettings?.RECEIPT?.print_item_price == 1 ? `(${currencyFormat(itm?.price)})` : ''}
                            </td>
                            <td style="text-align: end">${currencyFormat(itm.qty * itm?.price)}</td>
                          </tr>
                          ${itm?.promotion ?
                `<tr>
                              <td style="text-align: start">${itm?.showText} </td>
                            </tr>`
                : ''}`;
          })
          : cashTotalAmount?.map((itm) => {
            return `<tr>
                            <td style="text-align: start">
                              ${itm.qty} x ${itm.manualname}
                              ${posSettings?.RECEIPT?.print_item_price == 1 ? `(${currencyFormat(itm?.price)})` : ''}
                            </td>
                            <td style="text-align: end">${currencyFormat(itm.qty * itm?.price)}</td>
                          </tr>

                          ${itm?.promotion
                ? `<tr>
                              <td style="text-align: start">${itm?.showText}</td>
                            </tr>`
                : ''}`;
          })
        }

                <tr>
                  <td style="text-align: start">Subtotal:</td>
                  <td style="text-align: end">${currencyFormat(subtotal)}</td>
                </tr> 

                ${allTax?.length > 0
          ? allTax?.map((itm) => {
            if (itm.total > 0) {
              return `<tr>
                            <td style="text-align: start">${itm.tax_name}</td>
                            <td style="text-align: end">${currencyFormat(itm.total)}</td>
                          </tr>`
            }
          })
          : ''
        }

              <tr>
                <td style="text-align: start">B Deposit:</td>
                <td style="text-align: end">${currencyFormat(0)}</td>
              </tr>

              ${additionalCharges.length > 0 
                ? additionalCharges?.map((itm) => {
                    return `<tr>
                                  <td style="text-align: start">${itm?.costname}:</td>
                                  <td style="text-align: end">${currencyFormat(itm?.total)}</td>
                                </tr>`
                  }) 
                : ``
              }

              ${roundOffTotalPay(subtotal + tax) - (subtotal + tax) > 0 ?
          `<tr>
                  <td style="text-align: start">Rounding:</td>
                  <td style="text-align: end">
                    ${currencyFormat(
            roundOffTotalPay(subtotal + tax) - (subtotal + tax)
          )}
                  </td>
                </tr>`
          : ''}
          ${tip_amount > 0 ? `
          <tr>
                  <td style="text-align: start">Tips Amount:</td>
                  <td style="text-align: end">
                    ${currencyFormat(tip_amount)}
                  </td>
                </tr>
          ` : ``}

              <tr>
                <td style="text-align: start">
                  <strong>Total (${qty} items):</strong>
                </td>
                <td style="text-align: end; text-decoration: overline;">
                  <strong>
                    ${currencyFormat(roundOffTotalPay(subtotal + tax + tip_amount))}
                  </strong>
                </td>
              </tr>

              <tr>
                <td style="text-align: start">
                  <strong>Total Tendered:</strong>
                </td>
                <td style="text-align: end; text-decoration: overline;">
                  <strong>
                    ${currencyFormat(total_tendered + tip_amount)}
                  </strong>
                </td>
              </tr>

              ${(props?.payment && props.payment?.length != 0)
          ? props?.payment?.map((item) => {
            return `<>
                        <tr>
                          <td style="text-align: start">${item.method}:</td>
                          <td style="text-align: end">${currencyFormat(item.amount)}</td>
                        </tr>
                        <tr>
                          <td style="text-align: start">Tendered:</td>
                          <td style="text-align: end">${currencyFormat(item.tender)}</td>
                        </tr>
                        <tr>
                          <td style="text-align: start">Change:</td>
                          <td style="text-align: end">${currencyFormat(item.change)}</td>
                        </tr>
                      </>`})
          : cashPayments?.length > 0
            ? cashPayments?.map((item) => {
              return `<>
                        <tr>
                          <td style="text-align: start">${item.method}:</td>
                          <td style="text-align: end">${currencyFormat(item.amount)}</td>
                        </tr>
                        <tr>
                          <td style="text-align: start">Tendered:</td>
                          <td style="text-align: end">${currencyFormat(item.tender)}</td>
                        </tr>
                        <tr>
                          <td style="text-align: start">Change:</td>
                          <td style="text-align: end">${currencyFormat(item.change)}</td>
                        </tr>
                      </>`})
            : paymentArr?.map((item) => {
              return `<>
                        <tr>
                          <td style="text-align: start">${item.method}:</td>
                          <td style="text-align: end">${currencyFormat(item.amount)}</td>
                        </tr>
                        <tr>
                          <td style="text-align: start">Tendered:</td>
                          <td style="text-align: end">${currencyFormat(item.tender)}</td>
                        </tr>
                        <tr>
                          <td style="text-align: start">Change:</td>
                          <td style="text-align: end">${currencyFormat(item.change)}</td>
                        </tr>
                      </>`})
        }

              ${(props?.custMobile?.length > 0 &&
          posSettings?.RECEIPT?.print_customer_details == 1 &&
          customer != "") ?
          `<>
                    ${posSettings?.RECEIPT?.print_customer_name == 1
            ? `<tr className="mt-2">
                        <td style="text-align: start">Customer Name:</td>
                        <td style="text-align: end">${customer[0]?.name}</td>
                      </tr>`
            : ''}
                    ${posSettings?.RECEIPT?.print_customer_mobile == 1
            ? `<tr>
                        <td style="text-align: start">Customer Mobile:</td>
                        <td style="text-align: end">${customer[0]?.mobile}</td>
                      </tr>`
            : ''}
                    ${posSettings?.RECEIPT?.print_customer_address == 1
            ? `<tr>
                        <td style="text-align: start;">Customer Address:</td>
                        <td style="text-align: end; maxWidth: 200px; wordWrap: break-word; ">${customer[0]?.address}</td>
                      </tr>`
            : ''}
                    ${posSettings?.RECEIPT?.print_customer_notes == 1
            ? `<tr>
                        <td style="text-align: start">Notes:</td>
                        <td style="text-align: end">${customer[0]?.notes}</td>
                      </tr>`
            : ''}
                  </>`
          : ''
        }
        ${transaction_response?.payment?.last_4 &&
        `<>
          <tr>
          <td colSpan="2">----------------------------------------------------------</td>
          </tr>
              <tr>
                <td style="text-align: start">Status:</td>
                <td style="text-align: end">${paymentStatus || ""}</td>
              </tr>
              <tr>
                <td style="text-align: start">Sale:</td>
                <td style="text-align: end">${currencyFormat(total_tendered) || ""}</td>
              </tr>
              <tr>
                <td style="text-align: start">Account:</td>
                <td style="text-align: end">********${transaction_response?.payment?.last_4 || ""}</td>
              </tr>
              <tr>
                <td style="text-align: start">App ID:</td>
                <td style="text-align: end">${transaction_response?.payment?.receipt_data?.emv_app_id || ""}</td>
              </tr>
              <tr>
                <td style="text-align: start">App Label:</td>
                <td style="text-align: end">${transaction_response?.payment?.receipt_data?.emv_app_label || ""}</td>
              </tr>
              <tr>
                <td style="text-align: start">Auth ID:</td>
                <td style="text-align: end">${transaction_response?.payment?.receipt_data?.emv_auth_code || ""}</td>
              </tr>
              <tr>
                <td style="text-align: start">Terminal ID:</td>
                <td style="text-align: end">${transaction_response?.payment?.receipt_data?.emv_term_id || ""}</td>
              </tr>
          </>`
        }
        ${transaction_response?.HostReferenceNumber &&
          `<>
            <tr>
            <td colSpan="2">----------------------------------------------------------</td>
            </tr>
                <tr>
                  <td style="text-align: start">Reference Number:</td>
                  <td style="text-align: end">${transaction_response?.HostReferenceNumber || ""}</td>
                </tr>
                <tr>
                  <td style="text-align: start">Card Type:</td>
                  <td style="text-align: end">${transaction_response?.cardHolderName || ""}</td>
                </tr>
                <tr>
                  <td style="text-align: start">Account:</td>
                  <td style="text-align: end">${transaction_response?.accountNumber || ""}</td>
                </tr>
                <tr>
                  <td style="text-align: start">Entry:</td>
                  <td style="text-align: end">Swipe</td>
                </tr>
                <tr>
                  <td style="text-align: start">Response Code:</td>
                  <td style="text-align: end">${transaction_response?.statusCode == "Sale Approved" ? "Approved" : "Declined"}</td>
                </tr>
                <tr>
                  <td style="text-align: start">Status:</td>
                  <td style="text-align: end">${transaction_response?.statusCode || ""}</td>
                </tr>
                ${transaction_response?.tip_amount > 0 ? `
                  <tr>
                    <td style="text-align: start">Tips Amount:</td>
                    <td style="text-align: end">
                      ${transaction_response?.tip_amount}
                    </td>
                  </tr>
                ` : ``}
                <tr>
                  <td style="text-align: start">Sale:</td>
                  <td style="text-align: end">${transaction_response?.approvedAmount || ""}</td>
                </tr>
            </>`
          }
              </tbody>
            </table>
            ${posSettings?.RECEIPT?.print_signature_line == "1"
          ? `<div style="font-size: 16px;">
                <p>X________________________</p>
              </div>`
          : ''}

            ${saleStatus != ""
          ? `<h4 style="font-size: 16px;">${saleStatus == "Canceled" ? "Sale Canceled" : ""}</h4>`
          : ''}

            <strong style="font-size: 16px;">
              ${posSettings?.RECEIPT?.receipt_footer_text ? posSettings?.RECEIPT?.receipt_footer_text : ''}
            </strong>

            ${posSettings?.RECEIPT?.promo_qr_code !== '' ?
          `<div style="font-size: 16px;">
                ${<QRCode
            style={{ zIndex: 1 }}
            size={100}
            value={posSettings?.RECEIPT?.promo_qr_code}
          />}
              </div>`
          : ''}
          </div>
        </div>
      </div>
    `);
      printw.document.write("</body></html>");
      printw.document.close();
      let printed = false;
      // console.log({ check: printw });
      if (!printed) {
        printed = true;
        printw.focus();
        printw.print();
      }
    }

  };

  const ComponentToPrint = React.forwardRef((props, ref) => {
    let todayDate = moment(new Date()).format("DD/MM/YYYY");
    let timeNow = moment(new Date()).format("HH:mm");
    const posSettings = JSON.parse(localStorage.getItem("posSetting"));
    const generalSettings = JSON.parse(localStorage.getItem("generalSetting"));
    const reference = JSON.parse(localStorage.getItem("ref"));
    let date_format;
    if (generalSettings?.FORMATES?.Date_Format == "m/d/y") {
      date_format = "MM/DD/YYYY";
    } else if (generalSettings?.FORMATES?.Date_Format == "d/m/y") {
      date_format = "DD/MM/YYYY";
    } else if (generalSettings?.FORMATES?.Date_Format == "y/m/d") {
      date_format = "YYYY/MM/DD";
    }

    let qty = 0;
    let tax = 0;
    let subtotal = 0;
    if (props?.saleslist && props?.saleslist.length != 0) {
      qty = props?.saleslist?.reduce(function (prev, current) {
        return prev + +current.qty;
      }, 0);
      tax = props?.saleslist?.reduce(function (prev, current) {
        return prev + +current.taxTotal;
      }, 0);
      subtotal = props?.saleslist?.reduce(function (prev, current) {
        return prev + +current.subtotal;
      }, 0);
    } else {
      console.log(`cashTotalAmount`, cashTotalAmount);
      qty = cashTotalAmount?.reduce(function (prev, current) {
        return prev + +current.qty;
      }, 0);
      tax = cashTotalAmount?.reduce(function (prev, current) {
        return prev + +current.taxTotal;
      }, 0);
      subtotal = cashTotalAmount?.reduce(function (prev, current) {
        return prev + +current.subtotal;
      }, 0);
    }
    var total_tendered = 0;
    if (props?.payment.length == 0) {
      total_tendered = cashPayments?.reduce(function (prev, current) {
        return prev + +current.tender;
      }, 0);
    } else {
      total_tendered = props?.payment?.reduce(function (prev, current) {
        return prev + +current.tender;
      }, 0);
    }
    console.log(`props?.payment`, props?.payment);
    console.log(`cashPayments`, cashPayments);
    let saledate = parseInt(localStorage.getItem("saleDate"));

    let origimage1 = "";
    const d_image = getDetailsofImage("PrintReciptLogo");
    console.log("d_image", d_image)
    if (d_image !== undefined && d_image.length > 0) {
      if (d_image[0]?.file !== undefined) {
        origimage1 = FormatImageBase64Code(d_image[0].file);
        console.log("d_image1", origimage1)
      }
    }

    let allTax = [];
    let uniqueTaxes = "";

    if (props?.salesitemList != undefined && props?.salesitemList.length > 0) {
      uniqueTaxes = props?.salesitemList.reduce((acc, current) => {
        if (!acc[current.tax]) {
          acc[current.tax] = parseFloat(current.taxTotal);
        } else {
          acc[current.tax] += parseFloat(current.taxTotal);
        }
        return acc;
      }, {});
    } else {
      uniqueTaxes = cashTotalAmount.reduce((acc, current) => {
        if (!acc[current.tax]) {
          acc[current.tax] = parseFloat(current.taxTotal);
        } else {
          acc[current.tax] += parseFloat(current.taxTotal);
        }
        return acc;
      }, {});
    }

    Object.entries(uniqueTaxes).map(([taxName, taxValue]) => {
      const tax_name = props.taxRules?.filter((item) => item.id == taxName);
      if (tax_name && tax_name.length > 0) {
        let array = {
          tax_name: tax_name[0].name,
          total: taxValue,
        };
        allTax.push(array);
      }
    });
    const customer = props?.custlist?.filter(
      (item) => item.mobile == props?.custMobile?.[0]
    );
    console.log(`props?.custlist`, props?.custlist);
    console.log(`props?.custMobile`, props?.custMobile?.[0]);
    console.log(`customer`, customer);
    const transaction_response = props?.payment[props?.payment?.length - 1]?.transaction_response
    const paymentStatus = transaction_response?.status === "capture_requested" || transaction_response?.status === "succeeded" ? "Approved" : ""
    return (
      <>
        <div
          className="print-container"
          ref={ref}
          style={{ textAlign: "center" }}
        >
          {origimage1 != undefined &&
            posSettings?.RECEIPT?.print_receipt_logo == 1 && (
              <Image src={origimage1} alt="logo" />
            )}
          {/* <p className="today-date">
            {todayDate} {timeNow}
          </p> */}
          <div style={{ alignItem: "center" }}>
            <h3 style={{ textAlign: "center" }}>
              {generalSettings?.BUSINESS?.business_name}
            </h3>
            <p style={{ textAlign: "center" }}>
              <strong>{posSettings?.RECEIPT?.header_line_2}</strong>
            </p>
            <p>
              <strong>{posSettings?.RECEIPT?.header_line_3}</strong>
            </p>
          </div>
          <div>
            <div>
              <table style={{ margin: '0 auto' }}>
                <tbody>
                  {posSettings?.RECEIPT?.print_sale_id == 1 && (
                    <tr>
                      <td style={{ textAlign: 'start' }} colSpan={2}>Transaction Ref:</td>
                      <td style={{ textAlign: 'end' }}>{reference}</td>
                    </tr>
                  )}
                  <tr>
                    <td style={{ textAlign: 'start' }} colSpan={2}>Transaction ID:</td>
                    <td style={{ textAlign: 'end' }}>{transId}</td>
                  </tr>
                  <tr className="mb-2">
                    <td style={{ textAlign: 'start' }} colSpan={2}>Sale Time:</td>
                    {/* <td>{dateFormate(saledate)}</td> */}
                    <td style={{ textAlign: 'end' }}>
                      {todayDate} {timeNow}
                    </td>
                  </tr>
                  {categoryList?.ageverification?.length > 0 && (
                    <tr style={{ textAlign: 'start' }} className="mb-2">
                      <td style={{ textAlign: 'end' }} colSpan={2}>Age Verification Checked</td>
                    </tr>
                  )}
                  {props?.saleslist.length == 0
                    ? cashTotalAmount?.map((itm) => {
                      return (
                        <>
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>
                              {itm.qty} x {itm.manualname}
                              {posSettings?.RECEIPT?.print_item_price == 1 &&
                                `(${currencyFormat(itm?.price)})`}
                            </td>
                            <td style={{ textAlign: 'end' }}>{currencyFormat(itm?.subtotal)}</td>
                          </tr>

                          {itm?.promotion && (
                            <tr>
                              <td style={{ textAlign: 'start' }} colSpan={2}>{itm?.showText} </td>
                            </tr>
                          )}
                        </>
                      );
                    })
                    : props?.saleslist?.map((itm) => {
                      return (
                        <>
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>
                              {itm.qty} x {itm.manualname}
                              {posSettings?.RECEIPT?.print_item_price == 1 &&
                                `(${currencyFormat(itm?.price)})`}
                            </td>
                            <td style={{ textAlign: 'end' }}>{currencyFormat(itm?.subtotal)}</td>
                          </tr>

                          {itm?.promotion && (
                            <tr>
                              <td style={{ textAlign: 'start' }} colSpan={2}>{itm?.showText} </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                  {(JSON.parse(localStorage.getItem("totalAmountObject"))?.totalDiscount > 0 || props?.totalAmount?.totalDiscount > 0) && (
                    <tr className="mt-2">
                      <td colSpan={2}>{JSON.parse(localStorage.getItem("totalAmountObject"))?.discountVal || props?.totalAmount?.discountVal}% Discount</td>
                      <td>{currencyFormat(JSON.parse(localStorage.getItem("totalAmountObject"))?.totalDiscount || props?.totalAmount?.totalDiscount)}</td>
                    </tr>
                  )}
                  <tr className="mt-2">
                    <td style={{ textAlign: 'start' }} colSpan={2}>Subtotal:</td>
                    <td style={{ textAlign: 'end' }}>{currencyFormat(subtotal)}</td>
                  </tr>

                  {allTax?.length > 0 &&
                    allTax?.map((itm) => {
                      return (
                        <tr>
                          <td style={{ textAlign: 'start' }} colSpan={2}>{itm.tax_name}</td>
                          <td style={{ textAlign: 'end' }}>{currencyFormat(itm.total)}</td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td style={{ textAlign: 'start' }} colSpan={2}>B Deposit:</td>
                    <td style={{ textAlign: 'end' }}>{currencyFormat(0)}</td>
                  </tr>
                  {roundOffTotalPay(subtotal + tax) - (subtotal + tax) > 0 && (
                    <tr>
                      <td style={{ textAlign: 'start' }} colSpan={2}>Rounding:</td>
                      <td style={{ textAlign: 'end' }}>
                        {currencyFormat(
                          roundOffTotalPay(subtotal + tax) - (subtotal + tax)
                        )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td style={{ textAlign: 'start' }} colSpan={2}>
                      <strong>Total ({qty} items):</strong>
                    </td>
                    <td style={{ textAlign: 'end' }}>
                      <strong style={{ textDecoration: "overline" }}>
                        {currencyFormat(roundOffTotalPay(subtotal + tax))}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'start' }} colSpan={2}>
                      <strong>Total Tendered:</strong>
                    </td>
                    <td style={{ textAlign: 'end' }}>
                      <strong style={{ textDecoration: "overline" }}>
                        {currencyFormat(total_tendered)}
                      </strong>
                    </td>
                  </tr>
                  {/* <tr>
                  <td colSpan={2}>Cash:</td>
                  <td>{currencyFormat(sumCash)}</td>
                </tr>
                <tr>
                  <td colSpan={2}>Tendered:</td>
                  <td>{currencyFormat(print_tendor_amount)}</td>
                </tr>
                <tr>
                  <td colSpan={2}>Change:</td>
                  <td>{currencyFormat(changeAmount)}</td>
                </tr> */}
                  {props?.payment && props.payment.length != 0
                    ? props?.payment?.map((item) => {
                      return (
                        <>
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>{item.method}:</td>
                            <td style={{ textAlign: 'end' }}>{currencyFormat(item.amount)}</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>Tendered:</td>
                            <td style={{ textAlign: 'end' }}>{currencyFormat(item.tender)}</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>Change:</td>
                            <td style={{ textAlign: 'end' }}>{currencyFormat(item.change)}</td>
                          </tr>
                        </>
                      );
                    })
                    : cashPayments?.map((item) => {
                      return (
                        <>
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>{item.method}:</td>
                            <td style={{ textAlign: 'end' }}>{currencyFormat(item.amount)}</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>Tendered:</td>
                            <td style={{ textAlign: 'end' }}>{currencyFormat(item.tender)}</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>Change:</td>
                            <td style={{ textAlign: 'end' }}>{currencyFormat(item.change)}</td>
                          </tr>
                        </>
                      );
                    })}

                  {props?.custMobile?.length > 0 &&
                    posSettings?.RECEIPT?.print_customer_details == 1 &&
                    customer != "" && (
                      <>
                        {posSettings?.RECEIPT?.print_customer_name == 1 && (
                          <tr className="mt-2">
                            <td style={{ textAlign: 'start' }} colSpan={2}>Customer Name:</td>
                            <td style={{ textAlign: 'end' }}>{customer?.[0]?.name}</td>
                          </tr>
                        )}
                        {posSettings?.RECEIPT?.print_customer_mobile == 1 && (
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>Customer Mobile:</td>
                            <td style={{ textAlign: 'end' }}>{customer?.[0]?.mobile}</td>
                          </tr>
                        )}
                        {posSettings?.RECEIPT?.print_customer_address == 1 && (
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>Customer Address:</td>
                            <td style={{ textAlign: 'end' }}>{customer?.[0]?.address}</td>
                          </tr>
                        )}
                        {posSettings?.RECEIPT?.print_customer_notes == 1 && (
                          <tr>
                            <td style={{ textAlign: 'start' }} colSpan={2}>Notes:</td>
                            <td style={{ textAlign: 'end' }}>{customer?.[0]?.notes}</td>
                          </tr>
                        )}
                      </>
                    )}
                  {transaction_response &&
                    <>
                      <tr>
                        <td colSpan={3}>----------------------------------------------------------</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'start' }} colSpan={2}>Status:</td>
                        <td style={{ textAlign: 'end' }}>${paymentStatus || ""}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'start' }} colSpan={2}>Sale:</td>
                        <td style={{ textAlign: 'end' }}>${currencyFormat(total_tendered || "")}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'start' }} colSpan={2}>Account:</td>
                        <td style={{ textAlign: 'end' }}>********${transaction_response?.payment?.last_4 || ""}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'start' }} colSpan={2}>App ID:</td>
                        <td style={{ textAlign: 'end' }}>${transaction_response?.payment?.receipt_data?.emv_app_id || ""}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'start' }} colSpan={2}>App Label:</td>
                        <td style={{ textAlign: 'end' }}>${transaction_response?.payment?.receipt_data?.emv_app_label || ""}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'start' }} colSpan={2}>Auth ID:</td>
                        <td style={{ textAlign: 'end' }}>${transaction_response?.payment?.receipt_data?.emv_auth_code || ""}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'start' }} colSpan={2}>Terminal ID:</td>
                        <td style={{ textAlign: 'end' }}>${transaction_response?.payment?.receipt_data?.emv_term_id || ""}</td>
                      </tr>
                    </>
                  }
                </tbody>
              </table>
              {posSettings?.RECEIPT?.print_signature_line == "1" && (
                <div style={{ alignItem: "center" }}>
                  <p>X________________________</p>
                </div>
              )}
              {saleStatus != "" && (
                <h4>{saleStatus == "Canceled" && "Sale Canceled"}</h4>
              )}

              <strong className="footer-details">
                {posSettings?.RECEIPT?.receipt_footer_text}
              </strong>
              {posSettings?.RECEIPT?.promo_qr_code != undefined && (
                <div className="mt-2">
                  <QRCode
                    style={{ zIndex: 1 }}
                    size={100}
                    value={posSettings?.RECEIPT?.promo_qr_code}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  });

  const handleVerifiedPayment = () => {
    PrintFn();
    // handlePrint();
    // window.print();
    closedCheckOutPopUp(false, "paymentVerified");
  };

  const handlePayment = (type, customTip = 0) => {
    handleLoader(true, "Follow Prompts on Terminal");
    console.log(`type`, type);
    let commandType = "card";
    if (type == "EBT Cash") {
      commandType = "ebt cash";
    } else if (type == "Gift Card") {
      commandType = "gift";
    } else if (type == "Debit Card") {
      commandType = "debit";
    } else if (type == "Card") {
      commandType = "card";
    } else if (type == "EBT Sale") {
      commandType = "ebt";
    }

    // new code starts

    cardPaymentTransaction(commandType, customTip);
  };

  // payment transaction through card (ventiv processor)
  const cardPaymentTransaction = (type, customTip = 0) => {
    var salesobj = getCardSaleObject();
    var parms = {};
    if (salesobj.items.length == 0)
      return alert("Please add atleast one item before sale");
    // ventiv processor call thorugh javascript
    console.log(`props`, props);
    var totalAmountPayable = parseFloat(props?.totalAmounts?.Total).toFixed(2);
    console.log(`sumCashBalance`, sumCashBalance);
    console.log(`props payment`, props.payment);
    console.log(`actual amount`, actualAmount);
    var currentTenderval = actualAmount;

    if (type == "ebt") {
      if (
        currentTenderval == "" ||
        currentTenderval == null ||
        parseFloat(currentTenderval) == 0
      ) {
        currentTenderval = props?.totalAmounts.EBTTotal;
      }
    }

    console.log(`currentTenderval`, currentTenderval);

    if (
      currentTenderval == "" ||
      currentTenderval == null ||
      parseFloat(currentTenderval) == 0
    ) {
      // currentTenderval = totalAmountPayable;
      currentTenderval = sumCashBalance;
    }

    if (customTip != 0) {
      currentTenderval = parseFloat(customTip) + currentTenderval;
    }

    parms.headers = getPaymentHeader(); // getpayment headers;
    parms.ticketNumber = generate_ref_number(); //genrate unique sale number;
    parms.transactionAmount = currentTenderval;
    const generalSetting = props.generalSetting?.CREDIT;

    if (
      generalSetting.prompt_tips != undefined &&
      generalSetting.prompt_tips == 1
    ) {
      parms["tipsenable"] = true;
    } else {
      parms["tipsenable"] = false;
    }

    var settings = getPaymentObject("Pax", parms, "sale", null, type);
    // $('#dialog-processing').dialog({
    //   modal: true, //Not necessary but dims the page background
    //   dialogClass: 'forsalespage dialog-processing-inner',
    //   open: function (event, ui) {
    //     $(".dialog-processing-inner").find(".ui-dialog-titlebar-close").remove();
    //   }
    // });

    console.log(`settings`, settings);

    $.ajax(settings)
      .done(function (res) {
        setRefund(false);
        console.log(`res`, res);
        let response = handlePaymentResponse("Pax", res);
        handleLoader(false, "");
        console.log("response->", response);
        const fraud_prevention = props.generalSetting?.CREDIT?.fraud_prevention;
        const fraudAmount = props.generalSetting?.CREDIT?.show_fraud_amount_above;
        setCardResponse(response);
        if (
          response?.approvedAmount >= sumCashBalance &&
          fraud_prevention == 1 &&
          sumCashBalance >= fraudAmount
        ) {
          setFraudPrevent(true);
        }

        if (response.isApproved == true && response.approvedAmount > 0) {
          console.log("approved");

          setResponseMsg(response?.HostInformation[1]);
          setReference(response?.TraceInformation[1]);

          // const paymentState = getPaymentArrayCard("Card", response.approvedAmount, 0, response?.transactionId);
          // updateArray(paymentState);

          const ebtBal = {
            Balance1: response?.Balance1,
            Balance2: response?.Balance2,
          };

          let card_response = {
            HostReferenceNumber: response?.HostInformation.HostReferenceNumber,
            cardHolderName: response?.cardHolderName,
            accountNumber: response?.accountNumber,
            statusCode: response?.statusCode,
            approvedAmount: response?.approvedAmount,
          };

          // let card_response_json = "testing";
          let card_response_json = JSON.stringify(card_response);

          if (
            props.totalAmounts.EBTTotal != "" &&
            props.totalAmounts.EBTTotal > 0 &&
            type == "ebt"
          ) {
            if (
              !updatePaymentArrayCard(
                type,
                parseFloat(response.approvedAmount),
                response?.transactionId,
                ebtBal,
                customTip != 0
                  ? parseFloat(customTip)
                  : parseFloat(response?.AmountInformation?.TipAmount) / 100,
                card_response
              )
            ) {
              console.log("inside is there");
              UpdateButtonValues(
                sumCashBalance,
                parseFloat(response.approvedAmount)
              );
            }
          } else if (!actualAmount) {
            console.log(`sumCashBalance`, sumCashBalance);
            if (
              !updatePaymentArrayCard(
                type,
                sumCashBalance,
                response?.transactionId,
                ebtBal,
                customTip != 0
                  ? parseFloat(customTip)
                  : parseFloat(response?.AmountInformation?.TipAmount) / 100,
                card_response
              )
            ) {
              UpdateButtonValues(sumCashBalance, sumCashBalance);
            }
          } else {
            console.log(`actualAmount`, actualAmount);
            if (
              !updatePaymentArrayCard(
                type,
                actualAmount,
                response?.transactionId,
                ebtBal,
                customTip != 0
                  ? parseFloat(customTip)
                  : parseFloat(response?.AmountInformation?.TipAmount) / 100,
                card_response
              )
            ) {
              UpdateButtonValues(sumCashBalance, actualAmount);
            }
          }

          let payments = [];
          // payments.push(paymentState);
          console.log(`props->`, props?.payment);
          console.log(`totalAmountPayable`, totalAmountPayable);

          // if (props?.payment) {

          console.log(`props payment->`, payments);
          let actualAmount_ = 0;
          if (props.totalAmounts.EBTTotal > 0) {
            actualAmount_ = parseFloat(props.totalAmounts.EBTTotal);
          } else {
            if (!actualAmount) {
              actualAmount_ = parseFloat(sumCashBalance);
            } else {
              actualAmount_ = parseFloat(actualAmount);
            }
          }

          const totalAmountPayable_ = parseFloat(totalAmountPayable);

          console.log(`actualAmount_`, actualAmount_);
          console.log(`totalAmountPayable_`, totalAmountPayable_);
          console.log(`sumCashBalance_`, sumCashBalance);

          if (
            actualAmount_ < totalAmountPayable_ &&
            parseFloat(sumCashBalance) != 0
          ) {
            // console.log(`partial payment done`, actualAmount_);
            // console.log(`partial payment done`, totalAmountPayable_);
            // setPartial(true);
            // setActualAmount("");
          } else {
            // ConfirmCheckOutSale(props?.payment);
            // if (wpos_config?.asktoPrint == "ask") {
            //   setCheckOut(true);
            // } else if (wpos_config?.asktoPrint == "print") {
            //   PrintFn();
            // }
          }

          // }

          console.log(`pprops`, props.payment);

          const posSettings = JSON.parse(localStorage.getItem("posSetting"));

          console.log(`posSettings.RECEIPT`, posSettings.RECEIPT);

          if (
            wpos_config?.hasOwnProperty("cash_draw_connected") &&
            wpos_config.cash_draw_connected === true &&
            posSettings.RECEIPT.open_drawer_card_and_side_card == 1
          ) {
            Cashdraw(false);
          }
        } else if (response.isApproved == false) {
          console.log(`time out`, response);

          setPaxError("timeout");
          dispatch(paxErrorState("timeout", PAXERROR));
          localStorage.setItem("paxerror", "timeout");
          setResponseMsg(`Error: ${response.ResponseMessage}`);
          setShowIcon(Cancel);
          if (wpos_config?.asktoPrint == "ask") {
            setCheckOut(true);
          } else if (wpos_config?.asktoPrint == "print") {
            PrintFn();
          } else {
            setCheckOut(true);
          }
        }
      })
      .fail(function (jqXHR, status, error) {
        console.log(`jqXHR`, jqXHR);
        var errormessage;
        if (jqXHR.status === 0) {
          errormessage = "Could not connect to pinpad";
          setShowIcon(Cancel);
        } else if (jqXHR.status == 404) {
          errormessage = "Requested page not found. [404]";
          setShowIcon(attention);
        } else if (jqXHR.status == 500) {
          errormessage = "Internal Server Error [500].";
          setShowIcon(attention);
        } else {
          errormessage = "Uncaught Error.\n" + jqXHR.responseText;
        }
        handleLoader(false, "");

        setOpenErrorMsg(true);
        setTitle("Pax Error");
        setErrorMsg(errormessage);
        setCardType("");

        const troubleShoot = props.generalSetting?.CREDIT?.tbs_btn;
        if (troubleShoot == 1) {
          setTbsBtn(true);
        } else {
          setTbsBtn(false);
        }

        console.log(`errormessage`, errormessage);
      });
  };

  const handlePaymentResponse = (type, response) => {
    if (type == "Tripos" || type == "TriposDirect") {
      return response;
    } else if (type == "Pax") {
      //     var config = WPOS.getConfigTable();
      //     var paxSettings= config.deviceconfig;
      //     // var paxSettings= config.general.paxsettings;
      //   paxc=new PAXCard(paxSettings.pax_protocol?paxSettings.pax_protocol:'https',paxSettings.pax_ip?paxSettings.pax_ip:'127.0.0.1',paxSettings.pax_port?paxSettings.pax_port:'10009');

      return parseResponse(response);
    }
  };

  function parseResponse(response) {
    var commandType = "DoCredit";
    var checkParams = StringToHex(response).split(" ").pop();
    var RedundancyCheck = StringToHex(response).split(" ").pop().substring(1);

    var check = getLRC(checkParams);

    if (check == RedundancyCheck) {
      //get package detail info
      var packetInfo = [];
      var len = StringToHex(response).indexOf("03");
      var hex = StringToHex(response).slice(0, len).split(/02|1c/);

      console.log(hex);
      if (commandType == "DoCredit") {
        var subHex = [],
          subPacketInfo = [];
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            if (hex[i].indexOf("1f") > 0) {
              subHex = hex[i].split("1f");
              console.log(subHex);
              subPacketInfo = [];
              for (var j = 0; j < subHex.length; j++) {
                if (subHex[j] != "") {
                  subPacketInfo.push(HexToString(subHex[j]));
                }
              }
              console.log(subPacketInfo);
              packetInfo.push(subPacketInfo);
            } else {
              packetInfo[i] = HexToString(hex[i]);
            }
          }
        }
      } else {
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            packetInfo[i] = HexToString(hex[i]);
          }
        }
      }
      response = packetInfo;
    }
    if (typeof response == "string") {
      // WPOS.util.okDialog("Error", response, function (dat) { });
      console.log(`responsee`, response);
      return true;
    }

    console.log(`responsee`, response);

    var i = 0,
      j = -1;
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
    };
    PacketageInfo.DoCredit.Status = response[++i];
    PacketageInfo.DoCredit.Command = response[++i];
    PacketageInfo.DoCredit.Version = response[++i];
    PacketageInfo.DoCredit.ResponseCode = response[++i];
    PacketageInfo.DoCredit.ResponseMessage = response[++i];
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.Command) {
          commandName = key;
        }
      }
    }
    if (PacketageInfo.DoCredit.ResponseCode == "000000") {
      PacketageInfo.DoCredit.isApproved = true;
    } else {
      PacketageInfo.DoCredit.isApproved = false;
    }

    console.log(`packetInfo->`, PacketageInfo);

    PacketageInfo.DoCredit.HostInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.HostInformation == "") {
      PacketageInfo.DoCredit.HostInformation = {};
      PacketageInfo.DoCredit.HostInformation.HostResponseCode = "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage = "";
      PacketageInfo.DoCredit.HostInformation.AuthCode = "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber = "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber = "";
    } else if (typeof PacketageInfo.DoCredit.HostInformation == "string") {
      console.log(`type->`, PacketageInfo.DoCredit.HostInformation);
    } else {
      PacketageInfo.DoCredit.HostInformation.HostResponseCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.AuthCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit._processor = {
      processorLogs: [
        "ExpressResponseCode: [0]\r\nExpressResponseMessage: [" +
          PacketageInfo.DoCredit.isApproved
          ? commandName + "Approved"
          : "" +
          "]\r\nHostResponseCode: [" +
          PacketageInfo.DoCredit.HostInformation.HostResponseCode +
          "]\r\nHostResponseMessage: [ " +
          PacketageInfo.DoCredit.HostInformation.HostResponseMessage +
          "]",
      ],
      processorRawResponse: "",
      processorReferenceNumber:
        PacketageInfo.DoCredit.HostInformation.HostReferenceNumber,
      processorRequestFailed: PacketageInfo.DoCredit.isApproved,
      processorRequestWasApproved: PacketageInfo.DoCredit.isApproved,
      processorResponseCode: PacketageInfo.DoCredit.isApproved
        ? commandName + "Approved"
        : "",
      processorResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      expressResponseCode:
        PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      expressResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      hostResponseCode: PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      hostResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      logs: ["ExpressResponseCode: ''"],
      rawResponse: "",
    };

    PacketageInfo.DoCredit.TransactionType =
      response[++i] != undefined ? response[i] : "";

    PacketageInfo.DoCredit.commandName = "";
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.TransactionType) {
          commandName = key.charAt(0).toUpperCase() + key.slice(1) + " ";
          PacketageInfo.DoCredit.commandName = commandName;
        }
      }
    }

    PacketageInfo.DoCredit.AmountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AmountInformation == "") {
      PacketageInfo.DoCredit.AmountInformation = {};
      PacketageInfo.DoCredit.approvedAmount = "";
      PacketageInfo.DoCredit.subTotalAmount = "0";
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount = "";
      PacketageInfo.DoCredit.AmountInformation.AmountDue = "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount = "";
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount = "";
      PacketageInfo.DoCredit.cashbackAmount =
        PacketageInfo.DoCredit.AmountInformation.CashBackAmount;
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = "";
      PacketageInfo.DoCredit.debitSurchargeAmount = "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount = "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 = "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.approvedAmount = parseFloat(
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
      );
      PacketageInfo.DoCredit.subTotalAmount = parseFloat(
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
      );

      PacketageInfo.DoCredit.AmountInformation.AmountDue =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.tipAmount =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.TipAmount) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.TipAmount / 100)
          : PacketageInfo.DoCredit.AmountInformation.TipAmount;
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    // add params balance 1 and balance 2 for ebt
    if (["T05", "T07", "T09"].includes(PacketageInfo.DoCredit.Command)) {
      PacketageInfo.DoCredit.Balance1 =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance1) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance1 / 100)
          : PacketageInfo.DoCredit.AmountInformation.Balance1;
      PacketageInfo.DoCredit.Balance2 =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance2) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance2 / 100)
          : PacketageInfo.DoCredit.AmountInformation.Balance2;
    } else {
      PacketageInfo.DoCredit.Balance1 = 0;
      PacketageInfo.DoCredit.Balance2 = 0;
    }

    PacketageInfo.DoCredit.fsaCard = "NotApplicable";
    PacketageInfo.DoCredit.convenienceFeeAmount = "";
    PacketageInfo.DoCredit.AccountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AccountInformation == "") {
      PacketageInfo.DoCredit.AccountInformation = {};
      PacketageInfo.DoCredit.AccountInformation.Account = "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode = "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate = "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype = "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber = "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo = "";
      PacketageInfo.DoCredit.AccountInformation.CardType = "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder = "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage = "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AccountInformation.Account =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.accountNumber =
      PacketageInfo.DoCredit.AccountInformation.Account;
    PacketageInfo.DoCredit.isCardInserted =
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator;
    PacketageInfo.DoCredit.cardHolderName =
      PacketageInfo.DoCredit.AccountInformation.CardHolder;

    PacketageInfo.DoCredit.TraceInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.TraceInformation == "") {
      PacketageInfo.DoCredit.TraceInformation = {};
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber = "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AVSinformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AVSinformation == "") {
      PacketageInfo.DoCredit.AVSinformation = {};
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.CommercialInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.CommercialInformation == "") {
      PacketageInfo.DoCredit.CommercialInformation = {};
      PacketageInfo.DoCredit.CommercialInformation.PONumber = "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.CommercialInformation.PONumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription =
        response[i][++j] != undefined ? response[i][j] : "";
    }
    console.log(PacketageInfo.DoCredit.CommercialInformation);

    PacketageInfo.DoCredit.motoEcommerce =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.motoEcommerce == "") {
      PacketageInfo.DoCredit.motoEcommerce = {};
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType = "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType = "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber = "";
      PacketageInfo.DoCredit.motoEcommerce.Installments = "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.Installments =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AdditionalInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AdditionalInformation == "")
      PacketageInfo.DoCredit.AdditionalInformation = {};
    var additionalInfoArr = PacketageInfo.DoCredit.AdditionalInformation,
      keyValue = [];
    for (i = 0; i < additionalInfoArr.length; i++) {
      keyValue = additionalInfoArr[i].split("=");
      PacketageInfo.DoCredit.AdditionalInformation[keyValue[0]] = keyValue[1];
      keyValue = [];
    }
    if (
      PacketageInfo.DoCredit.AdditionalInformation.hasOwnProperty("CARDBIN")
    ) {
      PacketageInfo.DoCredit.binValue =
        PacketageInfo.DoCredit.AdditionalInformation.CARDBIN;
    }
    PacketageInfo.DoCredit.cardLogo = "";
    PacketageInfo.DoCredit.currencyCode = "";
    PacketageInfo.DoCredit.entryMode = "";
    PacketageInfo.DoCredit.expirationYear = "";
    PacketageInfo.DoCredit.expirationMonth = "";
    PacketageInfo.DoCredit.paymentType = "";
    PacketageInfo.DoCredit.pinVerified = "";
    PacketageInfo.DoCredit.signature = {
      statusCode: "SignatureNotRequiredByThresholdAmount",
    };
    PacketageInfo.DoCredit.statusCode = PacketageInfo.DoCredit.isApproved
      ? commandName + "Approved"
      : "";
    PacketageInfo.DoCredit.transactionDateTime =
      PacketageInfo.DoCredit.TraceInformation.TimeStamp;
    PacketageInfo.DoCredit.transactionId =
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber;
    PacketageInfo.DoCredit.merchantId = "";

    console.log(`PacketageInfo`, PacketageInfo.DoCredit);
    return PacketageInfo.DoCredit;
  }

  const getCardBalance = (amount) => {
    if (amount === "") {
      return amount;
    } else {
      return +amount + CalculateAdditionalCardPrice(+props.totalAmounts.Total)
    }
  }

  const getPaymentHeader = () => {
    var header = {};
    return header;
  };

  const generate_ref_number = () => {
    var date = new Date().getTime();
    const setupData = JSON.parse(localStorage.getItem("setupData"));
    let reference_number =
      date +
      "-" +
      setupData.device +
      "-" +
      Math.floor(Math.random() * 10000 + 1);
    return reference_number;
  };

  const getPaymentObject = (
    type,
    parms,
    processtype = "sale",
    url = null,
    cardsaletype
  ) => {
    if (type == "Pax") {
      // parms['tipsenable'] = true;
      var settings = getPaymentSettings(parms, processtype, cardsaletype);
    }
    return settings;
  };

  const getPaymentSettings = (params, processtype = "sale", cardsaletype) => {
    var version, transactionType; //,accountInformation={},traceInformation,avsInformation,cashierInformation,commercialInformation,motoEcommerce,additionalInformation;
    // if(!ConfigureFlag){
    //  DisplayHandler.RemoveLoading();
    //  var $dialog = $("<div id='dialog' title='Command Dialog'>");
    //  HintDialog.CreateDialog("Configure Data isn't set yet.",$dialog);
    //  return false;
    // }

    var configuration = {
      version: "1.28",
      protocol: "https",
      ip: "127.0.0.1",
      port: "10009",
      promptForSignature: 0,
    };
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
      add: "10",
      activate: "08",
      deactivate: "12",
      balance: "23",
    };

    version = configuration.version;
    transactionType = processTypes[processtype];
    var timestamp = Date.now();
    //set transaction Data

    let amountInformation = {};
    amountInformation.TransactionAmount =
      processtype == "void"
        ? ""
        : Math.round(parseFloat(params.transactionAmount) * 100);
    amountInformation.TipAmount = "";
    amountInformation.CashBackAmount = "";
    amountInformation.MerchantFee = "";
    amountInformation.TaxAmount = "";
    amountInformation.FuelAmount = "";
    console.log(amountInformation);

    let accountInformation = {};
    accountInformation.Account = "";
    accountInformation.EXPD = "";
    accountInformation.CVVCode = "";
    accountInformation.EBTtype = cardsaletype == "ebt cash" ? "C" : "";
    accountInformation.VoucherNumber = "";
    accountInformation.Force = "";
    accountInformation.FirstName = "";
    accountInformation.LastName = "";
    accountInformation.CountryCode = "";
    accountInformation.State_ProvinceCode = "";
    accountInformation.CityName = "";
    accountInformation.EmailAddress = "";

    let traceInformation = {};
    traceInformation.ReferenceNumber = params.ticketNumber.substring(0, 15);
    traceInformation.InvoiceNumber = "";
    traceInformation.AuthCode = "";
    traceInformation.TransactionNumber = params.hasOwnProperty("transactionId")
      ? params.transactionId
      : "";
    traceInformation.TimeStamp = "";
    traceInformation.ECRTransID = "";

    let avsInformation = {};
    avsInformation.ZipCode = "";
    avsInformation.Address = "";
    avsInformation.Address2 = "";

    let cashierInformation = {};
    cashierInformation.ClerkID = "";
    cashierInformation.ShiftID = "";

    let commercialInformation = {};
    commercialInformation.PONumber = "";
    commercialInformation.CustomerCode = "";
    commercialInformation.TaxExempt = "";
    commercialInformation.TaxExemptID = "";
    commercialInformation.MerchantTaxID = "";
    commercialInformation.DestinationZipCode = "";
    commercialInformation.ProductDescription = "";

    let motoEcommerce = {};
    motoEcommerce.MOTO_E_CommerceMode = "";
    motoEcommerce.TransactionType = "";
    motoEcommerce.SecureType = "";
    motoEcommerce.OrderNumber = "";
    motoEcommerce.Installments = "";
    motoEcommerce.CurrentInstallment = "";

    let additionalInformation = {};
    additionalInformation.TABLE = "";
    additionalInformation.GUEST = "";
    additionalInformation.SIGN =
      processtype == "sale" ? configuration.promptForSignature : "";
    additionalInformation.TICKET = "";
    additionalInformation.HREF = "";
    additionalInformation.TIPREQ =
      params.hasOwnProperty("tipsenable") && params.tipsenable === true
        ? 1
        : "";
    additionalInformation.SIGNUPLOAD = "";
    additionalInformation.REPORTSTATUS = "";
    additionalInformation.TOKENREQUEST = "";
    additionalInformation.TOKEN = "";
    additionalInformation.CARDTYPE = "";
    additionalInformation.CARDTYPEBITMAP = "";

    additionalInformation.PASSTHRUDATA = "";
    additionalInformation.RETURNREASON = "";
    additionalInformation.ORIGTRANSDATE = "";
    additionalInformation.ORIGPAN = "";
    additionalInformation.ORIGEXPIRYDATE = "";
    additionalInformation.ORIGTRANSTIME = "";
    additionalInformation.DISPROGPROMPTS = "";
    additionalInformation.GATEWAYID = "";
    additionalInformation.GETSIGN =
      processtype == "sale" ? configuration.promptForSignature : "";

    additionalInformation.ENTRYMODEBITMAP = "";
    additionalInformation.RECEIPTPRINT = "1";
    additionalInformation.CPMODE = "";
    additionalInformation.ODOMETER = "";
    additionalInformation.VEHICLENO = "";
    additionalInformation.JOBNO = "";
    additionalInformation.DRIVERID = "";
    additionalInformation.EMPLOYEENO = "";
    additionalInformation.LICENSENO = "";
    additionalInformation.JOBID = "";
    additionalInformation.DEPARTMENTNO = "";
    additionalInformation.CUSTOMERDATA = "";
    additionalInformation.USERID = "";
    additionalInformation.VEHICLEID = "";
    console.log(amountInformation);

    var command = "T00";
    if (cardsaletype == "gift") {
      command = "T06";
    } else if (cardsaletype == "debit") {
      command = "T02";
    } else if (cardsaletype == "ebt" || cardsaletype == "ebt cash") {
      command = "T04";
    } else if (cardsaletype == "batchclose") {
      command = "B00";
    }

    console.log(`command`, command);

    return DoCredit({
      command: command,
      version: version,
      transactionType: transactionType,
      amountInformation: amountInformation,
      accountInformation: accountInformation,
      traceInformation: traceInformation,
      avsInformation: avsInformation,
      cashierInformation: cashierInformation,
      commercialInformation: commercialInformation,
      motoEcommerce: motoEcommerce,
      additionalInformation: additionalInformation,
    });
  };

  //DO Credit
  const DoCredit = (doCreditInfo) => {
    var amountInformation,
      accountInformation,
      traceInformation,
      avsInformation,
      cashierInformation,
      commercialInformation,
      motoEcommerce,
      additionalInformation;
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      mDestinationIP: `${paxConfig.pax_protocol == ""
        ? "http"
        : paxConfig.pax_protocol == "1"
          ? "http"
          : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };

    var params = [
      PAX.mStx.hex,
      doCreditInfo.command,
      PAX.mFS.hex,
      doCreditInfo.version,
    ];
    params.push(PAX.mFS.hex);
    if (doCreditInfo.transactionType != "") {
      params.push(doCreditInfo.transactionType);
    }
    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "amountInformation",
      doCreditInfo.amountInformation
    );

    console.log(
      `doCreditInfo.amountInformation`,
      doCreditInfo.amountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "accountInformation",
      doCreditInfo.accountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "traceInformation",
      doCreditInfo.traceInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "avsInformation", doCreditInfo.avsInformation);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "motoEcommerce", doCreditInfo.motoEcommerce);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    params.push(PAX.mEtx.hex);

    var lrc = getLRC(params);

    console.log(params);

    //prepare for base64 encoding.
    var command_hex = base64ToHex(Base64.btoa(doCreditInfo.command));
    var version_hex = base64ToHex(Base64.btoa(doCreditInfo.version));
    var transactionType_hex = base64ToHex(
      Base64.btoa(doCreditInfo.transactionType)
    );
    var amountInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.amountInformation)
    );
    var accountInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.accountInformation)
    );
    var traceInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.traceInformation)
    );
    var avsInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.avsInformation)
    );
    var cashierInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.cashierInformation)
    );
    var commercialInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.commercialInformation)
    );
    var motoEcommerce_hex = base64ToHex(
      Base64.btoa(doCreditInfo.motoEcommerce)
    );
    var additionalInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.additionalInformation)
    );

    //var elements = [this.mStx.code, command_hex, PAX.mFS.code, version_hex, PAX.mFS.code, uploadFlag_hex, PAX.mFS.code, timeout, this.mEtx.code, base64ToHex(Base64.btoa(lrc))];
    var elements = [PAX.mStx.code];
    elements.push(command_hex);
    elements.push(PAX.mFS.code);
    elements.push(version_hex);
    elements.push(PAX.mFS.code);

    if (transactionType_hex != "") {
      elements.push(transactionType_hex);
    }
    elements.push(PAX.mFS.code);

    elements = AddBase64(
      elements,
      "amountInformation",
      doCreditInfo.amountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "accountInformation",
      doCreditInfo.accountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "traceInformation",
      doCreditInfo.traceInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "avsInformation",
      doCreditInfo.avsInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(elements, "motoEcommerce", doCreditInfo.motoEcommerce);
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    elements.push(PAX.mEtx.code);
    elements.push(base64ToHex(Base64.btoa(lrc)));
    console.log("elements");
    console.log(elements);

    var final_string = elements.join(" ");
    var final_b64 = hexToBase64(final_string);
    console.log("LRC: " + lrc);
    console.log("Base64: " + final_b64);

    // if(customData != ''){
    // 	final_b64 = hexToBase64(final_string+"&custom_data=<PAX>"+customData+"</PAX>");
    // }

    var url = PAX.mDestinationIP + "?" + final_b64;
    console.log("URL: " + url);
    return { url: url, timeout: PAX.timeout.DoCredit };
    // this.HttpCommunication('DoCredit',url,function(response){
    // 	callback(response);
    // },PAX.timeout.DoCredit);
  };

  const AddBase64 = (elements, type, objectInfo) => {
    //console.log(objectInfo);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    var empty = 0;
    var arr = [];
    arr = arr.concat(elements);
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.code);
        continue;
      }
      if (type == "additionalInformation") {
        if (objectInfo[name] == "") continue;
        empty++;
        arr.push(
          base64ToHex(Base64.btoa(name + "=" + objectInfo[name].toString()))
        );
      } else {
        empty++;
        arr.push(base64ToHex(Base64.btoa(objectInfo[name].toString())));
      }
      arr.push(PAX.mUS.code);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = elements;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.code);
    }
    //console.log(arr);
    return arr;
  };

  const PushParams = (params, type, objectInfo) => {
    var empty = 0;
    var arr = [];
    arr = arr.concat(params);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.hex);
        continue;
      }

      if (type == "additionalInformation") {
        if (objectInfo[name] == "") {
          continue;
        }
        empty++;
        arr.push(name + "=" + objectInfo[name].toString());
      } else {
        empty++;
        arr.push(objectInfo[name].toString());
      }
      arr.push(PAX.mUS.hex);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = params;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.hex);
    }
    //console.log(params);
    return arr;
  };

  //*************** getCardSaleObject ****************//
  function getCardSaleObject() {
    // create object
    var salesobj = {};
    //salesobj.ref = date + "-" + config.deviceid + "-" + Math.floor((Math.random() * 10000) + 1);
    //var ref_number = generate_ref_number();

    salesobj.ref = generate_ref_number();
    salesobj.userid = 10;
    salesobj.devid = 9;
    salesobj.locid = 9;
    salesobj.custid = 1;
    salesobj.cost = 5.0;
    salesobj.subtotal = 5.0;
    salesobj.total = 5.0;
    salesobj.numitems = 1;
    salesobj.items = 1;
    salesobj.discountamt = 0.0;
    salesobj.discountvalue = 0;
    salesobj.discount = 0;
    salesobj.discount_amountvalue = 0;
    // add tax information
    salesobj.tax = 0.0;
    // for (var i in taxtotals) {
    //   taxtotals[i] = taxtotals[i].toFixed(2);
    // }
    salesobj.taxdata = 0.0;

    return salesobj;
  }

  const [payFacInitiateData, setPayFacInitiateData] = useState({
    init: false
  });
  const [showCancelPayFac, setShowCancelPayFac] = useState(false)
  const [printRcpt, setPrintRecpt] = useState(false)
  const [lastPaymentId, setLastPaymentId] = useState([])
  const [lastPaymentsDetails, setLastPaymentsDetails] = useState([])
  const [lastPaymentToken, setLastPaymentToken] = useState(null)
  const [payFacPaymentStatus, setPayFacPaymentStatus] = useState({
    check: false
  })
  const [completePayFacPayment, setCompletePayFacPayment] = useState(false)
  const [payFacAbort, setPayFacAbort] = useState(false);

  const getPayfacOrderDetails = (amount, payment_method_types, capture_method, external_payment_id) => {
    let no_cash_per = getSettingData(ACCOUNTSETTINGDATA)?.NONCASEADJUSTMENT?.percentage
    let no_cash_en = getSettingData(ACCOUNTSETTINGDATA)?.NONCASEADJUSTMENT?.enable === "1";
    return {
      amount,
      non_cash_adjustment: no_cash_en ? (no_cash_per / 100) * amount : undefined,
      external_account_id: getSettingData(GENERALSETIINGDATA)?.CREDIT?.payfac_account_id,
      terminal_id: getDeviceTerminalId(),
      external_payment_id,
      capture_method,
      payment_method_types,
      known_payment_ids: lastPaymentId?.join(",")
    }
  }

  const getPayfacAuthDetails = (data) => {
    setLastPaymentId([...lastPaymentId, data.payment_id])
    return {
      payment_id: data.payment_id,
      terminal_id: getDeviceTerminalId(),
      payment_method_types: data.payment_method_types,
      amount: data.amount,
      external_payment_id: data.external_payment_id,
      external_account_id: getSettingData(GENERALSETIINGDATA)?.CREDIT?.payfac_account_id
    }
  }

  const getStatusDetails = (data) => {
    setLastPaymentToken(data?.token)
    return {
      // token: data.token,
      external_account_id: getSettingData(GENERALSETIINGDATA)?.CREDIT?.payfac_account_id,
      payment_id: data?.payment_id || lastPaymentId[lastPaymentId.length - 1]
    }
  }

  const getRefundDetails = (amount, payment_id) => {
    return {
      external_account_id: getSettingData(GENERALSETIINGDATA)?.CREDIT?.payfac_account_id,
      payment_id: payment_id || lastPaymentId[lastPaymentId.length - 1],
      amount
    }
  }

  const [intervalIdPayfac, setIntervalIdPayfac] = useState(null);
  const [timeoutIdPayfac, setTimeoutIdPayfac] = useState(null);
  const [netWorkErrorPayment, setNetworkErrorPayment] = useState(false);

  const addDeclineCards = (message, request, response) => {
    dispatch(addActionFunction(DECLINECARDADD, {
      error_message: message,
      transaction_response: JSON.stringify(response?.data),
      transaction_request: response?.config.data,
      user_id: authUserId(),
      deviceid: getDeviceAndStore()[0],
      store: getDeviceAndStore()[1],
      amount: actualAmount,
      processdt: Date.now(),
    }))
  }

  function checkStatus(data) {
    let lastStatus = "";
    let paymentStatus;
    let id;

    const checkAndUpdateStatus = async () => {
      if (lastStatus === "capture_requested" || lastStatus === "succeeded") {
        clearInterval(id);
        clearTimeout(timeoutIdPayfac);
        setPayFacPaymentStatus({ check: false });
        setCompletePayFacPayment(paymentStatus);
        // Clear the interval if the condition is met
      } else if (lastStatus === "error") {
        setPayFacPaymentStatus({ check: false });
        clearInterval(id); // Clear the interval if the condition is met
        clearTimeout(timeoutIdPayfac);
        handlePaymentError("Error", "Payment Declined.")
      } else if (lastStatus === "requires_payment_method") {
        setPayFacPaymentStatus({ check: false });
        clearInterval(id); // Clear the interval if the condition is met
        clearTimeout(timeoutIdPayfac);
        handlePaymentError("Error", "Payment canceled / Declined by terminal.")
      } else {
        try {
          const status = await payFac(VERIFYSTATUS, data);
          if (status?.status == 200) {
            lastStatus = status?.data?.status;
            paymentStatus = status?.data
          } else {
            setPayFacPaymentStatus({ check: false });
            addDeclineCards(status?.data?.error);
            handlePaymentError();
            clearInterval(id); // Clear the interval on error
            clearTimeout(timeoutIdPayfac);
          }
        } catch (err) {
          if (err.request) {
            setNetworkErrorPayment(true);
          }
          setPayFacPaymentStatus({ check: false });
          addDeclineCards(err?.response?.data?.error || err?.message, err?.response);
          handlePaymentError();
          clearInterval(id); // Clear the interval on error
          clearTimeout(timeoutIdPayfac);
        }
      }
    };

    id = setInterval(checkAndUpdateStatus, 1000);
    setIntervalIdPayfac(id);
  }

  const timeoutCountDown = () => {
    let timer = +getSettingData(GENERALSETIINGDATA)?.CREDIT?.payfac_p5_timeout + 3;
    if (!isNaN(+getSettingData(GENERALSETIINGDATA)?.CREDIT?.payfac_p5_timeout) && getSettingData(GENERALSETIINGDATA)?.CREDIT?.payfac_p5_timeout !== "") {
      let tId = setTimeout(() => {
        handleCancelPayfacPayment();
        setPayFacPaymentStatus({ check: false });
        clearInterval(intervalIdPayfac);
      }, [1000 * timer])
      setTimeoutIdPayfac(tId);
    }
  }

  useEffect(() => {
    if (completePayFacPayment && !cancelTransaction) {
      let ext_transaction_id = completePayFacPayment?.external_transaction_id;
      let payment_id = completePayFacPayment?.payment_id;
      let paymentDone = updatePaymentArrayCard(
        payFacInitiateData.type,
        completePayFacPayment?.amount,
        ext_transaction_id,
        undefined,
        undefined,
        completePayFacPayment,
        payment_id
      );
      if (!paymentDone) {
        UpdateButtonValues(
          sumCashBalance,
          completePayFacPayment?.amount
        );
      }
      setCompletePayFacPayment(false);
    }
  }, [completePayFacPayment])

  useEffect(() => {
    const performPayFacOperation = async () => {
      try {
        if (payFacInitiateData.init) {
          if (!getDeviceTerminalId()?.length) {
            handlePaymentError("Error", "Terminal Id not Found. Please check your device settings");
          } else {
            if (lastPaymentId.length && lastPaymentId[lastPaymentId.length - 1] && netWorkErrorPayment) {
              const status = await payFac(VERIFYSTATUS, getStatusDetails());
              if (status?.data?.status === "capture_requested" || status?.data?.status === "succeeded") {
                setCompletePayFacPayment(status?.data);
              } else {
                setNetworkErrorPayment(false);
                handlePayFacPayments(payFacInitiateData.type, payFacInitiateData.insert_type);
              }
            } else {
              setNetworkErrorPayment(false);
              handlePayFacPayments(payFacInitiateData.type, payFacInitiateData.insert_type);
            }
          }
        }
      } catch (err) {
        if (err.request) {
          setNetworkErrorPayment(true);
        }
        setPayFacPaymentStatus({ check: false });
        addDeclineCards(err?.response?.data?.error || err?.message, err?.response);
        handlePaymentError();
        clearInterval(intervalIdPayfac); // Clear the interval on error
        clearTimeout(timeoutIdPayfac);
      }
    };

    performPayFacOperation();
  }, [payFacInitiateData]);

  useEffect(() => {
    const fetchData = async () => {
      if (payFacPaymentStatus.check) {
        setShowCancelPayFac(true);
        let statusDetails = getStatusDetails({
          ...payFacPaymentStatus.data,
        });
        checkStatus(statusDetails);
        timeoutCountDown();
      } else {
        clearInterval(intervalIdPayfac);
        clearTimeout(timeoutIdPayfac);
      }
    };

    fetchData();
  }, [payFacPaymentStatus]);

  const handlePayFacPayments = async (data, insert_type) => {
    try {
      let external_payment_id = saleRef;
      let payment_method_types = insert_type ? "card_not_present_mke" : "card_present";
      let amt = actualAmount != "" ? actualAmount : sumRegularBalance;
      const orderDetails = getPayfacOrderDetails(amt, payment_method_types, "automatic", external_payment_id)
      let payFacOrder = await payFac(CREATEORDER, orderDetails);
      if (payFacOrder) {
        let authDetails = getPayfacAuthDetails({
          ...payFacOrder.data,
          external_payment_id,
          payment_method_types,
          amount: actualAmount != "" ? +actualAmount : sumCashBalance,
        })
        let paymentAuth = await payFac(AUTHORDER, authDetails);
        if (paymentAuth) {
          setLastPaymentToken(paymentAuth?.data?.token);
          setPayFacPaymentStatus({
            check: true,
            data: paymentAuth?.data
          })
        }
      }
    } catch (error) {
      if (error.request) {
        setNetworkErrorPayment(true);
      }
      addDeclineCards(error?.response?.data?.error || error?.message, error?.request, error?.response);
      handlePaymentError("Error", error?.response?.data?.error || error?.message)
    }
  }

  const handleCancelPayfacPayment = async () => {
    handleLoader(true, "Follow Prompts on Terminal");
    setcancelTransaction(false);
    const status = await payFac(VERIFYSTATUS, getStatusDetails());
    const cancelPayload = {
      payment_id: lastPaymentId[lastPaymentId.length - 1],
      external_account_id: getSettingData(GENERALSETIINGDATA)?.CREDIT?.payfac_account_id
    }
    let cancleStatus;
    if (status?.data?.status === "capture_requested" || status?.data?.status === "succeeded") {
      // normal cancel
      cancleStatus = await payFac(CANCEL, cancelPayload);
      if (cancleStatus?.data?.status === "refund_requested") {
        handlePaymentError("Error", "Payment has canceled. Payment will refund if approved")
      }
    } else {
      //terminal cancellation
      cancleStatus = payFac(HARDWARECANCEL, cancelPayload);
      // if (cancleStatus?.data?.status === "requires_payment_method") {
      //   handlePaymentError("Error", "Payment has canceled.");
      // }
      setPayFacPaymentStatus({ check: true });
    }
  }

  const handlePayFacRefund = async (type) => {
    if (type === "lastPayment") {
      let payments = props?.payment;
      let refundStatus = await payFac(PAYFACREFUND, getRefundDetails(props?.payment[props.payment.length - 1]?.tender, props?.payment[props.payment.length - 1]?.payment_id))
      if (refundStatus?.data?.status === "refunded" || refundStatus?.data?.status === "partially_refunded" || refundStatus?.data?.status === "refund_requested") {
        setPartial(false);
        setSumCashBalance(parseFloat(sumCashBalance) + parseFloat(props?.payment[props.payment.length - 1]?.tender))
        setSumNonCashBalance(parseFloat(sumCashBalance) + parseFloat(props?.payment[props.payment.length - 1]?.tender))
        payments.pop();
        dispatch(updatePaymentArrayReducer(REVERTPAYMENT, payments));
        handleLoader(false, "");
      }
    } else {
      let totalTenderRefund = 0;
      let paymentIds = [];
      handleLoader(true, "");
      for (let it of props?.payment) {
        let refundStatus = await payFac(PAYFACREFUND, getRefundDetails(it?.tender, it?.payment_id))
        if (refundStatus?.data?.status === "refunded" || refundStatus?.data?.status === "partially_refunded" || refundStatus?.data?.status === "refund_requested") {
          totalTenderRefund += parseFloat(it?.tender);
          paymentIds.push(it?.payment_id)
        }
      }
      handleLoader(false, "");
      setSumCashBalance(parseFloat(sumCashBalance) + parseFloat(totalTenderRefund))
      setSumNonCashBalance(parseFloat(sumCashBalance) + parseFloat(totalTenderRefund))
      dispatch(updatePaymentArrayReducer(REVERTPAYMENT, props?.payment?.filter(e => !paymentIds.includes(e?.payment_id))));
    }
  }

  const getAppropriateMsg = (msg) => {
    let messages = {
      "Account not found by external_account_id: ": "Account Id is missing/wrong",
      "The amount must be >= 0.5": "The amount must be greater than or equal to $0.50"
    }
    return messages[msg] || msg
  }

  const handlePaymentError = (title = "Error", msg = "Payment could not processed") => {
    setTendorAmount("");
    setPayFacInitiateData({ init: false });
    setPayFacAbort(false);
    handleLoader(false, "");
    setShowCancelPayFac(false);
    setOpenErrorMsg(true);
    setTitle(title);
    setErrorMsg(window.navigator.onLine ? getAppropriateMsg(msg) : "Error: Could not connect. Verify your network.")
    setShowIcon(Cancel);
    setCardType("");
  }

  const handlePaymentOption = (type, amountReq, ebt, insert_type) => {
    const genSet = props.genSetting.CREDIT;
    console.log("genSet", genSet);
    const data = props.salesitemList.filter(
      (item) => item.id === parseInt(genSet?.gift_item_id) || item?.itemData?.item_type == GIFTITEMTYPE
    );
    console.log(`data-->1`, props.salesitemList);
    console.log(`data-->2`, data);
    if (type === CASH || type === CHECK) {
      setShowIcon(null);
      setPaymentType("Cash");
      setRefund(false);
      if ((genSet?.gift_item_id != "" || checkGiftItem(props.salesitemList)) && data.length > 0) {
        if (actualAmount == "") {
          setTenderError(true);
        } else {
          const chvalue =
            parseFloat(actualAmount) -
            parseFloat(roundOffTotalPay(Number(sumCashBalance)));
          const paymentArry = getPaymentArray(
            CASH,
            actualAmount,
            chvalue > 0 ? chvalue : 0
          );
          let payments = props.payment.concat(paymentArry);
          addGiftCard(payments, (giftResponse) => {
            if (giftResponse === "ABORTED") {
              console.log("giftResponse sumCashBalance", props?.salesitemList);
              setSumCashBalance(parseFloat(paymentArry?.amount));
              setSumNonCashBalance(parseFloat(paymentArry?.amount));
              setChangeAmount(chvalue);
              setSumCash(parseFloat(paymentArry?.amount));
              setTendorAmount("");
              setActualAmount("");
            } else {
              setCashPayments(payments);
              setCashTotalAmount(props?.salesitemList);
              ConfirmCheckOutSale(
                [paymentArry],
                giftResponse !== "timeout" && giftResponse !== true
                  ? giftResponse
                  : null
              );
              handleLoader(false, "");
              setCheckOut(true);
            }
          });
        }
      } else if (!updatePaymentArray(type, actualAmount)) {
        UpdateButtonValues(sumCashBalance, actualAmount);
      }
    } else if (type === SIDECARD) {
      if (
        !updatePaymentArray(
          type,
          actualAmount != "" ? actualAmount : sumCashBalance
        )
      ) {
        UpdateButtonValues(sumCashBalance, actualAmount);
      }
    } else if (
      type === CARD ||
      type === DEBITCARD ||
      type === GIFTCARD ||
      type === EBTSALE ||
      type === EBTCASH
    ) {
      setPaymentType("Card");
      setShowIcon(attention);
      if (props?.GeneralSetting?.CREDIT?.card_type === 1) {
        const fraud_prevention = props.generalSetting?.CREDIT?.fraud_prevention;
        const fraudAmount = props.generalSetting?.CREDIT?.show_fraud_amount_above;
        if (
          fraudBypass === false &&
          fraud_prevention != undefined &&
          fraud_prevention == 1 &&
          fraudAmount !== "" &&
          (actualAmount != "" ? +actualAmount : sumCashBalance) >= +fraudAmount
        ) {
          ShowMsgInfo(
            true,
            `Amount Greater than ${fraudAmount}, Please check customer card and ID`,
            "Fraud Warning",
            type
          );
          setShowIcon(null);
        } else if (
          fraudBypass === false &&
          fraud_prevention != undefined &&
          fraud_prevention == 1 &&
          fraudAmount === ""
        ) {
          handleFraud();
          setShowIcon(null);
        } else {
          setCashTotalAmount(props?.salesitemList);

          if (actualAmount == "") {
            setActualAmount(sumCashBalance);
          }
          if (type == "EBT Sale") {
            console.log(`type`, type);
            console.log(`new`, props?.totalAmounts.EBTTotal);
            setActualAmount(
              actualAmount == ""
                ? parseFloat(props?.totalAmounts.EBTTotal)
                : actualAmount
            );
          }

          console.log(`actualAmount`, actualAmount);
          console.log(`salesitemList`, props.salesitemList);
          console.log(`ebtTotal`, props?.totalAmounts.EBTTotal);

          console.log(`type`, type);
          if (type == "Card" || type == "Debit Card") {
            if (props?.GeneralSetting?.CREDIT.custom_tips == 1) {
              handleLoader(true, "Follow Prompts on Terminal");
              if (window.navigator.onLine === false) {
                setTimeout(
                  function () {
                    handleLoader(false, "");
                    setOpenErrorMsg(true);
                    setTitle("Pax Error");
                    setErrorMsg("Could not connect to pinpad");
                    setShowIcon(Cancel);
                    setCardType("");
                  },
                  [3000]
                );
              }
              checkPaxSettingCustomTip(type, function (tipresponse) {
                console.log(`tipresponse>>`, tipresponse);
                if (
                  tipresponse.hasOwnProperty("tipvalue") &&
                  tipresponse.tipvalue !== null &&
                  tipresponse.tipvalue > 0
                ) {
                  let customtipamt = 0;
                  let tipamount = 0;
                  if (actualAmount == "") {
                    customtipamt = parseFloat(
                      (sumCashBalance * tipresponse.tipvalue) / 100
                    ).toFixed(2);
                    // tipamount = parseFloat(sumCashBalance)+parseFloat(customtipamt);
                  } else {
                    customtipamt = parseFloat(
                      (actualAmount * tipresponse.tipvalue) / 100
                    ).toFixed(2);
                    // tipamount = parseFloat(actualAmount)+parseFloat(customtipamt);
                  }
                  // var tipamount = Number(parseInt(customtipamt * 100));
                  console.log(`tipamount->`, customtipamt);
                  handlePayment(type, customtipamt);
                } else if (
                  tipresponse.hasOwnProperty("timeout") &&
                  tipresponse.timeout == true
                ) {
                  // setPaxError("timeout");
                  // setResponseMsg(`Error: ${tipresponse.ResponseMessage}`);
                } else {
                  if (tipresponse.tipvalue == 0) {
                    handlePayment(type);
                  }
                }
              });
            } else {
              handlePayment(type);
            }
          } else {
            handlePayment(type);
          }
        }
      } else if (props?.GeneralSetting?.CREDIT?.card_type === 4) {
        if (+actualAmount > +sumRegularBalance) {
          setOpenErrorMsg(true);
          setTitle("Error");
          setErrorMsg("Tendered amount can not be more than balance.");
          setShowIcon(null);
          return;
        }

        handleLoader(true, "Follow Prompts on Terminal");
        if (window.navigator.onLine) {
          if (actualAmount === "") {
            setActualAmount(sumRegularBalance)
          }

          const fraud_prevention = props.generalSetting?.CREDIT?.payfac_fraud_prevention;
          const fraudAmount = props.generalSetting?.CREDIT?.payfac_show_fraud_amount_above;
          if (
            fraud_prevention != undefined &&
            fraud_prevention == 1 &&
            fraudAmount !== "" &&
            (tendor_amount != "" ? +tendor_amount : sumRegularBalance) >= +fraudAmount
          ) {
            ShowMsgInfo(
              true,
              `Amount Greater than ${fraudAmount}, Please check customer card and ID`,
              "Fraud Warning",
              type
            );
            setShowIcon(null);
            setPayFacInitiateData({
              type,
              insert_type
            })
            return;
          }

          setPayFacInitiateData({
            init: true,
            type,
            insert_type
          })

        } else {
          setTimeout(
            function () {
              handleLoader(false, "");
              setOpenErrorMsg(true);
              setTitle("Error");
              setErrorMsg("Error: Could not connect. Verify your network.");
              setShowIcon(Cancel);
              setCardType("");
            },
            [3000]
          );
          return;
        }
      } else {
        handleLoader(true, "Follow Prompts on Terminal");
        setTimeout(
          function () {
            handleLoader(false, "");
            setOpenErrorMsg(true);
            setTitle("Pax Error");
            setErrorMsg("Could not connect to pinpad");
            setShowIcon(Cancel);
            setCardType("");
          },
          [4000]
        );
      }
      // alert("Working on Pax Integration");
    } else {
      setActualAmount(sumCashBalance);
      if (amountReq === 1) {
        if (!updatePaymentArray(type, actualAmount))
          UpdateButtonValues(sumCashBalance, actualAmount);
      } else {
        const paymentState = getPaymentArray(
          ebt === 1 ? "Ebt" : type,
          sumCashBalance,
          0
        );
        updateArray(paymentState);
        handlePrintRecipt("Other", 0, sumCashBalance, paymentState);
      }
    }
  };

  const UpdateButtonValues = (sumCashBalance, actualAmount) => {
    console.log(`sumCashBalance->`, sumCashBalance);
    console.log(`actualAmount->`, actualAmount);
    console.log(
      `sumcashbal->`,
      parseFloat(sumCashBalance) - parseFloat(actualAmount)
    );
    setSumCashBalance(parseFloat(sumCashBalance) - parseFloat(actualAmount));
    setSumNonCashBalance(
      parseFloat(sumNonCashBalance) - parseFloat(actualAmount)
    );
    setTendorAmount("");
    setActualAmount("");
  };

  const updatePaymentArray = (type, actualAmount) => {
    if (actualAmount !== "") {
      const paymentState = getPaymentArray(type, actualAmount, changeAmount);
      console.log(`paymentState`, paymentState);
      updateArray(paymentState);
      if (checkPaymentAmount()) return true;
      else return false;
    } else {
      console.log(`actualAmount`, actualAmount);
      setTenderError(true);
      return true;
    }
  };

  const getPaymentArray = (type, actualAmount, changeAmount) => {
    const paymentState = {
      method: type,
      amount: Number(
        parseFloat(actualAmount) - parseFloat(changeAmount)
      ).toFixed(2),
      tender: parseFloat(actualAmount).toFixed(2),
      change: parseFloat(changeAmount).toFixed(2),
      transaction_response: null,
      tip_amount: 0,
    };
    return paymentState;
  };

  const updatePaymentArrayCard = (
    type,
    actualAmount,
    transactionId,
    ebtBal,
    tip,
    card_response_json,
    payment_id
  ) => {
    console.log(`type`, type);
    console.log(`actualAmount`, actualAmount);
    console.log(`transactionId`, transactionId);
    console.log(`ebtBal`, ebtBal);
    console.log(`tip`, tip);
    console.log(`card response type`, card_response_json);

    if (type == "ebt") {
      if (actualAmount !== "") {
        const paymentState = getPaymentArrayCard(
          type,
          actualAmount,
          changeAmount,
          transactionId,
          ebtBal,
          tip,
          card_response_json,
          payment_id
        );
        console.log(`paymentState`, paymentState);
        updateArray(paymentState);
        if (checkPaymentAmount("ebt", actualAmount)) return true;
        else return false;
      } else if (sumCashBalance !== "") {
        const paymentState = getPaymentArrayCard(
          type,
          sumCashBalance,
          changeAmount,
          transactionId,
          ebtBal,
          tip,
          card_response_json,
          payment_id
        );
        updateArray(paymentState);
        if (checkPaymentAmount("ebt", actualAmount)) return true;
        else return false;
      } else {
        setTenderError(true);
        return true;
      }
    } else {
      if (actualAmount !== "") {
        const paymentState = getPaymentArrayCard(
          type,
          actualAmount,
          changeAmount,
          transactionId,
          ebtBal,
          tip,
          card_response_json,
          payment_id
        );
        console.log(`paymentState`, paymentState);
        updateArray(paymentState);
        if (checkPaymentAmount()) return true;
        else return false;
      } else if (sumCashBalance !== "") {
        const paymentState = getPaymentArrayCard(
          type,
          sumCashBalance,
          changeAmount,
          transactionId,
          ebtBal,
          tip,
          card_response_json,
          payment_id
        );
        updateArray(paymentState);
        if (checkPaymentAmount()) return true;
        else return false;
      } else {
        setTenderError(true);
        return true;
      }
    }
  };

  const getPaymentArrayCard = (
    type,
    actualAmount,
    changeAmount,
    transactionId,
    ebtBal,
    tip = 0,
    card_response_json,
    payment_id
  ) => {
    let transaction_details = {
      ...card_response_json,
      transactionId: parseInt(transactionId),
    };
    const paymentState = {
      method: type,
      amount: Number(
        parseFloat(actualAmount) - parseFloat(changeAmount)
      ).toFixed(2),
      tender: parseFloat(actualAmount).toFixed(2),
      change: parseFloat(changeAmount).toFixed(2),
      // transaction_response: parseInt(transactionId),
      transaction_response: {...transaction_details, tip_amount: tip},
      transactionId: parseInt(transactionId),
      ebt: ebtBal,
      tip_amount: tip,
      payment_id
      // transaction_responce: card_response_json
    };
    return paymentState;
  };

  const checkPaymentAmount = (type = null, ebtAmount = null) => {
    // totalAmountPayable
    if (type == "ebt") {
      if (ebtAmount == "") {
        if (parseFloat(changeAmount) == parseFloat(0)) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          parseFloat(ebtAmount) > parseFloat(sumCashBalance) ||
          (parseFloat(ebtAmount) === parseFloat(sumCashBalance) &&
            parseFloat(changeAmount) == parseFloat(0))
        )
          return true;
        else return false;
      }
    } else {
      if (
        parseFloat(actualAmount) > parseFloat(sumCashBalance) ||
        (parseFloat(actualAmount) == parseFloat(sumCashBalance) &&
          parseFloat(changeAmount) == parseFloat(0))
      )
        return true;
      else return false;
    }
  };

  const updateArray = (data) => {
    dispatch(updatePaymentArrayReducer(PAYMENT, data));
  };

  const handleCancleSalePayment = async () => {
    if (props?.payment?.filter(e => e.payment_id).length) {
      await handlePayFacRefund()
    }
    const data = updateSaleDataArray(props.payment);
    const { value, color } = SaleStatusData(parseInt(CANCELEDVALUE));
    setSaleStatus(value);
    dispatch(
      checkOutSaleItem(ITEMSALE, data, ADDCONFIRMSALEITEM, CANCELEDVALUE)
    );
    setPrintRecipt(true);
  };

  const updateSaleDataArray = (payment) => {
    return {
      items: props?.salesitemList,
      totalAmt: !checkEbt(payment) ? props?.totalAmounts : { ...props?.totalAmounts, Total: parseFloat(props?.totalAmounts.EBTTotal) },
      payment: payment,
      fees: props?.additionalFee,
      taxlist: props?.taxList,
      taxRule: props?.taxRules,
      BottleDeposit: props?.bottleDeposit,
      custMobile: props?.custMobile,
      loyaltyPointsPerDollar: props?.generalSetting?.LOYALTY?.loyalty_points,
      loyaltyPointsEnabled:
        props?.generalSetting?.LOYALTY?.enable_loyalty_points,
      promotionData: props?.promotionData,
      email_invoice_data: getInvoiceEmailData(),
      remove_items_id: getRemoveItemsIdSuspendSale(props?.salesitemList?.map(e => e.id)),
      ref: saleRef,
      accountSetting: props?.accountSetting,
    };
  };

  const refundVoidFn = (cmdtype, fraudPayment) => {
    console.log("fraudPayment", fraudPayment);
    setPartial(false);
    handleLoader(true, "Follow Prompts on Terminal");
    let refund_url = "";
    var parms = {};

    let lastPayment =
      fraudPayment != undefined
        ? fraudPayment
        : props.payment[props.payment.length - 1];

    var refund_amount = parseFloat(lastPayment?.amount);
    parms.transactionAmount = parseFloat(refund_amount);
    const transactionNumber = parseInt(
      lastPayment.transaction_response.transactionId
    );

    parms.transactionId = transactionNumber;
    parms.headers = getPaymentHeader();
    parms.ticketNumber = reference;

    var settings = getPaymentObject("Pax", parms, cmdtype, refund_url, "");

    $.ajax(settings)
      .done(function (response) {
        response = handlePaymentResponse("Pax", response);
        console.log(`void refund response->`, response);
        if (response.isApproved == true) {
          fraudPayment == undefined && setRefund(true);
          const payments = props.payment;
          setSumCashBalance((prev) =>
            prev + fraudPayment != undefined
              ? fraudPayment?.tender
              : payments[payments.length - 1]?.transaction_response
                ?.approvedAmount
          );
          setSumNonCashBalance((prev) =>
            prev + fraudPayment != undefined
              ? fraudPayment?.tender
              : payments[payments.length - 1]?.transaction_response
                ?.approvedAmount
          );
          let popped =
            fraudPayment != undefined
              ? payments.filter(
                (payment) =>
                  fraudPayment?.transactionId != payment?.transactionId
              )
              : payments.slice(0, -1);
          dispatch(updatePaymentArrayReducer(REVERTPAYMENT, popped));
          setPartial(false);
          handleLoader(false, "");
          if (cmdtype == "void") {
          } else if (cmdtype == "refund") {
          }
        }
      })
      .fail(function (data) { });
  };

  function checkPaxSettingCustomTip(cardsaletype, callback) {
    var isTipsPromptOneTime = false;

    let respnosedata = {
      tipvalue: null,
    };

    if (isTipsPromptOneTime === true) {
      // return callback(respnosedata);
    }

    let customtipvalues = {
      title: "Select Tip",
      1: null,
      2: null,
      3: null,
      4: "NO TIP",
    };
    console.log(`propsGeneralSetting`, props.GeneralSetting);
    //check pax and custom tip enable
    try {
      if (props?.GeneralSetting?.CREDIT.custom_tips == 1) {
        let custom_tip_one =
          props?.GeneralSetting?.CREDIT.tip1 &&
            props?.GeneralSetting?.CREDIT.tip1 != ""
            ? props?.GeneralSetting?.CREDIT.tip1
            : null;
        let custom_tip_two =
          props?.GeneralSetting?.CREDIT.tip2 &&
            props?.GeneralSetting?.CREDIT.tip2 != ""
            ? props?.GeneralSetting?.CREDIT.tip2
            : null;
        let custom_tip_three =
          props?.GeneralSetting?.CREDIT.tip3 &&
            props?.GeneralSetting?.CREDIT.tip3 != ""
            ? props?.GeneralSetting?.CREDIT.tip3
            : null;

        if (custom_tip_one !== null)
          customtipvalues["1"] = custom_tip_one + "%";
        if (custom_tip_two !== null)
          customtipvalues["2"] = custom_tip_two + "%";
        if (custom_tip_three !== null)
          customtipvalues["3"] = custom_tip_three + "%";

        if (
          custom_tip_one === null &&
          custom_tip_two === null &&
          custom_tip_three === null
        ) {
          return callback(respnosedata);
        }

        var commandType = null;
        var tipsettings = getPaxTipDialogSettings(customtipvalues);
        console.log(`tipsettings`, tipsettings);
        $.ajax(tipsettings)
          .done(function (tipresponse) {
            if (tipresponse !== null) {
              console.log(`tipresponse->`, tipresponse);

              tipresponse = parseResponse(tipresponse);
              console.log(`tipresponse->>`, tipresponse);
              if (tipresponse?.ResponseMessage == "ABORTED") {
                setLoader(false);
              }
              if (
                tipresponse.hasOwnProperty("HostInformation") &&
                customtipvalues.hasOwnProperty(tipresponse["HostInformation"])
              ) {
                let seltipvalue =
                  customtipvalues[tipresponse["HostInformation"]];
                if (seltipvalue == "NO TIP") {
                  seltipvalue = 0;
                } else {
                  if (
                    typeof seltipvalue === "string" &&
                    seltipvalue.length > 0 &&
                    seltipvalue.includes("%")
                  ) {
                    seltipvalue = Number(seltipvalue.replace(/%/g, ""));
                  } else {
                    seltipvalue = null;
                  }
                }
                console.log(`seltipvalue`, seltipvalue);
                respnosedata.tipvalue = seltipvalue;
                // isTipsPromptOneTime = true;
                return callback(respnosedata);
              } else if (tipresponse?.ResponseMessage == "TIMEOUT") {
                setLoader(false);
                return callback({ timeout: true });
              } else {
                return callback(false);
              }
            } else {
              return callback(false);
            }
          })
          .fail(function (jqXHR, status, error) {
            console.log(`error`, error);
            setLoader(false);
            handleLoader(false, "");
            setOpenErrorMsg(true);
            setTitle("Pax Error");
            setErrorMsg("Could not connect to pinpad");
            setShowIcon(Cancel);
            setCardType("");
            return callback(respnosedata);
          });
      } else {
        return callback(respnosedata);
      }
    } catch (e) {
      return callback(respnosedata);
    }
  }

  const getPaxTipDialogSettings = function (params) {
    const config = "";
    var settings = null;
    try {
      settings = showDialogForInput(params);
      console.log(`settings`, settings);
      return settings;
    } catch (e) {
      return false;
    }
  };

  function showDialogForInput(params) {
    var configuration = {
      version: "1.28",
      protocol: "https",
      ip: "127.0.0.1",
      port: "10009",
      promptForSignature: 0,
    };

    let command = "A06";
    let version = configuration.version;
    let title =
      params.hasOwnProperty("title") && params.title !== null
        ? params.title
        : "Select Tip";
    let input_one =
      params.hasOwnProperty("1") && params["1"] !== null && params["1"] !== ""
        ? params["1"]
        : null;
    let input_two =
      params.hasOwnProperty("2") && params["2"] !== null && params["2"] !== ""
        ? params["2"]
        : null;
    let input_three =
      params.hasOwnProperty("3") && params["3"] !== null && params["3"] !== ""
        ? params["3"]
        : null;
    let input_four =
      params.hasOwnProperty("4") && params["4"] !== null ? params["4"] : "NO";
    let timeout = "100";
    let continuousScreen = "1";

    if (
      input_one !== null &&
      input_two !== null &&
      input_three === null &&
      input_four !== null
    ) {
      input_three = input_four;
      input_four = null;
    }
    if (
      input_one !== null &&
      input_two === null &&
      input_three === null &&
      input_four !== null
    ) {
      input_two = input_four;
      input_four = null;
    }
    if (
      input_one !== null &&
      input_two === null &&
      input_three !== null &&
      input_four !== null
    ) {
      input_two = input_three;
      input_three = input_four;
      input_four = null;
    }
    if (
      input_one === null &&
      input_two !== null &&
      input_three !== null &&
      input_four !== null
    ) {
      input_one = input_two;
      input_two = input_three;
      input_three = input_four;
      input_four = null;
    }
    if (
      input_one === null &&
      input_two === null &&
      input_three !== null &&
      input_four !== null
    ) {
      input_one = input_three;
      input_two = input_four;
      input_three = null;
      input_four = null;
    }
    if (
      input_one === null &&
      input_two !== null &&
      input_three === null &&
      input_four !== null
    ) {
      input_one = input_two;
      input_two = input_four;
      input_four = null;
    }

    let paxdata = {
      command: command,
      version: version,
      title: title,
      input_one: input_one,
      input_two: input_two,
      input_three: input_three,
      input_four: input_four,
      timeout: timeout,
      continuousScreen: continuousScreen,
    };

    return ShowInputDialog(paxdata);
  }

  //show dialog
  function ShowInputDialog(dialogData) {
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      mDestinationIP: `${paxConfig.pax_protocol == ""
        ? "http"
        : paxConfig.pax_protocol == "1"
          ? "http"
          : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };

    let params = [
      PAX.mStx.hex,
      dialogData.command,
      PAX.mFS.hex,
      dialogData.version,
      PAX.mFS.hex,
      dialogData.title,
      PAX.mFS.hex,
    ];

    //prepare for base64 encoding.
    var command_hex = base64ToHex(Base64.btoa(dialogData.command));
    var version_hex = base64ToHex(Base64.btoa(dialogData.version));
    var title_hex = base64ToHex(Base64.btoa(dialogData.title));
    var input_four_hex = base64ToHex(Base64.btoa(dialogData.input_four));
    var timeout_hex = base64ToHex(Base64.btoa(dialogData.timeout));
    var contscreen_hex = base64ToHex(Base64.btoa(dialogData.continuousScreen));

    var elements = [PAX.mStx.code];
    elements.push(command_hex);
    elements.push(PAX.mFS.code);
    elements.push(version_hex);
    elements.push(PAX.mFS.code);
    elements.push(title_hex);
    elements.push(PAX.mFS.code);

    if (dialogData.input_one !== null) {
      params.push(dialogData.input_one);
      var input_one_hex = base64ToHex(Base64.btoa(dialogData.input_one));
      elements.push(input_one_hex);
    }

    elements.push(PAX.mFS.code);
    params.push(PAX.mFS.hex);
    if (dialogData.input_two !== null) {
      params.push(dialogData.input_two);
      var input_two_hex = base64ToHex(Base64.btoa(dialogData.input_two));
      elements.push(input_two_hex);
    }

    elements.push(PAX.mFS.code);
    params.push(PAX.mFS.hex);
    if (dialogData.input_three !== null) {
      params.push(dialogData.input_three);
      var input_three_hex = base64ToHex(Base64.btoa(dialogData.input_three));
      elements.push(input_three_hex);
    }

    elements.push(PAX.mFS.code);
    params.push(PAX.mFS.hex);

    if (dialogData.input_four !== null) {
      params.push(dialogData.input_four);
      elements.push(input_four_hex);
    }

    params.push(PAX.mFS.hex);
    elements.push(PAX.mFS.code);

    params.push(dialogData.timeout);
    params.push(PAX.mFS.hex);

    params.push(dialogData.continuousScreen);
    params.push(PAX.mEtx.hex);

    // elements.push(PAX.mFS.code);
    elements.push(timeout_hex);
    elements.push(PAX.mFS.code);

    elements.push(contscreen_hex);
    elements.push(PAX.mEtx.code);

    var lrc = getLRC(params);

    elements.push(base64ToHex(Base64.btoa(lrc)));

    var final_string = elements.join(" ");
    var final_b64 = hexToBase64(final_string);

    var url = PAX.mDestinationIP + "?" + final_b64;

    console.log(`url`, url);

    return { url: url };
  }

  const ResetPax = () => {
    var initialInfo = {
      command: "A00",
      version: "1.28",
    };
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      // mDestinationIP: "http://127.0.0.1:10009",
      mDestinationIP: `${paxConfig.pax_protocol == ""
        ? "https"
        : paxConfig.pax_protocol == "1"
          ? "http"
          : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };
    var params = [
      PAX.mStx.hex,
      initialInfo.command,
      PAX.mFS.hex,
      initialInfo.version,
      PAX.mEtx.hex,
    ];
    var lrc = getLRC(params);

    var command_hex = base64ToHex(Base64.btoa(initialInfo.command));
    var version_hex = base64ToHex(Base64.btoa(initialInfo.version));
    //var elements = [this.mStx, command_hex, this.mFS, version_hex, this.mEtx, base64ToHex(Base64.btoa(lrc))];
    var elements = [
      PAX.mStx.code,
      command_hex,
      PAX.mFS.code,
      version_hex,
      PAX.mEtx.code,
      base64ToHex(Base64.btoa(lrc)),
    ];

    var final_string = elements.join(" ");

    var final_b64 = hexToBase64(final_string);

    // var url = PAX.mDestinationIP + "?" + final_b64;
    var url = PAX.mDestinationIP + "?AkEyNhwxLjI4A08=";

    HttpCommunication(
      "Restart",
      url,
      function (response) {
        console.log(`paxres`, response);
      },
      PAX.timeout.Initialize
    );
  };

  const InitializePax = () => {
    var initialInfo = {
      command: "A00",
      version: "1.28",
    };
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      mDestinationIP: `${paxConfig.pax_protocol == ""
        ? "http"
        : paxConfig.pax_protocol == "1"
          ? "http"
          : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };
    var params = [
      PAX.mStx.hex,
      initialInfo.command,
      PAX.mFS.hex,
      initialInfo.version,
      PAX.mEtx.hex,
    ];
    //[02]A08[1c]1.28[1c]0[1c]90000[03]
    //var params = [0x02,"A08",0x1c,"1.28",0x1c, "0", 0x1c,"90000",0x03];
    var lrc = getLRC(params);

    var command_hex = base64ToHex(Base64.btoa(initialInfo.command));
    var version_hex = base64ToHex(Base64.btoa(initialInfo.version));
    //var elements = [this.mStx, command_hex, this.mFS, version_hex, this.mEtx, base64ToHex(Base64.btoa(lrc))];
    var elements = [
      PAX.mStx.code,
      command_hex,
      PAX.mFS.code,
      version_hex,
      PAX.mEtx.code,
      base64ToHex(Base64.btoa(lrc)),
    ];

    var final_string = elements.join(" ");
    //console.log("final_string: " + final_string);

    var final_b64 = hexToBase64(final_string);
    console.log("LRC: " + lrc);
    console.log("Base64: " + final_b64);
    var url = PAX.mDestinationIP + "?" + final_b64;
    console.log("URL: " + url);

    HttpCommunication(
      "Initialize",
      url,
      function (response) {
        console.log(response);
        // callback(response);
      },
      PAX.timeout.Initialize
    );
  };

  const HttpCommunication = (commandType, url, callback, timeout) => {
    $.ajax({
      url: url,
      timeout: timeout,
      error: function (xmlHttpRequest, error) {
        setTimeout(function () {
          handleLoader(false);
          setTbsBtn(false);
          setTitle("Error");
          setErrorMsg(
            "Please Manually Power Cycle the Card machine and Relogin"
          );
          setShowIcon(null);
          setOpenErrorMsg(true);
        }, 3000);
        console.log("Ajax error info: " + error);
        callback(error);
      },
      success: function (response) {
        console.log("success");
        console.log("Raw response: " + response);

        // handleLoader(true, "Restarting");
        setOpenErrorMsg(false);

        setTimeout(() => {
          setTitle("Success");
          setErrorMsg("PAX Troubleshooting is done");
          setShowIcon(null);
          setOpenErrorMsg(true);
          setTbsBtn(false);
          handleLoader(false, "");
        }, [8000]);

        var checkParams = StringToHex(response).split(" ").pop();
        var RedundancyCheck = StringToHex(response)
          .split(" ")
          .pop()
          .substring(1);

        var check = getLRC(checkParams);

        if (check == RedundancyCheck) {
          //get package detail info
          var packetInfo = [];
          var len = StringToHex(response).indexOf("03");
          var hex = StringToHex(response).slice(0, len).split(/02|1c/);

          console.log(hex);
          if (commandType == "DoCredit") {
            var subHex = [],
              subPacketInfo = [];
            for (var i = 0; i < hex.length; i++) {
              if (hex[i] != "") {
                if (hex[i].indexOf("1f") > 0) {
                  subHex = hex[i].split("1f");
                  console.log(subHex);
                  subPacketInfo = [];
                  for (var j = 0; j < subHex.length; j++) {
                    if (subHex[j] != "") {
                      subPacketInfo.push(HexToString(subHex[j]));
                    }
                  }
                  console.log(subPacketInfo);
                  packetInfo.push(subPacketInfo);
                } else {
                  packetInfo[i] = HexToString(hex[i]);
                }
              }
            }
          } else {
            for (var i = 0; i < hex.length; i++) {
              if (hex[i] != "") {
                packetInfo[i] = HexToString(hex[i]);
              }
            }
          }

          console.log("Separate package info: ");
          console.log(packetInfo);
          callback(packetInfo);
        }
      },
    });
  };

  const paxTroubleShoot = () => {
    // InitializePax();
    setOpenErrorMsg(false);
    handleLoader(true, "Restarting");
    ResetPax();
  };

  const partialFn = () => {
    setNetworkErrorPayment(false);
    setPartial(false);
    handleLoader(false, "");
  };

  useEffect(() => {
    const setupData = JSON.parse(localStorage.getItem("setupData"));
    console.log(`props---->`, props);
    if (props?.devices.length > 0) {
      const paxDetails = props.devices.filter(
        (item) => item.id === setupData.device
      );
      console.log(`paxDetails`, paxDetails);
      if (
        paxDetails != undefined &&
        (paxDetails != "") & (paxDetails != null)
      ) {
        setPaxConfig({
          pax_ip: paxDetails[0].pax_ip,
          pax_port: paxDetails[0].pax_port,
          pax_protocol: paxDetails[0].pax_protocol,
        });
      }
    }
  }, []);

  const cashRounding = getSettingData(POSSETTINGDATA)?.SALE?.cash_rounding;
  console.log(
    cashRounding,
    Number(roundOffTotalPay(Number(sumCash))),
    Number(button1.value),
    typeof button1.value,
    typeof roundOffTotalPay(Number(sumCash))
  );

  const handlingFraudPreventSale = () => {
    setFraudPrevent(false);
    setShowIcon(attention);
    handleLoader(false, "");
    if (partial == false) {
      ConfirmCheckOutSale(props.payment);
      if (!isWeb()) {
        // open cash drawer
        var thermaPrinter = preparePrinter("epson");
        if (
          wpos_config &&
          wpos_config?.hasOwnProperty("report_printer") &&
          wpos_config.report_printer != undefined
        ) {
          window.nodeprinter.printDirect({
            printerName: wpos_config.report_printer,
            type: "RAW",
            data: thermaPrinter.getBuffer(),
            success: function (jobID) {
              for (var x = 0; x < props.payment.length; x++) {
                let itm = props.payment[x];
                if (
                  itm.method == "Cash" &&
                  wpos_config.cash_draw_connected === true
                ) {
                  Cashdraw(false);
                }
              }
            },
            error: function (err) {
              console.error(err);
            },
          });
        }
      }
      if (wpos_config?.asktoPrint == "ask") {
        setCheckOut(true);
      } else if (wpos_config?.asktoPrint == "print") {
        PrintFn();
      } else {
        PrintFn();
      }
    }
  };

  const handlingFraudPreventSaleRefunds = () => {
    let methods = ["card", "debit", "ebt cash", "ebt", "gift"];
    for (let i of props.payment) {
      if (methods.includes(i.method)) {
        refundVoidFn("refund", i);
        break;
      }
    }
    setRefund(true);
    setTenderClicked(false);
    setFraudPrevent(false);
  };

  const handleRetryPopUp = () => {
    setOpenErrorMsg1(false);
    setErrorMsg("");
    const paymentArry = getPaymentArray(
      CASH,
      sumCashBalance,
      changeAmount > 0 ? changeAmount : 0
    );
    let payments = props.payment.concat(paymentArry);
    addGiftCard(payments, (giftResponse) => {
      if (giftResponse === "ABORTED") {
        console.log("giftResponse sumCashBalance", props?.salesitemList);
        setSumCashBalance(parseFloat(paymentArry?.amount));
        setSumNonCashBalance(parseFloat(paymentArry?.amount));
        setChangeAmount(changeAmount);
        setSumCash(parseFloat(paymentArry?.amount));
        setTendorAmount("");
        setActualAmount("");
      } else {
        setCashPayments(payments);
        setCashTotalAmount(props?.salesitemList);
        ConfirmCheckOutSale(
          [paymentArry],
          giftResponse !== "timeout" && giftResponse !== true
            ? giftResponse
            : null
        );
        handleLoader(false, "");
        setCheckOut(true);
      }
    });
  };

  const handleRetryClosepopUp = () => {
    setOpenErrorMsg1(false);
    setErrorMsg("");
    console.log("giftResponse", giftResponse, giftPayment);
    setCheckOut(true);
    setCashPayments(giftPayment);
    setCashTotalAmount(props?.salesitemList);
    ConfirmCheckOutSale(
      giftPayment,
      giftResponse !== "timeout" && giftResponse !== true ? giftResponse : null
    );
  };

  return (
    <>
      {loader && (
        <CustomModal
          open={loader}
          dialogClassName={`custom-modal confirmation error-popup`}
          title={loaderType}
          content={<div style={{ paddingTop: '40px', paddingBottom: '20px' }}>
            <LoaderSpinner additionalClass="loader-index" />
          </div>}
          action={<>
            {showCancelPayFac && (
              <Button
                variant="primary"
                type="button"
                className="index-btn"
                onClick={() => {
                  setPayFacPaymentStatus({
                    ...payFacPaymentStatus,
                    check: false,
                  })
                  setPayFacAbort(true)
                }}
              >
                Cancel
              </Button>
            )}
          </>}
          removeCloseIcon={`d-none`}
        />
      )}
      <Row>
        <Col className="totals-box py-3" lg="4">
          <ListGroup className="mb-3" as="ol" numbered="true">
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold"># Items:</div>
              </div>
              <Badge>
                {qtyFormat(
                  decimalQty,
                  sumQtySaleitem.reduce((a, b) => a + +b, 0)
                )}
              </Badge>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Cash Total</div>
              </div>
              <Badge>{currencyFormat(roundOffTotalPay(Number(sumCash)))}</Badge>
            </ListGroup.Item>
            {nonCashAdjEnable > 0 && (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-center"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{NonCashAdjLable + " Total"}</div>
                </div>
                <Badge>
                  {currencyFormat(roundOffTotalPay(Number(totalAmount)))}
                </Badge>
              </ListGroup.Item>
            )}

            {sumRegularTotal > 0 && (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-center"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Regular Total</div>
                </div>
                <Badge>
                  {currencyFormat(sumRegularTotal)}
                </Badge>
              </ListGroup.Item>
            )}
            {deafultEbtTaxCheck > 0 && checkedEbtAny && (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-center"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">EBT Total</div>
                </div>
                <Badge>{currencyFormat(sumEBTTotal)}</Badge>
              </ListGroup.Item>
            )}
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Tendered:</div>
              </div>
              <Form.Group className="form-floating with-label-up">
                <FormField
                  type="text"
                  name="actualAmount"
                  value={actualAmount}
                  onChange={(e) => handleChange(e)}
                  placeholder="$"
                  autoComplete="off"
                />
              </Form.Group>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Cash Balance</div>
              </div>
              <Badge>
                {currencyFormat(roundOffTotalPay(Number(sumCashBalance)))}
              </Badge>
            </ListGroup.Item>

            {nonCashAdjEnable > 0 && (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-center"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{NonCashAdjLable + " Balance"}</div>
                </div>
                <Badge>
                  {currencyFormat(roundOffTotalPay(Number(sumNonCashBalance)))}
                </Badge>
              </ListGroup.Item>
            )}

            {sumRegularBalance > 0 && (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-center"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Regular Balance</div>
                </div>
                <Badge>
                  {currencyFormat(sumRegularBalance)}
                </Badge>
              </ListGroup.Item>
            )}


            {deafultEbtTaxCheck > 0 && checkedEbtAny && (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-center"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">EBT Balance</div>
                </div>
                <Badge>{currencyFormat(sumEBTTotal)}</Badge>
              </ListGroup.Item>
            )}
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Change:</div>
              </div>
              <Badge>{currencyFormat(changeAmount)}</Badge>
            </ListGroup.Item>
          </ListGroup>
          <Row className="cta-btns">
            {button1.disable !== true && (
              <Col lg="4" sm="2" xs="6">
                <Button
                  variant="outline-blackcustom"
                  type="button"
                  onClick={() => handlePrintRecipt("button", button1.value)}
                >
                  {currencyFormat(button1.value)}
                </Button>
              </Col>
            )}
            {button2.disable !== true && (
              <Col lg="4" sm="2" xs="6">
                <Button
                  variant="outline-blackcustom"
                  type="button"
                  onClick={() => handlePrintRecipt("button", button2.value)}
                >
                  {currencyFormat(button2.value)}
                </Button>
              </Col>
            )}
            {button3.disable !== true && (
              <Col lg="4" sm="2" xs="6">
                <Button
                  variant="outline-blackcustom"
                  type="button"
                  onClick={() => handlePrintRecipt("button", button3.value)}
                >
                  {currencyFormat(button3.value)}
                </Button>
              </Col>
            )}
            {button4.disable !== true && (
              <Col lg="4" sm="2" xs="6">
                <Button
                  variant="outline-blackcustom"
                  type="button"
                  onClick={() => handlePrintRecipt("button", button4.value)}
                >
                  {currencyFormat(button4.value)}
                </Button>
              </Col>
            )}
            {button5.disable !== true && (
              <Col lg="4" sm="2" xs="6">
                <Button
                  variant="outline-blackcustom"
                  type="button"
                  onClick={() => handlePrintRecipt("button", button5.value)}
                >
                  {currencyFormat(button5.value)}
                </Button>
              </Col>
            )}
            {button6.disable !== true && (
              <Col lg="4" sm="2" xs="6">
                <Button
                  variant="outline-blackcustom"
                  type="button"
                  onClick={() => handlePrintRecipt("button", button6.value)}
                >
                  {currencyFormat(button6.value)}
                </Button>
              </Col>
            )}
            {button7.disable !== true && (
              <Col lg="4" sm="2" xs="6">
                <Button
                  variant="outline-blackcustom"
                  type="button"
                  onClick={() => handlePrintRecipt("button", button7.value)}
                >
                  {currencyFormat(button7.value)}
                </Button>
              </Col>
            )}
            {button8.disable !== true && (
              <Col lg="4" sm="2" xs="6">
                <Button
                  variant="outline-blackcustom"
                  type="button"
                  onClick={() => handlePrintRecipt("button", button8.value)}
                >
                  {currencyFormat(button8.value)}
                </Button>
              </Col>
            )}
            {button9.disable !== true && (
              <Col lg="4" sm="2" xs="6">
                <Button
                  variant="outline-blackcustom"
                  type="button"
                  onClick={() => handlePrintRecipt("button", button9.value)}
                >
                  {currencyFormat(button9.value)}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
        <Col lg="4" className="mt-4 mt-lg-0">
          <CustomNumpad
            checkoutKeys="checkout"
            setValue={getKeyPadValue}
            type="checkout"
            hideNumpad={closedCheckOutPopUp}
            handleCancle={setcancelTransaction}
          />
        </Col>
        <Col
          lg="4"
          className="mt-4 mt-lg-0 py-3 cta-btns payment-info align-self-start"
        >
          <Button
            type="button"
            variant="outline-blackcustom"
            onClick={() => handlePaymentOption(CASH)}
          >
            <Image src={cashIcon} alt="Cash Icon" />
            {CASH}
          </Button>
          <Button
            type="button"
            variant="outline-blackcustom"
            onClick={() => handlePaymentOption(CARD)}
          >
            <Image src={debit} alt="debit Icon" />
            {CARD}
          </Button>
          {otherButton.check === 1 && (
            <Button
              type="button"
              variant="outline-blackcustom"
              onClick={() => handlePaymentOption(CHECK)}
            >
              <Image src={check} alt="check Icon" />
              {CHECK}
            </Button>
          )}
          {otherButton.side === 1 && (
            <Button
              type="button"
              variant="outline-blackcustom"
              onClick={() => handlePaymentOption(SIDECARD)}
            >
              <Image src={sideCard} alt="sideCard Icon" />
              {SIDECARD}
            </Button>
          )}
          {otherButton.gift === 1 && (
            <Button
              type="button"
              variant="outline-blackcustom"
              onClick={() => handlePaymentOption(GIFTCARD)}
            >
              <Image src={giftCard} alt="giftCard Icon" />
              {GIFTCARD}
            </Button>
          )}
          {otherButton.ebtSale === 1 && (
            <Button
              type="button"
              variant="outline-blackcustom"
              onClick={() => handlePaymentOption(EBTSALE)}
              disabled={
                !checkedEbtAny ||
                props?.accountSetting?.TAXRULEDATA?.deafultEbtTaxCheck == 0
              }
            >
              <Image src={ebt} alt="ebt Icon" />
              {EBTSALE}
            </Button>
          )}
          {otherButton.ebtCash === 1 && (
            <Button
              type="button"
              variant="outline-blackcustom"
              onClick={() => handlePaymentOption(EBTCASH)}
            >
              <Image src={ebt} alt="ebt Icon" />
              {EBTCASH}
            </Button>
          )}
          {otherButton.debit === 1 && (
            <Button
              type="button"
              variant="outline-blackcustom"
              onClick={() => handlePaymentOption(DEBITCARD)}
            >
              <Image src={debit} alt="debit Icon" />
              {DEBITCARD}
            </Button>
          )}

          {payfac_manual_card && (
            <Button
              type="button"
              variant="outline-blackcustom"
              onClick={() => handlePaymentOption(CARD, "", "", "manual")}
            >
              <Image src={debit} alt="debit Icon" />
              Manual Card
            </Button>
          )}

          {tenderButton
            .filter((item) => {
              return parseInt(item.value) !== 0;
            })
            .map((data, i) => {
              return (
                <Button
                  key={i}
                  type="button"
                  variant="outline-blackcustom"
                  onClick={() =>
                    handlePaymentOption(
                      FirtsLetterUpperCase(data.label.toLowerCase()),
                      parseInt(data.amount_requird),
                      parseInt(data.ebt_enable)
                    )
                  }
                >
                  {FirtsLetterUpperCase(data.label.toLowerCase())}
                </Button>
              );
            })}

          {sale_customer && sale_customer.ishousepay && (
            <Button
              type="button"
              variant="outline-blackcustom"
              onClick={() =>
                handlePrintRecipt(HOUSEPAY, roundOffTotalPay(Number(sumCash)))
              }
            >
              <Image src={debit} alt="debit Icon" />
              {HOUSEPAY}
            </Button>
          )}
        </Col>
      </Row>
      <div style={{ display: "none" }}>
        <ComponentToPrint
          ref={componentRef}
          details={props.details}
          custMobile={props?.custMobile}
          custlist={props?.custlist}
          saleslist={props?.salesitemList}
          totalAmount={props?.totalAmounts}
          payment={props?.payment}
          taxRules={props?.taxRules}
        />
      </div>
      {/* Tender Error PopUp*/}
      <CustomModal
        backdrop={false}
        open={checkOut}
        handleClose={
          props?.paxerror == "timeout" ? handleClosePaxpopUp : handleClosepopUp
        }
        dialogClassName="custom-modal confirmation checkout-complete modal-388w align-btn"
        title={props?.paxerror == "timeout" ? "Error" : "Checkout Complete"}
        content={
          <>
            <span
              className={`${paymentType === "Card" ? "timer-txt" : "timer-txt-2"
                }`}
            >
              {wpos_config?.autoHideReciptDialog &&
                props?.paxerror != "timeout" &&
                `(${timer})`}
            </span>
            {/* pax response msg */}

            {showIcon && <Image src={showIcon} alt="warning" width="170px" />}
            <h4>{responseMsg}</h4>
            <span className="mb-3">Print Receipt ?</span>
            <div>Change: ${parseFloat(checkoutChangeAmt).toFixed(2)}</div>
          </>
        }
        action={
          <>
            <Button
              variant="primary px-5 form-cta-btn "
              type="button"
              onClick={(e) => {
                PrintFn(e);
                setCheckOut(false);
                setResponseMsg("");
                setCardResponse("");
                if (props?.paxerror != "timeout") {
                  const wosConfig = JSON.parse(localStorage.getItem("wpos_config"));
                  if (!(wosConfig?.connectMethod === "ep" && isWeb())) {
                    handleClosepopUp()
                  }
                  setTimeout(() => {
                    props.setCustMobile("");
                    if (props?.custMobile?.length == 0) {
                      localStorage.removeItem("custMobile_reprint")
                    }
                  }, 2000)
                }
              }}
            >
              Yes
            </Button>
            <Button
              variant="outline-blackcustom px-5"
              type="button"
              onClick={
                props?.paxerror == "timeout"
                  ? handleClosePaxpopUp
                  : handleClosepopUp
              }
            >
              No
            </Button>
          </>
        }
      />

      <CustomModal
        backdrop={false}
        open={fraudPrevent}
        handleClose={() => {
          setRefundConfirmation(true);
          setFraudPrevent(false);
        }}
        dialogClassName="custom-modal confirmation checkout-complete modal-388w align-btn"
        title="Fraud Warning"
        content={
          <>
            {/* pax response msg */}
            <Image src={attention} alt="warning" width="170px" />
            <h5>Keep It:</h5>
            <h5>Press Yes or No?</h5>
          </>
        }
        action={
          <>
            <Button
              variant="primary px-5 form-cta-btn"
              type="button"
              onClick={() => {
                handlingFraudPreventSale();
              }}
            >
              Yes
            </Button>
            <Button
              variant="outline-blackcustom px-5"
              type="button"
              onClick={() => {
                setRefundConfirmation(true);
                setFraudPrevent(false);
              }}
            >
              No
            </Button>
          </>
        }
      />

      {/* refundConfirmation PopUp*/}
      <CustomModal
        backdrop={false}
        open={refundConfirmation}
        handleClose={() => {
          handlingFraudPreventSale();
          setRefundConfirmation(false);
        }}
        dialogClassName="custom-modal confirmation verify-age"
        title="Are You Sure?"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.warningRed}
                alt="warning Icon"
              />
              Amount will be returned to Card.
            </h1>
            <Button
              variant="primary px-3 position-absolute form-cta-btn"
              type="button"
              onClick={(e) => {
                handlingFraudPreventSaleRefunds();
                setRefundConfirmation(false);
              }}
            >
              Yes
            </Button>
          </>
        }
        action={
          <Button
            variant="outline-blackcustom px-5 m-auto"
            onClick={() => {
              handlingFraudPreventSale();
              setRefundConfirmation(false);
            }}
          >
            No
          </Button>
        }
      />

      <CustomModal
        backdrop={false}
        open={partial}
        handleClose={handleClosepopUp}
        dialogClassName="custom-modal confirmation checkout-complete modal-388w"
        title="Demo Approved"
        content={
          <>
            {/* pax response msg */}
            <Image src={attention} alt="warning" width="170px" />
            <h5>Partial Approved</h5>
            <h5>Approved Amount: ${props.payment[props?.payment?.length - 1]?.amount}</h5>
            {/* <div>Change: ${parseFloat(checkoutChangeAmt).toFixed(2)}</div> */}
            <h5>
              Remaining Cash Balance: ${parseFloat(sumCashBalance).toFixed(2)}
            </h5>
            <h5>Keep Partial Payment?</h5>

            <Button
              variant="primary px-5 position-absolute form-cta-btn yes-right"
              type="button"
              onClick={() => partialFn()}
            >
              Yes
            </Button>
          </>
        }
        action={
          <Button
            variant="outline-blackcustom px-5 m-auto no-right"
            type="button"
            onClick={() => {
              if (payFacInitiateData.init) {
                handlePayFacRefund("lastPayment")
              } else (
                refundVoidFn("void")
              )
            }
            }
            className="no-btn"
          >
            No
          </Button>
        }
      />
      {/* Cancel Sale PopUp*/}
      <CustomModal
        backdrop={false}
        open={cancelTransaction}
        handleClose={() => setcancelTransaction(false)}
        dialogClassName="custom-modal confirmation verify-age"
        title="Confirmation"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.warningRed}
                alt="warning Icon"
              />
              Are you sure want to cancel the transaction?
            </h1>
            <Button
              variant="primary px-3 position-absolute form-cta-btn"
              type="button"
              onClick={(e) => {
                handleCancleSalePayment(e)
              }
              }
            >
              Yes
            </Button>
          </>
        }
        action={
          <Button
            variant="outline-blackcustom px-5 m-auto"
            onClick={() => {
              setcancelTransaction(false)
            }
            }
          >
            No
          </Button>
        }
      />

      {/* payfac abort payment  */}
      <CustomModal
        backdrop={false}
        open={payFacAbort}
        handleClose={() => setPayFacAbort(false)}
        dialogClassName="custom-modal confirmation verify-age"
        title="Confirmation"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.warningRed}
                alt="warning Icon"
              />
              Are you sure want to cancel the payment?
            </h1>
            <Button
              variant="primary px-3 position-absolute form-cta-btn"
              type="button"
              onClick={(e) => {
                setPayFacAbort(false)
                handleCancelPayfacPayment()
              }
              }
            >
              Yes
            </Button>
          </>
        }
        action={
          <Button
            variant="outline-blackcustom px-5 m-auto"
            onClick={() => {
              setPayFacAbort(false)
              setPayFacPaymentStatus({
                ...payFacPaymentStatus,
                check: true,
              })
            }
            }
          >
            No
          </Button>
        }
      />
      {/* Checkout PopUp*/}
      <CustomModal
        open={tenderError}
        handleClose={() => setTenderError(false)}
        dialogClassName="custom-modal confirmation error-popup"
        title="Alert"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.warning}
                alt="warning Icon"
              />
              Please enter tendered value before continuing
            </h1>
          </>
        }
        action={
          <>
            <Button
              type="button"
              onClick={() => setTenderError(false)}
              variant="primary px-5"
            >
              Okay
            </Button>
          </>
        }
      />
      {/* Print Recipt PopUp Cancel Sale Popup*/}
      <CustomModal
        open={printRecipt}
        handleClose={handleClosepopUp}
        dialogClassName="custom-modal confirmation verify-age"
        title="Confirmation"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.printBg}
                alt="print Icon"
              />
              Would you like to print a receipt?
            </h1>
            <Button
              variant="primary px-3 position-absolute form-cta-btn"
              type="button"
              onClick={(e) => handleVerifiedPayment(e)}
            >
              Yes
            </Button>
          </>
        }
        action={
          <Button
            variant="outline-blackcustom px-5 m-auto"
            onClick={handleClosepopUp}
          >
            No
          </Button>
        }
      />

      {/* Warning Popup*/}
      <CustomModal
        open={openErrorMsg}
        backdrop={false} // "static"
        handleClose={handleCloseThispopUp}
        dialogClassName={`custom-modal confirmation error-popup align-btn`}
        title={title}
        content={
          <>
            <div className="text-center">
              {showIcon && (
                <Image src={Cancel} alt="error icon" className="mb-3" />
              )}
            </div>
            <h1
              className="mb-3"
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              {errorMsg}
            </h1>
            {title === "Pax Error" && (
              <>
                <p style={{ margin: 0 }}>Possible Fixes Include:</p>
                <ol style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li>1) Completely close and re-open the POS application</li>
                  <li>2) Restart the Pinpad, then restart this computer</li>
                  <li>3) Call the Technical Support line ( 844 ) 919-2017</li>
                </ol>
              </>
            )}
          </>
        }
        action={
          <>
            {printRcpt && (
              <Button
                type="button"
                variant="primary px-5"
                onClick={() => {
                  handleVerifiedPayment()
                }}
              >
                Print Reciept
              </Button>
            )}
            <Button
              type="button"
              variant="light px-5"
              onClick={
                cardType != ""
                  ? handleFraud
                  : electronError
                    ? handleElectronPopUpClose
                    : handleCloseThispopUp
              }
            >
              OK
            </Button>
            {tbsBtn == true && (
              <Button
                type="button"
                variant="light px-5"
                onClick={paxTroubleShoot}
              >
                Troubleshoot
              </Button>
            )}
          </>
        }
      />

      {/* Confirm Popup*/}
      <CustomModal
        open={openErrorMsg1}
        backdrop={false}
        handleClose={handleRetryClosepopUp}
        dialogClassName={`custom-modal confirmation error-popup cancel-modal`}
        title={title}
        content={<h1 className="mb-3 justify-content-center">{errorMsg}</h1>}
        action={
          <>
            <Button
              type="button"
              variant="primary px-5"
              onClick={handleRetryPopUp}
            >
              Yes
            </Button>
            <Button
              type="button"
              variant="outline-blackcustom px-5"
              onClick={handleRetryClosepopUp}
            >
              No
            </Button>
          </>
        }
      />
    </>
  );
};


const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    salesitemList: state.saleItem.saleItemList,
    saleList: state.saleItem.saleList,
    saleItem: state.saleItem,
    totalAmounts: state.saleItem.totalAmount,
    promotionData: state.saleItem.promotionData,
    payment: state.saleItem.payment,
    additionalFee: state.inventory.additionalFee,
    taxList: state.inventory.tax,
    taxRules: state.inventory.taxRule,
    bottleDeposit: state.inventory.bottledeposit,
    posSetting: state.inventory.posSetting.data,
    accountSetting: state.inventory.accountSetting.data,
    genSetting: state.inventory.generalSetting.data,
    image: state.setting.images,
    generalSetting: state.setting.generalSetting.data,
    devices: state.inventory.devices,
    custlist: state.inventory.customerList,
    category: state.inventory.category,
    paxerror: state.saleItem.paxerror,
    graphReport: state.saleItem.graphData,
    completeSaleItemList: state.saleItem.completeSaleItemList,
    enterAge: state.saleItem.enterAge,
  };
};
export default connect(mapStateToprops)(Checkout);
