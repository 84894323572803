import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
// core styles
// import "./scss/volt.scss";
import "./scss/volt.scss";
// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";
import { Provider } from "react-redux";
import { store } from "./app/store";
import history from "./history/history";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ErrorBoundary } from "./config/bugSnag-config";
import ThemeContextWrapper from "./theme/ThemeWrapper";

// offline images
function importAll(r) {
  let images = [];
  r.keys().forEach((item, index) => {
    images[index] = r(item);
  });
  return images;
}


const images = importAll(
  require.context("./assets/images", false, /\.(png|jpe?g|svg)$/)
);

setTimeout(function () {
  if (images.length > 0) {
    for (const image of images) {
      const imageElement = new Image();
      imageElement.src = image;
    }
  }
}, 3000);
// offline images

ReactDOM.render(
  <ThemeContextWrapper>
    <ErrorBoundary>
      <Provider store={store}>
        <Router history={history}>
          <ScrollToTop />
          <HomePage />
        </Router>
      </Provider>
    </ErrorBoundary>
  </ThemeContextWrapper>,
  document.getElementById("root")
);
serviceWorkerRegistration.register();