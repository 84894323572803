import React from "react";
import ReactSelect, { components } from "react-select";
import { FirtsLetterUpperCase } from "../app/helpers/custom";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const ReactCustom = (props) => {
  const {
    openMenuOnClick,
    firstLetter,
    otherData,
    placement,
    formField,
    placeholder,
    options,
    value,
    onSelectChange,
    readOnly,
    upperCase,
    multi,
    stringData,
  } = props;

  return (
    <>
     
        <ReactSelect
          classNamePrefix="react-select"
          isMulti={multi}
          placeholder={placeholder}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          styles={{
            valueContainer: (provided, state) => ({
              ...provided,
              overflow: "visible",
            }),
            placeholder: (provided, state) => ({
              ...provided,
              position: "absolute",
              top: state.hasValue || state.selectProps.inputValue ? -15 : "7px",
              transition: "top 0.1s, font-size 0.1s",

              transform:
                (state.hasValue || state.selectProps.inputValue) &&
                "scale(0.85)",
              opacity: (state.hasValue || state.selectProps.inputValue) && 1,
            }),
          }}
          options={options?.map((option) => {
            const name = option?.name ? option?.name : option?.username;
            return {
              ...option,
              value: option?.id,
              label: upperCase
                ? name.toUpperCase()
                : firstLetter
                ? FirtsLetterUpperCase(name)
                : name,
            };
          })}
          value={
            multi
              ? Object.entries(value)?.length !== 0
                ? value
                : ""
              : options
                  ?.filter((option) => {
                    return (
                      option?.id === (stringData ? value : parseInt(value))
                    );
                  })
                  ?.map((list) => {
                    const name = list?.name ? list?.name : list?.username;
                    return {
                      ...list,
                      value: list?.id,
                      label: upperCase
                        ? name.toUpperCase()
                        : firstLetter
                        ? FirtsLetterUpperCase(name)
                        : name,
                    };
                  })
          }
          onChange={(e) =>
            onSelectChange(multi ? e : e.value, formField, otherData)
          }
          isDisabled={readOnly}
          placement={placement !== undefined ? placement : ""}
          openMenuOnClick={
            openMenuOnClick !== undefined ? openMenuOnClick : true
          }
        />
    </>
  );
};
export default ReactCustom;
