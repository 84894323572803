import { getFormateImages, getFormattedItemList } from "app/helpers/custom";
import { addSettingData } from "../../axios/authHeader";
import {
  ITEMLIST_GET_LIST,
  CATEGORY_GET_LIST,
  REFRESHREMOTEDATA,
  TAX_GET_LIST,
  LOADING,
  ACCOUNTSETTING,
  TAXRULE_GET_LIST,
  BOTTLEDEPOSIT,
  ADDITIONALFEE,
  GENERALSETTING,
  UPDATEGENERALSETTINGS,
  DEVICE_GET_LIST,
  STORE_GET_LIST,
  POSSETTING,
  UPDATEPOSSETTING,
  REPORT_GET_LIST,
  IMAGEUPLOAD,
  CUSTOMERDATA,
  REGISTERREPORT,
  CUSTOMER_GET_LIST,
  SUPPLIER_GET_LIST,
  ITEMTYPE_GET_LIST,
  TAG_GET_LIST,
  ITEMSIZE_GET_LIST,
  ITEMUPDATE,
  STAFFANDADMIN,
  CUSTOMERUPDATE,
  POSSIDEETTINGS_GET,
  ITEMADDED,
  EXPENSE_VENDOR_GET_LIST,
  EXPENSE_CATEGORY_GET_LIST,
  EXPENSETYPE_GET_LIST,
  DAYREPORT,
  GETITEMLIST,
  CLOCKIN,
  CLOCKOUT,
  UPDATE_ITEM_QTY_IN_HAND,
  CASHRECONCILIATION,
  ITEMUPDATELABELPRINT,
  CUSTOMER_REWARDS_GET_LIST,
  EXPENSE,
  PROMOTIONS_GET_LIST,
  PROMOTIONS,
  NEXTMODULE,
  STOCKCODE_GET_LIST,
  ITEMMOSIFIER_GET_LIST,
  MERGEITEMSPAYLOAD,
  ADDITIONALFEE_GET_LIST,
  ITEMTAG_GET_LIST,
  ITEM,
  ITEMUPDATEPUSHER,
  ITEMSALELIST_GET_LIST,
  UPDATELOTTODATA,
  UPDATELOTTOIDS,
  INITIALIZED_POPUP
} from "../helpers/constant";
import Localstorage from "../storage/Localstorage";

const initialState = {
  itemlist: [],
  itemlistraw: [],
  itemcodelist: [],
  itemmodifierlist: [],
  itemadditionalfeelist: [],
  itemtaggetlist: [],
  saleItemList: [],
  category: [],
  taxRule: [],
  bottledeposit: [],
  additionalFee: [],
  tax: [],
  accountSetting: {
    data: [],
  },
  stores: [],
  devices: [],
  generalSetting: {
    data: [],
  },
  posSetting: {
    data: [],
  },
  reportList: {},
  images: [],
  loading: false,
  registerReport: [],
  dayReport: [],
  customerList: [],
  customerRewardsList: [],
  tags: [],
  supplier: [],
  promotions: [],
  itemType: [],
  size: [],
  userList: [],
  expenseTypeList: [],
  expenseCategoryList: [],
  expenseVendorList: [],
  msg: "",
  success: 0,
  nextmodule: 1,
  disabled: true,
  initialize_popup: true,
  summaryReport: [],
  added: 0,
  updated: 0,
  closeRegisterSuccess: 0,
  labelPrintItem: {},
  lottoInformation: {},
  lottoIds: [],
};

// Reducer based on type of action and return state and payloads
export default function getListReducer(state = initialState, actions) {
  switch (actions.type) {
    case NEXTMODULE: {
      return {
        ...state,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case CASHRECONCILIATION:
      return {
        ...state,
        closeRegisterSuccess: Math.random(),
      };
    case ITEMLIST_GET_LIST:
      return {
        ...state,
        itemlistraw: actions.payload.data?.data,
        nextmodule: actions.payload.nextmodule,
      };
    case STOCKCODE_GET_LIST:
      return {
        ...state,
        itemcodelist: actions.payload.data?.data,
        nextmodule: actions.payload.nextmodule,
      };
    case ITEMMOSIFIER_GET_LIST:
      return {
        ...state,
        itemmodifierlist: actions.payload.data?.data,
        nextmodule: actions.payload.nextmodule,
      };
    case ADDITIONALFEE_GET_LIST:
      return {
        ...state,
        itemadditionalfeelist: actions.payload.data?.data,
        nextmodule: actions.payload.nextmodule,
      };
    case ITEMTAG_GET_LIST:
      return {
        ...state,
        itemtaggetlist: actions.payload.data?.data,
        nextmodule: actions.payload.nextmodule,
      };
    case MERGEITEMSPAYLOAD: {
      let itemlist = getFormattedItemList(
        state.itemlistraw,
        state.itemmodifierlist,
        state.itemcodelist,
        state.itemadditionalfeelist,
        state.itemtaggetlist
      );

      return {
        ...state,
        itemlist,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case ITEMUPDATEPUSHER: {
      return {
        ...state,
        itemlist: actions.payload.data,
      };
    }
    case UPDATE_ITEM_QTY_IN_HAND: {
      let items = actions.payload;
      let itemlist = state.itemlist;
      for (let x of items) {
        let index = itemlist.findIndex((e) => e.id === x.id);
        if (index >= 0) {
          itemlist[index] = {
            ...itemlist[index],
            totalitem: itemlist[index].totalitem - x.qty,
          };
        }
      }
      return {
        ...state,
        itemlist,
      };
    }
    case CATEGORY_GET_LIST:
      return {
        ...state,
        category: actions.payload.data,
        nextmodule: actions.payload.nextmodule,
      };
    case TAXRULE_GET_LIST:
      return {
        ...state,
        taxRule: actions.payload.data,
        nextmodule: actions.payload.nextmodule,
      };
    case DEVICE_GET_LIST:
      return {
        ...state,
        devices: actions.payload.data,
        nextmodule: actions.payload.nextmodule || 2,
      };
    case STORE_GET_LIST:
      {
        let stores = actions.payload.data;

        return {
          ...state,
          stores: stores,
          nextmodule: actions.payload.nextmodule || 2,
        };
      }
    case TAX_GET_LIST:
      return {
        ...state,
        tax: actions.payload.data,
        nextmodule: actions.payload.nextmodule,
      };
    case BOTTLEDEPOSIT:
      return {
        ...state,
        bottledeposit: actions.payload.data,
        nextmodule: actions.payload.nextmodule,
      };
    case ADDITIONALFEE:
      return {
        ...state,
        additionalFee: actions.payload.data,
        nextmodule: actions.payload.nextmodule,
      };
    case ACCOUNTSETTING:
      addSettingData("accountSetting", actions.payload.data[0].data);
      return {
        ...state,
        accountSetting: {
          data: JSON.parse(actions.payload.data[0].data),
        },
        nextmodule: actions.payload.nextmodule,
      };
    case POSSETTING:
      addSettingData("posSetting", actions.payload.data[0].data);
      return {
        ...state,
        posSetting: {
          data: JSON.parse(actions.payload.data[0].data),
        },
        nextmodule: actions.payload.nextmodule,
      };
    case UPDATEPOSSETTING:
      addSettingData("posSetting", JSON.stringify(actions.payload));
      return {
        ...state,
        posSetting: {
          data: actions.payload,
        },
      };
    case GENERALSETTING:
      addSettingData("generalSetting", actions.payload.data[0].data);
      return {
        ...state,
        generalSetting: {
          data: JSON.parse(actions.payload.data[0].data),
        },
        nextmodule: actions.payload.nextmodule,
      };
    case UPDATEGENERALSETTINGS:
      return {
        ...state,
        generalSetting: actions.payload,
      };
    case REPORT_GET_LIST:
      return {
        ...state,
        reportList: actions.payload.data,
        loading: false,
      };
    case REGISTERREPORT:
      return {
        ...state,
        registerReport: actions.payload.data,
      };
    case DAYREPORT:
      return {
        ...state,
        dayReport: actions.payload.data,
      };
    case IMAGEUPLOAD: {
      let formateImages = getFormateImages(actions.payload.data);
      addSettingData("images", JSON.stringify(formateImages));
      return {
        ...state,
        images: formateImages,
        nextmodule: actions.payload.nextmodule
      };
    }
    case INITIALIZED_POPUP:
      return {
        ...state,
        initialize_popup: actions.status
      }
    case CUSTOMER_GET_LIST: {
      addSettingData("customer", JSON.stringify(actions.payload.data.data));
      return {
        ...state,
        customerList: actions.payload.data?.data,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case CUSTOMER_REWARDS_GET_LIST: {
      // addSettingData("customer", JSON.stringify(actions.payload.data.data));
      return {
        ...state,
        customerRewardsList: actions.payload.data?.data,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case CUSTOMERUPDATE: {
      return {
        ...state,
        loading: false,
        updated: actions.payload,
      };
    }
    case STAFFANDADMIN: {
      addSettingData("usersList", JSON.stringify(actions.payload.data.data));
      return {
        ...state,
        userList: actions.payload.data.data,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case SUPPLIER_GET_LIST: {
      return {
        ...state,
        supplier: actions.payload.data,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case PROMOTIONS_GET_LIST: {
      return {
        ...state,
        promotions: actions.payload.data,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case PROMOTIONS: {
      return {
        ...state,
        promotions: actions.payload,
      };
    }
    case POSSIDEETTINGS_GET: {
      addSettingData("wpos_config", actions.payload.data[0].data);
      return {
        ...state,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case ITEMSIZE_GET_LIST: {
      return {
        ...state,
        size: actions.payload.data,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case ITEMTYPE_GET_LIST: {
      return {
        ...state,
        itemType: actions.payload.data,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case TAG_GET_LIST: {
      return {
        ...state,
        tags: actions.payload.data,
        nextmodule: actions.payload.nextmodule,
      };
    }
    case CUSTOMERDATA: {
      return {
        ...state,
        loading: false,
        added: actions.payload,
      };
    }
    case ITEMUPDATE: {
      const itemlist = [...state.itemlist];
      const Index = itemlist.findIndex(
        (data) => data.id === actions.payload.data.data.id
      );
      itemlist[Index] = actions.payload.data.data;
      return {
        ...state,
        itemlist,
        loading: false,
        msg: "itemUpdated",
      };
    }
    case ITEMUPDATELABELPRINT: {
      console.log(`state`, state);
      console.log(`action`, actions.payload);
      return {
        ...state,
        labelPrintItem: actions.payload.data,
      };
    }
    case ITEMADDED: {
      const itemlist = [...state.itemlist];
      itemlist.push(actions.payload.data.data);
      return {
        ...state,
        itemlist,
        loading: false,
        msg: "itemAdded",
      };
    }
    case REFRESHREMOTEDATA:
      return {
        ...state,
        nextmodule: actions.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: actions.payload.loading,
        msg: actions.payload.msg,
      };
    case EXPENSE_VENDOR_GET_LIST:
      return {
        ...state,
        expenseVendorList: actions.payload.data,
        disabled: false,
      };
    case EXPENSE_CATEGORY_GET_LIST:
      return {
        ...state,
        expenseCategoryList: actions.payload.data,
        disabled: false,
      };
    case EXPENSETYPE_GET_LIST:
      return {
        ...state,
        expenseTypeList: actions.payload.data,
        disabled: false,
      };
    case EXPENSE:
      {
        let list = Localstorage.getItem("wpos_cpayouts") || [];
        list.push(actions.payload.data);
        addSettingData("wpos_cpayouts", JSON.stringify(list));
        return {
          ...state,
          success: Math.random(),
        };
      }
    // case DAYREPORT:
    //   return {
    //     ...state,
    //     datReport: actions.payload.data,
    //   };
    case GETITEMLIST:
      return {
        ...state,
        saleItemList: actions.payload.data,
      };
    case CLOCKIN:
      localStorage.setItem(
        "clockInOutDetails",
        JSON.stringify({
          clockInDetails: actions.payload.data,
          clockOutDetails: false,
        })
      );
      return {
        ...state,
      };
    case CLOCKOUT:
      localStorage.setItem(
        "clockInOutDetails",
        JSON.stringify({
          clockOutDetails: actions.payload.data,
          clockInDetails: false,
        })
      );
      return {
        ...state,
      };
    case ITEM: {
      return {
        ...state,
        loading: false,
      };
    }
    case ITEMSALELIST_GET_LIST:
      return {
        ...state,
        nextmodule: actions.payload.nextmodule,
      };
    // case IMAGEUPLOAD:
    //   return {
    //     ...state,
    //     nextmodule: actions.payload.nextmodule,
    //   };
    case UPDATELOTTODATA:
      return {
        ...state,
        lottoInformation: actions.payload
      }
    case UPDATELOTTOIDS:
      return {
        ...state,
        lottoIds: actions.payload
      }
    default: {
      return state;
    }
  }
}
