import React, { useState, useEffect } from "react";
// Data tables
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
// import { salestable } from "../../data/tables";
import { Card } from "@themesberg/react-bootstrap";
import FormField from "../components/formField";
import PropTypes from 'prop-types';
import CustomScroll from "react-custom-scroll";

export const RefundTransaction = (props) => {
  const [items, setitems] = useState([]);
  useEffect(() => {
    setitems(props.items || []);
  }, [props?.items]);
  const returnedFormater = (cell, row) => {
    return (
      // <input
      //   type="number"
      //   autoFocus={focus === "unit_per_case" && true}
      //   className="form-control"
      //   value={row.unit_per_case}
      //   onChange={(e) => handleChangeUpdate(e, "unit_per_case", row)}
      //   onBlur={handleFocus}
      // />
      <FormField
        name="notes"
        value={row.qty}
        placeholder=""
        // onChange={(e) => setNotes(e.target.value)}
        disabled
        style={{ maxWidth: "none" }}
      />
    );
  };

  const nameFormater = (cell, row) => {
    return (
      <>
        {row.qty} * {row.name} ($ {row.price})
      </>
    );
  };

  const columns = [
    {
      dataField: "qty",
      text: "# Returned",
      formatter: returnedFormater,
      sort: true,
      attrs: {
        "data-title": "# Returned",
      },
    },
    {
      dataField: "name",
      text: "Item",
      sort: true,
      attrs: {
        "data-title": "Item",
      },
      formatter: nameFormater,
    },
  ];
  return (
    <>
      <Card className="custom-card no-checkbox no-box-shadow border-0  column-visibility-table auto-width">
        <div className="with-scroll-on-table">
          <ToolkitProvider
            key="Id"
            keyField="id"
            data={items}
            columns={columns}
          >
            {(props) => (
              <>
                <div className="pagination-wrapper">
                  <CustomScroll
                    addScrolledClass
                    heightRelativeToParent="calc(100% - 0px)"
                  >
                    <BootstrapTable
                      bootstrap4
                      headerClasses="table-header"
                      pagination={false}
                      keyField="Id"
                      key="Id"
                      {...props.baseProps}
                      dataColumn={items.title}
                      defaultSorted={false}
                    />
                  </CustomScroll>
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      </Card>
    </>
  );
};
RefundTransaction.propTypes = {
  items: PropTypes.array,
  baseProps: PropTypes.object,
};