import actions from "../../assets/images/CStorePOS/actions.svg";
import ActivateCard from "../../assets/images/CStorePOS/activate-card.svg";
import DeactivateCard from "../../assets/images/CStorePOS/deactivate-card.svg";
import add from "../../assets/images/CStorePOS/add.svg";
import AddBalance from "../../assets/images/CStorePOS/add-balance.svg";
import allCategories from "../../assets/images/CStorePOS/all-categories.svg";
import backBlue from "../../assets/images/CStorePOS/back-blue.svg";
import bckBtn from "../../assets/images/CStorePOS/back-btn-green.svg";
import banIcon from "../../assets/images/CStorePOS/ban.svg";
import barcodeIcon from "../../assets/images/CStorePOS/barcode.png";
import cancel from "../../assets/images/CStorePOS/cancel.png";
import cart from "../../assets/images/CStorePOS/cart-arrow-down.svg";
import sideCard from "../../assets/images/CStorePOS/cash-icon.svg";
import check from "../../assets/images/CStorePOS/check.png";
import check1 from "../../assets/images/CStorePOS/check1.svg";
import CheckBalance from "../../assets/images/CStorePOS/check-balance.svg";
import LogoutIcon from "../../assets/images/CStorePOS/check-circle.svg";
import close from "../../assets/images/CStorePOS/close.png";
import cost from "../../assets/images/CStorePOS/cost.svg";
import cross from "../../assets/images/CStorePOS/cross.svg";
import crossIcon from "../../assets/images/CStorePOS/cross-screen.svg";
import customer from "../../assets/images/CStorePOS/customer1.svg";
import dayReport from "../../assets/images/CStorePOS/day-report.svg";
import debit from "../../assets/images/CStorePOS/debit.svg";
import deleteIcon from "../../assets/images/CStorePOS/delete.svg";
import delivery from "../../assets/images/CStorePOS/delivery.png";
import details from "../../assets/images/CStorePOS/details.svg";
import ebt from "../../assets/images/CStorePOS/ebt.svg";
import edit from "../../assets/images/CStorePOS/edit.svg";
import ExpanIcon from "../../assets/images/CStorePOS/expand.svg";
import EyeIcon from "../../assets/images/CStorePOS/eye-btn.svg";
import EyeIconCLosed from "../../assets/images/CStorePOS/eye-btn-closed.svg";
import faCross from "../../assets/images/CStorePOS/faCross.svg";
import faPLus from "../../assets/images/CStorePOS/faPlus.svg";
import general from "../../assets/images/CStorePOS/general.svg";
import logo from "../../assets/images/CStorePOS/get.png";
import giftCard from "../../assets/images/CStorePOS/gist-card.svg";
import hamburger from "../../assets/images/CStorePOS/hamburger.svg";
import hamburger2 from "../../assets/images/CStorePOS/hamburger-2.svg";
import ClockIcon from "../../assets/images/CStorePOS/Icon-awesome-clock.svg";
import AdminIcon from "../../assets/images/CStorePOS/Icon-awesome-user-cog.svg";
import FullscreenIcon from "../../assets/images/CStorePOS/Icon-map-fullscreen.svg";
import PrintLabelIcon from "../../assets/images/CStorePOS/Icon-material-label.svg";
import listIcon from "../../assets/images/CStorePOS/Icon-metro-list.svg";
import LogoutIcon1 from "../../assets/images/CStorePOS/Icon-open-account-logout.svg";
import items from "../../assets/images/CStorePOS/items1.svg";
import logo1 from "../../assets/images/CStorePOS/logo-new.svg";
import minus from "../../assets/images/CStorePOS/minus.svg";
import miscallenous from "../../assets/images/CStorePOS/miscallenous.svg";
import moneyOff from "../../assets/images/CStorePOS/money-off.svg";
import more from "../../assets/images/CStorePOS/more.svg";
import noData from "../../assets/images/CStorePOS/no-data-found.svg";
import offers from "../../assets/images/CStorePOS/offers.svg";
import options from "../../assets/images/CStorePOS/options.svg";
import payments from "../../assets/images/CStorePOS/payments.svg";
import PlusAdd from "../../assets/images/CStorePOS/plus-add.svg";
import plus from "../../assets/images/CStorePOS/plus-green.svg";
import print from "../../assets/images/CStorePOS/print.svg";
import printBg from "../../assets/images/CStorePOS/print-big.svg";
import printIcon from "../../assets/images/CStorePOS/print-icon.svg";
import printing from "../../assets/images/CStorePOS/printing.svg";
import promotions from "../../assets/images/CStorePOS/promotions.svg";
import redeem from "../../assets/images/CStorePOS/reddem.svg";
import refundCash from "../../assets/images/CStorePOS/refund-cash.svg";
import refund from "../../assets/images/CStorePOS/refund-icon.svg";
import refundCard from "../../assets/images/CStorePOS/refund-on-card.svg";
import registerReports from "../../assets/images/CStorePOS/register-reports.svg";
import remove from "../../assets/images/CStorePOS/remove.svg";
import sellerReports from "../../assets/images/CStorePOS/seller.svg";
import defaultImage from "../../assets/images/CStorePOS/shop-with-us.svg";
import shortcutIcon from "../../assets/images/CStorePOS/shortcut-icon.svg";
import BgImage from "../../assets/images/CStorePOS/signin-bg-cstore.jpeg";
import whatsSelling from "../../assets/images/CStorePOS/star.svg";
import takingsCOunt from "../../assets/images/CStorePOS/takings-count.svg";
import adminIcon from "../../assets/images/CStorePOS/user.png";
import warning from "../../assets/images/CStorePOS/warning.svg";
import warningRed from "../../assets/images/CStorePOS/warning-red.svg";
import logoWhite from "../../assets/images/CStorePOS/logo-new-white.svg";
import ratings from "../../assets/images/CStorePOS/ratings.svg";
import minusIcon from "../../assets/images/CStorePOS/circle-minus.svg";
import plusIcon from "../../assets/images/CStorePOS/circle-plus.svg";
import giftIcon from "../../assets/images/CStorePOS/gift-icon.svg";
import discount from "../../assets/images/CStorePOS/discount.svg";
import logout from "../../assets/images/logout.svg";
import settings from "../../assets/images/CStorePOS/settings.svg";
import register from "../../assets/images/CStorePOS/register.png";
import weborders from "../../assets/images/CStorePOS/weborders.png";
import reports from "../../assets/images/CStorePOS/reports.svg";
import pos from "../../assets/images/CStorePOS/pos_sales.svg";
import tag from "../../assets/images/CStorePOS/tag.svg";

import advancedUpdate from "../../assets/images/CStorePOS/advanced-update.svg";



export const CStorePOSImg = () => {
  const Img = {
    advancedUpdate:advancedUpdate,
    tag:tag,
    pos:pos,
    reports:reports,
    weborders:weborders,
    register:register,
    settings:settings,
    logout:logout,
    discount:discount,
    ratings: ratings,
    plusIcon:plusIcon,
    minusIcon:minusIcon,
    logoWhite:logoWhite,
    hamburger2:hamburger2,
    warningRed:warningRed,
    warning:warning,
    adminIcon:adminIcon,
    takingsCOunt:takingsCOunt,
    whatsSelling:whatsSelling,
    BgImage:BgImage,
    shortcutIcon:shortcutIcon,
    defaultImage:defaultImage,
    sellerReports:sellerReports,
    remove:remove,
    registerReports:registerReports,
    refundCash:refundCash,
    redeem:redeem,
    promotions:promotions,
    printing:printing,
    printIcon:printIcon,
    printBg:printBg,
    print:print,
    plus:plus,
    PlusAdd:PlusAdd,
    payments:payments,
    options:options,
    offers:offers,
    noData:noData,
    more:more,
    moneyOff:moneyOff,
    miscallenous:miscallenous,
    minus:minus,
     actions:actions,
     ActivateCard:ActivateCard,
     DeactivateCard:DeactivateCard,
     add:add,
     AddBalance:AddBalance,
     allCategories:allCategories,
     backBlue:backBlue,
     bckBtn:bckBtn,
     banIcon:banIcon,
     barcodeIcon:barcodeIcon,
     cancel:cancel,
     cart:cart,
     sideCard:sideCard,
     check:check,
     check1:check1,
     CheckBalance:CheckBalance,
     LogoutIcon:LogoutIcon,
     close:close,
     cost:cost,
     cross:cross,
     crossIcon:crossIcon,
     customer:customer,
     dayReport:dayReport,
     debit:debit,
     deleteIcon:deleteIcon,
     delivery:delivery,
     details:details,
     ebt:ebt,
     edit:edit,
     ExpanIcon:ExpanIcon,
     EyeIcon:EyeIcon,
     EyeIconCLosed:EyeIconCLosed,
     faCross:faCross,
     faPLus:faPLus,
     general:general,
     logo:logo,
     giftCard:giftCard,
     hamburger:hamburger,
     ClockIcon:ClockIcon,
     AdminIcon:AdminIcon,
     FullscreenIcon:FullscreenIcon,
     PrintLabelIcon:PrintLabelIcon,
     listIcon:listIcon,
     LogoutIcon1:LogoutIcon1,
     items:items,
     logo1:logo1,
     refund:refund,
     refundCard:refundCard,
     giftIcon:giftIcon,
  };
  return Img;
};

export default CStorePOSImg;
