import React, { useEffect, useState } from "react";
import { Button, Row, Col, Image } from "@themesberg/react-bootstrap";
import { SortableContainer, arrayMove } from "react-sortable-hoc";
import { connect, useDispatch } from "react-redux";
import ToDoList from "./ToDoList";
import {
  ADDITEMONSALE,
  AGEPOPUPSHOW,
  SHORTCUT,
  NUMPADSTATUS,
  SHORTCUTVIEW,
  LOTTOPAYOUTTYPE,
  ONLINEPAYOUTTYPE,
  LOTTOSALETYPE,
  ONLINELOTTERYTYPE,
} from "../../../../app/helpers/constant";
import {
  AddSaleItemList,
  AgePopUpShow,
  ShowNumpadStatus,
} from "../../../../app/actions/saleItemAction";
import getListService from "../../../../app/services/getListService";
import PropTypes from 'prop-types';
import CustomModal from "pages/components/Modals";

const SortableList = SortableContainer(ToDoList);

const Categories = (props) => {
  const [shortcutItems, setShortcutItems] = useState([]);
  const [show , setShow] = useState(false);
  const [ListType, setListType] = useState("");
  const dispatch = useDispatch();
  const [, setIsLottoButtonEnabled] = useState(false);
  const [, setLottoItems] = useState([]);
  const [LottoIds, setLottoIds] = useState([]);

  useEffect(() => {
    setIsLottoButtonEnabled(
      props?.PosSetting?.data?.OPTION?.enable_lotto_buttons === "1"
    );
    setLottoIds([
      parseInt(props?.PosSetting?.data?.OPTION?.lotto_payout_item),
      parseInt(props?.PosSetting?.data?.OPTION?.lotto_sale_item),
      parseInt(props?.PosSetting?.data?.OPTION?.online_payout_item),
      parseInt(props?.PosSetting?.data?.OPTION?.online_lottery_item),
    ]);
    // FetchData();
  }, [props?.posSetting]);

  useEffect(() => {
    if (props?.ListType !== undefined) {
      let filterItems;
      if (props?.ListType.type === "shortcutkey") {
        filterItems = props?.itemList
          .filter((item) => item.shortcutkeys === true)
          .sort((a, b) => {
            a.item_shortcut_no = a.item_shortcut_no ? a.item_shortcut_no : "";
            b.item_shortcut_no = b.item_shortcut_no ? b.item_shortcut_no : "";
            return a.item_shortcut_no.localeCompare(b.item_shortcut_no);
          });
      } else if (props?.ListType.type === "item") {
        filterItems = props?.itemList;
      } else if (props?.ListType.type === "category") {
        filterItems = props?.itemList.filter(
          (item) => item.category === parseInt(props.ListType.id)
        );
      } else if (props?.ListType.type === "miscellaneous") {
        filterItems = props?.itemList.filter(
          (item) => item.category === null || item.category === ""
        );
      }
      setShortcutItems(filterItems);
      setListType(props?.ListType.type);
    }
    if (props?.itemList.length > 0) {
      setLottoItems(
        props?.itemList.filter((item) => LottoIds.includes(item.id))
      );
    }
  }, [props.ListType, props.itemList, LottoIds, props?.posSetting]);

  function isDark(color) {
    var match = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return match
      ? parseFloat(match[1]) + parseFloat(match[2]) + parseFloat(match[3]) <
        (3 * 256) / 2
        ? "white"
        : "black"
      : "black"; // r+g+b should be less than half of max (3  256)
  }

  const todosInit = shortcutItems.map((item) => {
    return {
      id: item?.id,
      content:
        props?.ListType.type === "shortcutkey" &&
          item?.item_shortcut_name !== "" &&
          item?.item_shortcut_name !== undefined &&
          item?.item_shortcut_name !== null
          ? item?.item_shortcut_name
          : item?.name,
      bgcolor: item?.color_code,
      contentColor: isDark(item?.color_code),
      itemdetails: item,
      isDone: false
    };
  });


  const onSortEnd = (e) => {
    let newIndex = shortcutItems.findIndex(it => it.id == e.newIndex)
    let oldIndex = shortcutItems.findIndex(it => it.id == e.oldIndex)
    var newTodos = arrayMove(shortcutItems, oldIndex, newIndex);
    newTodos = newTodos.filter(item => item !== undefined);
    const UpdateList = newTodos.map((item, index) => {
      return {
        ids: item?.id,
        item_shortcut_no: index + 1,
      };
    });
    getListService.UpdateData(SHORTCUT, UpdateList);
    setShortcutItems(newTodos);
  };
  const key = [
    {
      lift: [32],
      drop: [32],
      cancel: [27],
      up: [38, 37],
      down: [40, 39],
    },
  ];
  const showPurchasePrompt = (id) => {
    let total = props?.totalAmount?.Total
    const data = props?.itemList.filter((item) => item.id === parseInt(id));
    if (total == 0 && data[0]?.item_type == 13) {
      setShow(true);
      return;
    }

    dispatch(AddSaleItemList(data[0], ADDITEMONSALE));
    dispatch(ShowNumpadStatus(false, NUMPADSTATUS));
    dispatch(AgePopUpShow(true, AGEPOPUPSHOW));
  };

  return (
    <>
      <Button
        className="back-btn"
        variant="link"
        onClick={() => {
          dispatch({ payload: false, type: SHORTCUTVIEW })
          props.setShowDataType(false)
        }}
      >
        <Image className="me-2" src={props?.dynamicImages?.bckBtn} alt="logo" />
      </Button>
      {ListType === "shortcutkey" && (
        <SortableList
          items={todosInit}
          onSortEnd={onSortEnd}
          distance={1}
          axis="xy"
          lockToContainerEdges={true}
          key={key}
          dragOrDrop={props?.dragOrDrop}
        />
      )}
      {(ListType === "category" ||
        ListType === "item" ||
        ListType === "miscellaneous") && (
          <>
            <Row className="category-list">
              {todosInit.map((x, i) => {
                if (![11, LOTTOPAYOUTTYPE, ONLINEPAYOUTTYPE, ONLINELOTTERYTYPE, LOTTOSALETYPE].includes(x.itemdetails.item_type)) {
                  return (
                    <Col
                      md={4}
                      xs={6}
                      key={x.id}
                      className="mb-3"
                      onClick={() => showPurchasePrompt(x.id)}
                    >
                      <div className="wrapper" title={x.content}>
                        <h3>{x.content}</h3>
                      </div>
                    </Col>
                  );
                }
              })}
            </Row>
          </>
        )}

      <CustomModal
        open={show}
        handleClose={() => setShow(false)}
        dialogClassName="custom-modal confirmation error-popup"
        title="Error"
        content={<h1 className="mb-3">{"Please add minimum one item to apply percentage discount item!"}</h1>}
        action={
          <Button
            type="button"
            onClick={() => setShow(false)}
            variant="danger px-5 m-auto"
          >
            OK
          </Button>
        }
      />
    </>
  );
};

Categories.propTypes = {
  PosSetting: PropTypes.shape({
    data: PropTypes.shape({
      OPTION: PropTypes.shape({
        enable_lotto_buttons: PropTypes.string,
        lotto_payout_item: PropTypes.string,
        lotto_sale_item: PropTypes.string,
        online_payout_item: PropTypes.string,
        online_lottery_item: PropTypes.string,
      }),
    }),
  }),
  posSetting: PropTypes.array,
  itemList: PropTypes.array,
  dragOrDrop: PropTypes.bool,
  setShowDataType: PropTypes.func,
  dynamicImages: PropTypes.shape({
    bckBtn: PropTypes.string,
  }),
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    category: state.inventory.category,
    PosSetting: state.inventory.posSetting,
    totalAmount: state.saleItem.totalAmount,
  };
};
export default connect(mapStateToprops)(Categories);
