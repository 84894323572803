import React from "react";
// Data table
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
// import CustomScroll from "react-custom-scroll";
import { Image } from "@themesberg/react-bootstrap";
import noData from "../../assets/images/no-data-found.svg";
import PropTypes from 'prop-types';

const BootsrapTable2 = ({
  columns,
  cellEdit,
  data,
  exportCSVToggle,
  cellEditToggle,
  rowStyle,
  rowClasses,
  SearchBarToggle,
  PaginationOptions,
  keyField,
  keyData,
  pagination,
  noDataIndication,
  expandRow,
  remote,
  onTableChange,
}) => {
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  return (
    <>
      {data.length > 0 ? (
        // <CustomScroll
        //   addScrolledClass
        //   heightRelativeToParent="calc(100% - 0px)"
        // >
        <ToolkitProvider
          keyField={keyField}
          data={data}
          columns={columns}
          exportCSV
          key={keyData}
          search
        >
          {(props) => (
            <>
              {SearchBarToggle && (
                <>
                  <div className="d-flex d-justify-content-between flex-column flex-md-row  align-items-md-center mb-3 px-3">
                    <div className="search-bar">
                      <SearchBar {...props.searchProps}/>
                      {props.searchProps?.searchText?.length > 0 && (
                        <ClearSearchButton
                          className="clear"
                          {...props.searchProps}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              {exportCSVToggle && (
                <ExportCSVButton {...props.csvProps}>
                  Export CSV
                </ExportCSVButton>
              )}
              <div className="pagination-wrapper">
                <BootstrapTable
                  bootstrap4
                  headerClasses="table-header"
                  keyField={keyField}
                  key={keyData}
                  pagination={
                    pagination ? paginationFactory(PaginationOptions) : null
                  }
                  expandRow={expandRow}
                  noDataIndication={
                    noDataIndication ? (
                      <div className="empty-data">
                        <Image src={noData} alt="No Data Found Icon" /> No Data
                        Found
                      </div>
                    ) : (
                      ""
                    )
                  }
                  {...props.baseProps}
                  dataColumn={data.title}
                  rowStyle={rowStyle}
                  rowClasses={rowClasses}
                  remote={remote}
                  onTableChange={onTableChange}
                  cellEdit={
                    cellEditToggle &&
                    cellEditFactory({
                      mode: "click",
                      autoSelectText: true,
                      blurToSave: true,
                    })
                  }
                />
              </div>
            </>
          )}
        </ToolkitProvider>
      ) : (
        // </CustomScroll>
        <div className="no-data">
          <ToolkitProvider
            keyField={keyField}
            data={data}
            columns={columns}
            exportCSV
            key={keyData}
          >
            {(props) => (
              <>
                {SearchBarToggle && (
                  <>
                    <div className="d-flex d-justify-content-between flex-column flex-md-row  align-items-md-center mb-3 px-3">
                      <div className="search-bar">
                        <SearchBar {...props.searchProps} />
                        {props.searchProps?.searchText?.length > 0 && (
                          <ClearSearchButton
                            className="clear"
                            {...props.searchProps}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
                {exportCSVToggle && (
                  <ExportCSVButton {...props.csvProps}>
                    Export CSV
                  </ExportCSVButton>
                )}
                <div className="pagination-wrapper">
                  <BootstrapTable
                    bootstrap4
                    headerClasses="table-header"
                    keyField={keyField}
                    key={keyData}
                    pagination={
                      pagination ? paginationFactory(PaginationOptions) : null
                    }
                    expandRow={expandRow}
                    noDataIndication={
                      noDataIndication ? (
                        <div className="empty-data">
                          <Image src={noData} alt="No Data Found Icon" /> No
                          Data Found
                        </div>
                      ) : (
                        ""
                      )
                    }
                    {...props.baseProps}
                    dataColumn={data.title}
                    remote={remote}
                    onTableChange={onTableChange}
                    rowStyle={rowStyle}
                    cellEdit={
                      cellEditToggle &&
                      cellEditFactory({
                        mode: "click",
                        autoSelectText: true,
                        blurToSave: true,
                      })
                    }
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      )}
    </>
  );
};
BootsrapTable2.propTypes = {
  columns: PropTypes.array,
  cellEdit: PropTypes.object,
  data: PropTypes.array, 
  exportCSVToggle: PropTypes.func,
  cellEditToggle: PropTypes.func,
  rowStyle: PropTypes.func, 
  rowClasses: PropTypes.func, 
  SearchBarToggle: PropTypes.bool, 
  PaginationOptions: PropTypes.object, 
  keyField: PropTypes.string, 
  expandRow: PropTypes.object, 
  remote: PropTypes.object, 
  onTableChange: PropTypes.func,
  searchProps: PropTypes.shape({
    searchText: PropTypes.string,
  }),
  csvProps: PropTypes.object,
  baseProps: PropTypes.object,
};
export default BootsrapTable2;
