import React, { useEffect, useState, lazy, Suspense } from "react";
import { Button, Col, Row } from "@themesberg/react-bootstrap";

import WebOrdersTable from "./WebOrdersTable";
import PrintLabel from "../printLabel";
import { CommonPusherComponent } from "../components/commonPusherConponent";
import LoaderSpinner from "components/Loader";

const WebOrders = (props) => {
  return (
    <>
      <PrintLabel />

      <CommonPusherComponent />
      <Row className="mb-3">
        <Col>
          <div className="custom-data-table">
            <WebOrdersTable />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default WebOrders;
