
const itemtable = [
  {
    id: "1",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
  {
    id: "2",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
  {
    id: "3",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
  {
    id: "3",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
  {
    id: "4",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
  {
    id: "5",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
  {
    id: "6",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
  {
    id: "7",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
  {
    id: "8",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
  {
    id: "9",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
  {
    id: "10",
    Qty: "1",
    Name: "Cocal cola",
    Price: "10",
    StockCode: "232323231",
    Tax: "GST",
    Total: "1",
    Options: "1",
    actions: "Actions",
  },
];
const tasklisttable = [
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
  {
    TaskList: "BOTTLE",
    DateTime: "2/17/2022 03:30: PM",
    Status: "Pending",
    actions: "Actions",
  },
];
const salestable = [
  {
    GID: "261035",
    Ref: "1",
    DeviceLocation: "Register1 / Inventory",
    CustomerName: "UNKNOWN",
    Mobile: "N/A",
    Items: "1",
    Total: "$59.68",
    SaleTime: "2022-3-28 08:12:36",
    Status: "Complete",
    Actions: "Actions",
  },
 
];

const countReport = [
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
  {
    Method: "Cash",
    Payments: "4",
    Takings: "$290.37",
    Refunds: "0",
    Refunds1: "$0.00",
    Payout: "0",
    Payout1: "$0.00",
    Balance: "$290.37",
  },
];

const itemsTable = [
  {
    Qty: 1,
    Name: "10 SPAN CHARDONNAY 750ML",
    Price: "$14.99",
    Tax: "$0.85 (HighTax 8.5%)",
    Total: "$14.99",
  },
  {
    Qty: 1,
    Name: "10 SPAN CHARDONNAY 750ML",
    Price: "$14.99",
    Tax: "$0.85 (HighTax 8.5%)",
    Total: "$14.99",
  },
  {
    Qty: 1,
    Name: "10 SPAN CHARDONNAY 750ML",
    Price: "$14.99",
    Tax: "$0.85 (HighTax 8.5%)",
    Total: "$14.99",
  },
  {
    Qty: 1,
    Name: "10 SPAN CHARDONNAY 750ML",
    Price: "$14.99",
    Tax: "$0.85 (HighTax 8.5%)",
    Total: "$14.99",
  },
  {
    Qty: 1,
    Name: "10 SPAN CHARDONNAY 750ML",
    Price: "$14.99",
    Tax: "$0.85 (HighTax 8.5%)",
    Total: "$14.99",
  },
];
const paymentsTable = [
  {
    Method: "Cash",
    Amount: "$14.99",
    Tendered: "$14.99",
    Change: "$0.00",
    Date: "9/7/20",
  },
  {
    Method: "Cash",
    Amount: "$14.99",
    Tendered: "$14.99",
    Change: "$0.00",
    Date: "9/7/20",
  },
  {
    Method: "Cash",
    Amount: "$14.99",
    Tendered: "$14.99",
    Change: "$0.00",
    Date: "9/7/20",
  },
  {
    Method: "Cash",
    Amount: "$14.99",
    Tendered: "$14.99",
    Change: "$0.00",
    Date: "9/7/20",
  },
  {
    Method: "Cash",
    Amount: "$14.99",
    Tendered: "$14.99",
    Change: "$0.00",
    Date: "9/7/20",
  },
  {
    Method: "Cash",
    Amount: "$14.99",
    Tendered: "$14.99",
    Change: "$0.00",
    Date: "9/7/20",
  },
];


export {
  itemtable,
  tasklisttable,
  salestable,
  itemsTable,
  paymentsTable,
  countReport
};
