import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

const RippleButton = (props) => {
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  React.useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);
  return (
    <>
      <button
        className="MuiButtonBase-root MuiButton-root MuiButton-text ripple-button"
        onClick={(e) => {
          e.preventDefault(); 
          const rect = e.target.getBoundingClientRect();
          setCoords({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
          });
          props.setValue(props.text)
          if(props.icon !==undefined){
            props.setIcon(props.icon)
          }
        }}
      >
        <span className="Muibutton-label">{props.text}</span>
        {isRippling ? (
          <span
            className="ripple"
            style={{
              left: coords.x,
              top: coords.y,
              background: "#e6f7fd"
            }}
          />
        ) : (
          ""
        )}
      </button>
    </>
  );
};
RippleButton.propTypes = {
  setValue: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  setIcon: PropTypes.func, 
};
export default RippleButton;
