import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { Routes } from "../routes";
import LocalStorage from "../app/storage/Localstorage";
import SessionStorage from "../app/storage/Sessionstorage";
import history from "../history/history";
import { ToastContainer } from "react-toastify";
import { useFullScreenHandle } from "react-full-screen";
import { pusherprops } from "../config/pusher-config";
import { PusherProvider } from "../context/PusherContext";
import "react-toastify/dist/ReactToastify.css";
import info from "../assets/images/info.png";
import crossIcon from "../assets/images/cross-screen.svg";
import PropTypes from 'prop-types';

// components
import Navbar from "../components/Navbar";
import Footer from "./footer/footer";

import { db } from "../dexieDb/db";
import {
  ITEM,
  ITEMLIST_GET_LIST,
  CATEGORY,
  CATEGORY_GET_LIST,
  TAX,
  TAX_GET_LIST,
  TAXRULE,
  TAXRULE_GET_LIST,
  LOADING,
  ACCOUNTSETTING,
  BOTTLEDEPOSIT,
  ADDITIONALFEE,
  GENERALSETTING,
  ITEMSALE,
  ITEMSALELIST_GET_LIST,
  DEVICE,
  DEVICE_GET_LIST,
  STORE,
  STORE_GET_LIST,
  POSSETTING,
  POSSIDEETTINGS,
  IMAGEUPLOAD,
  CUSTOMERDATA,
  CUSTOMER_GET_LIST,
  TAG,
  TAG_GET_LIST,
  ITEMTYPE,
  ITEMTYPE_GET_LIST,
  SUPPLIER,
  SUPPLIER_GET_LIST,
  ITEMSIZE,
  ITEMSIZE_GET_LIST,
  STAFFANDADMIN,
  POSSIDEETTINGS_GET,
  BUSINESSLOGO,
  CUSTOMERREWARDS,
  CUSTOMER_REWARDS_GET_LIST,
  PROMOTIONS,
  PROMOTIONS_GET_LIST,
  STOCKCODE_GET_LIST,
  ITEMMOSIFIER_GET_LIST,
  MERGEITEMSPAYLOAD,
  ADDITIONALFEE_GET_LIST,
  ITEMTAG_GET_LIST,
  UPDATELOTTODATA,
  UPDATELOTTOIDS,
  INITIALIZED_POPUP,
  LABELTEMPLATE_GET_LIST
} from "../app/helpers/constant";
import {
  getListActionFunction,
  loadingAction,
} from "../app/actions/getListAction";
import { useDispatch, useSelector } from "react-redux";
import {
  DeviceUUId,
  getDeviceAndStore,
  getUser,
  isMobileDevice
} from "../axios/authHeader";
import { setdynamicImages } from "../app/actions/getListAction";
import CustomerScreenComponents from "./components/customer-screen/CustomerScreenComponents";
import CustomErrorBoundary from "../customErrorBoundary";
import { setUserDetails } from "../app/actions/authAction";
import { CombineImg } from "../components/dynamicimages/CombineImgs";
import Initializing from "./Initial-Setup/Initializing";

import Signin from "./Signin";
import Reports from "./reports/reports";
import Settings from "./settings/settings";
import WebOrders from "./webOrders/WebOrders";
import Register from "./register/register";
import Sales from "./sales/sales";
import getListService from "app/services/getListService";
import { confirmedResponseAction } from "app/actions/getListAction";
import { Button, Image } from "@themesberg/react-bootstrap";
import MobileCashRegister from './mobile-cash-register/MobileCashRegister';

const RouteWithLoader = ({ component: Component }) => {
  const location = useLocation();

  useEffect(() => {
    let url = "";
    if (location.pathname !== "/customer-screen") {
      if (checkuser()) {
        if (location.pathname === "/") url = "/pos";
        else url = location.pathname;
      } else {
        localStorage.removeItem("pos_user");
        SessionStorage.removeItem("timeout");
        url = "/";
      }
      history.push({
        pathname: url,
      });
    }
  }, [location.pathname]);

  return (
    <Route
      render={(props) => (
        <>
          <Component {...props} />

          <ToastContainer limit={1} />
        </>
      )}
    />
  );
};

const RouteWithSignIn = ({ component: Component, props, ...rest }) => {
  const [isSlideInOpen, setSlideInOpen] = useState(false);
  const toggleSlideIn = () => {
    if (window.innerWidth <= 767) { // Check the screen width
      setSlideInOpen(!isSlideInOpen);
    }
  };
  const closeSlideIn = () => {
    setSlideInOpen(false);
  };
  const location = useLocation();
  // const [pusher, setPusher] = useState(null);
  const [prevModule, setPrevModule] = useState(undefined);
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const nextmodule = useSelector((state) => state.inventory.nextmodule);
  const initialize = useSelector((state) => state.inventory.initialize_popup)
  const displayMsg = useSelector((state) => state.inventory.msg);
  const [styles, setStyle] = useState(localStorage.getItem("width"));
  const wrapper = useRef();
  const [labelModal, setLabelModal] = useState(false);

  db.open()
    .then(() => {
      // console.error("successfully connected");
    })
    .catch(function (e) {
      console.error("Open failed: " + e);
    });

  // useMemo(() => {
  //   setPusher(pusherCalling());
  // }, []);

  useEffect(() => {
    if (!nextmodule && !prevModule) {
      getDataFromServer(1);
      setPrevModule(1);
    }
    if (nextmodule > 0 && nextmodule < 29) {
      getDataFromServer(nextmodule);
      setPrevModule(nextmodule);
    }
  }, [nextmodule]);

  useEffect(() => {
    getTemplateList();
  }, []);

  const getTemplateList = () => {
    try {
      const store = getDeviceAndStore();
      dispatch(
        getListActionFunction(
          LABELTEMPLATE_GET_LIST,
          store[1],
          LABELTEMPLATE_GET_LIST
        )
      );
      // dispatch(getListActionFunction(ITEM, store[1], ITEMLIST_GET_LIST));
    } catch (error) {}
  };

  useEffect(() => {
    FetchData();
  }, []);

  const FetchData = async () => {
    const Data = await getListService.getLottoData();
    const Ids = [];
    for (let i in Data?.data) {
      if (Data?.data[i].item_type !== 11) {
        Ids.push(Data?.data[i].id);
      }
    }
    dispatch(confirmedResponseAction(Data?.data, UPDATELOTTODATA));
    dispatch(confirmedResponseAction(Ids, UPDATELOTTOIDS));
  };

  function loadingOnlineData(nextmodule, store) {
    switch (nextmodule) {
      case 1:
        LoaderSpinnerShow(true, "store");
        WposGetDataByApi(STORE, "", STORE_GET_LIST, ++nextmodule);
        break;
      case 2:
        LoaderSpinnerShow(true, "store");
        WposGetDataByApi(DEVICE, store[1], DEVICE_GET_LIST, ++nextmodule);
        break;
      case 3:
        LoaderSpinnerShow(true, "setting");
        WposGetDataByApi(TAX, store[1], TAX_GET_LIST, ++nextmodule);
        break;
      case 4:
        LoaderSpinnerShow(true, "setting");
        WposGetDataByApi(TAXRULE, store[1], TAXRULE_GET_LIST, ++nextmodule);
        break;
      case 5:
        LoaderSpinnerShow(true, "setting");
        WposGetDataByApi(
          ACCOUNTSETTING,
          store[1],
          ACCOUNTSETTING,
          ++nextmodule
        );
        break;
      case 6:
        LoaderSpinnerShow(true, "setting");
        WposGetDataByApi(POSSETTING, store[1], POSSETTING, ++nextmodule);
        break;
      case 7:
        LoaderSpinnerShow(true, "setting");
        WposGetDataByApi(
          GENERALSETTING,
          store[1],
          GENERALSETTING,
          ++nextmodule
        );
        break;
      case 8:
        LoaderSpinnerShow(true, "items");
        WposGetDataByApi(ITEM, store[1], ITEMLIST_GET_LIST, ++nextmodule);
        break;
      case 9:
        LoaderSpinnerShow(true, "setting");
        WposGetDataByApi(ADDITIONALFEE, store[1], ADDITIONALFEE, ++nextmodule);
        break;
      case 10:
        LoaderSpinnerShow(true, "items");
        WposGetDataByApi(
          STOCKCODE_GET_LIST,
          store[1],
          STOCKCODE_GET_LIST,
          ++nextmodule
        );
        break;
      case 11:
        LoaderSpinnerShow(true, "setting");
        WposGetDataByApi(
          POSSIDEETTINGS,
          store,
          POSSIDEETTINGS_GET,
          ++nextmodule
        );
        break;
      case 12:
        LoaderSpinnerShow(true, "items");
        WposGetDataByApi(
          ITEMMOSIFIER_GET_LIST,
          store[1],
          ITEMMOSIFIER_GET_LIST,
          ++nextmodule
        );
        break;
      case 13:
        LoaderSpinnerShow(true, "items");

        WposGetDataByApi(
          ADDITIONALFEE_GET_LIST,
          store[1],
          ADDITIONALFEE_GET_LIST,
          ++nextmodule
        );
        break;
      case 14:
        LoaderSpinnerShow(true, "category");
        WposGetDataByApi(CATEGORY, store[1], CATEGORY_GET_LIST, ++nextmodule);
        break;
      case 15:
        LoaderSpinnerShow(true, "item");
        WposGetDataByApi(
          ITEMTAG_GET_LIST,
          store[1],
          ITEMTAG_GET_LIST,
          ++nextmodule
        );
        break;
      case 16:
        LoaderSpinnerShow(true, "item");
        WposGetDataByApi(TAG, store[1], TAG_GET_LIST, ++nextmodule);
        break;
      case 17:
        LoaderSpinnerShow(true, "item");
        WposGetDataByApi(ITEMSIZE, store[1], ITEMSIZE_GET_LIST, ++nextmodule);
        break;
      case 18:
        LoaderSpinnerShow(true, "supplier");
        WposGetDataByApi(SUPPLIER, store[1], SUPPLIER_GET_LIST, ++nextmodule);
        break;
      case 19:
        LoaderSpinnerShow(true, "items");
        WposGetDataByApi(BOTTLEDEPOSIT, store[1], BOTTLEDEPOSIT, ++nextmodule);
        break;
      case 20:
        LoaderSpinnerShow(true, "user");
        WposGetDataByApi(STAFFANDADMIN, store[1], STAFFANDADMIN, ++nextmodule);
        break;

      case 21:
        LoaderSpinnerShow(true, "customer");
        WposGetDataByApi(
          CUSTOMERDATA,
          store[1],
          CUSTOMER_GET_LIST,
          ++nextmodule
        );
        break;
      case 22:
        LoaderSpinnerShow(true, "items");
        WposGetDataByApi(
          CUSTOMERREWARDS,
          store[1],
          CUSTOMER_REWARDS_GET_LIST,
          ++nextmodule
        );
        break;
      case 23:
        LoaderSpinnerShow(true, "items");
        WposGetDataByApi(ITEMTYPE, store[1], ITEMTYPE_GET_LIST, ++nextmodule);
        break;
      case 24:
        LoaderSpinnerShow(true, "merging");
        dispatch({
          payload: { nextmodule: ++nextmodule },
          type: MERGEITEMSPAYLOAD,
        });
        break;

      case 25:
        LoaderSpinnerShow(true, "promotions");
        WposGetDataByApi(
          PROMOTIONS,
          store[1],
          PROMOTIONS_GET_LIST,
          ++nextmodule
        );
        break;
      case 26:
        LoaderSpinnerShow(false, "sales");
        WposGetDataByApi(
          ITEMSALE,
          { storeId: store[1] },
          ITEMSALELIST_GET_LIST,
          ++nextmodule
        );
        break;
      case 27:
        LoaderSpinnerShow(false, "images");
        WposGetDataByApi(IMAGEUPLOAD, store[1], IMAGEUPLOAD, ++nextmodule);
        dispatch({
          type: INITIALIZED_POPUP,
          status: false
        })
        break;
      default:
        return false;
    }
  }
  function getDataFromServer(nextmodule) {
    const store = getDeviceAndStore();
    const registerId = getDeviceAndStore(true);
    if (store && registerId) {
      loadingOnlineData(nextmodule, store, registerId);
    }
  }

  function LoaderSpinnerShow(con, msg) {
    dispatch(loadingAction({ loading: con, msg: msg }, LOADING));
  }

  function WposGetDataByApi(type, storeOrregister, constant, nextmodule) {
    dispatch(
      getListActionFunction(type, storeOrregister, constant, nextmodule)
    );
  }

  const HandleWidth = (data) => {
    switch (data) {
      case 1:
        setStyle("960px");
        localStorage.setItem("width", "960px");
        break;
      case 2:
        setStyle("1152px");
        localStorage.setItem("width", "1152px");
        break;
      case 3:
        setStyle("1280px");
        localStorage.setItem("width", "1280px");
        break;
      case 4:
        setStyle("1366px");
        localStorage.setItem("width", "1366px");
        break;
      case 5:
        setStyle("none");
        localStorage.setItem("width", "none");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let url = "";
    if (checkuser()) {
      if (location.pathname === "/") url = "/pos";
      else url = location.pathname;
    } else {
      localStorage.removeItem("pos_user");
      sessionStorage.removeItem("timeout");
      url = "/";
    }
    history.push({
      pathname: url,
    });
  }, [location.pathname]);

  return (
    <>
      {nextmodule <= 27 && initialize ? (
        <Initializing msg={displayMsg} now={nextmodule} min={0} max={28} />
      ) : (
        <Route
          {...rest}
          render={(props) => (
            <>
              <PusherProvider pusher={pusherprops}>
                <div
                  className={`maxwidth_${styles} ${isMobileDevice() ? 'mobile-cash-register' : ''
                    } watermark-added`}
                  ref={wrapper}
                  style={{ maxWidth: `${styles}`, margin: 'auto' }}
                >
                  <Navbar
                    handle={handle}
                    setWidth={HandleWidth}
                    setLabelModal={setLabelModal}
                  />
                  <Button
                    type="button"
                    title="Store information"
                    onClick={toggleSlideIn}
                    variant="primary d-block d-md-none store-info-footer"
                  >
                    <Image src={info} alt="Store information" />
                  </Button>

                  <main className="content">
                    {isMobileDevice() ? (
                      <>
                        <MobileCashRegister />
                      </>
                    ) : (
                      // Render the original Component for non-mobile view
                      <>
                        <Component {...props} labelModal={labelModal} businessLogo={rest?.businessLogo} />
                      </>
                    )}
                    <ToastContainer limit={1} />
                  </main>
                  <div
                    className={`shortcut-overlay for-footer ${isSlideInOpen ? 'visible' : ''
                      }`}
                    onClick={closeSlideIn}
                  ></div>
                  <div
                    className={`shortcut-container footer-info ${isSlideInOpen ? 'open' : ''
                      }`}
                  >
                    <Button
                      type="button"
                      onClick={closeSlideIn}
                      variant="link d-block d-md-none"
                    >
                      <Image src={crossIcon} alt="cross icon" />
                    </Button>
                    <Footer />
                  </div>
                </div>
              </PusherProvider>
            </>
          )}
        />
      )}
    </>
  );
};

// const pusherCalling = () => {
//   // Enable pusher logging - don't include this in production
//   // Pusher.logToConsole = false;
//   // Set up pusher instance with main channel subscription
//   // Be able to subscribe to the same channel in another component
//   // with separate callback but utilizing the existing connection
//   // const pusher = new Pusher(PUSHER_CONFIG.key, {
//   //   cluster: PUSHER_CONFIG.cluster,
//   //   forceTLS: true,
//   // });
//   // return pusher;
// };

const checkuser = () => {
  let check = false;
  const localstorage = LocalStorage.getItem("pos_user");
  const sessionstorage = SessionStorage.getItem("timeout");
  if (sessionstorage && localstorage && localstorage.token && DeviceUUId()) {
    check = true;
  }
  return check;
};
const getAppName = (value) => {
  if (value) {
    if (value === "bottlepos") {
      return "BottlePOS";
    } else if (value === "cigarpos") {
      return "CigarsPOS";
    } else if (value === "cstorepos") {
      return "CStorePOS";
    } else {
      return "";
    }
  }
  return "";
};

const getApptypeFromUrl = () => {
  let url = window.location.href;
  return getAppName(url.split(".")[1]);
};

export default () => {
  let dispatch = useDispatch();
  const general_setting = useSelector((state) => state.setting.generalSetting);
  // const store = getDeviceAndStore();
  const Images = useSelector((state) => state.setting.images);
  const fontSize = useSelector((state) => state.setting.fontSize);
  const [businessLogo, setBusinessLogo] = useState("");
  const [dnamicImg, setDynamicImg] = useState(null);

  useEffect(() => {
    if (getUser()?.id) {
      dispatch(setUserDetails(getUser()?.id));
    }
  }, []);

  useEffect(() => {
    dispatch(setdynamicImages(dnamicImg));
  }, [dnamicImg]);

  useEffect(() => {
    if (Images && Array.isArray(Images)) {
      setBusinessLogo(Images?.find((elem) => elem.name == BUSINESSLOGO)?.file);
    }
  }, [Images]);

  useEffect(() => {
    var images = importAll(
      require.context(
        "../assets/images/apptype/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    let favicon = document.getElementById("favicon");
    const appType =
      general_setting?.data?.BUSINESS?.logo_name || getApptypeFromUrl();

    setDynamicImg(CombineImg(appType));

    // set app type in local storage
    localStorage.setItem("appType", appType);

    // Default css loading for selected app type
    const importScss = async () => {
      if (appType === "CigarsPOS") {
        await import("../scss/volt-cigar.scss");
        if (favicon) {
          favicon.href = images["CigarPOSfav"];
        }
        document.title = `CigarsPOS`;
      } else if (appType === "CStorePOS") {
        await import("../scss/volt-cstore.scss");
        if (favicon) {
          favicon.href = images["favicon-cstore"];
        }
        document.title = `CStorePOS`;
      } else if (
        appType === "BottlePOS" ||
        appType === "PNCstore" ||
        appType === "PNLiquor" ||
        appType === "PNTobacco" ||
        appType === "PNRetail"
      ) {
        await import("../scss/volt.scss");
        if (
          appType === "PNCstore" ||
          appType === "PNLiquor" ||
          appType === "PNTobacco" ||
          appType === "PNRetail"
        ) {
          document.title = `POS Nation`;
          favicon.href = images["favicon"];
        } else {
          document.title = `BottlePos`;
          favicon.href = images["favicon-bottlepos"];
        }
      } else {
        await import("../scss/volt.scss");
        document.title = `Loading...`;
        favicon.href = images["favicon-bottlepos"];
      }
      // Do something after the SCSS file is imported
    };
    const Otherdetails = general_setting?.data?.OTHER;
    changeTheme(Otherdetails?.darkmode);
    importScss();
  }, [general_setting]);

  const changeTheme = (darkmode) => {
    if (darkmode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  };

  function importAll(r) {
    let images = {};
    r.keys().map((item) => {
      images[item.replace("./", "").split(".")[0]] = r(item);
    });
    return images;
  }

  // useEffect(() => {
  //   let size = fontSize !== "default" ? fontSize + "px" : null;

  //   let allElem = document.querySelectorAll("*");

  //   for (let i = 0; i < allElem.length; i++) {
  //     const element = allElem[i];
  //     element.style.fontSize = size;
  //   }
  // }, [fontSize]);

  //prevent number field increase decrease on scroll
  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  // console.log = function () {};

  return (
    <CustomErrorBoundary>
      <Switch>
        <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
        {/* for Signin page */}
        {/* for Customer Screen */}
        <RouteWithLoader
          exact
          path={Routes.CustomerScreen.path}
          component={CustomerScreenComponents}
        />
        {/* pages if user login*/}
        {/* <RouteWithSignIn exact path={Routes.Admin.path} component={Admin} /> */}

        <RouteWithSignIn
          exact
          path={Routes.Register.path}
          component={Register}
          businessLogo={businessLogo}
        />
        <RouteWithSignIn exact path={Routes.Sales.path} component={Sales} />
        <RouteWithSignIn exact path={Routes.Reports.path} component={Reports} />
        <RouteWithSignIn
          exact
          path={Routes.WebOrders.path}
          component={WebOrders}
        />
        <RouteWithSignIn
          exact
          path={Routes.Settings.path}
          component={Settings}
        />
        {/* Redirect if User not login  */}
        <Redirect to={Routes.Signin.path} />
      </Switch>
    </CustomErrorBoundary>
  );
};
RouteWithLoader.propTypes = {
  component: PropTypes.elementType.isRequired,
};
RouteWithSignIn.propTypes = {
  component: PropTypes.elementType.isRequired, // Define prop validation for component
  // You can add prop validation for other props in `rest` here if needed
};