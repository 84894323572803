import {
  ITEMSALELIST,
  ADDITEMONSALE,
  NUMPADSTATUS,
  AGEPOPUPSHOW,
  TOTALAMOUNT,
  PAYMENT,
  REVERTPAYMENT,
  REMOVEITEMSALE,
  ADDCONFIRMSALEITEM,
  REMOVEALLITEMSALE,
  ITEMSALELIST_GET_LIST,
  GRAPHREPORT,
  CUSTOMERSCREENCHECK,
  EXPENDUI,
  ITEMSALELISTUPDATED,
  OFFLINESALE,
  COMPLETEITEMSALELIST,
  REPORTTRNSACTION,
  POSSIDEETTINGS,
  LABELTEMPLATE_GET_LIST,
  LOADING,
  REEDEM_REWARDS,
  SALE_CUSTOMER,
  PROMOTION_DATA,
  DISCOUNT,
  PAXERROR,
  UPDATESALESTATUS,
  REMOVESALELIST,
  ITEMDISCOUNTS,
  DATEOFBIRTH
} from "../helpers/constant";

const initialState = {
  saleItemList: [],
  saleItemadded: [],
  completeSaleItemList: [],
  ageUpShow: false,
  payment: [],
  totalAmount: [],
  confirmSale: [],
  saleList: [],
  saleListCount: 0,
  sale_customer: null,
  allDataRemove: false,
  graphData: [],
  customerScreen: [],
  suspendedSale: {},
  reedemPointDiscount: {},
  expendCounter: 0,
  success: 0,
  offlineSale: false,
  offlineSaleData: [],
  reportTransaction: [],
  promotionData: {},
  discount: {
    value: "0",
    symbol: true,
  },
  itemDiscounts : {},
  loading: true,
  numpadstatus: true,
  paxerror: "",
  enterAge: ""
};

export default (state = initialState, actions) => {
  switch (actions.type) {
    case ITEMSALELIST:
      return {
        ...state,
        saleItemList: [...actions.payload],
        ageUpShow: false,
        allDataRemove: false,
      };
    case COMPLETEITEMSALELIST:
      return {
        ...state,
        completeSaleItemList: [...actions.payload],
        saleItemList: [],
        ageUpShow: false,
        allDataRemove: false,
      };
    case ADDITEMONSALE:
      return {
        ...state,
        saleItemadded: [actions.payload],
        ageUpShow: true,
        allDataRemove: false,
      };
    case NUMPADSTATUS:
      return {
        ...state,
        numpadstatus: actions.payload,
      };
    case REMOVEITEMSALE:
      return {
        ...state,
        saleItemadded: [],
        ageUpShow: false,
        allDataRemove: false,
      };
    case REMOVEALLITEMSALE:
      {
        localStorage.removeItem("payment");
        let suspendSale = localStorage.getItem("suspendSale");
        let suspendedSale = state.suspendedSale;
        let orderList = [...Object.values(state.saleList)];
        let existIndex = orderList?.findIndex(e => +e?.id === +actions?.payload?.data?.data?.id);
        if (existIndex !== -1) {
          orderList[existIndex] = actions?.payload?.data
        } else {
          orderList.unshift(actions.payload?.data);
        }
        if (suspendSale === "true") {
          suspendedSale = actions.payload.data;
          localStorage.removeItem("suspendSale");
        }
        return {
          ...state,
          saleItemadded: [],
          saleItemList: [],
          suspendedSale,
          ...(suspendSale === "true" && {
            saleList: orderList,
            saleListCount: state.saleListCount + 1,
          }),
          totalAmount: [],
          payment: [],
          ageUpShow: false,
          allDataRemove: true,
        };
      }
    case AGEPOPUPSHOW:
      return {
        ...state,
        ageUpShow: actions.payload,
        allDataRemove: false,
      };
    case TOTALAMOUNT:
      localStorage.setItem("totalAmount", JSON.stringify(actions.payload));
      localStorage.setItem(
        "totalAmount_reprint",
        JSON.stringify(actions.payload)
      );
      return {
        ...state,
        totalAmount: actions.payload,
        allDataRemove: false,
      };
    case PAYMENT:
      {
        let payment = [...state.payment];
        payment.push(actions.payload);
        localStorage.setItem("payment", JSON.stringify(payment));
        localStorage.setItem("payment_reprint", JSON.stringify(payment));
        return {
          ...state,
          payment,
          allDataRemove: false,
        };
      }
    case REVERTPAYMENT:
      return {
        ...state,
        payment: actions.payload,
      };
    case GRAPHREPORT:
      return {
        ...state,
        graphData: actions.payload,
        allDataRemove: false,
      };
    case ITEMSALELIST_GET_LIST:
      return {
        ...state,
        // saleList: actions.payload.data.results,
        saleList: actions.payload.data,
        saleListCount: actions.payload.data.count,
        loading: false,
      };
    case UPDATESALESTATUS: {
      const saleList = state.saleList;
      const Index = saleList.findIndex(
        (data) => data.id === actions.payload.data?.data?.id
      );
      saleList[Index] = actions.payload.data?.data;
      return {
        ...state,
        saleList,
      };
    }
    case ITEMSALELISTUPDATED: {
      const saleList = state.saleList;
      let reportTrans = state.reportTransaction;
      let suspendSale = state.suspendedSale;
      const Index = saleList.findIndex(
        (data) => data.id === actions.payload.data.id
      );
      const IndexTrans = reportTrans.findIndex(
        (data) => data.id === actions.payload.data.id
      );
      saleList[Index] = actions.payload.data;
      reportTrans[IndexTrans] = actions.payload.data;
      if (suspendSale.id === actions.payload.data.id) {
        suspendSale = actions.payload.data;
      }
      return {
        ...state,
        saleList,
        suspendedSale: suspendSale,
        reportTransaction: reportTrans,
        success: Math.random(),
      };
    }
    case CUSTOMERSCREENCHECK:
      {
        localStorage.setItem("customerScreen", JSON.stringify(actions.payload));
        return {
          ...state,
          customerScreen: actions.payload,
        };
      }
    case ADDCONFIRMSALEITEM:
      {
        let saleList = [...Object.values(state.saleList)];
        let Index = saleList.findIndex((e) => e.id === actions.payload.data.id);
        if (Index >= 0) {
          // saleList.splice(Index , 1 , actions.payload.data)
          saleList[Index] = actions.payload.data;
        } else {
          saleList.unshift(actions.payload.data);
        }
        let prevSale = state.suspendedSale;
        let compSale = prevSale;
        if (prevSale) {
          if (prevSale.id === actions.payload.data.id) {
            compSale = actions.payload.data;
          }
        }
        return {
          ...state,
          saleList,
          saleListCount:
            Index >= 0 ? state.saleListCount + 1 : state.saleListCount,
          suspendedSale: compSale,
          allDataRemove: true,
        };
      }
    case OFFLINESALE:
      return {
        ...state,
        offlineSale: actions.payload.status,
        offlineSaleData: actions.payload.data,
      };
    case POSSIDEETTINGS:
      return {
        ...state,
        posSetData: actions.payload,
      };
    case EXPENDUI:
      return {
        ...state,
        expendCounter: actions.payload,
        allDataRemove: false,
      };
    case REMOVESALELIST:
      return {
        ...state,
        allDataRemove: actions.payload,
      };
    case REPORTTRNSACTION: {
      return {
        ...state,
        reportTransaction: actions.payload.data?.sale,
      };
    }
    case LABELTEMPLATE_GET_LIST: {
      return {
        ...state,
        labeltemplates: actions.payload.data,
        disabled: false,
      };
    }
    case REEDEM_REWARDS:
      let id = state?.saleItemList[state?.saleItemList?.length - 1]?.id 
      let itemDiscount = {...state.itemDiscounts , ...(id && {[id] : {...actions.payload}})}
      return {
        ...state,
        reedemPointDiscount: actions.payload,
        itemDiscounts : itemDiscount
      };
    case SALE_CUSTOMER:
      return {
        ...state,
        sale_customer: actions.payload,
      };
    case PROMOTION_DATA:
      return {
        ...state,
        promotionData: actions.payload,
      };
    case LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case DISCOUNT: {
      let id = state?.saleItemList[state?.saleItemList?.length - 1]?.id 
      let itemDiscount = {...state.itemDiscounts , ...(id && {[id] : {...actions.payload}})}
      if(actions.payload.removeAll){
        itemDiscount = {}
      }
      return {
        ...state,
        discount: actions.payload,
        itemDiscounts : itemDiscount
      };
    }
    case ITEMDISCOUNTS : {
      return {
        ...state,
        itemDiscounts : actions.payload
      }
    }
    case PAXERROR: {
      return {
        ...state,
        paxerror: actions.payload,
      };
    }
    case DATEOFBIRTH: {
      return {
        ...state,
        enterAge: actions.payload,
      };
    }
    default:
      return state;
  }
};
