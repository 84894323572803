import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
} from "@themesberg/react-bootstrap";
import FormField from "../../../components/formField";
import Datetime from "react-datetime";
import moment from "moment";
import LoaderSpinner from "../../../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  getDeviceAndStore,
  getMasterStoreId,
} from "../../../../axios/authHeader";
import { toast } from "react-toastify";
import { useDispatch, connect, useSelector } from "react-redux";
import {
  addActionData,
  editActionData,
} from "../../../../app/actions/saleItemAction";
import { loadingAction } from "../../../../app/actions/getListAction";
import {
  CUSTOMERDATA,
  LOADING,
  CUSTOMERUPDATE,
} from "../../../../app/helpers/constant";
import {
  dateFormate,
  todayDate,
  validateLengthofString,
} from "../../../../app/helpers/custom";
import PropTypes from 'prop-types';

const CustomerForm = (props) => {
  const [custFormData, setCustFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    address: "",
    postcode: "",
    dt: dateFormate(new Date(todayDate("/", true)).getTime()),
    points: 0,
    dob: "",
    promotion_subscribed: 0,
    masterstore_id: getMasterStoreId(),
    store_id: getDeviceAndStore()[1],
  });
  const { name, mobile, email, address, postcode, dob } = custFormData;
  const [updateId, setUpdateId] = useState(null);
  const dispatch = useDispatch();
  const [custDetails, setCustDetails] = useState({});

  const custList = useSelector((state) => state.inventory.customerList);
  useEffect(() => {
    if (typeof props?.custDetails === "string") {
      setCustDetails(props?.custDetails);
    } else {
      let data = custList?.find((e) => +e.id == +props?.custDetails?.id);
      setCustDetails(data);
    }
  }, [props?.custDetails, custList]);

  useEffect(() => {
    if (custDetails !== undefined) {
      if (typeof custDetails === "string") {
        setCustFormData({ ...custFormData, mobile: custDetails });
      } else {
        if (typeof custDetails === "object") {
          Object.keys(custDetails).length && setUpdateId(custDetails?.id);
          Object.keys(custDetails).length && setCustFormData(custDetails);
        }
      }
    }
  }, [custDetails]);

  useEffect(() => {
    if (props.msg !== "" && props.msg === "customerData") {
      props.ReponseAdded(false);
      dispatch(loadingAction(false, LOADING));
    }
  }, [props.msg]);

  const handleInputChange = (e) => {
    if (!/^\s/.test(e.target.value)) {
      setCustFormData({ ...custFormData, [e.target.name]: e.target.value });
    }
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const OnSubmitForm = (e) => {
    let newFormData = {
      ...custFormData,
      masterstore_id: getMasterStoreId(),
    };
    e.preventDefault();
    if (name !== "") {
      if (mobile !== "" && mobile.length === 10) {
        if (validateLengthofString(postcode, 4, 11)) {
          dispatch(loadingAction(true, LOADING));
          if (updateId)
            dispatch(
              editActionData(
                CUSTOMERDATA,
                newFormData,
                updateId,
                CUSTOMERUPDATE
              )
            );
          else dispatch(addActionData(CUSTOMERDATA, newFormData, CUSTOMERDATA));
        } else {
          toast.error("Please enter vaild zipcode");
        }
      } else {
        toast.error(
          mobile && mobile.length > 0
            ? "Please enter vaild mobile no "
            : "Customer mobile no is required"
        );
      }
    } else {
      toast.error("Customer name is required");
    }
  };

  return (
    <>
      {props?.loading && <LoaderSpinner />}
      <Form autoComplete="off" onSubmit={(e) => OnSubmitForm(e)}>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Group className="form-floating">
              <FormField
                label="Name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => handleInputChange(e)}
                placeholder="Name"
              />
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group className="form-floating">
              <FormField
                label="Mobile Number"
                type="number"
                name="mobile"
                value={mobile}
                maxLength="10"
                onChange={(e) => handleInputChange(e)}
                placeholder="XXX-XXX-XXXX"
              />
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group className="form-floating">
              <FormField
                label="Email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => handleInputChange(e)}
                placeholder="Email"
              />
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group className="form-floating">
              <FormField
                label="Address"
                type="text"
                name="address"
                value={address}
                onChange={(e) => handleInputChange(e)}
                placeholder="Address"
              />
            </Form.Group>
          </Col>

          <Col md={6} className="mb-3">
            <Form.Group className="form-floating">
              <FormField
                label="Zipcode"
                type="number"
                name="postcode"
                value={postcode}
                onChange={(e) => handleInputChange(e)}
                placeholder="Zipcode"
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group className="custom-time-picker with-label-up">
              <Form.Label style={{ zIndex: "11" }}>DOB</Form.Label>
              <Datetime
                timeFormat={false}
                closeOnSelect={true}
                onChange={(e) =>
                  setCustFormData({ ...custFormData, dob: convert(e._d) })
                }
                renderInput={(props, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                    <FormField
                      type="text"
                      value={dob !== "" ? moment(dob).format("MM/DD/YYYY") : ""}
                      placeholder="mm/dd/yyyy"
                      onFocus={openCalendar}
                      onChange={() => {}}
                    />
                  </InputGroup>
                )}
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Check
              label="Opt-in SMS &amp; Email Promotions"
              id="sms_promotions"
              htmlFor="sms_promotions"
              name="sms_promotions"
              checked={custFormData.promotion_subscribed}
              onChange={(e) =>
                setCustFormData({
                  ...custFormData,
                  promotion_subscribed: e.target.checked ? 1 : 0,
                })
              }
              className="mb-2"
            />
          </Col>
        </Row>
        <Button
          variant="primary px-5 position-absolute form-cta-btn"
          type="submit"
          disabled={props.loading ? true : false}
          title={updateId ? `Update` : `Save`}
        >
          {updateId ? `Update` : `Save`}
        </Button>
      </Form>
    </>
  );
};
CustomerForm.propTypes = {
  custDetails: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ]),
  msg: PropTypes.string,
  ReponseAdded: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    loading: state.inventory.loading,
    msg: state.inventory.msg,
  };
};
export default connect(mapStateToprops)(CustomerForm);
