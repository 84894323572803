import React, { useState, useEffect } from "react";
// Data tables
import { Image, Fade } from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { connect } from "react-redux";
import { currencyFormat, taxDetailsByItemId } from "../../app/helpers/custom";
import PropTypes from 'prop-types';

const ItemsTable = (props) => {
  const { SearchBar, ClearSearchButton } = Search;
  const [itemDetails, setItemDetails] = useState([]);
  useEffect(() => {
    if (props.items !== undefined && props.items.length > 0) {
      setItemDetails(UpdateArray(props.items));
      if (props.items[0]?.temp_sale_id){
        let modifiedArray = props.items.map((item)=>{
          return {...item, 
            tax: JSON.stringify(
                  taxDetailsByItemId(
                      item?.tax, 
                      item?.qty, item?.price, 
                      props?.taxList, 
                      props?.taxRules, 
                      item?.fee, 
                      undefined, 
                      item?.bottleDeposit, 
                      props?.bottledeposit, 
                      item?.additionalFeeEnable, 
                      item?.bottleDepositEnable, 
                      item.bottleDepositTaxable
                  )
                )
            }
        })
        setItemDetails(UpdateArray(modifiedArray));
      }
    }
  }, [props.items]);

  let { promotionInfo } = props;
  let { discountInfo } = props;
  promotionInfo = promotionInfo ? promotionInfo : {};
  const UpdateArray = (array) => {
    let arr = [];
    for (let data of array) {
      arr.push({
        Id: data?.id,
        Qty: data?.qty,
        Name: data?.name || data.manualname,
        Price: currencyFormat(data?.unit_original || data?.orgPrice || data?.showPrice),
        Tax: UpdateTaxArray(
          data.tax && data.tax.length ? JSON.parse(data.tax) : [],
          props.taxList
        ),
        Total: currencyFormat(data?.price_total || data?.total),
      });
      if (promotionInfo[data?.storeditemid]) {
        arr.push({
          Name:
            promotionInfo[data?.storeditemid].name +
            "(" +
            promotionInfo[data?.storeditemid].promotext +
            ")",
        });
      }

      if (promotionInfo[data?.storeditemid]?.promotion2) {
        arr.push({
          Name:
          promotionInfo[data?.storeditemid]?.promotion2?.name +
            "(" +
            promotionInfo[data?.storeditemid]?.promotion2?.promotext +
            ")",
        });
      }
      if (discountInfo){
        if (discountInfo[data?.unique_item_id]) {
          arr.push({
            Name: `${parseFloat(discountInfo[data?.unique_item_id]?.discountValue).toFixed(2)}% Discount`,
            Total : currencyFormat(discountInfo[data?.unique_item_id]?.totalDiscount),
          });
        }
      }

    }
    return arr;
  };

  const UpdateTaxArray = (data, taxArray) => {
    // tax details
    var taxStr = "";
    for (var x in data.values) {
      let taxs = taxArray?.filter((e) => e.id == x);
      if (taxs?.length) {
        const TaxData = taxArray.filter((item) => item.id === parseInt(x));
        taxStr += `${currencyFormat(data.values[x])} (${TaxData[0]?.name} ${TaxData[0]?.value}%)\n`;
      }
    }
    if (taxStr === "") taxStr = "$" + parseFloat(0).toFixed(2);
    return taxStr;
  };

  const columns = [
    {
      dataField: "Qty",
      text: "Qty",
      sort: true,
      attrs: {
        "data-title": "Qty",
      },
    },
    {
      dataField: "Name",
      text: "Name",
      sort: true,
      attrs: {
        "data-title": "Name",
      },
    },
    {
      dataField: "Price",
      text: "Price",
      sort: true,
      attrs: {
        "data-title": "Price",
      },
    },
    {
      dataField: "Tax",
      text: "Tax",
      sort: true,
      attrs: {
        "data-title": "Tax",
      },
    },
    {
      dataField: "Total",
      text: "Total",
      sort: true,
      attrs: {
        "data-title": "Total",
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size}
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: false, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: itemDetails.length,
      },
    ],
  };

  // Expand row
  const size = useWindowSize();
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: "0 px",
      height: "0 px",
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const expandRow = {
    renderer: (row) => (
      <Fade appear={true} in={true}>
        <ul>
          <li>
            <span className="dtr-title">Price</span>
            <span className="dtr-data">{row.Price || "-"}</span>
          </li>
          <li>
            <span className="dtr-title">Tax</span>
            <span className="dtr-data">{row.Tax || "-"}</span>
          </li>
          <li>
            <span className="dtr-title">Total</span>
            <span className="dtr-data">{row.Total || "-"}</span>
          </li>
        </ul>
      </Fade>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return expendColorShow(isAnyExpands);
    },
    expandColumnRenderer: ({ expanded }) => {
      return expendColorShow(expanded);
    },
  };
  const expendColorShow = (value) => {
    if (value) {
      return (
        <Image
          className="expand-row-icon"
          src={props?.dynamicImages?.minusIcon}
          alt="minus icon"
        />
      );
    }
    return (
      <Image
        className="expand-row-icon"
        src={props?.dynamicImages?.plusIcon}
        alt="plus icon"
      />
    );
  };

  const expandRow2 = {
    renderer: () => {},
  };

  return (
    <>
      <div className="custom-card card border-0 no-checkbox column-visibility-table auto-width no-box-shadow items-pos-table">
        <div className="with-scroll-on-table">
          <ToolkitProvider
            keyField="Id"
            key="Id"
            data={itemDetails}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="d-flex d-justify-content-between flex-column flex-md-row  align-items-md-center mb-3">
                  <div className="search-bar">
                    <SearchBar {...props.searchProps} />
                    {props.searchProps?.searchText?.length > 0 && (
                      <ClearSearchButton
                        className="clear"
                        {...props.searchProps}
                      />
                    )}
                  </div>
                </div>
                <div className="pagination-wrapper">
                  <div
                    className={
                      size.width !== undefined &&
                      size.width >= 1 &&
                      size.width <= 576
                        ? "expand-table-row "
                        : ""
                    }
                  >
                    <BootstrapTable
                      bootstrap4
                      headerClasses="table-header"
                      pagination={paginationFactory(options)}
                      keyField="Id"
                      key="Id"
                      dataColumn={itemDetails.title}
                      {...props.baseProps}
                      defaultSorted={defaultSorted}
                      expandRow={
                        size.width !== undefined &&
                        size.width >= 1 &&
                        size.width <= 576
                          ? expandRow
                          : expandRow2
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </>
  );
};

ItemsTable.propTypes = {
  discountInfo: PropTypes.shape({
    discountTotal: PropTypes.string,
    discountValue: PropTypes.string,
  }),
  taxList: PropTypes.array,
  taxRules: PropTypes.array,
  bottledeposit: PropTypes.number,
  promotionInfo: PropTypes.object,
  dynamicImages: PropTypes.shape({
    minusIcon: PropTypes.string,
    plusIcon: PropTypes.string,
  }),
  searchProps: PropTypes.shape({
    searchText: PropTypes.string,
  }),
  baseProps: PropTypes.object,
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    taxList: state.inventory.tax,
    taxRules: state.inventory.taxRule,
    bottledeposit: parseFloat(state.inventory.bottledeposit),
  };
};
export default connect(mapStateToprops)(ItemsTable);
