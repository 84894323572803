import React, { useEffect, useState } from "react";
import { Image, ListGroup } from "@themesberg/react-bootstrap";
import { connect, useDispatch } from "react-redux";
import {
  authUserName,
  getDeviceAndStore,
  getDeviceAndStoreName,
} from "../../axios/authHeader";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FirtsLetterUpperCase,
  OfflineData,
  isWeb,
} from "../../app/helpers/custom";
import {
  DEVICE,
  STORE,
  POSSETTINGDATA,
  GENERALSETIINGDATA,
  ITEMSALE,
  ADDCONFIRMSALEITEM,
  COMPLETEVALUE,
  ITEMSALEREFUND,
  ITEMSALELISTUPDATED,
  ITEMSALELIST,
  ORDERVALUE,
  ITEMSALELIST_GET_LIST,
  ITEMNOSALE,
  CANCELEDVALUE
} from "../../app/helpers/constant";
import { getSettingData } from "../../axios/authHeader";
import { checkOutSaleItem, confirmedResponseAction, checkOutOfflineRefundSaleItem } from "app/actions/saleItemAction";
import { editActionFunction } from "app/actions/getListAction";

const Footer = (props) => {
  const [tabCutOff, setTabCutOff] = useState(0);
  const [tabCutOffShow, setTabCutOffShow] = useState(false);
  const [alcCutOff, setAlcCutOff] = useState(0);
  const [alcCutOffShow, setAlcCutOffShow] = useState(false);
  const [statusCheck, setStatusCheck] = useState(1);
  const [bussinessName, setBussinessName] = useState("Bussiness Name");
  const [ , setPosSetting] = useState(getSettingData(POSSETTINGDATA));
  const [customerNum, setCustomerNum] = useState("1234");
  const [generalsetting, setGeneralSetting] = useState(
    getSettingData(GENERALSETIINGDATA)
  );
  const [offlineRecords, setOfflineRecords] = useState(0);

  const dispatch = useDispatch();

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  useEffect(() => {
    window.addEventListener("storage", () => {
      setGeneralSetting(getSettingData(GENERALSETIINGDATA));
      setPosSetting(getSettingData(POSSETTINGDATA));
    });
  }, []);

  useEffect(() => {
    console.log("props.posSetting props.posSetting ", props.posSetting);
    if (props.posSetting?.OPTION !== undefined) {
      if (
        props.posSetting?.OPTION?.tobacco_cutoff_age ||
        props.posSetting?.OPTION?.tobacco_cutoff_age !== ""
      ) {
        setTabCutOffShow(true);
      }

      if (
        props.posSetting?.OPTION?.alcohol_cutoff_age ||
        props.posSetting?.OPTION?.alcohol_cutoff_age !== ""
      ) {
        setAlcCutOffShow(true);
      }
      setTabCutOff(parseInt(props.posSetting?.OPTION?.tobacco_cutoff_age || 0));
      setAlcCutOff(parseInt(props.posSetting?.OPTION?.alcohol_cutoff_age || 0));
    }
  }, [props.posSetting]);

  useEffect(() => {
    if (generalsetting?.BUSINESS !== undefined) {
      setBussinessName(
        generalsetting?.BUSINESS?.business_name ||
        props?.generalSetting?.BUSINESS?.business_name
      );
      setCustomerNum(
        generalsetting?.BUSINESS?.customer_no ||
        props?.generalSetting?.BUSINESS?.customer_no
      );
    }
  }, [generalsetting, props.generalSetting]);

  useEffect(() => {
    if (props?.device.length > 0) updateLocalData(DEVICE, props.device);
    if (props?.store.length > 0) updateLocalData(STORE, props.store);
    localStorage.setItem("SystemStatus", true);
  }, [props.device, props.store]);

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    function updateOnlineStatus() {
      var condition = navigator.onLine ? 1 : 2;
      localStorage.setItem("SystemStatus", condition === 1 ? true : false);
      setStatusCheck(condition);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem("realtimeItem");
    });
    return () => {
      window.removeEventListener('beforeunload', () => {
        localStorage.removeItem("realtimeItem");
      });
    };
  }, []);

  useEffect(() => {
    let offline_sales = JSON.parse(localStorage.getItem("off_sale"));
    let off_refund_sales = JSON.parse(localStorage.getItem("off_refund_sales"));
    let off_void_sales = JSON.parse(localStorage.getItem("off_void_sales"));
    if (statusCheck === 1) {
      console.log("add you are still in online");
      if (offline_sales !== null) {
        console.log("offline_sales1", offline_sales)
        offline_sales.reverse();
        console.log("offline_sales2", offline_sales)
        let updating_sales = offline_sales.map((s) => {
          if (s.status != 2 && s.status != 3) {
            if (s?.noSale == true) {
              dispatch(
                checkOutSaleItem(
                  ITEMNOSALE,
                  s,
                  ADDCONFIRMSALEITEM,
                  COMPLETEVALUE,
                  s?.items[0]?.sitemid
                )
              );
              return;
            } else if (s?.status == CANCELEDVALUE) {
              dispatch(
                checkOutSaleItem(ITEMSALE, s, ADDCONFIRMSALEITEM, CANCELEDVALUE)
              );
              return;
            }
            dispatch(
              checkOutSaleItem(
                ITEMSALE,
                s,
                ADDCONFIRMSALEITEM,
                s?.status == 0 ? ORDERVALUE : COMPLETEVALUE,
                s?.items[0].saleId,
                true
              )
            );
          } else {
            dispatch(
              checkOutOfflineRefundSaleItem(
                ITEMSALE,
                s,
                ADDCONFIRMSALEITEM,
                s?.status == 0 ? ORDERVALUE : COMPLETEVALUE,
                s?.items[0].saleId,
                true
              )
            );
          }
          if (s?.status == 0) {
            let saleList = props?.saleList.filter((item) => item?.ref != s?.ref)
            dispatch(confirmedResponseAction({ data: saleList }, ITEMSALELIST_GET_LIST))
          }
          return s;
        });
        setOfflineRecords(0)
        localStorage.removeItem("off_sale");
      }
      if (off_refund_sales !== null) {
        let updating_refunds = off_refund_sales.map((s) => {
          if (!s?.isOfflineSaleRefund) {
            dispatch(
              editActionFunction(
                ITEMSALEREFUND,
                s.formData,
                s.id,
                ITEMSALELISTUPDATED
              )
            );
          }
          return s;
        });
        localStorage.removeItem("off_refund_sales");
      }
      if (off_void_sales !== null) {
        let updating_voids = off_void_sales.map((s) => {
          if (!s?.formData?.isOfflineSaleVoid) {
            dispatch(
              editActionFunction(
                ITEMSALELIST,
                s.formData,
                s.id,
                ITEMSALELISTUPDATED
              )
            );
          }
          return s;
        });
        localStorage.removeItem("off_void_sales");
      }
    } else {
      console.log("add you are still in offline");
    }
  }, [statusCheck])

  useEffect(() => {
    let offline_sales = JSON.parse(localStorage.getItem("off_sale"));
    console.log("offline_sales", offline_sales)
    if (!isWeb() && offline_sales) {
      setOfflineRecords(offline_sales?.length)
    }
  }, [localStorage.getItem("off_sale")])

  useEffect(()=>{
    console.log("offlineRecords", offlineRecords, statusCheck)
  }, [offlineRecords, statusCheck])

  useEffect(() => {
    if (!isWeb()) {
      setTimeout(
        function () {
          // OfflineData(uploadOfflineSales);
          function uploadOfflineSales(ress) {
            if (
              ress != undefined &&
              ress != null &&
              Object.keys(ress).length > 0
            ) {
              // setOfflineRecords(ress.data.items.length);
            } else {
              // setOfflineRecords(0);
            }
          }
        },
        [2000]
      );
    }
  }, [props.saleitem.offlineSale, props.saleList]);

  const updateLocalData = (type, data) => {
    if (data.length > 0) {
      switch (type) {
        case DEVICE:
          const device = data?.find(
            (item) => item.id === getDeviceAndStore()[0]
          );
          localStorage.setItem("deviceName", device?.name);
          break;
        case STORE:
          const store = data?.find(
            (item) => item.id === getDeviceAndStore()[1]
          );
          localStorage.setItem("storeName", store?.name);
          break;
        default:
          return false;
      }
    }
  };

  return (
    <>
      <div className="footer">
        <ListGroup horizontal>
          <ListGroup.Item>
            Customer # {customerNum ? customerNum : "1234"} | Support #
            844-919-2017
          </ListGroup.Item>
          <ListGroup.Item>
            {FirtsLetterUpperCase(bussinessName)} -
            {getDeviceAndStoreName(true)[0]} - {getDeviceAndStoreName(true)[1]}-{" "}
            {FirtsLetterUpperCase(authUserName())}
          </ListGroup.Item>
          <ListGroup.Item className={alcCutOffShow || tabCutOffShow ? 'show' : 'hide'}>
            {(alcCutOffShow || tabCutOffShow) && (
              <div>
                {alcCutOffShow &&
                  `Alcohol Cutoff: ${mm}/${dd}/${yyyy - alcCutOff} `}
                {tabCutOffShow && alcCutOffShow ? "|" : ""}
                {tabCutOffShow &&
                  `Tobacco Cutoff: ${mm}/${dd}/${yyyy - tabCutOff}`}
              </div>
            )}
          </ListGroup.Item>
          {props?.loading ? (
            <ListGroup.Item>
              Updating
              <span className="mx-1">
                {FirtsLetterUpperCase(
                  props?.msg !== "" ? props?.msg + "..." : ""
                )}
              </span>
              <FontAwesomeIcon icon={faCloudDownloadAlt} />
            </ListGroup.Item>
          ) : (
            <>
              <ListGroup.Item>
                <div>
                  {offlineRecords > 0 && statusCheck !== 1 && `${offlineRecords} Offline Records`}
                </div>
                POS is
                {statusCheck === 1 ? (
                  <>
                    <span className="mx-1 text-success">Online</span>
                    <Image
                      className="me-2"
                      src={props?.dynamicImages?.LogoutIcon}
                      alt="list Icon"
                    />
                  </>
                ) : (
                  <>
                    <span className="mx-1 text-danger">Offline</span>
                    <Image
                      className="me-2"
                      src={props?.dynamicImages?.cross}
                      alt="list Icon"
                    />
                  </>
                )}
              </ListGroup.Item>
            </>
          )}
        </ListGroup>
      </div>
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    loading: state.inventory.loading,
    device: state.inventory.devices,
    store: state.inventory.stores,
    msg: state.inventory.msg,
    accountSetting: state.inventory.accountSetting.data,
    posSetting: state.inventory.posSetting.data,
    saleitem: state.saleItem,
    saleList: state.saleItem.saleList,
  };
};
export default connect(mapStateToprops)(Footer);
