import React from "react";
import { Form } from "@themesberg/react-bootstrap";
import PropTypes from 'prop-types';

/*
 * Reuseable Functional Component :
 * This Componenet will return the Form's Inputs For Type Text, Number, Password.
 * Note : We Will Extended it for Radio and Checkbox
 */

const FormField = React.forwardRef((props, ref) => {
  const setActive = (el, active) => {
    const formField = el.parentNode;
    if (active) {
      formField.classList.add("form-field--is-active");
    } else {
      formField.classList.remove("form-field--is-active");
      el.value === ""
        ? formField.classList.remove("form-field--is-filled")
        : formField.classList.add("form-field--is-filled");
    }
  };

  [].forEach.call(document.querySelectorAll(".form-control"), (el) => {
    el.onblur = () => {
      setActive(el, false);
    };
    el.onfocus = () => {
      setActive(el, true);
    };
  });

  const {
    id,
    min,
    max,
    type,
    rows,
    name,
    label,
    errors,
    className,
    placeholder,
    defaultValue,
    handleOnClick,
    ...inputProps
  } = props;
  return (
    <>
      {type == "textarea" && (
        <Form.Control
          id={id}
          ref={ref}
          as={type}
          rows={rows}
          min={min}
          max={max}
          name={name}
          className={className}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleOnClick ? () => handleOnClick() : ""}
          {...inputProps}
        />
      )}
      {type !== "textarea" && (
        <Form.Control
          id={id}
          ref={ref}
          type={type}
          min={min}
          max={max}
          name={name}
          className={className}
          defaultValue={defaultValue === null ? "" : defaultValue}
          placeholder={placeholder}
          onChange={handleOnClick ? () => handleOnClick() : ""}
          {...inputProps}
        />
      )}
      {label && <Form.Label>{label}</Form.Label>}
      {errors && (
        <small className="text-xs text-right absolute-error">
          {errors.message}
        </small>
      )}
    </>
  );
});

FormField.propTypes = {
  id: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.string,
  rows: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleOnClick: PropTypes.func,
};

export default FormField;
