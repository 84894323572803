import React, { useEffect, useState } from "react";
import { Card, Fade, Image } from "@themesberg/react-bootstrap";
// Other imports
import BootstrapTable2 from "../components/BootsrapTable2";
import { getDeviceAndStore } from "../../axios/authHeader";
import { getListActionFunction } from "../../app/actions/getListAction";
import { REGISTERREPORT } from "../../app/helpers/constant";
import { connect, useDispatch } from "react-redux";
import { currencyFormat, isNumericData, getStartTimeInReport, FirtsLetterUpperCase, getTextAccToAppType } from "../../app/helpers/custom";
import CustomScroll from "react-custom-scroll";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { getDeviceAndStoreName, authUserName } from "../../axios/authHeader";

const RegisterReports = (props) => {
  const [registerReportData, setRegisterReportData] = useState([]);
  const [startingCash, setStartingCash] = useState(parseFloat(0));
  const [printPrvw, setPrintPrvw] = useState(false);
  const [bussinessName, setBussinessName] = useState("Bussiness Name");
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.registerReport) {
      setRegisterReportData(
        UpdateRegisterReport(
          props?.registerReport?.data?.data,
          props?.registerReport?.data?.payData
        )
      );
    }
  }, [props.registerReport]);

  useEffect(() => {
    if (props?.generalSetting?.BUSINESS !== undefined) {
      setBussinessName(
        props?.generalSetting?.BUSINESS?.business_name ??
          props?.generalSetting?.BUSINESS?.business_name
      );
    }
  }, [props.generalSetting]);

  useEffect(() => {
    if (registerReportData != undefined && registerReportData.length > 0) {
      setPrintPrvw(
        `<div class="print-container" id="printthiswin">
                    <table class="table table-bordered table-print">
                        <thead>
                            <tr>
                            <th></th>
                            <th>#Sales</th>
                            <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${registerReportData
                              .map(
                                (itm) => `<tr>
                                <td>${itm.sale ?? ""}</td>
                                <td>${itm.num ?? ""}</td>
                                <td>${itm.total ?? ""}</td>
                            </tr>`
                              )
                              .join(" ")}
                        </tbody>
                    </table>
                </div>`
      );
    }
  }, [registerReportData]);

  useEffect(() => {
    if (props?.AccountSetting?.DEFAULTSETTING) {
      let stcash = props?.AccountSetting?.DEFAULTSETTING?.defaultvalue || 0;
      if (
        localStorage.getItem("wpos_startingcash") !== null &&
        localStorage.getItem("wpos_startingcash") !== undefined
      ) {
        stcash = localStorage.getItem("wpos_startingcash");
      }
      setStartingCash(stcash);
    }
  }, [props.AccountSetting, props.registerReport]);

  useEffect(() => {
    if (props?.printClick === "register") {
      // handlePrint();.
      openInNewTab();
    }
    props.setPrintClick("");
  }, [props?.printClick]);

  const openInNewTab = (url) => {
    let printw = window.open("", "Dead Stock", "height=800,width=650");
    let store_name = getDeviceAndStoreName(true)[1] || ""
    printw.document.write(
      '<html><head><style>.itmvendorno {width: 100%!important;} .hidecol {display: none;}.table-print {margin-bottom: 20px;}.mytable, .table-print { color:#000; width:100%;border-collapse: collapse;border:1px solid #000;font-family:"Source Sans Pro", sans-serif;} .mytable thead:first-child td{font-weight:bold;color:black;} .mytable th, .mytable td, .table-print td, .table-print th {border:1px solid #ccc;padding:5px 10px; font-size: 14px;} .mytable th small {font-size: 14px;} .mytable thead th:last-child, .mytable tr td:last-child {display: none;} @media print {#scrollableDiv{width: 100%;height: 100%;}  .visible-print{display: block; width: auto;height: auto;overflow:visible;}}.dataTables_sizing {height: 40px !important;overflow: auto;} table.mytable td input {border: none;text-align: left;font-family:"Source Sans Pro", sans-serif;font-size:14px; text-transform: uppercase;} .itemname{text-transform: uppercase;} table.mytable th, table.mytable td {text-align: center; white-space: nowrap;} .table-print th, .table-print td {text-align:center; white-space: normal;}table.mytable th:nth-child(2), table.mytable td:nth-child(2) {text-align: left;} @page {size: landscape} </style>'
    );
    // const typeContent = reportType == "Deadstock" ? "- Items Not Sold in "+filterTypDeadStock+" Days" : reportType == "Overstock" ? " By "+filterTypOverStock+"X" : reportType == "expense" && filterTypExpenseReports != "" ? " By "+ filterTypExpenseReports : "";
    printw.document.write(
      '</head><body style="background-color: #FFFFFF;font-family:"Source Sans Pro", sans-serif;"><h2 style="text-align: center; ">' +
      store_name  +
        " Register Report</h2>"
    );
    printw.document.write(
      '<h4 style="text-align: center; ">Range: ' +
        moment(getStartTimeInReport()).format("MM/DD/YY HH:mm:ss") + "- " +
        moment().format("MM/DD/YY HH:mm:ss") + "-" +
        FirtsLetterUpperCase(bussinessName) + "-" +
        FirtsLetterUpperCase(authUserName()) +
        "</h4>"
    );
    printw.document.write('<table class="mytable">');
    printw.document.write("<thead>");
    printw.document.write("</thead> <tbody>");
    printw.document.write("</tbody></table>");
    printw.document.write(printPrvw);
    printw.document.write("</body></html>");
    printw.document.close();
    let printed = false;
    console.log({ check: printw });
    if (!printed) {
      printed = true;
      printw.focus();
      printw.print();
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <>
        <div className="print-container" ref={ref}>
          <div
            style={{
              textAlign: "center",
              marginBottom: "5px",
              fontSize: "18px",
            }}
          >
            <h5 style={{ fontSize: "18px", margin: "15px 0 10px 18px" }}>
              {getDeviceAndStoreName(true)[1]} Register Report{" "}
            </h5>
            <h5
              id="dayreportdate"
              style={{ fontSize: "18px", margin: "0 0 10px 18px" }}
            >
              {moment(props?.dateRange?.start).format("YYYY-MM-DD hh:mm")} -{" "}
              {moment(props?.dateRange?.end).format("YYYY-MM-DD hh:mm")}
            </h5>
          </div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th># Sales</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {props.data.map((elem, index) => {
                return (
                  <>
                    <tr>
                      <td>{elem.sale}</td>
                      <td>{elem.num}</td>
                      <td>{elem.total}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  });

  function UpdateRegisterReport(repdata, payData) {
    const temparray = [];
    const bottledepositstatus = false;
    console.log(repdata);
    if (repdata !== undefined) {
      if (repdata.salenum === 0 && repdata.salerefs === "") {
        temparray.push([
          "Gross Sales",
          repdata.salenum,
          currencyFormat(repdata.saletotal),
        ]);
      } else {
        temparray.push([
          "Gross Sales",
          repdata.salenum,
          currencyFormat(repdata.saletotal),
        ]);
      }
      if (repdata.lottosalenum === 0 && repdata.lottosalerefs === "") {
        temparray.push([
          "Lotto Sales",
          repdata.lottosalenum,
          currencyFormat(repdata.lottosaletotal),
        ]);
      } else {
        temparray.push([
          "Lotto Sales",
          repdata.lottosalenum,
          currencyFormat(repdata.lottosaletotal),
        ]);
      }
      if (repdata.onlinelotterynum === 0 && repdata.onlinelotteryrefs === "") {
        temparray.push([
          "Online Lottery",
          repdata.onlinelotterynum,
          currencyFormat(repdata.onlinelotterytotal),
        ]);
      } else {
        temparray.push([
          "Online Lottery",
          repdata.onlinelotterynum,
          currencyFormat(repdata.onlinelotterytotal),
        ]);
      }
      if (repdata.feeitemsalenum === 0 && repdata.feeitemsalerefs === "") {
        temparray.push([
          "Fee Sales",
          repdata.feeitemsalenum,
          currencyFormat(repdata.feeitemsaletotal),
        ]);
      } else {
        temparray.push([
          "Fee Sales",
          repdata.feeitemsalenum,
          currencyFormat(repdata.feeitemsaletotal),
        ]);
      }
      if (repdata.houseitemsalenum === 0 && repdata.houseitemsalerefs === "") {
        temparray.push([
          "House Account Pay",
          repdata.houseitemsalenum,
          currencyFormat(repdata.houseitemsaletotal),
        ]);
      } else {
        temparray.push([
          "House Account Pay",
          repdata.houseitemsalenum,
          currencyFormat(repdata.houseitemsaletotal),
        ]);
      }
      if (repdata.refundnum === 0 && repdata.refundrefs === "") {
        temparray.push([
          "Refunds",
          repdata.refundnum,
          currencyFormat(repdata.refundtotal),
        ]);
      } else {
        temparray.push([
          "Refunds",
          repdata.refundnum,
          currencyFormat(repdata.refundtotal),
        ]);
      }
      if (repdata.voidnum === 0 && repdata.voidrefs === "") {
        temparray.push([
          "Voids",
          repdata.voidnum,
          currencyFormat(repdata.voidtotal),
        ]);
      } else {
        temparray.push([
          "Voids",
          repdata.voidnum,
          currencyFormat(repdata.voidtotal),
        ]);
      }
      temparray.push([
        "Sales Tax",
        repdata.taxqty,
        currencyFormat(repdata.taxes),
      ]);
      if (bottledepositstatus || repdata.itemdeposit !== null) {
        if (repdata.itemdepositcount === 0 && repdata.itemdepositrefs === "") {
          temparray.push([
            `${getTextAccToAppType("Bottle")} Deposit`,
            repdata.itemdepositcount,
            currencyFormat(repdata.itemdeposit),
          ]);
        } else {
          temparray.push([
            `${getTextAccToAppType("Bottle")} Deposit`,
            repdata.itemdepositcount,
            currencyFormat(repdata.itemdeposit),
          ]);
        }
      }
      if (bottledepositstatus || repdata.itemdepositreturn !== null) {
        if (
          repdata.itemdepositreturncount === 0 &&
          repdata.itemdepositreturnrefs === ""
        ) {
          temparray.push([
            "Deposit Return",
            repdata.itemdepositreturncount,
            currencyFormat(repdata.itemdepositreturn),
          ]);
        } else {
          temparray.push([
            "Deposit Return",
            repdata.itemdepositreturncount,
            currencyFormat(repdata.itemdepositreturn),
          ]);
        }
      }
      if (repdata.hasOwnProperty("additionalchargeslisting")) {
        if (Object.keys("repdata.additionalchargeslisting").length > 0) {
          for (var key in repdata.additionalchargeslisting) {
            temparray.push([
              repdata.additionalchargeslisting[key].labelname +
                " Additional Fee",
              repdata.additionalchargeslisting[key].addnlcount,
              currencyFormat(repdata.additionalchargeslisting[key].total),
            ]);
          }
        }
      }
      if (repdata.tipscount === 0 && repdata.tipsrefs === "") {
        temparray.push([
          "Tips Amount",
          repdata.tipscount,
          currencyFormat(repdata.tipsamounttotal),
        ]);
      } else {
        temparray.push([
          "Tips Amount",
          repdata.tipscount,
          currencyFormat(repdata.tipsamounttotal),
        ]);
      }
      if (repdata.discountref === "") {
        temparray.push([
          "Total Discount",
          "-",
          currencyFormat(repdata.discountamt),
        ]);
      } else {
        var discount = repdata.hasOwnProperty("discountamt")
          ? parseFloat(repdata.discountamt)
          : 0;
        if (
          repdata.lottosalediscount !== "" &&
          repdata.lottopayoutdiscount !== ""
        ) {
          discount +=
            parseFloat(repdata.lottosalediscount) +
            parseFloat(repdata.lottopayoutdiscount);
        }
        temparray.push([
          "Total Discount",
          repdata.discountqty,
          currencyFormat(discount),
        ]);
      }
      if (repdata.nontaxable === "") {
        temparray.push([
          "Non Taxable",
          repdata.qtytotal,
          currencyFormat(repdata.nontaxable),
        ]);
      } else {
        temparray.push([
          "Non Taxable",
          repdata.qtytotal,
          currencyFormat(repdata.nontaxable),
        ]);
      }
      if (repdata.salerounding && repdata.salerounding !== null) {
        temparray.push([
          "Sale Rounding",
          repdata?.salerounding_count,
          currencyFormat(repdata.salerounding),
        ]);
      }
      if (repdata.netsalenum === 0 && repdata.netsalerefs === "") {
        temparray.push([
          "Net Sales",
          repdata.netsalenum,
          currencyFormat(repdata.totaltakings),
        ]);
      } else {
        temparray.push([
          "Net Sales",
          repdata.netsalenum,
          currencyFormat(repdata.totaltakings),
        ]);
      }
      if (repdata.payoutnum === 0 && repdata.payoutrefs === "") {
        temparray.push([
          "Lotto Payout",
          repdata.payoutnum,
          currencyFormat(repdata.payouttotal),
        ]);
      } else {
        temparray.push([
          "Lotto Payout",
          repdata.payoutnum,
          currencyFormat(repdata.payouttotal),
        ]);
      }
      if (repdata.onlinepaynum === 0 && repdata.onlinepayrefs === "") {
        temparray.push([
          "Online Payout",
          repdata.onlinepaynum,
          currencyFormat(repdata.onlinepaytotal),
        ]);
      } else {
        temparray.push([
          "Online Payout",
          repdata.onlinepaynum,
          currencyFormat(repdata.onlinepaytotal),
        ]);
      }
      if (repdata.couponitemsalenum === 0 && repdata.onlinepayrefs === "") {
        temparray.push([
          "Coupon Sale ($)",
          repdata.couponitemsalenum,
          currencyFormat(repdata.couponitemsaletotal),
        ]);
      } else {
        temparray.push([
          "Coupon Sale ($)",
          repdata.couponitemsalenum,
          currencyFormat(repdata.couponitemsaletotal),
        ]);
      }
      if (repdata.couponitemsalenum === 0 && repdata.onlinepayrefs === "") {
        temparray.push([
          "Coupon Sale (%)",
          repdata.couponpercentitemsalenum,
          currencyFormat(repdata.couponpercentitemsaletotal),
        ]);
      } else {
        temparray.push([
          "Coupon Sale (%)",
          repdata.couponpercentitemsalenum,
          currencyFormat(repdata.couponpercentitemsaletotal),
        ]);
      }
      if (repdata.promocouponcount === 0 || repdata.promocouponcount === "") {
        temparray.push([
          "Promo Coupons",
          repdata.couponitemsalenum,
          currencyFormat(0),
        ]);
      } else {
        temparray.push([
          "Promo Coupons",
          repdata.promocouponcount,
          currencyFormat(repdata.promocoupondisc),
        ]);
      }
      if (repdata.cancelsalenum === 0 && repdata.cancelsalerefs === "") {
        temparray.push([
          "Canceled Sale",
          repdata.cancelsalenum,
          currencyFormat(repdata.cancelsaletotal),
        ]);
      } else {
        temparray.push([
          "Canceled Sale",
          repdata.cancelsalenum,
          currencyFormat(repdata.cancelsaletotal),
        ]);
      }
      if (repdata.hasOwnProperty("cashpayout")) {
        if (Object.keys("repdata.cashpayout").length > 0) {
          for (var key in repdata.cashpayout) {
            temparray.push([
              repdata.cashpayout[key].type +
                " " +
                repdata.cashpayout[key].payment_mode +
                " payout",
              repdata.cashpayout[key].count,
              currencyFormat(repdata.cashpayout[key].amount),
            ]);
          }
        } else {
          temparray.push(["Cash Payout", 0, currencyFormat(0.0)]);
        }
      }
      let payoutData = payData?.data;
      for (const payment in payoutData) {
        temparray.push([
          payment,
          payoutData[payment].salenum,
          currencyFormat(payoutData[payment].balance),
        ]);
      }
      let expectedCash = 0;
      if (payoutData) {
        if (
          payoutData.hasOwnProperty("Cash") &&
          isNumericData(payoutData.Cash.balance)
        ) {
          expectedCash =
            parseFloat(startingCash) + parseFloat(payoutData.Cash.balance);
        } else {
          expectedCash = parseFloat(startingCash) + 0.0;
        }
      }
      temparray.push(["Expected Cash", "", currencyFormat(expectedCash)]);
      temparray.push(["Cash Counted", "", currencyFormat(props?.totalCash)]);
      let mainData = temparray.map((elem) => {
        return {
          sale: elem[0],
          num: elem[1],
          total: elem[2],
        };
      });
      return mainData;
    }
  }

  const columns = [
    {
      dataField: "sale",
      text: "",
      attrs: {
        "data-title": "User",
      },
    },
    {
      dataField: "num",
      text: "# Sales",
      sort: true,
      attrs: {
        "data-title": "# Sales",
      },
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      attrs: {
        "data-title": "Total",
      },
    },
  ];

  // Expand row
  const size = useWindowSize();
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: "0 px",
      height: "0 px",
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const expandRow = {
    renderer: (row) => (
      <Fade appear={true} in={true}>
        <ul>
          <li>
            <span className="dtr-title">Total</span>
            <span className="dtr-data">{row.total || "-"}</span>
          </li>
        </ul>
      </Fade>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return expendColorShow(isAnyExpands);
    },
    expandColumnRenderer: ({ expanded }) => {
      return expendColorShow(expanded);
    },
  };
  const expendColorShow = (value) => {
    if (value) {
      return (
        <Image
          className="expand-row-icon"
          src={props?.dynamicImages?.minusIcon}
          alt="minus icon"
        />
      );
    }
    return (
      <Image
        className="expand-row-icon"
        src={props?.dynamicImages?.plusIcon}
        alt="plus icon"
      />
    );
  };

  const expandRow2 = {
    renderer: () => {},
  };
  return (
    <>
      <div style={{ display: "none" }}>
        <ComponentToPrint
          ref={componentRef}
          dateRange={props?.dateRange}
          data={registerReportData?.length ? registerReportData : []}
        />
      </div>
      <Card className="custom-card no-checkbox no-box-shadow border-0 report-with-scroll auto-width   column-visibility-table register-table">
        <div className=" with-scroll-on-table">
          <CustomScroll
            addScrolledClass
            heightRelativeToParent="calc(100% - 0px)"
            className={
              size.width !== undefined && size.width >= 1 && size.width <= 576
                ? "expand-table-row "
                : ""
            }
          >
            <BootstrapTable2
              columns={columns}
              data={registerReportData?.length ? registerReportData : []}
              keyField="id"
              noDataIndication={registerReportData?.length === 0 ? true : false}
              expandRow={
                size.width !== undefined && size.width >= 1 && size.width <= 576
                  ? expandRow
                  : expandRow2
              }
            />
          </CustomScroll>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    registerReport: state.inventory.registerReport,
    accountSetting: state.inventory.accountSetting.data,
    generalSetting: state.inventory.generalSetting.data,
  };
};
export default connect(mapStateToProps)(RegisterReports);
