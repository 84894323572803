// MobileRoutes.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Routes } from "../../routes";
import Register from "../register/register";
import Reports from '../reports/reports';
import Sales from '../sales/sales';
import WebOrders from "../webOrders/WebOrders";
import settings from '../settings/settings';

const MobileCashRegister = () => {
  return (
    <>
     <Switch>
      <Route exact path={Routes.Register.path} component={Register} />
      <Route exact path={Routes.Sales.path} component={Sales} />
      <Route exact path={Routes.Reports.path} component={Reports} />
      <Route exact path={Routes.WebOrders.path} component={WebOrders} />
      <Route exact path={Routes.Settings.path} component={settings} />
    </Switch>
    </>
  );
};

export default MobileCashRegister;
