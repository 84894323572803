import api from "../../axios/axios";
import {
  CASHRECONCILIATION,
  CUSTOMERDATA,
  GENERALSETIINGDATA,
  ITEMSALE,
  POSSIDEETTINGS,
  POSSETTINGDATA,
  HOUSEPAY,
  ITEMNOSALE,
  ITEMUPDATESALE,
  EMAILINVOICE,
  DEPOSITRETURNTYPE
} from "../helpers/constant";
import {
  refrenceNumber,
  mergeArrayData,
  combineObjectsAdditionalFee,
  getRandomId,
  taxDetailsByItemId,
  sumObjectsByKey,
  calculateFee,
  sumAdditionalFeebyFee,
  checkEbt
} from "../helpers/custom";
import {
  authUserId,
  getDeviceAndStore,
  getDeviceAndStoreName,
  getSettingData,
} from "../../axios/authHeader";
import moment from "moment";
import { roundOffToNext } from "../../pages/register/register";
import Localstorage from "app/storage/Localstorage";

export function addData(
  module,
  formData,
  saleStatus,
  id,
  isOfflineSale,
  giftResponse
) {
  const setting = getSettingData(GENERALSETIINGDATA);
  const device = getDeviceAndStore()[0];
  const storeId = getDeviceAndStore()[1];
  const cashRounding = getSettingData(POSSETTINGDATA)?.SALE?.cash_rounding;

  const roundOffTotalPay = (amount) => {
    if (typeof amount !== "number" || isNaN(amount)) {
      return amount; // Return the original amount if it is not a valid number
    }
    if (cashRounding == 0) {
      return amount.toFixed(2);
    } else if (cashRounding == 5 || cashRounding == 10) {
      return roundOffToNext(amount, cashRounding);
    } else {
      return amount.toFixed(2);
    }
  };

  const roundOffAddedValue = (originalAmount) => {
    const total = parseFloat(
      roundOffTotalPay(parseFloat(formData.totalAmt.Total.toFixed(2)))
    );
    return total - originalAmount;
  };

  switch (module) {
    case ITEMSALE: {
      const payments = formData.payment;
      const Details = formData.items;
      const accountSetting = formData.accountSetting
      const extraCost = formData?.totalAmt?.nonCashAdjType;
      const isEbtSale = checkEbt(payments)
      const extracostCalculate = [
        {
          total: parseFloat(formData?.totalAmt?.nonCashAdj.toFixed(2)),
          charge: 0,
          discount: Number(formData?.totalAmt?.nonCashAdj).toFixed(2),
          costname: extraCost !== "" ? extraCost?.split(",")[1] : "NonCash Adj",
          costtype: "non_cash_adjustment",
          costrate: extraCost !== "" ? extraCost?.split(",")[2] : "",
        },
      ];
      const totalSubtotal = Details.map((data) => {
        if (data.subtotal === "NaN") {
          return parseFloat(0);
        } else {
          return parseFloat(data.subtotal);
        }
      });
      const totalEbtSubtotal = Details.map((data) => {
        if (data.subtotal === "NaN") {
          return parseFloat(0);
        } else {
          return parseFloat(data.orgPrice);
        }
      });
      const totalSubCost = Details.map((data) => {
        if (data.cost === "NaN" || !data.cost) {
          return parseFloat(0);
        } else {
          return parseFloat(data.cost);
        }
      });
      const totalNumberItem = Details.map((data) => {
        if (data.qty === "NaN") {
          return parseFloat(0);
        } else {
          return parseFloat(data.qty);
        }
      });
      let codes = [];
      for (let codedata of Details) {
        if (codedata?.itemData?.code) {
          codes.push({
            sale_id: codedata.saleId,
            sitemid: codedata.id,
            qty: codedata.qty,
            name: codedata.manualname,
            code_id:
              codedata?.itemData?.code !== undefined
                ? codedata?.itemData?.code.map((codes) => {
                  return codes.id;
                })
                : 0,
            code:
              codedata?.itemData?.code !== undefined
                ? codedata?.itemData?.code.map((codes) => {
                  return codes.code;
                })
                : "",
          });
        }
      }

      const discounts = Details.map((data) => {
        return {
          sale_id: id,
          item_id: data.manual_item ? "" : data.id,
          discount: parseFloat(data?.totalDiscount).toFixed(2),
        };
      });

      // const loyaltyPoints = Details?.reduce(function (prev, current) {
      //   if (current?.loyaltyrewards == 1) {
      //     return prev + 0;
      //   } else {
      //     if (current?.itemData?.pointsmultiplier == 0) {
      //       return prev + +current.price;
      //     } else {
      //       return (
      //         prev +
      //         +current?.itemData?.pointsmultiplier *
      //         (current.price * current.qty)
      //       );
      //     }
      //   }
      // }, 0);
      const itemDeatils = Details.map((data) => {
        return {
          ref: refrenceNumber() + getRandomId(),
          sitemid: data.manual_item ? "" : data.id,
          unique_item_id: data.id,
          sale_id: data.saleId,
          qty: data.qty,
          name: data.manualname || "",
          unit: data.price,
          taxid:
            isEbtSale && data?.ebt
              ? accountSetting?.TAXRULEDATA?.deafultEbtTax
              : data.tax,
          increaseqty: data.increaseqty,
          tax:
            data.tax !== "" && isEbtSale && data?.ebt
              ? taxDetailsByItemId(
                  accountSetting?.TAXRULEDATA?.deafultEbtTax,
                  data.qty,
                  data.price,
                  formData.taxlist,
                  formData.taxRule,
                  data.fee,
                  formData.fees,
                  data.bottle_deposit,
                  formData.BottleDeposit,
                  data.additionalFeeEnable,
                  data.bottleDepositEnable,
                  data.bottleDepositTaxable
                )
              : data.tax !== "" &&
                  data?.itemData?.item_type !== DEPOSITRETURNTYPE
                ? taxDetailsByItemId(
                    data.tax,
                    data.qty,
                    data.price,
                    formData.taxlist,
                    formData.taxRule,
                    data.fee,
                    formData.fees,
                    data.bottle_deposit,
                    formData.BottleDeposit,
                    data.additionalFeeEnable,
                    data.bottleDepositEnable,
                    data.bottleDepositTaxable
                  )
                : {},
          taxTotal:
            data.tax !== "" && isEbtSale && data?.ebt
              ? taxDetailsByItemId(
                  accountSetting?.TAXRULEDATA?.deafultEbtTax,
                  data.qty,
                  data.price,
                  formData.taxlist,
                  formData.taxRule,
                  data.fee,
                  formData.fees,
                  data.bottle_deposit,
                  formData.BottleDeposit,
                  data.additionalFeeEnable,
                  data.bottleDepositEnable,
                  data.bottleDepositTaxable
                )?.total
              : data.taxTotal,
          price: isEbtSale && data?.ebt ? data.ebttotal : data.total,
          code_id:
            data?.itemData?.code !== undefined
              ? data.itemData.code.map((codes) => {
                  return codes.id;
                })
              : 0,
          code: data?.itemData?.code,
          itemrank: data.rank,
          size: data.size,
          fee: data.fee,
          itemdeposit: isNaN(+data.bottleDeposit) ? 0 : data.bottleDeposit,
          additionalFeeCalculate: calculateFee(
            data.fee,
            formData.fees,
            data.additionalFeeEnable,
            data.qty,
            data.price,
            data.id
          ),
          additionalcharges: data?.additionalFee,
          additionalfeetaxable: data?.additionalFee,
          discountamt:
            !isNaN(+data?.totalDiscount) && data?.totalDiscount !== ""
              ? parseFloat(data?.totalDiscount).toFixed(2)
              : 0,
          additionalfeenontaxable: "0",
          itemtype:
            data?.itemData?.item_type !== undefined
              ? data?.itemData?.item_type
              : data?.item_type
                ? data?.item_type
                : "",
          changed_price: data?.manual
            ? 0
            : Math.abs(+data?.item_original_price - data?.price),
          itemtags:
            data?.itemData?.tag !== undefined
              ? data?.itemData?.tag.map((tags) => {
                  return tags.tag_name;
                })
              : [],
          upcfull: 0,
          pointsmultiplier: data?.itemData?.pointsmultiplier,
          loyaltypointsrequired: "",
          donottrackinventory: data?.itemData?.donottrackinventory,
          desc: data?.itemData?.item_size_name,
          cost: data?.avg_cost ? data?.avg_cost : "0.00",
          unit_original: data?.showPrice,
          price_total:
            isEbtSale && data?.ebt ? data?.ebttotal : data?.showTotal,
          alt_name: data?.itemData?.item_shortcut_name,
          giftcarditem: false,
          minprice: +data?.itemData?.price ? +data?.itemData?.price : 0,
          tags:
            data?.itemData?.tag !== undefined
              ? data?.itemData?.tag.map((tags) => {
                  return tags.tag_name;
                })
              : [],
          donotdiscountitem:
            data?.discountTextForItem !== undefined ? true : false,
          categoryid: data?.itemData?.category,
          exclusenoncashadj: false,
          excludeloyaltyreward: false,
          showhideinventory: data?.itemData?.hideinventory,
          allowebt: data?.ebt,
          modifier: !data?.manual_item ? data?.itemData?.modifier : [],
          manual_item: data?.manual_item,
        };
      });
      const totaltax = itemDeatils.map((data) => {
        if (data.taxTotal === "NaN") {
          return parseFloat(0);
        } else {
          return parseFloat(data.taxTotal);
        }
      });
      const TaxData = mergeArrayData(
        itemDeatils.map((data) => {
          return data?.tax?.values;
        })
      );
      const additionalCharge = combineObjectsAdditionalFee(
        itemDeatils.map((data) => {
          return data?.additionalFeeCalculate;
        })
      );
      const rating = localStorage.getItem("customer_rating");
      let customer = localStorage.getItem("sale_customer");
      customer = JSON.parse(
        customer && customer !== null && customer !== "null" ? customer : "{}"
      );
      if (isOfflineSale === true) {
        customer = formData.customer;
      }
      localStorage.setItem("customer_rating", "");
      localStorage.setItem("sale_customer", null);
      localStorage.removeItem("customerNumPadHide", false);

      const totalSaleAmt = parseFloat(
        !isEbtSale ? roundOffTotalPay(parseFloat(formData.totalAmt.Total.toFixed(2))) : parseFloat(formData.totalAmt.Total.toFixed(2))
      );

      let [
        housesaleborrow,
        currentsaleborrow,
        housesalepaid,
        houseborrow,
        housepaid,
      ] = [undefined, undefined, undefined, false, false];

      let totalPaymentHouseSale = parseFloat(
        payments
          .filter((e) => e.method === HOUSEPAY)
          ?.reduce((a, b) => a + parseFloat(b.amount), 0)
      );

      if (customer && totalPaymentHouseSale) {
        currentsaleborrow = parseFloat(totalPaymentHouseSale).toFixed(2);
        housesaleborrow = parseFloat(
          totalPaymentHouseSale + parseFloat(customer.pendingbalance || 0)
        ).toFixed(2);
        houseborrow = true;
      }

      if (customer && customer?.houseSalePayment) {
        housesalepaid = totalSaleAmt;
        housesaleborrow = parseFloat(
          parseFloat(customer.pendingbalance || 0) - housesalepaid
        ).toFixed(2);
        housepaid = true;
      }
      let gift_resp = null;
      if (giftResponse) {
        gift_resp = {
          devid: device,
          status: giftResponse?.isApproved ? giftResponse?.isApproved : false,
          response: giftResponse,
        };
      }
      const ref = formData?.ref ? formData?.ref : refrenceNumber(device);
      const data = {
        total: totalSaleAmt,
        rounding: !isEbtSale ? parseFloat(
          roundOffAddedValue(parseFloat(formData.totalAmt.Total)).toFixed(2)
        ) : 0,
        // cashRounding: parseFloat(roundOffAddedValue(parseFloat(formData.totalAmt.Total)).toFixed(2)),
        promocouponcount: 0,
        promocoupondisc: 0,
        // promocouponcount: Object.keys(formData?.promotionData)?.length,
        // promocoupondisc: Object.values(formData?.promotionData)?.reduce(((a , b) => a + +b.off) , 0),
        isroundingenable: cashRounding == 0 ? false : true,
        extracosts: extraCost !== "" ? extracostCalculate : {},
        ref: ref,
        userid: authUserId(),
        device_id: device,
        custid: formData?.customer?.id || customer?.id || 0,
        custemail: formData?.customer?.email || customer?.email || "",
        custname: formData?.customer?.name || customer?.name || "",
        custphone: formData?.customer?.mobile || customer?.mobile || "",
        custnotes: formData?.customer?.notes || customer?.notes,
        customerage: customer.dob
          ? +moment().format("YYYY") - +moment(customer.dob).format("YYYY")
          : "",
        customerdob: formData?.customer?.dob || customer.dob || "",
        custpoints: !isOfflineSale
          ? customer?.points
          : formData?.customer?.totalpoints,
        totalLoyaltyPoints: String(customer?.sale_point || "0"),
        redeemTotalPoints: String(customer?.redeemTotalPoints || "0"),
        excludeloyaltytotal: customer?.excludeloyaltytotal || 0,
        cost: parseFloat(totalSubCost.reduce((a, b) => a + b, 0).toFixed(2)),
        subtotal: !isEbtSale ? parseFloat(
          totalSubtotal.reduce((a, b) => a + b, 0).toFixed(2)
        ): parseFloat(
          totalEbtSubtotal.reduce((a, b) => a + b, 0).toFixed(2)
        ),
        numitems: parseFloat(totalNumberItem.reduce((a, b) => a + b, 0)),
        processdt: refrenceNumber(),
        payments: payments,
        codes: codes,
        payout: 0,
        multipleDiscounts: {},
        discountvalue: discounts,
        discountindex: -1,
        discount: parseFloat(formData?.totalAmt?.mainValue || 0).toFixed(2),
        discountamt: parseFloat(formData?.totalAmt?.totalDiscount || 0).toFixed(
          2
        ),
        discount_value: formData?.totalAmt?.discountVal || 0,
        discount_symbol: formData?.totalAmt?.discountSymbol || "",
        totaltendered: formData.totalAmt.Total,
        promotions: {},
        additionalcharges: JSON.stringify(
          sumAdditionalFeebyFee(additionalCharge)
        ),
        manualpriceedit: 0,
        // totalLoyaltyPoints:
        //   formData.loyaltyPointsEnabled == 1
        //     ? loyaltyPoints * formData.loyaltyPointsPerDollar
        //     : 0,
        rating: rating ? parseInt(rating) : 0,
        scanpromocouponcode: [],
        // ...(parseFloat(totaltax.reduce((a, b) => a + b, 0).toFixed(2)) > 0 && {
          tax: parseFloat(totaltax.reduce((a, b) => a + b, 0).toFixed(2)),
        // }),
        taxdata: JSON.stringify(sumObjectsByKey(TaxData)),
        items: itemDeatils,
        remove_items_id: formData?.remove_items_id,
        dtType: setting?.FORMATES?.Timezone || "",
        store_id: storeId,
        status: saleStatus,
        iscancelorder: saleStatus === 6 ? true : false,
        register_id: getDeviceAndStore(true)[1],
        data: JSON.stringify({promotion : formData?.promotionData || {} , discount : Localstorage.getItem("discount_data") || {}}),
        housesaleborrow,
        currentsaleborrow,
        housesalepaid,
        houseborrow,
        housepaid,
        giftcard: giftResponse ? JSON.stringify(gift_resp) : null,
      };

      localStorage.setItem("ref", JSON.stringify(data.ref));
      localStorage.setItem("saleDate", new Date().getTime());
      localStorage.setItem("promotion_data", null);

      //update customer point and house account balance details
      // if (customer && Object.keys(customer).length) {
      //   let updateDetails = { ...customer };

      //   if (totalPaymentHouseSale) {
      //     updateDetails = {
      //       ...updateDetails,
      //       pendingbalance: parseFloat(
      //         totalPaymentHouseSale + parseFloat(customer.pendingbalance || 0)
      //       ).toFixed(2),
      //     };
      //   }

      //   if (housesalepaid) {
      //     updateDetails = {
      //       ...updateDetails,
      //       pendingbalance: parseFloat(
      //         parseFloat(customer.pendingbalance || 0) - housesalepaid
      //       ).toFixed(2),
      //     };
      //   }

      //   if (
      //     totalPaymentHouseSale ||
      //     housesalepaid
      //     // customer?.redeemTotalPoints > 0 ||
      //     // customer?.sale_point
      //   ) {
      //     api.put(
      //       `/api/customer/api/customerdetails/${customer.id}/`,
      //       updateDetails
      //     );
      //   }
      // }
      
      if (formData.email_invoice_data) {
        Localstorage.setItem("invoice_mail_detail", {
          ...formData.email_invoice_data,
          subject: `Invoice #${data.ref} ${getDeviceAndStoreName(true)[1]
            } reciept`,
        });
      }
      if (id) {
        return api.put(`api/sales/api/updatesale/${id}/`, data);
      } else {
        return api.post(`/api/sales/api/sales/`, data);
      }
    }
    case ITEMUPDATESALE:
      break;
    case EMAILINVOICE:
      api.post(`/api/sales/api/salesinvoice/email/`, formData);
    case POSSIDEETTINGS:
      {
        const body = {
          data: JSON.stringify(formData),
        };
        return api.put(
          `/api/setting/api/initialconfig/?store_id=${storeId}&device_id=${device}`,
          body
        );
      }
    case CASHRECONCILIATION:
      return api.post(`/api/inventory/api/registerclosing/`, formData);
    case CUSTOMERDATA:
      return api.post(`/api/customer/api/customer/`, formData);
    case ITEMNOSALE:
      return api.post(`/api/sales/api/sales/`, formData);
    default:
      return false;
  }
}

export function editData(type, formData, id) {
  switch (type) {
    case CUSTOMERDATA:
      return api.put(`api/customer/api/customerdetails/${id}/`, formData);
    default:
      return false;
  }
}

export default {
  addData,
  editData,
};
