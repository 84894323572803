import React, { useEffect, useState } from "react";
import { Image, ListGroup } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import Nothappy from "../../../assets/images/Not happy.svg";
import Ok from "../../../assets/images/Ok.svg";
import Awesome from "../../../assets/images/Awesome.svg";
import localBusiness from "../../../assets/images/support-local-business.svg";
import PropTypes from 'prop-types';

const ThankYou = (props) => {
  const [show, ] = useState(true);
  const [showReview, setShowReview] = useState(false);
  const [ratings, ] = useState({
    0: "No Rating",
    1: "Not Happy",
    5: "Awesome",
    3: "Ok",
  });
  // const CurrentUrl = window.location.protocol + "//" + window.location.host;

  // useEffect(() => {
  //   if (props?.rating === "0") {
  //     if(props?.review_url !== ""){
  //       setShowReview(true)
  //     }else{
  //       props.thankYouHide(false);
  //     }
  //   }
  // }, [props?.rating , props?.review_url])

  useEffect(() => {
    props.thankYouHide(show);
  }, [show]);

  const handleRating = (rating) => {
    // setShow((s) => !s);
    if (props?.review_url && props?.review_url !== "" && rating == 5) {
      setShowReview(true);
    } else {
      props.thankYouHide(false);
    }
    localStorage.setItem("customer_rating", rating);
  };

  return (
    <>
      {!showReview ? (
        <>
          <div
            className="thankyou-wrapper"
            style={{ display: show ? "flex" : "none" }}
          >
            <h1>Thank You!</h1>
            <p>How would you rate your experience?</p>
            <ListGroup>
              <ListGroup.Item>
                <Link to="#" title="Not Happy" onClick={() => handleRating(1)}>
                  {/* <img src={CurrentUrl + "/static/media/Not happy.svg"} alt="Not Happy" /> */}
                  <Image src={Nothappy} alt="Not Happy" />
                  <span>{ratings[1]}</span>
                </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                <Link to="#" title="OK" onClick={() => handleRating(3)}>
                  {/* <img src={CurrentUrl + "/static/media/Ok.svg"} alt="OK" /> */}
                  <Image src={Ok} alt="OK" />
                  <span>{ratings[3]}</span>
                </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                <Link to="#" title="Awesome" onClick={() => handleRating(5)}>
                  {/* <img src={CurrentUrl + "/static/media/Awesome.svg"} alt="Awesome" /> */}
                  <Image src={Awesome} alt="Awesome" />
                  <span>{ratings[5]}</span>
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </>
      ) : (
        <>
          <div
            className="thankyou-wrapper review-section"
            style={{ display: show ? "flex" : "none" }}
          >
            <div className="qr-code">
              {/* <img
                src={CurrentUrl + "/images/support-local-business.svg"}
                alt="Support local business"
              /> */}
              <Image src={localBusiness} alt="Support local business" />
              <QRCode style={{ zIndex: 1 }} value={props?.review_url} />
              <h1 className="mb-0">Leave us a review!</h1>
            </div>
          </div>
        </>
      )}
    </>
  );
};

ThankYou.propTypes = {
  thankYouHide: PropTypes.func.isRequired,
  review_url: PropTypes.string,
};

export default ThankYou;
