import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import getListReducer from "./getListReducer";
import saleItemReducer from "./saleItemReducer";
import SettingReducer from "./settingReducer";
// combine all reducer here
const RootReducer = combineReducers({
  auth: AuthReducer,
  inventory:getListReducer,
  saleItem:saleItemReducer,
  setting:SettingReducer
});

export default RootReducer;