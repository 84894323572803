import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  InputGroup,
} from "@themesberg/react-bootstrap";
import LoaderSpinner from "../../../components/Loader";
import moment from "moment";
import FormField from "../../components/formField";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ReactSelect, { components } from "react-select";
import CustomNumpad from "../../components/customNumpad";
import {
  checkUndefindAndNull,
} from "../../../app/helpers/custom";
import { authUserId, getDeviceAndStore } from "../../../axios/authHeader";

//payment mode
import {
  CASH,
  CHECK,
  CARD,
  REPRINT,
  OTHER,
  ETF,
} from "../../../app/helpers/constant";

//actions and constants
import {
  EXPENSE,
  EXPENSETYPE,
  EXPENSETYPE_GET_LIST,
  EXPENSE_ADDED,
  EXPENSE_CATEGORY,
  EXPENSE_CATEGORY_GET_LIST,
  EXPENSE_VENDOR,
  EXPENSE_VENDOR_GET_LIST,
} from "../../../app/helpers/constant";
import {
  getListActionFunction,
  addActionFunction,
} from "../../../app/actions/getListAction";

//redux
import { connect, useDispatch } from "react-redux";

//custom model
import CustomModal from "../../components/Modals";
import PropTypes from 'prop-types';

const AddPayout = (props) => {
  let payment_mode = [CASH, CHECK, CARD, ETF, OTHER];
  const dispatch = useDispatch();
  const [showCustomerNumpad, setShowCustomerNumpad] = useState(false);
  const [amount, setAmount] = useState({ value: "" });
  const [formData, setFormData] = useState(initialFormData());
  const [title, setTitle] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(true);
  const [arrowKeyClick, setArrowKeyClick] = useState(false);
  const [selectAllNumber, setSelectAllNumber] = useState(false);
  let formRef = useRef();
  const { ValueContainer, Placeholder } = components;

  useEffect(() => {
    let amt = formData?.amount;
    if (selectAllNumber) {
      amt = "";
      setSelectAllNumber(false);
    }
    if (amount.value === "ArrowLeft") {
      formRef.current[2].selectionEnd = 0;
      formRef.current[2].focus();
      setArrowKeyClick("left");
    } else if (amount.value === "ArrowRight") {
      formRef.current[2].selectionStart = String(amt).length;
      formRef.current[2].focus();
      setArrowKeyClick("right");
    } else if (amount.value === "Clear") {
      setFormData({ ...formData, amount: "" });
    } else if (amount.value === "-") {
      setFormData({ ...formData, amount: amt });
    } else {
      setFormData({
        ...formData,
        amount:
          arrowKeyClick === "left" ? amount.value + amt : amt + amount.value,
      });
    }
  }, [amount]);

  useEffect(() => {
    if (props?.disable) getDropdowns();
    else {
      setFormData(initialFormData());
      setShow1(false);
    }
  }, [
    props?.expense_type_list,
    props?.expense_category_list,
    props?.expense_vendor_list,
    props?.disable,
  ]);

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const getDropdowns = () => {
    try {
      dispatch(
        getListActionFunction(
          EXPENSE_VENDOR,
          props?.storeId,
          EXPENSE_VENDOR_GET_LIST
        )
      );

      dispatch(
        getListActionFunction(
          EXPENSE_CATEGORY,
          props?.storeId,
          EXPENSE_CATEGORY_GET_LIST
        )
      );

      dispatch(
        getListActionFunction(EXPENSETYPE, props?.storeId, EXPENSETYPE_GET_LIST)
      );
    } catch (error) {}
  };

  const handleChange = (type, id) => {
    if (type == "expense_date") {
      let expense_date = moment(id).format("MM/DD/YYYY");
      setFormData({ ...formData, [type]: expense_date });
    } else {
      setFormData({ ...formData, [type]: id });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!formData.type?.value || formData.type?.value === "") {
      return showError("Please select expense type.");
    } else if (
      !formData.payment_mode?.value ||
      formData.payment_mode?.value === ""
    ) {
      return showError("Please select payment mode");
    } else if (!formData.amount || formData.amount == 0) {
      return showError("Amount cannot be 0", "Error");
    } else if (formData.amount && isNaN(+formData.amount)) {
      return showError("Amount must be numeric", "Error");
    } else if (
      formData.payment_mode?.value === CHECK &&
      (!formData.check_number || formData.check_number === "")
    ) {
      return showError("Please enter check number.", "Error");
    } else if (!formData.expense_date || formData.expense_date === "") {
      return showError("Please select expense date");
    }
    // }else if(!formData.memo || formData.memo === ""){
    //   return showError("Please enter memo")
    // }else if(!formData.vendor?.value || formData.vendor?.value === ""){
    //   return showError("Please select expense vendor")
    // }else if(!formData.category?.value || formData.category?.value === ""){
    //   return showError("Please select category")
    // }
    if (formData.check_number === "") {
      delete formData.check_number;
    }
    dispatch(
      addActionFunction(
        EXPENSE,
        { ...formData, processdt: `${Date.now()}` },
        EXPENSE_ADDED
      )
    );
    props.print(REPRINT);
    props.printValues(formData);
  };

  const showError = (msg, title = "Required field") => {
    setTitle(title);
    setErrorMsg(msg);
    setShow(true);
  };

  function initialFormData(data) {
    let store = getDeviceAndStore();
    return {
      expense_date: checkUndefindAndNull(
        data?.expense_date ? data?.expense_date : new Date(),
        ""
      ),
      category: checkUndefindAndNull(data?.category, ""),
      payment_mode: { label: payment_mode[0], value: payment_mode[0] },
      amount: checkUndefindAndNull("0", ""),
      check_number: checkUndefindAndNull(data?.check_number, ""),
      type: {
        value: props?.expense_type_list[0]?.id,
        label: props?.expense_type_list[0]?.name?.toUpperCase(),
      },
      vendor: checkUndefindAndNull(data?.vendor, ""),
      invoice_number: checkUndefindAndNull(data?.invoice_number, ""),
      memo: checkUndefindAndNull(data?.memo, ""),
      store: checkUndefindAndNull(store[1], ""),
      register_id: checkUndefindAndNull(store[0], ""),
      user_id: checkUndefindAndNull(authUserId(), ""),
    };
  }

  function compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      {show1 && (
        <LoaderSpinner />
      )}
      <Form autoComplete="off" ref={formRef}>
        <Row>
          <Col xs={12} className="mb-3 custom-react-select">
            <Form.Group>
              <ReactSelect
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                styles={{
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top:
                      state.hasValue || state.selectProps.inputValue
                        ? -15
                        : "7px",
                    transition: "top 0.1s, font-size 0.1s",

                    transform:
                      (state.hasValue || state.selectProps.inputValue) &&
                      "scale(0.85)",
                    opacity:
                      (state.hasValue || state.selectProps.inputValue) && 1,
                  }),
                }}
                classNamePrefix="react-select"
                options={props?.expense_type_list?.sort(compare).map((list) => {
                  return {
                    ...list,
                    value: list.id,
                    label: list.name.toUpperCase(),
                  };
                })}
                value={formData.type}
                onChange={(e) => handleChange("type", e)}
                placeholder={"Select Type"}
              />
            </Form.Group>
          </Col>

          <Col xs={12} className="mb-3 custom-react-select">
            <Form.Group>
              <ReactSelect
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                styles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    overflow: "visible",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top:
                      state.hasValue || state.selectProps.inputValue
                        ? -15
                        : "7px",
                    transition: "top 0.1s, font-size 0.1s",

                    transform:
                      (state.hasValue || state.selectProps.inputValue) &&
                      "scale(0.85)",
                    opacity:
                      (state.hasValue || state.selectProps.inputValue) && 1,
                  }),
                }}
                classNamePrefix="react-select"
                options={payment_mode.sort(compare).map((name) => {
                  return {
                    value: name,
                    label: name.toUpperCase(),
                  };
                })}
                value={formData.payment_mode}
                onChange={(e) => handleChange("payment_mode", e)}
                placeholder={"Select Payment Mode"}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <div className="custom-numpad">
              <Form.Group className="form-floating">
                <FormField
                  type="text"
                  id="amount"
                  value={formData.amount}
                  onChange={(e) => handleChange("amount", e.target.value)}
                  onKeyPress={(e) => {
                    if (showCustomerNumpad && e.key === "Enter") {
                      setShowCustomerNumpad(false);
                    }
                  }}
                  onClick={(e) => {
                    e.target.select();
                    setSelectAllNumber(true);
                    setShowCustomerNumpad(true);
                  }}
                  onFocus={() => {
                    setArrowKeyClick(false);
                  }}
                  placeholder="Amount"
                  label="Amount"
                />
              </Form.Group>
              {showCustomerNumpad && (
                <CustomNumpad
                  type="CustomerKeypad"
                  setValue={setAmount}
                  hideNumpad={setShowCustomerNumpad}
                />
              )}
            </div>
          </Col>
          {formData.payment_mode.value == CHECK && (
            <Col md={6} className="mb-3">
              <Form.Group className="form-floating">
                <FormField
                  label="Check Number"
                  type="text"
                  name="Check Number"
                  value={formData.check_number}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => handleChange("check_number", e.target.value)}
                  placeholder="Check Number"
                />
              </Form.Group>
            </Col>
          )}

          <Col md={6} className="mb-3 ">
            <Form.Group className="custom-time-picker" id="birthday">
              <Form.Label>Date</Form.Label>
              <Datetime
                timeFormat={false}
                closeOnSelect={true}
                onChange={(e) => handleChange("expense_date", e._d)}
                name="remind_date"
                renderInput={(props, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={
                        formData.expense_date !== ""
                          ? moment(formData.expense_date).format("MM/DD/YYYY")
                          : ""
                      }
                      placeholder="mm/dd/yyyy"
                      onFocus={openCalendar}
                      onChange={() => {}}
                    />
                  </InputGroup>
                )}
              />
            </Form.Group>
          </Col>

          <Col
            md={formData.payment_mode.value == CHECK ? 6 : 12}
            className="mb-3"
          >
            <Form.Group className="form-floating">
              <FormField
                label="Memo"
                type="text"
                name="Memo"
                value={formData.memo}
                onFocus={(e) => e.target.select()}
                onChange={(e) => handleChange("memo", e.target.value)}
                placeholder="Memo"
              />
            </Form.Group>
          </Col>
          <Col xs={12} className="mb-3">
            <Form.Group className="form-floating">
              <FormField
                label="Invoice"
                type="text"
                name="Invoice"
                value={formData.invoice_number}
                onFocus={(e) => e.target.select()}
                onChange={(e) => handleChange("invoice_number", e.target.value)}
                placeholder="Invoice"
              />
            </Form.Group>
          </Col>
          <Col xs={12} className="mb-3 custom-react-select">
            <Form.Group>
              <ReactSelect
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                styles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    overflow: "visible",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top:
                      state.hasValue || state.selectProps.inputValue
                        ? -15
                        : "7px",
                    transition: "top 0.1s, font-size 0.1s",

                    transform:
                      (state.hasValue || state.selectProps.inputValue) &&
                      "scale(0.85)",
                    opacity:
                      (state.hasValue || state.selectProps.inputValue) && 1,
                  }),
                }}
                classNamePrefix="react-select"
                options={props?.expense_vendor_list
                  ?.sort(compare)
                  .map((list) => {
                    return {
                      ...list,
                      value: list.id,
                      label: list.name.toUpperCase(),
                    };
                  })}
                value={formData.vendor}
                onChange={(e) => handleChange("vendor", e)}
                placeholder={"Select Vendor"}
              />
            </Form.Group>
          </Col>
          <Col xs={12} className="mb-3 custom-react-select">
            <Form.Group>
              <ReactSelect
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                styles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    overflow: "visible",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top:
                      state.hasValue || state.selectProps.inputValue
                        ? -15
                        : "7px",
                    transition: "top 0.1s, font-size 0.1s",

                    transform:
                      (state.hasValue || state.selectProps.inputValue) &&
                      "scale(0.85)",
                    opacity:
                      (state.hasValue || state.selectProps.inputValue) && 1,
                  }),
                }}
                classNamePrefix="react-select"
                options={props?.expense_category_list
                  ?.sort(compare)
                  .map((list) => {
                    return {
                      ...list,
                      value: list.id,
                      label: list.name.toUpperCase(),
                    };
                  })}
                value={formData.category}
                onChange={(val) => handleChange("category", val)}
                placeholder={"Select Category"}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button
          variant="primary px-5 position-absolute form-cta-btn"
          type="button"
          onClick={onSubmit}
          title="Save"
        >
          Save
        </Button>
      </Form>

      <CustomModal
        open={show}
        handleClose={() => setShow(false)}
        dialogClassName={`custom-modal confirmation error-popup`}
        title={title}
        content={<h1 className="mb-3 mt-3">{errorMsg}</h1>}
        action={
          <>
            <Button
              type="button"
              onClick={() => setShow(false)}
              variant="success px-5 m-auto"
              title="Ok"
            >
              Ok
            </Button>
          </>
        }
      />
    </>
  );
};

AddPayout.propTypes = {
  disable: PropTypes.bool,
  expense_vendor_list: PropTypes.array,
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  selectProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  print: PropTypes.func,
  printValues: PropTypes.func,
  expense_type_list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  expense_category_list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

const mapStateToProps = (state) => {
  return {
    storeId: state.auth.storeId,
    disable: state.inventory.disabled,
    expense_type_list: state.inventory.expenseTypeList,
    expense_category_list: state.inventory.expenseCategoryList,
    expense_vendor_list: state.inventory.expenseVendorList,
  };
};

export default connect(mapStateToProps)(AddPayout);
