import React, { useEffect, useState } from "react";
import { logoutAction } from "../../app/actions/authAction";
import {
  CATEGORYCHANNEL,
  CATEGORYEVENT,
  CATEGORY_GET_LIST,
  CUSTOMER_CHANNEL,
  CUSTOMER_EVENT,
  CUSTOMER_GET_LIST,
  DEVICE_CHANNEL,
  IMAGEUPLOAD,
  ITEMSALELIST_GET_LIST,
  PROMOTIONS,
  SALECHANNEl,
  SALE_EVENT,
  SETTING_CHANNEL,
  SETTING_EVENT,
  TERMINAL_DEVICE_EVENT,
} from "../../app/helpers/constant";
import { usePusher } from "../../context/PusherContext";
import CustomModal from "./Modals";
import { Button, Image } from "@themesberg/react-bootstrap";
import {
  getDeviceAndStore,
  getMasterStoreId,
} from "../../axios/authHeader";
import { useDispatch, useSelector } from "react-redux";
import { getListActionFunction } from "../../app/actions/getListAction";
import { PusherUpdateFunction } from "../../app/helpers/custom";
import Localstorage from "../../app/storage/Localstorage";
import { ITEMCHANNEl, PROMOTION_EVENT } from "../../app/helpers/pusherConstant";

export const CommonPusherComponent = () => {
  const pusher = usePusher();
  const dispatch = useDispatch();
  const [messagePopup, setMessagePopup] = useState([]);
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [logoutTimer, setLogoutTimer] = useState(10);
  const [intervalId, setInterValId] = useState("");
  const promotion_list = useSelector((state) => state.inventory.promotions);
  const category_list = useSelector((state) => state.inventory.category);
  const salesList = useSelector((state) => state.saleItem.saleList);
  const images = useSelector((state) => state.inventory.images);
  // const posSetting = getSettingData(POSSETTINGDATA);

  //sales pusher changes

  useEffect(() => {
    function childEventCallback(data) {
      const { method, id } = data;
      const salesCopy = [...salesList];
      if (method === "delete" && id > 0) {
        const listUpdate = PusherUpdateFunction("delete", salesCopy, data);
        dispatch({
          type: ITEMSALELIST_GET_LIST,
          payload: { data: listUpdate },
        });
        return;
      }

      if (method === "put" || method === "add") {
        try {
          const pusherData = JSON.parse(data.data || "{}");

          if (pusherData.store_id !== getDeviceAndStore()[1]) {
            return;
          }

          const listUpdate = PusherUpdateFunction(
            method,
            salesCopy,
            pusherData
          );
          dispatch({
            type: ITEMSALELIST_GET_LIST,
            payload: { data: listUpdate },
          });

          // if (posSetting?.POS?.pos_record_include === 1) {
          //   if (+pusherData.device_id == +getDeviceAndStore()[0]) {

          //   }
          // } else {
          //   const listUpdate = PusherUpdateFunction(
          //     method,
          //     salesCopy,
          //     pusherData,
          //     true
          //   );
          //   dispatch({
          //     type: ITEMSALELIST_GET_LIST,
          //     payload: { data: listUpdate },
          //   });
          // }
        } catch (e) {}
      }
    }

    const channel = pusher.subscribe(SALECHANNEl);
    channel.bind(SALE_EVENT, childEventCallback);

    return () => {
      channel.unbind(SALE_EVENT, childEventCallback);
    };
  }, [salesList, pusher, dispatch]);

  //category pusher changes
  useEffect(() => {
    function childEventCallback(data) {
      if (data.method === "delete") {
        data.data = { id: data.id };
      }
      if (+data.master_store_id === +getMasterStoreId()) {
        let cat_list = [...category_list];
        let updatedList = PusherUpdateFunction(
          data.method,
          cat_list,
          data.data
        );
        dispatch({
          type: CATEGORY_GET_LIST,
          payload: { data: updatedList },
        });
      }
    }
    const channel = pusher.subscribe(CATEGORYCHANNEL);
    channel.bind(CATEGORYEVENT, childEventCallback);
    return () => {
      channel.unbind(CATEGORYEVENT, childEventCallback);
    };
  }, [pusher, category_list, dispatch]);

  //sales pusher changes
  useEffect(() => {
    function childEventCallback(data) {
      if (data.method === "delete") {
        let latestRef = JSON.parse(localStorage.getItem("ref"));
        if (latestRef) {
          if (latestRef === data.ref) {
            Localstorage.removeItem("payment_reprint");
            Localstorage.removeItem("saleitems_reprint");
          }
        }
      }
    }
    const channel = pusher.subscribe(SALECHANNEl);
    channel.bind(SALE_EVENT, childEventCallback);
    return () => {
      channel.unbind(SALE_EVENT, childEventCallback);
    };
  }, [pusher]);

  //settings pusher changes
  useEffect(() => {
    function childEventCallback(data) {
      if (data.type === IMAGEUPLOAD) {
        dispatch(
          getListActionFunction(
            IMAGEUPLOAD,
            getDeviceAndStore()[1],
            IMAGEUPLOAD
          )
        );
      }

      if (+data.store === +getDeviceAndStore()[1] && data.method === "update") {
        dispatch({
          type: data.type,
          payload: { data: [{ data: data.data?.data }] },
        });
      }
    }
    const channel = pusher.subscribe(SETTING_CHANNEL);
    channel.bind(SETTING_EVENT, childEventCallback);
    return () => {
      channel.unbind(SETTING_EVENT, childEventCallback);
    };
  }, [pusher, dispatch]);

  //promotion pusher changes
  useEffect(() => {
    function childEventCallback(data) {
      if (+data.storeid === +getDeviceAndStore()[1]) {
        if (data.method === "delete") {
          let id = data.data;
          data.data = [{ id }];
        }
        let newList = [...promotion_list];
        newList = PusherUpdateFunction(data.method, newList, data.data[0]);
        dispatch({
          type: PROMOTIONS,
          payload: newList,
        });
      }
    }
    const channel = pusher.subscribe(ITEMCHANNEl);
    channel.bind(PROMOTION_EVENT, childEventCallback);
    return () => {
      channel.unbind(PROMOTION_EVENT, childEventCallback);
    };
  }, [pusher, promotion_list, dispatch]);

  //customer pusher changes
  useEffect(() => {
    function childEventCallback(data) {
      if (getMasterStoreId() === data.masterstore) {
        let customerList = Localstorage.getItem("customer") || [];
        customerList = PusherUpdateFunction(
          data.method,
          customerList,
          data.data
        );
        dispatch({
          type: CUSTOMER_GET_LIST,
          payload: { data: { data: customerList } },
        });
      }
    }
    const channel = pusher.subscribe(CUSTOMER_CHANNEL);
    channel.bind(CUSTOMER_EVENT, childEventCallback);
    return () => {
      channel.unbind(CUSTOMER_EVENT, childEventCallback);
    };
  }, [pusher, dispatch]);

  //online device and messsanging changes
  useEffect(() => {
    function childEventCallback(data) {
      if (
        data?.data?.id === +getDeviceAndStore()[1] ||
        data?.data?.id ===
          +`${+getDeviceAndStore()[0]}${+getDeviceAndStore()[1]}`
      ) {
        if (data.method === "reset") {
          setLogoutPopup(true);
        } else {
          let msgList = messagePopup;
          msgList.push(data?.data?.message);
          setMessagePopup([...msgList]);
        }
      }
    }
    const channel = pusher.subscribe(DEVICE_CHANNEL);
    channel.bind(TERMINAL_DEVICE_EVENT, childEventCallback);
    return () => {
      channel.unbind(TERMINAL_DEVICE_EVENT, childEventCallback);
    };
  }, [pusher, messagePopup]);

  const logout = () => {
    dispatch(logoutAction());
  };

  const handleCancel = () => {
    setLogoutPopup(false);
    clearInterval(intervalId);
    setLogoutTimer(10);
  };

  useEffect(() => {
    if (logoutPopup) {
      let timer = 0;
      let intId = setInterval(() => {
        setLogoutTimer(logoutTimer - timer);
        if (timer === 10) {
          logout();
        }
        timer++;
      }, 1000);
      setInterValId(intId);
    }
  }, [logoutPopup, logout, setLogoutTimer]);

  const removeMessagePopUp = (e) => {
    let newList = messagePopup.filter((it) => it !== e);
    setMessagePopup(newList);
  };

  return (
    <>
      {/* online and messanging */}
      <div className="d-none">
        {images.map((e, index) => {
          return <Image key={index} src={e.file} />;
        })}
      </div>
      <CustomModal
        open={logoutPopup}
        handleClose={() => handleCancel()}
        dialogClassName="custom-modal confirmation"
        title="Reset Request"
        content={
          <>
            <p>
              The server has requested restart. This is usually to install an
              update. The terminal will restart in {logoutTimer} seconds.
            </p>
            Press Ok to restart now or Cancel to prevent the restart.
          </>
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5 me-2"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button variant="primary px-5 me-2" onClick={() => logout()}>
              Ok
            </Button>
          </>
        }
      />

      {messagePopup.length
        ? messagePopup.map((e, i) => {
            return (
              <CustomModal
                key={i}
                open={true}
                handleClose={() => removeMessagePopUp(e)}
                dialogClassName="custom-modal confirmation"
                title="Message"
                content={<h5>{e}</h5>}
                action={
                  <>
                    <Button
                      variant="outline-blackcustom px-5 me-2"
                      onClick={() => removeMessagePopUp(e)}
                    >
                      Ok
                    </Button>
                  </>
                }
              />
            );
          })
        : ""}
    </>
  );
};
