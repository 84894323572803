import {
  authUserId,
  getDeviceAndStore,
  getMasterStoreId,
  getSettingData,
} from "../../axios/authHeader";
import api from "../../axios/axios";
import {
  ITEM,
  ADDITIONALFEE_GET_LIST,
  ITEMMOSIFIER_GET_LIST,
  ITEMTAG_GET_LIST,
  PROMOTIONS,
  REEDEM_REWARDS,
  STOCKCODE_GET_LIST,
  ITEMIMAGE,
  CATEGORY,
  TAX,
  ACCOUNTSETTING,
  TAXRULE,
  BOTTLEDEPOSIT,
  ADDITIONALFEE,
  GENERALSETTING,
  ITEMSALE,
  DEVICE,
  ITEMUPDATE,
  STORE,
  TAG,
  ITEMTYPE,
  POSSETTING,
  POSSIDEETTINGS,
  REPORT,
  LASTCLOSINGTIME,
  IMAGEUPLOAD,
  REGISTERREPORT,
  CUSTOMERDATA,
  SUPPLIER,
  ITEMSIZE,
  STOCKCODECHECK,
  REMOVEMODIFIER,
  REMOVESTOCKCODE,
  STAFFANDADMIN,
  REMOVEDEVICEREGISTRATION,
  SHORTCUT,
  EXPENSETYPE,
  EXPENSE_CATEGORY,
  EXPENSE_VENDOR,
  EXPENSE,
  ITEMSALELIST,
  DAYREPORT,
  ITEMSALENOTE,
  ITEMSALEREFUND,
  GETITEMLIST,
  CLOCKIN,
  CLOCKOUT,
  HELLO,
  REGISTERCLOSINGTIME,
  REPORTTRNSACTION,
  USERDETAILS,
  LABELTEMPLATE_GET_LIST,
  ITEMUPDATELABELPRINT,
  BUSINESS_VALIDATE,
  CUSTOMERREWARDS,
  ITEMNOTFOUND,
  POSSETTINGDATA,
  GETIMAGEDATA,
  SALEEXTRACOST,
  SALEITEMS,
  SALECODES,
  SALEPAYMENTS,
  SALEVOID,
  SALEITEMCUSTOMER,
  PAYFACAUTHTOKEN,
  DECLINECARDADD,
} from "../helpers/constant";
import { getStartTimeInReport, getStimeForSale } from "../helpers/custom";

const ListData = (dataType, storeId, page) => {
  const device = getDeviceAndStore();
  switch (dataType) {
    case ITEM:
      return api.get(`/api/inventory/api/positemget/?store=${storeId}`);
    case STOCKCODE_GET_LIST:
      return api.get(`/api/inventory/api/allitemcodelist/?store=${storeId}`);
    case ITEMMOSIFIER_GET_LIST:
      return api.get(
        `/api/inventory/api/allitemmodifierlist/?store=${storeId}`
      );
    case ADDITIONALFEE_GET_LIST:
      return api.get(`/api/inventory/api/allitemfeelist/?store=${storeId}`);
    case ITEMTAG_GET_LIST:
      return api.get(`/api/inventory/api/allitemtaglist/?store=${storeId}`);
    case CATEGORY:
      return api.get(
        `/api/inventory/api/storeditemlist/?master_store=${getMasterStoreId()}`
      );
    case TAXRULE:
      return api.get(`/api/setting/api/createtaxrule/?store_id=${storeId}`);
    case TAX:
      return api.get(`/api/setting/api/createtaxitems/?store_id=${storeId}`);
    case ACCOUNTSETTING:
      return api.get(
        `/api/setting/api/config/?store_id=${storeId}&type=${dataType}`
      );
    case GENERALSETTING:
      return api.get(
        `/api/setting/api/config/?store_id=${storeId}&type=${dataType}`
      );
    case POSSETTING:
      return api.get(
        `/api/setting/api/config/?store_id=${storeId}&type=${dataType}`
      );
    case BOTTLEDEPOSIT:
      return api.get(`/api/inventory/api/bottledeposit/?store=${storeId}`);
    case ADDITIONALFEE:
      return api.get(
        `/api/inventory/api/createadvancesetting/?store=${storeId}`
      );
    case ITEMSALE: {
      const posSetting = getSettingData(POSSETTINGDATA);
      return api.get(`/api/sales/api/salelist/`, {
        params: {
          ...(posSetting?.POS?.pos_record_include === 1 && {
            device: getDeviceAndStore()[0],
          }),
          store: storeId.storeId,
          stime: getStimeForSale(posSetting?.POS?.pos_record_for_the_last),
          // page: storeId.page ? storeId.page : 1,
          // page_size: storeId.page_size ? storeId.page_size : 10,
          // ordering: storeId.ordering ? storeId.ordering : "-id",
          // status: storeId.status,
          // ref: storeId.ref ? storeId.ref : undefined,
          // search: storeId.search ? storeId.search : undefined,
        },
      });
    }

    case SALEEXTRACOST: {
      return api.get(`/api/sales/api/saleextracost/`, {
        params: {
          store: storeId.storeId,
        },
      });
    }

    case SALEITEMS: {
      return api.get(`/api/sales/api/saleitems/`, {
        params: {
          store: storeId.storeId,
        },
      });
    }
    case SALEPAYMENTS: {
      return api.get(`/api/sales/api/salepayements/`, {
        params: {
          store: storeId.storeId,
        },
      });
    }
    case SALEVOID: {
      return api.get(`/api/sales/api/salevoid/`, {
        params: {
          store: storeId.storeId,
        },
      });
    }

    case SALECODES: {
      return api.get(`/api/sales/api/salecodes/`, {
        params: {
          store: storeId.storeId,
        },
      });
    }
    case REPORT: {
      let startTime = getStartTimeInReport();
      return api.get(
        `/api/sales/api/salelist/?device=${device[0]}&stime=${startTime}`
      );
    }
    case STORE:
      return api.get(`/api/inventory/api/createstore`);
    case DEVICE:
      return api.get(`/api/inventory/api/createdevice/?store=${storeId}`);
    case LASTCLOSINGTIME:
      return api.get(`/api/sales/api/registerclosing/?device_id=${storeId}`);
    case POSSIDEETTINGS:
      return api.get(
        `/api/setting/api/initialconfig/?store_id=${storeId[1]}&device_id=${storeId[0]}`
      );
    case IMAGEUPLOAD:
      return api.get(`/api/setting/api/uploadimage/?store_id=${storeId}`);
    case REGISTERREPORT:
      {
        let etime = new Date();
        etime.setHours(23);
        etime.setMinutes(59);
        etime.setSeconds(59);
        etime = etime.getTime();
        let data = {
          deviceid: device[0],
          storeid: device[1],
          s_time: storeId.stime,
          e_time: etime,
          type: "",
          supplierids: "",
          categoryids: "",
          userid: authUserId(),
          payoutid: "",
        };
        return api.get(`/api/report/api/stats/registerreports/`, {
          params: data,
        });
      }
    case DAYREPORT: {
      let etime = new Date();
      etime.setHours(23);
      etime.setMinutes(59);
      etime.setSeconds(59);
      etime = etime.getTime();
      const data = {
        s_time: storeId.start
          ? new Date(storeId.start).getTime()
          : getStartTimeInReport(),
        e_time: storeId.end ? new Date(storeId.end).getTime() : etime,
        storeid: device[1],
        type: "",
        deviceid: device[0],
        supplierids: "",
        categoryids: "",
        userid: authUserId(),
        payoutid: "",
        posdayreport: "true",
      };
      return api.get(`/api/report/api/stats/registerreports/`, {
        params: data,
      });
    }
    case CUSTOMERDATA:
      return api.get(
        `/api/customer/api/customer/?masterstore=${getMasterStoreId()}`
      );
    case SUPPLIER:
      return api.get(
        `/api/inventory/api/storedsupplierlist/?master_store=${getMasterStoreId()}`
      );
    case TAG:
      return api.get(
        `/api/inventory/api/itemtaglist/?master_store=${getMasterStoreId()}`
      );
    case ITEMTYPE:
      return api.get(`/api/inventory/api/itemtypelist/?store=${storeId}`);
    case ITEMSIZE:
      return api.get(
        `/api/inventory/api/itemsizelist/?master_store=${getMasterStoreId()}`
      );
    case ITEMUPDATE:
      return api.get(`/api/inventory/api/itemdetail/${storeId}`);
    case ITEMUPDATELABELPRINT:
      return api.get(`/api/inventory/api/itemdetail/${storeId}`);
    case STAFFANDADMIN:
      return api.get(`/api/staff/api/users`);
    case EXPENSETYPE:
      return api.get(`api/inventory/api/expensetypelist/?store=${storeId}`);
    case EXPENSE_CATEGORY:
      return api.get(`api/inventory/api/expensecatlist/?store=${storeId}`);
    case EXPENSE_VENDOR:
      return api.get(`api/inventory/api/expensevendorlist/?store=${storeId}`);
    case GETITEMLIST:
      return api.post(`api/inventory/api/itemsget/`, { ids: page });
    case HELLO:
      return api.get(`/api/staff/api/profile`);
    case REGISTERCLOSINGTIME:
      return api.get(
        `api/inventory/api/getregisterreport/?store=${
          device[1]
        }&master=${getMasterStoreId()}`
      );
    case REPORTTRNSACTION:
      return api.post(`/api/sales/api/transactions/get/`, storeId);
    case USERDETAILS:
      return api.get(`/api/staff/api/userdetails/${storeId}/`);
    case LABELTEMPLATE_GET_LIST:
      return api.get(`api/setting/api/labeltemplates/?store_id=${storeId}`);
    case CUSTOMERREWARDS:
      return api.get(
        `/api/customer/api/customerreward/?masterstore=${getMasterStoreId()}`
      );
    case PROMOTIONS:
      return api.get(
        `/api/inventory/api/stockpromotionlist/?store_id=${storeId}`
      );
    default:
      return false;
  }
};

const DeleteRow = (type, id) => {
  switch (type) {
    case REMOVEMODIFIER: {
      return api.delete(`/api/inventory/api/itemsmodifierdetail/${id}/`);
    }
    case REMOVESTOCKCODE: {
      return api.delete(`/api/inventory/api/itemcodedetail/${id}/`);
    }
    case REMOVEDEVICEREGISTRATION: {
      return api.delete(`/api/setting/api/registerdevice/${id}/`);
    }
    default:
      return false;
  }
};
const UpdateData = (type, formdata, id) => {
  switch (type) {
    case ITEM: {
      const formDetail = formdata;
      const codelist = formDetail.STOCKCODE.code;
      const stockcode = [];
      for (const i in codelist) stockcode.push(codelist[i]);

      const modifierlist = formDetail.MODIFIER.list;
      const modifer = [];
      for (const i in modifierlist) modifer.push(modifierlist[i]);

      const additionalFee = formDetail.ADDITIONALFEE.fee;
      const tags = formDetail.TAG.tags;
      const additionalFeeArr = [];
      const compareTag = [];
      const compareFee = [];

      additionalFee.map((data) => {
        if (data.stored_item !== undefined) {
          additionalFeeArr.push(data.id);
          compareFee.push(data.id);
        } else {
          additionalFeeArr.push(data.id);
        }
        return null;
      });

      const tagArr = [];
      tags.map((data) => {
        if (data.stored_item !== undefined) {
          tagArr.push(data.id);
          compareTag.push(data.id);
        } else {
          tagArr.push(data.id);
        }
        return null;
      });

      const deletedFee = [];
      const deletedTag = [];

      formDetail.COLLECTEDITEM.fee.map((data) => {
        if (data.stored_item !== undefined) {
          deletedFee.push(data.id);
        }
      });
      formDetail.COLLECTEDITEM.tag.map((data) => {
        if (data.stored_item !== undefined) {
          deletedTag.push(data.id);
        }
      });
      const feeDeleted = deletedFee.filter(function (obj) {
        return compareFee.indexOf(obj) == -1;
      });
      const tagDeleted = deletedTag.filter(function (obj) {
        return compareTag.indexOf(obj) == -1;
      });

      const data = {
        name: formDetail.DATA.name,
        item_size: formDetail.DATA.item_size,
        default_qty: formDetail.DATA.default_qty,
        category: formDetail.DATA.category,
        supplier: formDetail.DATA.supplier,
        tax: formDetail.DATA.tax,
        promptforqty: formDetail.DATA.promptforqty,
        donotdiscount: formDetail.DATA.donotdiscount,
        donottrackinventory: formDetail.DATA.donottrackinventory,
        ebteligible: formDetail.DATA.ebteligible,
        hideinventory: formDetail.DATA.hideinventory,
        autoupdate: formDetail.DATA.autoupdate,
        closeoutitem: formDetail.DATA.closeoutitem,
        printlabel: formDetail.DATA.printlabel,
        shortcutkeys: formDetail.DATA.shortcutkeys,
        showtoweb: formDetail.DATA.showtoweb,
        totalitem: formDetail.DATA.totalitem,
        modifier: modifer,
        cases: formDetail.DATA.cases,
        vendoritemno: formDetail.DATA.vendoritemno,
        sku: formDetail.DATA.sku,
        unit_per_case: formDetail.DATA.unit_per_case,
        reorder_point: parseInt(formDetail.DATA.reorder_point),
        reorder_value: parseInt(formDetail.DATA.reorder_value),
        item_rank: formDetail.DATA.item_rank,
        item_name: formDetail.DATA.vendoritemname,
        price: formDetail.DATA.price,
        data: formDetail.DATA.note,
        case_cash_total: formDetail.DATA.case_cash_total,
        tag_id: tagDeleted,
        additional_fee: feeDeleted,
        item_type: formDetail.DATA.item_type,
        item_shortcut_no: formDetail.DATA.item_shortcut_no,
        item_shortcut_name: formDetail.DATA.item_shortcut_name,
        bottle_deposit: formDetail.DATA.bottle_deposit,
        color_code: formDetail.DATA.color_code,
        remind_date: formDetail.DATA.remind_date,
        pointsmultiplier: formDetail.DATA.pointsmultiplier,
        pointsrequired: formDetail.DATA.pointsrequired,
        code: stockcode,
        tag: tagArr,
        fee: additionalFeeArr,
        store: formDetail.StoreId,
        user_id: formDetail.user_id,
        user_name: formDetail.user_name,
        uuid: formDetail.uuid,
      };
      const finalData = { ...data, rawdata: JSON.stringify(data) };
      return api.put(`/api/inventory/api/itemdetail/${id}/`, finalData);
    }
    case SHORTCUT: {
      return api.post(`/api/inventory/api/itemdragdrop/`, formdata);
    }
    case ITEMSALELIST: {
      return api.post(`/api/sales/api/adminvoid/`, {
        sale: +id,
        store_id: formdata.storeId,
        user_id: authUserId(),
        reason: formdata.reason,
        processdt: `${Date.now()}`,
        void: 1,
        status: 1,
        method: "Pos",
        isOfflineSaleVoid: formdata?.isOfflineSaleVoid != undefined ? formdata?.isOfflineSaleVoid : null,
        ref: formdata.ref
      });
    }

    case ITEMSALENOTE: {
      return api.put(`api/sales/api/updatesale/${id}/`, formdata);
    }

    case ITEMSALEREFUND: {
      return api.post(`/api/sales/api/voidrefund/`, {
        sale: id,
        store_id: formdata.storeId,
        user_id: authUserId(),
        processdt: `${Date.now()}`,
        refund: 1,
        void: 0,
        status: 0,
        // items_id : formdata.itemsId,
        transaction_type: formdata.method === "CASH" ? 1 : 2,
        method: formdata.method,
        amount: formdata.amount,
        isOfflineSaleRefund: formdata?.isOfflineSaleRefund != undefined ? formdata?.isOfflineSaleRefund : null,
        ref: formdata.ref,
      });
    }

    default:
      return "";
  }
};

const AddData = (type, formdata) => {
  switch (type) {
    case EXPENSE:
      formdata.type = formdata?.type?.value;
      formdata.category = formdata?.category?.value;
      formdata.vendor = formdata?.vendor?.value;
      formdata.payment_mode = formdata?.payment_mode?.value;
      return api.post(`/api/inventory/api/expenselist/`, formdata);
    case ITEM: {
      const formDetail = formdata;
      const codelist = formDetail.STOCKCODE.code;
      const stockcode = [];
      for (const i in codelist)
        stockcode.push({
          code: codelist[i].code,
          upccode: codelist[i].upccode,
        });
      const onlycode = [];
      for (const i in codelist) onlycode.push(codelist[i].code);
      const modifierlist = formDetail.MODIFIER.list;
      const modifer = [];
      for (const i in modifierlist) modifer.push(modifierlist[i]);

      const additionalFee = formDetail.ADDITIONALFEE.fee;
      const additionalFeeArr = [];
      for (const i in additionalFee) additionalFeeArr.push(additionalFee[i].id);

      const tags = formDetail.TAG.tags;
      const tagArr = [];
      for (const i in tags) tagArr.push(tags[i].id);

      const data = {
        name: formDetail.DATA.name,
        item_size: formDetail.DATA.item_size,
        default_qty: formDetail.DATA.default_qty,
        category: formDetail.DATA.category,
        supplier: formDetail.DATA.supplier,
        tax: formDetail.DATA.tax,
        promptforqty: formDetail.DATA.promptforqty,
        donotdiscount: formDetail.DATA.donotdiscount,
        donottrackinventory: formDetail.DATA.donottrackinventory,
        ebteligible: formDetail.DATA.ebteligible,
        hideinventory: formDetail.DATA.hideinventory,
        autoupdate: formDetail.DATA.autoupdate,
        closeoutitem: formDetail.DATA.closeoutitem,
        printlabel: formDetail.DATA.printlabel,
        shortcutkeys: formDetail.DATA.shortcutkeys,
        showtoweb: formDetail.DATA.showtoweb,
        totalitem: formDetail.DATA.totalitem,
        itemdata: modifer,
        cases: formDetail.DATA.cases,
        vendoritemno: formDetail.DATA.vendoritemno,
        sku: formDetail.DATA.sku,
        unit_per_case: formDetail.DATA.unit_per_case,
        reorder_point: parseInt(formDetail.DATA.reorder_point),
        reorder_value: parseInt(formDetail.DATA.reorder_value),
        item_rank: formDetail.DATA.item_rank,
        item_name: formDetail.DATA.vendoritemname,
        price: formDetail.DATA.price,
        data: formDetail.DATA.note,
        case_cash_total: formDetail.DATA.case_cash_total,
        item_shortcut_no: formDetail.DATA.item_shortcut_no,
        item_shortcut_name: formDetail.DATA.item_shortcut_name,
        bottle_deposit: formDetail.DATA.bottle_deposit,
        color_code: formDetail.DATA.color_code,
        item_type: formDetail.DATA.item_type,
        remind_date: formDetail.DATA.remind_date,
        pointsmultiplier: formDetail.DATA.pointsmultiplier,
        pointsrequired: formDetail.DATA.pointsrequired,
        code: JSON.stringify(stockcode),
        tag: tagArr,
        fee: additionalFeeArr,
        store: formDetail.StoreId,
        onlycode: onlycode,
        user_id: formDetail.user_id,
        user_name: formDetail.user_name,
        uuid: formDetail.uuid,
      };
      const finalData = { ...data, rawdata: JSON.stringify(finalData) };

      return api.post(`/api/inventory/api/itemlist/`, data);
    }
    case CLOCKIN:
      return api.post(`api/inventory/api/createclockinout/`, formdata);
    case CLOCKOUT:
      return api.put(
        `/api/inventory/api/clockinoutdetail/${formdata.id}/`,
        formdata
      );
    case ITEMNOTFOUND: {
      return api.post(`/api/inventory/api/itemsnotfound/`, formdata);
    }
    case DECLINECARDADD: {
      return api.post(`/api/sales/sales/adddeclineCard/`, formdata);
    }
  }
};

const checkSingleRow = (type, data, code, cond, storeId) => {
  switch (type) {
    case STOCKCODECHECK: {
      let body;
      if (code !== "") {
        body = {
          code: data,
          duplicateitem: false,
        };
      } else {
        if (cond) {
          let codeArr = data?.map((code) => {
            return {
              code: code.code,
              item_id: code?.stored_item !== undefined ? code?.stored_item : "",
            };
          });
          body = {
            newCode: codeArr,
            duplicateitem: true,
          };
        } else {
          body = {
            code: data
              .filter((item) => {
                return item.stored_item === undefined;
              })
              .map((code) => {
                return code?.code;
              }),
            duplicateitem: false,
          };
        }
      }
      return api.post(
        `/api/inventory/api/itemcodecheck/?store_id=${storeId}`,
        body
      );
    }
    case BUSINESS_VALIDATE:
      return api.post(`/api/inventory/api/storevalidate/`, data);
    case REEDEM_REWARDS:
      return api.put(`/api/customer/api/customerdetails/${data.id}/`, data);
    case ITEM:
      return api.get(`/api/inventory/api/itemdetail/${data.id}/`);
    case GETIMAGEDATA:
      return api.post(`/api/setting/api/convertimage/`, data);
    case PAYFACAUTHTOKEN:
      return api.post(`/api/setting/api/authtoken/`)
    }
};

const ImageUpload = (type, data) => {
  switch (type) {
    case ITEMIMAGE:
      console.log(data);
      return api.post(`api/inventory/api/itemimage/`, data);
  }
};

const getLottoData = () => {
  const storeId = getDeviceAndStore();
  const master_store_id = localStorage.getItem("master_store_id");
  return api.get(
    `/api/inventory/api/itemview/?store=${storeId[1]}&masterstore=${master_store_id}`
  );
};

export default {
  ListData,
  DeleteRow,
  UpdateData,
  checkSingleRow,
  ImageUpload,
  AddData,
  getLottoData,
};
