import React, { useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
// import { BrowserMultiFormatReader } from '@zxing/browser';

const FastPrint = () => {
  const webcamRef = useRef(null);

  useEffect(() => {
    // const codeReader = new BrowserMultiFormatReader();

    // const scanBarcode = async () => {
    //   const webcamElement = webcamRef.current.video;
    //   const { result } = await codeReader.decodeOnceFromVideoDevice(undefined, webcamElement);

    //   if (result) {
    //     console.log('Barcode result:', result.text);
    //   }

    //   requestAnimationFrame(scanBarcode);
    // };

    // scanBarcode();

    // return () => {
    //   codeReader.stopAsyncDecode();
    //   codeReader.reset();
    // };
  }, []);

  return <Webcam ref={webcamRef} />;
};

export default FastPrint;
