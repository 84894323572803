import { BottleposImg } from "./BottleposImg";
import { CigarsPOSImg } from "./CigarsposIms";
import { CStorePOSImg } from "./CStore";
import { PosNationImg } from "./PosnationImg";
import { DefaultImg } from "./DefaultImg";

export const CombineImg = (name) => {
  console.log("combine image", CombineImg);
  if (name === "CigarsPOS") return CigarsPOSImg();
  else if (name === "CStorePOS") return CStorePOSImg();
  else if (name === "BottlePOS") return BottleposImg();
  else if (name === "PNCstore" || name === "PNLiquor" || name === "PNTobacco" || name === "PNRetail") return PosNationImg(name);
  return DefaultImg(name);
};


// export default CombineImg;
