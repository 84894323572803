
//logo for pnc store
import PNCstore from "../../assets/images/PosNation/PNCstore.png";
import PNCstoreWhite from "../../assets/images/PosNation/PNCstoreWhite.png";

//logo for pnLiquor
import PNLiquor from "../../assets/images/PosNation/PNLiquor.png";
import PNLiquorWhite from "../../assets/images/PosNation/PNLiquorWhite.png";

//logo for pnRetail
import PNRetail from "../../assets/images/PosNation/PNRetail.png";
import PNRetailWhite from "../../assets/images/PosNation/PNRetailWhite.png";

//logo for pnTobacco
import PNTobacco from "../../assets/images/PosNation/PNTobacco.png";
import PNTobaccoWhite from "../../assets/images/PosNation/PNTobaccoWhite.png";

import actions from "../../assets/images/actions.svg";
import ActivateCard from "../../assets/images/activate-card.svg";
import DeactivateCard from "../../assets/images/deactivate-card.svg";
import add from "../../assets/images/add.svg";
import AddBalance from "../../assets/images/add-balance.svg";
import allCategories from "../../assets/images/all-categories.svg";
import backBlue from "../../assets/images/back-blue.svg";
import bckBtn from "../../assets/images/back-btn-green.svg";
import banIcon from "../../assets/images/ban.svg";
import barcodeIcon from "../../assets/images/barcode.png";
import cancel from "../../assets/images/cancel.png";
import cart from "../../assets/images/cart-arrow-down.svg";
import sideCard from "../../assets/images/cash-icon.svg";
import check from "../../assets/images/check.png";
import check1 from "../../assets/images/check1.svg";
import CheckBalance from "../../assets/images/check-balance.svg";
import LogoutIcon from "../../assets/images/check-circle.svg";
import close from "../../assets/images/close.png";
import cost from "../../assets/images/cost.svg";
import cross from "../../assets/images/cross.svg";
import crossIcon from "../../assets/images/cross-screen.svg";
import customer from "../../assets/images/customer1.svg";
import dayReport from "../../assets/images/day-report.svg";
import debit from "../../assets/images/debit.svg";
import deleteIcon from "../../assets/images/delete.svg";
import delivery from "../../assets/images/delivery.png";
import details from "../../assets/images/details.svg";
import ebt from "../../assets/images/ebt.svg";
import edit from "../../assets/images/edit.svg";
import ExpanIcon from "../../assets/images/expand.svg";
import EyeIcon from "../../assets/images/eye-btn.svg";
import EyeIconCLosed from "../../assets/images/eye-btn-closed.svg";
import faCross from "../../assets/images/faCross.svg";
import faPLus from "../../assets/images/faPlus.svg";
import general from "../../assets/images/general.svg";
import logo from "../../assets/images/get.png";
import giftCard from "../../assets/images/gist-card.svg";
import hamburger from "../../assets/images/hamburger.svg";
import hamburger2 from "../../assets/images/hamburger-2.svg";
import ClockIcon from "../../assets/images/Icon-awesome-clock.svg";
import AdminIcon from "../../assets/images/Icon-awesome-user-cog.svg";
import FullscreenIcon from "../../assets/images/Icon-map-fullscreen.svg";
import PrintLabelIcon from "../../assets/images/Icon-material-label.svg";
import listIcon from "../../assets/images/Icon-metro-list.svg";
import LogoutIcon1 from "../../assets/images/Icon-open-account-logout.svg";
import items from "../../assets/images/items1.svg";
import minus from "../../assets/images/minus.svg";
import miscallenous from "../../assets/images/miscallenous.svg";
import moneyOff from "../../assets/images/money-off.svg";
import more from "../../assets/images/more.svg";
import noData from "../../assets/images/no-data-found.svg";
import offers from "../../assets/images/offers.svg";
import options from "../../assets/images/options.svg";
import payments from "../../assets/images/payments.svg";
import PlusAdd from "../../assets/images/plus-add.svg";
import plus from "../../assets/images/plus-green.svg";
import print from "../../assets/images/print.svg";
import printBg from "../../assets/images/print-big.svg";
import printIcon from "../../assets/images/print-icon.svg";
import printing from "../../assets/images/printing.svg";
import promotions from "../../assets/images/promotions.svg";
import redeem from "../../assets/images/reddem.svg";
import refundCash from "../../assets/images/refund-cash.svg";
import refund from "../../assets/images/refund-icon.svg";
import refundCard from "../../assets/images/refund-on-card.svg";
import registerReports from "../../assets/images/register-reports.svg";
import remove from "../../assets/images/remove.svg";
import sellerReports from "../../assets/images/seller.svg";
import defaultImage from "../../assets/images/shop-with-us.svg";
import shortcutIcon from "../../assets/images/shortcut-icon.svg";
import BgImage from "../../assets/images/signin-bg.png";
import whatsSelling from "../../assets/images/star.svg";
import takingsCOunt from "../../assets/images/takings-count.svg";
import adminIcon from "../../assets/images/user.png";
import warning from "../../assets/images/warning.svg";
import warningRed from "../../assets/images/warning-red.svg";
import ratings from "../../assets/images/ratings.svg";
import minusIcon from "../../assets/images/circle-minus.svg";
import plusIcon from "../../assets/images/circle-plus.svg";
import giftIcon from "../../assets/images/gift-icon.svg";
import discount from "../../assets/images/discount.svg";
import logout from "../../assets/images/logout.svg";
import tag from "../../assets/images/tag.svg";
import reports from "../../assets/images/reports.svg";
import pos from "../../assets/images/pos_sales.svg";
import settings from "../../assets/images/settings.svg";
import register from "../../assets/images/register.png";
import weborders from "../../assets/images/weborders.png";
import closeWhite from "../../assets/images/close-white.svg";
import advancedUpdate from "../../assets/images/PosNation/advanced-update.svg";

const getLogo = (appType) => {
  if (appType === "PNLiquor") {
    return PNLiquor;
  } else if (appType === "PNRetail") {
    return PNRetail;
  } else if (appType === "PNTobacco") {
    return PNTobacco;
  } else {
    return PNCstore;
  }
};

const getLogoWhite = (appType) => {
  if (appType === "PNLiquor") {
    return PNLiquorWhite;
  } else if (appType === "PNRetail") {
    return PNRetailWhite;
  } else if (appType === "PNTobacco") {
    return PNTobaccoWhite;
  } else {
    return PNCstoreWhite;
  }
};

export const PosNationImg = (name) => {
  const Img = {
    plusIcon:plusIcon,
    minusIcon:minusIcon,
    pos:pos,
    closeWhite:closeWhite,
    weborders:weborders,
    register:register,
    settings:settings,
    reports:reports,
    advancedUpdate: advancedUpdate,
    tag: tag,
    logout:logout,
    discount:discount,
    ratings: ratings,
    logo1: getLogo(name),
    logoWhite: getLogoWhite(name),
    hamburger2:hamburger2,
    warningRed:warningRed,
    warning:warning,
    adminIcon:adminIcon,
    takingsCOunt:takingsCOunt,
    whatsSelling:whatsSelling,
    BgImage:BgImage,
    shortcutIcon:shortcutIcon,
    defaultImage:defaultImage,
    sellerReports:sellerReports,
    remove:remove,
    registerReports:registerReports,
    refundCash:refundCash,
    redeem:redeem,
    promotions:promotions,
    printing:printing,
    printIcon:printIcon,
    printBg:printBg,
    print:print,
    plus:plus,
    PlusAdd:PlusAdd,
    payments:payments,
    options:options,
    offers:offers,
    noData:noData,
    more:more,
    moneyOff:moneyOff,
    miscallenous:miscallenous,
    minus:minus,
     actions:actions,
     ActivateCard:ActivateCard,
     DeactivateCard:DeactivateCard,
     add:add,
     AddBalance:AddBalance,
     allCategories:allCategories,
     backBlue:backBlue,
     bckBtn:bckBtn,
     banIcon:banIcon,
     barcodeIcon:barcodeIcon,
     cancel:cancel,
     cart:cart,
     sideCard:sideCard,
     check:check,
     check1:check1,
     CheckBalance:CheckBalance,
     LogoutIcon:LogoutIcon,
     close:close,
     cost:cost,
     cross:cross,
     crossIcon:crossIcon,
     customer:customer,
     dayReport:dayReport,
     debit:debit,
     deleteIcon:deleteIcon,
     delivery:delivery,
     details:details,
     ebt:ebt,
     edit:edit,
     ExpanIcon:ExpanIcon,
     EyeIcon:EyeIcon,
     EyeIconCLosed:EyeIconCLosed,
     faCross:faCross,
     faPLus:faPLus,
     general:general,
     logo:logo,
     giftCard:giftCard,
     hamburger:hamburger,
     ClockIcon:ClockIcon,
     AdminIcon:AdminIcon,
     FullscreenIcon:FullscreenIcon,
     PrintLabelIcon:PrintLabelIcon,
     listIcon:listIcon,
     LogoutIcon1:LogoutIcon1,
     items:items,
     refund:refund,
     refundCard:refundCard,
     giftIcon:giftIcon,
   
  };
  return Img;
};

export default PosNationImg;
