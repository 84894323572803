import React, { useState, useLayoutEffect, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from 'prop-types';

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const SliderMain = ({ posSettingData, image }) => {
  const [width, height] = useWindowSize();
  const [sliderKey, setSliderKey] = useState(Date.now()); // Initialize with a unique key

  useEffect(() => {
    // Update the key when the `image` prop changes
    setSliderKey(Date.now());
  }, [image, posSettingData.enable_autoplay, posSettingData.slidshow_Interval]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    fade: true,
    autoplay:
      posSettingData.enable_autoplay !== undefined &&
      posSettingData.enable_autoplay === "1"
        ? true
        : false,
    autoplaySpeed:
      posSettingData.slidshow_Interval !== undefined
        ? parseInt(posSettingData.slidshow_Interval) * 1000
        : 1000,
    cssEase: "linear",
    focusOnSelect: true,
  };

  return (
    <>
      <div className="custom-slick-slider">
        <Slider key={sliderKey} {...settings}>
          {image?.map((elem, i) => {
            let alt = `slider-img-${i}`;
            return (
              <div key={i}>
                <img src={elem} alt={alt} height={height} width={width} />
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};
SliderMain.propTypes = {
  posSettingData: PropTypes.shape({
    enable_autoplay: PropTypes.string,
    slidshow_Interval: PropTypes.string,
  }),
  image: PropTypes.arrayOf(PropTypes.string),
};
export default SliderMain;
