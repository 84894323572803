import getListService from "../services/getListService";
import { toast } from "react-toastify";
import {
  ADDCONFIRMSALEITEM,
  CLOCKIN,
  CLOCKOUT,
  COMPLETEVALUE,
  EXPENSE,
  HELLO,
  ITEM,
  ITEMNOTFOUND,
  ITEMSALE,
  ITEMSALELIST,
  ITEMSALEREFUND,
  NEXTMODULE,
  REPORT,
  SALECODES,
  SALEEXTRACOST,
  SALEITEMS,
  SALEPAYMENTS,
  SALEVOID,
  ITEMSALELIST_GET_LIST,
  ITEMSALELISTUPDATED,
  DECLINECARDADD,
} from "../helpers/constant";
import { checkOutSaleItem } from "./saleItemAction";
import { formateSalesData } from "app/helpers/custom";
export const LISTACTIONFAILED = "LISTACTIONFAILED";
export const SETTING_DYNAMIC_IMAGES = "SETTING_DYNAMIC_IMAGES";

// This function is used for get the data from database by api
// module -- type of api i.e. device,store , items,report
// storeid - store id which is get by setup time
// type is used for redux to store tha data in redux.
// device -- device id which is get by setup time data

export function getListActionFunction(module, storeid, type, nextmodule) {
  if (module === HELLO) {
    return (dispatch) => {
      getListService
        .ListData(module, storeid)
        .then((response) => {
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.statusText === "OK"
          ) {
            const data = {
              data: response.data,
              nextmodule: nextmodule,
            };
            // OfflineData(uploadOfflineSales);
            function uploadOfflineSales(ress) {
              console.log(`ress`, ress);
              dispatch(
                checkOutSaleItem(
                  ITEMSALE,
                  ress.data,
                  ADDCONFIRMSALEITEM,
                  COMPLETEVALUE,
                  ress?.data.items[0].saleId
                )
              );
            }
            dispatch(confirmedResponseAction(data, type));
          }
        })
        .catch((error) => {
          console.log("failed hello api");
          dispatch(failedResponseAction(error.response));
        });
    };
  } else if (module === ITEMSALE || module === REPORT) {
    return (dispatch) => {
      let salesRes = [
        { key: module, request: getListService.ListData(module, storeid) },
        {
          key: SALEEXTRACOST,
          request: getListService.ListData(SALEEXTRACOST, storeid),
        },
        {
          key: SALEITEMS,
          request: getListService.ListData(SALEITEMS, storeid),
        },
        {
          key: SALECODES,
          request: getListService.ListData(SALECODES, storeid),
        },
        {
          key: SALEPAYMENTS,
          request: getListService.ListData(SALEPAYMENTS, storeid),
        },
        { key: SALEVOID, request: getListService.ListData(SALEVOID, storeid) },
      ];
      Promise.all(salesRes.map((item) => item.request))
        .then((response) => {
          let salesData = [];
          response.forEach((resp, index) => {
            if (
              resp.status === 200 ||
              resp.status === 201 ||
              resp.statusText === "OK"
            ) {
              salesData = {
                ...salesData,
                [salesRes[index].key]: resp?.data?.data,
              };
            }
          });
          const data = {
            data: formateSalesData(salesData, module),
            nextmodule: nextmodule,
          };
          dispatch(confirmedResponseAction(data, type));
        })
        .catch((error) => {
          dispatch(failedResponseAction(error.response));
          const data = {
            nextmodule: nextmodule,
          };
          dispatch(confirmedResponseAction(data, NEXTMODULE));
        });
    };
  } else {
    return (dispatch) => {
      getListService
        .ListData(module, storeid)
        .then((response) => {
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.statusText === "OK"
          ) {
            const data = {
              data: response.data,
              nextmodule: nextmodule,
            };
            dispatch(confirmedResponseAction(data, type));
          }
        })
        .catch((error) => {
          dispatch(failedResponseAction(error.response));
          const data = {
            nextmodule: nextmodule,
          };
          dispatch(confirmedResponseAction(data, NEXTMODULE));
        });
    };
  }
}

//get single data by id
export function getSingleAction(module, id, type, formdata, printData) {
  return (dispatch) => {
    getListService
      .ListData(module, formdata)
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.statusText === "OK"
        ) {
          console.log(`response`, response);
          if (printData) {
            response = { ...response, data: { ...response?.data, modifier: [printData] } }
          }
          dispatch(confirmedResponseAction(response, type));
          // toast.success("item added successfully");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong !please try again");
        console.log(`error`, error);
        dispatch(failedResponseAction(error.response));
      });
  };
}

export function getResponseAction(module, storeId, data) {
  return async () => {
    let response = await getListService.ListData(module, storeId, data);
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.statusText === "OK"
    ) {
      return response.data;
    }
  };
}

export function editActionFunction(module, formdata, id, type) {
  return (dispatch) => {
    getListService
      .UpdateData(module, formdata, id)
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.statusText === "OK"
        ) {
          dispatch(confirmedResponseAction(response, type));
          let msg = "Updated successfully";
          if (module === ITEM) {
            msg = "Item update successfully";
          } else if (module === ITEMSALEREFUND) {
            msg = "sale refunded successfully";
          } else if (module === ITEMSALELIST) {
            msg = "sale voided successfully";
          } else if (type === ITEMSALELISTUPDATED) {
            dispatch(
              getListActionFunction(
                ITEMSALE,
                JSON.parse(localStorage.getItem("storeId")),
                ITEMSALELIST_GET_LIST
              )
            );
          }
          toast.success(msg);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong !please try again");
        dispatch(failedResponseAction(error.response));
      });
  };
}
export function addActionFunction(module, formdata) {
  return (dispatch) => {
    getListService
      .AddData(module, formdata)
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.statusText === "OK"
        ) {
          dispatch(confirmedResponseAction(response, module));
          if (module === EXPENSE) {
            toast.success("Your payout added successfully!");
          } else if (module === CLOCKIN || module === CLOCKOUT) {
            toast.success(module + " successfully");
          } else {
            if (![DECLINECARDADD, ITEMNOTFOUND].includes(module))
              toast.success("item added successfully");
          }
        }
      })
      .catch((error) => {
        if (![DECLINECARDADD].includes(module)) {
          toast.error("Something went wrong !please try again");
        }
        dispatch(failedResponseAction(error.response));
      });
  };
}

// this function is used for get list Action when reponse is successfully
export function confirmedResponseAction(response, type) {
  console.log(`response`, response);
  console.log(`type`, type);
  return {
    type: type,
    payload: response,
  };
}

// this function is used for refresh Data From Server

export function refreshServerData(type, nextmodule) {
  return {
    type: type,
    payload: nextmodule,
  };
}

// this function is used for lazy loading when response get by api
export function loadingAction(status, type) {
  return {
    type: type,
    payload: status,
  };
}

// this function is used for when we got error in api when get list of data.
export function failedResponseAction(response) {
  return {
    type: LISTACTIONFAILED,
    payload: response,
  };
}

function OfflineData(callback) {
  window.knex = window.Knex({
    client: "sqlite3",
    debug: false,
    acquireConnectionTimeout: 180000,
    connection: {
      filename: window.appData + "database.sqlite",
    },
    useNullAsDefault: true,
    log: {
      warn(message) {
        console.log(message);
      },
      error(message) {
        console.log(message);
      },
      deprecate(message) {
        console.log(message);
      },
      debug(message) {
        console.log(message);
      },
    },
  });
  window.knex.schema.hasTable("data").then(function (exists) {
    if (!exists) {
      return window.knex.schema.createTable("data", function (t) {
        t.string("key", 100).primary();
        t.jsonb("data");
      });
    }
  });
  window.knex.schema.hasTable("wpos_osales").then(function (exists) {
    if (!exists) {
      return window.knex.schema.createTable("wpos_osales", function (t) {
        t.string("ref", 100).primary();
        t.jsonb("data");
      });
    }
  });
  var query = window.knex("wpos_osales").select("*");
  query
    .then((rows) => {
      console.log("rows", rows);
      if (rows) {
        var jsondata = {};
        for (let index = 0; index < rows.length; index++) {
          const element = rows[index];
          jsondata = JSON.parse(element.data);
        }
        callback(jsondata);
      } else {
        callback({});
      }
    })
    .catch(function (e) {
      console.error("error reading from db", e);
      callback({});
    });
}
export function setdynamicImages(data) {
  return {
    type: SETTING_DYNAMIC_IMAGES,
    payload: data,
  };
}

export function changeMode(response, type) {
  return {
    type: type,
    payload: response,
  };
}

export function updateDevices(response, type) {
  return {
    type: type,
    payload: response,
  };
}

export function updateGeneralSettings(response, type) {
  return {
    type: type,
    payload: response,
  };
}
