import React, { useState, useEffect } from "react";
// Data tables
import {
  Image,
  Fade,
} from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { connect } from "react-redux";
import { currencyFormat } from "../../app/helpers/custom";
import PropTypes from 'prop-types';

const PaymentsTable = (props) => {
  const { SearchBar, ClearSearchButton } = Search;
  const [payments, setPayments] = useState([]);
  const [ , setDynamicImages] = useState(null);
  useEffect(() => {
    setDynamicImages(props.dynamicImages);
  }, [props.dynamicImages]);
  useEffect(() => {
    if (props.payments !== undefined && props.payments.length > 0) {
      setPayments(UpdateArray(props.payments));
    }
  }, [props.payments]);

  const UpdateArray = (array) => {
    return array.map((data) => {
      return {
        Id: data?.id,
        Method: data?.method,
        Amount: currencyFormat(+data?.amount + +data?.tip_amount ?? 0),
        Tendered: currencyFormat(data?.tender),
        Change: currencyFormat(data?.change),
      };
    });
  };

  const columns = [
    {
      dataField: "Method",
      text: "Method",
      sort: true,
      attrs: {
        "data-title": "Method",
      },
    },
    {
      dataField: "Amount",
      text: "Amount",
      sort: true,
      attrs: {
        "data-title": "Amount",
      },
    },
    {
      dataField: "Tendered",
      text: "Tendered",
      sort: true,
      attrs: {
        "data-title": "Tendered",
      },
    },
    {
      dataField: "Change",
      text: "Change",
      sort: true,
      attrs: {
        "data-title": "Change",
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "Id",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size}
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: false, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: payments.length,
      },
    ],
  };

  // Expand row
  const size = useWindowSize();
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: "0 px",
      height: "0 px",
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const expandRow = {
    renderer: (row) => (
      <Fade appear={true} in={true}>
        <ul>
          <li>
            <span className="dtr-title">Tendered</span>
            <span className="dtr-data">{row.Tendered || "-"}</span>
          </li>
          <li>
            <span className="dtr-title">Change</span>
            <span className="dtr-data">{row.Change || "-"}</span>
          </li>
        </ul>
      </Fade>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return expendColorShow(isAnyExpands);
    },
    expandColumnRenderer: ({ expanded }) => {
      return expendColorShow(expanded);
    },
  };
  const expendColorShow = (value) => {
    if (value) {
      return (
        <Image
          className="expand-row-icon"
          src={props?.dynamicImages?.minusIcon}
          alt="minus icon"
        />
      );
    }
    return (
      <Image
        className="expand-row-icon"
        src={props?.dynamicImages?.plusIcon}
        alt="plus icon"
      />
    );
  };

  const expandRow2 = {
    renderer: () => {},
  };

  return (
    <>
      <div className="custom-card card border-0 no-checkbox no-box-shadow column-visibility-table auto-width payment-pos-table">
      <div className="with-scroll-on-table">
        <ToolkitProvider
          key="Id"
          keyField="id"
          data={payments}
          columns={columns}
          search
        >
          {(props) => (
            <>
              <div className="d-flex d-justify-content-between flex-column flex-md-row  align-items-md-center mb-3">
                <div className="search-bar">
                  <SearchBar {...props.searchProps} />
                  {props.searchProps?.searchText?.length > 0 && (
                    <ClearSearchButton
                      className="clear"
                      {...props.searchProps}
                    />
                  )}
                </div>
              </div>
              <div className="pagination-wrapper">
                <div
                  className={
                    size.width !== undefined &&
                    size.width >= 1 &&
                    size.width <= 576
                      ? "expand-table-row "
                      : ""
                  }
                >
                  <BootstrapTable
                    bootstrap4
                    headerClasses="table-header"
                    pagination={paginationFactory(options)}
                    keyField="Id"
                    key="Id"
                    {...props.baseProps}
                    dataColumn={payments.title}
                    defaultSorted={defaultSorted}
                    expandRow={
                      size.width !== undefined &&
                      size.width >= 1 &&
                      size.width <= 576
                        ? expandRow
                        : expandRow2
                    }
                  />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      </div>
      </div>
    </>
  );
};

PaymentsTable.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      method: PropTypes.string,
      amount: PropTypes.string,
      tender: PropTypes.string,
      change: PropTypes.string,
    })
  ),
  dynamicImages: PropTypes.shape({
    ReactLogo: PropTypes.string,
    minusIcon: PropTypes.string,
    plusIcon: PropTypes.string,
  }),
  searchProps: PropTypes.shape({
    searchText: PropTypes.string,
  }),
  baseProps: PropTypes.object,
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
  };
};
export default connect(mapStateToprops)(PaymentsTable);