import React, { useState, useEffect } from "react";
// Data tables
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import {
  dateFormate,
  FirtsLetterUpperCase,
  SaleStatusData,
} from "../../app/helpers/custom";
import {
  Badge,
  Button,
  Card,
  Image,
  ListGroup,
} from "@themesberg/react-bootstrap";

import CustomModal from "../components/Modals";
import { RefundDetails } from "./RefundDetails";
import {
  DEVICE,
  STAFFANDADMIN,
  STORE,
} from "../../app/helpers/constant";

//redux
import { useDispatch, connect } from "react-redux";
import { authUserName, getDeviceAndStoreName } from "../../axios/authHeader";
import moment from "moment";
import PropTypes from 'prop-types';

const RefundTable = (props) => {
  const [show3, setShow3] = useState(false);
  const [refundData, setRefundData] = useState([]);
  const [row, setRow] = useState([]);
  const [payments, ] = useState([]);

  useEffect(() => {
    if (props?.data && props?.data?.length) {
      setRefundData(
        props?.data.map((elem) => {
          return {
            ...elem,
            type: elem.void ? 3 : 2,
          };
        })
      );
    }
  }, [props?.data]);

  // useEffect(() => {
  //   try {
  //     if (!props.devices.length > 0) {
  //       dispatch(getListAction(DEVICE, props?.storeId, DEVICE_GET_LIST));
  //     }
  //     if (!props.staff_admin.length > 0) {
  //       dispatch(getListAction(STAFFANDADMIN, props?.storeId, SATFF_ADMIN_GET_LIST));
  //     }
  //   } catch (error) { }
  // } , [props.taxes , props.devices , props.staff_admin])

  const viewFormater = (cell, row) => {
    return (
      <Button
        className="p-0"
        variant="link"
        onClick={() => {
          setRow(row);
          setShow3(true);
        }}
        title="View"
      >
        <Image src={props?.dynamicImages?.EyeIconCLosed} alt="Eye Icon" />
      </Button>
    );
  };
  const columns = [
    {
      dataField: "type",
      text: "Type",
      sort: true,
      attrs: {
        "data-title": "Type",
      },
      formatter: (cell, row ) => {
        return (
          <Badge className="status" bg={SaleStatusData(row.type)?.color}>
            {SaleStatusData(row.type)?.value}
          </Badge>
        );
      },
    },
    {
      dataField: "processdt",
      text: "Time",
      sort: true,
      formatter: (row) => {
        return moment(row.processdt).format("YYYY-MM-DD HH:mm:ss");
      },
      attrs: {
        "data-title": "Time",
      },
    },
    {
      dataField: "DeviceLocation",
      text: "View",
      formatter: viewFormater,
      sort: true,
      attrs: {
        "data-title": "View",
      },
    },
  ];

  // const getNameById = (type, id) => {
  //   switch (type) {
  //     case DEVICE:
  //       if (props.devices.length > 0) {
  //         const devName = props.devices.filter((item) => item.id === id);
  //         return devName[0] !== undefined
  //           ? FirtsLetterUpperCase(devName[0].name)
  //           : id;
  //       }
  //     case STORE:
  //       if (props.stores.length > 0) {
  //         const StrName = props.stores.filter((item) => item.id === id);
  //         return StrName[0] !== undefined
  //           ? FirtsLetterUpperCase(StrName[0].name)
  //           : id;
  //       }
  //     case STAFFANDADMIN:
  //       if (props.staff_admin.length > 0) {
  //         const StaffName = props.staff_admin.filter((item) => item.id === id);
  //         return StaffName[0] !== undefined
  //           ? FirtsLetterUpperCase(StaffName[0].username)
  //           : id;
  //       }
  //     default:
  //       return id;
  //   }
  // };

  return (
    <>
      <Card className="custom-card no-checkbox lookup-table ref-table no-box-shadow border-0  column-visibility-table auto-width">
      <div className="with-scroll-on-table">
        <ToolkitProvider
          key="Id"
          keyField="id"
          data={refundData}
          columns={columns}
        >
          {(props) => (
            <>
              <div className="pagination-wrapper">
                <BootstrapTable
                  bootstrap4
                  headerClasses="table-header"
                  pagination={false}
                  keyField="Id"
                  key="Id"
                  {...props.baseProps}
                  dataColumn={payments.title}
                  defaultSorted={false}
                />
              </div>
            </>
          )}
        </ToolkitProvider>
        </div>
        <CustomModal
          open={show3}
          handleClose={() => setShow3(false)}
          dialogClassName="custom-modal refund-details"
          title={props?.type + " Details"}
          content={
            <>
              <ListGroup as="ol" numbered>
                <ListGroup.Item as="li">
                  <div className="fw-bold">Time</div>
                  <span>{dateFormate(+row.processdt)}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="fw-bold">User</div>
                  <span>{authUserName()}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="fw-bold">Device</div>
                  <span>{getDeviceAndStoreName(true)[0]}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="fw-bold">Location</div>
                  <span>{getDeviceAndStoreName(true)[1]}</span>
                </ListGroup.Item>

                {row.reason && (
                  <ListGroup.Item>
                    <div className="fw-bold">Reason</div>
                    <span>{row.reason}</span>
                  </ListGroup.Item>
                )}

                {/* <ListGroup.Item>
                  <div className="fw-bold">Amount</div>
                  <span>{row.amount}</span>
                </ListGroup.Item> */}
                {row.method && (
                  <ListGroup.Item>
                    <div className="fw-bold">Method</div>
                    <span>{row.method}</span>
                  </ListGroup.Item>
                )}
              </ListGroup>

              {props?.type === "Refunded" && (
                <div className="custom-data-table">
                  <RefundDetails {...props} />
                </div>
              )}
            </>
          }
        />
      </Card>
    </>
  );
};

RefundTable.propTypes = {
  data: PropTypes.array,
  dynamicImages: PropTypes.shape({
    EyeIconCLosed: PropTypes.string,
  }),
  baseProps: PropTypes.object,
  type: PropTypes.string
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    devices: state.setting.device,
    stores: state.setting.stores,
    staff_admin: state.setting.staff_admin,
  };
};
export default connect(mapStateToprops)(RefundTable);
