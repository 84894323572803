// Create SessionStorage file for store data in local --
class Sessionstorage {
    ls = window.sessionStorage;

    setItem(key, value) {
      value = JSON.stringify(value)
      this.ls.setItem(key, value)
      return true
    }
  
    getItem(key) {
      let value = this.ls.getItem(key)
      try {
        return JSON.parse(value)
      } catch (e) {
        return null
      }
    }
    removeItem(key){
      this.ls.removeItem(key);
     try{
        return true;
     }catch(e){
         return null;
     }
   }
  
  }
  export default new Sessionstorage();