import React, { Fragment } from "react";
import { Modal, Button } from "@themesberg/react-bootstrap";
import PropTypes from 'prop-types';

function CustomModal(props) {
  return (
    <Fragment>
      <Modal
        as={Modal.Dialog}
        centered
        dialogClassName={props.dialogClassName}
        className={props.className}
        size={props.size}
        show={props.open}
        onHide={props.handleClose}
        backdrop={props.backdrop}
        keyboard={props.keyboard}
      >
        <Modal.Header className="justify-content-center">
          <Modal.Title>
            {props.title}
            <Button
              variant={props.removeCloseIcon ? props.removeCloseIcon : "close"}
              aria-label="Close"
              onClick={props.handleClose}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.content}</Modal.Body>
        <Modal.Footer className="pb-3 pt-0 ">{props.action}</Modal.Footer>
      </Modal>
    </Fragment>
  );
}

CustomModal.propTypes = {
  dialogClassName: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  handleClose: PropTypes.func,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  keyboard: PropTypes.bool,
  removeCloseIcon: PropTypes.string,
  content: PropTypes.node, 
  action: PropTypes.node,
};

export default CustomModal;
