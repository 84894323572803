import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Tab,
  Nav,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  ListGroup,
  Badge,
  Image,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import FormField from "../components/formField";
import ItemsTable from "./ItemsTable";
import PaymentsTable from "./PaymentsTable";
import {
  SaleStatusData,
  currencyFormat,
  BottleDepositCalculate,
  FirtsLetterUpperCase,
  dateFormate,
  dateFormateStructure,
  getLRC,
  base64ToHex,
  hexToBase64,
  StringToHex,
  HexToString,
  taxDetailsByItemId,
  mergeArrayData,
  sumObjectsByKey
} from ".././../app/helpers/custom";
import { connect } from "react-redux";
import { Base64 } from "js-base64";
import $ from "jquery";

//redux
import { useDispatch } from "react-redux";
import {
  editActionFunction,
} from "../../app/actions/getListAction";
import {
  ITEMSALELISTUPDATED,
  ITEMSALENOTE,
  POSSETTINGDATA,
} from "../../app/helpers/constant";
import RefundTable from "./RefundTable";
import {
  authUserName,
  getDeviceAndStoreName,
  getSettingData,
} from "../../axios/authHeader";
import LoaderSpinner from "components/Loader";
import CustomModal from "pages/components/Modals";
import Cancel from "../../assets/images/cancel.png";
// import { usePusher } from "context/PusherContext";

const TransactionDetails = (props) => {
  const dispatch = useDispatch();
  const [TaxInfo, setTaxInfo] = useState([]);
  const [discountInfo, setDiscountInfo] = useState({});
  const [bottleDeposit, setBottleDeposit] = useState(parseFloat(0));
  const [additionalFee, setAdditionalFee] = useState([]);
  const [mainData, setMainData] = useState({});
  const [custData, setCustData] = useState({});
  const [notes, setNotes] = useState(props.Details.Data?.notes);
  const [gst, setGst] = useState({});
  const [ratings, ] = useState({
    0: "No Rating",
    1: "Not Happy",
    5: "Awesome",
    3: "Ok",
  });
  const [rating, setRating] = useState("");
  const [possetting, ] = useState(
    getSettingData(POSSETTINGDATA) || props?.posSetting
  );
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState(false);
  const [paxConfig, setPaxConfig] = useState({
    pax_ip: "",
    pax_port: "",
    pax_protocol: "",
  });
  const [giftCardStatus, setGiftCardStatus] = useState(null);
  const [openErrorMsg1, setOpenErrorMsg1] = useState(false);
  const [title, setTitle] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [giftResponse, setGiftResponse] = useState({});

  const renderTooltip = () => (
    <Tooltip className="custom-tooltip general" id="button-tooltip">
      Rounding to the nearest 0.03 cents
    </Tooltip>
  );
  const UpdateArray = (data) => {
    const paymentArray = data?.payments || [];
    return {
      ...data,
      GID: data?.id,
      Ref: data?.ref?.split("-")[2],
      DeviceStore: FirtsLetterUpperCase(
        props?.devices?.find((e) => +e.id === +data.device_id)?.name || ""
      ),
      CustomerName: data?.custid > 0 ? data?.custname : "UNKNOWN",
      Mobile: data?.custid > 0 ? data?.custphone : "N/A",
      Items: data?.numitems,
      promotions: JSON.parse(data?.data || "{}")?.promotion || {},
      discounts: JSON.parse(data?.data || "{}")?.discount || {},
      Total: currencyFormat(data?.total),
      SaleTime: data?.dt,
      Status: data?.status,
      Payments: paymentArray,
      Item: data?.items,
      Extracosts: data?.extracosts,
      Processdt: dateFormate(parseInt(data?.processdt)),
      Store: FirtsLetterUpperCase(getDeviceAndStoreName(true)[1]),
      UserID: FirtsLetterUpperCase(authUserName()),
      Notes: data?.notes,
      Tip_amount: paymentArray?.reduce(
        (acc, obj) => acc + parseFloat(obj.tip_amount),
        0
      ),
      actions: "Actions",
    };
  };

  useEffect(() => {
    const setupData = JSON.parse(localStorage.getItem("setupData"));
    console.log(`props---->`, props);
    if (props?.devices.length > 0) {
      const paxDetails = props.devices.filter(
        (item) => item.id === setupData.device
      );
      console.log(`paxDetails`, paxDetails);
      if (
        paxDetails !== undefined &&
        (paxDetails !== "") & (paxDetails != null)
      ) {
        setPaxConfig({
          pax_ip: paxDetails[0].pax_ip,
          pax_port: paxDetails[0].pax_port,
          pax_protocol: paxDetails[0].pax_protocol,
        });
      }
    }
  }, []);

  useEffect(() => {
    console.log("mainData?.taxdata is", gst);
  }, [gst]);

  useEffect(() => {
    if (mainData?.taxdata) {
      setGst(JSON.parse(mainData?.taxdata));
    }
    if (mainData?.giftcard) {
      setGiftCardStatus(JSON.parse(mainData?.giftcard));
    }
    console.log("mainData is", mainData);
  }, [mainData]);

  useEffect(() => {
    let promoDis = JSON.parse(props.Details.Data?.data || "{}")
    setMainData({
      ...props.Details.Data,
      promotions: promoDis?.promotion || {},
      discounts : promoDis?.discount || {}
    });
    try {
      setCustData(
        props.Details.Data.customer != 0 ? props.Details.Data.customer : {}
      );
    } catch (e) {
      setCustData({});
    }
    setDiscountInfo({
      discountTotal: props.Details.Data.discountamt,
      discountValue: props.Details.Data.discount_value,
      discountSymbol: props.Details.Data.discount_symbol,
    });
  }, [props.Details.Data]);

  useEffect(() => {
    if (custData) {
      let selectedCustomer = props?.custlist.find((e) => e.id == custData.id);
      if (selectedCustomer) {
        setCustData(selectedCustomer);
      }
    }
  }, [props?.custlist, custData?.id]);

  useEffect(() => {
    let data = props?.saleList?.find((e) => e.id == props?.Details?.Data?.id);
    if (data && !(props?.Details?.Data?.temp_id || props?.Details?.Data?.Item[0]?.temp_sale_id)) {
      setMainData(UpdateArray(data));
      props.setTransectionDetails({ Data: UpdateArray(data) });
    } else {
      setMainData(props?.Details?.Data);
      props.setTransectionDetails({ Data: props?.Details?.Data });
    }
  }, [props?.success, props?.saleList]);

  useEffect(() => {
    if (mainData !== undefined) {
      setTaxInfo(UpdateTaxInfo(mainData, props.taxList));
      let modifiedArray, finalArray;
      if(props?.Details?.Data?.Item?.length){
        if (props?.Details?.Data?.Item[0]?.temp_sale_id){
          modifiedArray = props?.Details?.Data?.Item.map((item)=>{
            return {...item, 
              tax: JSON.stringify(
                    taxDetailsByItemId(
                        item?.tax, 
                        item?.qty, item?.price, 
                        props?.taxList, 
                        props?.taxRules, 
                        item?.fee, 
                        undefined, 
                        item?.bottleDeposit, 
                        props?.bottledeposit, 
                        item?.additionalFeeEnable, 
                        item?.bottleDepositEnable, 
                        item.bottleDepositTaxable
                    )
                  )
              }
          });
          const TaxData = mergeArrayData(
            modifiedArray.map((data) => {
              if (data?.tax){
                return JSON.parse(data?.tax)?.values;
              }
            })
          );
          finalArray = {...props?.Details?.Data, taxdata: JSON.stringify(sumObjectsByKey(TaxData))}
          setTaxInfo(UpdateTaxInfo(finalArray, props.taxList));
        }
      }
      setBottleDeposit(BottleDepositCalculate(mainData));
      setRating(ratings[props?.Details?.Data?.rating]);
    }
  }, [props.Details.Data]);
  useEffect(() => {
    if (
      mainData?.additionalcharges != undefined &&
      mainData?.additionalcharges.length > 5
    ) {
      try{
        setAdditionalFee(JSON.parse(JSON.parse(mainData?.additionalcharges)));
      }catch(e){}
    }
  }, [mainData?.additionalcharges]);

  const UpdateTaxInfo = (record, taxList) => {
    var TaxArray = [];
    try{

      if (record.hasOwnProperty("taxdata")) {
        for (var i in JSON.parse(record.taxdata)) {
          const TaxData = taxList?.find((item) => item.id === parseInt(i));
          if (TaxData) {
            TaxArray.push({
              label: `${TaxData?.name} (${TaxData?.value}%)`,
              value: parseFloat(JSON.parse(record?.taxdata)[i]).toFixed(2),
            });
          }
        }
        return TaxArray;
      }
    }catch(e){
      return []
    }
  };
  useEffect(() => {
    if (props?.saveNote) {
      saveNote();
      if (props.setSaveNote) {
        props.setSaveNote(false);
      }
    }
  }, [props?.saveNote]);

  const saveNote = () => {
    dispatch(
      editActionFunction(
        ITEMSALENOTE,
        { notes, ref: props?.Details?.Data?.ref },
        props?.Details?.Data?.id,
        ITEMSALELISTUPDATED
      )
    );
  };

  const roundOffToNext = (decimalNumber, rounding) => {
    let roundedNumber =
      Math.ceil(decimalNumber / (rounding / 100)) * (rounding / 100);
    return roundedNumber.toFixed(2);
  };

  const roundOffTotalPay = (amount) => {
    const cashRounding =
      possetting?.SALE?.cash_rounding ?? possetting?.SALE?.cash_rounding;
    if (typeof amount !== "number" || isNaN(amount)) {
      return amount; // Return the original amount if it is not a valid number
    }

    if (cashRounding == 0) {
      return amount.toFixed(2);
    } else if (cashRounding == 5 || cashRounding == 10) {
      return roundOffToNext(amount, cashRounding);
    } else {
      return amount.toFixed(2);
    }
  };

  const handleLoader = (value, type) => {
    setLoader(value);
    setLoaderType(type);
  };

  const getPaymentSettings = (params, processtype = "sale", cardsaletype) => {
    var version, transactionType; //,accountInformation={},traceInformation,avsInformation,cashierInformation,commercialInformation,motoEcommerce,additionalInformation;
    // if(!ConfigureFlag){
    //  DisplayHandler.RemoveLoading();
    //  var $dialog = $("<div id='dialog' title='Command Dialog'>");
    //  HintDialog.CreateDialog("Configure Data isn't set yet.",$dialog);
    //  return false;
    // }

    var configuration = {
      version: "1.28",
      protocol: "https",
      ip: "127.0.0.1",
      port: "10009",
      promptForSignature: 0,
    };
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
      add: "10",
      activate: "08",
      deactivate: "12",
      balance: "23",
    };

    version = configuration.version;
    transactionType = processTypes[processtype];
    var timestamp = Date.now();
    //set transaction Data

    let amountInformation = {};
    amountInformation.TransactionAmount =
      processtype == "void"
        ? ""
        : Math.round(parseFloat(params.transactionAmount) * 100);
    amountInformation.TipAmount = "";
    amountInformation.CashBackAmount = "";
    amountInformation.MerchantFee = "";
    amountInformation.TaxAmount = "";
    amountInformation.FuelAmount = "";
    console.log(amountInformation);

    let accountInformation = {};
    accountInformation.Account = "";
    accountInformation.EXPD = "";
    accountInformation.CVVCode = "";
    accountInformation.EBTtype = cardsaletype == "ebt cash" ? "C" : "";
    accountInformation.VoucherNumber = "";
    accountInformation.Force = "";
    accountInformation.FirstName = "";
    accountInformation.LastName = "";
    accountInformation.CountryCode = "";
    accountInformation.State_ProvinceCode = "";
    accountInformation.CityName = "";
    accountInformation.EmailAddress = "";

    let traceInformation = {};
    traceInformation.ReferenceNumber = params.ticketNumber.substring(0, 15);
    traceInformation.InvoiceNumber = "";
    traceInformation.AuthCode = "";
    traceInformation.TransactionNumber = params.hasOwnProperty("transactionId")
      ? params.transactionId
      : "";
    traceInformation.TimeStamp = "";
    traceInformation.ECRTransID = "";

    let avsInformation = {};
    avsInformation.ZipCode = "";
    avsInformation.Address = "";
    avsInformation.Address2 = "";

    let cashierInformation = {};
    cashierInformation.ClerkID = "";
    cashierInformation.ShiftID = "";

    let commercialInformation = {};
    commercialInformation.PONumber = "";
    commercialInformation.CustomerCode = "";
    commercialInformation.TaxExempt = "";
    commercialInformation.TaxExemptID = "";
    commercialInformation.MerchantTaxID = "";
    commercialInformation.DestinationZipCode = "";
    commercialInformation.ProductDescription = "";

    let motoEcommerce = {};
    motoEcommerce.MOTO_E_CommerceMode = "";
    motoEcommerce.TransactionType = "";
    motoEcommerce.SecureType = "";
    motoEcommerce.OrderNumber = "";
    motoEcommerce.Installments = "";
    motoEcommerce.CurrentInstallment = "";

    let additionalInformation = {};
    additionalInformation.TABLE = "";
    additionalInformation.GUEST = "";
    additionalInformation.SIGN =
      processtype == "sale" ? configuration.promptForSignature : "";
    additionalInformation.TICKET = "";
    additionalInformation.HREF = "";
    additionalInformation.TIPREQ =
      params.hasOwnProperty("tipsenable") && params.tipsenable === true
        ? 1
        : "";
    additionalInformation.SIGNUPLOAD = "";
    additionalInformation.REPORTSTATUS = "";
    additionalInformation.TOKENREQUEST = "";
    additionalInformation.TOKEN = "";
    additionalInformation.CARDTYPE = "";
    additionalInformation.CARDTYPEBITMAP = "";

    additionalInformation.PASSTHRUDATA = "";
    additionalInformation.RETURNREASON = "";
    additionalInformation.ORIGTRANSDATE = "";
    additionalInformation.ORIGPAN = "";
    additionalInformation.ORIGEXPIRYDATE = "";
    additionalInformation.ORIGTRANSTIME = "";
    additionalInformation.DISPROGPROMPTS = "";
    additionalInformation.GATEWAYID = "";
    additionalInformation.GETSIGN =
      processtype == "sale" ? configuration.promptForSignature : "";

    additionalInformation.ENTRYMODEBITMAP = "";
    additionalInformation.RECEIPTPRINT = "1";
    additionalInformation.CPMODE = "";
    additionalInformation.ODOMETER = "";
    additionalInformation.VEHICLENO = "";
    additionalInformation.JOBNO = "";
    additionalInformation.DRIVERID = "";
    additionalInformation.EMPLOYEENO = "";
    additionalInformation.LICENSENO = "";
    additionalInformation.JOBID = "";
    additionalInformation.DEPARTMENTNO = "";
    additionalInformation.CUSTOMERDATA = "";
    additionalInformation.USERID = "";
    additionalInformation.VEHICLEID = "";
    console.log(amountInformation);

    var command = "T00";
    if (cardsaletype == "gift") {
      command = "T06";
    } else if (cardsaletype == "debit") {
      command = "T02";
    } else if (cardsaletype == "ebt" || cardsaletype == "ebt cash") {
      command = "T04";
    } else if (cardsaletype == "batchclose") {
      command = "B00";
    }

    console.log(`command`, command);

    return DoCredit({
      command: command,
      version: version,
      transactionType: transactionType,
      amountInformation: amountInformation,
      accountInformation: accountInformation,
      traceInformation: traceInformation,
      avsInformation: avsInformation,
      cashierInformation: cashierInformation,
      commercialInformation: commercialInformation,
      motoEcommerce: motoEcommerce,
      additionalInformation: additionalInformation,
    });
  };

  const DoCredit = (doCreditInfo) => {
    var amountInformation,
      accountInformation,
      traceInformation,
      avsInformation,
      cashierInformation,
      commercialInformation,
      motoEcommerce,
      additionalInformation;
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      mDestinationIP: `${
        paxConfig.pax_protocol == ""
          ? "http"
          : paxConfig.pax_protocol == "1"
          ? "http"
          : "https"
      }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${
        paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
      }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };

    var params = [
      PAX.mStx.hex,
      doCreditInfo.command,
      PAX.mFS.hex,
      doCreditInfo.version,
    ];
    params.push(PAX.mFS.hex);
    if (doCreditInfo.transactionType != "") {
      params.push(doCreditInfo.transactionType);
    }
    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "amountInformation",
      doCreditInfo.amountInformation
    );

    console.log(
      `doCreditInfo.amountInformation`,
      doCreditInfo.amountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "accountInformation",
      doCreditInfo.accountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "traceInformation",
      doCreditInfo.traceInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "avsInformation", doCreditInfo.avsInformation);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "motoEcommerce", doCreditInfo.motoEcommerce);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    params.push(PAX.mEtx.hex);

    var lrc = getLRC(params);

    console.log(params);

    //prepare for base64 encoding.
    var command_hex = base64ToHex(Base64.btoa(doCreditInfo.command));
    var version_hex = base64ToHex(Base64.btoa(doCreditInfo.version));
    var transactionType_hex = base64ToHex(
      Base64.btoa(doCreditInfo.transactionType)
    );
    var amountInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.amountInformation)
    );
    var accountInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.accountInformation)
    );
    var traceInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.traceInformation)
    );
    var avsInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.avsInformation)
    );
    var cashierInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.cashierInformation)
    );
    var commercialInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.commercialInformation)
    );
    var motoEcommerce_hex = base64ToHex(
      Base64.btoa(doCreditInfo.motoEcommerce)
    );
    var additionalInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.additionalInformation)
    );

    //var elements = [this.mStx.code, command_hex, PAX.mFS.code, version_hex, PAX.mFS.code, uploadFlag_hex, PAX.mFS.code, timeout, this.mEtx.code, base64ToHex(Base64.btoa(lrc))];
    var elements = [PAX.mStx.code];
    elements.push(command_hex);
    elements.push(PAX.mFS.code);
    elements.push(version_hex);
    elements.push(PAX.mFS.code);

    if (transactionType_hex != "") {
      elements.push(transactionType_hex);
    }
    elements.push(PAX.mFS.code);

    elements = AddBase64(
      elements,
      "amountInformation",
      doCreditInfo.amountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "accountInformation",
      doCreditInfo.accountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "traceInformation",
      doCreditInfo.traceInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "avsInformation",
      doCreditInfo.avsInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(elements, "motoEcommerce", doCreditInfo.motoEcommerce);
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    elements.push(PAX.mEtx.code);
    elements.push(base64ToHex(Base64.btoa(lrc)));
    console.log("elements");
    console.log(elements);

    var final_string = elements.join(" ");
    var final_b64 = hexToBase64(final_string);
    console.log("LRC: " + lrc);
    console.log("Base64: " + final_b64);

    // if(customData != ''){
    // 	final_b64 = hexToBase64(final_string+"&custom_data=<PAX>"+customData+"</PAX>");
    // }

    var url = PAX.mDestinationIP + "?" + final_b64;
    console.log("URL: " + url);
    return { url: url, timeout: PAX.timeout.DoCredit };
    // this.HttpCommunication('DoCredit',url,function(response){
    // 	callback(response);
    // },PAX.timeout.DoCredit);
  };

  const AddBase64 = (elements, type, objectInfo) => {
    //console.log(objectInfo);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    var empty = 0;
    var arr = [];
    arr = arr.concat(elements);
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.code);
        continue;
      }
      if (type == "additionalInformation") {
        if (objectInfo[name] == "") continue;
        empty++;
        arr.push(
          base64ToHex(Base64.btoa(name + "=" + objectInfo[name].toString()))
        );
      } else {
        empty++;
        arr.push(base64ToHex(Base64.btoa(objectInfo[name].toString())));
      }
      arr.push(PAX.mUS.code);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = elements;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.code);
    }
    //console.log(arr);
    return arr;
  };

  const PushParams = (params, type, objectInfo) => {
    var empty = 0;
    var arr = [];
    arr = arr.concat(params);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.hex);
        continue;
      }

      if (type == "additionalInformation") {
        if (objectInfo[name] == "") {
          continue;
        }
        empty++;
        arr.push(name + "=" + objectInfo[name].toString());
      } else {
        empty++;
        arr.push(objectInfo[name].toString());
      }
      arr.push(PAX.mUS.hex);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = params;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.hex);
    }
    //console.log(params);
    return arr;
  };

  const handlePaymentResponse = (type, response) => {
    if (type == "Tripos" || type == "TriposDirect") {
      return response;
    } else if (type == "Pax") {
      //     var config = WPOS.getConfigTable();
      //     var paxSettings= config.deviceconfig;
      //     // var paxSettings= config.general.paxsettings;
      //   paxc=new PAXCard(paxSettings.pax_protocol?paxSettings.pax_protocol:'https',paxSettings.pax_ip?paxSettings.pax_ip:'127.0.0.1',paxSettings.pax_port?paxSettings.pax_port:'10009');

      return parseResponse(response);
    }
  };

  function parseResponse(response) {
    var commandType = "DoCredit";
    var checkParams = StringToHex(response).split(" ").pop();
    var RedundancyCheck = StringToHex(response).split(" ").pop().substring(1);

    var check = getLRC(checkParams);

    if (check == RedundancyCheck) {
      //get package detail info
      var packetInfo = [];
      var len = StringToHex(response).indexOf("03");
      var hex = StringToHex(response).slice(0, len).split(/02|1c/);

      console.log(hex);
      if (commandType == "DoCredit") {
        var subHex = [],
          subPacketInfo = [];
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            if (hex[i].indexOf("1f") > 0) {
              subHex = hex[i].split("1f");
              console.log(subHex);
              subPacketInfo = [];
              for (var j = 0; j < subHex.length; j++) {
                if (subHex[j] != "") {
                  subPacketInfo.push(HexToString(subHex[j]));
                }
              }
              console.log(subPacketInfo);
              packetInfo.push(subPacketInfo);
            } else {
              packetInfo[i] = HexToString(hex[i]);
            }
          }
        }
      } else {
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            packetInfo[i] = HexToString(hex[i]);
          }
        }
      }
      response = packetInfo;
    }
    if (typeof response == "string") {
      // WPOS.util.okDialog("Error", response, function (dat) { });
      console.log(`responsee`, response);
      return true;
    }

    console.log(`responsee`, response);

    var i = 0,
      j = -1;
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
    };
    PacketageInfo.DoCredit.Status = response[++i];
    PacketageInfo.DoCredit.Command = response[++i];
    PacketageInfo.DoCredit.Version = response[++i];
    PacketageInfo.DoCredit.ResponseCode = response[++i];
    PacketageInfo.DoCredit.ResponseMessage = response[++i];
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.Command) {
          commandName = key;
        }
      }
    }
    if (PacketageInfo.DoCredit.ResponseCode == "000000") {
      PacketageInfo.DoCredit.isApproved = true;
    } else {
      PacketageInfo.DoCredit.isApproved = false;
    }

    console.log(`packetInfo->`, PacketageInfo);

    PacketageInfo.DoCredit.HostInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.HostInformation == "") {
      PacketageInfo.DoCredit.HostInformation = {};
      PacketageInfo.DoCredit.HostInformation.HostResponseCode = "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage = "";
      PacketageInfo.DoCredit.HostInformation.AuthCode = "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber = "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber = "";
    } else if (typeof PacketageInfo.DoCredit.HostInformation == "string") {
      console.log(`type->`, PacketageInfo.DoCredit.HostInformation);
    } else {
      PacketageInfo.DoCredit.HostInformation.HostResponseCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.AuthCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit._processor = {
      processorLogs: [
        "ExpressResponseCode: [0]\r\nExpressResponseMessage: [" +
        PacketageInfo.DoCredit.isApproved
          ? commandName + "Approved"
          : "" +
            "]\r\nHostResponseCode: [" +
            PacketageInfo.DoCredit.HostInformation.HostResponseCode +
            "]\r\nHostResponseMessage: [ " +
            PacketageInfo.DoCredit.HostInformation.HostResponseMessage +
            "]",
      ],
      processorRawResponse: "",
      processorReferenceNumber:
        PacketageInfo.DoCredit.HostInformation.HostReferenceNumber,
      processorRequestFailed: PacketageInfo.DoCredit.isApproved,
      processorRequestWasApproved: PacketageInfo.DoCredit.isApproved,
      processorResponseCode: PacketageInfo.DoCredit.isApproved
        ? commandName + "Approved"
        : "",
      processorResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      expressResponseCode:
        PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      expressResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      hostResponseCode: PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      hostResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      logs: ["ExpressResponseCode: ''"],
      rawResponse: "",
    };

    PacketageInfo.DoCredit.TransactionType =
      response[++i] != undefined ? response[i] : "";

    PacketageInfo.DoCredit.commandName = "";
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.TransactionType) {
          commandName = key.charAt(0).toUpperCase() + key.slice(1) + " ";
          PacketageInfo.DoCredit.commandName = commandName;
        }
      }
    }

    PacketageInfo.DoCredit.AmountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AmountInformation == "") {
      PacketageInfo.DoCredit.AmountInformation = {};
      PacketageInfo.DoCredit.approvedAmount = "";
      PacketageInfo.DoCredit.subTotalAmount = "0";
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount = "";
      PacketageInfo.DoCredit.AmountInformation.AmountDue = "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount = "";
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount = "";
      PacketageInfo.DoCredit.cashbackAmount =
        PacketageInfo.DoCredit.AmountInformation.CashBackAmount;
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = "";
      PacketageInfo.DoCredit.debitSurchargeAmount = "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount = "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 = "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.approvedAmount = parseFloat(
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
      );
      PacketageInfo.DoCredit.subTotalAmount = parseFloat(
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
      );

      PacketageInfo.DoCredit.AmountInformation.AmountDue =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.tipAmount =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.TipAmount) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.TipAmount / 100)
          : PacketageInfo.DoCredit.AmountInformation.TipAmount;
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    // add params balance 1 and balance 2 for ebt
    if (["T05", "T07", "T09"].includes(PacketageInfo.DoCredit.Command)) {
      PacketageInfo.DoCredit.Balance1 =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance1) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance1 / 100)
          : PacketageInfo.DoCredit.AmountInformation.Balance1;
      PacketageInfo.DoCredit.Balance2 =
        parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance2) > 0
          ? parseFloat(PacketageInfo.DoCredit.AmountInformation.Balance2 / 100)
          : PacketageInfo.DoCredit.AmountInformation.Balance2;
    } else {
      PacketageInfo.DoCredit.Balance1 = 0;
      PacketageInfo.DoCredit.Balance2 = 0;
    }

    PacketageInfo.DoCredit.fsaCard = "NotApplicable";
    PacketageInfo.DoCredit.convenienceFeeAmount = "";
    PacketageInfo.DoCredit.AccountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AccountInformation == "") {
      PacketageInfo.DoCredit.AccountInformation = {};
      PacketageInfo.DoCredit.AccountInformation.Account = "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode = "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate = "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype = "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber = "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo = "";
      PacketageInfo.DoCredit.AccountInformation.CardType = "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder = "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage = "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AccountInformation.Account =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.accountNumber =
      PacketageInfo.DoCredit.AccountInformation.Account;
    PacketageInfo.DoCredit.isCardInserted =
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator;
    PacketageInfo.DoCredit.cardHolderName =
      PacketageInfo.DoCredit.AccountInformation.CardHolder;

    PacketageInfo.DoCredit.TraceInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.TraceInformation == "") {
      PacketageInfo.DoCredit.TraceInformation = {};
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber = "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AVSinformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AVSinformation == "") {
      PacketageInfo.DoCredit.AVSinformation = {};
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.CommercialInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.CommercialInformation == "") {
      PacketageInfo.DoCredit.CommercialInformation = {};
      PacketageInfo.DoCredit.CommercialInformation.PONumber = "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.CommercialInformation.PONumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription =
        response[i][++j] != undefined ? response[i][j] : "";
    }
    console.log(PacketageInfo.DoCredit.CommercialInformation);

    PacketageInfo.DoCredit.motoEcommerce =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.motoEcommerce == "") {
      PacketageInfo.DoCredit.motoEcommerce = {};
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType = "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType = "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber = "";
      PacketageInfo.DoCredit.motoEcommerce.Installments = "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.Installments =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AdditionalInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AdditionalInformation == "")
      PacketageInfo.DoCredit.AdditionalInformation = {};
    var additionalInfoArr = PacketageInfo.DoCredit.AdditionalInformation,
      keyValue = [];
    for (i = 0; i < additionalInfoArr.length; i++) {
      keyValue = additionalInfoArr[i].split("=");
      PacketageInfo.DoCredit.AdditionalInformation[keyValue[0]] = keyValue[1];
      keyValue = [];
    }
    if (
      PacketageInfo.DoCredit.AdditionalInformation.hasOwnProperty("CARDBIN")
    ) {
      PacketageInfo.DoCredit.binValue =
        PacketageInfo.DoCredit.AdditionalInformation.CARDBIN;
    }
    PacketageInfo.DoCredit.cardLogo = "";
    PacketageInfo.DoCredit.currencyCode = "";
    PacketageInfo.DoCredit.entryMode = "";
    PacketageInfo.DoCredit.expirationYear = "";
    PacketageInfo.DoCredit.expirationMonth = "";
    PacketageInfo.DoCredit.paymentType = "";
    PacketageInfo.DoCredit.pinVerified = "";
    PacketageInfo.DoCredit.signature = {
      statusCode: "SignatureNotRequiredByThresholdAmount",
    };
    PacketageInfo.DoCredit.statusCode = PacketageInfo.DoCredit.isApproved
      ? commandName + "Approved"
      : "";
    PacketageInfo.DoCredit.transactionDateTime =
      PacketageInfo.DoCredit.TraceInformation.TimeStamp;
    PacketageInfo.DoCredit.transactionId =
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber;
    PacketageInfo.DoCredit.merchantId = "";

    console.log(`PacketageInfo`, PacketageInfo.DoCredit);
    return PacketageInfo.DoCredit;
  }

  const retryAddBalance = () => {
    handleLoader(true, "Follow Prompts on Terminal");
    var parms = {
      ticketNumber: mainData?.ref,
      transactionAmount: mainData?.total,
    };
    var settings = getPaymentSettings(parms, "add", "gift");
    $.ajax(settings)
      .done(function (giftResponse) {
        handleLoader(false, "");
        console.log(`giftResponse`, giftResponse);
        var giftResponse = handlePaymentResponse("Pax", giftResponse);
        console.log(`giftResponse`, giftResponse);
        setGiftResponse(giftResponse);
        if (giftResponse.isApproved == true) {
          let giftcard = {
            devid: mainData?.device_id,
            status: true,
            response: giftResponse,
          };
          dispatch(
            editActionFunction(
              ITEMSALENOTE,
              {
                giftcard: JSON.stringify(giftcard),
                ref: props?.Details?.Data?.ref,
              },
              props?.Details?.Data?.id,
              ITEMSALELISTUPDATED
            )
          );
        } else if (giftResponse.isApproved == false) {
          setOpenErrorMsg1(true);
          setErrorMsg(
            <>
              <div className="text-center">
                <Image src={Cancel} alt="error icon" className="mb-3" /> <br />
                <p>Error: ABORTED</p>
                <p className="mt-2">Retry Adding Balance?</p>
              </div>
            </>
          );
          setTitle("Error");
        }
      })
      .fail(function (jqXHR, status, error) {
        console.log(`jqXHR`, jqXHR);
        var errormessage;
        if (jqXHR.status === 0) {
          errormessage = "Could not connect to pinpad";
        } else if (jqXHR.status == 404) {
          errormessage = "Requested page not found. [404]";
        } else if (jqXHR.status == 500) {
          errormessage = "Internal Server Error [500].";
        } else {
          errormessage = "Uncaught Error.\n" + jqXHR.responseText;
        }
        handleLoader(false, "");
        setOpenErrorMsg1(true);
        setErrorMsg(
          <>
            <div className="text-center">
              <Image src={Cancel} alt="error icon" className="mb-3" /> <br />
              <p>Error: {errormessage}</p>
              <p className="mt-2">Retry Adding Balance?</p>
            </div>
          </>
        );
        setTitle("Error");
        console.log(`errormessage`, errormessage);
      });
  };

  const handleRetryClosepopUp = () => {
    setOpenErrorMsg1(false);
    setErrorMsg("");
    setTitle("");
    let giftcard = {
      devid: mainData?.device_id,
      status: giftResponse?.isApproved,
      response: giftResponse,
    };
    console.log("giftResponse?.status", giftResponse);
    dispatch(
      editActionFunction(
        ITEMSALENOTE,
        { giftcard: JSON.stringify(giftcard), ref: props?.Details?.Data?.ref },
        props?.Details?.Data?.id,
        ITEMSALELISTUPDATED
      )
    );
  };

  const calculateSubTotal = (data)=>{
    const totalSubtotal = data?.Item?.map((i) => {
      if (i?.subtotal === "NaN") {
        return parseFloat(0);
      } else {
        return parseFloat(i?.subtotal);
      }
    }) || [];
    return parseFloat(totalSubtotal.reduce((a, b) => a + b, 0).toFixed(2))
  }

  const formatDOB = (dt)=>{
    const inputDate = new Date(dt);
    if (!isNaN(inputDate.getTime())) {
      const outputDateStr = `${inputDate.getMonth() + 1}/${inputDate.getDate()}/${inputDate.getFullYear()}`;
      return outputDateStr;
    } else {
      console.error('Invalid date format:', dt);
    }
  }

  return (
    <>
      {loader && (
        <CustomModal
          open={loader}
          dialogClassName={`custom-modal confirmation error-popup`}
          title={loaderType}
          content={<h1 className="mb-3"></h1>}
          action={<LoaderSpinner />}
          removeCloseIcon={`d-none`}
        />
      )}
      <div className="wrapper">
        <ListGroup as="ul" numbered="true">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="d-flex">
              <div className="fw-bold">Status</div>
              {/* <span className="status">{SaleStatusData(mainData?.Status).value}</span> */}
              <Badge bg={SaleStatusData(mainData?.Status).color}>
                {SaleStatusData(mainData?.Status).value}
              </Badge>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="d-flex">
              <div className="fw-bold">ID</div>
              <span>{mainData?.GID}</span>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="d-flex">
              <div className="fw-bold">Ref</div>
              <span>{mainData?.ref}</span>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="d-flex">
              <div className="fw-bold">Trans DT</div>
              <span> {dateFormateStructure(mainData?.SaleTime)}</span>
            </div>
          </ListGroup.Item>
        </ListGroup>
        <ListGroup as="ul" numbered="true">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="d-flex">
              <div className="fw-bold">Process DT</div>
              <span> {mainData?.Processdt}</span>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="d-flex">
              <div className="fw-bold">User</div>
              <span>{mainData?.UserID}</span>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="d-flex">
              <div className="fw-bold">Device</div>
              <span>{mainData?.DeviceStore}</span>
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="d-flex">
              <div className="fw-bold">Store</div>
              <span> {mainData?.Store}</span>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <Form.Group className="form-floating mt-3 mb-4">
        <FormField
          as="textarea"
          name="notes"
          value={notes !== null ? notes : ""}
          style={{ height: "88px" }}
          label="Notes"
          placeholder="Notes"
          onChange={(e) => setNotes(e.target.value)}
        />
      </Form.Group>

      {/* <Button
        type="button"
        disabled={!notes}
        variant="primary px-4 position-absolute form-cta-btn"
        onClick={saveNote}
      >
        Save
      </Button> */}
      <Tab.Container defaultActiveKey="Details">
        <Nav
          fill
          variant="pills"
          className="custom-tab-nav mx-auto flex-sm-row mt-4 w-100"
        >
          <Nav.Item>
            <Nav.Link eventKey="Details" className="mb-sm-3 mb-md-0">
              <Image
                className="me-2"
                src={props?.dynamicImages?.details}
                alt="Details Icon"
              />
              Details
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Items" className="mb-sm-3 mb-md-0">
              <Image
                className="me-2"
                src={props?.dynamicImages?.items}
                alt="Items Icon"
              />
              Items
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Payments" className="mb-sm-3 mb-md-0">
              <Image
                className="me-2"
                src={props?.dynamicImages?.payments}
                alt="Payments Icon"
              />
              Payments
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Customer" className="mb-sm-3 mb-md-0">
              <Image
                className="me-2"
                src={props?.dynamicImages?.customer}
                alt="Customer Icon"
              />
              Customer
            </Nav.Link>
          </Nav.Item>
          {false && (
            <Nav.Item>
              <Nav.Link eventKey="Delivery" className="mb-sm-3 mb-md-0">
                <Image
                  className="me-2"
                  src={props?.dynamicImages?.delivery}
                  alt="Delivery Icon"
                />
                Delivery
              </Nav.Link>
            </Nav.Item>
          )}

          <Nav.Item>
            <Nav.Link eventKey="Rating" className="mb-sm-3 mb-md-0">
              <Image
                className="me-2"
                width="20"
                src={props?.dynamicImages?.ratings}
                alt="Delivery Icon"
              />
              Rating
            </Nav.Link>
          </Nav.Item>

          {giftCardStatus !== null && (
            <Nav.Item>
              <Nav.Link eventKey="GiftCard" className="mb-sm-3 mb-md-0">
                <Image
                  className="me-2"
                  width="20"
                  src={props?.dynamicImages?.giftIcon}
                  alt="Gift Icon"
                />
                Gift Card
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>

        <Tab.Content className="custom-data-table below-description adjust-column ">
          <Tab.Pane eventKey="Details">
            <Row>
              <Col md="6 pt-3">
                <h2>Sale Totals</h2>
                <ListGroup as="ul" numbered="true">
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="d-flex">
                      <div className="fw-bold">Subtotal:</div>
                      <span>{currencyFormat(mainData?.subtotal || calculateSubTotal(props?.Details?.Data))}</span>
                    </div>
                  </ListGroup.Item>
                  {TaxInfo !== undefined &&
                    TaxInfo.length > 0 &&
                    TaxInfo.map((data, i) => {
                      return (
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                          key={i}
                        >
                          <div className="d-flex">
                            <div className="fw-bold">{data.label}:</div>
                            <span>{currencyFormat(data.value)}</span>
                          </div>
                        </ListGroup.Item>
                      );
                    })}
                  {mainData?.extracosts?.length > 0 &&
                    mainData?.extracosts.map((data, i) => {
                      return (
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                          key={i}
                        >
                          <div className="d-flex">
                            <div className="fw-bold">{data.label}:</div>
                            <span>{currencyFormat(data.total)}</span>
                          </div>
                        </ListGroup.Item>
                      );
                    })}
                  {bottleDeposit !== parseFloat(0) && (
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Bottle Deposit:</div>
                        <span>{currencyFormat(bottleDeposit)}</span>
                      </div>
                    </ListGroup.Item>
                  )}
                  {additionalFee.length > 0 &&
                    additionalFee.map((data, i) => {
                      return (
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                          key={i}
                        >
                          <div className="d-flex">
                            <div className="fw-bold">{data.costname}</div>
                            <span>{currencyFormat(data.total)}</span>
                          </div>
                        </ListGroup.Item>
                      );
                    })}
                  {mainData?.isroundingenable && mainData?.rounding > 0 && (
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">
                          Rounding:&nbsp;
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 150, hide: 300 }}
                            overlay={renderTooltip}
                          >
                            <FontAwesomeIcon
                              className="custom-overlay"
                              color="black"
                              icon={faInfoCircle}
                            />
                          </OverlayTrigger>
                        </div>
                        <span>{mainData?.rounding}</span>
                      </div>
                    </ListGroup.Item>
                  )}

                  {parseFloat(discountInfo.discountTotal || 0) > 0 && (
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Discount:</div>
                        <span>{`${
                          discountInfo.discountValue
                        }% (${currencyFormat(
                          discountInfo.discountTotal
                        )})`}</span>
                      </div>
                    </ListGroup.Item>
                  )}
                  {/* <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="d-flex">
                      <div className="fw-bold">Rounding:</div>
                      <span>
                        {gst["1"] && gst["2"]
                          ? currencyFormat(
                              Number(mainData?.total) -
                                Number(mainData?.subtotal) -
                                Number(gst["1"]) -
                                Number(gst["2"])
                            )
                          : currencyFormat(
                              Number(mainData?.total) -
                                Number(mainData?.subtotal)
                            )}
                      </span>
                    </div>
                  </ListGroup.Item> */}
                  {mainData?.Tip_amount > 0 && <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="d-flex">
                      <div className="fw-bold">Tip Amount:</div>
                      <span>{currencyFormat(mainData?.Tip_amount)}</span>
                    </div>
                  </ListGroup.Item>}
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="d-flex">
                      <div className="fw-bold">Total:</div>
                      <span>{currencyFormat(Number(mainData?.total) + (mainData?.Tip_amount ? +mainData?.Tip_amount : 0))}</span>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>

              {mainData?.salevoid && mainData?.salevoid.length ? (
                <Col md="6 pt-3" className="void-refund">
                  <h2>Void/Refund</h2>
                  <RefundTable
                    data={mainData?.salevoid}
                    items={mainData?.items}
                    type={SaleStatusData(mainData?.Status).value}
                  />
                </Col>
              ) : (
                <Col md="6 pt-3"></Col>
              )}
            </Row>
          </Tab.Pane>
          <Tab.Pane eventKey="Items" className="pt-3 stats">
            <h2>Items</h2>
            <ItemsTable
              promotionInfo={mainData.promotions}
              items={mainData?.Item}
              discountInfo={mainData.discounts}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Payments" className="pt-3">
            <h2>Payments</h2>
            <PaymentsTable payments={mainData?.Payments} />
          </Tab.Pane>
          {/* <Tab.Pane eventKey="Options" className="pt-2">
            <h2>Options</h2>
            <Options />
          </Tab.Pane> */}
          <Tab.Pane eventKey="Customer" className="pt-3">
            <h2>Customer</h2>
            {mainData?.custid > 0 && (
              <ListGroup as="ul" numbered="true">
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex">
                    <div className="fw-bold">Name:</div>
                    <span>{custData?.name}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex">
                    <div className="fw-bold">Email:</div>
                    <span>{custData?.email}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex">
                    <div className="fw-bold">Mobile:</div>
                    <span>{custData?.mobile}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex">
                    <div className="fw-bold">DOB:</div>
                    <span>{formatDOB(custData?.dob)}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex">
                    <div className="fw-bold">Address:</div>
                    <span>{custData?.address}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex">
                    <div className="fw-bold">Post Code:</div>
                    <span>{custData?.postcode}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex">
                    <div className="fw-bold">Points:</div>
                    <span>
                      {" "}
                      {custData?.points || props.Details.Data?.custpoints}
                    </span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="d-flex">
                    <div className="fw-bold">Notes:</div>
                    <span>
                      {" "}
                      {props.Details.Data?.custnotes || custData?.notes}
                    </span>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            )}
          </Tab.Pane>
          {false && (
            <Tab.Pane eventKey="Delivery" className="pt-3">
              <Row className="delivery">
                <Col md="6">
                  <h2>Payment Billing Address</h2>
                  <ListGroup as="ul" numbered="true">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Name</div>
                        <span> Karuna kumari</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Email</div>
                        <span>karunakumari@zapbuild.com</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Mobile</div>
                        <span>9625222096</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Address</div>
                        <span>US-50, Fallon, NV, USA</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Suburb</div>
                        <span> Fallon</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">State</div>
                        <span>Nevada</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Country</div>
                        <span> United States</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Zipcode</div>
                        <span> 94105</span>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md="6">
                  <h2>Payment Billing Address</h2>
                  <ListGroup as="ul" numbered="true">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Name:</div>
                        <span> Karuna kumari</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Mobile</div>
                        <span>9625222096</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Address</div>
                        <span>US-50, Fallon, NV, USA</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">City</div>
                        <span> Fallon</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">State</div>
                        <span>Nevada</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Country</div>
                        <span> United States</span>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                    >
                      <div className="d-flex">
                        <div className="fw-bold">Zipcode</div>
                        <span> 94105</span>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Tab.Pane>
          )}
          <Tab.Pane eventKey="Rating" className="pt-2">
            <h2>Customer Rating</h2>
            <p>
              <b>Rating:</b> <span>{rating}</span>
            </p>
          </Tab.Pane>
          <Tab.Pane eventKey="GiftCard" className="pt-2">
            <h2>
              Gift Card Balance Add Status :{" "}
              {giftCardStatus?.status ? "Completed" : "Failed"}
            </h2>
            {!giftCardStatus?.status && (
              <Button
                type="button"
                variant="primary px-4 form-cta-btn mt-2"
                onClick={retryAddBalance}
              >
                Retry
              </Button>
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      {/* Confirm Popup*/}
      <CustomModal
        open={openErrorMsg1}
        backdrop={false}
        handleClose={handleRetryClosepopUp}
        dialogClassName={`custom-modal confirmation error-popup cancel-modal`}
        title={title}
        content={<h1 className="mb-3 justify-content-center">{errorMsg}</h1>}
        action={
          <>
            <Button
              type="button"
              variant="primary px-5"
              onClick={() => {
                setOpenErrorMsg1(false);
                retryAddBalance();
              }}
            >
              Yes
            </Button>
            <Button
              type="button"
              variant="outline-blackcustom px-5"
              onClick={handleRetryClosepopUp}
            >
              No
            </Button>
          </>
        }
      />
    </>
  );
};


const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    taxList: state.inventory.tax,
    taxRules: state.inventory.taxRule,
    saleList: state.saleItem.saleList,
    success: state.saleItem.success,
    posSetting: state.inventory.posSetting.data,
    custlist: state.inventory.customerList,
    devices: state.inventory.devices,
  };
};
export default connect(mapStateToprops)(TransactionDetails);
