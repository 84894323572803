import React, { useEffect, useState } from "react";
import { Card, Fade, Image } from "@themesberg/react-bootstrap";
// Other imports
import BootstrapTable2 from "../components/BootsrapTable2";
import { connect, useDispatch } from "react-redux";
import { currencyFormat, getTodaysRecords } from "../../app/helpers/custom";
import { GRAPHREPORT } from "../../app/helpers/constant";
import { UpdateGraphData } from "../../app/actions/saleItemAction";
import CustomScroll from "react-custom-scroll";

const TakingCountReport = (props) => {
  const [takingCountReportData, setTakingCountReportData] = useState({});
  const [takingReport, setTakingReport] = useState([]);
  const [curcashtakings, setCurcashtakings] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.reportlist?.length > 0 || Array.isArray(props?.reportlist)) {
      setTakingCountReportData(getTakingCountList(props.reportlist));
    }
  }, [props.reportlist]);

  useEffect(() => {
    if (Object.entries(takingCountReportData).length > 0) {
      let data = {
        graphData: takingCountReportData,
        curCashTakings: curcashtakings,
      };
      let tCRData = { ...takingCountReportData?.methodtotals }
      if (localStorage.getItem("off_sale") != null) {
        let sales = JSON.parse(localStorage.getItem("off_sale"))
        let total = 0;
        for (let sale of sales) {
          const TotalPayment = sale?.Payments.reduce((acc, curr) => {
            acc += parseFloat(curr.amount);
            if (tCRData[curr["method"]]) {
              tCRData[curr["method"]]["amount"] = +tCRData[curr["method"]]["amount"] + +curr.amount
              tCRData[curr["method"]]["qty"] += 1
            }
            return acc;
          }, 0);
          total += TotalPayment
        }
        data = {
          ...data,
          graphData: {
            ...data?.graphData,
            salesnum: data?.graphData?.salesnum + sales?.length,
            salestotal: data?.graphData?.salestotal + total,
            totaltakings: parseFloat(data?.graphData?.totaltakings + total).toFixed(2)
          }
        }
      }

      if (localStorage.getItem("off_refund_sales") != null) {
        let off_refund_sales = JSON.parse(localStorage.getItem("off_refund_sales"))
        for (let sale of off_refund_sales) {
          data = {
            ...data,
            graphData: {
              ...data?.graphData,
              refundnum: data?.graphData?.refundnum + 1,
              refundtotal: data?.graphData?.refundtotal + +sale?.formData?.amount,
              totaltakings: parseFloat(data?.graphData?.totaltakings - +sale?.formData?.amount).toFixed(2)
            }
          }
          if (tCRData[sale?.formData?.method]) {
            tCRData[sale?.formData?.method]["refamount"] = +tCRData[sale?.formData?.method]["refamount"] + +sale?.formData?.amount
            tCRData[sale?.formData?.method]["refqty"] += 1
          }
        }
      }

      if (localStorage.getItem("off_void_sales") != null) {
        let off_void_sales = JSON.parse(localStorage.getItem("off_void_sales"))
        for (let sale of off_void_sales) {
          data = {
            ...data,
            graphData: {
              ...data?.graphData,
              voidnum: data?.graphData?.voidnum + 1,
              voidtotal: data?.graphData?.voidtotal + +sale?.formData?.amount,
              totaltakings: parseFloat(data?.graphData?.totaltakings - +sale?.formData?.amount).toFixed(2)
            }
          }
        }
      }

      dispatch(UpdateGraphData(data, GRAPHREPORT));
      setTakingReport(renderOnTableData(tCRData));
    }
  }, [takingCountReportData]);

  function renderOnTableData(methdenoms) {
    const returnArray = [];
    var i = 1;
    for (var method in methdenoms) {
      returnArray.push({
        id: i,
        Method: method,
        Payments: methdenoms[method].qty,
        Takings: currencyFormat(methdenoms[method].amount),
        Refunds: methdenoms[method].refqty,
        Refunds1: currencyFormat(methdenoms[method].refamount),
        Payout: methdenoms[method].payoutqty,
        Payout1: currencyFormat(methdenoms[method].payoutamount),
        Balance: currencyFormat(
          parseFloat(methdenoms[method].amount) -
          parseFloat(methdenoms[method].refamount) -
          parseFloat(methdenoms[method].payoutamount)
        ),
      });
      i++;
    }
    return returnArray;
  }

  const getTakingCountList = (saleList) => {
    const sales = getTodaysRecords(true, saleList);
    let sale;
    let emptfloat = parseFloat("0.00");
    let stime = new Date();
    let etime = new Date();
    stime.setHours(0);
    stime.setMinutes(0);
    stime.setSeconds(0);
    stime = stime.getTime();
    etime.setHours(23);
    etime.setMinutes(59);
    etime.setSeconds(59);
    etime = etime.getTime();
    const data = {
      salesnum: 0,
      salestotal: emptfloat,
      voidnum: 0,
      voidtotal: emptfloat,
      refundnum: 0,
      refundtotal: emptfloat,
      totaltakings: emptfloat,
      methodtotals: {},
    };
    let salestat;
    for (const key in sales) {
      sale = sales[key];
      salestat = sale.status;
      let amount;
      let method;
      switch (salestat) {
        case 3:
          data.voidnum++;
          data.voidtotal += parseFloat(sale.total);
          break;
        case 4:
          //nothing to do for declined sales
          break;
        case 2:
          // cycle though all refunds and add to total
          for (const i in sale.salevoid) {
            amount = parseFloat(sale.salevoid[i].amount);
            method = sale.salevoid[i].method;
            data.refundnum++;
            data.refundtotal += amount;
            // add payment type totals
            if (data.methodtotals.hasOwnProperty(method)) {
              // check if payment method field is alredy set
              data.methodtotals[method].refamount += amount;
              data.methodtotals[method].refqty++;
            } else {
              data.methodtotals[method] = {};
              data.methodtotals[method].refamount = amount;
              data.methodtotals[method].refqty = 1;
              data.methodtotals[method].amount = parseFloat(0);
              data.methodtotals[method].qty = 0;
            }
          }
          // count refund as a sale, but only if it was sold today
          if (sale.processdt < stime || sale.processdt > etime) {
            break; // the sale was not made today
          }
        case 1:
          data.salesnum++;
          data.salestotal += parseFloat(sale.total);
          // console.log(sale.total)
          // calc payment methods
          for (const p in sale.payments) {
            amount = parseFloat(sale.payments[p].amount);
            method = sale.payments[p].method;
            if (data.methodtotals.hasOwnProperty(method)) {
              // check if payment method field is alredy set
              data.methodtotals[method].amount += amount;
              data.methodtotals[method].qty++;
            } else {
              data.methodtotals[method] = {};
              data.methodtotals[method].amount = amount;
              data.methodtotals[method].qty = 1;
              data.methodtotals[method].refamount = parseFloat(0);
              data.methodtotals[method].refqty = 0;
            }
          }
      }
    }
    for (const x in data.methodtotals) {
      data.methodtotals[x].amount = parseFloat(
        data.methodtotals[x].amount
      ).toFixed(2);
      data.methodtotals[x].refamount = parseFloat(
        data.methodtotals[x].refamount
      ).toFixed(2);
      //init payouts
      data.methodtotals[x].payoutamount = 0;
      data.methodtotals[x].payoutqty = 0;
    }
    //calculate payouts
    const payouts = getPayoutReport();
    for (const x in payouts.methodtotals) {
      if (data.methodtotals.hasOwnProperty(x)) {
        data.methodtotals[x].payoutamount = parseFloat(
          payouts.methodtotals[x].amount
        ).toFixed(2);
        data.methodtotals[x].payoutqty = payouts.methodtotals[x].qty;
      } else {
        data.methodtotals[x] = {};
        data.methodtotals[x].amount = parseFloat(0);
        data.methodtotals[x].qty = 0;
        data.methodtotals[x].refamount = parseFloat(0);
        data.methodtotals[x].refqty = 0;
        data.methodtotals[x].payoutamount = parseFloat(
          payouts.methodtotals[x].amount
        ).toFixed(2);
        data.methodtotals[x].payoutqty = payouts.methodtotals[x].qty;
      }
    }
    // calculate takings
    data.totaltakings =
      data.salestotal.toFixed(2) - data.refundtotal.toFixed(2);
    let curcashtakings = "";
    if (data.methodtotals.hasOwnProperty("Cash")) {
      curcashtakings =
        (data.methodtotals.Cash.hasOwnProperty("amount")
          ? data.methodtotals["Cash"].amount
          : 0) -
        (data.methodtotals.Cash.hasOwnProperty("refamount")
          ? data.methodtotals["Cash"].refamount
          : 0);
      if (data.methodtotals.Cash.hasOwnProperty("payoutamount")) {
        curcashtakings =
          curcashtakings - data.methodtotals["Cash"].payoutamount;
      }
    } else {
      curcashtakings = parseFloat(0).toFixed(2);
    }
    setCurcashtakings(curcashtakings);
    //console.log(data.totaltakings)
    return data;
  };

  function getPayoutReport() {
    const payoutreport = getTodayPayout();
    const data = {
      methodtotals: {},
    };
    for (const key in payoutreport) {
      const method = payoutreport[key].payment_mode;
      const amount = parseFloat(payoutreport[key].amount);
      if (data.methodtotals.hasOwnProperty(method)) {
        // check if payment method field is alredy set
        data.methodtotals[method].amount += amount;
        data.methodtotals[method].qty++;
      } else {
        data.methodtotals[method] = {};
        data.methodtotals[method].amount = amount;
        data.methodtotals[method].qty = 1;
      }
    }
    return data;
  }

  function getTodayPayout() {
    const payouts = getPayouts();
    const todaypayouts = {};
    let stime = new Date();
    let etime = new Date();
    stime.setHours(0);
    stime.setMinutes(0);
    stime.setSeconds(0);
    stime = stime.getTime();
    etime.setHours(23);
    etime.setMinutes(59);
    etime.setSeconds(59);
    etime = etime.getTime();
    //check Im I close today my account
    const accountClosingTime = localStorage.getItem("accountClosingTime");
    if (accountClosingTime !== null) {
      if (stime < accountClosingTime) {
        //not old
        stime = accountClosingTime;
      }
    }
    for (const key in payouts) {
      if (payouts[key].processdt > stime && payouts[key].processdt < etime) {
        todaypayouts[key] = payouts[key];
      }
    }
    return todaypayouts;
  }

  function getPayouts() {
    if (
      localStorage.getItem("wpos_cpayouts") ??
      localStorage.getItem("wpos_cpayouts")
    ) {
      return JSON.parse(localStorage.getItem("wpos_cpayouts"));
    } else {
      return {};
    }
  }

  const columns = [
    {
      dataField: "Method",
      text: "Method",
      sort: true,
      attrs: {
        "data-title": "Method",
      },
    },
    {
      dataField: "Payments",
      text: "# Payments",
      sort: true,
      attrs: {
        "data-title": "# Payments",
      },
    },
    {
      dataField: "Takings",
      text: "Takings",
      sort: true,
      attrs: {
        "data-title": "Takings",
      },
    },
    {
      dataField: "Refunds",
      text: "# Refunds",
      sort: true,
      attrs: {
        "data-title": "# Refunds",
      },
    },
    {
      dataField: "Refunds1",
      text: "Refunds",
      sort: true,
      attrs: {
        "data-title": "Refunds",
      },
    },
    {
      dataField: "Payout",
      text: "# Payout",
      sort: true,
      attrs: {
        "data-title": "# Payout",
      },
    },
    {
      dataField: "Payout1",
      text: "Payout",
      sort: true,
      attrs: {
        "data-title": "Payout",
      },
    },
    {
      dataField: "Balance",
      text: "Balance",
      sort: true,
      attrs: {
        "data-title": "Balance",
      },
    },
  ];


  // Expand row
  const size = useWindowSize();
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: "0 px",
      height: "0 px",
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const expandRow = {
    renderer: (row) => (
      <Fade appear={true} in={true}>
        <ul>
          <li className="refunds-taking">
            <span className="dtr-title"># Refunds</span>
            <span className="dtr-data">{row.Refunds || "-"}</span>
          </li>
          <li className="refunds1-taking">
            <span className="dtr-title">Refunds</span>
            <span className="dtr-data">{row.Refunds1 || "-"}</span>
          </li>
          <li>
            <span className="dtr-title"># Payout</span>
            <span className="dtr-data">{row.Payout || "-"}</span>
          </li>
          <li>
            <span className="dtr-title">Payout</span>
            <span className="dtr-data">{row.Payout1 || "-"}</span>
          </li>
          <li>
            <span className="dtr-title">Balance</span>
            <span className="dtr-data">{row.Balance || "-"}</span>
          </li>

        </ul>
      </Fade>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return expendColorShow(isAnyExpands);
    },
    expandColumnRenderer: ({ expanded }) => {
      return expendColorShow(expanded);
    },
  };
  const expendColorShow = (value) => {
    if (value) {
      return (
        <Image
          className="expand-row-icon"
          src={props?.dynamicImages?.minusIcon}
          alt="minus icon"
        />
      );
    }
    return (
      <Image
        className="expand-row-icon"
        src={props?.dynamicImages?.plusIcon}
        alt="plus icon"
      />
    );
  };

  const expandRow2 = {
    renderer: () => { },
  };

  return (
    <>
      <Card className="custom-card no-checkbox no-box-shadow border-0 report-with-scroll   column-visibility-table auto-width taking-table">
        <div className=" with-scroll-on-table">
          <CustomScroll
            addScrolledClass
            heightRelativeToParent="calc(100% - 0px)"
            className={
              size.width !== undefined && size.width >= 1 && size.width <= 767
                ? "expand-table-row "
                : ""
            }
          >
            <BootstrapTable2
              columns={columns}
              data={takingReport}
              keyField="id"
              noDataIndication={takingReport.length === 0 ? true : false}
              expandRow={
                size.width !== undefined && size.width >= 1 && size.width <= 767
                  ? expandRow
                  : expandRow2
              }
            />
          </CustomScroll>
        </div>
      </Card>
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    reportlist: state.inventory.reportList,
  };
};
export default connect(mapStateToprops)(TakingCountReport);
