import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "@themesberg/react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { 
  UPDATEPOSSETTING, 
  SETTING_CHANNEL, 
  SETTING_EVENT, 
  ITEMUPDATEPUSHER, 
  SHORTCUTVIEW,
  LOTTOPAYOUTTYPE,
  ONLINEPAYOUTTYPE,
  LOTTOSALETYPE,
  ONLINELOTTERYTYPE,
} from "../../../../app/helpers/constant";
import { ITEMCHANNEl, ITEM_EVENT } from "app/helpers/pusherConstant";
import { updateGeneralSettings } from "app/actions/getListAction";
import { usePusher } from "context/PusherContext";
import { PusherUpdateFunction } from "app/helpers/custom";
import PropTypes from 'prop-types';

const SubGroup = (props) => {
  const [categoryList, setCategoryList] = useState([]);
  const [shortcutItems, setShortcutItems] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [miscellaneousItems, setMiscellaneousItems] = useState(0);
  const [, setIsLottoButtonEnabled] = useState(false);
  const [, setLottoItems] = useState([]);
  const dispatch = useDispatch();
  const [LottoIds, setLottoIds] = useState([]);
  const ItemTypes = [11, LOTTOPAYOUTTYPE, ONLINEPAYOUTTYPE, ONLINELOTTERYTYPE, LOTTOSALETYPE];
  const handleChange = (data) => {
    props.setCurrentId(data);
    dispatch({payload: data, type: SHORTCUTVIEW})
  };
  const pusher = usePusher();

  useEffect(() => {
    function childEventCallback(data) {
      const posSettings = JSON.parse(data?.data?.data)
      console.log("settings data", posSettings);
      if (data.type === "pos"){
        dispatch(updateGeneralSettings(posSettings, UPDATEPOSSETTING))
      }
    }
    const channel = pusher.subscribe(SETTING_CHANNEL);
    channel.bind(SETTING_EVENT, childEventCallback);
    return () => {
      channel.unbind(SETTING_EVENT, childEventCallback);
    };
  }, [pusher]);

  useEffect(() => {
    function childEventCallback(data) {
      console.log("outside")
      console.log("inside", data)
      const items = [...props?.itemList];
      let listUpdate;
      if (data.method === "put") {
        console.log("inside1", data)
        listUpdate = PusherUpdateFunction("put", items, data?.data);
      } else if (data.method === "add") {
        console.log("inside2", data)
        listUpdate = PusherUpdateFunction("add", items, data.data);
      } else if (data.method === "delete") {
        console.log("inside3", data)
        listUpdate = items.filter((item) => item.id != data?.data?.id);
      }
      dispatch({
        type: ITEMUPDATEPUSHER,
        payload: { data: listUpdate },
      });
    }
    const channel = pusher.subscribe(ITEMCHANNEl);
    channel.bind(ITEM_EVENT, childEventCallback);
    return () => {
      channel.unbind(ITEM_EVENT, childEventCallback);
    };
  }, [props?.itemList, pusher]);

  useEffect(() => {
    if (props?.category && props.category.length > 0)
      setCategoryList(props.category);
  }, [props.category]);

  useEffect(() => {
    setIsLottoButtonEnabled(
      props?.PosSetting?.data?.OPTION?.enable_lotto_buttons === "1"
    );
    setLottoIds([
      parseInt(props?.PosSetting?.data?.OPTION?.lotto_payout_item),
      parseInt(props?.PosSetting?.data?.OPTION?.lotto_sale_item),
      parseInt(props?.PosSetting?.data?.OPTION?.online_payout_item),
      parseInt(props?.PosSetting?.data?.OPTION?.online_lottery_item),
      parseInt(props?.PosSetting?.data?.OPTION?.house_pay_item),
    ]);
  }, [props?.PosSetting]);

  useEffect(() => {
    const totalItemsCount = props?.itemList.reduce((count, item) => {
      return count + (!LottoIds.includes(item.id) && !ItemTypes.includes(item.item_type) ? 1 : 0);
    }, 0);

    setTotalItems(totalItemsCount);
    if (props?.itemList.length > 0) {
      setShortcutItems(
        props?.itemList.filter((item) => item.shortcutkeys === true && !ItemTypes.includes(item?.item_type)).length
      );
      setMiscellaneousItems(
        props?.itemList.filter(
          (item) => item.category === null || item.category === ""
        ).length
      );
      console.log("settings data", props?.PosSetting);
      setLottoItems(
        props?.itemList.filter((item) => LottoIds.includes(item.id) && item.id != parseInt(props?.PosSetting?.data?.OPTION?.house_pay_item))
      );
    }
  }, [props.itemList, LottoIds, props?.PosSetting]);

  const groupItems = categoryList.map((groupEntity) => {
    if (groupEntity.cat_group_name !== "Lotto") {
      return (
        props?.itemList?.filter((item) => item.category === groupEntity.id)
          ?.length > 0 && (
          <Col
            md={4}
            xs={6}
            key={groupEntity.id}
            id={groupEntity.id}
            className="mb-3"
            onClick={() =>
              handleChange({ id: groupEntity.id, type: "category" })
            }
          >
            <div className="wrapper">
              <h3>{groupEntity.name}</h3>
              <span>
                (
                {
                  props?.itemList?.filter(
                    (item) => item.category === groupEntity.id
                  )?.length
                }{" "}
                Items)
              </span>
            </div>
          </Col>
        )
      );
    } else {
      return;
    }
  });

  return (
    <Row>
      <Col
        md={4}
        xs={6}
        key={"shortcutkey"}
        className="mb-3"
        onClick={() => handleChange({ type: "shortcutkey", id: "" })}
      >
        <div className="wrapper">
          <Image
            className="mb-2"
            src={props?.dynamicImages?.shortcutIcon}
            alt="shortcutIcon"
          />
          <h3>Shortcut Keys</h3>
          <span>({props?.PosSetting?.data?.OPTION?.enable_lotto_buttons == 1 ? 4+shortcutItems : 0+shortcutItems} Items)</span>
        </div>
      </Col>
      <Col
        md={4}
        xs={6}
        key={"categories"}
        className="mb-3"
        onClick={() => handleChange({ type: "item", id: "" })}
      >
        <div className="wrapper">
          <Image
            className="mb-2"
            src={props?.dynamicImages?.allCategories}
            alt="allCategories"
          />
          <h3>All Categories</h3>
          <span>({totalItems} Items)</span>
        </div>
      </Col>
      {groupItems}
      <Col
        md={4}
        xs={6}
        key={"miscellaneousItems"}
        className="mb-3"
        onClick={() => handleChange({ type: "miscellaneous", id: "" })}
      >
        <div className="wrapper">
          <Image
            className="mb-2"
            src={props?.dynamicImages?.miscallenous}
            alt="miscallenous"
          />
          <h3>Miscellaneous</h3>
          <span>({miscellaneousItems} Items)</span>
        </div>
      </Col>
    </Row>
  );
};
SubGroup.propTypes = {
  setCurrentId: PropTypes.func,
  itemList: PropTypes.array,
  category: PropTypes.array,
  PosSetting: PropTypes.shape({
    data: PropTypes.shape({
      OPTION: PropTypes.shape({
        enable_lotto_buttons: PropTypes.string,
        lotto_payout_item: PropTypes.string,
        lotto_sale_item: PropTypes.string,
        online_payout_item: PropTypes.string,
        online_lottery_item: PropTypes.string,
        house_pay_item: PropTypes.string,
      }),
    }),
  }),
  dynamicImages: PropTypes.shape({
    shortcutIcon: PropTypes.string,
    allCategories: PropTypes.string,
    miscallenous: PropTypes.string,
  }),
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    category: state.inventory.category,
    PosSetting: state.inventory.posSetting,
    itemList: state.inventory.itemlist,
  };
};
export default connect(mapStateToprops)(SubGroup);
