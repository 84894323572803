import React from "react";
import {
  Col,
  Row,
  Form,
} from "@themesberg/react-bootstrap";
import FormField from "../components/formField";
import moment from "moment-timezone";
import ReactSelect, { components } from "react-select";

//redux
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';


const Clock = (props) => {
  const user_list = useSelector((state) => state.inventory.userList)


  const handleChange = (val , key) => {
    props.setClockinoutFormData({...props.clockinoutFormData , [key] : val , time : new Date()})
  }
  return (
    <>
      <h2 className="mb-4">
        Time: <span> {moment().format("MM/DD/YYYY HH:mm:ss")} </span>
      </h2>
      <Form autoComplete="off">
        <Row>
          <Col xs={12} className="mb-3 custom-react-select">
            <Form.Group>
              <ReactSelect
                // components={{
                //   ValueContainer: CustomValueContainer,
                // }}
                // styles={{
                //   valueContainer: (provided, state) => ({
                //     ...provided,
                //     overflow: "visible",
                //   }),
                //   placeholder: (provided, state) => ({
                //     ...provided,
                //     position: "absolute",
                //     top:
                //       state.hasValue || state.selectProps.inputValue
                //         ? -15
                //         : "7px",
                //     transition: "top 0.1s, font-size 0.1s",

                //     transform:
                //       (state.hasValue || state.selectProps.inputValue) &&
                //       "scale(0.85)",
                //     opacity:
                //       (state.hasValue || state.selectProps.inputValue) && 1,
                //   }),
                // }}
                classNamePrefix="react-select"
                options={user_list.map((list) => {
                  return {
                    ...list,
                    value: list.id,
                    label: list.username.toUpperCase(),
                  };
                })}
                value={user_list.map((item) => {
                  return {
                    ...item,
                    value: item.id,
                    label: item.username.toUpperCase(),
                  };
                }).filter((item) => {
                  return item.value === props.clockinoutFormData?.user.id;
                })}
                onChange={(e) => handleChange(e , "user")}
                placeholder={"Staff Name"}
              />
            </Form.Group>
          </Col>

          <Col className="mb-3" xs={12}>
            <Form.Group className="form-floating">
              <FormField
                label="Password"
                type="password"
                name="Password"
                value={props.clockinoutFormData?.password}
                // onFocus={(e) => e.target.select()}
                onChange={(e) => handleChange(e.target.value , "password")}
                placeholder="Password"
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

Clock.propTypes = {
  clockinoutFormData: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number.isRequired, 
    }),
    password: PropTypes.string, 
  }),
  clockinoutFormData: PropTypes.object.isRequired
};

export default Clock;
