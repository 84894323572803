import LocalStorage from "../app/storage/Localstorage";
export function authHeader() {
  // return authorization header with token
  const localdata = LocalStorage.getItem("pos_user");
  let Token = null;
  if (localdata && localdata.token) {
    Token = localdata.token?.access;
  }
  if (Token) {
    return { Authorization: `Bearer ${Token}` };
  } else {
    return false;
  }
}


export function isMobileDevice() {
  // return login time of user
return  localStorage.getItem("isPOSDevice") === "true";
// return  true;
}

export function getLoginTime() {
  // return login time of user
  const localdata = LocalStorage.getItem("pos_user");
  let logtime = null;
  if (localdata && localdata.logtime) {
    logtime = localdata.logtime;
  }
  if (logtime) {
    return logtime.replace(",", "");
  } else {
    return new Date().toLocaleString().replace(",", "");
  }
}

export function authUserId(con) {
  // return authorization header with token
  const localdata = LocalStorage.getItem("pos_user");
  // const sessiondata = SessionStorage.getItem('user');
  let UserId = null;
  let SuperUser = 0;
  if (localdata && localdata.data.id !== undefined) {
    UserId = localdata?.data?.id;
  }
  if (localdata && localdata.data.is_superuser !== undefined) {
    SuperUser = localdata.data.is_superuser;
  }
  if (UserId && con === undefined) {
    return UserId;
  } else if (con) {
    return SuperUser === 1 ? true : false;
  } else {
    return false;
  }
}

export function authUserName() {
  // return userName by localstorage
  const localdata = LocalStorage.getItem("pos_user");
  let name = null;
  if (localdata && localdata.data.username !== undefined) {
    name = localdata.data.username;
  }
  if (name) {
    return name;
  } else {
    return "-";
  }
}

export function DeviceUUId(con) {
  // return true or false based on uuid exits or not
  const localData = localStorage.getItem("uuid");
  let UUID = null;
  if (localData !== undefined) {
    UUID = localData;
  }
  if (UUID) {
    return con ? UUID : true;
  } else {
    return false;
  }
}


export function getDeviceAndStore(con) {
  // return data if setupData exits or not
  const setupData = JSON.parse(localStorage.getItem("setupData"));
  let device_id = null;
  let store_id = null;
  let createDate = null;
  let RegistrationId = null;
  if (setupData !== null && setupData !== undefined) {
    if (setupData.device !== undefined) {
      device_id = setupData.device;
    }
    if (setupData.store !== undefined) {
      store_id = setupData.store;
    }
    if (setupData.created_at !== undefined) {
      createDate = setupData.created_at;
    }
    if (setupData.id !== undefined) {
      RegistrationId = setupData.id;
    }
    if (store_id && device_id && createDate && RegistrationId) {
      return con ? [createDate, RegistrationId] : [device_id, store_id];
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function getDeviceAndStoreName(con) {
  // return store name and device name
  const deviceName = localStorage.getItem("deviceName");
  const storeName = localStorage.getItem("StoreName");
  let device = null;
  let store = null;
  if (deviceName !== undefined && deviceName !== null) {
    device = deviceName;
  }
  if (storeName !== undefined && storeName !== null) {
    store = storeName;
  }
  if (device && store) {
    return con ? [device, store] : "-";
  } else {
    return [device, store];
  }
}

export function updateLocalConfigDetail() {
  const configData = JSON.parse(localStorage.getItem("wpos_config"));
  if (configData !== undefined && configData !== null) {
    return JSON.parse(configData.data);
  } else {
    return false;
  }
}

export function checkOnlineStatus() {
  const status = localStorage.getItem("SystemStatus");
  if (status !== null && status !== undefined) {
    if (status === "true") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function getSettingData(type) {
  const localSaveSetting = localStorage.getItem(type);
  if (
    localSaveSetting &&
    localSaveSetting !== null &&
    localSaveSetting !== undefined &&
    localSaveSetting.length > 10
  ) {
    return JSON.parse(localSaveSetting);
  } else {
    return false;
  }
}

export function addSettingData(type, data) {
  if (type && data) {
    localStorage.setItem(type, data);
  }
}
export function setAuthLocalStorage(loginData) {
  let jsonauth;
  const authDataArray = {
    data: loginData.data,
    token: loginData.token,
    logtime: new Date().toLocaleString(),
  };
  if (localStorage.getItem("wpos_auth") !== null) {
    jsonauth = JSON.parse(localStorage.getItem("wpos_auth"));
    jsonauth[loginData.data.username.toString()] = authDataArray;
  } else {
    jsonauth = {};
    jsonauth[loginData.data.username.toString()] = authDataArray;
  }
  localStorage.setItem("wpos_auth", JSON.stringify(jsonauth));
  return true;
}

export function isUserAdminstrator() {
  let storeId = LocalStorage.getItem("setupData");
  if (!storeId || storeId == null || storeId === "null") {
    return false;
  }
  storeId = storeId?.store || 1;
  const localdata = LocalStorage.getItem("pos_user");
  const sessiondata = undefined;
  let user = null;
  if (localdata && localdata.data.id !== undefined) {
    user = localdata.data;
  } else if (sessiondata && sessiondata.data.id !== undefined) {
    user = sessiondata.data;
  }
  let admin_access;
  let store_owner = user?.store_owner;
  if (user?.permission) {
    admin_access = user.permission[storeId].access_permission;
  }
  return admin_access == 2 || store_owner;
}

export function getUser() {
  // return authorization header with token
  const localdata = LocalStorage.getItem("pos_user");
  const sessiondata = undefined;
  let user = null;
  if (localdata && localdata.data !== undefined) {
    user = localdata.data;
  } else if (sessiondata && sessiondata.data !== undefined) {
    user = sessiondata.data;
  }
  if (user) {
    return user;
  } else {
    return false;
  }
}

export function getMasterStoreId() {
  let master_store = LocalStorage.getItem("master_store_id");
  return master_store;
}

export function checkUserPermission(module, userDetails) {
  try {
    let user = userDetails;
    let storeId = LocalStorage.getItem("setupData");
    storeId = storeId.store || 1;
    if (!user) {
      const localdata = LocalStorage.getItem("pos_user");
      const sessiondata = undefined;
      if (localdata && localdata.data.id !== undefined) {
        user = localdata.data;
      } else if (sessiondata && sessiondata.data.id !== undefined) {
        user = sessiondata.data;
      }
    }
    let permission = {};
    let admin_access;
    let dashboardAccess;
    if (user.permission) {
      admin_access = user.permission[storeId].access_permission;
      permission = user.permission[storeId].pospermission;
      dashboardAccess = user.permission[storeId].dashboard_permission;
    }

    let store_owner = user.store_owner;

    if (
      !permission ||
      permission != null ||
      permission !== "null" ||
      admin_access == 2 ||
      store_owner
    ) {
      // done=>
      // reports_hide
      // day_report_hide
      // allow_playback_hide
      // remove_items_from_tran_view
      // refund_transaction_hide
      // ask_close_register_on_logout
      // apply_discount_hide
      // allow_edit_item_view
      // allow_edit_item_edit
      // allow_suspend_and_recall
      // allow_add_item_hide

      //remaining=>
      // allow_exchange_refund_hide
      // print_register_report_short
      // print_register_report_regular

      // permission = {
      //     reports_hide: false,
      //     day_report_hide: false,
      //     print_register_report_short: false,
      //     print_register_report_regular: false,
      //     remove_items_from_tran_view: true,
      //     allow_playback_hide: false,
      //     refund_transaction_hide: true,
      //     apply_discount_hide: false,
      //     allow_exchange_refund_hide: false,
      //     allow_edit_item_view: false,
      //     allow_edit_item_edit: false,
      //     allow_add_item_hide: false,
      //     ask_close_register_on_logout: false,
      //     allow_suspend_and_recall: true,
      //   }
      if (admin_access == 2 || store_owner) {
        if (module === "ask_close_register_on_logout") {
          return false;
        } else {
          return true;
        }
      } else {
        if (module === "setting") {
          return false;
        } else {
          return permission[module];
        }
      }
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
