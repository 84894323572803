import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Fade,
  Badge,
  Image,
} from "@themesberg/react-bootstrap";

import { useHistory, useLocation } from "react-router-dom";

// Other imports
import { usePusher } from "../../context/PusherContext.js";
import ReactSelect, { components } from "react-select";
import BootstrapTable2 from "../components/BootsrapTable2";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../components/Modals";
import TransactionDetail from "../TransactionDetails/TransactionDetails";
import { connect, useDispatch } from "react-redux";
import CustomScroll from "react-custom-scroll";
import {
  SaleStatusData,
  FirtsLetterUpperCase,
  dateFormate,
  currencyFormat,
  isWeb,
  FormatImageBase64Code,
  base64ToHex,
  hexToBase64,
  StringToHex,
  HexToString,
  getLRC,
  PusherUpdateFunction,
} from "../../app/helpers/custom";
import {
  ALL,
  ALLVALUE,
  CANCELED,
  CANCELEDVALUE,
  COMPLETE,
  COMPLETEVALUE,
  DECELINED,
  DECELINEDVALUE,
  NOSALE,
  NOSALEVALUE,
  ORDER,
  ORDERVALUE,
  REFUNDED,
  REFUNDEDVALUE,
  VOID,
  VOIDVALUE,
  ITEMSALELIST,
  ITEMSALELISTUPDATED,
  ITEMSALEREFUND,
  SALESSATUSFILTER,
  ITEMSALENOTE,
  SALECHANNEl,
  SALE_EVENT,
  COMPLETEITEMSALELIST,
  ITEMSALE,
  ITEMSALELIST_GET_LIST,
  LOADING,
  ADDCONFIRMSALEITEM,
  UPDATESALESTATUS,
  REMOVEITEMSALE,
  DISCOUNT,
  PAYFACREFUND,
  GENERALSETIINGDATA,
  ITEMDISCOUNTS,
  RECALL,
  SIDECARD,
  DEBIT,
  CHECK
} from "../../app/helpers/constant";
import {
  authUserId,
  authUserName,
  checkUserPermission,
  getDeviceAndStore,
  getDeviceAndStoreName,
  getSettingData,
} from "../../axios/authHeader";
import moment from "moment";
import {
  editActionFunction,
  getListActionFunction,
  getSingleAction,
} from "../../app/actions/getListAction";
import FormField from "../components/formField";
import { RefundTransaction } from "../TransactionDetails/RefundTransaction";
import { useReactToPrint } from "react-to-print";
import "../../scss/print.scss";
import QRCode from "react-qr-code";
import {
  completeSale,
  confirmedResponseAction,
} from "../../app/actions/saleItemAction";
import { checkOutSaleItem } from "../../app/actions/saleItemAction";
import { Base64 } from "js-base64";
import $ from "jquery";
import { Auth } from "../auth/auth.js";
import Localstorage from "app/storage/Localstorage.js";
import LoaderSpinner from "components/Loader.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import noData from "../../assets/images/no-data-found.svg";
import { payFac } from "app/services/payFacService.js";
import Cancel from "../../assets/images/cancel.png";
import attention from '../../assets/images/attention.png';

const SalesTables = (props) => {
  const [darkMode,] = useState(props?.darkMode);
  const location = useLocation();
  let pusher = usePusher();
  const { SearchBar, ClearSearchButton } = Search;
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [show9, setShow9] = useState(false);
  const [isPayfacPayment, setIspayfacPayment] = useState(false);
  const [openType, setOpenType] = useState("");
  const [authSuccess, setAuthSuccess] = useState(false);
  const [completeClick, setCompleteClick] = useState(false);
  const [reason, setReason] = useState(props.Details?.Data?.Reason);
  const [notes, setNotes] = useState(props.Details?.Data?.notes);
  const [openErrorMsg, setOpenErrorMsg] = useState(false);
  const [openErrorMsg1, setOpenErrorMsg1] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [title, setTitle] = useState("");
  const [promt, setPromt] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [pageOffset, setPageOffset] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [orderBy, setOrderBy] = useState("asc");
  const [fieldName, setFieldName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewOrder, setViewOrder] = useState(true);
  const [saveNote, setSaveNote] = useState(false);
  const [openTransDetails, setOpenTransDetails] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(
    localStorage.getItem("SystemStatus")
  );
  const [dynamicImages, setDynamicImages] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState(false);
  const [auth, setAuth] = useState(false);
  const [authorizedUsers, setAuthorizedUsers] = useState([]);
  const [showIcon, setShowIcon] = useState(false);
  const [method, setMethod] = useState("");
  useEffect(() => {
    setDynamicImages(props.dynamicImages);
  }, [props.dynamicImages]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const statusList = [
    { name: ALL, id: ALLVALUE },
    { name: CANCELED, id: CANCELEDVALUE },
    { name: COMPLETE, id: COMPLETEVALUE },
    // { name: ACCEPTED, id: ACCEPTEDVALUE },
    { name: DECELINED, id: DECELINEDVALUE },
    { name: NOSALE, id: NOSALEVALUE },
    { name: ORDER, id: ORDERVALUE },
    { name: REFUNDED, id: REFUNDEDVALUE },
    { name: VOID, id: VOIDVALUE },
  ];

  const [saleListData, setSaleListData] = useState([]);
  const [transectionDetails, setTransectionDetails] = useState({ Data: "" });
  const [status, setStatus] = useState("");
  const [globalReference, setGlobalReference] = useState("");
  const [sideCardPayment, setSideCardPayment] = useState(false);
  const [debitCardPayment, setDebitCardPayment] = useState(false);
  const [checkPayment, setCheckPayment] = useState(false);

  useEffect(()=>{
    if (transectionDetails?.Data?.Payments?.length > 0){
      if (transectionDetails?.Data?.Payments.filter(payment => payment?.method === SIDECARD).length > 0){
        setSideCardPayment(true);
      } else {
        setSideCardPayment(false);
      }
      if (transectionDetails?.Data?.Payments.filter(payment => payment?.method === DEBIT).length > 0){
        setDebitCardPayment(true);
      } else {
        setDebitCardPayment(false);
      }
      if (transectionDetails?.Data?.Payments.filter(payment => payment?.method === CHECK).length > 0){
        setCheckPayment(true);
      } else {
        setCheckPayment(false);
      }
    }
  }, [transectionDetails])

  // pax config
  const [paxConfig, setPaxConfig] = useState({
    pax_ip: "",
    pax_port: "",
    pax_protocol: "",
  });

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    function updateOnlineStatus() {
      var condition = navigator.onLine ? 1 : 2;
      console.log("add you are still in1", condition === 1 ? true : false);
      localStorage.setItem("SystemStatus", condition === 1 ? true : false);
      setOnlineStatus(condition === 1 ? true : false);
    }
  }, []);

  useEffect(() => {
    const setupData = JSON.parse(localStorage.getItem("setupData"));
    if (props?.device.length > 0 && setupData != undefined) {
      const paxDetails = props.device.filter(
        (item) => item.id === setupData.device
      );
      if (
        paxDetails != undefined &&
        (paxDetails != "") & (paxDetails != null)
      ) {
        setPaxConfig({
          pax_ip: paxDetails[0].pax_ip,
          pax_port: paxDetails[0].pax_port,
          pax_protocol: paxDetails[0].pax_protocol,
        });
      }
    }
  }, [props.device]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setShow(false)
        if (props.handleClose) {
          props.handleClose(false);
        }
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);



  useEffect(() => {
    function childEventCallback(data) {
      const sales = [...saleListData];
      let listUpdate;
      let pusherData = data.data;
      try {
        pusherData = JSON.parse(pusherData);
      } catch (e) { }
      if (data.method === "put" && Object.entries(pusherData).length > 0) {
        listUpdate = PusherUpdateFunction(
          "put",
          sales,
          UpdateArray([pusherData])[0]
        );
      } else if (
        data.method === "add" &&
        Object.entries(pusherData).length > 0
      ) {
        listUpdate = PusherUpdateFunction(
          "add",
          sales,
          UpdateArray([pusherData])[0]
        );
        setPageCount(pageCount + 1);
      } else if (data.method === "delete" && data.id > 0) {
        listUpdate = PusherUpdateFunction("delete", sales, data);
        setPageCount(pageCount - 1);
      }
      setSaleListData(listUpdate);
    }
    const channel = pusher.subscribe(SALECHANNEl);
    channel.bind(SALE_EVENT, childEventCallback);
    return () => {
      channel.unbind(SALE_EVENT, childEventCallback);
    };
  }, [saleListData, pusher]);

  const handleTransectionDetails = (row) => {
    if (row) {
      setShow(true);
      setTransectionDetails({ Data: row });
    } else {
      setShow(false);
      setTimeout(() => {
        setTransectionDetails({ Data: "" });
      }, 300);
    }
  };

  useEffect(() => {
    if (
      props?.handleClose &&
      !show &&
      transectionDetails?.Data === "" &&
      openTransDetails
    ) {
      props.handleClose(false);
    }
  }, [show, transectionDetails]);

  useEffect(() => {
    if (show7) {
      setAuthSuccess(false);
      setShow7(false);
      if (authSuccess == true) {
        if (openType === "refund") {
          setShow2(authSuccess);
        } else if (openType === RECALL) {
          handleComplete(true)
        } else {
          setShow1(authSuccess);
        }
      }
    }
  }, [authSuccess]);

  useEffect(() => {
    if (props?.saleData && typeof props?.saleData === "object") {
      setTransectionDetails({
        Data: UpdateArray([props?.saleData])[0],
      });
      setOpenTransDetails(true);
      setShow(true);
    }
  }, [props?.saleData]);

  useEffect(() => {
    setStatus(props?.statusFilter);
  }, [props?.statusFilter]);

  function removeDuplicatesByRef(array) {
    const uniqueIds = {};
    const resultArray = [];

    for (const item of array) {
      if (!uniqueIds[item?.ref]) {
        uniqueIds[item?.ref] = true;
        resultArray.push(item);
      }
    }

    return resultArray;
  }

  useEffect(() => {
    setLoading(props?.loading);
    if (props?.saleList.length > 0) {
      let offline_sales;
      if (localStorage.getItem("off_sale")) {
        offline_sales = UpdateArray(
          JSON.parse(localStorage.getItem("off_sale"))
        );
      }
      offline_sales
        ? setSaleListData(
          removeDuplicatesByRef([
            ...offline_sales,
            ...UpdateArray(props.saleList),
          ])
        )
        : setSaleListData(removeDuplicatesByRef(UpdateArray(props.saleList)));
      setPageCount(props.saleList.length);
      setShow1(false);
      // offline sales listing
      const onlineList = UpdateArray(props.saleList);
      if (!isWeb()) {
        // OfflineData(uploadOfflineSales);
      }
      function uploadOfflineSales(ress) {
        const res_data = [];
        res_data.push(ress.data);
        console.log(`res_data`, res_data);
        if (Object.keys(ress).length > 0) {
          const offlineList = UpdateArray(res_data);
          const allRecords = [...offlineList, ...onlineList];
          setSaleListData(allRecords);
        }
      }
      // offline sales listing
    } else {
      // offline sales listing
      const onlineList = [];
      // setSaleListData([]);
      let offline_sales;
      if (localStorage.getItem("off_sale")) {
        offline_sales = UpdateArray(
          JSON.parse(localStorage.getItem("off_sale"))
        );
      }
      offline_sales ? setSaleListData([...offline_sales]) : setSaleListData([]);
      if (!isWeb()) {
        // OfflineData(uploadOfflineSales);
      }
      function uploadOfflineSales(ress) {
        const res_data = [];
        res_data.push(ress.data);
        console.log(`res_data`, res_data);
        if (Object.keys(ress).length > 0) {
          const offlineList = UpdateArray(res_data);
          const allRecords = [...offlineList, ...onlineList];
          setSaleListData(allRecords);
        }
      }
    }
  }, [props.saleList, props?.success, props?.loading]);


  const checkPayFacPayment = () => {
    try {
      const payFacPayments = transectionDetails?.Data?.Payments.filter(
        (payment) => payment?.method != "Cash" && JSON.parse(payment?.transaction_responce)?.payment_id
      );
      return payFacPayments?.length
    } catch (error) {
      console.warn("error ", error)
      return 0
    }
  }

  useEffect(() => {
    if (transectionDetails?.Data != "") {
      const cardTransactions = transectionDetails?.Data?.Payments.filter(
        (payment) => payment?.method != "Cash" && payment?.method != "Check" && payment?.method != "Side Card" && payment?.method != "gift" && payment?.method != DEBIT
      );
      const cashTransactions = transectionDetails?.Data?.Payments.filter(
        (payment) => payment?.method === "Cash"
      );
      let payFacPayments = checkPayFacPayment()
      setIspayfacPayment(payFacPayments)
      if (cardTransactions.length > 0) {
        if (payFacPayments) {
          setButtonDisable(cashTransactions.length ? true : payFacPayments != 1);
        } else {
          setButtonDisable(false);
        }
      } else {
        setButtonDisable(true);
      }
    }
  }, [transectionDetails]);

  const handleGlobalReference = (e, search) => {
    if (e && !search) {
      setRefFocus(true);
      setGlobalReference(e.target.value?.trim());
    } else {
      setRefFocus(false);
      setCancel(true);
      getSaleList(
        pageOffset,
        pageSize,
        searchText,
        orderBy,
        fieldName,
        status,
        globalReference,
        true
      );
    }
  };

  useEffect(() => {
    getSaleList(
      pageOffset,
      pageSize,
      searchText,
      orderBy,
      fieldName,
      status,
      globalReference
    );
  }, [status]);

  const handleViewOrder = (status) => {
    setViewOrder(!viewOrder);
    dispatch({
      type: SALESSATUSFILTER,
      actions: status,
    });
  };

  const UpdateArray = (array) => {
    return array
      .filter((dt) => {
        return dt != 0;
      })
      .map((data) => {
        let customer = undefined;
        if (data?.custid > 0 && data.customer) {
          customer = data?.customer;
        }
        return {
          ...data,
          GID: data?.id,
          Ref: data?.ref?.split("-")[2],
          DeviceStore: FirtsLetterUpperCase(
            props?.device?.find((e) => +e.id == +data?.device_id)?.name ||
            getDeviceAndStoreName(true)[0]
          ),
          CustomerName: customer ? customer?.name : "N/A",
          Mobile: customer ? customer?.mobile : "N/A",
          Items: data?.numitems || 0,
          Total: currencyFormat(data?.total),
          SaleTime: data?.dt,
          Status: data?.status,
          StatusDescription: SaleStatusData(parseInt(data?.status))?.value,
          Payments: data?.payments || [],
          Item: data?.items,
          Extracosts: data?.extracosts,
          Processdt: dateFormate(parseInt(data?.processdt)),
          Store: FirtsLetterUpperCase(getDeviceAndStoreName(true)[1]),
          UserID: FirtsLetterUpperCase(authUserName()),
          devicestore: `${FirtsLetterUpperCase(
            props?.device?.find((e) => +e.id == +data?.device_id)?.name ||
            getDeviceAndStoreName(true)[0]
          )} / ${FirtsLetterUpperCase(getDeviceAndStoreName(true)[1])}`,
          Notes: data?.notes,
          actions: "Actions",
        };
      });
  };

  const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          className="p-0"
          variant="link"
          onClick={() => handleTransectionDetails(row)}
          title="View"
        >
          <Image src={props?.dynamicImages?.EyeIconCLosed} alt="Eye Icon" />
        </Button>
      </>
    );
  };
  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    const { value, color } = SaleStatusData(parseInt(row.Status));
    return <Badge bg={color}>{value}</Badge>;
  };

  const gidFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="d-flex  align-items-sm-center">
        {row.GID == undefined || row.GID == "" ? (
          <Badge bg="danger">Offline</Badge>
        ) : (
          <Badge bg="purple">Synced</Badge>
        )}
        <span className="ms-2">{row.GID}</span>
      </div>
    );
  };


  function OfflineData(callback) {
    window.knex = window.Knex({
      client: "sqlite3",
      debug: false,
      acquireConnectionTimeout: 180000,
      connection: {
        filename: window.appData + "database.sqlite",
      },
      useNullAsDefault: true,
      log: {
        warn(message) {
          console.log(message);
        },
        error(message) {
          console.log(message);
        },
        deprecate(message) {
          console.log(message);
        },
        debug(message) {
          //console.log(message)
        },
      },
    });
    window.knex.schema.hasTable("data").then(function (exists) {
      if (!exists) {
        return window.knex.schema.createTable("data", function (t) {
          t.string("key", 100).primary();
          t.jsonb("data");
        });
      }
    });
    window.knex.schema.hasTable("wpos_osales").then(function (exists) {
      if (!exists) {
        return window.knex.schema.createTable("wpos_osales", function (t) {
          t.string("ref", 100).primary();
          t.jsonb("data");
        });
      }
    });
    var query = window.knex("wpos_osales").select("*");
    query
      .then((rows) => {
        console.log("rows", rows);
        if (rows) {
          var jsondata = {};
          for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            jsondata = JSON.parse(element.data);
          }
          callback(jsondata);
        } else {
          callback({});
        }
      })
      .catch(function (e) {
        console.error("error reading from db", e);
        callback({});
      });
  }

  const columns = [
    {
      dataField: "GID",
      text: "GID",
      sort: true,
      formatter: gidFormatter,
      attrs: {
        "data-title": "GID",
      },
      sortFunc: (a, b, order) => {
        if (order === "asc") {
          return b - a;
        }
        return a - b; // desc
      },
    },
    {
      dataField: "Ref",
      text: "Ref",
      sort: true,
      attrs: {
        "data-title": "Ref",
      },
    },
    {
      dataField: "ref",
      text: "ref",
      sort: true,
      hidden: true,
      attrs: {
        "data-title": "ref",
      },
    },
    {
      dataField: "devicestore",
      text: "Device / Store",
      sort: true,
      attrs: {
        "data-title": "Device / Store",
      },
    },
    {
      dataField: "CustomerName",
      text: "Customer Name",
      sort: true,
      attrs: {
        "data-title": "Customer Name",
      },
    },
    {
      dataField: "Mobile",
      text: "Mobile",
      sort: true,
      attrs: {
        "data-title": "Mobile",
      },
    },
    {
      dataField: "Items",
      text: "#Items",
      sort: true,
      attrs: {
        "data-title": "#Items",
      },
    },
    {
      dataField: "Total",
      text: "Total",
      sort: true,
      attrs: {
        "data-title": "Total",
      },
    },
    {
      dataField: "Processdt",
      text: "Sale Time",
      sort: true,
      attrs: {
        "data-title": "Sale Time",
      },
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      attrs: {
        "data-title": "Status",
      },
    },
    {
      dataField: "Actions",
      text: "Actions",
      formatter: actionsFormatter,
      attrs: {
        "data-title": "Actions",
      },
    },
  ];

  const handleLoader = (value, type) => {
    setLoader(value);
    setLoaderType(type);
  };

  const handlePayFacRefundFn = async () => {
    try {
      handleLoader(true, "Follow Prompts on Terminal");
      let payFacResposne = JSON.parse(transectionDetails?.Data?.Payments[0]?.transaction_responce);
      let refundStatus = await payFac(PAYFACREFUND, { payment_id: payFacResposne.payment_id, external_account_id: getSettingData(GENERALSETIINGDATA)?.CREDIT?.payfac_account_id, amount: payFacResposne.amount })
      if (refundStatus?.data?.status === "refunded" || refundStatus?.data?.status === "partially_refunded" || refundStatus?.data?.status === "refund_requested") {
        handleLoader(false, "");
        dispatch(
          editActionFunction(
            ITEMSALEREFUND,
            {
              method: "Card",
              processdt: `${Date.now()}`,
              storeId: transectionDetails?.Data?.store_id,
              amount: transectionDetails?.Data?.total,
              itemsId: transectionDetails?.Data?.items.map((e) => {
                return e.id;
              }),
            },
            transectionDetails?.Data?.id,
            ITEMSALELISTUPDATED
          )
        );
        setPromt({
          type: "refundPrintConfitmaton",
          title: "Confirmation",
          msg: "Would you like to print a receipt?",
        });
        setShow6(false);
        setShow2(false);
      }
    } catch (error) {
      setPromt({
        title: "Error",
        msg: error?.response?.data?.error || error?.message,
      });
    }
  }

  const refundVoidFn = (cmdtype) => {
    setShow6(false);
    console.log(`propps`, transectionDetails);
    let refund_url = "";
    var parms = {};

    var refund_amount = +transectionDetails.Data?.total + +transectionDetails.Data?.Tip_amount;
    parms.transactionAmount = parseFloat(refund_amount);
    let responseObj = "";
    var transaction_responce;
    // var transaction_responce =
    //   transectionDetails?.Data?.Payments[0].transaction_responce
    for (let payment of transectionDetails?.Data?.Payments) {
      if (payment.transaction_responce) {
        transaction_responce = payment.transaction_responce;
        break;
      }
    }
    if (transaction_responce != null && transaction_responce != "") {
      responseObj = JSON.parse(transaction_responce.replace(/'/g, '"'));
    }
    const transactionNumber = parseInt(responseObj?.transactionId);
    console.log(`transactionNumber`, transactionNumber);
    parms.transactionId = transactionNumber;
    // parms.transactionId = transectionDetails.Data?.ref;

    parms.headers = getPaymentHeader();
    parms.ticketNumber = transectionDetails.Data?.ref;

    var settings = getPaymentObject("Pax", parms, cmdtype, refund_url);
    handleLoader(true, "Follow Prompts on Terminal");
    $.ajax(settings)
      .done(function (response) {
        response = handlePaymentResponse("Pax", response);
        console.log(`void refund response->`, response);
        handleLoader(false, "");
        if (response.isApproved == true) {
          console.log(`approved`, response.isApproved);
          const storeId = transectionDetails.Data.store_id;
          console.log(`storeId`, storeId);
          const id = transectionDetails.Data.id;

          if (cmdtype == "void") {
            dispatch(
              editActionFunction(
                ITEMSALELIST,
                { reason, processdt: `${Date.now()}`, storeId },
                id,
                ITEMSALELISTUPDATED
              )
            );
            setShow2(false);
            setShow1(false);
            setPromt(false);
            setReason("");
          } else if (cmdtype == "refund") {
            dispatch(
              editActionFunction(
                ITEMSALEREFUND,
                {
                  method: method,
                  processdt: `${Date.now()}`,
                  storeId: transectionDetails?.Data?.store_id,
                  amount: transectionDetails?.Data?.total,
                  itemsId: transectionDetails?.Data?.items.map((e) => {
                    return e.id;
                  }),
                },
                transectionDetails?.Data?.id,
                ITEMSALELISTUPDATED
              )
            );
            setPromt({
              type: "refundPrintConfitmaton",
              title: "Confirmation",
              msg: "Would you like to print a receipt?",
            });
            setShow6(false);
            setShow2(false);
          }
        }
        setMethod("")
      })
      .fail(function (jqXHR, status, error) {
        var errormessage;
        if (jqXHR.status === 0) {
          errormessage = "Could not connect to pinpad";
          setShowIcon(Cancel);
        } else if (jqXHR.status == 404) {
          errormessage = "Requested page not found. [404]";
          setShowIcon(attention);
        } else if (jqXHR.status == 500) {
          errormessage = "Internal Server Error [500].";
          setShowIcon(attention);
        } else {
          errormessage = "Uncaught Error.\n" + jqXHR.responseText;
        }
        handleLoader(false, "");

        setOpenErrorMsg1(true);
        setTitle("Pax Error");
        setErrorMsg(errormessage);
        // setCardType("");
        setMethod("")
      });
  };

  const handlePaymentResponse = (type, response) => {
    if (type == "Pax") {
      return parseResponse(response);
    }
  };

  function parseResponse(response) {
    var commandType = "DoCredit";
    var checkParams = StringToHex(response).split(" ").pop();
    var RedundancyCheck = StringToHex(response).split(" ").pop().substring(1);

    var check = getLRC(checkParams);

    if (check == RedundancyCheck) {
      //get package detail info
      var packetInfo = [];
      var len = StringToHex(response).indexOf("03");
      var hex = StringToHex(response).slice(0, len).split(/02|1c/);

      console.log(hex);
      if (commandType == "DoCredit") {
        var subHex = [],
          subPacketInfo = [];
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            if (hex[i].indexOf("1f") > 0) {
              subHex = hex[i].split("1f");
              console.log(subHex);
              subPacketInfo = [];
              for (var j = 0; j < subHex.length; j++) {
                if (subHex[j] != "") {
                  subPacketInfo.push(HexToString(subHex[j]));
                }
              }
              console.log(subPacketInfo);
              packetInfo.push(subPacketInfo);
            } else {
              packetInfo[i] = HexToString(hex[i]);
            }
          }
        }
      } else {
        for (var i = 0; i < hex.length; i++) {
          if (hex[i] != "") {
            packetInfo[i] = HexToString(hex[i]);
          }
        }
      }
      response = packetInfo;
    }
    if (typeof response == "string") {
      // WPOS.util.okDialog("Error", response, function (dat) { });
      console.log(`responsee`, response);
      return true;
    }

    var i = 0,
      j = -1;
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
    };
    PacketageInfo.DoCredit.Status = response[++i];
    PacketageInfo.DoCredit.Command = response[++i];
    PacketageInfo.DoCredit.Version = response[++i];
    PacketageInfo.DoCredit.ResponseCode = response[++i];
    PacketageInfo.DoCredit.ResponseMessage = response[++i];
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.Command) {
          commandName = key;
        }
      }
    }
    if (PacketageInfo.DoCredit.ResponseCode == "000000") {
      PacketageInfo.DoCredit.isApproved = true;
    } else {
      PacketageInfo.DoCredit.isApproved = false;
    }

    PacketageInfo.DoCredit.HostInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.HostInformation == "") {
      PacketageInfo.DoCredit.HostInformation = {};
      PacketageInfo.DoCredit.HostInformation.HostResponseCode = "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage = "";
      PacketageInfo.DoCredit.HostInformation.AuthCode = "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber = "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber = "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber = "";
    } else {
      PacketageInfo.DoCredit.HostInformation.HostResponseCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostResponseMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.AuthCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.HostReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.TraceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.HostInformation.BatchNumber =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit._processor = {
      processorLogs: [
        "ExpressResponseCode: [0]\r\nExpressResponseMessage: [" +
          PacketageInfo.DoCredit.isApproved
          ? commandName + "Approved"
          : "" +
          "]\r\nHostResponseCode: [" +
          PacketageInfo.DoCredit.HostInformation.HostResponseCode +
          "]\r\nHostResponseMessage: [ " +
          PacketageInfo.DoCredit.HostInformation.HostResponseMessage +
          "]",
      ],
      processorRawResponse: "",
      processorReferenceNumber:
        PacketageInfo.DoCredit.HostInformation.HostReferenceNumber,
      processorRequestFailed: PacketageInfo.DoCredit.isApproved,
      processorRequestWasApproved: PacketageInfo.DoCredit.isApproved,
      processorResponseCode: PacketageInfo.DoCredit.isApproved
        ? commandName + "Approved"
        : "",
      processorResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      expressResponseCode:
        PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      expressResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      hostResponseCode: PacketageInfo.DoCredit.HostInformation.HostResponseCode,
      hostResponseMessage:
        PacketageInfo.DoCredit.HostInformation.HostResponseMessage,
      logs: ["ExpressResponseCode: ''"],
      rawResponse: "",
    };

    PacketageInfo.DoCredit.TransactionType =
      response[++i] != undefined ? response[i] : "";

    PacketageInfo.DoCredit.commandName = "";
    var commandName = "";
    for (var key in processTypes) {
      if (processTypes.hasOwnProperty(key)) {
        var val = processTypes[key];
        if (val == PacketageInfo.DoCredit.TransactionType) {
          commandName = key.charAt(0).toUpperCase() + key.slice(1) + " ";
          PacketageInfo.DoCredit.commandName = commandName;
        }
      }
    }

    PacketageInfo.DoCredit.AmountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AmountInformation == "") {
      PacketageInfo.DoCredit.AmountInformation = {};
      PacketageInfo.DoCredit.approvedAmount = "";
      PacketageInfo.DoCredit.subTotalAmount = "0";
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount = "";
      PacketageInfo.DoCredit.AmountInformation.AmountDue = "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount = "";
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount = "";
      PacketageInfo.DoCredit.cashbackAmount =
        PacketageInfo.DoCredit.AmountInformation.CashBackAmount;
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee = "";
      PacketageInfo.DoCredit.debitSurchargeAmount = "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount = "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 = "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AmountInformation.ApproveAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.approvedAmount = parseFloat(
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
      );
      PacketageInfo.DoCredit.subTotalAmount = parseFloat(
        PacketageInfo.DoCredit.AmountInformation.ApproveAmount / 100
      );

      PacketageInfo.DoCredit.AmountInformation.AmountDue =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.TipAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.tipAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.CashBackAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.MerchantFee_SurchargeFee =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.TaxAmount =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.Balance1 =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AmountInformation.Balance2 =
        response[i][++j] != undefined ? response[i][j] : "";
    }
    PacketageInfo.DoCredit.fsaCard = "NotApplicable";
    PacketageInfo.DoCredit.convenienceFeeAmount = "";
    PacketageInfo.DoCredit.AccountInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AccountInformation == "") {
      PacketageInfo.DoCredit.AccountInformation = {};
      PacketageInfo.DoCredit.AccountInformation.Account = "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode = "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate = "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype = "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber = "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo = "";
      PacketageInfo.DoCredit.AccountInformation.CardType = "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder = "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode = "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage = "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AccountInformation.Account =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.EntryMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.ExpireDate =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.EBTtype =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.VoucherNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.NewAccountNo =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardHolder =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CVDApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CVDMessage =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.accountNumber =
      PacketageInfo.DoCredit.AccountInformation.Account;
    PacketageInfo.DoCredit.isCardInserted =
      PacketageInfo.DoCredit.AccountInformation.CardPresentIndicator;
    PacketageInfo.DoCredit.cardHolderName =
      PacketageInfo.DoCredit.AccountInformation.CardHolder;

    PacketageInfo.DoCredit.TraceInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.TraceInformation == "") {
      PacketageInfo.DoCredit.TraceInformation = {};
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber = "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber = "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.ReferenceNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.TraceInformation.TimeStamp =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AVSinformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AVSinformation == "") {
      PacketageInfo.DoCredit.AVSinformation = {};
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode = "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.AVSinformation.AVSApprovalCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.AVSinformation.AVSMessage =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.CommercialInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.CommercialInformation == "") {
      PacketageInfo.DoCredit.CommercialInformation = {};
      PacketageInfo.DoCredit.CommercialInformation.PONumber = "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt = "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID = "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID = "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode = "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.CommercialInformation.PONumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.CustomerCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExempt =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.TaxExemptID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.MerchantTaxID =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.DestinationZipCode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.CommercialInformation.ProductDescription =
        response[i][++j] != undefined ? response[i][j] : "";
    }
    console.log(PacketageInfo.DoCredit.CommercialInformation);

    PacketageInfo.DoCredit.motoEcommerce =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.motoEcommerce == "") {
      PacketageInfo.DoCredit.motoEcommerce = {};
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode = "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType = "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType = "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber = "";
      PacketageInfo.DoCredit.motoEcommerce.Installments = "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment = "";
    } else {
      j = -1;
      PacketageInfo.DoCredit.motoEcommerce.MOTO_ECommerceMode =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.TransactionType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.SecureType =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.OrderNumber =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.Installments =
        response[i][++j] != undefined ? response[i][j] : "";
      PacketageInfo.DoCredit.motoEcommerce.CurrentInstallment =
        response[i][++j] != undefined ? response[i][j] : "";
    }

    PacketageInfo.DoCredit.AdditionalInformation =
      response[++i] != undefined ? response[i] : "";
    if (PacketageInfo.DoCredit.AdditionalInformation == "")
      PacketageInfo.DoCredit.AdditionalInformation = {};
    var additionalInfoArr = PacketageInfo.DoCredit.AdditionalInformation,
      keyValue = [];
    for (i = 0; i < additionalInfoArr.length; i++) {
      keyValue = additionalInfoArr[i].split("=");
      PacketageInfo.DoCredit.AdditionalInformation[keyValue[0]] = keyValue[1];
      keyValue = [];
    }
    if (
      PacketageInfo.DoCredit.AdditionalInformation.hasOwnProperty("CARDBIN")
    ) {
      PacketageInfo.DoCredit.binValue =
        PacketageInfo.DoCredit.AdditionalInformation.CARDBIN;
    }
    PacketageInfo.DoCredit.cardLogo = "";
    PacketageInfo.DoCredit.currencyCode = "";
    PacketageInfo.DoCredit.entryMode = "";
    PacketageInfo.DoCredit.expirationYear = "";
    PacketageInfo.DoCredit.expirationMonth = "";
    PacketageInfo.DoCredit.paymentType = "";
    PacketageInfo.DoCredit.pinVerified = "";
    PacketageInfo.DoCredit.signature = {
      statusCode: "SignatureNotRequiredByThresholdAmount",
    };
    PacketageInfo.DoCredit.statusCode = PacketageInfo.DoCredit.isApproved
      ? commandName + "Approved"
      : "";
    PacketageInfo.DoCredit.transactionDateTime =
      PacketageInfo.DoCredit.TraceInformation.TimeStamp;
    PacketageInfo.DoCredit.transactionId =
      PacketageInfo.DoCredit.TraceInformation.TransactionNumber;
    PacketageInfo.DoCredit.merchantId = "";

    console.log(`PacketageInfo`, PacketageInfo.DoCredit);
    return PacketageInfo.DoCredit;
  }

  const getPaymentHeader = () => {
    var header = {};
    return header;
  };

  const getPaymentObject = (type, parms, processtype = "sale", url = null) => {
    if (type == "Pax") {
      var settings = getPaymentSettings(parms, processtype);
    }
    return settings;
  };

  //DO Credit
  const DoCredit = (doCreditInfo) => {
    var amountInformation,
      accountInformation,
      traceInformation,
      avsInformation,
      cashierInformation,
      commercialInformation,
      motoEcommerce,
      additionalInformation;
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      mDestinationIP: `${paxConfig.pax_protocol == ""
        ? "https"
        : paxConfig.pax_protocol == "1"
          ? "http"
          : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };

    var params = [
      PAX.mStx.hex,
      doCreditInfo.command,
      PAX.mFS.hex,
      doCreditInfo.version,
    ];
    params.push(PAX.mFS.hex);
    if (doCreditInfo.transactionType != "") {
      params.push(doCreditInfo.transactionType);
    }
    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "amountInformation",
      doCreditInfo.amountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "accountInformation",
      doCreditInfo.accountInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "traceInformation",
      doCreditInfo.traceInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "avsInformation", doCreditInfo.avsInformation);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );

    params.push(PAX.mFS.hex);
    params = PushParams(params, "motoEcommerce", doCreditInfo.motoEcommerce);

    params.push(PAX.mFS.hex);
    params = PushParams(
      params,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    params.push(PAX.mEtx.hex);

    var lrc = getLRC(params);

    console.log(params);

    //prepare for base64 encoding.
    var command_hex = base64ToHex(Base64.btoa(doCreditInfo.command));
    var version_hex = base64ToHex(Base64.btoa(doCreditInfo.version));
    var transactionType_hex = base64ToHex(
      Base64.btoa(doCreditInfo.transactionType)
    );
    var amountInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.amountInformation)
    );
    var accountInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.accountInformation)
    );
    var traceInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.traceInformation)
    );
    var avsInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.avsInformation)
    );
    var cashierInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.cashierInformation)
    );
    var commercialInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.commercialInformation)
    );
    var motoEcommerce_hex = base64ToHex(
      Base64.btoa(doCreditInfo.motoEcommerce)
    );
    var additionalInformation_hex = base64ToHex(
      Base64.btoa(doCreditInfo.additionalInformation)
    );

    //var elements = [this.mStx.code, command_hex, PAX.mFS.code, version_hex, PAX.mFS.code, uploadFlag_hex, PAX.mFS.code, timeout, this.mEtx.code, base64ToHex(Base64.btoa(lrc))];
    var elements = [PAX.mStx.code];
    elements.push(command_hex);
    elements.push(PAX.mFS.code);
    elements.push(version_hex);
    elements.push(PAX.mFS.code);

    if (transactionType_hex != "") {
      elements.push(transactionType_hex);
    }
    elements.push(PAX.mFS.code);

    elements = AddBase64(
      elements,
      "amountInformation",
      doCreditInfo.amountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "accountInformation",
      doCreditInfo.accountInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "traceInformation",
      doCreditInfo.traceInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "avsInformation",
      doCreditInfo.avsInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "cashierInformation",
      doCreditInfo.cashierInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "commercialInformation",
      doCreditInfo.commercialInformation
    );
    elements.push(PAX.mFS.code);
    elements = AddBase64(elements, "motoEcommerce", doCreditInfo.motoEcommerce);
    elements.push(PAX.mFS.code);
    elements = AddBase64(
      elements,
      "additionalInformation",
      doCreditInfo.additionalInformation
    );

    elements.push(PAX.mEtx.code);
    elements.push(base64ToHex(Base64.btoa(lrc)));
    console.log("elements");
    console.log(elements);

    var final_string = elements.join(" ");
    var final_b64 = hexToBase64(final_string);
    console.log("LRC: " + lrc);
    console.log("Base64: " + final_b64);

    // if(customData != ''){
    // 	final_b64 = hexToBase64(final_string+"&custom_data=<PAX>"+customData+"</PAX>");
    // }

    var url = PAX.mDestinationIP + "?" + final_b64;
    console.log("URL: " + url);
    return { url: url, timeout: PAX.timeout.DoCredit };
    // this.HttpCommunication('DoCredit',url,function(response){
    // 	callback(response);
    // },PAX.timeout.DoCredit);
  };

  const AddBase64 = (elements, type, objectInfo) => {
    //console.log(objectInfo);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    var empty = 0;
    var arr = [];
    arr = arr.concat(elements);
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.code);
        continue;
      }
      if (type == "additionalInformation") {
        if (objectInfo[name] == "") continue;
        empty++;
        arr.push(
          base64ToHex(Base64.btoa(name + "=" + objectInfo[name].toString()))
        );
      } else {
        empty++;
        arr.push(base64ToHex(Base64.btoa(objectInfo[name].toString())));
      }
      arr.push(PAX.mUS.code);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = elements;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.code);
    }
    //console.log(arr);
    return arr;
  };

  const PushParams = (params, type, objectInfo) => {
    var empty = 0;
    var arr = [];
    arr = arr.concat(params);
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
    };
    for (let name in objectInfo) {
      if (objectInfo[name] == "" && type != "additionalInformation") {
        arr.push(PAX.mUS.hex);
        continue;
      }

      if (type == "additionalInformation") {
        if (objectInfo[name] == "") {
          continue;
        }
        empty++;
        arr.push(name + "=" + objectInfo[name].toString());
      } else {
        empty++;
        arr.push(objectInfo[name].toString());
      }
      arr.push(PAX.mUS.hex);
    }
    arr.pop();
    if (empty == 0 && type != "additionalInformation") {
      arr = params;
    }
    if (empty == 0 && type == "additionalInformation") {
      arr.push(PAX.mFS.hex);
    }
    //console.log(params);
    return arr;
  };

  const getPaymentSettings = (params, processtype = "sale") => {
    var version, transactionType; //,accountInformation={},traceInformation,avsInformation,cashierInformation,commercialInformation,motoEcommerce,additionalInformation;
    // if(!ConfigureFlag){
    //  DisplayHandler.RemoveLoading();
    //  var $dialog = $("<div id='dialog' title='Command Dialog'>");
    //  HintDialog.CreateDialog("Configure Data isn't set yet.",$dialog);
    //  return false;
    // }

    var configuration = {
      version: "1.28",
      protocol: "https",
      ip: "127.0.0.1",
      port: "10009",
      promptForSignature: 0,
    };
    var PacketageInfo = {
      Initialize: {},
      GetSignature: {},
      DoSignature: {},
      DoCredit: {},
    };
    var processTypes = {
      void: "16",
      sale: "01",
      refund: "02",
      void_sale: "17",
    };

    version = configuration.version;
    transactionType = processTypes[processtype];
    var timestamp = Date.now();
    //set transaction Data

    let amountInformation = {};
    amountInformation.TransactionAmount =
      processtype == "void"
        ? ""
        : Math.round(parseFloat(params.transactionAmount) * 100);
    amountInformation.TipAmount = "";
    amountInformation.CashBackAmount = "";
    amountInformation.MerchantFee = "";
    amountInformation.TaxAmount = "";
    amountInformation.FuelAmount = "";
    console.log(amountInformation);

    let accountInformation = {};
    accountInformation.Account = "";
    accountInformation.EXPD = "";
    accountInformation.CVVCode = "";
    accountInformation.EBTtype = "";
    accountInformation.VoucherNumber = "";
    accountInformation.Force = "";
    accountInformation.FirstName = "";
    accountInformation.LastName = "";
    accountInformation.CountryCode = "";
    accountInformation.State_ProvinceCode = "";
    accountInformation.CityName = "";
    accountInformation.EmailAddress = "";

    let traceInformation = {};
    traceInformation.ReferenceNumber = params.ticketNumber.substring(0, 15);
    traceInformation.InvoiceNumber = "";
    traceInformation.AuthCode = "";
    traceInformation.TransactionNumber = params.hasOwnProperty("transactionId")
      ? params.transactionId
      : "";
    traceInformation.TimeStamp = "";
    traceInformation.ECRTransID = "";

    let avsInformation = {};
    avsInformation.ZipCode = "";
    avsInformation.Address = "";
    avsInformation.Address2 = "";

    let cashierInformation = {};
    cashierInformation.ClerkID = "";
    cashierInformation.ShiftID = "";

    let commercialInformation = {};
    commercialInformation.PONumber = "";
    commercialInformation.CustomerCode = "";
    commercialInformation.TaxExempt = "";
    commercialInformation.TaxExemptID = "";
    commercialInformation.MerchantTaxID = "";
    commercialInformation.DestinationZipCode = "";
    commercialInformation.ProductDescription = "";

    let motoEcommerce = {};
    motoEcommerce.MOTO_E_CommerceMode = "";
    motoEcommerce.TransactionType = "";
    motoEcommerce.SecureType = "";
    motoEcommerce.OrderNumber = "";
    motoEcommerce.Installments = "";
    motoEcommerce.CurrentInstallment = "";

    let additionalInformation = {};
    additionalInformation.TABLE = "";
    additionalInformation.GUEST = "";
    additionalInformation.SIGN =
      processtype == "sale" ? configuration.promptForSignature : "";
    additionalInformation.TICKET = "";
    additionalInformation.HREF = "";
    additionalInformation.TIPREQ = "";
    additionalInformation.SIGNUPLOAD = "";
    additionalInformation.REPORTSTATUS = "";
    additionalInformation.TOKENREQUEST = "";
    additionalInformation.TOKEN = "";
    additionalInformation.CARDTYPE = "";
    additionalInformation.CARDTYPEBITMAP = "";

    additionalInformation.PASSTHRUDATA = "";
    additionalInformation.RETURNREASON = "";
    additionalInformation.ORIGTRANSDATE = "";
    additionalInformation.ORIGPAN = "";
    additionalInformation.ORIGEXPIRYDATE = "";
    additionalInformation.ORIGTRANSTIME = "";
    additionalInformation.DISPROGPROMPTS = "";
    additionalInformation.GATEWAYID = "";
    additionalInformation.GETSIGN =
      processtype == "sale" ? configuration.promptForSignature : "";

    additionalInformation.ENTRYMODEBITMAP = "";
    additionalInformation.RECEIPTPRINT = "1";
    additionalInformation.CPMODE = "";
    additionalInformation.ODOMETER = "";
    additionalInformation.VEHICLENO = "";
    additionalInformation.JOBNO = "";
    additionalInformation.DRIVERID = "";
    additionalInformation.EMPLOYEENO = "";
    additionalInformation.LICENSENO = "";
    additionalInformation.JOBID = "";
    additionalInformation.DEPARTMENTNO = "";
    additionalInformation.CUSTOMERDATA = "";
    additionalInformation.USERID = "";
    additionalInformation.VEHICLEID = "";
    console.log(amountInformation);

    return DoCredit({
      command: "T00",
      version: version,
      transactionType: transactionType,
      amountInformation: amountInformation,
      accountInformation: accountInformation,
      traceInformation: traceInformation,
      avsInformation: avsInformation,
      cashierInformation: cashierInformation,
      commercialInformation: commercialInformation,
      motoEcommerce: motoEcommerce,
      additionalInformation: additionalInformation,
    });
  };

  const defaultSorted = [
    {
      dataField: "GID",
      order: "desc",
    },
  ];

  // Expeand row
  const expandRow = {
    renderer: (row) => (
      <Fade appear={true} in={true}>
        <ul>
          <li>
            <span className="dtr-title">GID</span>
            <span className="dtr-data">{gidFormatter(null, row)}</span>
          </li>
          <li>
            <span className="dtr-title">Customer Name</span>
            <span className="dtr-data">{row?.CustomerName}</span>
          </li>
          <li className="mobile-sales">
            <span className="dtr-title">Mobile</span>
            <span className="dtr-data">{row?.Mobile}</span>
          </li>
          <li>
            <span className="dtr-title">#Items</span>
            <span className="dtr-data">{row?.Items}</span>
          </li>
          <li>
            <span className="dtr-title">Total</span>
            <span className="dtr-data">{row?.Total}</span>
          </li>
          <li>
            <span className="dtr-title">Sale Time</span>
            <span className="dtr-data">{row?.Processdt}</span>
          </li>
          <li className="status-sales">
            <span className="dtr-title">Status</span>
            <span className="dtr-data">{statusFormatter(null, row)}</span>
          </li>
        </ul>
      </Fade>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return expendColorShow(isAnyExpands);
    },
    expandColumnRenderer: ({ expanded }) => {
      return expendColorShow(expanded);
    },
  };
  const expendColorShow = (value) => {
    if (value) {
      return (
        <Image
          className="expand-row-icon"
          src={props?.dynamicImages?.minusIcon}
          alt="minus icon"
        />
      );
    }
    return (
      <Image
        className="expand-row-icon"
        src={props?.dynamicImages?.plusIcon}
        alt="plus icon"
      />
    );
  };

  const expandRow2 = {
    renderer: () => { },
  };

  const size = useWindowSize();
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: "0 px",
      height: "0 px",
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const totalNumberOfPages = pageSize != 0 ? Math.ceil(pageCount / pageSize) : 1;
  const selectOptions = Array.from({ length: totalNumberOfPages }).map(
    (_, index) => ({
      value: index + 1,
      label: String(index + 1),
    })
  );

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size}
    </span>
  );
  const pageListRenderer = ({
    pages,
    onPageChange
  }) => {
    // just exclude <, <<, >>, >
    // const pageWithoutIndication = pages.filter(p => typeof p.page !== 'string');
    const activePage = pageOffset;
    return (
      <div className="bottom-pagination-control">
        <ul className="pagination react-bootstrap-table-page-btns-ul">
          {
            pages.map(p => (
              <li className={`page-item ${activePage === p.page ? 'active' : ''}`}>
                <a className="page-link" href="#" onClick={() => onPageChange(p.page)} >{p.page}</a>
              </li>
            ))
          }
        </ul>
        {pages.length > 1 &&
          <div className="go-to-page custom-react-select">
            <span className="page-selector">Go to Page</span>
            <ReactSelect
              classNamePrefix="react-select"
              menuPlacement="top"
              options={selectOptions}
              value={selectOptions.find(
                (option) => option.value === pageOffset
              )}
              onChange={(selectedOption) => {
                onPageChange(selectedOption.value)
              }}
            />
          </div>
        }
      </div>
    );
  };


  const options = {
    page: pageOffset, // Specify the current page. It's necessary when remote is enabled
    sizePerPage: pageSize, // Specify the size per page. It's necessary when remote is enabled
    // totalSize: saleListData?.length,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: false, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    pageListRenderer,
    paginationTotalRenderer: customTotal,
    onSizePerPageChange: (size) => setPageSize(size),
    onPageChange: (page) => {
      setPageOffset(page);
    },
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: saleListData?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  const handleVoid = (id, processdt, storeId) => {
    if (transectionDetails.Data.payments[0]?.method == "Card") {
      refundVoidFn("void");
    } else {
      if (onlineStatus === "true") {
        dispatch(
          editActionFunction(
            ITEMSALELIST,
            {
              reason,
              processdt: `${Date.now()}`,
              storeId,
              ref: transectionDetails?.Data?.ref,
            },
            id,
            ITEMSALELISTUPDATED
          )
        );
      } else {
        const formData = { reason, processdt: `${Date.now()}`, storeId, isOfflineSaleVoid: transectionDetails?.Data?.temp_id ? true : false, ref: transectionDetails?.Data?.ref, amount: transectionDetails?.Data?.total };
        const off_void_sales = JSON.parse(
          localStorage.getItem("off_void_sales")
        );
        let updatedSales = props?.saleList.map((i) => {
          if (i.id == id) {
            return {
              ...i,
              salevoid: [
                {
                  id,
                  method: "Pos",
                  reason,
                  sale: id,
                  store_id: transectionDetails?.Data?.store_id,
                  transaction_type: 0,
                  user_id: authUserId(),
                  processdt: `${Date.now()}`,
                  void: 1,
                },
              ],
              status: 3,
              Status: 3,
            };
          }
          return i;
        });

        if (transectionDetails?.Data?.temp_id) {
          let off_sales = JSON.parse(localStorage.getItem("off_sale"));
          let updated_off_sales = off_sales.map((sale) => {
            if (sale?.temp_id == id) {
              return {
                ...sale,
                salevoid: [
                  {
                    id,
                    method: "Pos",
                    reason,
                    sale: id,
                    storeId: transectionDetails?.Data?.store_id,
                    transaction_type: 0,
                    user_id: authUserId(),
                    processdt: `${Date.now()}`,
                    void: 1,
                  },
                ],
                status: 3,
                Status: 3,
              };
            }
            return sale;
          });
          localStorage.setItem("off_sale", JSON.stringify(updated_off_sales));
          setTransectionDetails({
            Data: {
              ...transectionDetails?.Data,
              salevoid: [
                {
                  id,
                  method: "Pos",
                  reason,
                  sale: id,
                  store_id: transectionDetails?.Data?.store_id,
                  transaction_type: 0,
                  user_id: authUserId(),
                  processdt: `${Date.now()}`,
                  void: 1,
                },
              ],
              status: 3,
              Status: 3,
            },
          });
        }

        dispatch(
          confirmedResponseAction({ data: updatedSales }, ITEMSALELIST_GET_LIST)
        );
        if (off_void_sales) {
          localStorage.setItem(
            "off_void_sales",
            JSON.stringify([...off_void_sales, { formData, id }])
          );
        } else {
          localStorage.setItem(
            "off_void_sales",
            JSON.stringify([{ formData, id }])
          );
        }
      }
      setShow2(false);
      setPromt(false);
      setReason("");
    }
  };

  const ComponentToPrint = React.forwardRef((props, ref) => {
    const data = props?.details.Data;
    const images = require.context("../../assets/images/apptype", true);
    let imgsrc;
    // if (localStorage.getItem("appLogo") === null) {
    //   imgsrc = logo;
    // } else {
    //   imgsrc = images(`./${localStorage.getItem("appLogo")}.png`);
    // }
    const posSettings = JSON.parse(localStorage.getItem("posSetting"));
    const generalSettings = JSON.parse(localStorage.getItem("generalSetting"));
    let date_format;
    if (generalSettings?.FORMATES?.Date_Format == "m/d/y") {
      date_format = "MM/DD/YYYY";
    } else if (generalSettings?.FORMATES?.Date_Format == "d/m/y") {
      date_format = "DD/MM/YYYY";
    } else if (generalSettings?.FORMATES?.Date_Format == "y/m/d") {
      date_format = "YYYY/MM/DD";
    }
    let taxPercent = 0;
    if (
      transectionDetails?.Data?.Item != undefined &&
      transectionDetails?.Data?.Item.length > 0
    ) {
      taxPercent = (
        (transectionDetails?.Data?.Item[0]?.tax_total /
          transectionDetails?.Data.subtotal) *
        100
      ).toFixed(1);
    }

    let origimage1 = "";
    const d_image = getDetailsofImage("PrintReciptLogo");
    if (d_image !== undefined && d_image.length > 0) {
      if (d_image[0]?.file !== undefined) {
        origimage1 = d_image[0].file;
      }
    }

    let allTax = [];
    let uniqueTaxes = "";

    if (
      transectionDetails.Data.items != undefined &&
      transectionDetails.Data.items.length > 0
    ) {
      uniqueTaxes = transectionDetails.Data.items.reduce((acc, current) => {
        if (!acc[current.tax_id]) {
          acc[current.tax_id] = parseFloat(current.tax_total);
        } else {
          acc[current.tax_id] += parseFloat(current.tax_total);
        }
        return acc;
      }, {});
    }

    Object.entries(uniqueTaxes).map(([taxName, taxValue]) => {
      const tax_name = props.taxRules?.filter((item) => item.id == taxName);
      if (tax_name != undefined && tax_name.length > 0) {
        let array = {
          tax_name: tax_name[0].name,
          total: taxValue,
        };
        allTax.push(array);
      }
    });

    const { value, color } = SaleStatusData(
      parseInt(props.details.Data.Status)
    );

    if (data?.payments && data?.payments.length > 0) {
      var totalTendered = data?.payments.reduce((accumulator, item) => {
        return accumulator + parseFloat(item.tender);
      }, 0);
    }

    const customer = transectionDetails?.Data?.customer;
    console.log("data?.payments", data?.payments)
    let transaction_response = data?.payments[data?.payments?.length - 1]?.transaction_responce;
    if (transaction_response) {
      transaction_response = JSON.parse(transaction_response);
    } else {
      transaction_response = undefined;
    }
    const paymentStatus = transaction_response?.status === "capture_requested" || transaction_response?.status === "succeeded" ? "Approved" : ""

    return (
      <>
        <div className="print-container" ref={ref}>
          {origimage1 != undefined &&
            posSettings?.RECEIPT?.print_receipt_logo == 1 && (
              <Image src={origimage1} alt="logo" />
            )}
          <div style={{ alignItem: "center" }}>
            <h3 style={{ textAlign: "center" }}>
              {generalSettings?.BUSINESS?.business_name}
            </h3>
            <p style={{ textAlign: "center" }}>
              <strong>{posSettings?.RECEIPT?.header_line_2}</strong>
            </p>
            <p>
              <strong>{posSettings?.RECEIPT?.header_line_3}</strong>
            </p>
          </div>
          <div>
            <table>
              <tbody>
                {posSettings?.RECEIPT?.print_sale_id == 1 && (
                  <tr className="mt-2">
                    <td colSpan={2}>Transaction Ref:</td>
                    <td>{data?.ref}</td>
                  </tr>
                )}
                <tr>
                  <td colSpan={2}>Transaction ID:</td>
                  <td>{data?.GID}</td>
                </tr>
                <tr className="mb-2">
                  <td colSpan={2}>Sale Time:</td>
                  <td>{moment(data?.SaleTime).format(date_format)}</td>
                </tr>
                {Array.isArray(transectionDetails?.Data?.Item) &&
                  transectionDetails?.Data?.Item?.map((itm) => {
                    return (
                      <tr>
                        <td colSpan={2}>
                          {itm.qty} x {itm.name}
                          {posSettings?.RECEIPT?.print_item_price == 1 &&
                            `(${currencyFormat(itm?.unit_original)})`}
                        </td>
                        <td>{currencyFormat(itm?.unit * itm.qty)}</td>
                      </tr>
                    );
                  })}
                {transectionDetails?.Data?.discount > 0 && (
                  <tr className="mt-2">
                    <td colSpan={2}>{transectionDetails?.Data?.discount_value}% Discount</td>
                    <td>{currencyFormat(transectionDetails?.Data?.discount)}</td>
                  </tr>
                )}
                {/* <td colSpan={2}>HighTax: {posSettings?.RECEIPT?.do_not_print_tax_per_On_receipt == 0 && `(${taxPercent}%)`}</td> */}

                {allTax?.length > 0 &&
                  allTax?.map((itm, index) => {
                    return (
                      <tr className={index == 0 && "mt-2"}>
                        <td>{itm.tax_name}</td>
                        <td>{currencyFormat(itm.total)}</td>
                      </tr>
                    );
                  })}
                <tr>
                  <td colSpan={2}>B Deposit:</td>
                  <td>{currencyFormat(0)}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <strong>
                      Total ({transectionDetails?.Data?.Items} items):
                    </strong>
                  </td>
                  <td>
                    <strong style={{ textDecoration: "overline" }}>
                      {currencyFormat(transectionDetails?.Data.total)}
                    </strong>
                  </td>
                </tr>
                {data?.payments && data?.payments.length > 0 && (
                  <tr>
                    <td colSpan={2}>
                      <strong>Total Tendered:</strong>
                    </td>
                    <td>
                      <strong style={{ textDecoration: "overline" }}>
                        {currencyFormat(totalTendered)}
                      </strong>
                    </td>
                  </tr>
                )}
                {props.details?.Data?.Payments &&
                  props?.details?.Data?.Payments?.map((item) => {
                    return (
                      <>
                        <tr>
                          <td colSpan={2}>{item.method}:</td>
                          <td>{currencyFormat(item.amount)}</td>
                        </tr>
                        <tr>
                          <td colSpan={2}>Tendered:</td>
                          <td>{currencyFormat(item.tender)}</td>
                        </tr>
                        <tr>
                          <td colSpan={2}>Change:</td>
                          <td>{currencyFormat(item.change)}</td>
                        </tr>
                      </>
                    );
                  })}

                {posSettings?.RECEIPT?.print_customer_details == 1 &&
                  customer != "" && (
                    <>
                      {posSettings?.RECEIPT?.print_customer_name == 1 && (
                        <tr className="mt-2">
                          <td colSpan={2}>Customer Name:</td>
                          <td>{customer?.name}</td>
                        </tr>
                      )}
                      {posSettings?.RECEIPT?.print_customer_mobile == 1 && (
                        <tr>
                          <td colSpan={2}>Customer Mobile:</td>
                          <td>{customer?.mobile}</td>
                        </tr>
                      )}
                      {posSettings?.RECEIPT?.print_customer_address == 1 && (
                        <tr>
                        <td colSpan={4} style={{ textAlign: 'start' }}>Customer Address:</td>
                        <td style={{ textAlign: 'end', maxWidth: '200px', wordWrap: 'break-word' }}>{customer?.address}</td>
                        </tr>
                      )}
                      {posSettings?.RECEIPT?.print_customer_notes == 1 && (
                        <tr>
                          <td colSpan={2}>Notes:</td>
                          <td>{customer?.notes}</td>
                        </tr>
                      )}
                    </>
                  )}
                {transaction_response && (
                  <>
                    <tr>
                      <td colSpan={2}>-------------------------------------------------------------------------</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Status:</td>
                      <td >{paymentStatus || ""}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Sale:</td>
                      <td >{currencyFormat(transaction_response?.amount) || ""}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Account:</td>
                      <td>********{transaction_response?.payment?.last_4 || ""}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>App ID:</td>
                      <td>{transaction_response?.payment?.receipt_data?.emv_app_id || ""}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>App Label:</td>
                      <td>{transaction_response?.payment?.receipt_data?.emv_app_label || ""}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Auth ID:</td>
                      <td>{transaction_response?.payment?.receipt_data?.emv_auth_code || ""}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Terminal ID:</td>
                      <td>{transaction_response?.payment?.receipt_data?.emv_term_id || ""}</td>
                    </tr>
                  </>)
                }
              </tbody>
            </table>
            {posSettings?.RECEIPT?.print_signature_line == "1" && (
              <div style={{ alignItem: "center" }}>
                <p>X________________________</p>
              </div>
            )}
            {value != "Complete" && (
              <h4 style={{ color: color }}>
                {value == "Canceled"
                  ? "Sale Canceled"
                  : value == "Refunded"
                    ? "Refund"
                    : value == "Void"
                      ? "VOID TRANSACTION"
                      : value}
              </h4>
            )}
            {value == "Refunded" && (
              <table>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      {moment().format("DD/MM/YY")}{" "}
                      {moment().format("HH:mm:ss")}
                      {" Cash"}
                    </td>
                    <td>{currencyFormat(transectionDetails?.Data.total)}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      ({transectionDetails?.Data?.Items} items)
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            <strong className="footer-details mb-2 mt-2">
              {posSettings?.RECEIPT?.receipt_footer_text}
            </strong>
            {posSettings?.RECEIPT?.promo_qr_code != undefined && (
              <div className="mt-2">
                <QRCode
                  style={{ zIndex: 1 }}
                  size={100}
                  value={posSettings?.RECEIPT?.promo_qr_code}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  });

  const PrintFn = async () => {
    const wosConfig = JSON.parse(localStorage.getItem("wpos_config"));
    if (wosConfig) {
      if (wosConfig.connectMethod == "ep") {
        if (isWeb()) {
          ShowMsgInfo(
            true,
            "Electron print support only in electron application.",
            "Alert!"
          );
        } else {
          // ESC/P receipt generation
          var esc_init = "\x1B" + "\x40"; // initialize printer
          var esc_p = "\x1B" + "\x70" + "\x30"; // open drawer
          var esc_full_cut = "\x1B" + "\x69"; // esc obsolute full (one point left) cut
          var esc_part_cut = "\x1B" + "\x6D"; // esc obsolute partial (three points left) cut
          var gs_full_cut = "\x1D" + "\x56" + "\x30"; // cut paper
          var gs_part_cut = "\x1D" + "\x56" + "\x31"; // partial cut paper
          var esc_a_l = "\x1B" + "\x61" + "\x30"; // align left
          var esc_a_c = "\x1B" + "\x61" + "\x31"; // align center
          var esc_a_r = "\x1B" + "\x61" + "\x32"; // align right
          var esc_double = "\x1B" + "\x21" + "\x31"; // heading
          var font_reset = "\x1B" + "\x21" + "\x02"; // styles off
          var esc_ul_on = "\x1B" + "\x2D" + "\x31"; // underline on
          var esc_ul_off = "\x1B" + "\x2D" + "\x30"; // underline off
          var esc_bold_on = "\x1B" + "\x45" + "\x31"; // emphasis on
          var esc_bold_off = "\x1B" + "\x45" + "\x30"; // emphasis off

          var cmd = getEscReceiptHeader();
          // cmd += getLogo();
          cmd += getReceiptContent();

          function getEscReceiptHeader() {
            const posSettings = JSON.parse(localStorage.getItem("posSetting"));
            const generalSettings = JSON.parse(
              localStorage.getItem("generalSetting")
            );
            let header_line_2 = posSettings?.RECEIPT?.header_line_2
              ? posSettings?.RECEIPT?.header_line_2
              : "";
            var header_2 = "";
            if (header_line_2 != "") {
              let header_line_2_array = header_line_2.split("\\n");
              for (let i of header_line_2_array) {
                header_2 += i + "\n";
              }
            }
            console.log("header_2", header_2);
            let header_line_3 = posSettings?.RECEIPT?.header_line_3
              ? posSettings?.RECEIPT?.header_line_3
              : "";
            var header_3 = "";
            if (header_line_3 != "") {
              let header_line_3_array = header_line_3.split("\\n");
              for (let i of header_line_3_array) {
                header_3 += i + "\n";
              }
            }
            console.log("header_3", header_3);
            let header =
              esc_init +
              esc_a_c +
              esc_double +
              (generalSettings?.BUSINESS?.business_name
                ? generalSettings?.BUSINESS?.business_name + "\n"
                : "") +
              font_reset;
            header += esc_bold_on + header_2 + "\n";
            header += header_3 + "\n";
            return header;
          }
          let salesPath = "";
          const d_image = getDetailsofImage("PrintReciptLogo");
          if (d_image !== undefined && d_image.length > 0) {
            if (d_image[0]?.file !== undefined) {
              const origimage1 = d_image[0].file;
              salesPath = window.appData + "logo";
              if (!window.fs.existsSync(salesPath)) {
                window.fs.mkdirSync(salesPath);
              }
              const base64Data = origimage1.replace(
                /^data:image\/png;base64,/,
                ""
              );
              window.fs.writeFileSync(
                salesPath + "/image.png",
                base64Data,
                "base64"
              );
            }
          }

          function getReceiptContent() {
            const posSettings = JSON.parse(localStorage.getItem("posSetting"));
            const generalSettings = JSON.parse(
              localStorage.getItem("generalSetting")
            );
            // const taxPercent = ((transectionDetails.Data.Item[0].tax_total / transectionDetails?.Data.subtotal) * 100).toFixed(1)
            let saledate = parseInt(localStorage.getItem("saleDate"));
            let tax = props?.salesitemList?.reduce(function (prev, current) {
              return prev + +current.taxTotal;
            }, 0);
            let qty = props?.salesitemList?.reduce(function (prev, current) {
              return prev + +current.qty;
            }, 0);
            let total_tendered = props?.payment?.reduce(function (
              prev,
              current
            ) {
              return prev + +current.tender;
            }, 0);
            let subtotal = transectionDetails?.Data.Item?.reduce(function (
              prev,
              current
            ) {
              return prev + +current.unit_original * current.qty;
            }, 0);
            console.log(`transectionDetails?.Data.Item`, transectionDetails);
            console.log(`transectionDetails?.Data`, transectionDetails?.Data);
            const data = transectionDetails?.Data;

            let content = "\n" + font_reset;
            if (posSettings?.RECEIPT?.print_sale_id == 1) {
              content += getEscTableRow(
                "Transaction Ref:",
                data?.ref,
                false,
                false,
                true
              );
            }
            // content += getEscTableRow(
            //   "Transaction ID:",
            //   data?.GID,
            //   false,
            //   false,
            //   true
            // );

            content += getEscTableRow(
              "Sale Time:",
              dateFormate(data?.SaleTime),
              false,
              false,
              true
            );

            content += "\n";

            console.log(`transectionDetails`, transectionDetails);

            for (var x = 0; x < transectionDetails?.Data.Item.length; x++) {
              let itm = transectionDetails?.Data.Item[x];
              content +=
                getEscTableRow(
                  itm.qty +
                  " x " +
                  itm.name +
                  " (" +
                  currencyFormat(itm?.unit_original) +
                  ")",
                  currencyFormat(itm?.unit * itm.qty),
                  false,
                  false,
                  true
                ) + "\n";
            }

            // content += esc_bold_on + getEscTableRow('Subtotal:', currencyFormat(subtotal), false, false, true);
            content +=
              esc_bold_on +
              getEscTableRow(
                "Subtotal:",
                currencyFormat(transectionDetails?.Data?.subtotal),
                false,
                false,
                true
              );

            const uniqueTaxes = transectionDetails?.Data.Item.reduce(
              (acc, current) => {
                if (!acc[current.tax_id]) {
                  acc[current.tax_id] = parseFloat(current.tax_total);
                } else {
                  acc[current.tax_id] += parseFloat(current.tax_total);
                }
                return acc;
              },
              {}
            );

            const tip = transectionDetails?.Data.Payments.reduce(
              (acc, item) => acc + parseFloat(item.tip_amount),
              0
            );

            Object.entries(uniqueTaxes).map(([taxName, taxValue], index) => {
              console.log(`index`, index);
              console.log(`uniqueTaxes`, uniqueTaxes.length - 1);
              const tax_name = props.taxRules?.filter(
                (item) => item.id == taxName
              );
              if (tax_name && tax_name.length > 0) {
                if (index == Object.keys(uniqueTaxes).length - 1) {
                  if (tip != undefined && tip > 0) {
                    content +=
                      font_reset +
                      getEscTableRow(
                        tax_name[0].name + ":",
                        currencyFormat(taxValue),
                        false,
                        false,
                        true
                      );
                  } else {
                    content +=
                      font_reset +
                      getEscTableRow(
                        tax_name[0].name + ":",
                        currencyFormat(taxValue),
                        false,
                        true,
                        true
                      );
                  }
                } else {
                  content +=
                    font_reset +
                    getEscTableRow(
                      tax_name[0].name + ":",
                      currencyFormat(taxValue),
                      false,
                      false,
                      true
                    );
                }
              }
            });

            const totalTendered = transectionDetails?.Data?.Payments.reduce(
              (accumulator, item) => {
                return accumulator + parseFloat(item.tender);
              },
              0
            );

            console.log(
              `transectionDetails?.Data.payments`,
              transectionDetails?.Data.payments
            );

            console.log(`tip amount->`, tip);
            if (tip != undefined && tip > 0) {
              content += getEscTableRow(
                "Tip Amount: ",
                currencyFormat(tip),
                false,
                true,
                true
              );
            }

            // content += "HighTax ("+taxPercent+"%): "+currencyFormat(transectionDetails.Data.Item[0].tax_total)+"\n";
            content +=
              esc_bold_on +
              getEscTableRow(
                "Total (" + transectionDetails?.Data?.Items + " items): ",
                currencyFormat(
                  parseFloat(transectionDetails?.Data.total) + parseFloat(tip)
                ),
                false,
                true,
                true
              );
            content += getEscTableRow(
              "Total Tendered",
              currencyFormat(parseFloat(totalTendered) + parseFloat(tip)),
              false,
              false,
              true
            );
            content += font_reset;

            console.log(`props---`, props);
            console.log(`transectionDetails`, transectionDetails?.Data);
            for (
              var x = 0;
              x < transectionDetails?.Data?.Payments.length;
              x++
            ) {
              let itm = transectionDetails?.Data?.Payments[x];
              content +=
                getEscTableRow(
                  itm.method + ":",
                  currencyFormat(itm?.amount),
                  false,
                  false,
                  true
                ) +
                getEscTableRow(
                  "Tendered:",
                  currencyFormat(itm?.tender),
                  false,
                  false,
                  true
                ) +
                getEscTableRow(
                  "Change:",
                  currencyFormat(itm?.change),
                  false,
                  false,
                  true
                );
            }

            if (
              transectionDetails.Data?.salevoid &&
              transectionDetails.Data?.salevoid.length > 0
            ) {
              content += "\n";
              console.log(
                `transectionDetails.Data?.salevoid`,
                transectionDetails.Data?.salevoid
              );
              for (
                var x = 0;
                x < transectionDetails.Data?.salevoid.length;
                x++
              ) {
                let itm = transectionDetails.Data?.salevoid[x];
                if (itm.void) {
                  content += esc_init + esc_a_c + "VOID TRANSACTION \n";
                } else {
                  content += esc_init + esc_a_c + "Refunded\n\n";
                  content += getEscTableRow(
                    dateFormate(parseInt(itm.processdt)) + " " + itm.method,
                    currencyFormat(itm.amount),
                    false,
                    false,
                    true
                  );
                  content += esc_a_l + "(1 items)";
                }
              }
              content += "\n";
            }

            if (transectionDetails?.Data?.Payments.length > 0) {
              content += "\n";
              const transaction_responce =
                transectionDetails?.Data?.Payments[0].transaction_responce;
              if (transaction_responce != null && transaction_responce != "") {
                const responseObj = JSON.parse(
                  transaction_responce.replace(/'/g, '"')
                ); // replace single quotes with double quotes and parse into object

                content += getEscTableRow(
                  "Reference Number:",
                  responseObj.HostReferenceNumber,
                  false,
                  false,
                  true
                );
                content += getEscTableRow(
                  "Card Type:",
                  responseObj.cardHolderName.trim(),
                  false,
                  false,
                  true
                );
                content += getEscTableRow(
                  "Account:",
                  responseObj.accountNumber,
                  false,
                  false,
                  true
                );
                content += getEscTableRow(
                  "Entry:",
                  "Swipe",
                  false,
                  false,
                  true
                );
                content += getEscTableRow(
                  "Response Code:",
                  "APPROVED",
                  false,
                  false,
                  true
                );
                content += getEscTableRow(
                  "Status:",
                  responseObj.statusCode,
                  false,
                  false,
                  true
                );
                if (tip != undefined && tip > 0) {
                  content += getEscTableRow(
                    "Tip Amount: ",
                    currencyFormat(tip),
                    false,
                    false,
                    true
                  );
                }
                content += getEscTableRow(
                  "Sale:",
                  responseObj.approvedAmount,
                  false,
                  false,
                  true
                );
              }
            }

            if (
              transectionDetails?.Data?.Payments.length > 0 &&
              transectionDetails?.Data?.Payments[0].method == "card" &&
              posSettings?.RECEIPT?.print_signature_line == "1"
            ) {
              content += "\n";
              content += "\n";
              content += "X_________________________________";
              content += "\n";
              content +=
                "Cardmember agrees to pay total \n in accordance with agreement \n governing use of such card.";
            }

            if (
              transectionDetails?.Data?.details?.Data?.customer != "" &&
              posSettings?.RECEIPT?.print_customer_details == 1
            ) {
              const customer = transectionDetails?.Data?.customer;
              console.log(
                `customer`,
                typeof transectionDetails?.Data?.customer
              );
              console.log(`customer`, customer?.name);
              if (
                posSettings?.RECEIPT?.print_customer_name == 1 &&
                customer?.name
              ) {
                content += getEscTableRow(
                  "Customer Name:",
                  customer?.name,
                  false,
                  false,
                  true
                );
              }
              if (
                posSettings?.RECEIPT?.print_customer_mobile == 1 &&
                customer?.mobile
              ) {
                content += getEscTableRow(
                  "Customer Mobile:",
                  customer?.mobile,
                  false,
                  false,
                  true
                );
              }
              if (
                posSettings?.RECEIPT?.print_customer_address == 1 &&
                customer?.address
              ) {
                content += getEscTableRow(
                  "Customer Address:",
                  customer?.address,
                  false,
                  false,
                  true
                );
              }
              content += getEscTableRow(
                "Customer Postcode:",
                customer?.postcode,
                false,
                false,
                true
              );
              if (
                posSettings?.RECEIPT?.print_customer_notes == 1 &&
                customer?.notes
              ) {
                content += getEscTableRow(
                  "Notes:",
                  customer?.notes,
                  false,
                  false,
                  true
                );
              }
            }

            if (posSettings?.RECEIPT?.receipt_footer_text != undefined) {
              content +=
                "\n" +
                esc_bold_on +
                esc_a_c +
                posSettings?.RECEIPT?.receipt_footer_text;
            }
            content += font_reset;

            if (posSettings?.RECEIPT?.promo_qr_code) {
              // content += <QRCode style={{ zIndex: 1 }} value={posSettings?.RECEIPT?.promo_qr_code} />
            }
            return content;
          }

          const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
          const posSettings = JSON.parse(localStorage.getItem("posSetting"));
          let qrtext = posSettings?.RECEIPT?.promo_qr_code;

          var thermaPrinter = preparePrinter("epson");
          thermaPrinter.alignCenter();
          if (
            salesPath != undefined &&
            posSettings?.RECEIPT?.print_receipt_logo == 1
          ) {
            console.log(`print logo`, salesPath);
            await thermaPrinter.printImage(salesPath);
          }
          thermaPrinter.drawLine();
          thermaPrinter.newLine();
          thermaPrinter.println(cmd);
          if (qrtext == "" || qrtext == null) {
            thermaPrinter.cut();
          }

          console.log(`cmd`, cmd);
          console.log(`wpos_config.report_printer`, wpos_config.report_printer);

          window.nodeprinter.printDirect({
            printerName: wpos_config.report_printer,
            type: "RAW",
            data: thermaPrinter.getBuffer(),
            success: function (jobID) {
              if (qrtext != "" && qrtext != null) {
                printQrCode(qrtext, true);
              }
              console.log(`Printed with Job ID: ${jobID}`);
              setPromt(false);
            },
            error: function (err) {
              console.error(err);
            },
          });
        }
      } else {
        console.log(`receipt print`, wosConfig.reciptOfPrint);
        for (let index = 1; index <= wosConfig.reciptOfPrint; index++) {
          console.log("loop" + index);
        }
        handlePrint();
        setPromt(false);
      }
    } else {
      // ShowMsgInfo(
      //   true,
      //   "Printer is not selected",
      //   "Error"
      // )
      handlePrint();
      setPromt(false);
    }
  };

  const printQrCode = async (qrtext, cutpaper) => {
    const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));

    let thermaPrinter = preparePrinter("epson");
    thermaPrinter.newLine();
    thermaPrinter.printQR(qrtext, {
      cellSize: 5, // 1 - 8
      correction: "H", // L(7%), M(15%), Q(25%), H(30%)
      model: 1, // 1 - Model 1
      // 2 - Model 2 (standard)
      // 3 - Micro QR
    });

    if (cutpaper) {
      thermaPrinter.cut();
    }

    window.nodeprinter.printDirect({
      data: thermaPrinter.getBuffer(),
      type: "RAW", // type: RAW, TEXT, PDF, JPEG, .. depends on platform
      printerName: wpos_config.report_printer,
      success: function (jobID) {
        console.log("sent to printer with ID: " + jobID);
      },
      error: function (err) {
        console.log(err);
      },
    });
  };

  function getDetailsofImage(type) {
    if (type !== undefined) {
      const detailsData = props?.image.filter((item) => item.name === type);
      return detailsData;
    }
  }

  function getEscTableRow(
    leftstr,
    rightstr,
    bold,
    underline,
    stretch,
    name = ""
  ) {
    var esc_init = "\x1B" + "\x40"; // initialize printer
    var esc_p = "\x1B" + "\x70" + "\x30"; // open drawer
    var esc_full_cut = "\x1B" + "\x69"; // esc obsolute full (one point left) cut
    var esc_part_cut = "\x1B" + "\x6D"; // esc obsolute partial (three points left) cut
    var gs_full_cut = "\x1D" + "\x56" + "\x30"; // cut paper
    var gs_part_cut = "\x1D" + "\x56" + "\x31"; // partial cut paper
    var esc_a_l = "\x1B" + "\x61" + "\x30"; // align left
    var esc_a_c = "\x1B" + "\x61" + "\x31"; // align center
    var esc_a_r = "\x1B" + "\x61" + "\x32"; // align right
    var esc_double = "\x1B" + "\x21" + "\x31"; // heading
    var font_reset = "\x1B" + "\x21" + "\x02"; // styles off
    var esc_ul_on = "\x1B" + "\x2D" + "\x31"; // underline on
    var esc_ul_off = "\x1B" + "\x2D" + "\x30"; // underline off
    var esc_bold_on = "\x1B" + "\x45" + "\x31"; // emphasis on
    var esc_bold_off = "\x1B" + "\x45" + "\x30"; // emphasis off
    var ltr = true;

    var sheet_width = 48;
    sheet_width = sheet_width ? sheet_width : 48;
    rightstr = rightstr != undefined && rightstr != "" ? rightstr : "-";
    leftstr = leftstr.toString();
    rightstr = rightstr.toString();
    var pad = "";
    // adjust for bytes of escp commands that set the character set
    var llength =
      leftstr.indexOf("\x1B\x74") !== -1
        ? leftstr.length - 3 * (leftstr.match(/\x1B\x74/g) || []).length
        : leftstr.length;
    var rlength =
      rightstr.indexOf("\x1B\x74") !== -1
        ? rightstr.length - 3 * (rightstr.match(/\x1B\x74/g) || []).length
        : rightstr.length;
    if (llength + rlength > sheet_width) {
      // var clip = (llength + rlength) - sheet_width; // get amount to clip
      // leftstr = leftstr.substring(0, (llength - (clip + 3)));
      // pad = "...";
      //no need to add ... we need to show all info lets go to new line
      if (name == "price") {
        var mid = sheet_width - rlength - 1;
        //rightstr = " "+rightstr+"  "+leftstr.substring(mid,llength);
        rightstr =
          " " + rightstr + "  " + "\n" + leftstr.substring(mid, llength);
        leftstr = leftstr.substring(0, mid);
      }
    } else {
      var num = sheet_width - (llength + rlength);
      pad = new Array(num + 1).join(" ");
    }
    var row;
    if (ltr) {
      row =
        leftstr +
        (stretch ? pad : "") +
        (underline ? esc_ul_on : "") +
        rightstr +
        (underline ? esc_ul_off : "") +
        (!stretch ? pad : "") +
        "\n";
    } else {
      row =
        (!stretch ? pad : "") +
        (underline ? esc_ul_on : "") +
        rightstr +
        (underline ? esc_ul_off : "") +
        (stretch ? pad : "") +
        leftstr +
        "\n";
    }
    if (bold) {
      // format row
      row = esc_bold_on + row + esc_bold_off;
    }
    return row;
  }

  function preparePrinter(type) {
    let thermaPrinter;
    if (type == "star") {
      thermaPrinter = new window.ThermalPrinter({
        type: "STAR",
      });
    } else {
      thermaPrinter = new window.ThermalPrinter({
        type: "EPSON",
      });
    }
    return thermaPrinter;
  }

  const ShowMsgInfo = (modal, type, msg) => {
    setOpenErrorMsg(modal);
    setErrorMsg(type);
    setTitle(msg);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClosepopUp = () => {
    setOpenErrorMsg(false);
    setOpenErrorMsg1(false);
    setTimeout(() => {
      setErrorMsg("");
      setTitle("");
    }, 200);
  };

  const OfflinehandleClosepopUp = () => {
    setShow8(false);
    setTimeout(() => {
      setErrorMsg("");
      setTitle("");
    }, 200);
  };

  const handleRefund = (method) => {
    let formData = {
      method,
      processdt: `${Date.now()}`,
      storeId: transectionDetails?.Data?.store_id,
      amount: transectionDetails?.Data?.total,
      itemsId: transectionDetails?.Data?.items
        ? transectionDetails?.Data?.items?.map((e) => {
          return e.id;
        })
        : [],
      ref: transectionDetails?.Data?.ref,
    };
    const id = transectionDetails?.Data?.id
      ? transectionDetails?.Data?.id
      : +transectionDetails?.Data?.temp_id;
    console.log("formData formData", formData);
    console.log("id id", id);
    if (onlineStatus == "true") {
      dispatch(
        editActionFunction(ITEMSALEREFUND, formData, id, ITEMSALELISTUPDATED)
      );
    } else {
      let off_refund_sales = JSON.parse(
        localStorage.getItem("off_refund_sales")
      );
      let updatedSales = props?.saleList.map((i) => {
        if (i.id == id) {
          return {
            ...i,
            salevoid: [
              {
                id,
                formData,
                reason: null,
                sale: id,
                store_id: transectionDetails?.Data?.store_id,
                transaction_type: 2,
                user_id: authUserId(),
                processdt: `${Date.now()}`,
                void: 0,
              },
            ],
            status: 2,
            Status: 2,
          };
        }
        return i;
      });
      if (transectionDetails?.Data?.temp_id) {
        // updatedSales = props?.saleList.unshift(transectionDetails?.Data);
        console.log(
          "transectionDetails?.Data?",
          transectionDetails?.Data?.temp_id
        );
        updatedSales = saleListData.map((sale) => {
          if (sale?.temp_id == id) {
            return {
              ...sale,
              salevoid: [
                {
                  id,
                  formData,
                  reason: null,
                  sale: id,
                  store_id: transectionDetails?.Data?.store_id,
                  transaction_type: 2,
                  user_id: authUserId(),
                  processdt: `${Date.now()}`,
                  void: 0,
                },
              ],
              status: 2,
              Status: 2,
            };
          }
          return sale;
        });
        console.log("modifiedSaleList", updatedSales);
        let off_sales = JSON.parse(localStorage.getItem("off_sale"));
        let updated_off_sales = off_sales.map((sale) => {
          if (sale?.temp_id == transectionDetails?.Data?.temp_id) {
            return {
              ...sale,
              salevoid: [
                {
                  id,
                  formData,
                  reason: null,
                  sale: id,
                  store_id: transectionDetails?.Data?.store_id,
                  transaction_type: 2,
                  user_id: authUserId(),
                  processdt: `${Date.now()}`,
                  void: 0,
                },
              ],
              status: 2,
              Status: 2,
            };
          }
          return sale;
        });
        localStorage.setItem("off_sale", JSON.stringify(updated_off_sales));
        setTransectionDetails({
          Data: {
            ...transectionDetails?.Data,
            salevoid: [
              {
                id,
                formData,
                reason: null,
                sale: id,
                store_id: transectionDetails?.Data?.store_id,
                transaction_type: 2,
                user_id: authUserId(),
                processdt: `${Date.now()}`,
                void: 0,
              },
            ],
            status: 2,
            Status: 2,
          },
        });
        // setSaleListData(modifiedSaleList);
        // let off_sales = JSON.parse(localStorage.getItem("off_sale"));
        // let filtered_off_sales = off_sales.filter((sale) => sale?.temp_id != id);
        // localStorage.setItem("off_sale", filtered_off_sales);
      }
      dispatch(
        confirmedResponseAction({ data: updatedSales }, ITEMSALELIST_GET_LIST)
      );
      if (off_refund_sales != null) {
        localStorage.setItem(
          "off_refund_sales",
          JSON.stringify([
            ...off_refund_sales,
            {
              id,
              formData,
              isOfflineSaleRefund: transectionDetails?.Data?.temp_id
                ? true
                : false,
            },
          ])
        );
      } else {
        localStorage.setItem(
          "off_refund_sales",
          JSON.stringify([
            {
              id,
              formData,
              isOfflineSaleRefund: transectionDetails?.Data?.temp_id
                ? true
                : false,
            },
          ])
        );
      }
      console.log("offline transaction details", transectionDetails);
    }
    setPromt({
      type: "refundPrintConfitmaton",
      title: "Confirmation",
      msg: "Would you like to print a receipt?",
    });
    setShow4(false);
    setShow2(false);
    setMethod("");
  };

  const getPromotionDiscountData = (data) => {
    try {
      return [JSON.parse(data)?.promotion, JSON.parse(data)?.discount]
    } catch (e) {
      return [{}, {}]
    }
  }

  useEffect(() => {
    let filteredUsers = props.userlist.filter((user) => {
      if (user?.permission) {
        if (user?.permission[getDeviceAndStore()[1]]?.pospermission?.allow_suspend_and_recall) {
          return true
        }
      } else if (user?.store_owner == 1) {
        return true
      }
      return false;
    });
    setAuthorizedUsers(filteredUsers)
  }, [props.userlist])

  const handleComplete = (allow) => {
    if (!checkUserPermission("allow_suspend_and_recall") && !allow) {
      setShow7(true)
      setOpenType(RECALL)
    } else {
      let sale_customer;
      let promotionData = getPromotionDiscountData(transectionDetails?.Data?.data)[0]
      let discountData = getPromotionDiscountData(transectionDetails?.Data?.data)[1]

      if (transectionDetails?.Data?.custid) {
        let customers = Localstorage.getItem("customer");
        sale_customer = customers?.find(
          (e) => +e.id == +transectionDetails?.Data?.custid
        );
        if (sale_customer) {
          Localstorage.setItem("sale_customer", sale_customer);
        }
      }
      const list = transectionDetails?.Data.Item.map((e, index) => {
        let code = [];
        if (transectionDetails?.Data?.sale_code) {
          code = transectionDetails?.Data?.sale_code?.filter(
            (it) => +it.storeditemid === +e.storeditemid
          );
        }
        let codeObj = code
          ? code.map((e) => {
            return {
              id: e.code_id,
              code: e.code,
            };
          })
          : [];
        return {
          ...e,
          id: e.storeditemid || e?.id,
          sale_id: transectionDetails?.Data?.id || index,
          saleId: transectionDetails?.Data?.id || index,
          default_qty: e.qty,
          already_add_price: e.unit_original || e?.orgPrice,
          ebteligible: e.allowebt || e?.ebt || false,
          item_rank: e.itemrank || "",
          bottle_deposit:
            e.itemdeposit && e.itemdeposit !== "0.00" ? e.itemdeposit : null,
          manual_item: e.manual_item,
          modifier: e.modifier ? JSON.parse(e.modifier) : e?.itemData?.modifier,
          code: codeObj,
          item_type: e.itemtype || e.itemData?.item_type,
          tax: e.tax_id || e.tax,
          name: e?.name || e?.manualname,
          ref: transectionDetails?.Data?.ref,
          price: e.minprice,
          increaseqty: !isNaN(+e.increaseqty) && e.increaseqty !== "" ? e.increaseqty : 1,
          alreadyAddedPromotion: promotionData[e.storeditemid || e?.id],
          donotdiscount: e?.donotdiscountitem
        };
      });
      Localstorage.setItem("complete_sale_item_ids", list?.map(e => e.id));
      dispatch(completeSale(list, COMPLETEITEMSALELIST));
      setShow(false);
      if (Object.keys(discountData)?.length) {
        dispatch({
          type: ITEMDISCOUNTS,
          payload: discountData
        })
      }
      history.push({
        pathname: "/pos",
        customer_mobile: sale_customer?.mobile
      });
      if (props?.handleClose) {
        props.handleClose();
      }
    }
  };

  const handleRemove = () => {
    dispatch(
      editActionFunction(
        ITEMSALENOTE,
        { status: DECELINEDVALUE },
        transectionDetails?.Data?.id,
        UPDATESALESTATUS
      )
    );
    setTimeout(() => {
      setShow5(false);
    }, 1000);
    history.push("/pos");
  };

  const selectChange = (e) => {
    if (window.navigator.onLine == true) {
      dispatch({
        type: SALESSATUSFILTER,
        actions: e.value,
      });
    } else {
      setShow8(true);
      setErrorMsg("This functionality is not available in offline mode.");
      setTitle("Error");
    }
  };


  const getSaleList = (
    page,
    size,
    search,
    order_by,
    field_name,
    statusFil,
    ref,
    refsearch
  ) => {
    let offline_sales = JSON.parse(localStorage.getItem("off_sale") || "[]");
    let previousList = removeDuplicatesByRef(
      offline_sales.concat(props?.saleList)
    );
    if (isNaN(parseFloat(ref)) && isNaN(parseFloat(statusFil))) {
      if (UpdateArray(previousList)?.length < pageSize * pageOffset) {
        setPageOffset(1)
      }
      if (pageSize < 10){
        setPageSize(10)
      }
      setSaleListData(UpdateArray(previousList));
      setPageCount(UpdateArray(previousList).length)
    } else if (refsearch) {
      let filteredList = UpdateArray(
        previousList.filter(
          (it) => +it?.ref?.split("-")?.pop() === ref || it?.ref?.includes(ref)
        )
      );
      if (filteredList?.length < pageSize * pageOffset) {
        setPageOffset(1)
      }
      if (pageSize < 10){
        setPageSize(10)
      }
      setSaleListData(filteredList);
      setPageCount(filteredList.length)
    } else {
      let filteredList = UpdateArray(
        previousList.filter((it) => +it.status == +statusFil)
      );
      if (filteredList?.length < pageSize * pageOffset) {
        setPageOffset(1)
      }
      if (pageSize < 10){
        setPageSize(10)
      }
      setSaleListData(filteredList);
      setPageCount(filteredList.length)
    }

    // dispatch({ type: LOADING, payload: true });
    // setPageCount(0);
    // let ordering = field_name
    //   ? order_by === "asc"
    //     ? shortField[field_name]
    //     : `-${shortField[field_name]}`
    //   : undefined;
    // dispatch(
    //   getListActionFunction(
    //     ITEMSALE,
    //     {
    //       storeId: getDeviceAndStore()[1],
    //       page,
    //       page_size: size,
    //       search: search,
    //       ordering,
    //       status: statusFil,
    //       ref,
    //     },
    //     ITEMSALELIST_GET_LIST
    //   )
    // );
  };

  const onTableChange = (type, newState) => {
    setPageSize(newState.sizePerPage);
    setPageOffset(newState.page);
    setOrderBy(newState.sortOrder);
    setFieldName(newState.sortField);
    setSearchText(newState.searchText);
    // getSaleList(
    //   newState.page,
    //   newState.sizePerPage,
    //   newState.searchText,
    //   newState.sortOrder,
    //   newState.sortField,
    //   status,
    //   globalReference
    //   // addExactSearch(),
    //   // advanceSearchData
    // );
  };

  function onColumnMatch({
    searchText,
    value,
    column,
    row
  }) {
    // implement your custom match logic on every cell value
    const val = searchText.trim().toLowerCase();

    if (val === '') {
      return true;
    }

    const columnsToCheck = [
      'GID',
      'Ref',
      'devicestore',
      'CustomerName',
      'Mobile',
      'Items',
      'Processdt',
      'Total',
      'StatusDescription',
      'ref'
    ];

    return columnsToCheck.some((column) =>
      String(row[column]).toLowerCase().includes(val)
    );
  }

  const posSettings = JSON.parse(localStorage.getItem("posSetting"));
  const [refFocus, setRefFocus] = useState(false);
  const [cancel, setCancel] = useState(false);
  let displayData = saleListData?.filter(e => e != undefined && e?.ref != undefined);
  const Table = () => {
    return (
      <Card className="custom-card no-checkbox column-visibility-table auto-width pagination-absolute sales-pos-table ">
        <Card.Header className="d-flex flex-column flex-md-row justify-content-md-between align-items-lg-center">
          <h2 className="mb-0">Sales</h2>
          <div
            className="d-flex align-items-sm-center top-search flex-column flex-sm-row"
            style={{ width: "auto" }}
          >
            <Form
              className="navbar-search stock-code-search mb-3 mb-sm-0"
              onSubmit={(e) => e.preventDefault()}
            >
              <Form.Group className="d-flex">
                <InputGroup className="input-group-merge search-bar">
                  <Button
                    variant="purple"
                    onClick={() => handleGlobalReference(undefined, true)}
                    title="Search"
                  >
                    <FontAwesomeIcon color="white" size="sm" icon={faSearch} />
                  </Button>
                  <Form.Control
                    type="text"
                    autoFocus={refFocus}
                    onClick={() => {
                      // setRefFocus(true);
                    }}
                    onBlur={() => {
                      // setRefFocus(false);
                    }}
                    value={globalReference}
                    onChange={handleGlobalReference}
                    placeholder="Global Reference Search"
                  />
                </InputGroup>
                {globalReference != "" && cancel &&
                  <Button
                    variant="purple clear"
                    title="Clear"
                    onClick={() => {
                      setGlobalReference('')
                      setCancel(false);
                      getSaleList(null, null, null, null, null, '', '', true);
                    }}
                  >
                    <Image src={
                      !darkMode
                        ? props?.dynamicImages?.closeWhite
                        : props?.dynamicImages?.crossIcon
                    }
                      alt="cross icon" />
                  </Button>
                }
              </Form.Group>
            </Form>

            <Button
              variant="purpule-light add-btn"
              onClick={() => handleViewOrder(viewOrder ? 0 : "")}
              title={viewOrder ? "View Order" : "View All"}
            >
              <Image src={props?.dynamicImages?.EyeIcon} alt="Add Icon" />
              {viewOrder ? "View Order" : "View All"}
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="p-3 px-0">
          <div>
            <Form.Group className="custom-react-select me-sm-5 mb-3 mb-sm-0 filter-by-status">
              <ReactSelect
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                styles={{
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top:
                      state.hasValue || state.selectProps.inputValue
                        ? -15
                        : "7px",
                    transition: "top 0.1s, font-size 0.1s",

                    transform:
                      (state.hasValue || state.selectProps.inputValue) &&
                      "scale(0.85)",
                    opacity:
                      (state.hasValue || state.selectProps.inputValue) && 1,
                  }),
                }}
                classNamePrefix="react-select"
                options={statusList.map((item) => {
                  return {
                    ...item,
                    value: item.id,
                    label: item.name,
                  };
                })}
                value={statusList
                  .map((item) => {
                    return {
                      ...item,
                      value: item.id,
                      label: item.name,
                    };
                  })
                  .filter((item) => {
                    return item.value === props?.statusFilter;
                  })}
                onChange={(e) => {
                  selectChange(e);
                }}
                placeholder={"Filter by Status"}
              />
            </Form.Group>
            <div className=" with-scroll-on-table">


              <ToolkitProvider
                keyField={"Ref"}
                data={displayData}
                columns={columns}
                exportCSV
                search={{ onColumnMatch }}
              >
                {(props) => (
                  <>
                    <div className="d-flex d-justify-content-between flex-column flex-md-row  align-items-md-center mb-5 mb-md-3 pb-2 pb-md-0 px-3">
                      <div className="search-bar">
                        <SearchBar
                          {...props.searchProps}
                          delay={2000}
                        />
                        {props.searchProps?.searchText?.length > 0 && (
                          <ClearSearchButton
                            className="clear"
                            {...props.searchProps}
                          />
                        )}
                      </div>
                    </div>
                    <div className="pagination-wrapper">
                      <CustomScroll
                        addScrolledClass
                        heightRelativeToParent="calc(100% - 0px)"
                        className={
                          size.width !== undefined &&
                            size.width >= 1 &&
                            size.width <= 991
                            ? "expand-table-row "
                            : ""
                        }
                      >
                        <BootstrapTable
                          bootstrap4
                          headerClasses="table-header"
                          keyField={"Ref"}
                          pagination={paginationFactory(options)}
                          expandRow={
                            size.width !== undefined &&
                              size.width >= 1 &&
                              size.width <= 991
                              ? expandRow
                              : expandRow2
                          }
                          noDataIndication={
                            <div className="empty-data">
                              <Image src={noData} alt="No Data Found Icon" /> No Data
                              Found
                            </div>
                          }
                          {...props.baseProps}
                        />
                      </CustomScroll>
                    </div>
                  </>
                )}
              </ToolkitProvider>


              {/* {totalNumberOfPages > 1 && (
                <div className="go-to-page custom-react-select mt-5">
                  <span className="page-selector">Go to Page</span>
                  <ReactSelect
                    classNamePrefix="react-select"
                    menuPlacement="top"
                    options={selectOptions}
                    value={selectOptions.find(
                      (option) => option.value === pageOffset
                    )}
                    onChange={(selectedOption) => {
                      setPageOffset(selectedOption.value)
                    }}
                  />
                </div>
              )} */}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loader && (
        <CustomModal
          open={loader}
          dialogClassName={`custom-modal confirmation error-popup`}
          title={loaderType}
          content={<h1 className="mb-3"></h1>}
          action={<LoaderSpinner />}
          removeCloseIcon={`d-none`}
        />
      )}
      {!props.onlyShowTransActionsDetails && <Table />}

      {/* Transaction detail popup */}
      <CustomModal
        open={show && transectionDetails?.Data !== ""}
        handleClose={() => handleTransectionDetails()}
        dialogClassName="custom-modal modal-50w transaction-details with-border-bottom"
        title="Transaction Details"
        backdrop="static"
        keyboard={false}
        content={
          <TransactionDetail
            handleClose={() => handleTransectionDetails()}
            setTransectionDetails={setTransectionDetails}
            Details={transectionDetails}
            saveNote={saveNote}
            setSaveNote={setSaveNote}
          />
        }
        action={
          <>
            <div className="d-flex">
              <Button
                variant="outline-blackcustom  px-4 me-2"
                type="button"
                onClick={() => handleTransectionDetails()}
                title="Cancel"
              >
                Cancel
              </Button>
              <Button
                type="button"
                // disabled={!notes}
                variant="purpule-light px-4 "
                onClick={() => PrintFn()}
                title="Print"
              >
                <Image
                  className="me-2"
                  src={props?.dynamicImages?.printIcon}
                  alt="print Icon"
                />
                Print
              </Button>

              <div style={{ display: "none" }}>
                <ComponentToPrint
                  ref={componentRef}
                  details={transectionDetails}
                  payment={props?.payment}
                  taxRules={props?.taxRules}
                />
              </div>
              {checkUserPermission("allow_playback_hide") && (
                <Button
                  type="button"
                  // disabled={!notes}
                  variant={
                    transectionDetails?.Data?.status == COMPLETEVALUE ||
                    transectionDetails?.Data?.status == ORDERVALUE
                      ? "purpule-light px-4"
                      : "purpule-light px-4"
                  }
                  onClick={() => setShow3(true)}
                  title="PlayBack"
                >
                  PlayBack
                </Button>
              )}

              {transectionDetails?.Data?.status == COMPLETEVALUE && (
                <Button
                  type="button"
                  // className={
                  //   posSettings?.SALE?.hide_void_option == 1
                  //     ? "ref-btn"
                  //     : "ref-btn-2"
                  // }
                  // disabled={!notes}
                  variant="purpule-light px-4"
                  onClick={() => {
                    if (!checkUserPermission("refund_transaction_hide")) {
                      setShow7(true);
                      setOpenType("refund");
                    } else {
                      setShow2(true);
                    }
                  }}
                  title="Refund"
                >
                  <Image
                    className="me-2"
                    src={props?.dynamicImages?.refund}
                    alt="refund Icon"
                  />
                  Refund
                </Button>
              )}

              {transectionDetails?.Data?.status == ORDERVALUE && (
                <>
                  <Button
                    type="button"
                    // disabled={!notes}
                    variant="purpule-light px-4 me-2"
                    onClick={() => handleComplete()}
                    title="Complete"
                  >
                    <Image
                      className="me-2"
                      src={props?.dynamicImages?.refund}
                      alt="refund Icon"
                    />
                    Complete
                  </Button>

                  <Button
                    type="button"
                    // disabled={!notes}
                    variant="purpule-light px-4"
                    onClick={() => setShow5(true)}
                    title="Remove"
                  >
                    <Image
                      className="me-2"
                      src={props?.dynamicImages?.deleteIcon}
                      alt="refund Icon"
                    />
                    Remove
                  </Button>
                </>
              )}
              {posSettings?.SALE?.hide_void_option != 1 &&
                transectionDetails?.Data?.status == COMPLETEVALUE && (
                  <Button
                    variant="outline-danger px-4"
                    onClick={() => {
                      // if (!checkUserPermission("void_transaction_hide")) {
                      //   setShow7(true);
                      //   setOpenType("void");
                      // } else {
                      setShow1(true);
                      // }
                    }}
                    title="Void"
                  >
                    Void
                  </Button>
                )}
              <Button
                type="button"
                variant="primary px-4 form-cta-btn m-0"
                onClick={() => setSaveNote(true)}
                title="Save"
              >
                Save
              </Button>
            </div>
          </>
        }
      />
      {/* Void transaction */}
      <CustomModal
        open={show1}
        handleClose={() => setShow1(false)}
        size="sm"
        dialogClassName="custom-modal modal-388w with-border-bottom"
        title="Void transaction"
        content={
          <>
            <Form.Group className="form-floating mt-3 mb-4">
              <FormField
                as="textarea"
                name="Reason"
                value={reason !== null ? reason : ""}
                style={{ height: "357px" }}
                label="Reason"
                placeholder="Reason"
                onChange={(e) => setReason(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="outline-danger px-4 position-absolute form-cta-btn"
              title="Process"
              onClick={() => {
                // if (parseFloat(transectionDetails?.Data?.total || 0) <= 0) {
                //   ShowMsgInfo(true, "Amount must be greater than 0.", "Error");
                //   return;
                // }
                if (!reason || reason === "") {
                  ShowMsgInfo(true, "Reason must not empty.", "Error");
                } else {
                  setPromt({
                    type: "voidConfirmation",
                    title: "Void Transaction",
                    msg: "Are you sure you want to void this transaction?",
                  });
                }
              }}
            >
              Process
            </Button>
          </>
        }
        action={
          <>
            <Button
              variant="outline-blackcustom  px-5"
              type="button"
              onClick={() => setShow1(false)}
              title="Cancel"
            >
              Cancel
            </Button>
          </>
        }
      />
      {/* refund transaction */}
      <CustomModal
        open={show2}
        handleClose={() => setShow2(false)}
        dialogClassName="custom-modal maxwidth_411px  with-border-bottom transaction-details refund-transaction"
        title="Refund transaction"
        backdrop="static"
        keyboard={false}
        content={
          <>
            <div className="custom-data-table">
              <RefundTransaction items={transectionDetails?.Data?.items} />
              <Form.Group className="form-floating mt-3 with-label-up mb-4">
                <FormField
                  name="notes"
                  value={transectionDetails?.Data?.total}
                  label="Refund Amount"
                  placeholder="Refund Amount"
                  // onChange={(e) => setNotes(e.target.value)}
                  disabled
                />
              </Form.Group>
            </div>
          </>
        }
        action={
          <>
            <div className="d-flex">
              <Button
                variant="outline-blackcustom px-4 me-2"
                onClick={() => setShow2(false)}
                title="Cancel"
              >
                Cancel
              </Button>
              <Button
                variant="purpule-light px-3 me-2"
                title="Refund Cash"
                onClick={() => {
                  if (transectionDetails?.Data?.total > 0) {
                    setMethod("Cash");
                    setShow4(true);
                  } else {
                    setShow9(true);
                  }
                }}
              >
                <Image
                  className="me-2"
                  src={props?.dynamicImages?.refundCash}
                  alt="Refund Cash Icon"
                />
                Refund Cash
              </Button>
              {!checkPayment && !sideCardPayment && !debitCardPayment && (
                <Button
                  variant="outline-seagreen px-3 me-0"
                  disabled={buttonDisable}
                  onClick={() => {
                    setMethod("Card");
                    setShow6(true);
                  }}
                  title="Refund on Card"
                >
                  <Image
                    className="me-2"
                    src={props?.dynamicImages?.refundCard}
                    alt="Refund on Card Icon"
                  />
                  Refund on Card
                </Button>
              )}
              {sideCardPayment && (
                <Button
                  variant="outline-seagreen px-3 me-0"
                  onClick={() => {
                    setMethod(SIDECARD);
                    setShow4(true);
                  }}
                  title="Refund on Side Card"
                >
                  <Image
                    className="me-2"
                    src={props?.dynamicImages?.refundCard}
                    alt="Refund on Card Icon"
                  />
                  Side Card
                </Button>
              )}
              {checkPayment && (
                <Button
                  variant="outline-seagreen px-3 me-0"
                  onClick={() => {
                    setMethod(CHECK);
                    setShow4(true);
                  }}
                  title="Refund on Side Card"
                >
                  <Image
                    className="me-2"
                    src={props?.dynamicImages?.refundCard}
                    alt="Refund on Card Icon"
                  />
                  Check
                </Button>
              )}
              {debitCardPayment && (
                <Button
                  variant="outline-seagreen px-3 me-0"
                  onClick={() => {
                    setMethod(DEBIT);
                    setShow6(true);
                  }}
                  title="Refund on Card"
                >
                  <Image
                    className="me-2"
                    src={props?.dynamicImages?.refundCard}
                    alt="Refund on Card Icon"
                  />
                  Debit
                </Button>
              )}
            </div>
          </>
        }
      />
      {/* amount validation */}
      <CustomModal
        open={show9}
        handleClose={() => setShow3(false)}
        dialogClassName="custom-modal confirmation"
        title="Enter Amount"
        content={
          <>
            <h1 className="justify-content-center">
              Amount must be larger than 0.
            </h1>
          </>
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5 m-0"
              onClick={() => setShow9(false)}
              title="Ok"
            >
              Ok
            </Button>
          </>
        }
      />
      {/* PlayBack */}
      <CustomModal
        open={show3}
        handleClose={() => setShow3(false)}
        dialogClassName="custom-modal confirmation"
        title="Error"
        content={
          <>
            <h1 className="justify-content-center">Recording not found.</h1>
          </>
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5 m-0"
              onClick={() => setShow3(false)}
              title="Ok"
            >
              Ok
            </Button>
            {/* <Button
              variant="danger px-4"
              onClick={(e) => handleRefund("cash")}
            // disabled={ReturnDisabled("Return")}
            >
              Y
            </Button> */}
          </>
        }
      />

      {/* Refund trasaction confirmation */}
      <CustomModal
        open={show4}
        handleClose={() => {
          setMethod("");
          setShow4(false);
        }}
        dialogClassName="custom-modal confirmation"
        title="Confirmation"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.warningRed}
                alt="warning Icon"
              />
              Are you sure you want to refund this transaction?
            </h1>
          </>
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5 me-2"
              onClick={() => {
                setMethod("");
                setShow4(false);
              }}
              title="No"
            >
              No
            </Button>
            <Button
              variant="danger px-4"
              onClick={(e) => {
                handleRefund(method);
              }}
              title="Yes Refund"
              // disabled={ReturnDisabled("Return")}
            >
              Yes Refund
            </Button>
          </>
        }
      />

      {/* Refund Card trasaction confirmation */}
      <CustomModal
        open={show6}
        handleClose={() => {
          setMethod("");
          setShow6(false);
        }}
        dialogClassName="custom-modal confirmation"
        title="Confirmation"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.warningRed}
                alt="warning Icon"
              />
              Are you sure you want to refund this transaction?
            </h1>
          </>
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5 me-2"
              onClick={() => {
                setMethod("");
                setShow6(false);
              }}
              title="No"
            >
              No
            </Button>
            <Button
              variant="danger px-4"
              onClick={(e) => {
                if (isPayfacPayment) {
                  handlePayFacRefundFn();
                } else {
                  refundVoidFn("refund");
                }
              }}
              title="Yes Refund"
              // disabled={ReturnDisabled("Return")}
            >
              Yes Refund
            </Button>
          </>
        }
      />

      {/* Refund trasaction confirmation */}
      <CustomModal
        open={show5}
        handleClose={() => setShow5(false)}
        dialogClassName="custom-modal confirmation"
        title="Confirmation"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.warningRed}
                alt="warning Icon"
              />
              Are you sure you want to delete this transaction?
            </h1>
          </>
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5 me-2"
              onClick={() => setShow5(false)}
              title="No"
            >
              No
            </Button>
            <Button
              variant="danger px-4"
              onClick={(e) => handleRemove()}
              title="Yes Remove"
              // disabled={ReturnDisabled("Return")}
            >
              Yes Remove
            </Button>
          </>
        }
      />

      {/* ask auth */}

      <CustomModal
        open={show7}
        handleClose={() => setShow7(false)}
        dialogClassName="custom-modal confirmation with-border-bottom authorization"
        title="Authorization"
        content={
          <Auth setAuthSuccess={setAuthSuccess} userlist={authorizedUsers} />
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5"
              onClick={() => setShow7(false)}
              title="Cancel"
            >
              Cancel
            </Button>
          </>
        }
      />

      {/* success Popup*/}
      <CustomModal
        open={promt}
        handleClose={() => setPromt(false)}
        dialogClassName={`custom-modal confirmation error-popup`}
        title={promt.title}
        content={<h1 className="mb-3">{promt.msg}</h1>}
        action={
          <>
            <Button
              type="button"
              variant="primary px-5"
              title="Yes"
              onClick={() => {
                if (promt.type === "voidConfirmation") {
                  handleVoid(
                    transectionDetails?.Data?.id ||
                      transectionDetails?.Data?.temp_id,
                    transectionDetails?.Data?.processdt,
                    transectionDetails?.Data?.store_id
                  );
                } else if (promt.type === "refundPrintConfitmaton") {
                  PrintFn();
                  setTimeout(() => {
                    setPromt(false);
                  }, 800);
                }
              }}
            >
              Yes
            </Button>

            <Button
              type="button"
              variant="danger px-5"
              onClick={() => setPromt(false)}
              title="No"
            >
              No
            </Button>
          </>
        }
      />

      {/* Warning Popup*/}
      <CustomModal
        open={openErrorMsg}
        handleClose={handleClosepopUp}
        dialogClassName={`custom-modal confirmation error-popup`}
        title={title}
        content={<h1 className="mb-3">{errorMsg}</h1>}
        action={
          <>
            <Button
              type="button"
              variant="light px-5"
              onClick={handleClosepopUp}
              title="Ok"
            >
              OK
            </Button>
          </>
        }
      />

      {/*Pax Warning Popup*/}
      <CustomModal
        open={openErrorMsg1}
        backdrop={false} // "static"
        handleClose={handleClosepopUp}
        dialogClassName={`custom-modal confirmation error-popup align-btn`}
        title={title}
        content={
          <>
            <div className="text-center">
              {showIcon && (
                <Image src={Cancel} alt="error icon" className="mb-3" />
              )}
            </div>
            <h1
              className="mb-3"
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              {errorMsg}
            </h1>
            {title === "Pax Error" && (
              <>
                <p style={{ margin: 0 }}>Possible Fixes Include:</p>
                <ol style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li>1) Completely close and re-open the POS application</li>
                  <li>2) Restart the Pinpad, then restart this computer</li>
                  <li>3) Call the Technical Support line ( 844 ) 919-2017</li>
                </ol>
              </>
            )}
          </>
        }
        action={
          <>
            <Button
              type="button"
              variant="light px-5"
              onClick={handleClosepopUp}
            >
              OK
            </Button>
          </>
        }
      />

      {/* Offline Warning Popup */}
      <CustomModal
        open={show8}
        handleClose={OfflinehandleClosepopUp}
        dialogClassName={`custom-modal confirmation error-popup`}
        title={title}
        content={<h1 className="mb-3">{errorMsg}</h1>}
        action={
          <>
            <Button
              type="button"
              variant="light px-5 bg-danger text-light"
              onClick={OfflinehandleClosepopUp}
              title="Ok"
            >
              OK
            </Button>
          </>
        }
      />
    </>
  );
};

const mapStateToprops = (state) => {
  return {
    storeId: state.auth.storeId,
    dynamicImages: state.setting.dynamicImages,
    msg: state.inventory.msg,
    loading: state.saleItem.loading,
    saleList: state.saleItem.saleList,
    saleListCount: state.saleItem.saleListCount,
    payment: state.saleItem.payment,
    success: state.saleItem.success,
    device: state.inventory.devices,
    store: state.inventory.stores,
    userlist: state.inventory.userList,
    statusFilter: state.setting.salesStatusFilter,
    saleItemList: state.inventory.saleItemList,
    image: state.setting.images,
    graphReport: state.saleItem.graphData,
    taxRules: state.inventory.taxRule,
    items: state.inventory.itemlist,
    darkMode: state.setting.darkmode,
  };
};
export default connect(mapStateToprops)(SalesTables);
