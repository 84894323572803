import React, { useEffect, useState } from "react";
import {
  ITEMUPDATELABELPRINT,
  PRINTLABEL_CHANNEL,
  PRINTLABEL_EVENT,
} from "../app/helpers/constant";
import { usePusher } from "../context/PusherContext";
import CustomModal from "./components/Modals";
import { Button } from "@themesberg/react-bootstrap";
import { getDeviceAndStore, getSettingData } from "../axios/authHeader";
import { useDispatch, connect } from "react-redux";
import { xml2json, json2xml } from "xml-js";
import {
  confirmedResponseAction,
  getListActionFunction,
  getSingleAction,
} from "../app/actions/getListAction";
import PropTypes from 'prop-types';

const PrintLabel = (props) => {
  // online and messanging
  const pusher = usePusher();
  const dispatch = useDispatch();
  const [messagePopup, setMessagePopup] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [posSideSetting, ] = useState(
    getSettingData("wpos_config")
  );
  const [noOfLabels, ] = useState(1);

  useEffect(() => {
    setTemplateList(props?.labeltemplates);
  }, [props?.labeltemplates]);

  useEffect(() => {
    setItemList(props?.itemlist);
  }, [props?.itemlist]);

  useEffect(() => {
    if (props?.labelPrintItem != undefined) {
      const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
      if (
        wpos_config &&
        wpos_config?.hasOwnProperty("label_printer") &&
        wpos_config.label_printer != undefined &&
        wpos_config.label_printer != ""
      ) {
        if (Object.keys(props?.labelPrintItem).length > 0) {
          printLabel(wpos_config.label_printer, props?.labelPrintItem);
        }
      }
    }
  }, [props?.labelPrintItem]);

  useEffect(() => {
    function childEventCallback(data) {
      console.log(`data`, data);
      dispatch(
        getSingleAction(
          ITEMUPDATELABELPRINT,
          data?.data?.itemid,
          ITEMUPDATELABELPRINT,
          data?.data?.itemid,
          data?.data?.itemdata
        )
      );
      // const wpos_config = JSON.parse(localStorage.getItem("wpos_config"));
      // const item = itemList.find(itm => itm.id === data?.data?.itemid);
      // if(wpos_config && wpos_config?.hasOwnProperty('label_printer') && wpos_config.label_printer != undefined && wpos_config.label_printer != ""){
      //     printLabel(wpos_config.label_printer, item);
      // }
    }
    const channel = pusher.subscribe(PRINTLABEL_CHANNEL);
    channel.bind(PRINTLABEL_EVENT, childEventCallback);
    return () => {
      channel.unbind(PRINTLABEL_EVENT, childEventCallback);
    };
  }, [pusher, itemList, props]);

  const printLabel = async (label_printer, item) => {
    const Dymo = require("dymojs"),
      dymo = new Dymo();

    const template = templateList.filter(
      (item) => item.id === posSideSetting.template
    );

    // Parse XML to JSON
    const jsonData = xml2json(template[0]?.content, {
      compact: true,
      spaces: 4,
    });
    const parsedData = JSON.parse(jsonData);

    // Find the <Name>Price</Name> element and replace the value
    const objectInfo = parsedData.DieCutLabel.ObjectInfo;
    const priceObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "Price"
    );
    if (priceObject) {
      priceObject.TextObject.StyledText.Element.String._text =
        "$" + item?.modifier[0]?.price;
    }

    const nameObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "Name1"
    );
    if (nameObject) {
      nameObject.TextObject.StyledText.Element.String._text =
        item?.item?.name ?? "";
    }

    const name2Object = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "Name2"
    );
    if (name2Object) {
      name2Object.TextObject.StyledText.Element.String._text =
        item?.item?.name2 ?? "";
    }

    const supplierObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "SupplierName"
    );
    if (supplierObject) {
      supplierObject.TextObject.StyledText.Element.String._text =
        item?.item?.supplier_name ?? "";
    }

    const promoObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "PromotionName"
    );
    if (promoObject) {
      promoObject.TextObject.StyledText.Element.String._text =
        item?.item?.PromotionName ?? "";
    }

    const promoPriceObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "PromotionPrice"
    );
    if (promoPriceObject) {
      promoPriceObject.TextObject.StyledText.Element.String._text =
        item?.item?.PromotionPrice ?? "";
    }

    const promoQtyObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "PromotionQty"
    );
    if (promoQtyObject) {
      promoQtyObject.TextObject.StyledText.Element.String._text =
        item?.item?.PromotionQty ?? "";
    }

    const codeObject = objectInfo.find(
      (obj) => obj.TextObject?.Name?._text === "code"
    );
    if (codeObject) {
      codeObject.TextObject.StyledText.Element.String._text =
        item?.item?.code ?? "";
    }

    // Convert JSON back to XML
    const modifiedXmlData = json2xml(parsedData, {
      compact: true,
      ignoreComment: true,
      spaces: 4,
    });

    for (let i = 0; i < noOfLabels; i++) {
      await dymo.print(label_printer, modifiedXmlData);
    }
    dispatch(confirmedResponseAction("", ITEMUPDATELABELPRINT));
  };

  return (
    <>
      <CustomModal
        open={messagePopup}
        handleClose={() => setMessagePopup(false)}
        dialogClassName="custom-modal confirmation"
        title="Message"
        content={<h5>{messagePopup}</h5>}
        action={
          <>
            <Button
              variant="outline-blackcustom px-5 me-2"
              onClick={() => setMessagePopup(false)}
            >
              Ok
            </Button>
          </>
        }
      />
    </>
  );
};
PrintLabel.propTypes = {
  labeltemplates: PropTypes.array, 
  itemlist: PropTypes.array, 
  labelPrintItem: PropTypes.object,
};
const mapStateToprops = (state) => {
  return {
    itemlist: state.inventory.itemlist,
    labeltemplates: state.saleItem.labeltemplates,
    labelPrintItem: state.inventory.labelPrintItem,
  };
};
export default connect(mapStateToprops)(PrintLabel);
