import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import FormField from "../../../pages/components/formField";
import CustomNumpad from "../../components/customNumpad";
import Categories from "./categories/categories";
import SubGroup from "./sub-group/sub-group";
import { connect, useDispatch } from "react-redux";
import CustomModal from "../../components/Modals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import shortCut from "../../../assets/images/shortcut.png";
import CustomerForm from "./customer/customerForm";
import CustomerDetail from "./customer/customerDetail";
import { PusherUpdateFunction } from "../../../app/helpers/custom";
import Localstorage from "../../../app/storage/Localstorage";
import {
  CUSTOMER_GET_LIST,
  REEDEM_REWARDS,
  SALE_CUSTOMER,
} from "../../../app/helpers/constant";
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';

const Shortcut = (props) => {
  const [darkMode,] = useState(props?.darkMode);
  const [isSlideInOpen, setSlideInOpen] = useState(false);
  const toggleSlideIn = () => {
    if (window.innerWidth <= 767) { // Check the screen width
      setSlideInOpen(!isSlideInOpen);
    }
  };
  const closeSlideIn = () => {
    setSlideInOpen(false);
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const [showDataType, setShowDataType] = useState(
    props?.posSideSetting?.alwayShowShortcut
      ? { type: "shortcutkey", id: "" }
      : false
  );
  const [customerNote, setCustomerNote] = useState("");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [directionNumnerTyping, setDirectionNumberTyping] =
    useState("ArrowRight");
  const [showCustomerNumpad, setShowCustomerNumpad] = useState(false);
  const [custMobileNumber, setCustMobileNumber] = useState([
    Localstorage.getItem("sale_customer")?.mobile || "",
  ]);
  const [custList, setCustList] = useState([]);
  const [selectCust, setSelectCust] = useState({});
  const [select, setSelect] = useState(false);
  const [customerPopup, setCustomerPopup] = useState(false);
  const [selectedIndex1, setSelectedIndex1] = useState(0); // Initialize selectedIndex to -1
  const [inputFocus, setInputFocus] = useState(false);
  const [openCustomerDetailPopUp, setOpenCustomerDetailPopUp] = useState(false);
  const dragOrDrop =
    props?.PosSetting?.enable_shortcut_menu_draggable === "1" ? false : true;

  useEffect(() => {
    if (location?.customer_mobile) {
      setCustMobileNumber(location?.customer_mobile || "");
    }
  }, [location?.customer_mobile]);

  useEffect(() => {
    setShowDataType(props?.shortCut)
  }, [props?.shortCut])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.storageArea !== localStorage) return;
      if (
        event.key === "customerScreenMobileNumber" &&
        event?.newValue?.length
      ) {
        let num = localStorage.getItem("customerScreenMobileNumber");
        localStorage.removeItem("customerScreenMobileNumber");
        setCustMobileNumber([num]);
        let customer = filterCustomer([num]);
        if (customer) {
          setSelectCust(customer);
          setShow2(true);
          setShow(false);
        } else {
          setShow(true);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    let sale_customer = Localstorage.getItem("sale_customer");
    if (sale_customer) {
      let data = props?.custlist?.find((e) => +e.id == +selectCust?.id);
      if (data) {
        let finalData = { ...sale_customer, ...data, points: data?.points };
        localStorage.setItem("sale_customer", JSON.stringify(finalData || {}));
      }
    }
  }, [props?.custlist]);

  const filterCustomer = (num) => {
    let found = false;
    if (num?.length > 0 && num[0].length > 3) {
      let customerList = Localstorage.getItem("customer");
      let customer = customerList?.filter(
        (el) =>
          el?.mobile?.includes(num[0]) ||
          el?.name?.toLowerCase().includes(num[0]?.toLowerCase())
      );
      setCustList(customer?.slice(0, 10));
      found = customer?.length ? customer[0] : false;
    }
    setCustomerPopup(num.length > 0 && num[0].length > 0 ? true : false);
    return found;
  };

  useEffect(() => {
    show1 && setOpenCustomerDetailPopUp(true);
  }, [show1]);

  useEffect(() => {
    setShow1(false);
    if (props?.updated != 0 && openCustomerDetailPopUp) {
      setShow2(true);
      setOpenCustomerDetailPopUp(false);
    }
    setSelectCust(props?.updated);
  }, [props?.updated]);

  useEffect(() => {
    if (props?.added != 0 && openCustomerDetailPopUp) {
      setShow2(true);
      setOpenCustomerDetailPopUp(false);
    }
    setSelectCust(props?.added);
  }, [props?.added]);

  // useEffect(() => {
  //   closePopupModel();
  // }, [props.custlist]);

  // useEffect(() => {
  //   localStorage.setItem("sale_customer", null);
  //   dispatch({
  //     type: SALE_CUSTOMER,
  //     payload: {},
  //   });
  // }, []);

  const checkAndFilterCustomer = (num) => {
    filterCustomer(num);
    if (!num[0]?.length) {
      localStorage.setItem("sale_customer", null);
      dispatch({
        type: SALE_CUSTOMER,
        payload: {},
      });
      dispatch({
        type: REEDEM_REWARDS,
        payload: {},
      });
    }
  };

  useEffect(() => {
    if (props?.allDataRemove) {
      setCustMobileNumber([""]);
      setSelectCust({});
      dispatch({
        type: REEDEM_REWARDS,
        payload: {},
      });
    }
  }, [props?.allDataRemove]);

  const setCustMobileNumberFn = (mobile) => {
    setCustMobileNumber(mobile);
    props.custMobile(mobile);
    checkAndFilterCustomer(mobile);
  };

  const handleLookup = () => {
    if (custMobileNumber.length && custMobileNumber[0].length === 10) {
      let customer = filterCustomer(custMobileNumber);
      if (customer) {
        setSelectCust(customer);
        setShow2(true);
        setShow(false);
      } else {
        setShow(true);
      }
    } else {
      setShow(true);
    }
  }

  const handleDialogChange = () => {
    if (custMobileNumber.length && custMobileNumber[0].length > 3) {
      let customer = filterCustomer(custMobileNumber);
      if (customer) {
        setSelectCust(customer);
        setShow2(true);
        setShow(false);
      } else {
        setShow(true);
      }
    } else {
      setShow(true);
    }
  };

  const getCustMobileNumber = (data) => {
    if (data.type === "CustomerKeypad") {
      if (data.value === "Clear") {
        setCustMobileNumber("");
        checkAndFilterCustomer([""]);
      } else if (data.value === "ArrowRight" || data.value === "ArrowLeft") {
        let elem = document.getElementById("custMobile");
        let val = data.value === "ArrowLeft" ? 0 : elem.value.length;
        setDirectionNumberTyping(data.value);
        elem.setSelectionRange(val, val);
        elem.focus();
      } else if (data.value === "") {
        handleDialogChange();
      } else {
        let addVal =
          directionNumnerTyping === "ArrowRight"
            ? custMobileNumber + data.value
            : data.value + custMobileNumber;
        if (select) {
          setCustMobileNumber([data.value]);
          checkAndFilterCustomer([data.value]);
        } else {
          setCustMobileNumber([addVal]);
          checkAndFilterCustomer([addVal]);
        }
        setSelect(false);
      }
    }
  };

  const handleSelectCustomer = (data) => {
    setSelectCust(data);
    setShow2(data);
    setShowCustomerNumpad(false);
  };
  const OpenPopUpForaddCustomer = (type) => {
    if (type === "edit") {
      setShow1("edit");
      setShow2(false);
    } else {
      setShowCustomerNumpad(false);

      customerPopup && !select && setShow(true);
    }
  };

  const closePopupModel = (type) => {
    setShow1(false);
    setShow(false);
    setShowCustomerNumpad(false);
    setCustList([]);
  };

  useEffect(() => {
    if (show2) {
      saveSelectCust();
    }
  }, [selectCust, show2]);

  const saveSelectCust = () => {
    let sale_customer_1 = Localstorage.getItem("sale_customer");
    sale_customer_1 = sale_customer_1 || {};
    let sale_customer = { ...selectCust, ...sale_customer_1 };
    if (
      sale_customer &&
      typeof sale_customer === "object" &&
      sale_customer.mobile
    ) {
      localStorage.setItem("sale_customer", JSON.stringify(sale_customer));
      setCustMobileNumber([sale_customer.mobile]);
      props.custMobile([sale_customer.mobile]);
      localStorage.setItem(
        "custMobile_reprint",
        JSON.stringify(sale_customer.mobile)
      );
      dispatch({
        type: SALE_CUSTOMER,
        payload: sale_customer,
      });
    }
    setCustList([]);
  };

  const handleOkCustomer = () => {
    saveSelectCust();
    setShow2(false);
    setShow3(false);
    setShow1(false);
    let customerList = Localstorage.getItem("customer") || [];
    let sale_customer = Localstorage.getItem("sale_customer");
    let customer_orginal_points = customerList.find(
      (e) => e.id == sale_customer.id
    ).points;
    sale_customer = {
      ...sale_customer,
      points: customer_orginal_points,
      notes: customerNote,
    };
    customerList = PusherUpdateFunction("put", customerList, sale_customer);
    dispatch({
      type: CUSTOMER_GET_LIST,
      payload: { data: { data: customerList } },
    });
  };

  const handleKeyPress = (e) => {
    if (inputFocus) {
      if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectedIndex1((prevIndex) =>
          prevIndex === 0 ? custList.length - 1 : prevIndex - 1
        );
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectedIndex1((prevIndex) =>
          prevIndex === custList.length - 1 ? 0 : prevIndex + 1
        );
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (selectedIndex1 !== -1) {
          let customer = filterCustomer(custMobileNumber);
          handleSelectCustomer(custList[selectedIndex1] ? custList[selectedIndex1] : customer);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedIndex1 > custList.length - 1) {
      setSelectedIndex1(0);
    }
  }, [selectedIndex1]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedIndex1, inputFocus, custList]);

  return (
    <>
      <Button
        type="button"
        title="Shortcuts"
        onClick={toggleSlideIn}
        variant="primary d-block d-md-none"
      >
        {/* {isSlideInOpen ? 'Close Shortcuts' : 'Open Shortcuts'} */}
        <Image src={shortCut} alt="shortcut icon" />
      </Button>
      <Form.Group className="navbar-search input-search mb-3">
        <InputGroup className="input-group-merge search-bar">
          {/* <CustomScroll
            addScrolledClass
            heightRelativeToParent="calc(100% - 0px)"
          > */}
          <div className="scroll-auto-parent">
            <ListGroup className="scroll-auto" as="ul">
              {custMobileNumber !== undefined &&
                custMobileNumber?.length > 0 &&
                custMobileNumber[0]?.length > 3 &&
                custList &&
                custList?.length
                ? custList.map((data, idx) => {
                  return (
                    <ListGroup.Item
                      as="li"
                      key={idx}
                      className="list-grp"
                      onClick={() => handleSelectCustomer(data)}
                      active={idx === selectedIndex1}
                    >
                      <p>{`${data.mobile}${data.name !== null
                        ? (data?.name?.length ? "-" : "") + data.name
                        : ""
                        }${data.email !== null
                          ? (data?.email?.length ? "-" : "") + data.email
                          : ""
                        }`}</p>
                    </ListGroup.Item>
                  );
                })
                : ""}
            </ListGroup>
          </div>
          {/* </CustomScroll> */}
          <Button
            onClick={handleLookup}
            className="btn-inside-field"
            variant="purple"
            title="Lookup"
          >
            <FontAwesomeIcon
              color="white"
              size="sm"
              icon={faSearch}
              className="me-2"
            />
            Lookup
          </Button>


          <div className="custom-numpad">
            <FormField
              type="text"
              id="custMobile"
              style={{ width: "100%" }}
              value={custMobileNumber}
              onChange={(e) => setCustMobileNumberFn([e.target.value])}
              onClick={(e) => {
                if (
                  JSON.parse(localStorage.getItem("wpos_config") || "{}")
                    ?.screenKeypad
                ) {
                  setShowCustomerNumpad(true);
                }
                e.target.select();
                setSelect(true);
                setInputFocus(true);
              }}
              onBlur={() => {
                setInputFocus(false);
              }}
              placeholder="Customer Mobile Number"
              autoComplete="off"
            />
            {showCustomerNumpad && (
              <CustomNumpad
                type="CustomerKeypad"
                setValue={getCustMobileNumber}
                hideNumpad={() => OpenPopUpForaddCustomer("")}
              />
            )}
          </div>
        </InputGroup>
      </Form.Group>
      <div className={`shortcut-overlay ${isSlideInOpen ? 'visible' : ''}`} onClick={closeSlideIn}></div>
      <div className={`shortcut-container ${isSlideInOpen ? 'open' : ''}`}>
        <Button
          type="button"
          onClick={closeSlideIn}
          variant="link d-block d-md-none"
        >
          <Image src={
            !darkMode
              ? props?.dynamicImages?.crossIcon
              : props?.dynamicImages?.closeWhite
          } alt="cross icon" />

        </Button>

        <Card className="custom-card shortcut-wrapper">
          <Card.Header
            style={{ borderBottom: "none" }}
            className="d-flex justify-content-between align-items-center"
          >
            <h2 className={showDataType === false ? "ms-0" : "ms-4"}>
              {showDataType === false ? "Shortcuts" : "Categories"}
            </h2>
            {/* <div className="d-flex align-items-center top-search">
            <Form className="navbar-search stock-search">
              <Form.Group>
                <InputGroup className="input-group-merge search-bar">
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form>
          </div> */}
          </Card.Header>
          <Card.Body className="p-3 pt-1">
            <div className="scroll-auto shortcut">
              {showDataType === false ? (
                props.loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <SubGroup
                    setCurrentId={setShowDataType}
                    itemList={props?.itemList}
                  />
                )
              ) : (
                <Categories
                  ListType={showDataType}
                  setShowDataType={setShowDataType}
                  dragOrDrop={dragOrDrop}
                  itemList={props?.itemList}
                />
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
      <CustomModal
        open={show && !show2 && !show1}
        handleClose={() => setShow(false)}
        dialogClassName="custom-modal confirmation customer-not-found"
        title="Customer Not Found!"
        content={
          <>
            <h1>
              <Image
                className="me-3"
                src={props?.dynamicImages?.warning}
                alt="warning Icon"
              />
              <p>
                The number entered is not in the system. Please create a new
                customer to continue.
              </p>
            </h1>
          </>
        }
        action={
          <>
            <Button
              type="button"
              onClick={() => {
                setShow(false);
                setCustMobileNumber("");
              }}
              variant="outline-blackcustom px-5 cust-btn"
              title="Cancel"
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                setShow1(true);
                setShow(false);
              }}
              variant="primary px-5 ms-2 new-btn"
              title="Create new Customer"
            >
              Create new Customer
            </Button>
          </>
        }
      />

      {/* New Customer Add Form  */}
      <CustomModal
        open={show1}
        handleClose={closePopupModel}
        backdrop={"static"}
        dialogClassName="custom-modal with-border-bottom"
        title={show1 === "edit" ? "Edit Customer" : "Add New Customer"}
        size="md"
        content={
          <CustomerForm
            custDetails={
              show1 === "edit"
                ? Object.entries(selectCust).length > 1
                  ? selectCust
                  : custMobileNumber[0]
                : custMobileNumber[0]
            }
            ReponseAdded={closePopupModel}
          />
        }
        action={
          <>
            <Button
              type="button"
              onClick={closePopupModel}
              variant="outline-blackcustom px-3"
              title="Cancel"
            >
              Cancel
            </Button>
          </>
        }
      />
      {/* Customer Details when customer search by mobile number */}
      <CustomModal
        open={show2 || show3}
        handleClose={() => {
          setShow2(false);
          setShow3(false);
        }}
        backdrop={"static"}
        dialogClassName="custom-modal with-border-bottom customer-info cust-btns"
        title="Customer Info"
        size="lg"
        content={
          <CustomerDetail
            handleClose={() => {
              setShow2(false);
              setShow3(false);
            }}
            custDetails={selectCust}
            setNotes={setCustomerNote}
            notes={customerNote}
            ReponseAdded={() => handleOkCustomer()}
          />
        }
        action={
          <>
            <Button
              type="button"
              onClick={() => handleOkCustomer()}
              variant="outline-blackcustom px-3 me-3 m-0"
              title="Ok"
            >
              Ok
            </Button>
            <Button
              type="button"
              onClick={() => OpenPopUpForaddCustomer("edit")}
              variant="primary px-3 m-0"
              title="Edit"
            >
              Edit
            </Button>
          </>
        }
      />
    </>
  );
};

Shortcut.propTypes = {
  posSideSetting: PropTypes.shape({
    alwayShowShortcut: PropTypes.bool,
  }),
  PosSetting: PropTypes.shape({
    enable_shortcut_menu_draggable: PropTypes.string,
  }),
  custlist: PropTypes.array,
  updated: PropTypes.number,
  allDataRemove: PropTypes.bool,
  loading: PropTypes.bool,
  itemList: PropTypes.array,
  dynamicImages: PropTypes.shape({
    warning: PropTypes.string,
  }),
  custMobile: PropTypes.func,
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    loading: state.inventory.loading,
    custlist: state.inventory.customerList,
    added: state.inventory.added,
    updated: state.inventory.updated,
    allDataRemove: state.saleItem.allDataRemove,
    shortCut: state.setting.shortCut,
    darkMode: state.setting.darkmode,
  };
};
export default connect(mapStateToprops)(Shortcut);
