import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Image,
  ProgressBar,
} from "@themesberg/react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { SALE_CUSTOMER } from "app/helpers/constant";
import PropTypes from 'prop-types';
const Initializing = (props) => {
  const dispatch = useDispatch();
  // remove customer when initializing
  useEffect(() => {
    localStorage.setItem("sale_customer", null);
    dispatch({
      type: SALE_CUSTOMER,
      payload: {},
    });
  }, []);

  const { now, min, max, msg } = props;
  const [current , setCurrent] = useState(now);
  const debounceUpdate = (value) => {
    clearTimeout(debounceUpdate.timer);
    debounceUpdate.timer = setTimeout(() => {
      if(current <= now){
        setCurrent(value);
      }
    }, 200); // Adjust the debounce delay as needed
  };

  useEffect(() => {
    // Update 'now' with debouncing when props.now changes
    debounceUpdate(now);
  }, [now]);

  
  const [darkMode, ] = useState(props?.darkMode);
  return (
    <main>
      <section className="d-flex align-items-center vh-100 login">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light px-4 pt-5 initial-box  w-100">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <Image
                    width="300"
                    className="logo mb-5"
                    src={
                      !darkMode
                        ? props?.dynamicImages?.logo1
                        : props?.dynamicImages?.logoWhite
                    }
                    alt="Logo"
                  />
                </div>

                <div className="modal-title text-center mt-4 mb-3">
                  <h4>Loading online resources</h4>
                </div>
                <ProgressBar
                  animated
                  min={min}
                  max={max}
                  now={current}
                  className="custom-progress-bar mt-4"
                />
                <div className="text-center mt-5">
                  <h6>Updating {msg}...</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

Initializing.propTypes = {
  now: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  msg: PropTypes.string.isRequired,
  dynamicImages: PropTypes.object,
  darkMode: PropTypes.bool,
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    darkMode: state.setting.darkmode,
  };
};

export default connect(mapStateToprops)(Initializing);
