import AuthService from "../services/authServices";
import LocalStorage from "../storage/Localstorage";
import history from "../../history/history";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import {
  DeviceUUId,
  authHeader,
  authUserId,
  getDeviceAndStore,
  setAuthLocalStorage,
} from "../../axios/authHeader";
import Sessionstorage from "../storage/Sessionstorage";
import { getStartTimeInReport } from "../helpers/custom";
import getListService from "../services/getListService";
import { INITIALIZED_POPUP, REMOVESALELIST, USERDETAILS } from "../helpers/constant";
import Localstorage from "../storage/Localstorage";
import { RemoveSaleItemList } from "./saleItemAction";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOAD_ACTION = "LOAD_ACTION";
export const INACTIVITY_LOGOUT = "INACTIVITY_LOGOUT";
export const STORE_CHANGED = "STORE_CHANGED";
export const INITIALSETERROR = "INITIALSETERROR";
export const INITIALSETPOPUP = "INITIALSETPOPUP";
export const INITIALPAXSETPOPUP = "INITIALPAXSETPOPUP";
export const INITIALBATCHPOPUP = "INITIALBATCHPOPUP";

// function used for Login functionality
export function loginAction(
  username,
  password,
  store_id,
  master_store_id
) {
  return (dispatch) => {
    AuthService.login(username, password, store_id, master_store_id)
      .then(async (response) => {
        dispatch(confirmLoginAction(response.data.data));
        if (response?.data?.code == 200) {
          const resData = {
            data: response.data.data.data,
            token: response.data.data.token,
            logtime: new Date().toLocaleString(),
          };
          if (resData) {
            LocalStorage.setItem("pos_user", resData);
            Sessionstorage.setItem("timeout", "yes");
          }
          // check if device is setup on local or not
          // device is setup is checked by this below DeviceUUId
          if (DeviceUUId()) {
            //setup online devices
            let storeInfo = getDeviceAndStore();
            let onlineDevices = await AuthService.setUpOnlineDevices({
              store: storeInfo[1],
              device_id: storeInfo[0],
              user_id: resData?.data?.id,
            });
            if (onlineDevices.status == 200) {
              LocalStorage.setItem("online_device_info", onlineDevices?.data);
            }

            //check user has permission to access already setup store or not
            let setUpdata = Localstorage.getItem("setupData");
            let hasPer = false;
            if (setUpdata && setUpdata.store) {
              if (resData.data.access_stores) {
                let accessStore = JSON.parse(resData.data.access_stores);
                hasPer = accessStore.includes(setUpdata.store);
              }
              if (resData.data.store_owner == 1) {
                hasPer =
                  resData.data.master_store_id === setUpdata.master_store;
              }
            }

            if (hasPer) {
              if (getStartTimeInReport() === false) {
                localStorage.setItem(
                  "accountClosingTime",
                  new Date(getDeviceAndStore(true)[0]).getTime()
                );
              }
              const cookies = new Cookies();
              cookies.set("bottleposLoginCheck", true, {
                path: "/",
                maxAge: 1 * 60 * 60,
                sameSite: true,
              });
              setAuthLocalStorage(response.data.data);

              history.push({
                pathname: "/pos",
              });

              toast.success("Login successfully");
            } else {
              dispatch(intialSetError(true, INITIALSETERROR));
              history.push({
                pathname: "/",
                state: { showPermissionError: true },
              });
              return;
            }
          } else {
            setAuthLocalStorage(response.data.data);

            // if device is not setup and check if user is admin access or not if yes then go if block
            if (resData.data.is_superuser === 1) {
              dispatch(intialSetError(true, INITIALSETPOPUP));
              history.push({
                pathname: "/",
              });
            } else {
              // remove the all data from localstorage and show the msg user is permitted to setup device
              LocalStorage.removeItem("pos_user");
              Sessionstorage.removeItem("timeout", "yes");
              dispatch(intialSetError(true, INITIALSETERROR));
              history.push({
                pathname: "/",
              });
            }
          }
        } else {
          toast.error("Invalid username or password12 12 ");
          dispatch(failedLoginAction(response));
        }
      })
      .catch((error) => {
        toast.error("Invalid username or password");
        dispatch(failedLoginAction(error.response));
      });
  };
}

//clock inout added
export function clockInOutLoginAction(username, password) {
  return async () => {
    try {
      let storeId = getDeviceAndStore()[1];
      const master_store_id = Localstorage.getItem("master_store_id");
      let response = await AuthService.login(username, password, storeId, master_store_id);
      if (response.status === 200 && response.statusText === "OK") {
        return true;
      } else if (response?.data?.code === 200 && response?.data?.status === "success") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      toast.error("Invalid username or password");
      return false;
    }
  };
}

//auth validation
export function authValidation(username, password) {
  return async () => {
    try {
      let response = await AuthService.AuthUser(username, password);
      if (response.status === 200 && response.statusText === "OK") {
        return true;
      } else {
        toast.error("Invalid username or password");
        return false;
      }
    } catch (e) {
      toast.error("Invalid username or password");
      return false;
    }
  };
}

// function used for logout functionality
export function closeCustomerScreen() {
  if (window.electron) {
    const allWindow = window.electron.remote.BrowserWindow.getAllWindows();
    allWindow.forEach((element) => {
      if (element.customerScreen) {
        element.close();
      }
    });
  }
}
export function logoutAction() {
  return async (dispatch) => {
    dispatch(RemoveSaleItemList(true, REMOVESALELIST));
    dispatch(confirmLogoutAction({}));
    dispatch({
      type: INITIALIZED_POPUP,
      status: true
    })
    localStorage.removeItem("pos_user");
    const cookies = new Cookies();
    cookies.remove("bottleposLoginCheck");
    history.push({
      pathname: "/",
    });

    //remove online devices
    let onlineDeviceInfo = LocalStorage.getItem("online_device_info");
    LocalStorage.removeItem("online_device_info");
    if (
      onlineDeviceInfo &&
      onlineDeviceInfo != null &&
      onlineDeviceInfo !== "null" &&
      onlineDeviceInfo?.id
    ) {
      AuthService.RemoveOnlineDevices(onlineDeviceInfo.id);
    }
  };
}

// This function is used for setup register device if user has admin permission
export function setUpRegister(data, type) {
  return (dispatch) => {
    AuthService.setUpRegisterDevice(data)
      .then(async (response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.statusText === "OK"
        ) {
          if (authHeader() !== false) {
            //setup online devices
            let onlineDevices = await AuthService.setUpOnlineDevices({
              store: response.data.store_id,
              device_id: response.data.device_id,
              user_id: authUserId(),
            });
            if (onlineDevices.status == 201) {
              LocalStorage.setItem("online_device_info", onlineDevices?.data);
            }

            const setData = {
              ip: response.data.ip,
              id: response.data.id,
              user_agent: response.data.user_agent,
              created_at: response.data.created_at,
              device: response.data.device_id,
              store: response.data.store_id,
              master_store: data.master_store,
            };
            // save all the data in localstorage for setupdata to get register id and time
            localStorage.setItem("setupData", JSON.stringify(setData));
            localStorage.setItem("uuid", response.data.uuid);
            if (getStartTimeInReport() === false) {
              localStorage.setItem(
                "accountClosingTime",
                new Date(response.data.created_at).getTime()
              );
            }
            const cookies = new Cookies();
            cookies.set("bottleposLoginCheck", true, {
              path: "/",
              maxAge: 1 * 60 * 60,
              sameSite: true,
            });
            history.push({
              pathname: "/pos",
            });
          }
          toast.success("Device setup successfully");
          dispatch(confirmedSetupRegister(response.data, type));
        }
      })
      .catch((error) => {
        toast.error("Something went wrong!please try after some time");
        dispatch(failedLoginAction(error.response));
      });
  };
}

export function setUserDetails(userId) {
  return () => {
    getListService
      .ListData(USERDETAILS, userId)
      .then((response) => {
        if (response.data) {
          const localdata = LocalStorage.getItem("pos_user");
          if (localdata && localdata.data.id !== undefined) {
            localdata.data = response.data?.data;
            LocalStorage.setItem("user", localdata);
            LocalStorage.setItem("pos_user", localdata);
          }
        }
      })
      .catch(() => {
        // dispatch(logoutFailed(error.response));
      });
  };
}

// this function is used if  device  setup successfully to save the data in redux
export function confirmedSetupRegister(response, type) {
  return {
    type: type,
    payload: response,
  };
}

// function for if login failed credentials not matched
export function failedLoginAction(response) {
  return {
    type: LOGIN_FAILED,
    payload: response,
  };
}

// function for if login credentials matched
export function confirmLoginAction(response) {
  return {
    type: LOGIN_SUCCESS,
    payload: response,
  };
}

// function for if login credentials matched
export function intialSetError(response, type) {
  return {
    type: type,
    payload: response,
  };
}

// function for if logout success with autherization token
export function confirmLogoutAction(response) {
  return {
    type: LOGOUT_SUCCESS,
    payload: response,
  };
}

// function for if logout failed
export function logoutFailed(response) {
  return {
    type: LOGOUT_FAILED,
    payload: response,
  };
}

// funtion for using lazy loading in project.
export function loadingToggleAction(status) {
  return {
    type: LOAD_ACTION,
    payload: status,
  };
}

// function for pax initlization
export function intialPaxSetError(response, type) {
  return {
    type: type,
    payload: response,
  };
}
