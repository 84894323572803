import React, { useState } from "react";
// Data tables
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Card } from "@themesberg/react-bootstrap";
import PropTypes from 'prop-types';

export const RefundDetails = (props) => {
  // const { SearchBar, ClearSearchButton } = Search;
  const [payments, ] = useState([]);
  const columns = [
    {
      dataField: "name",
      text: "Item ID",
      sort: true,
      attrs: {
        "data-title": "Item ID",
      },
    },
    {
      dataField: "qty",
      text: "# Returned",
      sort: true,
      attrs: {
        "data-title": "# Returned",
      },
    },

  ];
  return (
    <>

      <Card className="custom-card no-checkbox no-box-shadow border-0  column-visibility-table auto-width">
      <div className="with-scroll-on-table">
        <ToolkitProvider key="Id" keyField="id" data={props?.items} columns={columns}>
          {(props) => (
            <>
              <div className="pagination-wrapper page-height">
                <BootstrapTable
                  bootstrap4
                  headerClasses="table-header"
                  pagination={false}
                  keyField="Id"
                  key="Id"
                  {...props.baseProps}
                  dataColumn={payments.title}
                  defaultSorted={false}
                />
              </div>
            </>
          )}
        </ToolkitProvider>
      </div>
      </Card>
    </>
  );
};
RefundDetails.propTypes = {
  items: PropTypes.array, 
  baseProps: PropTypes.object, 
};
