import React from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import hexToRgba from "hex-to-rgba";
import PropTypes from 'prop-types';

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    },
    updateColor: false,
  };

  // componentDidUpdate = ()=>{
  //   if(this.props.updateColorPicker !==null && this.props.updateColorPicker !==undefined){
  //     this.setState({updateColor : this.props.updateColorPicker == "#00ff00" ?  "#ef7923" : this.props.updateColorPicker})
  //   }
  // }

  componentDidMount = () => {
    this.props.colorCode(this.state.color);
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ updateColor: false });
    this.setState({ color: color.rgb });
    this.props.colorCode(color.rgb);
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "20px",
          height: "20px",
          borderRadius: "2px",
          background:
            this.props.updateColorPicker == "#00ff00"
              ? "#ef7923"
              : hexToRgba(this.props.updateColorPicker),
          // background: this.state.updateColor !== false ? (this.state.updateColor) :`rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
ColorPicker.propTypes = {
  colorCode: PropTypes.func.isRequired, 
  updateColorPicker: PropTypes.string, 
};

export default ColorPicker;
