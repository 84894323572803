
import actions from "../../assets/images/default/actions.svg";
import ActivateCard from "../../assets/images/default/activate-card.svg";
import DeactivateCard from "../../assets/images/default/deactivate-card.svg";
import add from "../../assets/images/default/add.svg";
import AddBalance from "../../assets/images/default/add-balance.svg";
import allCategories from "../../assets/images/default/all-categories.svg";
import backBlue from "../../assets/images/default/back-blue.svg";
import bckBtn from "../../assets/images/default/back-btn-green.svg";
import banIcon from "../../assets/images/default/ban.svg";
import barcodeIcon from "../../assets/images/default/barcode.png";
import cancel from "../../assets/images/default/cancel.png";
import cart from "../../assets/images/default/cart-arrow-down.svg";
import sideCard from "../../assets/images/default/cash-icon.svg";
import check from "../../assets/images/default/check.png";
import check1 from "../../assets/images/default/check1.svg";
import CheckBalance from "../../assets/images/default/check-balance.svg";
import LogoutIcon from "../../assets/images/default/check-circle.svg";
import close from "../../assets/images/default/close.png";
import cost from "../../assets/images/default/cost.svg";
import cross from "../../assets/images/default/cross.svg";
import crossIcon from "../../assets/images/default/cross-screen.svg";
import customer from "../../assets/images/default/customer1.svg";
import dayReport from "../../assets/images/default/day-report.svg";
import debit from "../../assets/images/default/debit.svg";
import deleteIcon from "../../assets/images/default/delete.svg";
import delivery from "../../assets/images/default/delivery.png";
import details from "../../assets/images/default/details.svg";
import ebt from "../../assets/images/default/ebt.svg";
import edit from "../../assets/images/default/edit.svg";
import ExpanIcon from "../../assets/images/default/expand.svg";
import EyeIcon from "../../assets/images/default/eye-btn.svg";
import EyeIconCLosed from "../../assets/images/default/eye-btn-closed.svg";
import faCross from "../../assets/images/default/faCross.svg";
import faPLus from "../../assets/images/default/faPlus.svg";
import general from "../../assets/images/default/general.svg";
import giftCard from "../../assets/images/default/gist-card.svg";
import hamburger from "../../assets/images/default/hamburger.svg";
import hamburger2 from "../../assets/images/default/hamburger-2.svg";
import ClockIcon from "../../assets/images/default/Icon-awesome-clock.svg";
import AdminIcon from "../../assets/images/default/Icon-awesome-user-cog.svg";
import FullscreenIcon from "../../assets/images/default/Icon-map-fullscreen.svg";
import PrintLabelIcon from "../../assets/images/default/Icon-material-label.svg";
import listIcon from "../../assets/images/default/Icon-metro-list.svg";
import LogoutIcon1 from "../../assets/images/default/Icon-open-account-logout.svg";
import items from "../../assets/images/default/items1.svg";
import minus from "../../assets/images/default/minus.svg";
import miscallenous from "../../assets/images/default/miscallenous.svg";
import moneyOff from "../../assets/images/default/money-off.svg";
import more from "../../assets/images/default/more.svg";
import noData from "../../assets/images/default/no-data-found.svg";
import offers from "../../assets/images/default/offers.svg";
import options from "../../assets/images/default/options.svg";
import payments from "../../assets/images/default/payments.svg";
import PlusAdd from "../../assets/images/default/plus-add.svg";
import plus from "../../assets/images/default/plus-green.svg";
import print from "../../assets/images/default/print.svg";
import printBg from "../../assets/images/default/print-big.svg";
import printIcon from "../../assets/images/default/print-icon.svg";
import printing from "../../assets/images/default/printing.svg";
import promotions from "../../assets/images/default/promotions.svg";
import redeem from "../../assets/images/default/reddem.svg";
import refundCash from "../../assets/images/default/refund-cash.svg";
import refund from "../../assets/images/default/refund-icon.svg";
import refundCard from "../../assets/images/default/refund-on-card.svg";
import registerReports from "../../assets/images/default/register-reports.svg";
import remove from "../../assets/images/default/remove.svg";
import sellerReports from "../../assets/images/default/seller.svg";
import defaultImage from "../../assets/images/default/shop-with-us.svg";
import shortcutIcon from "../../assets/images/default/shortcut-icon.svg";
import BgImage from "../../assets/images/default/signin-bg.png";
import whatsSelling from "../../assets/images/default/star.svg";
import takingsCOunt from "../../assets/images/default/takings-count.svg";
import adminIcon from "../../assets/images/default/user.png";
import warning from "../../assets/images/default/warning.svg";
import warningRed from "../../assets/images/default/warning-red.svg";
import ratings from "../../assets/images/ratings.svg";
import giftIcon from "../../assets/images/gift-icon.svg";
import logout from "../../assets/images/logout.svg";
import advancedUpdate from "../../assets/images/default/advanced-update.svg";


export const DefaultImg = () => {
  const Img = {
    advancedUpdate: advancedUpdate,
    logout:logout,
    ratings: ratings,
    // logoWhite:logoWhite,
    hamburger2: hamburger2,
    warningRed: warningRed,
    warning: warning,
    adminIcon: adminIcon,
    takingsCOunt: takingsCOunt,
    whatsSelling: whatsSelling,
    BgImage: BgImage,
    shortcutIcon: shortcutIcon,
    defaultImage: defaultImage,
    sellerReports: sellerReports,
    remove: remove,
    registerReports: registerReports,
    refundCash: refundCash,
    redeem: redeem,
    promotions: promotions,
    printing: printing,
    printIcon: printIcon,
    printBg: printBg,
    print: print,
    plus: plus,
    PlusAdd: PlusAdd,
    payments: payments,
    options: options,
    offers: offers,
    noData: noData,
    more: more,
    moneyOff: moneyOff,
    miscallenous: miscallenous,
    minus: minus,
    actions: actions,
    ActivateCard: ActivateCard,
    DeactivateCard: DeactivateCard,
    add: add,
    AddBalance: AddBalance,
    allCategories: allCategories,
    backBlue: backBlue,
    bckBtn: bckBtn,
    banIcon: banIcon,
    barcodeIcon: barcodeIcon,
    cancel: cancel,
    cart: cart,
    sideCard: sideCard,
    check: check,
    check1: check1,
    CheckBalance: CheckBalance,
    LogoutIcon: LogoutIcon,
    close: close,
    cost: cost,
    cross: cross,
    crossIcon: crossIcon,
    customer: customer,
    dayReport: dayReport,
    debit: debit,
    deleteIcon: deleteIcon,
    delivery: delivery,
    details: details,
    ebt: ebt,
    edit: edit,
    ExpanIcon: ExpanIcon,
    EyeIcon: EyeIcon,
    EyeIconCLosed: EyeIconCLosed,
    faCross: faCross,
    faPLus: faPLus,
    general: general,
    // logo: logo,
    giftCard: giftCard,
    hamburger: hamburger,
    ClockIcon: ClockIcon,
    AdminIcon: AdminIcon,
    FullscreenIcon: FullscreenIcon,
    PrintLabelIcon: PrintLabelIcon,
    listIcon: listIcon,
    LogoutIcon1: LogoutIcon1,
    items: items,
    //  logo1:logo1,
    refund: refund,
    refundCard: refundCard,
    giftIcon:giftIcon,
  };
  return Img;
};

export default DefaultImg;
