import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Image,
  ListGroup,
  Row,
  Form,
} from "@themesberg/react-bootstrap";
import { FirtsLetterUpperCase } from "../../../../app/helpers/custom";
import redeem from "../../../../assets/images/reddem.svg";
import offers from "../../../../assets/images/offers.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPLETEITEMSALELIST,
  DOLLER_REWARD_TYPE,
  POSSETTINGDATA,
  REEDEM_REWARDS,
} from "../../../../app/helpers/constant";
import Localstorage from "../../../../app/storage/Localstorage";
import CustomModal from "../../../components/Modals";
import CustomNumpad from "../../../components/customNumpad";
import FormField from "../../../components/formField";
import { completeSale } from "app/actions/saleItemAction";
import { getSettingData } from "axios/authHeader";
import PropTypes from 'prop-types';

const CustomerDetail = (props) => {
  const dispatch = useDispatch();
  const [custDetails, setCustDetails] = useState({});
  const [showItemNotFound, setShowItemNotFound] = useState(false);
  const [payBalanceAmt, setPayBalanceAmt] = useState(false);
  const [custOrginalPoints, setCustOriginalPoints] = useState(0);
  const [inputPoints, setInputPoints] = useState("");
  const [discountNotAllowed, setDiscountNotAllowed] = useState(false);
  const [invalidPointsInput, setInvalidPointInput] = useState(false);
  const [invalidPointsInputMsg, setInvalidPointInputMsg] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [pointsApplied, setPointsApplied] = useState(false);
  const totals = useSelector((state => state.saleItem.totalAmount))
  const [success, setSuccess] = useState({
    open: false,
    title: "",
    content: "",
  });
  const { notes, setNotes } = props;
  let rewardList =
    useSelector((state) => state.inventory.customerRewardsList) || [];
  let redeemPoints = useSelector((state) => state.saleItem.reedemPointDiscount);
  let saleItemList = useSelector((state) => state.saleItem.saleItemList) || [];
  let reedemPointDiscount =
    useSelector((state) => state.saleItem.reedemPointDiscount) || {};
  const [offerList, setOfferList] = useState([]);

  const custList = useSelector((state) => state.inventory.customerList);
  const itemList = useSelector((state) => state.inventory.itemlist);
  const categoryList = useSelector((state) => state.inventory.category);
  const [showDiscountNumpad, setShowDiscountNumpad] = useState(
    JSON.parse(localStorage.getItem("wpos_config"))?.screenKeypad
  );

  useEffect(() => {
    if (typeof props?.custDetails === "object" && props?.custDetails?.id) {
      let data = custList?.find((e) => +e.id == +props?.custDetails?.id);
      let sale_customer = Localstorage.getItem("sale_customer");
      if (reedemPointDiscount.points) {
        data = {
          ...sale_customer,
          ...data,
          points: data.points - +reedemPointDiscount.points,
        };
      }
      setNotes(data?.notes);
      setCustDetails(data || {});
      localStorage.setItem("sale_customer", JSON.stringify(data || {}));
      setCustOriginalPoints(+data?.points);
    }
  }, [props?.custDetails, custList]);

  useEffect(() => {
    if (redeemPoints?.points && Object.keys(custDetails).length) {
      setCustDetails({
        ...custDetails,
        points: custOrginalPoints - +redeemPoints?.points,
      });
    }
    if (
      redeemPoints?.points &&
      redeemPoints?.manualy_reedem &&
      Object.keys(custDetails).length
    ) {
      setCustDetails({
        ...custDetails,
        points: custOrginalPoints - +redeemPoints?.points,
      });
    }
  }, [redeemPoints]);

  let customerPoints = custDetails?.points || 0;
  useEffect(() => {
    let list = [];
    for (let e of rewardList) {
      let value = e.offer_type === "2" ? "$" + e.value : e.value + "%";
      if (e.points <= customerPoints) {
        list.push({
          rewardId: e.id,
          label: `Get ${value} off for ${e.points} points`,
        });
      }
    }
    setOfferList(list);
  }, [customerPoints, rewardList]);

  useEffect(() => {
    let Olist = offerList.map((e) => e?.label);
    let sale_customer = Localstorage.getItem("sale_customer");
    if (sale_customer) {
      let updateCustDetails = {
        ...sale_customer,
        offerList: Olist,
        notes,
        houseSalePayment: payBalanceAmt,
      };
      localStorage.setItem("sale_customer", JSON.stringify(updateCustDetails));
    }
  }, [offerList, notes, payBalanceAmt]);

  const applyRedeemPoints = (value, symbol, points, forceApply) => {
    if(saleItemList[saleItemList?.length - 1]?.totalDiscount > 0 && !pointsApplied) {
      setError(true)
    }else{
      setPointsApplied(true);
      if (saleItemList.length || forceApply) {
        let totalRedeemPoints = +points;
        if (forceApply) {
          totalRedeemPoints += +redeemPoints.points || 0;
        }
        dispatch({
          type: REEDEM_REWARDS,
          payload: {
            value: value,
            symbol: symbol,
            points: totalRedeemPoints,
            manualy_reedem: forceApply,
          },
        });
      }
    }
  };

  const handleOfferClick = (e) => {
    let saleItemList = Localstorage.getItem("saleitems");
    for (let x of saleItemList) {
      let category = categoryList?.find((e) => +e.id == +x.category);
      if (x.itemData?.donotdiscount) {
        setDiscountNotAllowed(true);
        return;
      }

      if (category?.discount) {
        setDiscountNotAllowed(true);
        return;
      }
    }

    let reward = rewardList.find((it) => +it.id == +e.rewardId);
    let symbol = reward?.offer_type === DOLLER_REWARD_TYPE ? "$" : "%";
    if(symbol === "$" && reward?.value > totals?.Total){
      setInvalidPointInputMsg("Offer amount cannot be greater than subtotal")
      setInvalidPointInput(true);
      return;
    }else{
      applyRedeemPoints(reward.value, symbol, reward.points);
    }
  };

  const handlePointsSubmit = () => {
    if (inputPoints === "") {
      setInvalidPointInputMsg("Invalid Input")
      setInvalidPointInput(true);
    }else if(+inputPoints > +custDetails?.points){
      setInvalidPointInputMsg("Not enough points")
      setInvalidPointInput(true);
    } else {
      let value = 0;
      applyRedeemPoints(value, "%", inputPoints, true);
      setShow(false);
      setInputPoints("");
      setSuccess({
        open: true,
        title: "Redeem points",
        content: inputPoints + " Points Redeemed!",
      });
    }
  };

  const payBalance = () => {
    let posSetting = getSettingData(POSSETTINGDATA);
    let houseItem = +posSetting?.OPTION?.house_pay_item;
    let item = itemList.find((e) => e.id == houseItem);
    if (item) {
      let list = item;
      setPayBalanceAmt(true);
      dispatch(completeSale([list], COMPLETEITEMSALELIST));
      props.handleClose();
    } else {
      setShowItemNotFound(true);
    }
  };

  const getDiscountValue = (data) => {
    if (data.value === "Enter") {
      setShowDiscountNumpad(false);
    }
    if (data.type === "DiscountKeypad" && data.value !== "Enter") {
      if (data.value === "Clear") {
        setInputPoints("");
      } else {
        setInputPoints(inputPoints + data.value);
      }
    }
  };

  return (
    <>
      <Row>
        <Col lg={6} md={6} sm={12}>
          <ListGroup>
            <ListGroup.Item>
              <h4>Name</h4>
              <p>{FirtsLetterUpperCase(custDetails?.name) || ""}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h4>Email</h4> <p>{custDetails?.email || ""}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h4>Mobile</h4>
              <p>{custDetails?.mobile || ""}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h4>DOB</h4>
              <p> {custDetails?.dob || ""}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h4>Address</h4>
              <p>{FirtsLetterUpperCase(custDetails?.address) || ""}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h4>Zipcode</h4>
              <p> {custDetails?.postcode || ""}</p>
            </ListGroup.Item>
          </ListGroup>
          {/* <div className="divider"></div> */}
        </Col>
        <Col lg={6} md={6} sm={12}>
          <ListGroup>
            <ListGroup.Item className="flex-direction-column">
              <h4>Rewards/Offers</h4>
              <div className="scroll-auto">
                {offerList && offerList.length ? (
                  offerList.map((e, index) => {
                    return (
                      <Button
                        key={index}
                        variant="outline-seagreen px-5"
                        onClick={() => handleOfferClick(e)}
                      >
                        <Image
                          className="me-2"
                          src={offers}
                          alt="offers Icon"
                        />
                        {e?.label}
                      </Button>
                    );
                  })
                ) : (
                  <p className="mt-2">No Offers available</p>
                )}
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row className="bottom-block">
        <Col lg={6} md={6} sm={12}>
          <ListGroup>
            <ListGroup.Item>
              <h4>SMS &amp; Email Promotions</h4>
              <p>
                {FirtsLetterUpperCase(
                  custDetails?.promotion_subscribed ? "Enable" : "Disable"
                ) || ""}
              </p>
            </ListGroup.Item>
            {/* <ListGroup.Item className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                checked={emailReceiptEnabled}
                onChange={() => setEmailReceiptEnabled(!emailReceiptEnabled)}
              />
              <h4 style={{ marginLeft: "10px" }}>Email Receipt:</h4>
            </ListGroup.Item> */}
            {custDetails?.ishousepay === 1 &&
              <>
              <ListGroup.Item>
                <h4>
                  Max Limit <p>{custDetails?.houseacclimit || "0.00"}</p>
                </h4>
              </ListGroup.Item>
            <ListGroup.Item className="flex-direction-column">
              <h4>
                Current Balance <p>{custDetails?.pendingbalance || ""}</p>
              </h4>
              <Button
                variant="outline-seagreen  px-4"
                onClick={() => payBalance()}
              >
                <Image className="me-2" src={redeem} alt="Redeem Icon" />
                Pay Balance
              </Button>
              </ListGroup.Item>
              </>
            }
            <ListGroup.Item className="flex-direction-column">
              <h4>
                Points <p>{custDetails?.points || 0}</p>
              </h4>
              <Button
                variant="outline-seagreen  px-5"
                onClick={() => setShow(true)}
              >
                <Image className="me-2" src={redeem} alt="Redeem Icon" />
                Redeem
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col lg={6} md={6} sm={12} className="mb-3">
          <Form.Group className="form-floating">
            <FormField
              as="textarea"
              rows="4"
              label="Notes"
              name="note"
              className="capitalCase"
              value={notes}
              // onFocus={(e) => {
              //   e.target.select();
              //   setFocus(false);
              // }}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Notes"
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Pop up for taking points input */}
      <CustomModal
        open={show}
        handleClose={() => setShow(false)}
        backdrop={"static"}
        dialogClassName="custom-modal with-border-bottom customer-info"
        title="Redeem points"
        size="md"
        content={
          <>
            <h5>Points Available: {custDetails?.points}</h5>
            <FormField
              type="number"
              id="custMobile"
              style={{ width: "100%" }}
              value={inputPoints}
              onChange={(e) => setInputPoints(e.target.value)}
              placeholder="Enter points"
              onClick={() => {
                if (
                  JSON.parse(localStorage.getItem("wpos_config"))?.screenKeypad
                ) {
                  setShowDiscountNumpad(true);
                }
              }}
            />
            {showDiscountNumpad && (
              <CustomNumpad
                type="DiscountKeypad"
                setValue={getDiscountValue}
                hideNumpad={setShowDiscountNumpad}
              />
            )}
          </>
        }
        action={
          <>
            <Button
              type="button"
              onClick={() => handlePointsSubmit()}
              variant="outline-blackcustom px-3 me-3 m-0"
            >
              Yes
            </Button>
            <Button
              type="button"
              onClick={() => setShow(false)}
              variant="primary px-3 m-0"
            >
              Close
            </Button>
          </>
        }
      />

      {/* house pay item not found warnning */}
      <CustomModal
        open={showItemNotFound}
        handleClose={() => setShowItemNotFound(false)}
        dialogClassName="custom-modal customer-info"
        title="Error"
        size="md"
        content={<h6>House Pay Item Not Found!</h6>}
        action={
          <Button
            type="button"
            onClick={() => setShowItemNotFound(false)}
            variant="outline-blackcustom px-3 me-3 m-0"
          >
            Ok
          </Button>
        }
      />

      {/* error for invalid point input */}
      <CustomModal
        open={invalidPointsInput}
        handleClose={() => setInvalidPointInput(false)}
        dialogClassName="custom-modal customer-info"
        title="Redeem Points"
        size="md"
        content={<h6>{invalidPointsInputMsg}</h6>}
        action={
          <Button
            type="button"
            onClick={() => setInvalidPointInput(false)}
            variant="outline-blackcustom px-3 me-3 m-0"
          >
            Ok
          </Button>
        }
      />

      <CustomModal
        open={discountNotAllowed}
        handleClose={() => setDiscountNotAllowed(false)}
        dialogClassName="custom-modal customer-info"
        title="Offer"
        size="md"
        content={<h6>Discount is not allowed on the Item(S)!</h6>}
        action={
          <Button
            type="button"
            onClick={() => setDiscountNotAllowed(false)}
            variant="outline-blackcustom px-3 me-3 m-0"
          >
            Ok
          </Button>
        }
      />
       <CustomModal
        open={error}
        handleClose={() => setError(false)}
        dialogClassName="custom-modal confirmation error-popup"
        title="Error"
        content={<h1 className="mb-3">{"Discount already applied, Please remove previous discount first to apply new discount."}</h1>}
        action={
          <Button
            type="button"
            onClick={() => setError(false)}
            variant="danger px-5 m-auto"
          >
            OK
          </Button>
        }
      />
      <CustomModal
        open={success.open}
        handleClose={() => props.ReponseAdded()}
        dialogClassName="custom-modal customer-info"
        title={success.title}
        size="md"
        content={<h6>{success.content}</h6>}
        action={
          <Button
            type="button"
            onClick={() => props.ReponseAdded()}
            variant="outline-blackcustom px-3 me-3 m-0"
          >
            Ok
          </Button>
        }
      />
    </>
  );
};
CustomerDetail.propTypes = {
  setNotes: PropTypes.func,
  custList: PropTypes.array,
  handleClose: PropTypes.func,
  ReponseAdded: PropTypes.func,
};
export default CustomerDetail;
