import React, { useEffect, useState } from "react";
import { Card, Button } from "@themesberg/react-bootstrap";

// Other imports
import BootstrapTable2 from "../components/BootsrapTable2";
import CustomScroll from "react-custom-scroll";

//redux
import { connect, useDispatch } from "react-redux";
import { POSSETTINGDATA } from "../../app/helpers/constant";
import { currencyFormat, getTextAccToAppType } from "../../app/helpers/custom";
import "../../../src/assets/css/customScroll.css";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import moment from "moment";
import { getDeviceAndStoreName } from "../../axios/authHeader";
import ReportTransactionTable from "./reportTransactionTable";
import CustomModal from "../components/Modals";
import { getSettingData } from "../../axios/authHeader";

export const UpdateRegisterReport = (repdata, otherdata) => {
  const temparray = [];
  if (repdata && typeof repdata === "object") {
    const bottledepositstatus = false;
    if (repdata.salenum === 0 && repdata.salerefs === "") {
      temparray.push([
        "Gross Sales",
        repdata.salenum,
        currencyFormat(repdata.saletotal),
      ]);
    } else {
      temparray.push([
        "Gross Sales",
        repdata.salenum,
        currencyFormat(repdata.saletotal),
        repdata.salerefs,
      ]);
    }
    if (repdata.lottosalenum === 0 && repdata.lottosalerefs === "") {
      temparray.push([
        "Lotto Sales",
        repdata.lottosalenum,
        currencyFormat(repdata.lottosaletotal),
      ]);
    } else {
      temparray.push([
        "Lotto Sales",
        repdata.lottosalenum,
        currencyFormat(repdata.lottosaletotal),
        repdata.lottosalerefs,
      ]);
    }
    if (repdata.onlinelotterynum === 0 && repdata.onlinelotteryrefs === "") {
      temparray.push([
        "Online Lottery",
        repdata.onlinelotterynum,
        currencyFormat(repdata.onlinelotterytotal),
        repdata.onlinelotteryrefs,
      ]);
    } else {
      temparray.push([
        "Online Lottery",
        repdata.onlinelotterynum,
        currencyFormat(repdata.onlinelotterytotal),
      ]);
    }
    if (repdata.feeitemsalenum === 0 && repdata.feeitemsalerefs === "") {
      temparray.push([
        "Fee Sales",
        repdata.feeitemsalenum,
        currencyFormat(repdata.feeitemsaletotal),
      ]);
    } else {
      temparray.push([
        "Fee Sales",
        repdata.feeitemsalenum,
        currencyFormat(repdata.feeitemsaletotal),
        repdata.feeitemsalerefs,
      ]);
    }
    if (repdata.houseitemsalenum === 0 && repdata.houseitemsalerefs === "") {
      temparray.push([
        "House Account Pay",
        repdata.houseitemsalenum,
        currencyFormat(repdata.houseitemsaletotal),
      ]);
    } else {
      temparray.push([
        "House Account Pay",
        repdata.houseitemsalenum,
        currencyFormat(repdata.houseitemsaletotal),
        repdata.houseitemsalerefs,
      ]);
    }
    if (repdata.refundnum === 0 && repdata.refundrefs === "") {
      temparray.push([
        "Refunds",
        repdata.refundnum,
        currencyFormat(repdata.refundtotal),
      ]);
    } else {
      temparray.push([
        "Refunds",
        repdata.refundnum,
        currencyFormat(repdata.refundtotal),
        repdata.refundrefs,
      ]);
    }
    if (repdata.voidnum === 0 && repdata.voidrefs === "") {
      temparray.push([
        "Voids",
        repdata.voidnum,
        currencyFormat(repdata.voidtotal),
      ]);
    } else {
      temparray.push([
        "Voids",
        repdata.voidnum,
        currencyFormat(repdata.voidtotal),
        repdata.voidrefs,
      ]);
    }
    temparray.push([
      "Sales Tax",
      repdata.taxqty,
      currencyFormat(repdata.taxes),
    ]);
    if (bottledepositstatus || repdata.itemdeposit !== null) {
      if (repdata.itemdepositcount === 0 && repdata.itemdepositrefs === "") {
        temparray.push([
          `${getTextAccToAppType("Bottle")} Deposit`,
          repdata.itemdepositcount,
          currencyFormat(repdata.itemdeposit),
        ]);
      } else {
        temparray.push([
          `${getTextAccToAppType("Bottle")} Deposit`,
          repdata.itemdepositcount,
          currencyFormat(repdata.itemdeposit),
        ]);
      }
    }
    if (bottledepositstatus || repdata.itemdepositreturn !== null) {
      if (
        repdata.itemdepositreturncount === 0 &&
        repdata.itemdepositreturnrefs === ""
      ) {
        temparray.push([
          "Deposit Return",
          repdata.itemdepositreturncount,
          currencyFormat(repdata.itemdepositreturn),
        ]);
      } else {
        temparray.push([
          "Deposit Return",
          repdata.itemdepositreturncount,
          currencyFormat(repdata.itemdepositreturn),
        ]);
      }
    }
    if (repdata.additionalchargeslisting) {
      for (let ac of repdata.additionalchargeslisting) {
        temparray.push([
          ac.labelname + " Additional Fee",
          +ac.addnlcount,
          currencyFormat(ac.total),
          repdata.additionalchargesresfs,
        ]);
      }
    }
    if (repdata.tipscount === 0 && repdata.tipsrefs === "") {
      temparray.push([
        "Tips Amount",
        repdata.tipscount,
        currencyFormat(repdata.tipsamounttotal),
      ]);
    } else {
      temparray.push([
        "Tips Amount",
        repdata.tipscount,
        currencyFormat(repdata.tipsamounttotal),
        repdata.tipsrefs,
      ]);
    }
    if (repdata.discountref === "") {
      temparray.push([
        "Total Discount",
        "-",
        currencyFormat(repdata.discountamt),
      ]);
    } else {
      var discount = repdata.hasOwnProperty("discountamt")
        ? parseFloat(repdata.discountamt)
        : 0;
      if (
        repdata.lottosalediscount !== "" &&
        repdata.lottopayoutdiscount !== ""
      ) {
        discount +=
          parseFloat(repdata.lottosalediscount) +
          parseFloat(repdata.lottopayoutdiscount);
      }
      temparray.push([
        "Total Discount",
        repdata.discountqty,
        currencyFormat(discount),
        repdata.discountref,
      ]);
    }
    if (repdata.nontaxable === "") {
      temparray.push([
        "Non Taxable",
        repdata.qtytotal,
        currencyFormat(repdata.nontaxable),
      ]);
    } else {
      temparray.push([
        "Non Taxable",
        repdata.qtytotal,
        currencyFormat(repdata.nontaxable),
      ]);
    }
    if (
      repdata.hasOwnProperty("salerounding") &&
      repdata.salerounding !== null &&
      repdata.salerounding > 0
    ) {
      temparray.push([
        "Sale Rounding",
        "-",
        currencyFormat(repdata.salerounding),
      ]);
    } //else{
    // }
    if (repdata.netsalenum === 0 && repdata.netsalerefs === "") {
      temparray.push([
        "Net Sales",
        repdata.netsalenum,
        currencyFormat(repdata.totaltakings),
      ]);
    } else {
      temparray.push([
        "Net Sales",
        repdata.netsalenum,
        currencyFormat(repdata.totaltakings),
        repdata.netsalerefs,
      ]);
    }
    if (+repdata?.neg_amt != 0 && repdata?.neg_amt){
      temparray.push([
        "Return/Exchange",
        repdata.neg_amt,
        currencyFormat(repdata.saletotalNegative),
        repdata.neg_refs,
      ]);
    }
    if (repdata.payoutnum === 0 && repdata.payoutrefs === "") {
      temparray.push([
        "Lotto Payout",
        repdata.payoutnum,
        currencyFormat(repdata.payouttotal),
      ]);
    } else {
      temparray.push([
        "Lotto Payout",
        repdata.payoutnum,
        currencyFormat(repdata.payouttotal),
        repdata.payoutrefs,
      ]);
    }
    if (repdata.onlinepaynum === 0 && repdata.onlinepayrefs === "") {
      temparray.push([
        "Online Payout",
        repdata.onlinepaynum,
        currencyFormat(repdata.onlinepaytotal),
      ]);
    } else {
      temparray.push([
        "Online Payout",
        repdata.onlinepaynum,
        currencyFormat(repdata.onlinepaytotal),
        repdata.onlinepayrefs,
      ]);
    }
    if (repdata.couponitemsalenum === 0 && repdata.onlinepayrefs === "") {
      temparray.push([
        "Coupon Sale($)",
        repdata.couponitemsalenum,
        currencyFormat(repdata.couponitemsaletotal),
      ]);
    } else {
      temparray.push([
        "Coupon Sale($)",
        repdata.couponitemsalenum,
        currencyFormat(repdata.couponitemsaletotal),
        repdata.onlinepayrefs,
      ]);
    }
    if (
      repdata.couponitemsalenum === 0 &&
      repdata.couponpercentitemsalerefs === ""
    ) {
      temparray.push([
        "Coupon Sale(%)",
        repdata.couponpercentitemsalenum,
        currencyFormat(repdata.couponpercentitemsaletotal),
      ]);
    } else {
      temparray.push([
        "Coupon Sale(%)",
        repdata.couponpercentitemsalenum,
        currencyFormat(repdata.couponpercentitemsaletotal),
        repdata.couponpercentitemsalerefs,
      ]);
    }
    if (repdata.promocouponcount === 0 || repdata.promocouponcount === "") {
      temparray.push([
        "Promo Coupons",
        repdata.couponitemsalenum,
        currencyFormat(0),
      ]);
    } else {
      temparray.push([
        "Promo Coupons",
        repdata.promocouponcount,
        currencyFormat(repdata.promocoupondisc),
      ]);
    }
    if (repdata.cancelsalenum === 0 && repdata.cancelsalerefs === "") {
      temparray.push([
        "Canceled Sale",
        repdata.cancelsalenum,
        currencyFormat(repdata.cancelsaletotal),
      ]);
    } else {
      temparray.push([
        "Canceled Sale",
        repdata.cancelsalenum,
        currencyFormat(repdata.cancelsaletotal),
        repdata.cancelsalerefs,
      ]);
    }
    if (repdata.nosalesnum === 0 && repdata.nosalesrefs === "") {
      temparray.push([
        "No Sale",
        repdata.nosalesnum,
        currencyFormat(repdata.nosalestotal),
      ]);
    } else {
      temparray.push([
        "No Sale",
        repdata.nosalesnum,
        currencyFormat(repdata.nosalestotal),
        repdata.nosalesrefs,
      ]);
    }
    if (
      repdata.manualpricechangenum === 0 &&
      repdata.manualpricechangerefs === ""
    ) {
      temparray.push([
        "Manual Price Change",
        repdata.manualpricechangenum,
        currencyFormat(repdata.manualpricechangetotal),
      ]);
    } else {
      temparray.push([
        "Manual Price Change",
        repdata.manualpricechangenum,
        currencyFormat(repdata.manualpricechangetotal),
        repdata.manualpricechangerefs,
      ]);
    }
    if (repdata.salenum === 0 && repdata.costrefs === "") {
      temparray.push(["Cost", repdata.salenum, currencyFormat(repdata.cost)]);
    } else {
      temparray.push([
        "Cost",
        repdata.salenum,
        currencyFormat(repdata.cost),
        repdata.costrefs,
      ]);
    }
    if (repdata.salenum === 0 && repdata.costrefs === "") {
      temparray.push([
        "Profit",
        repdata.salenum,
        currencyFormat(repdata.profit),
      ]);
    } else {
      temparray.push([
        "Profit",
        repdata.salenum,
        currencyFormat(repdata.profit),
        repdata.costrefs,
      ]);
    }
    if (repdata.cashnum === 0 && repdata.cashrefs === "") {
      temparray.push([
        "Cash",
        repdata.cashnum,
        currencyFormat(repdata.cashamt),
      ]);
    } else {
      temparray.push([
        "Cash",
        repdata.cashnum,
        currencyFormat(repdata.cashamt),
        repdata.cashrefs,
      ]);
    }
    if (repdata.cardnum === 0 && repdata.cardrefs === "") {
      temparray.push([
        "Card",
        repdata.cardnum,
        currencyFormat(repdata.cardamt),
      ]);
    } else {
      temparray.push([
        "Card",
        repdata.cardnum,
        currencyFormat(repdata.cardamt),
        repdata.cardrefs,
      ]);
    }
    if (repdata.checknum === 0 && repdata.checkrefs === "") {
      temparray.push([
        "Check",
        repdata.checknum,
        currencyFormat(repdata.checkamt),
      ]);
    } else {
      temparray.push([
        "Check",
        repdata.checknum,
        currencyFormat(repdata.checkamt),
        repdata.checkrefs,
      ]);
    }
    if (repdata.debitnum === 0 && repdata.debitrefs === "") {
      temparray.push([
        "Debit",
        repdata.debitnum,
        currencyFormat(repdata.debitamt),
      ]);
    } else {
      temparray.push([
        "Debit",
        repdata.debitnum,
        currencyFormat(repdata.debitamt),
        repdata.debitrefs,
      ]);
    }
    if (repdata.ebtnum === 0 && repdata.ebtrefs === "") {
      temparray.push(["Ebt", repdata.ebtnum, currencyFormat(repdata.ebtamt)]);
    } else {
      temparray.push([
        "Ebt",
        repdata.ebtnum,
        currencyFormat(repdata.ebtamt),
        repdata.ebtrefs,
      ]);
    }
    if (repdata.giftnum === 0 && repdata.giftrefs === "") {
      temparray.push([
        "Gift",
        repdata.giftnum,
        currencyFormat(repdata.giftamt),
      ]);
    } else {
      temparray.push([
        "Gift",
        repdata.giftnum,
        currencyFormat(repdata.giftamt),
        repdata.giftrefs,
      ]);
    }
    if (repdata.sidecardnum === 0 && repdata.sidecardrefs === "") {
      temparray.push([
        "Side Card",
        repdata.sidecardnum,
        currencyFormat(repdata.sidecardamt),
      ]);
    } else {
      temparray.push([
        "Side Card",
        repdata.sidecardnum,
        currencyFormat(repdata.sidecardamt),
        repdata.sidecardrefs,
      ]);
    }
    if (repdata.hasOwnProperty("cashpayout")) {
      if (Object.keys("repdata.cashpayout").length > 0) {
        for (var key in repdata.cashpayout) {
          temparray.push([
            repdata.cashpayout[key].type +
            " " +
            repdata.cashpayout[key].payment_mode +
            " payout",
            repdata.cashpayout[key].count,
            currencyFormat(repdata.cashpayout[key].amount),
          ]);
        }
      } else {
        temparray.push(["Cash Payout", 0, currencyFormat(0.0)]);
      }
    }
    if (repdata.hasOwnProperty("additional_tender")) {
      if (Object.keys("repdata.additional_tender").length > 0) {
        for (var key in repdata.additional_tender) {
          temparray.push([
            key,
            repdata.additional_tender[key].salenum,
            currencyFormat(repdata.additional_tender[key].balance),
            repdata.additional_tender[key].refs,
          ]);
        }
      }
    }
    // let payData = repdata.payData;
    // for (const payment in payData) {
    //     temparray.push([payment, payData[payment].salenum, currencyFormat(payData[payment].balance)]);
    // }
    let expectedCash = 0;
    // if(payData.hasOwnProperty('Cash') && isNumericData(payData.Cash.balance)){
    //     expectedCash = parseFloat(startingCash) + payData.Cash.balance;
    // }else{
    //     expectedCash = parseFloat(startingCash) + 0.00;
    // }
    // temparray.push(['Expected Cash', '', currencyFormat(expectedCash)]);
    // temparray.push(['Cash Counted', '', currencyFormat(props?.totalCash)]);
    temparray.push([
      "Awesome Rating",
      repdata.greatratingcount,
      String(repdata.greatrating),
      repdata.greatratingrefs,
    ]);
    temparray.push([
      "Ok Rating",
      repdata.okratingcount,
      String(repdata.okrating),
      repdata.okratingrefs,
    ]);
    temparray.push([
      "Not Happy Rating",
      repdata.nothappyratingcount,
      String(repdata.nothappyrating),
      repdata.nothappyratingrefs,
    ]);
    const posSetting = getSettingData(POSSETTINGDATA);
    if (
      otherdata?.dayreportcat?.data?.dayreport?.data &&
      posSetting?.OPTION?.day_report_category_format !== 1
    ) {
      let arr = Object.keys(otherdata?.dayreportcat?.data?.dayreport?.data)
        ? otherdata?.dayreportcat?.data?.dayreport?.data
        : {};
      if (typeof arr === "object") {
        for (let it of Object.keys(arr)) {
          if (posSetting?.OPTION?.day_report_category_format == 2) {
            let subArray = Array.isArray(arr[it]["data"])
              ? arr[it]["data"]
              : [arr[it]["data"]];
            for (let subCat of subArray) {
              temparray.push([
                subCat.name,
                subCat.hitstotal,
                String(parseFloat(subCat.pricetotal)?.toFixed(2)),
                "",
              ]);
            }

            temparray.push([
              arr[it]["total"]?.name,
              arr[it]["total"]?.hitstotal,
              String(parseFloat(arr[it]["total"]?.pricetotal)?.toFixed(2)),
              "",
              1,
            ]);
          } else {
            temparray.push([
              arr[it]["total"]?.name,
              arr[it]["total"]?.hitstotal,
              String(parseFloat(arr[it]["total"]?.pricetotal)?.toFixed(2)),
              "",
              1,
            ]);
          }
          // temparray.push(["", "", "", "", 1]);
        }
      }

      temparray.push([
        otherdata?.dayreportcat?.data?.dayreport?.total?.name,
        otherdata?.dayreportcat?.data?.dayreport?.total?.hitstotal,
        String(
          parseFloat(
            otherdata?.dayreportcat?.data?.dayreport?.total?.pricetotal
          )?.toFixed(2)
        ),
        "",
        1,
      ]);
    }
  }

  let result = [];
  for (let x of temparray) {
    result.push({
      Method: x[0],
      Balance:
        isNaN(parseFloat(x[1]).toFixed(2)) || parseFloat(x[1]) <= 0
          ? x[0] === "Awesome Rating" ||
            x[0] === "Ok Rating" ||
            x[0] === "Not Happy Rating"
            ? " - "
            : x[4]
              ? ""
              : "0"
          : x[0] === "Awesome Rating" ||
            x[0] === "Ok Rating" ||
            x[0] === "Not Happy Rating"
            ? `${x[1]} (${x[2]}%)`
            : x[1],
      Total:
        x[2] && parseFloat(x[2].replace(/\D/g, "")) > 0
          ? x[0] === "Awesome Rating" ||
            x[0] === "Ok Rating" ||
            x[0] === "Not Happy Rating"
            ? "-"
            : x[2]
          : x[0] === "Awesome Rating" ||
            x[0] === "Ok Rating" ||
            x[0] === "Not Happy Rating"
            ? "-"
            : x[4]
              ? ""
              : "0",
      ref: x[3],
      cat: x[4],
    });
  }
  return result;
};

const DayReports = (props) => {
  const [dayReportData, setDayReportData] = useState([]);
  const [refs, setRefs] = useState("");
  const [show, setShow] = useState(false);
  const [printPrvw, setPrintPrvw] = useState(false);

  useEffect(() => {
    setDayReportData(
      UpdateRegisterReport(
        props?.dayReport?.data?.data,
        props?.dayReport?.data
      )?.filter((e) => {
        return e?.Balance != 0 || (e?.Balance === "" && e?.Total === "");
      })
    );
  }, [props?.dayReport, props?.posSetting]);

  useEffect(() => {
    if (dayReportData.length > 0) {

      const yourHTMLString = `<div class="print-container" id="printthiswin">
    <table class="table table-bordered table-print">
        <thead>
            <tr>
                <th></th>
                <th>#Sales</th>
                <th>Total</th>
            </tr>
        </thead>
        <tbody>
            ${dayReportData
          .map((itm) => `
                    <tr>
                        <td>${itm.Method ?? ""}</td>
                        <td>${itm.Balance ? itm.Balance : "-"}</td>
                        <td>${itm.Total ? itm.Total == 0 ? currencyFormat(itm.Total) : itm.Total : "-"}</td>
                    </tr>
                `)
        }
        </tbody>
    </table>
</div>`;

      // Remove commas from the string
      const stringWithoutCommas = yourHTMLString.replace(/,/g, '');
      setPrintPrvw(stringWithoutCommas);
    }
  }, [dayReportData]);

  useEffect(() => {
    if (props?.printClick === "day") {
      // handlePrint();
      openInNewTab();
    }
    props.setPrintClick("");
  }, [props?.printClick]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const openInNewTab = (url) => {
    let printw = window.open("", "Dead Stock", "height=800,width=650");
    let store_name = getDeviceAndStoreName(true)[1] || ""
    printw.document.write(
      '<html><head><style>.itmvendorno {width: 100%!important;} .hidecol {display: none;}.table-print {margin-bottom: 20px;}.mytable, .table-print { color:#000; width:100%;border-collapse: collapse;border:1px solid #000;font-family:"Source Sans Pro", sans-serif;} .mytable thead:first-child td{font-weight:bold;color:black;} .mytable th, .mytable td, .table-print td, .table-print th {border:1px solid #ccc;padding:5px 10px; font-size: 14px;} .mytable th small {font-size: 14px;} .mytable thead th:last-child, .mytable tr td:last-child {display: none;} @media print {#scrollableDiv{width: 100%;height: 100%;}  .visible-print{display: block; width: auto;height: auto;overflow:visible;}}.dataTables_sizing {height: 40px !important;overflow: auto;} table.mytable td input {border: none;text-align: left;font-family:"Source Sans Pro", sans-serif;font-size:14px; text-transform: uppercase;} .itemname{text-transform: uppercase;} table.mytable th, table.mytable td {text-align: center; white-space: nowrap;} .table-print th, .table-print td {text-align:center; white-space: normal;}table.mytable th:nth-child(2), table.mytable td:nth-child(2) {text-align: left;} @page {size: landscape} </style>'
    );
    // const typeContent = reportType == "Deadstock" ? "- Items Not Sold in "+filterTypDeadStock+" Days" : reportType == "Overstock" ? " By "+filterTypOverStock+"X" : reportType == "expense" && filterTypExpenseReports != "" ? " By "+ filterTypExpenseReports : "";
    printw.document.write(
      '</head><body style="background-color: #FFFFFF;font-family:"Source Sans Pro", sans-serif;"><h2 style="text-align: center; ">' +
      store_name +
      " Day Report</h2>"
    );
    printw.document.write(
      '<h4 style="text-align: center; ">Range: ' +
      moment(props?.dateRange?.start).format("DD/MM/YY HH:mm:ss") + "-" + moment(props?.dateRange?.end).format("DD/MM/YY HH:mm:ss") +
      "</h4>"
    );
    printw.document.write('<table class="mytable">');
    printw.document.write("<thead>");
    printw.document.write("</thead> <tbody>");
    printw.document.write("</tbody></table>");
    printw.document.write(printPrvw);
    printw.document.write("</body></html>");
    printw.document.close();
    let printed = false;
    console.log({ check: printw });
    if (!printed) {
      printed = true;
      printw.focus();
      printw.print();
    }
  };

  const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
      <>
        <div className="print-container" ref={ref}>
          <div
            style={{
              textAlign: "center",
              marginBottom: "5px",
              fontSize: "18px",
            }}
          >
            <h5 style={{ fontSize: "18px", margin: "15px 0 10px 18px" }}>
              {getDeviceAndStoreName(true)[1]} Day Report{" "}
            </h5>
            <h5
              id="dayreportdate"
              style={{ fontSize: "18px", margin: "0 0 10px 18px" }}
            >
              {moment(props?.dateRange?.start).format("YYYY-MM-DD hh:mm")} -{" "}
              {moment(props?.dateRange?.end).format("YYYY-MM-DD hh:mm")}
            </h5>
          </div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th># Sales</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {props.data
                .filter(
                  (itm) =>
                    parseFloat(itm.Balance) !== 0 && parseFloat(itm.Total) !== 0
                )
                .map((elem, index) => {
                  return (
                    <>
                      <tr>
                        <td>{elem.Method}</td>
                        <td>{elem.Balance}</td>
                        <td>{elem.Total}</td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>
    );
  });

  const columns = [
    {
      dataField: "Method",
      text: "",
      attrs: {
        "data-title": "User",
      },
      formatter: (cell, row, index) => {
        if (dayReportData[index].cat) {
          return <span style={{ fontSize: "17px" }}>{row.Method}</span>;
        } else {
          return row.Method;
        }
      },
    },
    {
      dataField: "Balance",
      text: "# Sales",
      sort: true,
      formatter: (cell, row, index) => {
        if (row.ref && row.ref.length) {
          return (
            <span
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: dayReportData[index].cat ? "17px" : "",
              }}
              onClick={() => {
                setShow(true);
                setRefs(row.ref);
              }}
            >
              {row.Balance}
            </span>
          );
        } else {
          return (
            <span style={{ fontSize: dayReportData[index].cat ? "17px" : "" }}>
              {row.Balance}
            </span>
          );
        }
      },
      attrs: {
        "data-title": "# Sales",
      },
    },
    {
      dataField: "Total",
      text: "Total",
      sort: true,
      attrs: {
        "data-title": "Total",
      },
      formatter: (cell, row, index) => {
        if (dayReportData[index].cat) {
          return <span style={{ fontSize: "17px" }}>{row.Total}</span>;
        } else {
          return row.Total;
        }
      },
    },
  ];
  return (
    <>
      <Card className="custom-card no-checkbox no-box-shadow border-0 report-with-scroll auto-width column-visibility-table">
        <div className=" with-scroll-on-table">
          <CustomScroll
            addScrolledClass
            heightRelativeToParent="calc(100% - 0px)"
          >
            <BootstrapTable2
              columns={columns}
              data={dayReportData?.length ? dayReportData : []}
              keyField="id"
              noDataIndication={dayReportData?.length === 0 ? true : false}
            />
          </CustomScroll>
        </div>
      </Card>

      <div style={{ display: "none" }}>
        <ComponentToPrint
          ref={componentRef}
          dateRange={props?.dateRange}
          data={dayReportData?.length ? dayReportData : []}
        />
      </div>

      {/* Report transaction */}
      <CustomModal
        // dialogClassName={`maxwidth_${styles}` + " " + "custom-modal side-padding-zero report-transaction  with-border-bottom"}
        dialogClassName="custom-modal side-padding-zero report-transaction  with-border-bottom"
        // style={{ maxWidth: `${styles}`, margin: "auto" }}
        size="lg"
        open={show}
        handleClose={() => setShow(false)}
        // dialogClassName="custom-modal"
        title="Report Transactions"
        content={
          <>
            <div className="custom-data-table">
              <ReportTransactionTable refs={refs} />
            </div>
          </>
        }
        action={
          <>
            {/* <Button
              onClick={() => setCounter(counter + 1)}
              type="submit"
              variant="primary px-5 m-0 me-2"
            >
              Details
            </Button> */}
            <Button
              variant="outline-blackcustom px-5 m-0"
              onClick={() => setShow(false)}
              title="Close"
            >
              Close
            </Button>
          </>
        }
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    storeId: state.auth.storeId,
    dayReport: state.inventory.dayReport,
    accountSetting: state.inventory.accountSetting.data,
    posSetting: state.inventory.posSetting.data,
  };
};

export default connect(mapStateToProps)(DayReports);
