import React, { useEffect, useState } from "react";
import ReactSelect, { components } from "react-select";
import { CHANGE_SEARCH_FONTSIZE } from "../../app/helpers/constant";
import { useDispatch } from "react-redux";

const SearchFontSize = () => {
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const dispatch = useDispatch();


  const FontSize = [
    10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
    29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
    48, 49, 50,
  ];

  const handleFontSizeSearchChange = (selectedOption) => {

    setSelectedFontSearchSize(selectedOption);

    // Remove previous font size class from body
    const previousFontSizeSearchClass = localStorage.getItem("fontSizeSearchClass");
    if (previousFontSizeSearchClass) {
      document.body.classList.remove(previousFontSizeSearchClass);
    }

    // Add new font size class to body
    const fontSizeSearchClass = `font-size-search-${selectedOption.value}`;
    if (selectedOption.value !== "default") {
      document.body.classList.add(fontSizeSearchClass);
    }
    localStorage.setItem("fontSizeSearchClass", fontSizeSearchClass);

       // Dispatch action to update font size in Redux store
       dispatch({
        type: CHANGE_SEARCH_FONTSIZE,
        payload: selectedOption.value,
      });
  };

  const fontSizeSearchOptions = [
    { value: "default", label: "Default" },
    ...FontSize.map((item) => ({ value: item, label: item + "px" })),
  ];
  const [selectedFontSearchSize, setSelectedFontSearchSize] = useState(fontSizeSearchOptions[0]);
  

  useEffect(() => {
    const cachedFontSizeSearchClass = localStorage.getItem("fontSizeSearchClass");
    if (cachedFontSizeSearchClass) {
      // Apply the cached font size class to the body element
      document.body.classList.add(cachedFontSizeSearchClass);

      // Retrieve the font size value from the cached class name
      const fontSizeSearchValue = cachedFontSizeSearchClass.replace("font-size-search-", "");
      console.log("fontSizeSearchValue", fontSizeSearchValue);

      // Set the selected font size
      setSelectedFontSearchSize({
        value: fontSizeSearchValue,
        label: fontSizeSearchValue === "default" ? "Default" : fontSizeSearchValue + "px",
      });

      // Dispatch action to update font size in Redux store
      dispatch({
        type: CHANGE_SEARCH_FONTSIZE,
        payload: fontSizeSearchValue,
      });
    }
  }, []);

  return (
    <ReactSelect
      components={{
        ValueContainer: CustomValueContainer,
      }}
      styles={{
        valueContainer: (provided, state) => ({
          ...provided,
          overflow: "visible",
        }),
        placeholder: (provided, state) => ({
          ...provided,
          position: "absolute",
          top: state.hasValue || state.selectProps.inputValue ? -15 : "7px",
          transition: "top 0.1s, font-size 0.1s",

          transform:
            (state.hasValue || state.selectProps.inputValue) && "scale(0.85)",
          opacity: (state.hasValue || state.selectProps.inputValue) && 1,
        }),
      }}
      value={selectedFontSearchSize}
      classNamePrefix="react-select"
      onChange={handleFontSizeSearchChange}
      options={fontSizeSearchOptions}
      placeholder="Search Font Size"
    />
  );
};

export default SearchFontSize;
