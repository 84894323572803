import React, { useState } from "react";
import { Col, Table, Button } from "@themesberg/react-bootstrap";
import CustomModal from "../../../components/Modals";
import { useDispatch } from "react-redux";
import {
  ADDITEMONSALE,
  NUMPADSTATUS,
  DEPOSITRETURNTYPE,
  LOTTOPAYOUTTYPE,
  ONLINEPAYOUTTYPE,
  LOTTOSALETYPE,
  ONLINELOTTERYTYPE,
  NEGATIVETYPE,
} from "../../../../app/helpers/constant";
import {
  AddSaleItemList,
  ShowNumpadStatus,
} from "../../../../app/actions/saleItemAction";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';


const ToDoItem = ({
  name,
  todo,
  id,
  itemtype,
  shortCutItem,
  color_code,
  contentColor,
}) => {
  const [show, setShow] = useState(false);
  const [itemNotFound, setItemNotFound] = useState(false);
  const dispatch = useDispatch();
  const posSetting = useSelector((state) => state.inventory.posSetting.data);
  const accountSetting = useSelector((state)=> state.inventory.accountSetting.data);
  const itemsList = useSelector((state) => state.inventory.itemlist);
  const payOutIds = [
    parseInt(posSetting?.OPTION?.lotto_payout_item),
    parseInt(posSetting?.OPTION?.online_payout_item),
  ];
  const namesArray = [
    "Lotto Payout",
    "Lotto Sale",
    "Online Payout",
    "Online Lottery",
  ];

  const handleShortCutAdded = (id, itemtype) => {
    let fetchingId;
    if (itemtype == LOTTOPAYOUTTYPE) fetchingId = posSetting?.OPTION?.lotto_payout_item;
    else if (itemtype == LOTTOSALETYPE) fetchingId = posSetting?.OPTION?.lotto_sale_item;
    else if (itemtype == ONLINELOTTERYTYPE) fetchingId = posSetting?.OPTION?.online_lottery_item;
    else if (itemtype == ONLINEPAYOUTTYPE) fetchingId = posSetting?.OPTION?.online_payout_item;
    else if (itemtype === NEGATIVETYPE || itemtype === DEPOSITRETURNTYPE) fetchingId = id

    if (id !== undefined) {
      let filteredItem =
        itemsList && itemsList.filter((item) => item?.id == fetchingId);
      if (filteredItem.length > 0) {
        if (payOutIds.includes(id) || itemtype === NEGATIVETYPE) {
          filteredItem[0].modifier[0].price = "-";
        } else if (itemtype === DEPOSITRETURNTYPE){
          filteredItem[0].modifier[0].price = accountSetting?.BOTTLEDEPOSIT.bottleDepositReturnAmount;
        } else {
          filteredItem[0].modifier[0].price = "";
        }
        dispatch(AddSaleItemList(filteredItem[0], ADDITEMONSALE));
        dispatch(ShowNumpadStatus(true, NUMPADSTATUS));
      } else {
        setItemNotFound(true);
      }
    }
  };

  const handleShortCutItemAdded = (id) => {
    let filteredItem = itemsList && itemsList.filter((item) => item?.id == id);
    dispatch(AddSaleItemList(filteredItem[0], ADDITEMONSALE));
    dispatch(ShowNumpadStatus(false, NUMPADSTATUS));
  };
  return (
    <>
      <Col
        className="mb-3 sortable-column"
        md={4}
        key={id}
        title={namesArray.includes(todo?.name) ? todo.name : name}
        style={{
          backgroundColor: color_code == "#00ff00" ? "#ef7923" : color_code,
        }}
        onClick={() => {
          console.log("itemtype", itemtype)
          if (shortCutItem && itemtype !== NEGATIVETYPE && itemtype !== DEPOSITRETURNTYPE) {
            handleShortCutItemAdded(id);
          } else {
            handleShortCutAdded(id, itemtype);
          }
        }}
      >
        <span style={{ color: contentColor }}>
          {namesArray.includes(todo?.name) ? todo?.name : name}
        </span>
      </Col>
      {/* Item List popup */}
      <CustomModal
        open={show}
        size="lg"
        handleClose={() => setShow(false)}
        dialogClassName="custom-modal confirmation item-list"
        title="Item List"
        content={
          <>
            <h1 className="mb-3">Please select</h1>
            <Table responsive borderless>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody className="scroll-auto">
                <tr>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </Table>
          </>
        }
      />
      {/* Item Not Found */}
      <CustomModal
        open={itemNotFound}
        size="md"
        handleClose={() => setItemNotFound(false)}
        dialogClassName="custom-modal confirmation"
        title="Error"
        content={
          <>
            <h5 className="mb-3">{name} Item not found!</h5>
          </>
        }
        action={
          <Button
            variant="primary px-5 form-cta-btn"
            type="button"
            onClick={() => {
              setItemNotFound(false);
            }}
          >
            Ok
          </Button>
        }
      />
    </>
  );
};

ToDoItem.propTypes = {
  name: PropTypes.string,
  todo: PropTypes.shape({
    name: PropTypes.string,
  }),
  id: PropTypes.number,
  itemtype: PropTypes.number,
  shortCutItem: PropTypes.bool,
  color_code: PropTypes.string,
  contentColor: PropTypes.string,
};

export default ToDoItem;
