import React, { useState } from "react";
import {Button, Form } from "@themesberg/react-bootstrap";
import FormField from "../../components/formField";
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { connect, useDispatch } from "react-redux";
import { DATEOFBIRTH } from "app/helpers/constant";
import { confirmedResponseAction } from "app/actions/saleItemAction";

const AgeVerification = (props) => {
  const [enterAge, setEnterAge] = useState("");
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  const dispatch = useDispatch();
  const certainDoB = new Date(`${mm}/${dd}/${yyyy - props.ageNumber}`);
  const handleVerified = (e) => {
    const Validation = props?.PosSetting?.SALE?.must_enter_age_verification_date ?? props?.PosSetting?.SALE?.must_enter_age_verification_date
    const ageValidation = Validation ? parseInt(Validation) === 1 ? true : false :false 
    e.preventDefault();
    if(ageValidation){
      if(enterAge !==""){
        const date2 = new Date(enterAge);
        const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        if (date_regex.test(enterAge)) {
          // To calculate the age difference of years
          const Difference_In_Age = yyyy - date2.getFullYear();
          if (date2 < certainDoB) {
            props.VerifiedAge(true);
            dispatch(confirmedResponseAction(enterAge, DATEOFBIRTH));
          } else {
            props.VerifiedAge(false);
            props.ErrorMsg("Customer Not Old Enough for Purchase");
          }
        } else {
          props.VerifiedAge(false);
          props.ErrorMsg("Not a valid date");
        }
      }else{
       toast.error("Please enter birth date")
      }
    }else{
      props.VerifiedAge(true);
    }  
  };

  function checkValue(str, max) {
    if (str.charAt(0) !== "0" || str == "00") {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str =
        num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
          ? "0" + num
          : num.toString();
    }
    return str;
  }

  const EnterDate = (e) => {
    e.persist();
    var input = e.target.value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
    var values = input.split("/").map(function (v) {
      return v.replace(/\D/g, "");
    });
    if (values[0]) values[0] = checkValue(values[0], 12);
    if (values[1]) values[1] = checkValue(values[1], 31);
    var output = values.map(function (v, i) {
      return v.length == 2 && i < 2 ? v + "/" : v;
    });
    setEnterAge(output.join("").substr(0, 10));
  };

  return (
    <>
      <h1 className="mb-3">Please Verify Customer Is Born Before:</h1>
      <p> {`${mm}/${dd}/${yyyy - props.ageNumber}`}</p>
        <Form.Group className="form-floating with-label-up">
          <FormField
            label="Enter Birth Date"
            type="text"
            name="enterAge"
            value={enterAge}
            onChange={(e) => EnterDate(e)}
            placeholder="MM/DD/YYYY"
          />
        </Form.Group>
        <Button
          variant="primary px-3 position-absolute form-cta-btn"
          type="button"
          onClick={(e) => handleVerified(e)}
        >
          Yes
        </Button>
    </>
  );
};
AgeVerification.propTypes = {
  ageNumber: PropTypes.number,
  VerifiedAge: PropTypes.func,
  ErrorMsg: PropTypes.func,
};
export default AgeVerification;
