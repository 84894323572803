import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, logo: "" };

    if (this.props.showError === false) {
      this.state.error = null;
      this.state.errorInfo = null;
    }
  }

  componentDidCatch = (error, info) => {
    const body = document.getElementsByTagName("body");
    body[0].style.height = "100px";
    body[0].style.width = "100px";
    body[0].style.backgroundImage = `url(${this.props?.dynamicImages?.logo1})}`;
  };

  render() {
    if (this.state.errorInfo) {
      return <div></div>;
    } else {
      return this.props.children;
    }
  }
}
ErrorBoundary.propTypes = {
  showError: PropTypes.bool, 
  dynamicImages: PropTypes.object,
  children: PropTypes.node,
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
  };
};
export default connect(mapStateToprops)(ErrorBoundary);
