import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Row,
} from "@themesberg/react-bootstrap";
import { Type } from "react-bootstrap-table2-editor";
import { tasklisttable } from "../../data/tables";
import BootstrapTable2 from "../components/BootsrapTable2";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import add from "../../assets/images/add.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "../components/Modals";
import FormField from "../components/formField";
import ReactSelect, { components } from "react-select";
const TaskDetails = () => {
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  return (
    <>
      <h2>Tasks</h2>
      <ListGroup as="ol" numbered>
        <ListGroup.Item as="li">1. Cras justo odio</ListGroup.Item>
        <ListGroup.Item as="li">2. Cras justo odio</ListGroup.Item>
        <ListGroup.Item as="li">3. Cras justo odio</ListGroup.Item>
      </ListGroup>
      <Form autoComplete="off" className="mt-4">
        <Row>
          <Col md={6} className="mb-4 custom-react-select">
            <Form.Group>
              <ReactSelect
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                styles={{
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top:
                      state.hasValue || state.selectProps.inputValue
                        ? -15
                        : "7px",
                    transition: "top 0.1s, font-size 0.1s",

                    transform:
                      (state.hasValue || state.selectProps.inputValue) &&
                      "scale(0.85)",
                    opacity:
                      (state.hasValue || state.selectProps.inputValue) && 1,
                  }),
                }}
                classNamePrefix="react-select"
                // options={ItemSizeList.map((list) => {
                //   return {
                //     ...list,
                //     value: list.id,
                //     label: list.name.toUpperCase(),
                //   };
                // })}
                // value={ItemSizeList.map((item) => {
                //   return {
                //     ...item,
                //     value: item.id,
                //     label: item.name.toUpperCase(),
                //   };
                // }).filter((item) => {
                //   return item.value === item_size;
                // })}
                // onChange={(e) => handleSelectChange(e, "item_size")}
                placeholder={"Employee Name"}
              />
            </Form.Group>
          </Col>
          <Col md={6} className="mb-4">
            <Form.Group className="form-floating with-label-up">
              <FormField
                label="Task Time"
                type="text"
                name="name"
                // value={name}
                // onFocus={(e) => e.target.select()}
                // onChange={(e) => handleChange(e, "all")}
                placeholder="3/10/2022 10:15: AM"
              />
            </Form.Group>
          </Col>

          <Col md={12} className="mb-4">
            <Form.Group className="form-floating">
              <FormField
                as="textarea"
                rows="4"
                label="Notes"
                style={{ height: "150px" }}
                name="note"
                // value={note}
                // onFocus={(e) => e.target.select()}
                // onChange={(e) => handleChange(e, "all")}
                placeholder="Notes"
              />
            </Form.Group>
          </Col>
        </Row>
        <Button
          variant="primary px-5 position-absolute form-cta-btn"
          type="submit"
        >
          Save
        </Button>
      </Form>
    </>
  );
};

export default TaskDetails;
