import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Fade,
  Badge,
  Image,
} from "@themesberg/react-bootstrap";

// Other imports
import ReactSelect, { components } from "react-select";
import BootstrapTable2 from "../components/BootsrapTable2";
import CustomScroll from "react-custom-scroll";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  ALL,
  ALLVALUE,
  CANCELED,
  CANCELEDVALUE,
  COMPLETE,
  COMPLETEVALUE,
  DECELINED,
  DECELINEDVALUE,
  NOSALE,
  NOSALEVALUE,
  ORDER,
  ORDERVALUE,
  REFUNDED,
  REFUNDEDVALUE,
  VOID,
  VOIDVALUE,
  ACCEPTED,
  ACCEPTEDVALUE,
} from "../../app/helpers/constant";
import {
  SaleStatusData,
  FirtsLetterUpperCase,
  dateFormate,
  currencyFormat,
} from "../../app/helpers/custom";
import { authUserName, getDeviceAndStoreName } from "../../axios/authHeader";
import CustomModal from "../components/Modals";
import TransactionDetail from "../TransactionDetails/TransactionDetails";
import EyeIcon from "../../assets/images/eye-btn.svg";
import EyeIconCLosed from "../../assets/images/eye-btn-closed.svg";

const WebOrdersTable = (props) => {
  const { ValueContainer, Placeholder } = components;
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const statusList = [
    { name: ALL, id: ALLVALUE },
    { name: CANCELED, id: CANCELEDVALUE },
    { name: COMPLETE, id: COMPLETEVALUE },
    { name: ACCEPTED, id: ACCEPTEDVALUE },
    { name: DECELINED, id: DECELINEDVALUE },
    { name: NOSALE, id: NOSALEVALUE },
    { name: ORDER, id: ORDERVALUE },
    { name: REFUNDED, id: REFUNDEDVALUE },
    { name: VOID, id: VOIDVALUE },
  ];
  const [show, setShow] = useState(false);
  const [saleListData, setSaleListData] = useState([]);
  const [transectionDetails, setTransectionDetails] = useState({ Data: "" });
  const [status, setStatus] = useState("");
  const [dynamicImages, setDynamicImages] = useState(null);
  useEffect(() => {
    setDynamicImages(props.dynamicImages);
  }, [props.dynamicImages]);
  const handleTransectionDetails = (row) => {
    if (row) {
      setShow(true);
      setTransectionDetails({ Data: row });
    } else {
      setShow(false);
      setTimeout(() => {
        setTransectionDetails({ Data: "" });
      }, 300);
    }
  };
  useEffect(() => {
    if (props?.saleList.length > 0) {
      setSaleListData(UpdateArray(props.saleList));
    }
  }, [props.saleList]);

  const UpdateArray = (array) => {
    return array.map((data) => {
      return {
        ...data,
        GID: data?.id,
        Ref: data?.ref?.split("-")[2],
        DeviceStore: FirtsLetterUpperCase(getDeviceAndStoreName(true)[0]),
        CustomerName: data?.custid > 0 ? data?.custname : "UNKNOWN",
        Mobile: data?.custid > 0 ? data?.custphone : "N/A",
        Items: data?.numitems,
        Total: currencyFormat(data?.total),
        SaleTime: data?.dt,
        Status: data?.status,
        Payments: data?.payments,
        Item: data?.items,
        Extracosts: data?.extracosts,
        Processdt: dateFormate(parseInt(data?.processdt)),
        Store: FirtsLetterUpperCase(getDeviceAndStoreName(true)[1]),
        UserID: FirtsLetterUpperCase(authUserName()),
        Notes: data?.notes,
        actions: "Actions",
      };
    });
  };

  const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          onClick={() => handleTransectionDetails(row)}
          className="p-0"
          variant="link"
          title="View"
        >
          <Image src={props?.dynamicImages?.EyeIconCLosed} alt="Eye Icon" />
        </Button>
      </>
    );
  };
  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    const { value, color } = SaleStatusData(parseInt(row.Status));
    return <Badge bg={color}>{value}</Badge>;
  };

  const gidFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="d-flex  align-items-sm-center">
        <Badge bg="purple">Synced</Badge>
        <span className="ms-2">252601</span>
      </div>
    );
  };
  const columns = [
    {
      dataField: "GID",
      text: "GID",
      sort: true,
      formatter: gidFormatter,
      attrs: {
        "data-title": "GID",
      },
    },
    {
      dataField: "Ref",
      text: "Ref",
      sort: true,
      attrs: {
        "data-title": "Ref",
      },
    },

    {
      dataField: "CustomerName",
      text: "Customer Name",
      sort: true,
      attrs: {
        "data-title": "Customer Name",
      },
    },
    {
      dataField: "Address",
      text: "Address",
      sort: true,
      attrs: {
        "data-title": "Address",
      },
    },
    {
      dataField: "Mobile",
      text: "Mobile",
      sort: true,
      attrs: {
        "data-title": "Mobile",
      },
    },
    {
      dataField: "Items",
      text: "#Items",
      sort: true,
      attrs: {
        "data-title": "#Items",
      },
    },
    {
      dataField: "Total",
      text: "Total",
      sort: true,
      attrs: {
        "data-title": "Total",
      },
    },
    {
      dataField: "SaleTime",
      text: "Sale Time",
      sort: true,
      attrs: {
        "data-title": "Sale Time",
      },
    },
    {
      dataField: "orderSince",
      text: "Order Since",
      sort: true,
      attrs: {
        "data-title": "Order Since",
      },
    },
    {
      dataField: "OrderType",
      text: "Order Type",
      sort: true,
      attrs: {
        "data-title": "Order Type",
      },
    },
    {
      dataField: "PaymentType",
      text: "Payment Type",
      sort: true,
      attrs: {
        "data-title": "Payment Type",
      },
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      attrs: {
        "data-title": "Status",
      },
    },
    {
      dataField: "Actions",
      text: "Actions",
      formatter: actionsFormatter,
      attrs: {
        "data-title": "Actions",
      },
    },
  ];
  const defaultSorted = [
    {
      dataField: "GID",
      order: "desc",
    },
  ];

  // Expeand row
  const expandRow = {
    renderer: (row) => (
      <Fade appear={true} in={true}>
        <ul>
          <li>
            <span className="dtr-title">GID</span>
            <span className="dtr-data">{gidFormatter(null, row)}</span>
          </li>
          <li className="address-orders">
            <span className="dtr-title">Address</span>
            <span className="dtr-data">{row.Address}</span>
          </li>
          <li className="items-orders">
            <span className="dtr-title">#Items</span>
            <span className="dtr-data">{row.Items}</span>
          </li>
          <li className="total-orders">
            <span className="dtr-title">Total</span>
            <span className="dtr-data">{row.Total}</span>
          </li>
          <li>
            <span className="dtr-title">Sale Time</span>
            <span className="dtr-data">{row.SaleTime}</span>
          </li>
          <li>
            <span className="dtr-title">Order Since</span>
            <span className="dtr-data">{row.orderSince}</span>
          </li>
          <li>
            <span className="dtr-title">Order Type</span>
            <span className="dtr-data">{row.OrderType}</span>
          </li>
          <li className="payment-orders">
            <span className="dtr-title">Payment Type</span>
            <span className="dtr-data">{row.PaymentType}</span>
          </li>
          <li className="status-orders">
            <span className="dtr-title">Status</span>
            <span className="dtr-data">{statusFormatter(null, row)}</span>
          </li>
        </ul>
      </Fade>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return expendColorShow(isAnyExpands);
    },
    expandColumnRenderer: ({ expanded }) => {
      return expendColorShow(expanded);
    },
  };
  const expendColorShow = (value) => {
    if (value) {
      return (
        <Image
          className="expand-row-icon"
          src={props?.dynamicImages?.minusIcon}
          alt="minus icon"
        />
      );
    }
    return (
      <Image
        className="expand-row-icon"
        src={props?.dynamicImages?.plusIcon}
        alt="plus icon"
      />
    );
  };

  const expandRow2 = {
    renderer: () => {},
  };

  const size = useWindowSize();
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: "0 px",
      height: "0 px",
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size}
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: false, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "11",
        value: 11,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: saleListData.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  

  return (
    <>
      <Card className="custom-card no-checkbox with-page column-visibility-table auto-width pagination-absolute web-orders-table">
        <Card.Header className="d-flex flex-column flex-md-row justify-content-md-between align-items-lg-center">
          <h2 className="mb-0">Web Orders</h2>
          <div
            className="d-flex align-items-sm-center top-search flex-column flex-sm-row"
            style={{ width: "auto" }}

            
          >
            <Form className="navbar-search stock-code-search mb-3 mb-sm-0">
              <Form.Group>
                <InputGroup className="input-group-merge search-bar">
                  <Button variant="purple" title="Search">
                    <FontAwesomeIcon color="white" size="sm" icon={faSearch} />
                  </Button>
                  <Form.Control      
                    type="text"
                    placeholder="Global Reference Search"
                  />
                </InputGroup>
              </Form.Group>
            </Form>

            <Button style={{ width: "135px" }} variant="purpule-light add-btn" title="View Order">
              <Image src={props?.dynamicImages?.EyeIcon} alt="Add Icon" />
              View Order
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="p-3 px-0">
          <div>
            <Form.Group className="custom-react-select me-sm-4 mb-3 mb-sm-0 filter-by-status">
              <ReactSelect
                components={{
                  ValueContainer: CustomValueContainer,
                }}
                styles={{
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top:
                      state.hasValue || state.selectProps.inputValue
                        ? -15
                        : "7px",
                    transition: "top 0.1s, font-size 0.1s",

                    transform:
                      (state.hasValue || state.selectProps.inputValue) &&
                      "scale(0.85)",
                    opacity:
                      (state.hasValue || state.selectProps.inputValue) && 1,
                  }),
                }}
                classNamePrefix="react-select"
                options={statusList.map((item) => {
                  return {
                    ...item,
                    value: item.id,
                    label: item.name,
                  };
                })}
                value={statusList
                  .map((item) => {
                    return {
                      ...item,
                      value: item.id,
                      label: item.name,
                    };
                  })
                  .filter((item) => {
                    return item.value === status;
                  })}
                onChange={(e) => setStatus(e.value)}
                placeholder={"Filter by Status"}
              />
            </Form.Group>
            <div className=" with-scroll-on-table">
              <div
                className={
                  size.width !== undefined &&
                  size.width >= 1 &&
                  size.width <= 1024
                    ? "expand-table-row "
                    : ""
                }
              >
                <BootstrapTable2
                  columns={columns}
                  data={saleListData}
                  keyField="Ref"
                  pagination={true}
                  exportCSVToggle={false}
                  noDataIndication={saleListData.length === 0 ? true : false}
                  SearchBarToggle={true}
                  PaginationOptions={options}
                  defaultSorted={defaultSorted}
                  expandRow={
                    size.width !== undefined &&
                    size.width >= 1 &&
                    size.width <= 1024
                      ? expandRow
                      : expandRow2
                  }
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      {/* Transaction detail popup */}
      <CustomModal
        open={show}
        handleClose={() => handleTransectionDetails()}
        dialogClassName="custom-modal modal-50w transaction-details with-border-bottom"
        title="Transaction Details"
        backdrop="static"
        keyboard={false}
        content={
          <TransactionDetail
            handleClose={() => handleTransectionDetails()}
            setTransectionDetails={setTransectionDetails}
            Details={transectionDetails}
          />
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5 me-2"
              onClick={() => handleTransectionDetails()}
              title="Cancel"
            >
              Cancel
            </Button>
            <Button variant="outline-danger px-5" title="Void">Void</Button>
          </>
        }
      />
    </>
  );
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    msg: state.inventory.msg,
    loading: state.inventory.loading,
    saleList: state.saleItem.saleList,
    device: state.inventory.devices,
    store: state.inventory.stores,
  };
};
export default connect(mapStateToprops)(WebOrdersTable);
