export const ITEMCHANNEl = "items_channel";
export const ITEM_EVENT = "items_event";
export const SETTINGCHANNEl = "setting_channel";
export const ADDITIONALFEE_EVENT = "additionalFee_event";
export const BOTTELDEPOSIT_EVENT = "bottelDeposit_event";
export const TAXITEMS_EVENT = "taxitems_event";
export const TAXRULE_EVENT = "taxrule_event";
export const ACCOUNTSETTING_EVENT = "accountSetting_event";
export const GERENALSETTING_EVENT = "generalSetting_event";
export const WEBSETTING_EVENT = "webSetting_event";
export const POSETTING_EVENT = "posSetting_event";
export const ADMIN_EVENT = "admin_event";
export const STORE_EVENT = "store_event";
export const CATEGORY_EVENT = "category_event";
export const CATEGORYGROUP_EVENT = "categoryGroup_event";
export const ITEMSIZE_EVENT = "itemSize_event";
export const ITEMTAG_EVENT = "itemTag_event";
export const ITEMSUPPLIER_EVENT = "itemSupplier_event";
export const PROMOTION_EVENT = "promotion_event";
