import React, { useRef, useEffect } from "react";
import { Button, Image } from "@themesberg/react-bootstrap";
import RippleButton from "./rippleButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

const CustomNumpad = (props) => {
  const box = useRef(null);
  useEffect(() => {
    // Function for click event
    function handleOutsideClick(event) {
      if (box.current && !box.current.contains(event.target)) {
        if (props.type !== "checkout" && props.type !== "customerScreenKeypad")
          props.hideNumpad(false);
      }
    }
    // Adding click event listener
    setTimeout(() => {
      document.addEventListener("click", handleOutsideClick);
    }, [1]);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [box, props]);

  const handleValue = (value) => {
    const data = {
      value: value,
      index: props.setIndex,
      type: props.type,
    };
    props.setValue(data);
  };

  const handleEvent = (e, btn, value) => {
    e.preventDefault();
    if (btn === "Enter") {
      props.hideNumpad(false);
      const data = {
        value: props?.type !== "DiscountKeypad" ? "" : "Enter",
        index: props.setIndex,
        type: props.type,
      };
      props.setValue(data);
    } else if (btn === "Clear") {
      const data = {
        value: btn,
        index: props.setIndex,
        type: props.type,
      };
      props.setValue(data);
    } else if (btn === "Cancel") {
      props.hideNumpad(value);
    } else if (btn === "SaleCancle") {
      props.handleCancle(value);
    } else if (btn === "ArrowRight" || btn === "ArrowLeft") {
      const data = {
        value: btn,
        index: props.setIndex,
        type: props.type,
      };
      props.setValue(data);
    }
  };
  const ReturnDisabled = (type) => {
    var cond = props.payment !== undefined && props.payment.length > 0;
    if (type === "Return") {
      return cond;
    } else if (type === "Cancle") {
      return !cond;
    }
  };
  return (
    <>
      <div className="custom-numberpad" ref={box}>
        <div className="MuiPaper-root sc-bZQynM fpKsPY MuiPaper-elevation1">
          <div className="sc-dnqmqq jeiWCh">
            <RippleButton text="7" setValue={handleValue}></RippleButton>
            <RippleButton text="8" setValue={handleValue}></RippleButton>
            <RippleButton text="9" setValue={handleValue}></RippleButton>
            <RippleButton text="4" setValue={handleValue}></RippleButton>
            <RippleButton text="5" setValue={handleValue}></RippleButton>
            <RippleButton text="6" setValue={handleValue}></RippleButton>
            <RippleButton text="1" setValue={handleValue}></RippleButton>
            <RippleButton text="2" setValue={handleValue}></RippleButton>
            <RippleButton text="3" setValue={handleValue}></RippleButton>

            {props.checkoutKeys === "checkout" ? (
              <>
                <RippleButton text="0" setValue={handleValue}></RippleButton>
                <RippleButton text="00" setValue={handleValue}></RippleButton>
                <RippleButton
                  className="clear"
                  text="C"
                  setValue={handleValue}
                ></RippleButton>
                <div className="bottom-cta mt-3">
                  <Button
                    variant="danger px-3"
                    onClick={(e) => handleEvent(e, "Enter", false)}
                    disabled={ReturnDisabled("Return")}
                    title="Go Back"
                  >
                    <Image
                      className="me-3"
                      src={props?.dynamicImages?.backBlue}
                      alt="Back Icon"
                    />
                    Go Back
                  </Button>
                  <Button
                    onClick={(e) => handleEvent(e, "SaleCancle", true)}
                    variant="danger px-3"
                    disabled={ReturnDisabled("Cancle")}
                    title="Cancel"
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : props.type === "customerScreenKeypad" ? (
              <>
                <RippleButton text="" setValue={handleValue}></RippleButton>
                <RippleButton text="0" setValue={handleValue}></RippleButton>
                <RippleButton
                  text=""
                  setValue={() => handleValue("Enter")}
                ></RippleButton>
              </>
            ) : (
              <>
                <RippleButton text="." setValue={handleValue}></RippleButton>
                <RippleButton text="0" setValue={handleValue}></RippleButton>
                <RippleButton text="-" setValue={handleValue}></RippleButton>
              </>
            )}
          </div>
          {props.checkoutKeys === "checkout" ||
          props.type === "customerScreenKeypad" ? (
            ""
          ) : (
            <div className="cta-btn">
              <Button
                variant="purple enter"
                  onClick={(e) => handleEvent(e, "Enter", false)}
                  title="Enter"
              >
                Enter
              </Button>
              <Button
                variant="primary clear"
                  onClick={(e) => handleEvent(e, "Clear", false)}
                  title="Clear"
              >
                Clear
              </Button>
              <Button
                variant="danger cancel"
                  onClick={(e) => handleEvent(e, "Cancel", false)}
                  title="Cancel"
              >
                Cancel
              </Button>
              <div className="d-flex arrow-wrapper">
                <Button
                  variant="white"
                    onClick={(e) => handleEvent(e, "ArrowLeft", false)}
                    title="Left Arrow"
                >
                  <FontAwesomeIcon color="#000" icon={faArrowLeft} />
                </Button>
                <Button
                  variant="white"
                    onClick={(e) => handleEvent(e, "ArrowRight", false)}
                    title="Right Arrow"
                >
                  <FontAwesomeIcon color="#000" icon={faArrowRight} />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

CustomNumpad.propTypes = {
  type: PropTypes.string, 
  setIndex: PropTypes.number,
  setValue: PropTypes.func,
  handleCancle: PropTypes.func,
  payment: PropTypes.array,
  checkoutKeys: PropTypes.string,
  dynamicImages: PropTypes.shape({
    backBlue: PropTypes.string, 
  }),
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    payment: state.saleItem.payment,
  };
};
export default connect(mapStateToprops)(CustomNumpad);
