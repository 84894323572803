import { Row, Spinner } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { SortableElement } from "react-sortable-hoc";
import ToDoItem from "./ToDoItem";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateGeneralSettings } from "app/actions/getListAction";
import {
  SETTING_CHANNEL,
  SETTING_EVENT,
  UPDATEPOSSETTING,
  ITEMUPDATEPUSHER,
  LOTTOPAYOUTTYPE,
  ONLINEPAYOUTTYPE,
  LOTTOSALETYPE,
  ONLINELOTTERYTYPE,
} from "app/helpers/constant";
import { ITEMCHANNEl, ITEM_EVENT } from "app/helpers/pusherConstant";
import { usePusher } from "context/PusherContext";
import { PusherUpdateFunction } from "app/helpers/custom";
import PropTypes from 'prop-types';

const SortableItem = SortableElement(ToDoItem);

const ToDoList = ({ items, dragOrDrop }) => {
  const isLottoButtonEnabled = useSelector(
    (state) => state.inventory?.posSetting?.data?.OPTION?.enable_lotto_buttons
  );
  const itemList = useSelector((state) => state.inventory?.itemlist);
  const lottoInformation = useSelector(
    (state) => state.inventory?.lottoInformation
  );

  const [displayItems, setDisplayItems] = useState([]);
  const dispatch = useDispatch();
  const pusher = usePusher();

  useEffect(() => {
    function childEventCallback(data) {
      const posSettings = JSON.parse(data?.data?.data);
      console.log("settings data", posSettings);
      if (data.type === "pos"){
        dispatch(updateGeneralSettings(posSettings, UPDATEPOSSETTING));
      }
    }
    const channel = pusher.subscribe(SETTING_CHANNEL);
    channel.bind(SETTING_EVENT, childEventCallback);
    return () => {
      channel.unbind(SETTING_EVENT, childEventCallback);
    };
  }, [pusher]);

  useEffect(() => {
    function childEventCallback(data) {
      console.log("outside");
      console.log("inside", data);
      const items = [...itemList];
      let listUpdate;
      if (data.method === "put") {
        console.log("inside1", data);
        listUpdate = PusherUpdateFunction("put", items, data?.data);
      } else if (data.method === "add") {
        console.log("inside2", data);
        listUpdate = PusherUpdateFunction("add", items, data.data);
      } else if (data.method === "delete") {
        console.log("inside3", data);
        listUpdate = items.filter((item) => item.id != data?.data?.id);
      }
      dispatch({
        type: ITEMUPDATEPUSHER,
        payload: { data: listUpdate },
      });
    }
    const channel = pusher.subscribe(ITEMCHANNEl);
    channel.bind(ITEM_EVENT, childEventCallback);
    return () => {
      channel.unbind(ITEM_EVENT, childEventCallback);
    };
  }, [itemList, pusher]);

  useEffect(() => {
    setDisplayItems(items?.filter(e => ![11, LOTTOPAYOUTTYPE, ONLINEPAYOUTTYPE, ONLINELOTTERYTYPE, LOTTOSALETYPE].includes(e.itemdetails.item_type)));
  }, [items]);

  return Object.entries(lottoInformation).length > 0 ? (
    <>
      <Row className="category-list sortable-list">
        {isLottoButtonEnabled === "1" && (
          <>
            <SortableItem
              name={lottoInformation?.lottopayout?.name}
              id={lottoInformation?.lottopayout?.id}
              index={lottoInformation?.lottopayout?.id}
              key={lottoInformation?.lottopayout?.id}
              disabled={dragOrDrop}
              itemtype={lottoInformation?.lottopayout?.item_type}
            />
            <SortableItem
              name={lottoInformation?.lottosale?.name}
              id={lottoInformation?.lottosale?.id}
              index={lottoInformation?.lottosale?.id}
              key={lottoInformation?.lottosale?.id}
              disabled={dragOrDrop}
              itemtype={lottoInformation?.lottosale?.item_type}
            />
            <SortableItem
              name={lottoInformation?.onlinelottery?.name}
              id={lottoInformation?.onlinelottery?.id}
              index={lottoInformation?.onlinelottery?.id}
              key={lottoInformation?.onlinelottery?.id}
              disabled={dragOrDrop}
              itemtype={lottoInformation?.onlinelottery?.item_type}
            />
            <SortableItem
              name={lottoInformation?.onlinepayout?.name}
              id={lottoInformation?.onlinepayout?.id}
              index={lottoInformation?.onlinepayout?.id}
              key={lottoInformation?.onlinepayout?.id}
              disabled={dragOrDrop}
              itemtype={lottoInformation?.onlinepayout?.item_type}
            />
          </>
        )}
        {displayItems.length > 0 &&
          displayItems.map((item) => (
            <SortableItem
              name={item?.itemdetails?.item_shortcut_name}
              color_code={item?.bgcolor}
              contentColor={item?.contentColor}
              id={item?.itemdetails?.id}
              index={item?.itemdetails?.id}
              key={item?.itemdetails?.id}
              disabled={dragOrDrop}
              itemtype={item?.itemdetails?.item_type}
              shortCutItem={true}
            />
          ))}
      </Row>
    </>
  ) : (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};

ToDoList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  dragOrDrop: PropTypes.bool,
};

export default ToDoList;
