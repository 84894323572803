import Pusher from "pusher-js";
const PUSHER_CONFIG = {
  app_id: "1561974",
  key: "8256f50f15a85d86b50a",
  secret: "a78f01456768d9cb07aa",
  cluster: "us2",
};

const pusherprops = new Pusher(PUSHER_CONFIG.key, {
  cluster: PUSHER_CONFIG.cluster,
  forceTLS: true,
});
export { pusherprops };
