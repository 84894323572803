import axios from "axios";
import { authHeader } from "./authHeader";
// Default config options
const defaultOptions = {
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
  },
};


// Create instance
const instance = axios.create(defaultOptions);
// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  const token = authHeader();
  config.headers = token ? token : "";
  return config;
});
export default instance;