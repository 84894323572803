import React, { useEffect, useState, lazy, Suspense } from "react";
import { Badge, Col, Image, ListGroup, Row } from "@themesberg/react-bootstrap";
import ReportsTabbing from "./reportsTabbing";
import LoaderSpinner from "../../components/Loader";
// Images
import cart from "../../assets/images/cart-arrow-down.svg";
import moneyOff from "../../assets/images/money-off.svg";
import banIcon from "../../assets/images/ban.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleUp,
  faArrowAltCircleDown,
} from "@fortawesome/free-regular-svg-icons";
import CashReconciliation from "./cashReconciliation";
import {
  checkUserPermission,
  getDeviceAndStore,
  getMasterStoreId,
  getSettingData,
  isUserAdminstrator,
} from "../../axios/authHeader";
import { connect, useDispatch } from "react-redux";
import {
  ACCOUNTSETTINGDATA,
  LOADING,
  REPORT,
  POSSETTINGDATA,
  REPORT_GET_LIST,
  GENERALSETIINGDATA,
  REGISTERREPORT,
  REGISTERCLOSINGTIME,
} from "../../app/helpers/constant";
import {
  getListActionFunction,
  getResponseAction,
  loadingAction,
} from "../../app/actions/getListAction";
import { currencyFormat } from "../../app/helpers/custom";
import { CommonPusherComponent } from "../components/commonPusherConponent";

import PrintLabel from "../printLabel";
import moment from "moment";

const Reports = (props) => {
  const [accordion, setAccordion] = useState(false);
  const [reportTabbing, setReportTabbing] = useState(0);
  const dispatch = useDispatch();
  const [possetting, setPosSetting] = useState(getSettingData(POSSETTINGDATA));
  const [accountsetting, setAccountSetting] = useState(
    getSettingData(ACCOUNTSETTINGDATA)
  );
  const [generalsetting, setGeneralSetting] = useState(
    getSettingData(GENERALSETIINGDATA)
  );
  const [loading , setLoading] = useState(true);
  const [totalCashbal, setTotalCash] = useState(parseFloat(0));
  const [graphData, setGraphData] = useState({
    saleNo: 0,
    saleTotal: 0,
    refundNo: 0,
    refundTotal: 0,
    voidNo: 0,
    voidTotal: 0,
    totalTaking: 0,
  });

  // useEffect(() => {
  //   getReportList();
  // }, []);

  useEffect(() => {
    getRegister();
  }, [reportTabbing]);

  const getRegister = async () => {
    const setting = getSettingData(POSSETTINGDATA);
    setPosSetting(setting);
    // let stime = await dispatch(
    //   getResponseAction(REGISTERCLOSINGTIME, props?.storeId, {
    //     last_closing:
    //       setting?.OPTION?.register_report_show_format == 1
    //         ? "1d"
    //         : setting?.OPTION?.register_report_show_format + "m",
    //   })
    // );
    let params = setting?.OPTION?.register_report_show_format
      ? setting?.OPTION?.register_report_show_format == 1
        ? "days"
        : "month"
      : "days";
    let stime = moment()
      .subtract(setting?.OPTION?.register_report_show_format, params).startOf("day")
      .valueOf();
    stime = stime ? stime : moment().startOf("day").valueOf();
    localStorage.setItem("accountClosingTime", stime);
    dispatch(
      getListActionFunction(
        REGISTERREPORT,
        { storeId: props?.storeId, stime },
        REGISTERREPORT
      )
    );
    getReportList();
  };

  useEffect(() => {
    window.addEventListener("storage", () => {
      setAccountSetting(getSettingData(ACCOUNTSETTINGDATA));
      setPosSetting(getSettingData(POSSETTINGDATA));
      setGeneralSetting(getSettingData(GENERALSETIINGDATA));
    });
  }, []);

  const getReportList = () => {
    const store = getDeviceAndStore();
    dispatch(loadingAction(true, LOADING));
    dispatch(
      getListActionFunction(REPORT, { storeId: store[1] }, REPORT_GET_LIST)
    );
  };

  useEffect(()=>{
    if (!window.navigator.onLine){
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    if (props?.graphReport?.graphData !== undefined) {
      // const data = (!checkUserPermission("reports_hide") || isUserAdminstrator()) ? props.graphReport.graphData : {};
      const data = props?.graphReport?.graphData;
      setGraphData({
        saleNo: data?.salesnum !== undefined ? data.salesnum : 0,
        saleTotal: data?.salestotal !== undefined ? data.salestotal : 0,
        refundNo: data?.refundnum !== undefined ? data.refundnum : 0,
        refundTotal: data?.refundtotal !== undefined ? data.refundtotal : 0,
        voidNo: data?.voidnum !== undefined ? data.voidnum : 0,
        voidTotal: data?.voidtotal !== undefined ? data.voidtotal : 0,
        totalTaking: data?.totaltakings !== undefined ? data.totaltakings : 0,
      });
      setLoading(false);
    }
  }, [props.graphReport]);

  return (
    <>
      <PrintLabel />

      <CommonPusherComponent />
      {isUserAdminstrator() ? (
        <>
          {loading && <LoaderSpinner />}
          <Row className="mb-3 todays-takings">
            <>
              <Col xs="12">
                <h1 className="mb-3">Today's Takings</h1>
              </Col>
              <Col xxl="9" xl="8">
                <ListGroup as="ol" horizontal>
                  <ListGroup.Item
                    variant="seagreen"
                    as="li"
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="ms-md-2 me-auto">
                      <div className="fw-bold">
                        Sales
                        <Badge pill>
                          <Image
                            src={props?.dynamicImages?.cart}
                            alt="list Icon"
                          />
                        </Badge>
                      </div>
                      <p>
                        {graphData.saleNo}
                        <span
                          className={graphData.saleTotal > 0 ? `up` : `down`}
                        >
                          {currencyFormat(graphData.saleTotal)}
                          <FontAwesomeIcon
                            color="#049E64"
                            icon={
                              graphData.saleTotal > 0
                                ? faArrowAltCircleUp
                                : faArrowAltCircleDown
                            }
                            className="ms-2"
                          />
                        </span>
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    variant="yellow"
                    as="li"
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="ms-md-2 me-auto">
                      <div className="fw-bold">
                        Refunds
                        <Badge pill>
                          <Image
                            src={props?.dynamicImages?.moneyOff}
                            alt="list Icon"
                          />
                        </Badge>
                      </div>
                      <p>
                        {graphData.refundNo}
                        <span
                          className={graphData.refundTotal > 0 ? `up` : `down`}
                        >
                          {currencyFormat(graphData.refundTotal)}
                          <FontAwesomeIcon
                            color="#3965D8"
                            icon={
                              graphData.refundTotal > 0
                                ? faArrowAltCircleUp
                                : faArrowAltCircleDown
                            }
                            className="ms-2"
                          />
                        </span>
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    variant="pink"
                    as="li"
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="ms-md-2 me-auto">
                      <div className="fw-bold">
                        Voids
                        <Badge pill>
                          <Image
                            src={props?.dynamicImages?.banIcon}
                            alt="list Icon"
                          />
                        </Badge>
                      </div>
                      <p>
                        {graphData.voidNo}
                        <span
                          className={graphData.voidTotal > 0 ? `up` : `down`}
                        >
                          {currencyFormat(graphData.voidTotal)}
                          <FontAwesomeIcon
                            color="#C81919"
                            icon={
                              graphData.voidTotal > 0
                                ? faArrowAltCircleUp
                                : faArrowAltCircleDown
                            }
                            className="ms-2"
                          />
                        </span>
                      </p>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    variant="primary"
                    as="li"
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="ms-md-2 me-auto">
                      <div className="fw-bold">
                        Takings
                        <Badge pill>
                          <Image
                            src={props?.dynamicImages?.cart}
                            alt="list Icon"
                          />
                        </Badge>
                      </div>
                      <p>
                        <span
                          className={graphData.totalTaking > 0 ? `up` : `down`}
                        >
                          {currencyFormat(graphData.totalTaking)}
                          <FontAwesomeIcon
                            color="#08A198"
                            icon={
                              graphData.totalTaking > 0
                                ? faArrowAltCircleUp
                                : faArrowAltCircleDown
                            }
                            className="ms-2"
                          />
                        </span>
                      </p>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </>
            <Col xxl="3" xl="4" className="position-relative">
              <CashReconciliation
                totalCash={setTotalCash}
                PosSetting={possetting}
                AccountSetting={accountsetting}
                accordion={setAccordion}
              />
            </Col>
            <Col
              xs={9}
              className={
                accordion ? "expanded report-wrapper" : "shrink report-wrapper"
              }
            >
              <ReportsTabbing
                setReportTabbing={setReportTabbing}
                recCashData={totalCashbal}
                GeneralSetting={generalsetting}
                AccountSetting={accountsetting}
              />
            </Col>
            {/* <Col xxl="3" xl="4">
          <CashReconciliation
            totalCash={setTotalCash}
            PosSetting={possetting}
            AccountSetting={accountsetting}
          />
        </Col> */}
          </Row>
        </>
      ) : (
        <>
          {loading && <LoaderSpinner />}
          <Row className="mb-3 todays-takings">
            {(!checkUserPermission("reports_hide") ||
              checkUserPermission("day_report_view")) && (
              <>
                <Col xs="12">
                  <h1 className="mb-3">Today's Takings</h1>
                </Col>
                <Col xxl="9" xl="8">
                  <ListGroup as="ol" horizontal>
                    <ListGroup.Item
                      variant="seagreen"
                      as="li"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="ms-md-2 me-auto">
                        <div className="fw-bold">
                          Sales
                          <Badge pill>
                            <Image
                              src={props?.dynamicImages?.cart}
                              alt="list Icon"
                            />
                          </Badge>
                        </div>
                        <p>
                          {graphData.saleNo}
                          <span
                            className={graphData.saleTotal > 0 ? `up` : `down`}
                          >
                            {currencyFormat(graphData.saleTotal)}
                            <FontAwesomeIcon
                              color="#049E64"
                              icon={
                                graphData.saleTotal > 0
                                  ? faArrowAltCircleUp
                                  : faArrowAltCircleDown
                              }
                              className="ms-2"
                            />
                          </span>
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      variant="yellow"
                      as="li"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="ms-md-2 me-auto">
                        <div className="fw-bold">
                          Refunds
                          <Badge pill>
                            <Image
                              src={props?.dynamicImages?.moneyOff}
                              alt="list Icon"
                            />
                          </Badge>
                        </div>
                        <p>
                          {graphData.refundNo}
                          <span
                            className={
                              graphData.refundTotal > 0 ? `up` : `down`
                            }
                          >
                            {currencyFormat(graphData.refundTotal)}
                            <FontAwesomeIcon
                              color="#3965D8"
                              icon={
                                graphData.refundTotal > 0
                                  ? faArrowAltCircleUp
                                  : faArrowAltCircleDown
                              }
                              className="ms-2"
                            />
                          </span>
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      variant="pink"
                      as="li"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="ms-md-2 me-auto">
                        <div className="fw-bold">
                          Voids
                          <Badge pill>
                            <Image
                              src={props?.dynamicImages?.banIcon}
                              alt="list Icon"
                            />
                          </Badge>
                        </div>
                        <p>
                          {graphData.voidNo}
                          <span
                            className={graphData.voidTotal > 0 ? `up` : `down`}
                          >
                            {currencyFormat(graphData.voidTotal)}
                            <FontAwesomeIcon
                              color="#C81919"
                              icon={
                                graphData.voidTotal > 0
                                  ? faArrowAltCircleUp
                                  : faArrowAltCircleDown
                              }
                              className="ms-2"
                            />
                          </span>
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      variant="primary"
                      as="li"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="ms-md-2 me-auto">
                        <div className="fw-bold">
                          Takings
                          <Badge pill>
                            <Image
                              src={props?.dynamicImages?.cart}
                              alt="list Icon"
                            />
                          </Badge>
                        </div>
                        <p>
                          <span
                            className={
                              graphData.totalTaking > 0 ? `up` : `down`
                            }
                          >
                            {currencyFormat(graphData.totalTaking)}
                            <FontAwesomeIcon
                              color="#08A198"
                              icon={
                                graphData.totalTaking > 0
                                  ? faArrowAltCircleUp
                                  : faArrowAltCircleDown
                              }
                              className="ms-2"
                            />
                          </span>
                        </p>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </>
            )}
            <Col
              xxl={
                checkUserPermission("reports_hide") &&
                !checkUserPermission("day_report_view")
                  ? "12"
                  : "3"
              }
              xl={
                checkUserPermission("reports_hide") &&
                !checkUserPermission("day_report_view")
                  ? "12"
                  : "4"
              }
              className="position-relative"
            >
              <CashReconciliation
                totalCash={setTotalCash}
                PosSetting={possetting}
                AccountSetting={accountsetting}
                accordion={setAccordion}
              />
            </Col>
            {(!checkUserPermission("reports_hide") ||
              checkUserPermission("day_report_view")) && (
              <Col
                xs={9}
                className={
                  accordion
                    ? "expanded report-wrapper"
                    : "shrink report-wrapper"
                }
              >
                <ReportsTabbing
                  setReportTabbing={setReportTabbing}
                  recCashData={totalCashbal}
                  GeneralSetting={generalsetting}
                  AccountSetting={accountsetting}
                />
              </Col>
            )}
            {/* <Col xxl="3" xl="4">
          <CashReconciliation
            totalCash={setTotalCash}
            PosSetting={possetting}
            AccountSetting={accountsetting}
          />
        </Col> */}
          </Row>
        </>
      )}
    </>
  );
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    storeId: state.auth.storeId,
    loading: state.inventory.loading,
    reportlist: state.inventory.reportList,
    graphReport: state.saleItem.graphData,
  };
};
export default connect(mapStateToprops)(Reports);
