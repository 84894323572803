import React, { useState } from "react";
import {
  Col,
  Row,
  Form,
  Button,
  FormCheck,
  Container,
  Image,
} from "@themesberg/react-bootstrap";
import FormField from "./components/formField";
import LoaderSpinner from "../components/Loader";
import { connect, useDispatch } from "react-redux";
import {
  loginAction,
  loadingToggleAction,
  intialSetError,
  INITIALSETPOPUP,
  INITIALSETERROR,
  INITIALPAXSETPOPUP,
  intialPaxSetError,
  INITIALBATCHPOPUP,
  INACTIVITY_LOGOUT,
} from "../app/actions/authAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { DeviceUUId } from "../axios/authHeader";

import { useEffect } from "react";
import {
  BUSINESS_VALIDATE,
  DEVICE,
  DEVICE_GET_LIST,
  GENERALSETTING,
  STORE,
  STORE_GET_LIST,
} from "../app/helpers/constant";
import {
  getListActionFunction,
} from "../app/actions/getListAction";
import {
  base64ToHex,
  diffrenceBetweenTwoTimeStamp,
  getBatchHistory,
  getDateFromTimestamp,
  GetLocalTotalReport,
  getLRC,
  hexToBase64,
  HexToString,
  parseBatchHistory,
  parseReportTotalResponse,
  StringToHex,
} from "../app/helpers/custom";
import { Base64 } from "js-base64";
import $ from "jquery";
import history from "../history/history";
import crypto from "crypto";
import LocalStorage from "../app/storage/Localstorage";
import Sessionstorage from "../app/storage/Sessionstorage";
import { Link, useLocation } from "react-router-dom";
import Localstorage from "../app/storage/Localstorage";
import getListService from "../app/services/getListService";
import ReCAPTCHA from "react-google-recaptcha-enterprise";

import CustomModal from "./components/Modals";
import InitialSetup from "./Initial-Setup/InitialSetup";
import PropTypes from 'prop-types';

const Login = (props) => {
  const recaptchaSiteKey = "6LeW1hIpAAAAAPbkHuz9viUhcdi-L8f5vEOOJ0-c";
  const location = useLocation();
  const [loader, setLoader] = useState(props?.loading);
  const [remember, setRememberMe] = useState(false);
  const [showPermissionError, setShowPermissionError] = useState(false);
  const [formData, setformData] = useState({ username: "", password: "" });
  const { username, password } = formData;
  const [show1, setShow1] = useState(!DeviceUUId());
  const [, setStatusCheck] = useState(1);
  const [offline, setOffline] = useState(false);
  const [darkMode, ] = useState(props?.darkMode);
  const [url, setUrl] = useState("");
  const [disableNextButton, setDisableNextButton] = useState(true);


  // pax config
  const [paxConfig, setPaxConfig] = useState({
    pax_ip: "",
    pax_port: "",
    pax_protocol: "",
  });
  let count = 0;

  const dispatch = useDispatch();
  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  // const fetchData = () => {
  //   dispatch(getListActionFunction(STORE, "", STORE_GET_LIST));
  // };

  // useEffect(() => {
  //   if (!props?.stores?.length) {
  //     fetchData();
  //   }
  // }, []);

  useEffect(() => {
    if (!showPermissionError) {
      setShowPermissionError(location?.state?.showPermissionError);
    }
  }, [location]);

  const handleChange = (e) => {
    if (!/^\s/.test(e.target.value)) {
      setformData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    function updateOnlineStatus() {
      var condition = navigator.onLine ? 1 : 2;
      setStatusCheck(condition);
    }
  }, []);

  useEffect(() => {
    props?.storeId &&
      dispatch(
        getListActionFunction(
          GENERALSETTING,
          props.storeId,
          GENERALSETTING,
          undefined
        )
      );
  }, [props.storeId]);

  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);
  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  // const handleErrorMsg = () => {
  //   dispatch(intialSetError(false, INITIALSETPOPUP));
  //   dispatch(intialSetError(false, INITIALSETERROR));
  //   setformData({ username: "", password: "" });
  //   localStorage.removeItem("pos_user");
  //   sessionStorage.removeItem("timeout", "yes");
  // };

  function generatePasswordToken(password) {
    // Generate a SHA-256 hash of the password
    const hash = crypto.createHash("sha256").update(password).digest("hex");
    // Return the hash value as the password token
    return hash;
  }

  const SubmitHandler = (e) => {
    e.preventDefault();
    count = 0;
    if (username) {
      if (password) {
        if (window.navigator.onLine) {
          const master_store_id = Localstorage.getItem("master_store_id");
          const store_id = Localstorage.getItem("store_id");
          // getLastBatchTotal();
          if (localStorage.getItem("setupData") !== null) {
            count++;
            InitializePax(paxConfig);
            getLastBatchTotal(paxConfig);
          }
          dispatch(loadingToggleAction(true));
          dispatch(
            loginAction(username, password, store_id, master_store_id, remember)
          );
        } else {
          if (localStorage.getItem("setupData") !== null) {
            InitializePax(paxConfig);
          }
          setOffline(true);
        }
      } else {
        toast.error("Password is required");
      }
    } else {
      toast.error("Username is required");
    }
  };

  const handleOfflineLogin = () => {
    setOffline(false);
    const wpos_auth = localStorage.getItem("wpos_auth");
    const offlineUser = JSON.parse(wpos_auth);
    const hashPassword = generatePasswordToken(offlineUser.admin.token);
    console.log(`hashPassword`, hashPassword);
    if (
      offlineUser.admin.data.username == username &&
      offlineUser.admin.token != null
    ) {
      console.log("system offline mode");
      const resData = {
        data: offlineUser.admin.data,
        token: offlineUser.admin.token,
        logtime: new Date().toLocaleString(),
      };
      if (resData) {
        LocalStorage.setItem("user", resData);
        Sessionstorage.setItem("timeout", "yes");
      }
      history.push({
        pathname: "/pos",
      });
    } else {
      console.log("no data in localstorage");
    }
  };

  // Get last batch total
  const getLastBatchTotal = (paxConfig) => {
    const settings = GetLocalTotalReport(paxConfig);
    $.ajax(settings)
      .done(function (reportres) {
        const reportResponse = parseReportTotalResponse(reportres);
        console.log(`reportResponse`, reportResponse);
        if (
          reportResponse.hasOwnProperty("TotalCount") &&
          reportResponse.TotalCount > 0
        ) {
          getBatchHistoryReport(paxConfig);
        }
      })
      .fail(function (jqXHR) {
        var errormessage;
        if (jqXHR.status === 0) {
          errormessage = "Could not connect.\n Verify your network.";
        } else if (jqXHR.status == 404) {
          errormessage = "Requested page not found. [404]";
        } else if (jqXHR.status == 500) {
          errormessage = "Internal Server Error [500].";
        } else {
          errormessage = "Uncaught Error.\n" + jqXHR.responseText;
        }
      });
  };

  // Get last batch transactions
  const getBatchHistoryReport = (paxConfig) => {
    const settings = getBatchHistory(paxConfig);
    $.ajax(settings)
      .done(function (reportres) {
        const reportResponse = parseBatchHistory(reportres);
        console.log(`reportResponse`, reportResponse);

        if (reportResponse.hasOwnProperty("StartTime")) {
          // var yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
          var BatchStartTime = new Date(
            reportResponse.StartTime.replace(
              /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
              "$4:$5:$6 $2/$3/$1"
            )
          );
          let diff = diffrenceBetweenTwoTimeStamp(
            new Date().getTime(),
            BatchStartTime.getTime()
          );
          let splitDiff = diff.split(":");
          let hourDiff =
            splitDiff !== null &&
              splitDiff instanceof Array &&
              splitDiff.length > 0
              ? parseInt(splitDiff[0])
              : 0;

          if (hourDiff >= 24) {
            // var boxtittle = "Close Register";
            var message =
              "Batch on the Pinpad has not been closed since " +
              getDateFromTimestamp(BatchStartTime.getTime(), null, null, "12") +
              " , Do you want to close the batch?";
            console.log(`alert`, message);
            dispatch(intialPaxSetError(message, INITIALBATCHPOPUP));

            // WPOS.util.confirmDialog(message,boxtittle,function(res){
            //     if(res){
            //         WPOS.reports.closePaxBatch()
            //     }
            // });
          }
        }
      })
      .fail(function (jqXHR) {
        var errormessage;
        if (jqXHR.status === 0) {
          errormessage = "Could not connect.\n Verify your network.";
        } else if (jqXHR.status == 404) {
          errormessage = "Requested page not found. [404]";
        } else if (jqXHR.status == 500) {
          errormessage = "Internal Server Error [500].";
        } else {
          errormessage = "Uncaught Error.\n" + jqXHR.responseText;
        }
      });
    // var commandType = "";
  };

  const getIpAddress = (devices) => {
    const setupData = JSON.parse(localStorage.getItem("setupData"));
    let paxData = {
      pax_ip: "",
      pax_port: "",
      pax_protocol: "",
    };
    console.log("props?.device", devices);
    if (devices.length > 0 && setupData != undefined) {
      const paxDetails = devices.filter((item) => item.id === setupData.device);
      if (
        paxDetails != undefined &&
        (paxDetails != "") & (paxDetails != null)
      ) {
        paxData = {
          pax_ip: paxDetails[0].pax_ip,
          pax_port: paxDetails[0].pax_port,
          pax_protocol: paxDetails[0].pax_protocol,
        };
      }
    }
    InitializePax(paxData);
  };

  const InitializePax = (paxConfig) => {
    var initialInfo = {
      command: "A00",
      version: "1.28",
    };
    var PAX = {
      mStx: {
        hex: 0x02,
        code: "02",
      },

      mFS: {
        hex: 0x1c,
        code: "1c",
      },

      mEtx: {
        hex: 0x03,
        code: "03",
      },

      mUS: {
        hex: 0x1f,
        code: "1F",
      },
      // mDestinationIP: "http://127.0.0.1:10009",
      mDestinationIP: `${paxConfig.pax_protocol == ""
        ? "http"
        : paxConfig.pax_protocol == "1"
          ? "http"
          : "https"
        }://${paxConfig.pax_ip == "" ? "127.0.0.1" : paxConfig.pax_ip}:${paxConfig.pax_port == "" ? "10009" : paxConfig.pax_port
        }`,
      timeout: {
        Initialize: 120 * 1000,
        GetSignature: 120 * 1000,
        DoSignature: 120 * 1000,
        DoCredit: 120 * 1000,
      },
    };
    var params = [
      PAX.mStx.hex,
      initialInfo.command,
      PAX.mFS.hex,
      initialInfo.version,
      PAX.mEtx.hex,
    ];
    //[02]A08[1c]1.28[1c]0[1c]90000[03]
    //var params = [0x02,"A08",0x1c,"1.28",0x1c, "0", 0x1c,"90000",0x03];
    var lrc = getLRC(params);

    var command_hex = base64ToHex(Base64.btoa(initialInfo.command));
    var version_hex = base64ToHex(Base64.btoa(initialInfo.version));
    //var elements = [this.mStx, command_hex, this.mFS, version_hex, this.mEtx, base64ToHex(Base64.btoa(lrc))];
    var elements = [
      PAX.mStx.code,
      command_hex,
      PAX.mFS.code,
      version_hex,
      PAX.mEtx.code,
      base64ToHex(Base64.btoa(lrc)),
    ];

    var final_string = elements.join(" ");
    //console.log("final_string: " + final_string);

    var final_b64 = hexToBase64(final_string);
    console.log("LRC: " + lrc);
    console.log("Base64: " + final_b64);
    var url = PAX.mDestinationIP + "?" + final_b64;
    console.log("URL: " + url);

    HttpCommunication(
      "Initialize",
      url,
      async function (response) {
        console.log(`pax response`, response);
        if (response == "error") {
          if (count === 1) {
            count++;
            dispatch(
              getListActionFunction(DEVICE, props.storeId, DEVICE_GET_LIST)
            );
            const devices = await getListService.ListData(
              DEVICE,
              props.storeId
            );
            console.log("devices", devices);
            getIpAddress(devices?.data);
          } else {
            console.log("pax not connected");
            dispatch(intialPaxSetError(true, INITIALPAXSETPOPUP));
          }
        } else {
          dispatch(intialPaxSetError(false, INITIALPAXSETPOPUP));
          console.log("pax connected");
        }
        // callback(response);
      },
      PAX.timeout.Initialize
    );
  };

  const HttpCommunication = (commandType, url, callback, timeout) => {
    $.ajax({
      url: url,
      timeout: timeout,
      error: function (xmlHttpRequest, error) {
        // setTitle("Error");
        // setErrorMsg("Please Manually Power Cycle the Card machine and Relogin");
        // setPaxMsg(true);
        console.log("Ajax error info: " + error);
        callback(error);
        // setShow(PAXPOPUP);
        // setPaxError("Error");
      },
      success: function (response) {
        console.log("success");
        console.log("Raw response: " + response);

        var checkParams = StringToHex(response).split(" ").pop();
        var RedundancyCheck = StringToHex(response)
          .split(" ")
          .pop()
          .substring(1);

        var check = getLRC(checkParams);

        if (check == RedundancyCheck) {
          //get package detail info
          var packetInfo = [];
          var len = StringToHex(response).indexOf("03");
          var hex = StringToHex(response).slice(0, len).split(/02|1c/);

          console.log(hex);
          if (commandType == "DoCredit") {
            var subHex = [],
              subPacketInfo = [];
            for (var i = 0; i < hex.length; i++) {
              if (hex[i] != "") {
                if (hex[i].indexOf("1f") > 0) {
                  subHex = hex[i].split("1f");
                  console.log(subHex);
                  subPacketInfo = [];
                  for (var j = 0; j < subHex.length; j++) {
                    if (subHex[j] != "") {
                      subPacketInfo.push(HexToString(subHex[j]));
                    }
                  }
                  console.log(subPacketInfo);
                  packetInfo.push(subPacketInfo);
                } else {
                  packetInfo[i] = HexToString(hex[i]);
                }
              }
            }
          } else {
            for (let i = 0; i < hex.length; i++) {
              if (hex[i] != "") {
                packetInfo[i] = HexToString(hex[i]);
              }
            }
          }

          console.log("Separate package info: ");
          console.log(packetInfo);
          callback(packetInfo);
        }
      },
    });
  };

  const updateDevices = (devices) => {
    const setupData = JSON.parse(localStorage.getItem("setupData"));
    console.log(`setupData`, devices);
    if (devices.length > 0 && setupData != undefined) {
      const paxDetails = devices.filter((item) => item.id === setupData.device);
      console.log(`paxDetails`, paxDetails);
      if (
        paxDetails != undefined &&
        (paxDetails != "") & (paxDetails != null)
      ) {
        setPaxConfig({
          pax_ip: paxDetails[0].pax_ip,
          pax_port: paxDetails[0].pax_port,
          pax_protocol: paxDetails[0].pax_protocol,
        });
      }
    }
  };

  useEffect(() => {
    updateDevices(props.devices);
  }, [props.devices]);

  // Logic for pre sign
  const [formData1, setformData1] = useState({ StoreName: "" });
  const { StoreName } = formData1;
  const [showSignIn, setShowSignIn] = useState(false);

  const [showPreSignIn, setPreShowSignIn] = useState(false);


  useEffect(() => {
    let businessNameCached = localStorage.getItem("StoreName");
    let store_id = localStorage.getItem("storeId");
    let master_store_id = localStorage.getItem("master_store_id");
    if (
      businessNameCached &&
      businessNameCached != null &&
      businessNameCached !== "null" &&
      businessNameCached.length &&
      store_id &&
      store_id != null &&
      store_id !== "null" &&
      store_id.length &&
      master_store_id &&
      master_store_id != null &&
      master_store_id !== "null" &&
      master_store_id.length
    ) {
      setPreShowSignIn(false);
      setShowSignIn(true);
      setformData1({ StoreName: businessNameCached });
    } else {
      setPreShowSignIn(true);
      setShowSignIn(false);
    }
  }, []);

  const getSlug = (value) => {
    if (value) {
      let replaceVal = value.toLowerCase().replace(/ /g, "-");
      return replaceVal;
    } else {
      return value;
    }
  };

  const getDeviceList = (store) => {
    dispatch(getListActionFunction(DEVICE, store, DEVICE_GET_LIST));
  };

  useEffect(() => {
    if (setShowSignIn) {
      let store_id = Localstorage.getItem("store_id");
      if (store_id) {
        getDeviceList(store_id);
      }
    }
  }, [setShowSignIn]);

  const SubmitHandler1 = (e) => {
    e.preventDefault();
    if (!disableNextButton) {
      if (!formData1.StoreName) {
        toast.error("Enter your Store Name");
      } else {
        setLoader(true);
        getListService
          .checkSingleRow(BUSINESS_VALIDATE, {
            name: formData1.StoreName,
          })
          .then((response) => {
            if (response?.status == 200 && response?.data?.exist) {
              setShowSignIn(true);
              setPreShowSignIn(false);
              // Save the business name in the cache
              localStorage.setItem("StoreName", formData1.StoreName);
              localStorage.setItem("store_id", response?.data?.exist?.id);
              localStorage.setItem("storeId", response?.data?.exist?.id);
              Localstorage.setItem(
                "master_store_id",
                response?.data?.exist?.parent
              );
              getDeviceList(response?.data?.exist?.id);
            } else {
              toast.error("Store doesn't exist");
            }
            setLoader(false);
          })
          .catch((err) => {
            toast.error("Something went wrong");
          });

        // Update the URL
        const currentUrl = window.location.href;
        const getUrlSlug = getSlug(formData1.StoreName);
        const newUrl = `${getUrlSlug}`;
        window.history.pushState(null, "", newUrl);
        setUrl(newUrl);
      }
    }
  };
  const handleChange1 = (e) => {
    setformData1({ ...formData1, StoreName: e.target.value });
  };

  const handleLinkClick = () => {
    // Clear the business name field
    setformData1({ StoreName: "" });
    // Remove the added business name from the URL
    const currentUrl = window.location.href;
    const newUrl = currentUrl.replace(/[^/]*$/, "");
    window.history.replaceState({}, "", newUrl);
    Localstorage.removeItem("setupData");
    Localstorage.removeItem("uuid");
    setPreShowSignIn(true);
    setShowSignIn(false);
  };

  const onChangereCaptcha = async (e) => {
    if (e) {
      setDisableNextButton(false)
    } else {
      setDisableNextButton(true)
    }
  };

  const [initialSetUp, setInitaialSetUp] = useState(false);

  useEffect(() => {
    if (props.intialSetpop) {
      setInitaialSetUp(props?.intialSetpop);
      setShowSignIn(false);
      setPreShowSignIn(false);
    }
  }, [props.intialSetpop]);

  const handleAdminstratorLogin = () => {
    setShowSignIn(true);
    setPreShowSignIn(false);
    setInitaialSetUp(false);
  };

  const handleInactivityPopup = () => {
    dispatch({
      type: INACTIVITY_LOGOUT,
      data: false,
    });
  };

  return (
    <main>
      {loader && <LoaderSpinner />}
      <section className="d-flex align-items-center vh-100 login">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light px-4 py-5  w-100">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <Image
                    width="300"
                    className="logo"
                    src={
                      !darkMode
                        ? props?.dynamicImages?.logo1
                        : props?.dynamicImages?.logoWhite
                    }
                    alt="Logo"
                  />
                </div>
                {/* Pre sign in */}
                {showPreSignIn && (
                  <Form
                    className="mt-4 pre-sign-in"
                    onSubmit={(e) => SubmitHandler1(e)}
                  >
                    <Form.Group className="form-floating mb-4 store-info">
                      <FormField
                        label="Store Name"
                        type="text"
                        name="StoreName"
                        value={StoreName}
                        placeholder="StoreName"
                        autoComplete="off"
                        onChange={(e) => handleChange1(e)}
                      />
                    </Form.Group>

                    <ReCAPTCHA
                      sitekey={recaptchaSiteKey}
                      onChange={onChangereCaptcha}
                      onExpired={() => setDisableNextButton(true)}
                    />

                    <Button
                      variant="blue"
                      type="submit"
                      className="w-100 mt-4"
                      disabled={loader || disableNextButton}
                    >
                      {props.loading ? `Please wait..` : `Next`}
                    </Button>
                  </Form>
                )}

                {showSignIn && (
                  <div className="show-sign-in">
                    {/* <h2 className="font-weight-bold">
                      Login
                      <Link onClick={handleLinkClick}>Not your Store?</Link>
                    </h2> */}
                    <h4>{StoreName}</h4>
                    {/* Displaying the filled business name */}
                    <Form className="mt-4" onSubmit={(e) => SubmitHandler(e)}>
                      <Form.Group className="form-floating mb-4">
                        <FormField
                          label="Username"
                          type="text"
                          name="username"
                          value={username}
                          placeholder="Enter Username"
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Group>
                      <Form.Group className="form-floating mb-4 ">
                        <FontAwesomeIcon
                          onClick={togglePassword}
                          className="eye-icon"
                          icon={passwordShown ? faEye : faEyeSlash}
                        />
                        <FormField
                          label="Password"
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          value={password}
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter Password"
                        />
                      </Form.Group>
                      <Link onClick={handleLinkClick}>Not your Store?</Link>
                      <div className="d-flex justify-content-between align-items-center mb-4 ">
                        <Form.Check className="custom-checkbox" type="checkbox">
                          <FormCheck.Input
                            id="defaultCheck5"
                            name="remember_me"
                            onChange={(e) => handleRememberMe(e)}
                            className="me-2 custom-checkbox-bg"
                          />
                          <FormCheck.Label
                            htmlFor="defaultCheck5"
                            className="mb-0"
                          >
                            Remember me
                          </FormCheck.Label>
                        </Form.Check>
                      </div>
                      <Button
                        variant="blue"
                        type="submit"
                        className="w-100"
                        disabled={props.loading ? true : false}
                      >
                        {props.loading ? `Please wait..` : `Login`}
                      </Button>
                    </Form>
                  </div>
                )}

                {initialSetUp && (
                  <InitialSetup
                    handleAdminstratorLogin={handleAdminstratorLogin}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {showSignIn && !DeviceUUId() && (
        <CustomModal
          open={show1}
          size="md"
          handleClose={() => setShow1(false)}
          dialogClassName="custom-modal confirmation"
          title="Device Setup Alert"
          content={
            <h1>
              The device has not been setup yet, please login as an
              administrator to setup the device.
            </h1>
          }
          action={
            <Button
              type="button"
              onClick={() => setShow1(false)}
              variant="primary px-5"
              title="Ok"
            >
              OK
            </Button>
          }
        />
      )}
      {showPermissionError && (
        <CustomModal
          open={showPermissionError}
          size="lg"
          handleClose={() => setShowPermissionError(false)}
          dialogClassName="custom-modal confirmation error-popup"
          title="Error"
          content={
            <h1 className="mb-3">
              Sorry you dont have permission to access with the already setup
              store
            </h1>
          }
          action={
            <Button
              type="button"
              onClick={() => setShowPermissionError(false)}
              variant="danger px-5 m-auto"
            >
              OK
            </Button>
          }
        />
      )}

      {offline === true && (
        <CustomModal
          open={offline}
          size="md"
          handleClose={handleOfflineLogin}
          dialogClassName="custom-modal confirmation intital-device-setup"
          title="Offline"
          content={
            <h5>
              Your internect connection is not active and POS has started in
              Offline mode. Some features are not available in offline mode but
              you can always make sales and alter transactions that are locally
              available. When a connection becomes available POS will process
              your transactions on server.
            </h5>
          }
          action={
            <Button
              type="button"
              onClick={handleOfflineLogin}
              variant="danger px-5 m-auto"
            >
              OK
            </Button>
          }
        />
      )}

      <CustomModal
        open={props?.inactivity_logout}
        size="md"
        handleClose={() => handleInactivityPopup()}
        dialogClassName="custom-modal confirmation intital-device-setup"
        title="Logout"
        content={
          <h1 className="pb-5">
            You've been inactive for a while, so we've logged you out . Please
            login again
          </h1>
        }
        action={
          <Button
            type="button"
            onClick={() => handleInactivityPopup()}
            variant="primary px-5"
          >
            OK
          </Button>
        }
      />
    </main>
  );
};

Login.propTypes = {
  inactivity_logout: PropTypes.bool,
  // loading: PropTypes.bool,
  dynamicImages: PropTypes.shape({
    logo1: PropTypes.string,
    logoWhite: PropTypes.string,
  }),
};
const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    loading: state.auth.showloading,
    initialError: state.auth.initialError,
    intialSetpop: state.auth.initialpop,
    storeId: state.auth.storeId,
    general_setting: state.setting.generalSetting,
    inactivity_logout: state.auth.inactivity_logout,
    devices: state.inventory.devices,
    darkMode: state.setting.darkmode,
    stores: state.inventory.stores,
  };
};

export default connect(mapStateToprops)(Login);
