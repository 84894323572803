import React, { useEffect, useState } from "react";
import { Button, Card, Tab, Nav, Image } from "@themesberg/react-bootstrap";
import TakingCountReport from "./takingCountReportTable";
import WhatsSelling from "./whatsSelling";
import SellerReports from "./sellerReports";
import RegisterReports from "./registerReports";
import DayReports from "./dayReports";
import moment from "moment-timezone";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { connect, useDispatch } from "react-redux";
import {
  authUserName,
  checkUserPermission,
  getDeviceAndStoreName,
  getLoginTime,
  isUserAdminstrator,
} from "../../axios/authHeader";
import {
  todayDate,
  FirtsLetterUpperCase,
  getStartTimeInReport,
} from "../../app/helpers/custom";
import { DAYREPORT } from "../../app/helpers/constant";
import { getListActionFunction } from "../../app/actions/getListAction";
import CustomModal from "../components/Modals";

const ReportsTabbing = (props) => {
  const dispatch = useDispatch();
  const [bussinessName, setBussinessName] = useState("Bussiness Name");
  const [dateRange, setDateRange] = useState({
    start: moment().startOf("day").add(30, "minute").toDate(),
    end: moment().endOf("day").subtract(59, "second").toDate(),
  });
  const [printClick, setPrintClick] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (props?.GeneralSetting?.BUSINESS !== undefined) {
      setBussinessName(
        props?.GeneralSetting?.BUSINESS?.business_name ??
          props?.GeneralSetting?.BUSINESS?.business_name
      );
    }
  }, [props.GeneralSetting]);

  useEffect(() => {
    getDayReport();
  }, []);

  const handleCallback = (start, end) => {
    if (window.navigator.onLine == false){
      setError({
        title: "Error!",
        msg: `Sorry Cannot View Day Reports While Offline.`,
      })
    } else{
      dispatch(
        getListActionFunction(
          DAYREPORT,
          { start: new Date(start).getTime(), end: new Date(end).getTime() },
          DAYREPORT
        )
      );
      setDateRange({ start: start, end: end });
    }
  };

  const getDayReport = () => {
    dispatch(
      getListActionFunction(
        DAYREPORT,
        {
          start: new Date(dateRange.start).getTime(),
          end: new Date(dateRange.end),
        },
        DAYREPORT
      )
    );
  };

  const checkOnlineStatus = (type) => {
    if (window.navigator.onLine == false) {
      setError({
        title: "Error!",
        msg: `Sorry Cannot View ${type} Reports While Offline.`,
      });
      return false;
    }
  };

  const Details = () => {
    return (
      <>
        <p>
          {todayDate("-", false)} -
          {FirtsLetterUpperCase(getDeviceAndStoreName(true)[0])} -
          {FirtsLetterUpperCase(getDeviceAndStoreName(true)[1])}
        </p>
      </>
    );
  };
  const UserDetail = () => {
    return (
      <>
        {moment(getStartTimeInReport()).format("MM/DD/YY HH:mm:ss")}-{" "}
        {moment().format("MM/DD/YY HH:mm:ss")}-
        {FirtsLetterUpperCase(bussinessName)} -
        {FirtsLetterUpperCase(authUserName())}
      </>
    );
  };

  return (
    <>
      <Card className="custom-card">
        <Card.Header
          style={{ borderBottom: "none" }}
          className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center py-3"
        >
          <h2>Reports</h2>
        </Card.Header>
        <Card.Body>
          <Tab.Container
            defaultActiveKey={
              !checkUserPermission("reports_hide") || isUserAdminstrator()
                ? "Count"
                : "Day"
            }
          >
            <Nav
              fill
              variant="pills"
              className="custom-tab-nav mx-auto flex-sm-row w-100 mt-2"
            >
              {(!checkUserPermission("reports_hide") ||
                isUserAdminstrator()) && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="Count"
                      className="mb-sm-3 mb-md-0"
                      onClick={() => props.setReportTabbing(Math.random())}
                    >
                      <Image
                        className="me-2"
                        src={props?.dynamicImages?.takingsCOunt}
                        alt="takingsCOunt Icon"
                      />
                      Takings Count
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="Selling"
                      className="mb-sm-3 mb-md-0"
                      onClick={() => props.setReportTabbing(Math.random())}
                    >
                      <Image
                        className="me-2"
                        src={props?.dynamicImages?.whatsSelling}
                        alt="whatsSelling Icon"
                      />
                      What's Selling
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="Report"
                      className="mb-sm-3 mb-md-0"
                      onClick={() => props.setReportTabbing(Math.random())}
                    >
                      <Image
                        className="me-2"
                        src={props?.dynamicImages?.sellerReports}
                        alt="sellerReports Icon"
                      />
                      Seller Report
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="Register"
                      className="mb-sm-3 mb-md-0"
                      onClick={() => {
                        checkOnlineStatus("Register");
                        props.setReportTabbing(Math.random());
                      }}
                    >
                      <Image
                        className="me-2"
                        src={props?.dynamicImages?.registerReports}
                        alt="registerReports Icon"
                      />
                      Register Report
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}

              {isUserAdminstrator() ? (
                <Nav.Item>
                  <Nav.Link
                    eventKey="Day"
                    className="mb-sm-3 mb-md-0"
                    onClick={() => {
                      checkOnlineStatus("Day");
                      getDayReport();
                    }}
                  >
                    <Image
                      className="me-2"
                      src={props?.dynamicImages?.dayReport}
                      alt="dayReport Icon"
                    />
                    Day Report
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <>
                  {checkUserPermission("day_report_view") && (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Day"
                        className="mb-sm-3 mb-md-0"
                        onClick={() => {
                          checkOnlineStatus("Day");
                          getDayReport();
                        }}
                      >
                        <Image
                          className="me-2"
                          src={props?.dynamicImages?.dayReport}
                          alt="dayReport Icon"
                        />
                        Day Report
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </>
              )}
            </Nav>
            <Tab.Content className="custom-data-table p-3">
              <Tab.Pane eventKey="Count">
                <div className="d-flex align-items-center">
                  <h2>Takings Count Report</h2>
                  <Details />
                </div>
                <TakingCountReport />
              </Tab.Pane>
              <Tab.Pane eventKey="Selling" className="stats">
                <div className="d-flex align-items-center">
                  <h2>What's Selling Report</h2>
                  <Details />
                </div>
                <WhatsSelling />
              </Tab.Pane>
              <Tab.Pane eventKey="Report">
                <div className="d-flex align-items-center">
                  <h2>Seller Takings</h2>
                  <Details />
                </div>
                <SellerReports />
              </Tab.Pane>
              <Tab.Pane eventKey="Register">
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center">
                  <div className="d-flex align-items-center">
                    <h2>Current Shift Report</h2>
                    <p>
                      <UserDetail />
                    </p>
                  </div>
                  {window.navigator.onLine && (<Button
                    className="print"
                    variant="outline-blackcustom"
                    type="submit"
                    onClick={() => setPrintClick("register")}
                    title="Print"
                  >
                    <Image
                      className="me-2"
                      src={props?.dynamicImages?.print}
                      alt="print Icon"
                    />
                    Print
                  </Button>)}
                </div>
                {window.navigator.onLine && (
                  <RegisterReports
                    totalCash={props?.recCashData}
                    AccountSetting={props?.AccountSetting}
                    printClick={printClick}
                    setPrintClick={setPrintClick}
                  />
                )}
              </Tab.Pane>

              <Tab.Pane eventKey="Day">
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center">
                  <div className="d-flex align-items-center">
                    <h2>{FirtsLetterUpperCase(bussinessName)} Day Report</h2>
                    <p>
                      {moment(dateRange.start).format(
                        "MMMM DD YYYY, h:mm:ss A"
                      )}
                      -{moment(dateRange.end).format("MMMM DD YYYY, h:mm:ss A")}
                    </p>
                  </div>
                  <div className="d-flex date-range-wrapper">
                    <label className="me-2 mb-2">Range</label>
                    <div className="d-flex flex-column flex-sm-row">
                      <DateRangePicker
                        initialSettings={{
                          ranges: {
                            Today: [
                              moment()
                                .startOf("day")
                                .add(30, "minute")
                                .toDate(),
                              moment()
                                .endOf("day")
                                .subtract(59, "second")
                                .toDate(),
                            ],
                            Yesterday: [
                              moment()
                                .subtract(1, "days")
                                .startOf("day")
                                .add(30, "minute"),
                              moment()
                                .subtract(1, "days")
                                .endOf("day")
                                .subtract(59, "second"),
                            ],
                            "Last 7 Days": [
                              moment()
                                .subtract(6, "days")
                                .startOf("day")
                                .add(30, "minute"),
                              moment().endOf("day").subtract(59, "second"),
                            ],
                            "Last 30 Days": [
                              moment().subtract(30, "days").add(30, "minute"),
                              moment().endOf("day").subtract(59, "second"),
                            ],
                            "This Month": [
                              moment()
                                .startOf("month")
                                .startOf("day")
                                .add(30, "minute"),
                              moment()
                                .endOf("month")
                                .endOf("day")
                                .subtract(59, "second"),
                            ],
                            "This Year": [
                              moment()
                                .startOf("year")
                                .startOf("day")
                                .add(30, "minute"),
                              moment()
                                .endOf("month")
                                .endOf("day")
                                .subtract(59, "second"),
                            ],
                            "Last Month": [
                              moment()
                                .subtract(1, "month")
                                .subtract(29, "day")
                                .startOf("day")
                                .add(30, "minute"),
                              moment()
                                .subtract(1, "month")
                                .endOf("day")
                                .subtract(59, "second"),
                            ],
                          },
                          alwaysShowCalendars: true,
                          // showDropdowns: true,
                          opens: "center",
                          timePicker: true,
                          startDate: dateRange.start,
                          endDate: dateRange.end,
                          locale: {
                            format: "MM/DD/YYYY hh:mm A",
                            cancelLabel: "Clear",
                          },
                        }}
                        onCallback={handleCallback}
                      >
                        <input type="text" className="form-control" />
                      </DateRangePicker>

                      {window.navigator.onLine && (<Button
                        className="print"
                        variant="outline-blackcustom"
                        type="submit"
                        onClick={() => setPrintClick("day")}
                        title="Print"
                      >
                        <Image
                          className="me-2 "
                          src={props?.dynamicImages?.print}
                          alt="print Icon"
                        />
                        Print
                      </Button>)}
                    </div>
                  </div>
                </div>
                {window.navigator.onLine && (<DayReports
                  printClick={printClick}
                  setPrintClick={setPrintClick}
                  dateRange={dateRange}
                />)}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
      {/* error */}
      <CustomModal
        open={error}
        handleClose={() => setError(false)}
        dialogClassName="custom-modal without-save-btn clock-in with-border-bottom modal-clock-pass"
        title={error.title}
        content={error.msg}
        action={
          <>
            <Button
              variant="outline-seagreen px-5 me-2"
              onClick={() => setError(false)}
              title="Ok"
            >
              Ok
            </Button>
          </>
        }
      />
    </>
  );
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    generalSetting: state.inventory.generalSetting.data,
    registerReport: state.inventory.registerReport,
  };
};
export default connect(mapStateToprops)(ReportsTabbing);
