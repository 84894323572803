import React, { useEffect, useState } from "react";
import { Image } from "@themesberg/react-bootstrap";
// Images and icons
import LoaderSpinner from "../../../components/Loader";

// Data tables
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
// import BootsrapTable2 from "../../components/BootsrapTable2";
const Promotions = (props) => {
  const dynamicImages = useSelector((state) => state.setting.dynamicImages);
  const { SearchBar, ClearSearchButton } = Search;
  const [, setShow] = useState(false);
  const [deleteMsg,] = useState(false);
  const [updatemsg, setupdateMsg] = useState(false);
  const [managePromotions, setmanagePromotions] = useState([]);

  useEffect(() => {
    if (props?.content_edit == null) {
      setShow(false);
    }
  }, [props.content_edit]);
  useEffect(() => {
    if (props?.promotionsList !== undefined) {
      const list = [];
      props.promotionsList.map((e) => {
        const cond = e.item
          ? e.item.find((it) => +it.stored_item == +props?.itemId)
          : undefined;
        if (cond) {
          list.push({
            ...e,
            status: e.promotionstatus ? "Enable" : "Disable",
          });
        }
      });
      setmanagePromotions(list);
    }
    ToastMsg();
  }, [ToastMsg, props.promotionsList]);

  const ToastMsg = () => {
    if (updatemsg && props?.updateMsg !== "" && props?.successMsg) {
      toast.success(props?.updateMsg);
      setupdateMsg(false);
    } else if (props?.addMsg && props?.insertMsg !== "" && props?.successMsg) {
      toast.success(props?.insertMsg);
      props.retrunMsg(false);
    } else if (deleteMsg && props?.deleteMsg !== "" && props?.successMsg) {
      toast.success(props?.deleteMsg);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      attrs: {
        "data-title": "ID",
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      attrs: {
        "data-title": "Name",
      },
    },
    {
      dataField: "status",
      text: "Status",
      attrs: {
        "data-title": "Status",
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size}
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: false, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Previous",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: managePromotions.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <div className="custom-data-table mt-3">
        <div className="custom-card card no-box-shadow border-0 no-checkbox two-column no-box-shadow without-scroll pro-table">
          {props?.showloading && (

            <LoaderSpinner />
          )}
    <div className="with-scroll-on-table">
          <ToolkitProvider
            keyField="id"
            data={managePromotions}
            columns={columns}
            exportCSV
            search
          >
            {(props) => (
              <>
                <div className="d-flex d-justify-content-between flex-column flex-md-row  align-items-md-center mb-3">
                  <div className="search-bar">
                    <SearchBar {...props.searchProps} />
                    {props.searchProps?.searchText?.length > 0 && (
                      <ClearSearchButton
                        className="clear"
                        {...props.searchProps}
                      />
                    )}
                  </div>
                </div>
                <div className="pagination-wrapper  page-text">
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    headerClasses="table-header"
                    noDataIndication={() => (
                      <div className="empty-data">
                        <Image
                          src={dynamicImages?.noData}
                          alt="No Data Found Icon"
                        />
                        No Data Available
                      </div>
                    )}
                    pagination={paginationFactory(options)}
                    {...props.baseProps}
                    dataColumn={managePromotions.title}
                  // defaultSorted={defaultSorted}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        </div>
        </div>
      </div>
    </>
  );
};
Promotions.propTypes = {
  promotionsList: PropTypes.array,
  updatemsg: PropTypes.bool,
  updateMsg: PropTypes.string,
  successMsg: PropTypes.bool,
  addMsg: PropTypes.bool,
  insertMsg: PropTypes.string,
  retrunMsg: PropTypes.func,
  deleteMsg: PropTypes.string,
  showloading: PropTypes.bool,
  searchProps: PropTypes.shape({
    searchText: PropTypes.string,
  }),
};
export default Promotions;
