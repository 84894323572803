import {
  ADDCONFIRMSALEITEM,
  CASHRECONCILIATION,
  CUSTOMERDATA,
  CUSTOMERUPDATE,
  HELLO,
  OFFLINESALE,
  POSSIDEETTINGS,
  REMOVEALLITEMSALE,
  ITEMSALEREFUND,
  ITEMSALELISTUPDATED,
  ITEMSALELIST,
  DATEOFBIRTH,
  ITEMSALE
} from "../helpers/constant";
import {
  FirtsLetterUpperCase,
  OfflineData,
  currencyFormat,
  dateFormate,
  isWeb,
} from "../helpers/custom";
import posService from "../services/saleItemService";
import { toast } from "react-toastify";
import { getListActionFunction, editActionFunction } from "./getListAction";
import { authUserName, getDeviceAndStoreName } from "../../axios/authHeader";

// this function is used for add item on sale list
export function AddSaleItemList(formData, type) {
  return {
    type: type,
    payload: formData,
  };
}

export function ShowNumpadStatus(formData, type) {
  return {
    type: type,
    payload: formData,
  };
}

// this function is used for addData to reduces for calculations
export function AddDataToReducer(formData, type) {
  return {
    type: type,
    payload: formData,
  };
}

// this function is used for remove item from list which added in sale list
export function RemoveSaleItemList(formData, type) {
  return {
    type: type,
    payload: formData,
  };
}

// this function is used for show the age popup condition true or false.
export function AgePopUpShow(cond, type) {
  return {
    type: type,
    payload: cond,
  };
}

// this function is used for add total payable amount to redux so that we can easily get
export function AddTotalpayAmount(amt, type) {
  return {
    type: type,
    payload: amt,
  };
}
// this function is used for update payment array when doing multiple payment
export function updatePaymentArrayReducer(type, data) {
  return {
    type: type,
    payload: data,
  };
}

// this function is used for Expend ui on click of expend button
export function expendUiDataReducer(type, data) {
  return {
    type: type,
    payload: data,
  };
}

// this function is used for show the data to graph in report section
export function UpdateGraphData(data, type) {
  return {
    type: type,
    payload: data,
  };
}

// this function is used for confirm sale add to database
export function checkOutSaleItem(
  module,
  data,
  type,
  Salestatus,
  id,
  isOfflineSale,
  giftResponse
) {
  return (dispatch) => {
    posService
      .addData(module, data, Salestatus, id, isOfflineSale, giftResponse)
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.statusText === "OK"
        ) {
          console.log(`response`, response);
          if (!isWeb()) {
            window.knex.schema
              .dropTableIfExists("wpos_osales")
              .then(function () {
                console.log("Table dropped successfully");
              })
              .catch(function (err) {
                console.log("Error dropping table:", err);
              });
          }
          dispatch(offLineSale({ status: false, data: [] }, OFFLINESALE));
          dispatch(confirmedResponseAction(response.data, type));
          if (module === ITEMSALE){
            dispatch(confirmedResponseAction('', DATEOFBIRTH));
          }
        }
      })
      .catch((error) => {
        if (window.navigator.onLine === false) {
          dispatch(offLineSale({ status: true, data: data }, OFFLINESALE));
          setTimeout(
            function () {
              let interval = setInterval(() => {
                // OfflineData(uploadOfflineSales);
                function uploadOfflineSales(ress) {
                  if (
                    ress.data != null &&
                    ress.data !== undefined &&
                    ress.data !== ""
                  ) {
                    console.log(`hello api loop`);
                    // OfflineData(helloApi);
                  } else {
                    console.log(`clear interval`);
                    clearInterval(interval);
                  }
                }
              }, 60 * 1000);
            },
            [1000]
          );

          function helloApi(ress) {
            const res_data = [];
            res_data.push(ress.data);
            const offlineList = UpdateArray(res_data);
            dispatch(getListActionFunction(HELLO, offlineList, "", ""));
          }
        } else {
          if (![ADDCONFIRMSALEITEM, REMOVEALLITEMSALE].includes(type)) {
            dispatch(failedResponseAction(error.response, type));
          }
        }
      });
  };
}

// this function is used for confirm sale add to database
export function checkOutOfflineRefundSaleItem(
  module,
  data,
  type,
  Salestatus,
  id,
  isOfflineSale,
  giftResponse
) {
  return (dispatch) => {
    posService
      .addData(module, data, Salestatus, id, isOfflineSale, giftResponse)
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.statusText === "OK"
        ) {
          console.log(`response`, response);
          if (!isWeb()) {
            window.knex.schema
              .dropTableIfExists("wpos_osales")
              .then(function () {
                console.log("Table dropped successfully");
              })
              .catch(function (err) {
                console.log("Error dropping table:", err);
              });
          }

          dispatch(offLineSale({ status: false, data: [] }, OFFLINESALE));
          dispatch(confirmedResponseAction(response.data, type));
          if (data?.status === 2) {
            dispatch(
              editActionFunction(
                ITEMSALEREFUND,
                data?.salevoid[0]?.formData,
                response?.data?.data?.id,
                ITEMSALELISTUPDATED
              )
            );
          } else if (data?.status == 3) {
            console.log("step2", data, data?.salevoid[0], response?.data?.data?.id)
            dispatch(
              editActionFunction(
                ITEMSALELIST,
                data?.salevoid[0],
                response?.data?.data?.id,
                ITEMSALELISTUPDATED
              )
            );
          }
        }
      })
      .catch((error) => {
        if (window.navigator.onLine === false) {
          dispatch(offLineSale({ status: true, data: data }, OFFLINESALE));
          setTimeout(
            function () {
              let interval = setInterval(() => {
                // OfflineData(uploadOfflineSales);
                function uploadOfflineSales(ress) {
                  if (
                    ress.data != null &&
                    ress.data !== undefined &&
                    ress.data !== ""
                  ) {
                    console.log(`hello api loop`);
                    // OfflineData(helloApi);
                  } else {
                    console.log(`clear interval`);
                    clearInterval(interval);
                  }
                }
              }, 60 * 1000);
            },
            [1000]
          );

          function helloApi(ress) {
            const res_data = [];
            res_data.push(ress.data);
            const offlineList = UpdateArray(res_data);
            dispatch(getListActionFunction(HELLO, offlineList, "", ""));
          }
        } else {
          if (![ADDCONFIRMSALEITEM, REMOVEALLITEMSALE].includes(type)) {
            dispatch(failedResponseAction(error.response, type));
          }
        }
      });
  };
}

const UpdateArray = (array) => {
  return array.map((data) => {
    let customer = undefined;
    if (data.custid > 0 && data.customer) {
      try {
        customer = JSON.parse(data?.customer);
      } catch (e) {
        customer = undefined;
      }
    }
    return {
      ...data,
      GID: data?.id,
      Ref: data?.ref?.split("-")[2],
      DeviceStore: FirtsLetterUpperCase(getDeviceAndStoreName(true)[0]),
      CustomerName: customer ? customer?.name : "N/A",
      Mobile: customer ? customer?.mobile : "N/A",
      Items: data?.numitems,
      Total: currencyFormat(data?.total),
      SaleTime: data?.dt,
      Status: data?.status,
      Payments: data?.payments,
      Item: data?.items,
      Extracosts: data?.extracosts,
      Processdt: dateFormate(parseInt(data?.processdt)),
      Store: FirtsLetterUpperCase(getDeviceAndStoreName(true)[1]),
      UserID: FirtsLetterUpperCase(authUserName()),
      Notes: data?.notes,
      actions: "Actions",
    };
  });
};

// this function is used for add the data to db based on module
// module mean's -- cash reconciliation , customer details ..
export function addActionData(module, data, type) {
  return (dispatch) => {
    posService
      .addData(module, data)
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.statusText === "OK"
        ) {
          dispatch(confirmedResponseAction(response.data, type));
          if (type === CASHRECONCILIATION) {
            localStorage.setItem("accountClosingTime", new Date().getTime());
          } else if (type === CUSTOMERDATA) {
            toast.success("Customer added successfully");
          } else if (type === POSSIDEETTINGS) {
            dispatch(confirmedResponseAction(data, type));
          }
        }
      })
      .catch((error) => {
        if (type === CUSTOMERDATA) {
          if (typeof error.response?.data === "object") {
            toast.error(Object.values(error.response?.data)[0][0]);
          }
        } else {
          dispatch(failedResponseAction(error.response, type));
        }
      });
  };
}

export function editActionData(module, data, id, type, doNotToast) {
  return (dispatch) => {
    posService
      .editData(module, data, id)
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.statusText === "OK"
        ) {
          if (type === CUSTOMERUPDATE && !doNotToast) {
            toast.success("Customer updated successfully");
          }
          dispatch(confirmedResponseAction(response.data, type));
        }
      })
      .catch((error) => {
        if (type === CUSTOMERUPDATE) {
          if (typeof error.response?.data === "object") {
            toast.error(Object.values(error.response?.data)[0][0]);
          }
        } else {
          dispatch(failedResponseAction(error.response, type));
        }
      });
  };
}

// this function is used for add confirm data to database and return confirm response
export function confirmedResponseAction(response, type) {
  return {
    type: type,
    payload: response,
  };
}
// this function is used for add  Failed data to database and return error
export function failedResponseAction(response, type) {
  return {
    type: type,
    payload: response,
  };
}

//this function is used to complete sale from tranaction detail to register
export function completeSale(response, type) {
  return {
    type: type,
    payload: response,
  };
}

// offline sale
export function offLineSale(response, type) {
  return {
    type: type,
    payload: response,
  };
}

export function paxErrorState(response, type) {
  return {
    type: type,
    payload: response,
  };
}
