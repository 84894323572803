import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Form,
  Image,
  FormCheck,
  ListGroup,
  Fade,
  Tooltip,
  OverlayTrigger,
} from "@themesberg/react-bootstrap";
import BootstrapTable2 from "../../components/BootsrapTable2";
import FormField from "../../../pages/components/formField";
import CustomModal from "../../../pages/components/Modals";
import boopSfx from "../../../assets/sounds/item_not_found.mp3";
import CustomScroll from "react-custom-scroll";
import { connect, useDispatch } from "react-redux";
import CustomNumpad from "../../components/customNumpad";
import {
  AddSaleItemList,
  RemoveSaleItemList,
  AgePopUpShow,
  completeSale,
  AddTotalpayAmount,
  ShowNumpadStatus,
} from "../../../app/actions/saleItemAction";
import {
  ITEMSALELIST,
  AGEPOPUPSHOW,
  REMOVEITEMSALE,
  ITEM,
  ADDITEM,
  COMPLETEITEMSALELIST,
  TOTALAMOUNT,
  VALIDATION,
  PROMOTION_DATA,
  DISCOUNT,
  NUMPADSTATUS,
  REEDEM_REWARDS,
  ADDITEMONSALE,
  STOCKCODECHECK,
  ITEMNOTFOUND,
  ITEMUPDATEPUSHER,
  ITEMDISCOUNTS,
  DEPOSITRETURNTYPE,
  LOTTOPAYOUTTYPE,
  ONLINEPAYOUTTYPE,
  LOTTOSALETYPE,
  ONLINELOTTERYTYPE,
  NEGATIVETYPE
} from "../../../app/helpers/constant";
import AddItems from "./AddItem";
import AgeVerification from "./AgeVerification";
import { Howl, Howler } from "howler";
import {
  checkValue,
  currencyFormat,
  getTheBestPromotion,
  qtyFormat,
  validateInputVal,
  formatCode,
  PusherUpdateFunction,
  checkCustomerInPromotion,
} from "../../../app/helpers/custom";
import {
  addSettingData,
  checkUserPermission,
  getDeviceAndStore,
} from "../../../axios/authHeader";
import { Auth } from "../../auth/auth";
import Localstorage from "app/storage/Localstorage";
import { addActionFunction } from "app/actions/getListAction";
import { useLocation } from "react-router-dom";
import onScan from 'onscan.js';
import { ITEMCHANNEl, ITEM_EVENT } from "app/helpers/pusherConstant";
import { usePusher } from "context/PusherContext";
import PropTypes from 'prop-types';

const ItemsContain = (props) => {
  const location = useLocation();
  const [show, setShow] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [itemList, setItemList] = useState(props?.salesitemList);
  const [showPriceNumpad, setShowPriceNumpad] = useState(false);
  const [showQtyNumpad, setShowQtyNumpad] = useState(false);
  const [checkElementExits, setCheckElementExits] = useState([]);
  const [selectQtyDialog, setSelectQtyDialog] = useState(false);
  const [dataForSelectQty, setDataForSelectQty] = useState([]);
  const [defaultEbtEnable, setDefaultEbtEnable] = useState(0);
  const [agePopup, setAgePopup] = useState(false);
  const [ageNumber, setAgeNumber] = useState(0);
  const [DataAgeVerification, setDataAgeVerification] = useState([]);
  const [clearValue, setClearValue] = useState(false);
  const [openErrorMsg, setOpenErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [itemcondition, setItemCondition] = useState(false);
  const [itemData, setItemData] = useState({});
  const [defaultTax, setDefaultTax] = useState("");
  const [bottleDepositTaxable, setbottleDepositTaxable] = useState(0);
  const [auth, setAuth] = useState(false);
  const [authType, setAuthType] = useState("");
  const [authSuccess, setAuthSuccess] = useState(false);
  const [bottleDepositEnable, setBottleDepositEnable] = useState(0);
  const [additionalFeeEnable, setAdditionalFeeEnable] = useState(0);
  const [defaultEbtTax, setDefaultEbtTax] = useState("0");
  const [EnableKeypadDecimal, setEnableKeypadDecimal] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalDiscountValue, setTotalDiscountValue] = useState(0);
  const [sound, setSound] = useState("");
  const [focus, setFocus] = useState(false);
  const [focusDirPrice, setFocusDirPrice] = useState({});
  const [focusDirQty, setFocusDirQty] = useState({});
  const [left, setLeft] = useState(false);
  const [numpadVisible, setNumpadVisible] = useState(false);
  const [numpadStatus,] = useState(
    props?.posSideSetting?.screenKeypad || true
  );
  const [screenKeypad, setScreenKeypad] = useState(
    JSON.parse(localStorage.getItem("wpos_config"))?.screenKeypad
  );
  const [priceCheckList, setPriceCheckList] = useState([]);
  const [UpcTenCode, setUpcTenCode] = useState(
    props?.genSetting?.OTHER?.use_upc_ten
  );
  const [barcode, setBarcode] = useState(null);
  const priceKeyRef = useRef([]);
  const qtyKeyRef = useRef([]);
  const dispatch = useDispatch();
  const taxChange = props?.PosSetting?.SALE?.allow_changing_stored_item_taxes;
  const priceChange =
    props?.PosSetting?.SALE?.allow_changing_stored_item_prices;
  const decimalQty = props?.PosSetting?.SALE?.allow_decimal_qty_sale;
  const discountSymbolLimit =
    props?.PosSetting?.SALE?.maximum_discount_limit_per_sale_type;
  const discountValueLimit =
    props?.PosSetting?.SALE?.maximum_discount_limit_per_sale_amount;
  const isMaximumDiscountEnabled =
    props?.PosSetting?.SALE?.enable_maximum_discount_limit_per_sale;

  const [mainItemList, setMainItemList] = useState(props?.salesitemList);
  const [LottoIds, setLottoIds] = useState([]);
  const [priceQtyError, setPriceQtyError] = useState({ open: false });

  const [displayList, setDisplayList] = useState([]);
  const [change, setChange] = useState(false);
  const payOutIds = [
    parseInt(props?.PosSetting?.OPTION?.lotto_payout_item),
    parseInt(props?.PosSetting?.OPTION?.online_payout_item),
  ];
  const pusher = usePusher();
  let scannedData = false;
  let Value = '';
  useEffect(() => {
    setScreenKeypad(
      JSON.parse(localStorage.getItem("wpos_config"))?.screenKeypad
    );
  }, [localStorage.getItem("wpos_config")]);

  useEffect(() => {
    setFocusDirQty({})
    setFocusDirPrice({})
  }, [showPriceNumpad, showQtyNumpad])

  useEffect(() => {
    if (props?.focusNo !== '') {
      priceKeyRef.current[props?.focusNo].focus();
      setNumpadVisible(true)
      setDisplayList((prev) => prev.map((i) => {
        return { ...i, change: false }
      }))
    }
  }, [props?.focusNo])

  useEffect(() => {
    setUpcTenCode(props?.genSetting?.OTHER?.use_upc_ten);
  }, [props?.genSetting]);

  useEffect(() => {
    if (EnableKeypadDecimal != 0) {
      if (priceKeyRef.current[focusDirPrice.index] && focusDirPrice.left) {
        priceKeyRef.current[focusDirPrice.index].selectionEnd = 0;
      } else if (priceKeyRef.current[focusDirPrice.index] && !focusDirPrice.left && !focusDirPrice.right) {
        priceKeyRef.current[focusDirPrice.index].selectionEnd = focusDirPrice.focus < 4 && priceKeyRef.current[focusDirPrice.index].value?.length <= 4 && !left ? 4 : focusDirPrice.focus;
        priceKeyRef.current[focusDirPrice.index].selectionStart = focusDirPrice.focus < 4 && priceKeyRef.current[focusDirPrice.index].value?.length <= 4 && !left ? 4 : focusDirPrice.focus;
      }
    } else {
      if (priceKeyRef.current[focusDirPrice.index] && focusDirPrice.left) {
        priceKeyRef.current[focusDirPrice.index].selectionEnd = 0;
      } else if (priceKeyRef.current[focusDirPrice.index] && !focusDirPrice.left && !focusDirPrice.right) {
        priceKeyRef.current[focusDirPrice.index].selectionEnd = focusDirPrice.focus;
        priceKeyRef.current[focusDirPrice.index].selectionStart = focusDirPrice.focus;
      }
    }

    if (qtyKeyRef.current[focusDirQty.index] && focusDirQty.left) {
      qtyKeyRef.current[focusDirQty.index].selectionEnd = 0;
      qtyKeyRef.current[focusDirQty.index].focus()
    }

    if (qtyKeyRef.current[focusDirQty.index] && focusDirQty.right) {
      qtyKeyRef.current[focusDirQty.index].selectionStart = qtyKeyRef.current[focusDirQty.index]?.value?.length + 1;
      qtyKeyRef.current[focusDirQty.index].focus()
    } else if (qtyKeyRef.current[focusDirQty.index] && !focusDirQty.right && !focusDirQty.left) {
      qtyKeyRef.current[focusDirQty.index].selectionEnd = focusDirQty.focus;
      qtyKeyRef.current[focusDirQty.index].selectionStart = focusDirQty.focus;
      qtyKeyRef.current[focusDirQty.index].focus()
    }
  }, [displayList])

  // Modification tables row data
  useEffect(() => {
    let newLists = [];
    let myIndex = 0;
    for (let dis of mainItemList) {
      if (!isNaN(+dis.min_price) && dis.min_price !== "") {
        dis = {
          ...dis,
          min_price_text: `Item ${dis.manualname} has minimum price ${currencyFormat(+dis.min_price)}`
        }
      }
      if (dis.promotion || dis.totalDiscount > 0) {
        newLists.push({ ...dis, myIndex });
        if (dis.promotion) {
          newLists.push({
            manualname: dis.showText,
            showTotal: dis.showTotalText,
            promotionData: dis.promotion,
          });
        }
        if (dis.promotion2) {
          newLists.push({
            manualname: dis.showText2,
            showTotal: dis.showTotalText2,
            promotionData: dis.promotion2,
          });
        }
        if (dis.totalDiscount > 0) {
          newLists.push({
            manualname: `DISCOUNT (${parseFloat(dis.discountValue).toFixed(2)}%)`,
            showTotal: currencyFormat(dis.totalDiscount),
            myId: dis.id,
            discountData: true
          });
        }
      } else {
        newLists.push({ ...dis, myIndex });
      }
      myIndex++;
    }

    // if (totalDiscount > 0) {
    //   newLists.push({
    //     manualname: `DISCOUNT (${parseFloat(totalDiscountValue).toFixed(2)}%)`,
    //     showTotal: currencyFormat(totalDiscount),
    //     discountData: true,
    //     myIndex: newLists.length,
    //   });
    // }

    setDisplayList(newLists);
  }, [mainItemList]);

  useEffect(() => {
    async function childEventCallback(data) {
      if (+data?.data?.store === +getDeviceAndStore()[1]) {
        const items = [...props?.item];
        let listUpdate;
        if (data.method === "put" && Object.entries(data.data).length > 0) {
          listUpdate = PusherUpdateFunction("put", items, data.data);
        } else if (
          data.method === "add" &&
          Object.entries(data.data).length > 0
        ) {
          listUpdate = PusherUpdateFunction("add", items, data.data);
          let realtimeItems = localStorage.getItem("realtimeItem")
          if (realtimeItems) {
            localStorage.setItem("realtimeItem", JSON.stringify([data.data, ...JSON.parse(realtimeItems)]))
          } else {
            localStorage.setItem("realtimeItem", JSON.stringify([data.data]))
          }
        } else if (data.method === "delete" && data.id > 0) {
          listUpdate = PusherUpdateFunction("delete", items, data);
        }
        dispatch({
          type: ITEMUPDATEPUSHER,
          payload: { data: listUpdate },
        });
      }
    }
    const channel = pusher.subscribe(ITEMCHANNEl);
    channel.bind(ITEM_EVENT, childEventCallback);
    return () => {
      channel.unbind(ITEM_EVENT, childEventCallback);
    };
  }, [itemList, pusher]);

  useEffect(() => {
    // Setup the new Howl.
    setSound(new Howl({ src: [boopSfx] }));
    // Change global volume.
    Howler.volume(1);
  }, []);

  if (!itemList.length && props.discount.value && !location?.discount) {
    dispatch({
      type: DISCOUNT,
      payload: {
        value: 0,
        symbol: false,
      },
    });
  }

  useEffect(() => {
    if (props?.AddManualItem) {
      AddMoreData();
      props.SetAddManualItem(false);
      setShowPriceNumpad(itemList.length);
    } else if (props?.AddDepReturn){
      AddMoreData(null, null, "Bottle Deposit Return", props?.depositReturn?.bottleDepositReturnAmount);
      props.setIsDepReturn(false);
      setShowPriceNumpad(itemList.length);
    }
  }, [props.AddManualItem, props?.AddDepReturn]);

  useEffect(() => {
    if (props?.allDataRemove) setItemList([]);
  }, [props.allDataRemove]);

  const getNewTaxCalc = (item, taxId) => {
    return {
      tax: taxId,
      subtotal: calculateSubtotal(item.price, qtyFormat(decimalQty, item.qty)),
      total: calculateTax(
        taxId,
        item.price,
        qtyFormat(decimalQty, item.qty),
        item.bottle_deposit,
        item.fee
      )[0],
      taxTotal: calculateTax(
        taxId,
        item.price,
        qtyFormat(decimalQty, item.qty),
        item.bottle_deposit,
        item.fee
      )[1],
    };
  };

  useEffect(() => {
    if (props?.completeSaleItemList && props?.completeSaleItemList.length) {
      setItemList([]);
      setMainDiscountItems(props?.itemDiscounts)
      for (let it of props?.completeSaleItemList) {
        DataAddedToSaleTable(it);
      }
      dispatch(completeSale([], COMPLETEITEMSALELIST));
    }
  }, [props?.completeSaleItemList]);

  const checkForPromotion = (list, stack_promotion) => {
    const updatedList = [];
    let promotionData = Localstorage.getItem("promotion_data") || {};
    const sale_customer = Localstorage.getItem("sale_customer");
    let customerPromotionLists = [];

    let promoList;
    if (stack_promotion) {
      promoList = props?.promotions?.filter((e) => e.stack_promotion === "1");
    } else {
      promoList = props?.promotions?.filter((e) => e.stack_promotion === "0");
    }

    if (sale_customer && !stack_promotion) {
      customerPromotionLists = promoList.filter((e) =>
        checkCustomerInPromotion(e, sale_customer)
      );
    }
    promoList = customerPromotionLists?.length
      ? customerPromotionLists
      : promoList?.filter(
          (e) =>
            !e?.customer?.length && !e.customergroup?.length && !e.all_customer
        );

    for (let x of list) {
      if (x.manual_item) {
        updatedList.push(x);
      } else if (x.alreadyAddedPromotion) {
        updatedList.push({
          ...x,
          showText: x.alreadyAddedPromotion.name,
          showTotalText: x.alreadyAddedPromotion.promotext,
          promotion: true,
          ...(x?.alreadyAddedPromotion?.promotion2 && {
            showText2: x.alreadyAddedPromotion?.promotion2.name,
            showTotalText2: x.alreadyAddedPromotion?.promotion2.promotext,
            promotion2: true,
          }),
        });
        promotionData[x.id] = x.alreadyAddedPromotion;
      } else {
        let bestPromotion = getTheBestPromotion(
          [promoList, list],
          x.id,
          x.category,
          x.size,
          x.tag || [],
          Math.abs(x.qty),
          Math.abs(x.price)
        );
        if (bestPromotion && bestPromotion.promoid) {
          const appliedPromo = props?.promotions?.find(
            (itm) => +itm.id == +bestPromotion?.promoid
          );
          let continueApply = true;
          if (!stack_promotion) {
            promotionData[x.id] = bestPromotion;
          } else {
            if (x.promotion) {
              promotionData[x.id] = {
                ...promotionData[x.id],
                promotion2: bestPromotion,
              };
            } else {
              continueApply = false;
            }
          }
          if (continueApply) {
            const customer_tax = bestPromotion.customer_tax
              ? parseInt(bestPromotion.customer_tax)
              : undefined;
            const applyCustomerTax = customer_tax;

            let newTaxcalc = {};

            if (
              applyCustomerTax &&
              checkCustomerInPromotion(appliedPromo, sale_customer)
            ) {
              newTaxcalc = getNewTaxCalc(x, customer_tax);
            }
            updatedList.push({
              ...x,
              price: bestPromotion.updatePrice,
              showPrice: parseFloat(bestPromotion.updatePrice).toFixed(2),
              ...(x.promotion && {
                promotion2: true,
                showText2: bestPromotion.name,
                showTotalText2: bestPromotion.promotext,
              }),
              ...(!x.promotion && {
                showText: bestPromotion.cost_promo
                  ? `${bestPromotion.name} (${bestPromotion.promotext})`
                  : bestPromotion.name,
                showTotalText: bestPromotion.cost_promo
                  ? ""
                  : bestPromotion.promotext,
                promotion: true,
              }),
              ...newTaxcalc,
            });
          } else {
            updatedList.push(x);
          }
        } else {
          updatedList.push(x);
        }
      }
    }

    dispatch({
      type: PROMOTION_DATA,
      payload: promotionData,
    });
    Localstorage.setItem("promotion_data", promotionData);
    return updatedList;
  };

  useEffect(() => {
    localStorage.setItem("promotion_data", null);
    localStorage.setItem("discount_data", null);
  }, []);

  const calculateCuponItems = (itemList) => {
    return itemList.map((item, idx) => {
      let itemType = item?.type;
      let couponItem;
      if (itemType == 12 || itemType == 13) {
        if (item?.itemData?.modifier[0]?.type == 0 && itemType == 12) {
          couponItem = {
            ...item,
            price: -parseFloat(item?.price).toFixed(2),
            showPrice: -parseFloat(item?.price).toFixed(2),
          };
        }

        if (itemType == 13) {
          let total = itemList
            ?.slice(0, idx)
            ?.reduce(
              (a, b) =>
                a +
                calculateTax(b.tax, b.price, b.qty, b.bottle_deposit, b.fee)[0],
              0
            );
          let priceCal = parseFloat((item?.price / 100) * total).toFixed(2);
          let price =
            +item?.itemData?.modifier[0]?.type == 0 ? -priceCal : priceCal;
          couponItem = {
            ...item,
            price,
            coupon_item: true,
          };
        }
      }
      return couponItem || item;
    });
  };

  useEffect(() => {
    let checkedList = checkForPromotion(calculateCuponItems(itemList));
    let checkedList2 = checkForPromotion(checkedList, true);
    let updatedList = addDiscount(checkedList2);
    !updatedList.length && setTotalDiscount(0);
    setMainItemList(updatedList);
  }, [itemList, props?.promotions, props?.sale_customer]);

  useEffect(() => {
    if (!mainItemList.length) {
      localStorage.setItem("discount_data", null);
      localStorage.setItem("promotion_data", null);
      dispatch({
        type: PROMOTION_DATA,
        payload: {},
      });
      const data = {
        ...props.totalAmount,
        totalDiscount: 0,
        discountVal: 0,
        discountSymbol: "%",
      };
      setTotalDiscount(0);
      setTotalDiscountValue(0);
      dispatch(AddTotalpayAmount(data, TOTALAMOUNT));
      // if(Object.keys(props?.itemDiscounts)?.length && !location.discount){
      //   dispatch({
      //     type: ITEMDISCOUNTS,
      //     payload: {}
      //   })
      // }
    }
    dispatch(AddSaleItemList(mainItemList, ITEMSALELIST));
  }, [mainItemList]);

  const applyDisc = (disaVal, discountSymbol, ignore) => {
    let lastItemPrice = +itemList[itemList.length - 1]?.price || 0;
    if (
      (discountSymbolLimit === "%" && +disaVal > 100) ||
      (discountSymbolLimit === "&" && disaVal > lastItemPrice)
    ) {
      setOpenErrorMsg(true);
      setErrorMsg("You cannot apply discount more than 100%");
    } else if (
      discountSymbolLimit === discountSymbol &&
      +discountValueLimit < +disaVal &&
      isMaximumDiscountEnabled === "1"
    ) {
      setOpenErrorMsg(true);
      let sec = props?.discount?.symbol
        ? "You cannot apply discount more than $" + discountValueLimit
        : "You cannot apply discount more than " + discountValueLimit + "%";
      return setErrorMsg(sec);
    } else {
      if (itemList[itemList?.length - 1]?.id) {
        setMainDiscountItems(props?.itemDiscounts);
      }
      setMainDiscount({
        ...props.discount,
        type: "pureDiscount",
      });
    }
  };

  const reedemDisc = () => {
    if (itemList[itemList?.length - 1]?.id) {
      setMainDiscountItems(props?.itemDiscounts);
    }

    setMainDiscount({
      value: props.reedemPointDiscount?.value,
      symbol: props.reedemPointDiscount?.symbol === "%" ? 1 : 0,
      type: "pointsReedem",
    });
  };

  const [mainDisc, setMainDiscount] = useState({ value: 0, symbol: "%" });
  const [mainDiscItems, setMainDiscountItems] = useState({});
  useEffect(() => {
    let discountSymbol = props?.discount?.symbol ? "$" : "%";
    applyDisc(
      props.discount?.value,
      discountSymbol,
      props.discount?.ignorePopup || false
    );
  }, [props.discount]);

  useEffect(() => {
    reedemDisc(props.reedemPointDiscount?.value, undefined, true);
  }, [props.reedemPointDiscount]);

  useEffect(() => {
    let checkedList = checkForPromotion(calculateCuponItems(itemList));
    let checkedList1 = checkForPromotion(checkedList, true);
    let updatedList = addDiscount(checkedList1);
    setMainItemList(updatedList);
  }, [mainDisc, mainDiscItems, props?.itemDiscounts]);

  const addDiscount = (list) => {
    let discountTotal = 0;
    let newList = [];
    let overalPrice = 0;
    let discountData = Localstorage.getItem("discount_data") || {};
    for (let x of list) {
      let discountTextForItem = undefined;
      let price = +x.price;
      let category = props?.category?.find((e) => +e.id == +x.category);
      let discountAllowCat = category ? !category?.discount : 1;

      if (x.itemData?.donotdiscount) {
        discountTextForItem = ` Discount is not allowed on ${x.manualname} Item`;
      }

      if (category?.discount) {
        discountTextForItem = `Discount is not allowed on ${category?.name} Category`;
      }
      let itemdtotal;
      let itemId = x?.itemData?.unique_item_id || x?.id;
      if (
        !x.itemData?.donotdiscount &&
        parseFloat(mainDiscItems[itemId]?.value) > 0 &&
        !x.totalDiscount &&
        discountAllowCat
      ) {
        overalPrice += price;
        if (mainDiscItems[itemId]?.symbol) {
          price = price - +mainDiscItems[itemId]?.value / x.qty;
          itemdtotal = (+mainDiscItems[itemId]?.value / x.qty / x.price) * 100;
        } else {
          price = price - +(+mainDiscItems[itemId]?.value / 100) * price;
          itemdtotal = +mainDiscItems[itemId]?.value;
        }
        if (parseFloat(price) < parseFloat(x.min_price || 0)) {
          price = parseFloat(x.min_price || 0);
        }
      }
      const categoryDetails = props?.category?.filter(
        (item) => item.id == x.itemData?.category
      );

      let totalDiscount = (parseFloat(x.price) - parseFloat(price)) * x.qty;
      totalDiscount =
        Number.isNaN(totalDiscount) || totalDiscount < 0 ? 0 : totalDiscount;
      discountTotal += totalDiscount;
      let setqty = qtyFormat(decimalQty, x.qty);
      if (x.coupon_item) {
        setqty = 1;
      }

      if (totalDiscount > 0) {
        discountData[x.id] = {
          totalDiscount,
          discountValue: itemdtotal,
          symbol: mainDiscItems[x.id]?.symbol,
          value: mainDiscItems[x.id]?.value,
        };
        Localstorage.setItem("discount_data", discountData);
      }

      // inclusive tax
      const itemData = props?.taxlist?.find(
        (item) => item.id === parseInt(x.tax)
      );
      const appliedTax = props?.taxCount?.find(
        (item) => item.id === itemData?.base_tax[0]?.basetax_id
      );
      let inclusive_tax_rate = 0;
      let inclusive_tax = 0;
      if (itemData?.inclusive_tax == 1) {
        inclusive_tax_rate = parseFloat(appliedTax?.value) / 100;
        inclusive_tax = parseFloat(
          ((price * setqty) / (1 + inclusive_tax_rate)) * inclusive_tax_rate
        ).toFixed(2);
      }
      // inclusive tax
      x.fee = typeof x.fee === "string" ? JSON.parse(x.fee) : x.fee;

      let total =
        x?.item_type == DEPOSITRETURNTYPE ||
        x?.itemData?.item_type == DEPOSITRETURNTYPE
          ? +setqty * +price
          : calculateTax(x.tax, price, setqty, x.bottle_deposit, x.fee)[0];

      let showTotal = calculateTax(
        x.item_type == DEPOSITRETURNTYPE ||
          x?.itemData?.item_type == DEPOSITRETURNTYPE
          ? 0
          : x.tax,
        x.price,
        setqty,
        x.bottle_deposit,
        x.fee
      )[0];
      newList.push({
        ...x,
        discountTextForItem,
        ...(totalDiscount > 0 && {
          price,
          totalDiscount,
          discountValue: Number.isNaN(itemdtotal)
            ? 0
            : parseFloat(itemdtotal).toFixed(2),
        }),
        subtotal: calculateSubtotal(
          price,
          setqty,
          x.item_type == DEPOSITRETURNTYPE ||
            x?.itemData?.item_type == DEPOSITRETURNTYPE
            ? 0
            : inclusive_tax
        ),
        ...(x.coupon_item && { showPrice: price }),
        showTotal: parseFloat(showTotal).toFixed(2),
        total,
        taxTotal:
          x.item_type == DEPOSITRETURNTYPE ||
          x?.itemData?.item_type == DEPOSITRETURNTYPE
            ? 0
            : calculateTax(x.tax, price, setqty, x.bottle_deposit, x.fee)[1],
        bottleDeposit:
          x.item_type == DEPOSITRETURNTYPE ||
          x?.itemData?.item_type == DEPOSITRETURNTYPE
            ? +calculateSubtotal(price, setqty, 0) * +setqty
            : calculateBottleDepositTax(+x.bottle_deposit, setqty, 0),
        additionalFee: calculateAdditionalFeeTax(x.fee, price, setqty, 0),
        ebttotal: calculateEbtTax(
          x.ebt,
          defaultEbtTax,
          price,
          setqty,
          x.bottle_deposit,
          x.fee
        ),
        cost: calculateSubtotal(x.avg_cost, setqty),
        category: x.itemData?.category,
        loyaltyrewards: categoryDetails[0]?.loyaltyrewards,
      });
    }
    let dtotal = (parseFloat(discountTotal) / overalPrice) * 100;
    setTotalDiscountValue(dtotal);
    setTotalDiscount(parseFloat(discountTotal));
    let totalAmt = Localstorage.getItem("totalAmount");
    totalAmt = totalAmt || {};
    const data = {
      ...totalAmt,
      totalDiscount: parseFloat(discountTotal),
      discountVal: Number.isNaN(dtotal) ? 0 : parseFloat(dtotal).toFixed(2),
      discountSymbol: mainDisc.symbol ? "$" : "%",
      discountType: mainDisc.type,
      mainValue: mainDisc.value,
    };
    dispatch(AddTotalpayAmount(data, TOTALAMOUNT));
    return newList;
  };

  const removeDiscount = (id) => {
    setMainDiscountItems((prevDiscounts) => {
      const newDiscounts = { ...prevDiscounts };
      delete newDiscounts[id];
      dispatch({
        type: ITEMDISCOUNTS,
        payload: newDiscounts,
      });
      return newDiscounts;
    });
  };

  useEffect(() => {
    handleCloseEdit();
  }, [props.item]);

  useEffect(() => {
    if (props?.accountSetting?.TAXRULEDATA !== undefined) {
      setDefaultEbtEnable(
        props?.accountSetting?.TAXRULEDATA?.deafultEbtTaxCheck || 0
      );
      setDefaultEbtTax(props?.accountSetting?.TAXRULEDATA?.deafultEbtTax || 0);
      setDefaultTax(props?.accountSetting?.TAXRULEDATA?.defaultTax || 0);
    }
    if (props?.accountSetting?.BOTTLEDEPOSIT !== undefined) {
      setbottleDepositTaxable(
        parseInt(props?.accountSetting?.BOTTLEDEPOSIT?.taxable || 0)
      );
      setBottleDepositEnable(
        parseInt(props?.accountSetting?.BOTTLEDEPOSIT?.enable || 0)
      );
    }
    if (props?.accountSetting?.ADDITIONALFEE !== undefined) {
      setAdditionalFeeEnable(
        parseInt(props?.accountSetting?.ADDITIONALFEE?.feeSetting || 0)
      );
    }
  }, [props.AccountSetting, props?.accountSetting]);

  useEffect(() => {
    if (props?.PosSetting?.OPTION !== undefined) {
      setEnableKeypadDecimal(
        parseInt(props?.PosSetting?.OPTION?.enable_keypad_price_decimal || 0)
      );
    }
    setLottoIds([
      parseInt(props?.PosSetting?.OPTION?.lotto_payout_item),
      parseInt(props?.PosSetting?.OPTION?.lotto_sale_item),
      parseInt(props?.PosSetting?.OPTION?.online_payout_item),
      parseInt(props?.PosSetting?.OPTION?.online_lottery_item),
    ]);
  }, [props.PosSetting]);

  const AddMoreData = (id, qty, name, already_add_price) => {
    const randId = Math.floor(Math.random() * 90000) + 10000;
    let inputstate = {
      id: id ? id : randId,
      qty: qty ? qty : "1",
      manualname: name ? name : "",
      increaseqty: "1",
      price: already_add_price ? already_add_price : "",
      item_original_price: already_add_price ? already_add_price : "",
      showPrice: already_add_price ? already_add_price : "",
      // tax: defaultTax,
      tax: already_add_price
        ? 0
        : props?.taxlist && props.taxlist.length > 0
          ? props?.taxlist[0].id
          : defaultTax,
      subtotal: Number(0).toFixed(2),
      total: Number(0).toFixed(2),
      showTotal: already_add_price ? already_add_price : Number(0).toFixed(2),
      taxTotal: Number(0).toFixed(2),
      bottleDeposit: already_add_price
        ? +already_add_price
        : Number(0).toFixed(2),
      additionalFee: Number(0).toFixed(2),
      manual: true,
      avg_cost: Number(0).toFixed(2),
      cost: Number(0).toFixed(2),
      ebt: false,
      ebtenable: false,
      rank: "M",
      fee: [],
      ebttotal: Number(0).toFixed(2),
      bottleDepositEanble: 0,
      additionalFeeEnale: 0,
      bottleDepositTaxable: 0,
      itemData: [],
      decimalPrice: "",
      manual_item: true,
      min_price: already_add_price ? "" : 0,
    };
    if (already_add_price) {
      inputstate = { ...inputstate, item_type: DEPOSITRETURNTYPE, status: 2 };
    }
    if (true) {
      setItemList((prev) => [...prev, inputstate]);
    }
  };

  useEffect(() => {
    if (authSuccess == true) {
      if (auth === "viewEditItem") {
        setShow(ITEM);
        setItemData(selectedItemId);
      } else {
        setItemList((prev) => prev.filter((item) => item !== prev[auth - 1]));
      }
      setAuthSuccess(false);
      setAuth(false);
    }
  }, [authSuccess]);

  const RemoveMoreData = (Rowindex) => {
    setChange(false);
    if (!checkUserPermission("remove_items_from_tran_view")) {
      setAuth(Rowindex + 1);
      setAuthType("remove_items_from_tran_view");
    } else {
      setItemList((prev) => prev.filter((item) => item !== prev[Rowindex]));
      removeDiscount(itemList[Rowindex]?.id);
      let promoData = props?.promotionData;
      delete promoData[itemList[Rowindex]?.id];
      Localstorage.setItem("promotion_data", promoData);
      dispatch({
        type: PROMOTION_DATA,
        payload: promoData,
      });
    }
    if (itemList.length <= 1) {
      dispatch({
        type: DISCOUNT,
        payload: {
          value: 0,
          symbol: false,
          ignorePopup: false,
        },
      });
    }
  };

  /// responsible for item add on sale ui table
  useEffect(() => {
    if (
      props?.salesitemList !== undefined &&
      props?.salesitemList?.length > 0
    ) {
      setCheckElementExits(
        props?.salesitemList.map((data) => {
          return data.id;
        })
      );
    } else {
      setCheckElementExits([]);
    }
    addSettingData("saleitems", JSON.stringify(props?.salesitemList));
    if (Object.entries(props?.salesitemList)?.length > 0) {
      addSettingData("saleitems_reprint", JSON.stringify(props?.salesitemList));
    }
    setClearValue(true);
  }, [props.salesitemList]);

  /// responsible for item add on sale ui table
  useEffect(() => {
    updateSaleTableUi(
      props?.SaleItem[0],
      props?.saleitemAdded,
      props?.category
    );
  }, [props.SaleItem[0], props.category, props.saleitemAdded]);

  function updateSaleTableUi(searchData, shortCutData, category) {
    let ArrayData = {};
    if (searchData && searchData !== undefined) {
      ArrayData = searchData;
    } else if (
      shortCutData &&
      shortCutData !== undefined &&
      shortCutData?.length > 0
    ) {
      ArrayData = shortCutData[0];
    }
    if (
      ArrayData !== undefined &&
      Object.entries(ArrayData)?.length > 0 &&
      category
    ) {
      return AgeCheckVerificationFunction(category, ArrayData, props?.agePopup);
    }
  }

  function AgeCheckVerificationFunction(category, saleitem, agePopup) {
    let ageNumberSet = "NOAGECATEGORY";
    if (
      category !== undefined &&
      category?.length > 0 &&
      saleitem?.category !== null &&
      saleitem?.category !== undefined &&
      saleitem?.category > 0
    ) {
      const ageNumber = category?.find(
        (item) => item.id === saleitem?.category
      );
      ageNumberSet = checkValue(ageNumber?.ageverification)
        ? parseInt(ageNumber?.ageverification)
        : "NOAGECATEGORY";
    }
    DataAddedToCheckAge(saleitem, ageNumberSet, agePopup);
  }

  /// This is for age verification purposes
  const DataAddedToCheckAge = (ItemData, AgeVerification, ageUpShow) => {
    if (AgeVerification !== "" && AgeVerification !== "NOAGECATEGORY") {
      if (
        CheckVerified(ItemData.id) ||
        [
          11,
          LOTTOPAYOUTTYPE,
          ONLINEPAYOUTTYPE,
          ONLINELOTTERYTYPE,
          LOTTOSALETYPE,
          12,
          13,
        ].includes(ItemData.item_type)
      ) {
        DataAddedToSaleTable(ItemData);
      } else {
        if (ageUpShow) {
          setAgePopup(ageUpShow);
          setAgeNumber(AgeVerification);
          setDataAgeVerification(ItemData);
          // Play the sound.
          sound.play();
        }
      }
    } else {
      DataAddedToSaleTable(ItemData);
    }
  };

  const DataAddedToSaleTable = (Itemdata) => {
    if (
      Itemdata?.promptforqty === false ||
      Itemdata?.modifier?.length < 2 ||
      Itemdata.sale_id
    ) {
      const defQty =
        Itemdata?.default_qty !== null && Itemdata?.default_qty !== undefined
          ? Itemdata?.default_qty
          : 0;
      const quantity =
        qtyFormat(
          decimalQty,
          Itemdata?.modifier?.[0]?.qty !== undefined
            ? Itemdata?.modifier?.[0]?.qty
            : 1
        ) < qtyFormat(decimalQty, defQty)
          ? qtyFormat(decimalQty, defQty)
          : Itemdata?.modifier?.[0]?.qty;
      setShowPriceNumpad(mainItemList.length);
      if (Itemdata.sale_id || Itemdata.prev_added) {
        AddedDataToSale(
          Itemdata,
          quantity ? +quantity : 1,
          Itemdata.manual_item,
          Itemdata.sale_id
        );
      } else {
        if (prevState(Itemdata.id, quantity, Itemdata?.modifier?.[0]?.price)) {
          AddedDataToSale(Itemdata, +quantity, false);
        }
      }
    } else {
      if (props.agePopup) {
        // Play the sound.
        sound.play();
        setSelectQtyDialog(true);
        setDataForSelectQty(Itemdata);
      }
    }
  };

  const selectedQty = (array, qty) => {
    if (qty && array && array.id !== undefined) {
      if (prevState(array.id, qty, array?.modifier[0]?.price)) {
        AddedDataToSale(array, qty, false);
      }
    }
    closeSelectQtyDialog();
  };

  const prevState = (id, qty, price) => {
    if (checkElementExits.indexOf(id) > -1) {
      const Index = itemList?.findIndex((item) => item.id === id);
      increaseQty(
        qtyFormat(decimalQty, itemList[Index]?.qty),
        Index,
        "qty",
        qtyFormat(decimalQty, qty),
        price
      );
      return false;
    } else return true;
  };
  const AddedDataToSale = (array, qty, manual, id) => {
    const taxvalue = LottoIds.includes(array.id)
      ? 10
      : props?.taxlist?.length > 0
        ? props?.taxlist?.filter((item) => item.id === parseInt(array?.tax))
            ?.length > 0
          ? parseInt(array?.tax)
          : props?.taxlist[0]?.id
        : "";
    const inputstate = {
      id: array.id,
      qty: JSON.stringify(qty),
      increaseqty: id ? +array?.increaseqty : JSON.stringify(qty),
      manualname: array.name,
      price: array.already_add_price
        ? parseFloat(array.already_add_price)
        : array?.modifier?.[0]?.price,
      item_original_price: array.already_add_price
        ? parseFloat(array.already_add_price)
        : array?.modifier?.[0]?.price,
      orgPrice: array.already_add_price
        ? parseFloat(array.already_add_price)
        : array?.modifier?.[0]?.price,
      showPrice: array.already_add_price
        ? parseFloat(array.already_add_price)
        : array?.modifier?.[0]?.price,
      avg_cost: manual ? "0.00" : array?.modifier?.[0]?.avg_cost,
      cost: calculateSubtotal(
        array?.modifier?.[0]?.avg_cost,
        qtyFormat(decimalQty, qty)
      ),
      tax: taxvalue,
      subtotal: calculateSubtotal(
        array.already_add_price
          ? parseFloat(array.already_add_price)
          : array?.modifier?.[0]?.price,
        qtyFormat(decimalQty, qty)
      ),
      total: calculateTax(
        taxvalue,
        array.already_add_price
          ? parseFloat(array.already_add_price)
          : array?.modifier?.[0]?.price,
        qtyFormat(decimalQty, qty),
        array?.bottle_deposit,
        array?.fee
      )[0],
      showTotal: calculateTax(
        taxvalue,
        array.already_add_price
          ? parseFloat(array.already_add_price)
          : array?.modifier?.[0]?.price,
        qtyFormat(decimalQty, qty),
        array?.bottle_deposit,
        array?.fee
      )[0],
      manual: manual,
      ebt: array.ebteligible,
      ebtenable: array.ebteligible,
      rank: array.item_rank,
      default_qty: array.default_qty,
      min_price: array.price,
      bottle_deposit: array.bottle_deposit,
      fee: array.fee,
      taxTotal: calculateTax(
        taxvalue,
        array.already_add_price
          ? parseFloat(array.already_add_price)
          : array?.modifier?.[0]?.price,
        qtyFormat(decimalQty, qty),
        array.bottle_deposit,
        array.fee
      )[1],
      bottleDeposit:
        array.item_type == DEPOSITRETURNTYPE ||
        array?.itemData?.item_type == DEPOSITRETURNTYPE
          ? +array.subtotal * +qtyFormat(decimalQty, qty)
          : calculateBottleDepositTax(
              array.bottle_deposit,
              qtyFormat(decimalQty, qty),
              0
            ),
      additionalFee: calculateAdditionalFeeTax(
        array.fee,
        array.already_add_price
          ? parseFloat(array.already_add_price)
          : array?.modifier?.[0]?.price,
        qtyFormat(decimalQty, qty),
        0
      ),
      ebttotal: calculateEbtTax(
        array.ebteligible,
        defaultEbtTax,
        array.already_add_price
          ? parseFloat(array.already_add_price)
          : array?.modifier?.[0]?.price,
        qtyFormat(decimalQty, qty),
        array.bottle_deposit,
        array.fee
      ),
      bottleDepositEnable: bottleDepositEnable,
      additionalFeeEnable: additionalFeeEnable,
      bottleDepositTaxable: bottleDepositTaxable,
      itemData: { ...array, id: array.storeditemid || array.id },
      decimalPrice: array.already_add_price
        ? parseFloat(array.already_add_price)
        : array?.modifier?.[0]?.price,
      saleId: id,
      manual_item: manual,
      category: array.category,
      size: array.item_size,
      tag: array.tag,
      type: array.item_type,
      pointsmultiplier: array.pointsmultiplier || 1,
      alreadyAddedPromotion: array.alreadyAddedPromotion,
    };
    if (true) {
      setItemList((prev) => [...prev, inputstate]);
    }
    setDataAgeVerification([]);
    dispatch(RemoveSaleItemList([], REMOVEITEMSALE));
  };

  const increaseQty = (qty, index, formfield, increasedQty, price) => {
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        const setqty = +qty + +increasedQty;

        // inclusive tax
        const itemData = props?.taxlist?.find(
          (item) => item.id === parseInt(itemList[index]?.tax)
        );
        const appliedTax = props?.taxCount?.find(
          (item) => item.id === itemData?.base_tax[0]?.basetax_id
        );
        console.log(`appliedTax?.value`, appliedTax?.value);
        console.log(`price`);
        let inclusive_tax_rate = 0;
        let inclusive_tax = 0;
        if (itemData?.inclusive_tax == 1) {
          inclusive_tax_rate = parseFloat(appliedTax?.value) / 100;
          inclusive_tax = parseFloat(
            ((price * setqty) / (1 + inclusive_tax_rate)) * inclusive_tax_rate
          ).toFixed(2);
        }
        console.log(`inclusive_tax__`, inclusive_tax);
        // inclusive tax

        return {
          ...item,
          [formfield]: qtyFormat(decimalQty, setqty),
          subtotal: calculateSubtotal(price, setqty, inclusive_tax),
          total: calculateTax(
            itemList[index].tax,
            price,
            setqty,
            itemList[index].bottle_deposit,
            itemList[index].fee
          )[0],
          taxTotal: calculateTax(
            itemList[index].tax,
            price,
            setqty,
            itemList[index].bottle_deposit,
            itemList[index].fee
          )[1],
          bottleDeposit: (itemList[index].item_type == DEPOSITRETURNTYPE || itemList[index]?.itemData?.item_type == DEPOSITRETURNTYPE) ? +calculateSubtotal(price, setqty, 0) * +setqty : calculateBottleDepositTax(
            itemList[index].bottle_deposit,
            setqty,
            0
          ),
          additionalFee: calculateAdditionalFeeTax(
            itemList[index].fee,
            price,
            setqty,
            0
          ),
          ebttotal: calculateEbtTax(
            itemList[index].ebt,
            defaultEbtTax,
            price,
            setqty,
            itemList[index].bottle_deposit,
            itemList[index].fee
          ),
          cost: calculateSubtotal(itemList[index].avg_cost, setqty),
        };
      });
    });
  };

  const decreaseQty = (qty, index, formfield, decreaseqty, price, row) => {
    if (qty > decreaseqty || row?.itemData?.item_type == NEGATIVETYPE) {
      setItemList((prev) => {
        return prev.map((item, i) => {
          if (i !== index) {
            return item;
          }
          const setqty = qty - decreaseqty;

          // inclusive tax
          const itemData = props?.taxlist?.find(
            (item) => item.id === parseInt(itemList[index]?.tax)
          );
          const appliedTax = props?.taxCount?.find(
            (item) => item.id === itemData?.base_tax[0]?.basetax_id
          );
          let inclusive_tax_rate = 0;
          let inclusive_tax = 0;
          if (itemData?.inclusive_tax == 1) {
            inclusive_tax_rate = parseFloat(appliedTax?.value) / 100;
            inclusive_tax = parseFloat(
              ((price * setqty) / (1 + inclusive_tax_rate)) * inclusive_tax_rate
            ).toFixed(2);
          }
          console.log(`inclusive_tax__`, inclusive_tax);
          // inclusive tax

          return {
            ...item,
            [formfield]: qtyFormat(decimalQty, setqty),
            subtotal: calculateSubtotal(price, setqty, inclusive_tax),
            total: calculateTax(
              itemList[index].tax,
              price,
              setqty,
              itemList[index].bottle_deposit,
              itemList[index].fee
            )[0],
            taxTotal: calculateTax(
              itemList[index].tax,
              price,
              setqty,
              itemList[index].bottle_deposit,
              itemList[index].fee
            )[1],
            bottleDeposit: (itemList[index].item_type == DEPOSITRETURNTYPE || itemList[index]?.itemData?.item_type == DEPOSITRETURNTYPE) ? +calculateSubtotal(price, setqty, 0) * +setqty :calculateBottleDepositTax(
              itemList[index].bottle_deposit,
              setqty,
              0
            ),
            additionalFee: calculateAdditionalFeeTax(
              itemList[index].fee,
              price,
              setqty,
              0
            ),
            ebttotal: calculateEbtTax(
              itemList[index].ebt,
              defaultEbtTax,
              price,
              setqty,
              itemList[index].bottle_deposit,
              itemList[index].fee
            ),
            cost: calculateSubtotal(itemList[index].avg_cost, setqty),
          };
        });
      });
    }
  };

  const CheckedEbt = (e, index) => {
    e.persist();
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {
          ...item,
          ebt: e.target.checked,
          ebttotal: calculateEbtTax(
            e.target.checked,
            defaultEbtTax,
            itemList[index].price,
            qtyFormat(decimalQty, itemList[index].qty),
            itemList[index].bottle_deposit,
            itemList[index].fee
          ),
        };
      });
    });
  };

  const handleSelectChange = (event, index, formfield) => {
    event.persist();
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        if (event.target.value !== null) {
          return {
            ...item,
            [formfield]: event.target.value,
            subtotal: calculateSubtotal(
              itemList[index].price,
              qtyFormat(decimalQty, itemList[index].qty)
            ),
            total: calculateTax(
              event.target.value,
              itemList[index].price,
              qtyFormat(decimalQty, itemList[index].qty),
              itemList[index].bottle_deposit,
              itemList[index].fee
            )[0],
            taxTotal: calculateTax(
              event.target.value,
              itemList[index].price,
              qtyFormat(decimalQty, itemList[index].qty),
              itemList[index].bottle_deposit,
              itemList[index].fee
            )[1],
            bottleDeposit: (itemList[index].item_type == DEPOSITRETURNTYPE || itemList[index]?.itemData?.item_type == DEPOSITRETURNTYPE) ? +itemList[index].subtotal * +qtyFormat(decimalQty, itemList[index].qty) :calculateBottleDepositTax(
              itemList[index].bottle_deposit,
              qtyFormat(decimalQty, itemList[index].qty),
              0
            ),
            additionalFee: calculateAdditionalFeeTax(
              itemList[index].fee,
              itemList[index].price,
              qtyFormat(decimalQty, itemList[index].qty),
              0
            ),
            ebttotal: calculateEbtTax(
              itemList[index].ebt,
              defaultEbtTax,
              itemList[index].price,
              qtyFormat(decimalQty, itemList[index].qty),
              itemList[index].bottle_deposit,
              itemList[index].fee
            ),
          };
        }
      });
    });
  };

  const ConfirmAgeVerification = (cond) => {
    if (cond) {
      DataAddedToSaleTable(DataAgeVerification);
    } else {
      setOpenErrorMsg(true);
    }
    setAgePopup(false);
    setAgeNumber(0);
    dispatch(AgePopUpShow(false, AGEPOPUPSHOW));
  };

  const handleQtyChange = (event, index, formfield) => {
    event.persist();
    setFocus(formfield);

    // if (event.target.value?.length > 5) {
    //   setOpenErrorMsg(true);
    //   setErrorMsg("Qty can not be greater than 5 digits.");
    //   return;
    // }

    if (event.target.value.length > 5 && localStorage.getItem("isScanned") == "false") {
      setOpenErrorMsg(true);
      setErrorMsg("Qty can not be greater than 5 digits.");
      return;
    }

    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        const qty =
          event.target.value !== ""
            ? qtyFormat(decimalQty, event.target.value)
            : '';
        return {
          ...item,
          [formfield]: qty,
          subtotal: calculateSubtotal(itemList[index].price, qty),
          total: calculateTax(
            itemList[index].tax,
            itemList[index].price,
            qty,
            itemList[index].bottle_deposit,
            itemList[index].fee
          )[0],
          taxTotal: calculateTax(
            itemList[index].tax,
            itemList[index].price,
            qty,
            itemList[index].bottle_deposit,
            itemList[index].fee
          )[1],
          bottleDeposit: (itemList[index].item_type == DEPOSITRETURNTYPE || itemList[index]?.itemData?.item_type == DEPOSITRETURNTYPE) ? +calculateSubtotal(itemList[index].price, qty) * +qty :calculateBottleDepositTax(
            itemList[index].bottle_deposit,
            qty,
            0
          ),
          additionalFee: calculateAdditionalFeeTax(
            itemList[index].fee,
            itemList[index].price,
            qty,
            0
          ),
          ebttotal: calculateEbtTax(
            itemList[index].ebt,
            defaultEbtTax,
            itemList[index].price,
            qty,
            itemList[index].bottle_deposit,
            itemList[index].fee
          ),
          cost: calculateSubtotal(itemList[index].avg_cost, qty),
        };
      });
    });
  };

  const handleNameChange = (event, index, formfield) => {
    localStorage.setItem("formField", formfield);
    event.persist();
    setFocus(formfield);
    setItemList((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return {
          ...item,
          [formfield]: event.target.value,
        };
      });
    });
  };

  const handlePriceEnter = (event, index, formfield, qty) => {
    event.persist();
    setFocus(formfield);
    localStorage.setItem("rowIndex", index)
    localStorage.setItem("formField", formfield);
    if (!isNaN(event.target.value || event.target.value === ".")) {
      if (event.target.value.length > 8) {
        // setOpenErrorMsg(true);
        // setErrorMsg("Price can not be greater than 8 digits.");
        // return;
      }
      setItemList((prev) => {
        return prev.map((item, i) => {
          if (i !== index) {
            return item;
          }
          let price = event.target.value !== "" ? event.target.value : 0;
          const setqty = qtyFormat(decimalQty, qty);
          return {
            ...item,
            [formfield]: payOutIds.includes(mainItemList[index].itemData.id) || mainItemList[index].itemData?.item_type === NEGATIVETYPE
              ? price > 0
                ? parseInt(-price)
                : price
              : price,
            showPrice:
              price.length == 1 &&
                payOutIds.includes(mainItemList[index].itemData.id) || mainItemList[index].itemData?.item_type === NEGATIVETYPE
                ? parseInt(-price)
                : price,
            subtotal: calculateSubtotal(price, setqty),
            total: calculateTax(
              itemList[index].tax,
              price,
              setqty,
              itemList[index].bottle_deposit,
              itemList[index].fee
            )[0],

            taxTotal: (mainItemList[index]?.item_type == DEPOSITRETURNTYPE || mainItemList[index]?.itemData?.item_type == DEPOSITRETURNTYPE) ? 0 : calculateTax(
              itemList[index].tax,
              price,
              setqty,
              itemList[index].bottle_deposit,
              itemList[index].fee
            )[1],
            bottleDeposit: (itemList[index].item_type == DEPOSITRETURNTYPE || itemList[index]?.itemData?.item_type == DEPOSITRETURNTYPE) ? +calculateSubtotal(price, setqty) * +setqty :calculateBottleDepositTax(
              itemList[index].bottle_deposit,
              setqty,
              0
            ),
            additionalFee: calculateAdditionalFeeTax(
              itemList[index].fee,
              price,
              setqty,
              0
            ),
            ebttotal: calculateEbtTax(
              itemList[index].ebt,
              defaultEbtTax,
              price,
              setqty,
              itemList[index].bottle_deposit,
              itemList[index].fee
            ),
          };
        });
      });
    }
    setChange(true);
  };

  useEffect(() => {
    setChange(false);
  }, [props?.salesitemList]);


  const getPriceValue = (data) => {
    if (isNaN(+itemList[data.index].decimalPrice) && data.value === "") {
      priceKeyRef.current[data.index].select();
      setPriceQtyError({
        open: true,
        msg: "Please enter valid price.",
        index: data.index,
        type: "price",
      });
    }
    if (data.type === "priceKeypad" && data.value !== "") {
      setItemList((prev) => {
        return prev.map((item, i) => {
          if (i !== data.index) {
            return item;
          }
          if (data.value === "ArrowRight") {
            priceKeyRef.current[data.index].selectionStart =
              itemList[data.index].price.length;
            priceKeyRef.current[data.index].focus();
            setFocusDirPrice({ index: data.index, right: true, left: false });
            setLeft(false);
            return {
              ...item,
            };
          } else if (data.value === "ArrowLeft") {
            priceKeyRef.current[data.index].selectionEnd = 0;
            priceKeyRef.current[data.index].focus();
            setFocusDirPrice({ index: data.index, right: false, left: true });
            setLeft(true);
            return {
              ...item,
            };
          } else if (data.value === "Clear") {
            setLeft(false);
            return {
              ...item,
              price: "",
              showPrice: "",
              decimalPrice: "",
            };
          } else {
            const setQty = qtyFormat(decimalQty, itemList[data.index].qty);
            let inputVal = validateInputVal(
              data.value,
              itemList[data.index].decimalPrice,
              clearValue,
              priceKeyRef.current[data.index].selectionEnd,
              EnableKeypadDecimal
            );
            setFocusDirPrice({ index: data.index, right: false, left: false, focus: priceKeyRef.current[data.index].selectionEnd + 1 });
            setChange(true);
            const setpriceValue = inputVal;
            const setprice =
              EnableKeypadDecimal !== 0
                ? Number.isInteger(+setpriceValue)
                  ? (parseFloat(setpriceValue) / 100).toFixed(2)
                  : parseFloat(setpriceValue.replace(/\./g, "") / 100).toFixed(2) != "NaN" ? parseFloat(setpriceValue.replace(/\./g, "") / 100).toFixed(2) : setpriceValue.replace(/\./g, "")
                : payOutIds.includes(mainItemList[data.index]?.itemData?.id) || mainItemList[data.index].itemData?.item_type === NEGATIVETYPE
                  ? setpriceValue > 0
                    ? -setpriceValue
                    : setpriceValue
                  : setpriceValue;
            if (String(setprice).length <= 8) {
              return {
                ...item,
                price: setprice,
                showPrice: setprice,
                decimalPrice: setpriceValue,
                subtotal: calculateSubtotal(setprice, setQty),
                total: calculateTax(
                  itemList[data.index].tax,
                  setprice,
                  setQty,
                  itemList[data.index].bottle_deposit,
                  itemList[data.index].fee
                )[0],
                taxTotal: calculateTax(
                  itemList[data.index].tax,
                  setprice,
                  setQty,
                  itemList[data.index].bottle_deposit,
                  itemList[data.index].fee
                )[1],
                bottleDeposit: (itemList[data.index].item_type == DEPOSITRETURNTYPE || itemList[data.index]?.itemData?.item_type == DEPOSITRETURNTYPE) ? +itemList[data.index].subtotal * +setQty :calculateBottleDepositTax(
                  itemList[data.index].bottle_deposit,
                  setQty,
                  0
                ),
                additionalFee: calculateAdditionalFeeTax(
                  itemList[data.index].fee,
                  setprice,
                  setQty,
                  0
                ),
                ebttotal: calculateEbtTax(
                  itemList[data.index].ebt,
                  defaultEbtTax,
                  setprice,
                  setQty,
                  itemList[data.index].bottle_deposit,
                  itemList[data.index].fee
                ),
              };
            } else {
              setOpenErrorMsg(true);
              setErrorMsg("Price can not be greater than 8 digits.");
              return {
                ...item,
              };
            }
          }
        });
      });
      setClearValue(false);
    }
  };

  const getQtyValue = (data) => {
    if (isNaN(+ itemList[data.index].qty) && data.value === "") {
      qtyKeyRef.current[data.index].select()
      setPriceQtyError({
        open: true,
        msg: "Please enter valid quantity.",
        index: data.index,
        type: "qty",
      });
    }
    if (data.type === "qtyKeypad" && data.value !== "") {
      setItemList((prev) => {
        return prev.map((item, i) => {
          if (i !== data.index) {
            return item;
          }
          if (data.value === "ArrowRight") {
            qtyKeyRef.current[data.index].selectionStart =
              itemList[data.index].qty.length;
            qtyKeyRef.current[data.index].focus();
            setFocusDirQty({ index: data.index, right: true, left: false })
            return {
              ...item,
            };
          } else if (data.value === "ArrowLeft") {
            qtyKeyRef.current[data.index].selectionEnd = 0;
            qtyKeyRef.current[data.index].focus();
            setFocusDirQty({ index: data.index, right: false, left: true })
            return {
              ...item,
            };
          } else if (data.value === "Clear") {
            return {
              ...item,
              qty: '',
            };
          }
          else {
            const setqty = validateInputVal(
              data.value,
              itemList[data.index].qty,
              clearValue,
              qtyKeyRef.current[data.index].selectionEnd
            );
            const qty = qtyFormat(decimalQty, setqty);
            setFocusDirQty({ index: data.index, right: false, left: false, focus: qtyKeyRef.current[data.index].selectionEnd + 1 })
            if (String(qty).length <= 5) {
              return {
                ...item,
                qty: qty,
                subtotal: calculateSubtotal(itemList[data.index].price, qty),
                total: calculateTax(
                  itemList[data.index].tax,
                  itemList[data.index].price,
                  qty,
                  itemList[data.index].bottle_deposit,
                  itemList[data.index].fee
                )[0],
                taxTotal: calculateTax(
                  itemList[data.index].tax,
                  itemList[data.index].price,
                  qty,
                  itemList[data.index].bottle_deposit,
                  itemList[data.index].fee
                )[1],
                bottleDeposit: (itemList[data.index].item_type == DEPOSITRETURNTYPE || itemList[data.index]?.itemData?.item_type == DEPOSITRETURNTYPE) ? +itemList[data.index].subtotal * +qty :calculateBottleDepositTax(
                  itemList[data.index].bottle_deposit,
                  qty,
                  0
                ),
                additionalFee: calculateAdditionalFeeTax(
                  itemList[data.index].fee,
                  itemList[data.index].price,
                  qty,
                  0
                ),
                ebttotal: calculateEbtTax(
                  itemList[data.index].ebt,
                  defaultEbtTax,
                  itemList[data.index].price,
                  qty,
                  itemList[data.index].bottle_deposit,
                  itemList[data.index].fee
                ),
                cost: calculateSubtotal(itemList[data.index].avg_cost, qty),
              };
            } else {
              setOpenErrorMsg(true);
              setErrorMsg("Qty can not be greater than 5 digits.");
              return {
                ...item,
              };
            }
          }
        });
      });
      setClearValue(false);
    }
  };

  const handleKeyPress = (event, index, type, formField) => {
    dispatch(ShowNumpadStatus(true, NUMPADSTATUS));
    // setShowPriceNumpad(true);
    localStorage.setItem("rowIndex", index)
    localStorage.setItem("formField", formField);
    if (index !== undefined && type !== undefined) {
      event.target.select();
      setClearValue(true);
      type === "price" ? setShowPriceNumpad(index) : setShowQtyNumpad(index);
    } else {
      if (event.key === "Enter") {
        if (formField == "price") {
          priceKeyRef.current.blur();
        }
        setShowPriceNumpad(false);
        checkMinimumPrice(index);
        setShowQtyNumpad(false);
        setFocus(false);
      }
    }
  };

  const handleOnBlur = (e, index, formfield) => {
    const settings = props.posSetting || props.PosSetting;
    console.log("localStorage.getItem", localStorage.getItem("isScanned"));
    if (e?.relatedTarget?.nodeName == "INPUT") {
      setShowQtyNumpad(false);
    }
    if (e.target.value?.length > 5) {
      if (localStorage.getItem("isScanned") == "false") {
        setOpenErrorMsg(true);
        setErrorMsg("Qty can not be greater than 5 digits.");
      }
      setItemList((prev) => {
        return prev.map((item, i) => {
          if (i !== index) {
            return item;
          }
          return {
            ...item,
            qty: 1,
          };
        });
      });
      localStorage.setItem("isScanned", false);
      return;
    } else {
      setItemList((prev) => {
        return prev.map((item, i) => {
          if (i !== index) {
            return item;
          }
          const qty =
            e.target.value !== "" ? qtyFormat(decimalQty, e.target.value) : '';
          return {
            ...item,
            [formfield]: qty,
            subtotal: calculateSubtotal(itemList[index].price, qty),
            total: calculateTax(
              itemList[index].tax,
              itemList[index].price,
              qty,
              itemList[index].bottle_deposit,
              itemList[index].fee
            )[0],
            taxTotal: calculateTax(
              itemList[index].tax,
              itemList[index].price,
              qty,
              itemList[index].bottle_deposit,
              itemList[index].fee
            )[1],
            bottleDeposit: (itemList[index].item_type == DEPOSITRETURNTYPE || itemList[index]?.itemData?.item_type == DEPOSITRETURNTYPE) ? +calculateSubtotal(itemList[index].price, qty) * +qty :calculateBottleDepositTax(
              itemList[index].bottle_deposit,
              qty,
              0
            ),
            additionalFee: calculateAdditionalFeeTax(
              itemList[index].fee,
              itemList[index].price,
              qty,
              0
            ),
            ebttotal: calculateEbtTax(
              itemList[index].ebt,
              defaultEbtTax,
              itemList[index].price,
              qty,
              itemList[index].bottle_deposit,
              itemList[index].fee
            ),
            cost: calculateSubtotal(itemList[index].avg_cost, qty),
          };
        });
      });
    }
    if (!(showQtyNumpad === index && numpadStatus)) {
      if (
        !checkValue(settings?.SALE?.allow_decimal_qty_sale) &&
        !Number.isInteger(parseFloat(e.target.value)) &&
        (e.target.value !== "" || e.target.value != 0)
      ) {
        setShow(VALIDATION);
      }
    } else {
      e.target.focus();
    }
  };

  const handlePriceOnBlur = (e, index) => {
    setChange(false);
    let ScannedCode = localStorage.getItem("barcode")
    if (e?.relatedTarget?.nodeName == "INPUT") {
      setShowPriceNumpad(false);
    }
    if (!isNaN(e.target.value || e.target.value === ".")) {
      if (e.target.value?.length > 8) {
        if (localStorage.getItem("isScanned") == "false") {
          setOpenErrorMsg(true);
          setErrorMsg("Price can not be greater than 8 digits.");
          // return;
        }
        setItemList((prev) => {
          return prev.map((item, i) => {
            if (i !== index) {
              return item;
            }
            return {
              ...item,
              price: item?.price.endsWith(ScannedCode) ? item?.price.slice(0, -ScannedCode?.length) : '',
              showPrice: item?.price.endsWith(ScannedCode) ? item?.price.slice(0, -ScannedCode?.length) : '',
            };
          });
        });
      } else if (localStorage.getItem("isScanned") == "true") {
        localStorage.setItem("isScanned", false);
      }
    }
    localStorage.removeItem("barcode");
  };

  const handleClose = (e) => {
    setShow(false);
  };

  const checkMinimumPrice = (index) => {
    setLeft(false);
    if (index !== undefined) {
      const minPrice =
        itemList[index]?.min_price && itemList[index]?.min_price !== null
          ? parseFloat(itemList[index]?.min_price)
          : 0;
      const Price =
        itemList[index]?.price && itemList[index]?.price !== null
          ? parseFloat(itemList[index]?.price)
          : 0;
      if (minPrice > Price) {
        if (payOutIds.includes(mainItemList[index].itemData.id) || itemList[index]?.min_price == "" || itemList[index].itemData?.item_type === NEGATIVETYPE) {
          setShowPriceNumpad(false);
          setPriceCheckList((prev) =>
            prev.filter((item) => item !== prev[index])
          );
        } else {
          const listData = [...priceCheckList];
          const data = {
            id: itemList[index].id,
            name: itemList[index].manualname,
            minPrice: minPrice,
          };
          const listCheck = listData?.filter(
            (item) => item.id === itemList[index]?.id
          );
          if (listCheck && listCheck !== undefined && listCheck?.length > 0) {
            const Index = listData.findIndex((item) => item.id === data.id);
            listData[Index] = data;
          } else {
            listData.push(data);
          }
          if (props?.PosSetting?.SALE?.allow_negative_item_prices == 0 || minPrice > Price) {
            setShow("Price");
            setItemList((prev) => {
              return prev.map((item, i) => {
                if (i !== index) {
                  return item;
                }
                let price = minPrice
                const setqty = qtyFormat(decimalQty, mainItemList[index]?.qty);
                return {
                  ...item,
                  "price": payOutIds.includes(mainItemList[index].itemData.id) || mainItemList[index].itemData?.item_type === NEGATIVETYPE
                    ? price > 0
                      ? parseInt(-price)
                      : price
                    : price,
                  showPrice:
                    price.length == 1 &&
                      payOutIds.includes(mainItemList[index].itemData.id) || mainItemList[index].itemData?.item_type === NEGATIVETYPE
                      ? parseInt(-price)
                      : price,
                  subtotal: calculateSubtotal(price, setqty),
                  total: calculateTax(
                    itemList[index].tax,
                    price,
                    setqty,
                    itemList[index].bottle_deposit,
                    itemList[index].fee
                  )[0],

                  taxTotal: calculateTax(
                    itemList[index].tax,
                    price,
                    setqty,
                    itemList[index].bottle_deposit,
                    itemList[index].fee
                  )[1],
                  bottleDeposit: (itemList[index].item_type == DEPOSITRETURNTYPE || itemList[index].itemData?.item_type == DEPOSITRETURNTYPE) ? +itemList[index].subtotal * +setqty :calculateBottleDepositTax(
                    itemList[index].bottle_deposit,
                    setqty,
                    0
                  ),
                  additionalFee: calculateAdditionalFeeTax(
                    itemList[index].fee,
                    price,
                    setqty,
                    0
                  ),
                  ebttotal: calculateEbtTax(
                    itemList[index].ebt,
                    defaultEbtTax,
                    price,
                    setqty,
                    itemList[index].bottle_deposit,
                    itemList[index].fee
                  ),
                };
              });
            });
          }
          setPriceCheckList(listData);
          setShowPriceNumpad(false);
          setChange(false);
        }
      } else {
        setShowPriceNumpad(false);
        setPriceCheckList((prev) =>
          prev.filter((item) => item !== prev[index])
        );
      }
    }
  };

  useEffect(() => {
    setNumpadVisible(props?.numpadstatus)
    setDisplayList((prev) => prev.map((i) => {
      return { ...i, change: false }
    }))
  }, [props?.numpadstatus])

  const numpad = (cell, row, rowIndex) => {
    if (row.promotionData || row.discountData) {
      return "";
    } else {
      rowIndex = row.myIndex;
      let disableInput = priceChange == 2 || mainItemList[rowIndex]?.manual_item
        ? false
        : parseFloat(row.orgPrice) == 0
          ? false
          : [11, LOTTOPAYOUTTYPE, ONLINEPAYOUTTYPE, ONLINELOTTERYTYPE, LOTTOSALETYPE].includes(
            mainItemList[rowIndex]?.itemData?.item_type
          )
            ? false
            : LottoIds.includes(mainItemList[rowIndex]?.itemData?.id)
              ? false
              : +mainItemList[rowIndex]?.orgPrice < 0
                ? false
                : mainItemList[rowIndex]?.itemData?.item_type == NEGATIVETYPE
                  ? false
                  : true
      return (
        <>
          <div className="numpad-container" key={rowIndex}>
            <input
              className="price-bg form-control"
              type="text"
              value={
                payOutIds.includes(mainItemList[rowIndex]?.itemData?.id) &&
                  row.showPrice > 0 || (mainItemList[rowIndex]?.itemData?.item_type == NEGATIVETYPE && row.showPrice > 0) || ((mainItemList[rowIndex].item_type == DEPOSITRETURNTYPE || mainItemList[rowIndex]?.itemData?.item_type == DEPOSITRETURNTYPE) && row.showPrice > 0)
                  ? -row.showPrice
                  : row.showPrice
              }
              autoFocus={
                focus === "price" || mainItemList?.length - 1 == rowIndex
              }
              ref={(el) => (priceKeyRef.current[rowIndex] = el)}
              onChange={(e) => handlePriceEnter(e, rowIndex, "price", row.qty)}
              disabled={disableInput}
              onClick={(e) => handleKeyPress(e, rowIndex, "price")}
              onBlur={(e) => handlePriceOnBlur(e, rowIndex)}
              onKeyPress={(e) =>
                handleKeyPress(e, rowIndex, undefined, "price")
              }
              placeholder="Price"
            />
            {showPriceNumpad === rowIndex &&
              numpadStatus &&
              // props.numpadstatus &&
              !disableInput &&
              screenKeypad === true && numpadVisible && (
                // || (mainItemList[rowIndex]?.itemData.modifier?.[0].price == "0.00" && screenKeypad === true))  &&(
                <CustomNumpad
                  type="priceKeypad"
                  setIndex={rowIndex}
                  setValue={getPriceValue}
                  hideNumpad={
                    !priceQtyError?.open
                      ? () => checkMinimumPrice(rowIndex)
                      : () => { setLeft(false) }
                  }
                />
              )}
          </div>
        </>
      );
    }
  };

  const handleDialogUpdateItem = (id, manual) => {
    if (window.navigator.onLine == true) {
      if (!manual) {
        try {
          let itemId = mainItemList.find((e) => e.id == id)?.itemData?.id;
          setSelectedItemId(itemId);
          if (!checkUserPermission("allow_edit_item_view")) {
            setAuth("viewEditItem");
            setAuthType("allow_edit_item_view");
          } else {
            setShow(ITEM);
            setItemData(itemId);
          }
        } catch (error) {
          console.error("error ", error);
          setShow(null);
          setItemData({});
        }
      } else {
        setItemCondition(true);
      }
    } else {
      setOpenErrorMsg(true);
      setErrorMsg("Cannot Edit Item While System is Offline.");
    }
  };

  const actionsFormatter = (cell, row, rowIndex) => {
    rowIndex = row.myIndex;
    return (
      <div className="d-flex align-items-center">
        {row.promotionData || row.discountData ? (
          row.discountData && !row.promotionData ? (
            <Button
              className="p-0"
              variant="link"
              onClick={() => removeDiscount(row.myId)}
              title="Remove Discount"
            >
              <Image src={props?.dynamicImages?.remove} alt="remove" />
            </Button>
          ) : (
            ""
          )
        ) : (
          <>
            <Button
              className="p-0"
              type="button"
              variant="link"
              title="Plus Quantity"
              onClick={() =>
                increaseQty(
                  qtyFormat(decimalQty, row.qty),
                  rowIndex,
                  "qty",
                  qtyFormat(decimalQty, row.increaseqty),
                  row.price
                )
              }
            >
              <Image src={props?.dynamicImages?.add} alt="add" />
            </Button>
            <Button
              className="p-0"
              variant="link"
              type="button"
              onClick={() =>
                decreaseQty(
                  qtyFormat(decimalQty, row.qty),
                  rowIndex,
                  "qty",
                  qtyFormat(decimalQty, row.increaseqty),
                  row.price,
                  row
                )
              }
              title="Minus Quantity"
            >
              <Image src={props?.dynamicImages?.minus} alt="minus" />
            </Button>
            <Button
              className="p-0"
              variant="link"
              onClick={() => RemoveMoreData(rowIndex)}
              title="Remove Item"
            >
              <Image src={props?.dynamicImages?.remove} alt="remove" />
            </Button>
          </>
        )}
      </div>
    );
  };

  // const promotionsFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   return (
  //     <>
  //      <p>1% Off 2 Items On Tag Whisky (1% Off)	</p>
  //     </>
  //   );
  // };

  const editItem = (cell, row, rowIndex) => {
    if (row.promotionData || row.discountData) {
      return "";
    } else {
      rowIndex = row.myIndex;
      return (
        <>
          <Button
            onClick={() => handleDialogUpdateItem(row.id, row.manual)}
            className="p-0"
            variant="link"
            title="Edit Item"
            key={rowIndex}
          >
            <Image src={props?.dynamicImages?.more} alt="more" />
          </Button>
        </>
      );
    }
  };
  const totalFormatter = (cell, row, rowIndex) => {
    if (row.promotionData || row.discountData) {
      return <span style={{ color: "red" }}>{row.showTotal}</span>;
    } else {
      rowIndex = row.myIndex;
      if (isNaN(row.showTotal)) {
        return "0.00"
      }
      return (
        <>
          {payOutIds.includes(row.id) === false && row.itemData.item_type != NEGATIVETYPE
            ? row.showTotal
            : row.showTotal == "NaN"
              ? "0.00"
              : parseFloat(row.showTotal) < 0
                ? parseFloat(row.showTotal).toFixed(2)
                : parseFloat(-row.showTotal).toFixed(2)}
        </>
      );
    }
  };

  const handleScanningInputs = (code, index, formField) => {
    localStorage.setItem("isScanned", true);
    if (code !== "") {
      setBarcode(code);
      localStorage.setItem("barcode", code);
      setItemList((prev) => {
        return prev.map((item, i) => {
          if (i !== index) {
            return item;
          }
          if (formField == "price") {
            return {
              ...item,
              price: 0,
              showPrice: 0,
            };
          }
          return {
            ...item,
            [formField]: formField == "qty" ? 1 : 0,
          };
        });
      });
      console.log("onScaned", code);
      let filteredItems = props?.item.filter((i) => {
        return (
          i?.code[0]?.code == code ||
          i?.code[0]?.code == formatCode(code, UpcTenCode)
        );
      });
      if (filteredItems.length == 0) {
        let realtimeItems = localStorage.getItem("realtimeItem")
        if (realtimeItems) {
          filteredItems = JSON.parse(localStorage.getItem("realtimeItem")).filter((i) => {
            return (
              i?.code[0]?.code == code ||
              i?.code[0]?.code == formatCode(code, UpcTenCode)
            );
          })
        }
      }
      if (filteredItems.length > 0) {
        dispatch(AddSaleItemList(filteredItems[0], ADDITEMONSALE));
        dispatch(ShowNumpadStatus(true, NUMPADSTATUS));
      } else {
        // setBarcode(code);
        setShow(STOCKCODECHECK);
        dispatch(
          addActionFunction(ITEMNOTFOUND, {
            code: code,
            store: getDeviceAndStore()[1],
            dt: Date.now(),
          })
        );
      }
    }
    if (formField == "price") {
      // priceKeyRef.current.blur();
    }
    setShowPriceNumpad(false);
    checkMinimumPrice(index);
    setShowQtyNumpad(false);
    setFocus(false);
    scannedData = false;
    Value = '';
  }

  useEffect(() => {
    onScan.attachTo(document, {
      suffixKeyCodes: [13],
      reactToPaste: false,
      onScan: function (sCode, iQty) {
        console.log('onScan Scanned: ' + iQty + 'x ' + sCode);
        setTimeout(() => {
          Value = ''
        }, [100])
      },
      onKeyDetect: function (iKeyCode) {
        localStorage.setItem("isScanned", true);
        if (iKeyCode == 13) {
          scannedData = true
        } else {
          switch (iKeyCode) {
            case 48:
              Value += "0"
              break;
            case 49:
              Value += "1"
              break;
            case 50:
              Value += "2"
              break;
            case 51:
              Value += "3"
              break;
            case 52:
              Value += "4"
              break;
            case 53:
              Value += "5"
              break;
            case 54:
              Value += "6"
              break;
            case 55:
              Value += "7"
              break;
            case 56:
              Value += "8"
              break;
            case 57:
              Value += "9"
              break;
            default:
              Value += ""
          }
        }
      },
      onScanError: function (oDebug) {
        if (oDebug?.message == "Receieved code was not entered in time" && scannedData && localStorage.getItem("formField") != "manualname") {
          console.log("onScan oDebug", Value, oDebug)
          handleScanningInputs(Value, localStorage.getItem("rowIndex"), localStorage.getItem("formField"))
          Value = '';
        } else {
          localStorage.setItem("isScanned", false);
          Value = '';
        }
      },
      minLength: 6,
      timeBeforeScanTest: 100,
      avgTimeByChar: 20
    });
    return () => {
      onScan.detachFrom(document);
    };
  }, [document, onScan])

  const QtyEdit = (cell, row, rowIndex) => {
    if (row.promotionData || row.discountData) {
      return "";
    } else {
      rowIndex = row.myIndex;
      return (
        <>
          <div className="numpad-container" key={rowIndex}>
            <input
              type="text"
              className="form-control"
              value={row.qty}
              autoFocus={focus === "qty" && true}
              ref={(el) => (qtyKeyRef.current[rowIndex] = el)}
              onChange={(e) => handleQtyChange(e, rowIndex, "qty")}
              onClick={(e) => handleKeyPress(e, rowIndex, "qty")}
              onBlur={(e) => handleOnBlur(e, rowIndex, "qty")}
              onKeyPress={(e) => handleKeyPress(e, rowIndex, undefined, "qty")}
              placeholder="Qty"
            />
            {showQtyNumpad === rowIndex && numpadStatus && screenKeypad === true && (
              <CustomNumpad
                type="qtyKeypad"
                setIndex={rowIndex}
                setValue={getQtyValue}
                hideNumpad={!priceQtyError?.open ? setShowQtyNumpad : () => { }}
                decimalQty={decimalQty}
              />
            )}
          </div>
        </>
      );
    }
  };

  const SelectTaxList = (cell, row, rowIndex) => {
    if (row.promotionData || row.discountData) {
      return "";
    } else {
      rowIndex = row.myIndex;
      return (
        <select
          className="form-control"
          value={
            LottoIds.includes(mainItemList[rowIndex]?.itemData?.id) === true || row?.item_type == DEPOSITRETURNTYPE || row?.itemData?.item_type == DEPOSITRETURNTYPE
              ? 10
              : row?.tax !== ""
                ? parseInt(row?.tax)
                : 0
          }
          onChange={(e) => handleSelectChange(e, rowIndex, "tax")}
          disabled={taxChange && taxChange === 1 ? false : true}
          key={rowIndex}
        >
          {LottoIds.includes(mainItemList[rowIndex]?.itemData?.id) || row?.item_type == DEPOSITRETURNTYPE || row?.itemData?.item_type == DEPOSITRETURNTYPE ? (
            <option value={10}>No Tax</option>
          ) : (
            props?.taxlist?.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              );
            })
          )}
        </select>
      );
    }
  };

  const checkbox = (cell, row, rowIndex) => {
    if (row.promotionData || row.discountData) {
      return <span style={{ color: "red" }}>{row.manualname}</span>;
    } else {
      rowIndex = row.myIndex;
      return (
        <>
          <div
            className="d-flex align-items-center justify-content-between w-100"
            key={rowIndex}
          >
            {(row.manual || row?.itemData?.item_type == DEPOSITRETURNTYPE) && (
              <FormField
                type="text"
                autoFocus={focus === "manualname" && true}
                value={row.manualname}
                onChange={(e) => handleNameChange(e, rowIndex, "manualname")}
                placeholder="Enter Item Name"
                onBlur={() => setFocus(false)}
                disabled={row?.item_type == DEPOSITRETURNTYPE || row?.itemData?.item_type == DEPOSITRETURNTYPE ? true : false}
              />
            )}
            {!row.manual && row?.itemData?.item_type != DEPOSITRETURNTYPE && (
              <>
                <span>{row.manualname}</span>
                <div className="d-flex align-items-center">
                  {(row?.discountTextForItem || (row?.min_price_text && +row?.min_price > 0)) && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>
                        <span>{row?.discountTextForItem}</span><br />
                        <span>{+row?.min_price > 0 ? row?.min_price_text : ''}</span>
                      </Tooltip>}
                      trigger={["hover", "focus"]}
                    >
                      <Button className="p-0" variant="link" title="Discount Icon">
                        <Image
                          style={{
                            width: "30px",
                            height: "30px",
                            position: "relative",
                            top: "-2px",
                          }}
                          src={props?.dynamicImages?.discount}
                          alt="discount Icon"
                        />
                      </Button>
                    </OverlayTrigger>
                  )}
                  {defaultEbtEnable > 0 && (
                    <Form.Check type="checkbox" key={rowIndex}>
                      <FormCheck.Input
                        id={`defaultCheck5${rowIndex}`}
                        checked={row.ebt}
                        disabled={row.ebtenable}
                        onChange={(e) => CheckedEbt(e, rowIndex)}
                        className="me-2"
                      />
                      <FormCheck.Label
                        htmlFor={`defaultCheck5${rowIndex}`}
                        className="mb-0"
                      >
                        EBT
                      </FormCheck.Label>
                    </Form.Check>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      );
    }
  };

  // To select the qty on click the modifier button
  const closeSelectQtyDialog = () => {
    setSelectQtyDialog(false);
    setTimeout(() => {
      setDataForSelectQty([]);
      setDataAgeVerification([]);
    }, 400);
    setAgePopup(false);
    setAgeNumber(0);
    dispatch(AgePopUpShow(false, AGEPOPUPSHOW));
    dispatch(RemoveSaleItemList([], REMOVEITEMSALE));
  };

  // To Ebt Tax calculation
  const calculateEbtTax = (
    ebtCheck,
    defaultEbt,
    price,
    qty,
    botteDeposit,
    fee
  ) => {
    if (ebtCheck) {
      return calculateTax(defaultEbt, price, qty, botteDeposit, fee)[0];
    } else {
      return parseFloat(0).toFixed(2);
    }
  };

  // To  Total Tax calculation
  const calculateTax = (taxruleid, price, qty, botteDeposit, fee) => {
    if (taxruleid !== "" && parseInt(taxruleid) > 0) {
      const itemData = props?.taxlist?.find(
        (item) => item.id === parseInt(taxruleid)
      );
      let bottletax = parseFloat(0);
      let feetax = parseFloat(0);
      let bottleTaxable = parseFloat(0);
      let feeTaxable = parseFloat(0);
      if (
        itemData !== undefined &&
        itemData?.multi_mode !== undefined &&
        parseInt(itemData?.multi_mode) === 2
      ) {
        let sumtax = parseFloat(0);
        for (let i = 0; i < itemData?.base_tax?.length; i++) {
          const appliedTax = props?.taxCount?.find(
            (item) => item.id === itemData?.base_tax[i]?.basetax_id
          );
          sumtax += parseFloat(appliedTax?.value);
        }
        if (bottleDepositEnable > 0) {
          if (bottleDepositTaxable > 0) {
            bottleTaxable = calculateBottleDepositTax(
              botteDeposit,
              qty,
              sumtax
            );
          }
          bottletax = calculateBottleDepositTax(botteDeposit, qty, 0);
        }
        if (additionalFeeEnable > 0) {
          feeTaxable = calculateAdditionalFeeTax(fee, price, qty, sumtax);
          feetax = calculateAdditionalFeeTax(fee, price, qty, 0);
        }
        const total = calculateSubtotal(price, qty);
        const taxtotal =
          ((parseFloat(feeTaxable) +
            parseFloat(bottleTaxable) +
            parseFloat(total)) *
            sumtax) /
          100;
        const otherTaxTotal = parseFloat(feetax) + parseFloat(bottletax);
        return [
          parseFloat(
            parseFloat(total) +
            parseFloat(parseFloat(taxtotal)) +
            otherTaxTotal
          ),
          parseFloat(taxtotal),
        ];
      } else if (
        itemData !== undefined &&
        itemData?.multi_mode !== undefined &&
        parseInt(itemData?.multi_mode) === 1
      ) {
        if (itemData?.base_tax?.length > 0) {
          const appliedTax = props?.taxCount?.find(
            (item) => item.id === itemData?.base_tax[0]?.basetax_id
          );
          console.log(`appliedTax`, appliedTax);
          console.log(`props?.taxCount`, props?.taxCount);
          console.log(`itemData`, itemData);

          // for inclusive tax
          let inclusive_tax_rate = 0;
          let inclusive_tax = 0;
          if (itemData.inclusive_tax == 1) {
            inclusive_tax_rate = parseFloat(appliedTax?.value) / 100;
            inclusive_tax = parseFloat(
              price * qty * (1 + inclusive_tax_rate) - price * qty
            );
          }
          // for inclusive tax

          if (bottleDepositEnable > 0) {
            if (bottleDepositTaxable > 0) {
              bottleTaxable = calculateBottleDepositTax(
                botteDeposit,
                qty,
                parseFloat(appliedTax?.value)
              );
            }
            bottletax = calculateBottleDepositTax(botteDeposit, qty, 0);
          }
          if (additionalFeeEnable > 0) {
            feeTaxable = calculateAdditionalFeeTax(
              fee,
              price,
              qty,
              parseFloat(appliedTax?.value)
            );
            feetax = calculateAdditionalFeeTax(fee, price, qty, 0);
          }
          const total = calculateSubtotal(price, qty);
          const taxtotal = parseFloat(
            ((parseFloat(feeTaxable) +
              parseFloat(bottleTaxable) +
              parseFloat(total)) *
              parseFloat(appliedTax?.value ?? 0)) /
            100
          );

          const otherTaxTotal = parseFloat(feetax) + parseFloat(bottletax);
          // return [
          //   parseFloat(
          //     parseFloat(total) + parseFloat(taxtotal) + otherTaxTotal
          //   ),
          //   parseFloat(parseFloat(taxtotal)),
          // ];
          if (inclusive_tax > 0) {
            // for inclusive tax
            return [
              parseFloat(
                parseFloat(total) +
                parseFloat(otherTaxTotal)
              ),
              parseFloat(parseFloat(inclusive_tax)),
            ];
            // for inclusive tax
          } else {
            return [
              parseFloat(
                parseFloat(total) + parseFloat(taxtotal) + otherTaxTotal
              ),
              parseFloat(parseFloat(taxtotal)),
            ];
          }
        } else {
          if (additionalFeeEnable > 0) {
            feetax = calculateAdditionalFeeTax(fee, price, qty, 0);
          }
          if (bottleDepositEnable > 0) {
            bottletax = calculateBottleDepositTax(botteDeposit, qty, 0);
          }
          const otherTaxTotal = parseFloat(feetax) + parseFloat(bottletax);
          const totalTax = calculateSubtotal(price, qty);
          return [
            parseFloat(parseFloat(totalTax) + otherTaxTotal),
            parseFloat(0),
          ];
        }
      } else {
        if (additionalFeeEnable > 0) {
          feetax = calculateAdditionalFeeTax(fee, price, qty, 0);
        }
        if (bottleDepositEnable > 0) {
          bottletax = calculateBottleDepositTax(botteDeposit, qty, 0);
        }
        const otherTaxTotal = parseFloat(feetax) + parseFloat(bottletax);
        const totalTax = calculateSubtotal(price, qty);
        return [
          parseFloat(parseFloat(totalTax) + otherTaxTotal),
          parseFloat(0),
        ];
      }
    } else {
      const totalTax = calculateSubtotal(price, qty);
      return [parseFloat(totalTax), parseFloat(0)];
    }
  };

  const calculateBottleDepositTax = (bottleDeposit, qty, sumtax) => {
    if (
      bottleDepositEnable > 0 &&
      bottleDeposit !== undefined &&
      bottleDeposit !== null
    ) {
      const appliedbottle = props?.bottleDeposit?.find(
        (item) => item.id === parseInt(bottleDeposit)
      );
      if (appliedbottle){
        if (bottleDepositTaxable > 0 && sumtax > 0) {
          return appliedbottle?.amount * qty;
        } else {
          const bottleTax = appliedbottle?.amount * qty;
          return Number(bottleTax).toFixed(2);
        }
      } else{
        return Number(+bottleDeposit * qty).toFixed(2);
      }
    } else {
      return Number(0).toFixed(2);
    }
  };

  const calculateAdditionalFeeTax = (additionalFees, price, qty, sumtax) => {
    if (
      additionalFeeEnable > 0 &&
      additionalFees !== undefined &&
      additionalFees?.length > 0
    ) {
      let sumFee = parseFloat(0);
      let perfee = parseFloat(0);
      let sumfeeTax = parseFloat(0);
      let perfeeTax = parseFloat(0);
      for (let i = 0; i < additionalFees?.length; i++) {
        const appliedFee = props?.additionalFee?.find(
          (item) => item.id === parseInt(additionalFees[i]?.fee)
        );
        if (appliedFee?.type === 1) {
          if (appliedFee?.taxable === 1) {
            perfeeTax += (parseFloat(appliedFee?.value) * price) / 100;
          }
          perfee += (parseFloat(appliedFee?.value) * price) / 100;
        } else {
          if (appliedFee?.taxable === 1) {
            sumfeeTax += parseFloat(appliedFee?.value);
          }
          sumFee += parseFloat(appliedFee?.value);
        }
      }
      if (sumtax > 0 && parseFloat(sumfeeTax + perfeeTax) > 0) {
        return qty * parseFloat(sumfeeTax + perfeeTax);
      } else {
        return Number(parseFloat(sumFee + perfee) * qty).toFixed(2);
      }
    } else {
      return Number(0).toFixed(2);
    }
  };

  const calculateSubtotal = (price, qty, inclusive_tax = null) => {
    if (price !== "" && qty !== "") {
      if (inclusive_tax !== null && inclusive_tax !== "NaN") {
        return Number(parseFloat(price * qty - inclusive_tax));
      } else {
        return Number(parseFloat(+price * +qty));
      }
    } else {
      return parseFloat(0);
    }
  };

  const CheckVerified = (id) => {
    if (checkElementExits.indexOf(id) > -1) return true;
    else return false;
  };

  const columns = [
    {
      dataField: "qty",
      text: "Qty",
      sort: true,
      formatter: QtyEdit,
      formatExtraData: showQtyNumpad,
      attrs: {
        "data-title": "Qty",
      },
    },
    {
      dataField: "manualname",
      text: "Name",
      sort: true,
      formatter: checkbox,
      attrs: {
        "data-title": "Name",
      },
    },
    {
      dataField: "showPrice",
      text: "Price",
      sort: true,
      formatter: numpad,
      formatExtraData: showPriceNumpad,
      attrs: {
        "data-title": "Price",
      },
    },
    {
      dataField: "tax",
      text: "Tax",
      sort: true,
      formatter: SelectTaxList,
      attrs: {
        "data-title": "Tax",
      },
    },
    {
      dataField: "showTotal",
      text: "Total",
      sort: true,
      formatter: totalFormatter,
      attrs: {
        "data-title": "Total",
      },
    },
    {
      dataField: "Options",
      text: "Edit",
      sort: true,
      formatter: editItem,
      attrs: {
        "data-title": "Edit",
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
      formatter: actionsFormatter,
      attrs: {
        "data-title": "Actions",
      },
    },
    // {
    //   dataField: "promotions",
    //   text: "",
    //   formatter: promotionsFormatter,
    //   attrs: {
    //     "data-title": "Promotions",
    //   },
    // },
  ];

  const rowClasses = (row, rowIndex) => {
    if (row.promotionData) {
      console.log("Applying class to row:", rowIndex);
      // If the row has promotion data, apply a custom class
      return "promotion-row";
    } else if (row.discountData) {
      // Apply a class for rows with discountData
      return "discount-row";
    } else {
      // If not, apply a different class or leave it empty
      return "";
    }
  };

  const rowStyle = (row) => {
    switch (row.rank) {
      case "A":
        return { backgroundColor: "#e8f9dd", borderColor: "#ddd" };
      case "B":
        return { backgroundColor: "#dce2ff", borderColor: "#ddd" };
      case "C":
        return { backgroundColor: "#fffed6", borderColor: "#ddd" };
      case "D":
        return { backgroundColor: "#fff2f2", borderColor: "#ddd" };
      case "M":
        return { backgroundColor: "#f2dede", borderColor: "#ddd" };
      default:
        return { backgroundColor: "#f5f5f5", borderColor: "#ddd" };
    }
  };
  const handleClosepopUp = (e) => {
    e.preventDefault();
    dispatch(AgePopUpShow(false, AGEPOPUPSHOW));
    setAgePopup(false);
    setOpenErrorMsg(false);
    setErrorMsg("");
    setDataAgeVerification([]);
    setAgeNumber(0);
    dispatch(RemoveSaleItemList([], REMOVEITEMSALE));
  };

  const handlePriceQtyErrorClose = (e) => {
    e.preventDefault();
    setPriceQtyError({ open: false });
    setClearValue(true);
    if (priceQtyError?.type === "price") {
      priceKeyRef.current[priceQtyError?.index].select();
      setShowPriceNumpad(priceQtyError?.index);
    }
    if (priceQtyError?.type === "qty") {
      qtyKeyRef.current[priceQtyError?.index].select();
      setShowQtyNumpad(priceQtyError?.index);
    }
  };

  const handleDailogPayClose = () => {
    setShow(false);
    setErrorMsg("");
    localStorage.setItem("isScanned", false);
  };

  const handleBrowseItem = () => {
    alert("Browse item ");
    localStorage.setItem("isScanned", false);
  };

  const handleCloseEdit = (cond) => {
    if (cond) {
      setShow(null);
      setItemData({});
    }
  };

  // Expand row
  const size = useWindowSize();
  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: "0 px",
      height: "0 px",
    });

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  const expandRow = {
    renderer: (row) => {
      return (
        <Fade appear={true} in={true}>
          <ul>
            {/* <li className="mb-2">
              <span className="dtr-title">Name</span>
              <span className="dtr-data">{checkbox(null, row)}</span>
            </li> */}
            <li className="mb-2">
              <span className="dtr-title">Tax</span>
              <span className="dtr-data">{SelectTaxList(null, row)}</span>
            </li>
            <li className="mb-2">
              <span className="dtr-title">Total</span>
              <span className="dtr-data">{totalFormatter(null, row)}</span>
            </li>
            <li>
              <span className="dtr-title">Edit</span>
              <span className="dtr-data">{editItem(null, row)}</span>
            </li>
          </ul>
        </Fade>
      );
    },
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return expendColorShow(isAnyExpands);
    },
    expandColumnRenderer: ({ expanded }) => {
      return expendColorShow(expanded);
    },
  };

  const expendColorShow = (value) => {
    if (value) {
      return (
        <Image
          className="expand-row-icon"
          src={props?.dynamicImages?.minusIcon}
          alt="minus icon"
        />
      );
    }
    return (
      <Image
        className="expand-row-icon"
        src={props?.dynamicImages?.plusIcon}
        alt="plus icon"
      />
    );
  };

  const expandRow2 = {
    renderer: () => { },
  };

  return (
    <>
      <CustomScroll
        addScrolledClass
        heightRelativeToParent="calc(100% - 0px)"
        className={
          size.width !== undefined && size.width >= 1 && size.width <= 767
            ? "expand-table-row "
            : ""
        }
      >
        <BootstrapTable2
          columns={columns}
          data={displayList}
          keyData="id"
          keyField="id"
          rowStyle={rowStyle}
          rowClasses={rowClasses}
          expandRow={
            size.width !== undefined && size.width >= 1 && size.width <= 767
              ? expandRow
              : expandRow2
          }
        />

        {/* 
        {priceCheckList.length ? (
          <ul>
            {priceCheckList.map((e) => {
              if (e?.name) {
                return (
                  <li style={{ color: "red" }}>Item {e.name} has minimum price {currencyFormat(e.minPrice)}</li>
                );
              }
            })}
          </ul>
        ) : (
          ""
        )} */}

        {/* {totalDiscount > 0 && (
          <>
            <h2 style={{ color: "red" }}>
              DISCOUNT ({totalDiscountValue}%)&nbsp;&nbsp;&nbsp;&nbsp;
              {currencyFormat(totalDiscount)}
            </h2>
            <Button onClick={() => removeDiscount()}>cancel</Button>
          </>
        )} */}
      </CustomScroll>
      {/* Error message for minimum price indicator */}
      <CustomModal
        open={show === "Price" && priceCheckList?.length > 0}
        handleClose={() => setShow(false)}
        dialogClassName="custom-modal confirmation error-popup"
        title="Error"
        content={
          <>
            {priceCheckList?.length > 0 && (
              <div className="minimum-price-indicator">
                <ListGroup as="ul">
                  {priceCheckList?.map((data, i) => {
                    return (
                      <ListGroup.Item
                        variant="primary"
                        as="li"
                        key={i}
                        numbered="true"
                      >
                        {`Item ${data?.name} Has Minimum Price ${currencyFormat(
                          data?.minPrice
                        )}`}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
                <p>
                  Item(s) price set to minimum price and adjust the discount
                  accordingly.
                </p>
              </div>
            )}
          </>
        }
        action={
          <Button
            type="button"
            onClick={() => setShow(null)}
            variant="danger px-5 m-auto"
          >
            OK
          </Button>
        }
      />
      {/* Select qty popup */}
      <CustomModal
        open={itemcondition}
        handleClose={() => setItemCondition(false)}
        dialogClassName="custom-modal confirmation error-popup"
        title="Error"
        content={
          <h1>
            <Image
              className="me-3"
              src={props?.dynamicImages?.warningRed}
              alt="warning Icon"
            />
            This item not exist anymore.
          </h1>
        }
        action={
          <Button
            type="button"
            onClick={() => setItemCondition(false)}
            variant="primary px-5"
          >
            OK
          </Button>
        }
      />
      {/* Select qty popup */}
      <CustomModal
        open={selectQtyDialog}
        handleClose={closeSelectQtyDialog}
        dialogClassName="custom-modal confirmation select-qty"
        title="Please Select Qty"
        content={
          <>
            <h1 className="mb-3">{dataForSelectQty?.name || "-"}</h1>
            <ListGroup as="ul" horizontal>
              {dataForSelectQty?.modifier !== undefined
                ? dataForSelectQty?.modifier
                  ?.filter((item) => {
                    return item.prompt !== false;
                  })
                  ?.map((data, i) => {
                    return (
                      <ListGroup.Item
                        as="li"
                        key={i}
                        onClick={() =>
                          selectedQty(dataForSelectQty, data.qty)
                        }
                      >
                        <p>{data.qty}</p>
                        <span>{currencyFormat(data.price)}</span>
                      </ListGroup.Item>
                    );
                  })
                : ""}
            </ListGroup>
          </>
        }
      />
      <CustomModal
        open={show === VALIDATION}
        handleClose={handleClose}
        dialogClassName={`custom-modal confirmation error-popup`}
        title={"Invalid quantity"}
        content={<h1 className="mb-3 mt-3">Decimal value not allowed</h1>}
        action={
          <>
            <Button
              type="button"
              onClick={handleClose}
              // variant={`${show === ERROR ? `outline-blackcustom m-auto` : `outline-blackcustom`}px-5 `}
              variant="outline-blackcustom px-4 m-0"
            >
              Ok
            </Button>
          </>
        }
      />
      {/* Add or edit Item*/}
      <CustomModal
        open={show === ITEM}
        size="lg"
        handleClose={handleCloseEdit}
        dialogClassName="custom-modal modal-60w item-popup with-border-bottom"
        title="Edit Item"
        content={
          <AddItems
            updateData={itemData}
            closeEditModel={handleCloseEdit}
            AccountSetting={props?.AccountSetting}
            GeneralSetting={props?.GeneralSetting}
            PosSetting={props?.PosSetting}
          />
        }
        action={
          <Button variant="outline-blackcustom px-5" onClick={handleCloseEdit}>
            Cancel
          </Button>
        }
      />
      {/* Age Verification PopUp*/}
      <CustomModal
        open={agePopup}
        handleClose={(e) => handleClosepopUp(e)}
        dialogClassName="custom-modal confirmation verify-age"
        title="Verify Age"
        content={
          <AgeVerification
            ageNumber={Number.isNaN(ageNumber) ? 0 : ageNumber}
            VerifiedAge={ConfirmAgeVerification}
            ErrorMsg={setErrorMsg}
            PosSetting={props?.PosSetting}
          />
        }
        action={
          <Button
            type="button"
            variant="light px-5"
            onClick={(e) => handleClosepopUp(e)}
          >
            No
          </Button>
        }
      />
      {/* For show Error Msg When Age is not verified or not enter valid age */}
      <CustomModal
        open={openErrorMsg}
        handleClose={handleClosepopUp}
        dialogClassName="custom-modal confirmation error-popup"
        title="Error"
        content={<h1 className="mb-3">{errorMsg}</h1>}
        action={
          <Button
            type="button"
            onClick={handleClosepopUp}
            variant="danger px-5 m-auto"
          >
            OK
          </Button>
        }
      />

      {/* price qty validation popup */}

      <CustomModal
        open={priceQtyError?.open}
        handleClose={handlePriceQtyErrorClose}
        dialogClassName="custom-modal confirmation error-popup"
        title="Error"
        content={<h1 className="mb-3">{priceQtyError?.msg}</h1>}
        action={
          <Button
            type="button"
            onClick={handlePriceQtyErrorClose}
            variant="danger px-5 m-auto"
          >
            OK
          </Button>
        }
      />

      {/* ask auth */}

      <CustomModal
        open={auth}
        handleClose={() => setAuth(false)}
        dialogClassName="custom-modal  with-border-bottom authorization"
        title="Authorization"
        content={
          <Auth
            setAuthSuccess={setAuthSuccess}
            userlist={props.userlist}
            type={authType}
          />
        }
        action={
          <>
            <Button
              variant="outline-blackcustom px-5"
              onClick={() => setAuth(false)}
            >
              Cancel
            </Button>
          </>
        }
      />

      {/* Item Not Found */}
      <CustomModal
        open={show === STOCKCODECHECK}
        handleClose={handleDailogPayClose}
        dialogClassName="custom-modal confirmation item-not-found with-border-bottom"
        title="Item Not Found"
        content={
          <img
            className="my-3"
            width="80px"
            height="80px"
            src={props?.dynamicImages?.cancel}
            alt="cancel icon"
          />
        }
        action={
          <>
            <Button
              type="button"
              onClick={handleDailogPayClose}
              variant="primary px-5"
            >
              OK
            </Button>
            <Button
              id="browseBtn"
              type="button"
              onClick={handleBrowseItem}
              variant="primary px-5"
            >
              Browse
            </Button>
            <Button
              type="button"
              onClick={() => {
                if (!checkUserPermission("allow_add_item_hide")) {
                  setAuth(true);
                } else {
                  setShow(ADDITEM);
                }
                localStorage.setItem("isScanned", false);
              }}
              variant="primary px-5"
            >
              Add New Item
            </Button>
          </>
        }
      />

      {/* Add Item*/}
      <CustomModal
        open={show === ADDITEM}
        size="lg"
        handleClose={handleDailogPayClose}
        dialogClassName="custom-modal modal-60w item-popup"
        title="Add Item"
        content={
          <AddItems
            stockCode={barcode}
            closeEditModel={handleDailogPayClose}
            AccountSetting={props?.accountSetting}
            GeneralSetting={props?.genSetting}
          />
        }
        action={
          <Button variant="light px-5" onClick={handleDailogPayClose}>
            Cancel
          </Button>
        }
      />
    </>
  );
};

ItemsContain.propTypes = {
  salesitemList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  posSideSetting: PropTypes.shape({
    screenKeypad: PropTypes.bool,
    fixedKeypad: PropTypes.bool,
  }),
  AddManualItem: PropTypes.bool,
  SetAddManualItem: PropTypes.func,
  allDataRemove: PropTypes.bool,
  completeSaleItemList: PropTypes.array,
  discount: PropTypes.shape({
    ignorePopup: PropTypes.bool,
  }),
  reedemPointDiscount: PropTypes.shape({
    value: PropTypes.number,
    symbol: PropTypes.string,
  }),
  category: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  taxlist: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      base_tax: PropTypes.arrayOf(
        PropTypes.shape({
          basetax_id: PropTypes.number,
        })
      ),
    })
  ),
  taxCount: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  SaleItem: PropTypes.array,
  dynamicImages: PropTypes.shape({
    more: PropTypes.string,
    remove: PropTypes.string,
    add: PropTypes.string,
    minus: PropTypes.string,
    discount: PropTypes.string,
    minusIcon: PropTypes.string,
    plusIcon: PropTypes.string,
    warningRed: PropTypes.string,
    cancel: PropTypes.string,
  }),
  bottleDeposit: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  additionalFee: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
};

const mapStateToprops = (state) => {
  return {
    dynamicImages: state.setting.dynamicImages,
    taxlist: state.inventory.taxRule,
    taxCount: state.inventory.tax,
    salesitemList: state.saleItem.saleItemList,
    totalAmount: state.saleItem.totalAmount,
    completeSaleItemList: state.saleItem.completeSaleItemList,
    saleitemAdded: state.saleItem.saleItemadded,
    numpadstatus: state.saleItem.numpadstatus,
    category: state.inventory.category,
    itemType: state.inventory.itemType,
    additionalFee: state.inventory.additionalFee,
    bottleDeposit: state.inventory.bottledeposit,
    item: state.inventory.itemlist,
    agePopup: state.saleItem.ageUpShow,
    userlist: state.inventory.userList,
    allDataRemove: state.saleItem.allDataRemove,
    PosSetting: state.inventory.posSetting?.data,
    genSetting: state.inventory.generalSetting?.data,
    accountSetting: state.inventory.accountSetting.data,
    reedemPointDiscount: state.saleItem.reedemPointDiscount,
    sale_customer: state.saleItem.sale_customer,
    promotions: state.inventory.promotions,
    discount: state.saleItem.discount,
    itemDiscounts: state.saleItem.itemDiscounts,
    promotionData: state.saleItem.promotionData
  };
};
export default connect(mapStateToprops)(ItemsContain);